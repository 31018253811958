import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import { setReminderAlert } from "../redux";
import Badge from "@mui/material/Badge";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  snack: {
    backgroundColor: "warning",
  },
  snackTitle: {
    color: "black",
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const mapStateToProps = (state) => {
  const reminders = state.reminders.Reminders;
  return {
    reminders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReminderAlert: (id) => dispatch(setReminderAlert({ id: id })),
  };
};

function RemindersBadge({
  reminders,
  setReminderAlert,
}) {
  const classes = useStyles();
  const [numReminders, setNumReminders] = useState(0);
  const [open, setOpen] = useState(false);
  const [remindText, setRemindText] = useState("");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={handleCloseSnack}
      ></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const updateData = () => {
    setNumReminders(
      reminders?.filter((R) => R.startDate === moment().format("YYYY-MM-DD"))
        ?.length
    );
    const remind = reminders?.filter(
      (R) => moment(R.startDateTime).isSameOrBefore() && R.alertSent === false
    );
    if (remind.length > 0) {
      setOpen(remind.length > 0);
      setRemindText(remind[0].title);
      setReminderAlert(remind[0].id);
    }
  };

  function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
  }

  useEffect(() => {
    const remindersInterval = setInterval(() => {
      updateData();
    }, 1000);
    return () => clearInterval(remindersInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders]);

  return numReminders > 0 ? (
    <>
      <Badge color="error" showZero={false} badgeContent={numReminders}>
        <NotificationsActiveOutlinedIcon />
      </Badge>
      <Snackbar
        onClose={handleCloseSnack}
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        TransitionComponent={TransitionRight}
        action={action}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="warning"
          sx={{ width: "100%" }}
          icon={<NotificationsActiveOutlinedIcon fontSize="inherit" />}
        >
          <div className={classes.snackTitle}>Reminder</div>
          <br />
          {remindText}
        </Alert>
      </Snackbar>
    </>
  ) : (
    <div></div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RemindersBadge);
