import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import moment from "moment";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import {
  hexToRGB,
  getDisplaySetting,
  getPersonalSetting,
} from "Components/Utilities";
import CalendarDayFrame from "./CalendarDayFrame";
import UserStats from "Calendars/Components/UserStats";
import RemindersDialog from "../../Reminders/RemindersDialog";

const useStyles = makeStyles((theme) => ({
  userLine: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(32, 1fr)",
    position: "relative",
  },
  departmentName: {
    fontSize: "0.8rem",
    minHeight: "1.4rem",
    width: "15vw",
    backgroundColor: theme.palette.background.paperBottom,
    gridColumn: "1",
    alignSelf: "center",
    paddingLeft: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  personName: {
    fontSize: "1rem",
    width: "15vw",
    gridColumn: "1",
    textAlign: "right",
    alignSelf: "center",
    padding: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  highlight: {
    fontWeight: "500",
    color: theme.palette.text.important,
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggeduser.LoggedUser,
    monthOffdays: state.month.MonthOffdays,
    persons: state.persons.PersonsMin,
    settings: state.settings.PersonalSettings?.find(
      (PS) => PS.component === "calendars"
    )?.settings,
    monthStatic: state.month.MonthStatic,
    offDayTypes: state.offdaytypes.OffDayTypes,
    selectedDays: state.yearcalendar.selectedDays,
    reminders: state.reminders.Reminders,
    showReminderDate: state.reminders.showReminderDate,
  };
};

const UserLine = ({
  userId,
  userIsApprentice,
  startDate,
  loggedUser,
  department,
  monthOffdays,
  persons,
  settings,
  monthStatic,
  offDayTypes,
  selectedDays,
  reminders,
  showReminderDate,
  workOnSaturday,
  workdayColor,
  weekendColor,
  todayColor,
  useSpecialDayTooltips,
  showSchoolHolidays,
  showSchoolHolidaysOnlyApprentices,
  schoolHolidaysShowAsBar,
  schoolHolidaysBackColor,
  schoolHolidaysFontColor,
  schoolHolidaysShowInTooltip,
  showSpecialDays,
  specialDaysShowAsBar,
  specialDaysBackColor,
  specialDaysShowInTooltip,
  specialDaysTooltipFontColor,
  specialDaysIdentifier,
  specialDaysFontColor,
  holidaysShowAsBar,
  holidaysBackColor,
  holidaysShowInTooltip,
  holidaysTooltipFontColor,
  holidaysIdentifier,
  holidaysFontColor,
  showRemarkableDays,
  birthdayIcon,
  birthdayFontColor,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");
  const currentUser = persons.find((P) => P.id === userId);
  const emptyState = {
    mouseDown: false,
    startId: "",
    startCellId: "",
    endId: "",
    endCellId: "",
  };
  // Variables for marking frames
  const [state, setState] = useState(emptyState);

  // State for user stats
  const [openStatsForm, setOpenStatsForm] = useState(0);

  const today = moment();
  const year = monthStatic[0]
    ? monthStatic[0].date.slice(0, 4)
    : moment().format("YYYY");
  const month = monthStatic[0]
    ? monthStatic[0].date.slice(5, 7) - 1
    : moment().format("MM");
  const username = persons?.find((P) => P.id === userId).listName;
  const userOffdays = monthOffdays?.filter((MOD) => MOD.userId === userId);
  const handleClick = (userId) => {
    if (
      loggedUser.isadmin ||
      loggedUser.isassistant ||
      loggedUser.id === userId
    ) {
      // Show user stats
      store.dispatch({ type: "SET_DISPLAY_USER", payload: userId });
      setOpenStatsForm(userId);
    }
  };

  const showRemindersOnlyFuture =
    parseInt(getPersonalSetting("reminders", "showOnlyFuture")) === 1;

  const clearSelection = () => {
    store.dispatch({
      type: "SET_SELECTED_IDS",
      payload: { startId: -1, endId: -1 },
    });
    store.dispatch({ type: "SET_SELECTED_WORKDAYS", payload: 0 });
    store.dispatch({ type: "SET_SELECTED_DAYS", payload: [] });
  };

  const handleMouseDown = (id) => {
    const day = id.split("-")[1];
    setState({
      mouseDown: true,
      saveToStore: false,
      startId: day,
      startDate: moment([year, month, day]),
      startCellId: id,
      endId: day,
      endDate: moment([year, month, day]),
      endCellId: id,
    });
    const userId = id.split("d")[1].split("-")[0];
    store.dispatch({ type: "SET_DISPLAY_USER", payload: userId });
    store.dispatch({ type: "SET_DISPLAY_APPROVEMENT", payload: 0 });
  };

  const handleMouseUp = (id) => {
    if (state.mouseDown) {
      const day = id.split("-")[1];
      setState({
        ...state,
        mouseDown: false,
        saveToStore: true,
        endDate: moment([year, month, day]),
      });
      store.dispatch({ type: "SET_EDIT_MODE", payload: true });
    }
  };

  const handleMouseEnter = (id) => {
    if (state.mouseDown) {
      const day = id.split("-")[1];
      setState({
        ...state,
        saveToStore: false,
        endId: id.split("-")[1],
        endCellId: id,
        endDate: moment([year, month, day]),
      });
      // setSelection(userId, false);
    }
  };

  const handleMouseMove = (id) => {
    if (state.mouseDown) {
      const day = id.split("-")[1];
      setState({
        ...state,
        saveToStore: false,
        endId: id.split("-")[1],
        endCellId: id,
        endDate: moment([year, month, day]),
      });
      // setSelection(userId, false);
    }
  };

  const handleHover = (reminder, date) => {
    store.dispatch({
      type: "SET_DISPLAY_REMINDER",
      payload: { reminder: reminder, displayDate: date },
    });
  };

  useEffect(() => {
    if (selectedDays.length === 0) {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  useEffect(() => {
    const startSel = Math.min(state.startId, state.endId);
    const endSel = Math.max(state.startId, state.endId);

    if (startSel > 0 && endSel > 0) {
      const firstDate = moment.min(state.startDate, state.endDate);
      const lastDate = moment.max(state.startDate, state.endDate);
      store.dispatch({
        type: "SET_DAY_INFO",
        payload: {
          startDate: firstDate,
          endDate: lastDate,
          user: username,
        },
      });
      let checkElement = {};
      let workdays = 0;
      let days = [];

      for (var id = startSel; id <= endSel; id++) {
        checkElement = document.getElementById("d" + userId + "-" + id);
        if (checkElement) {
          if (parseFloat(checkElement.getAttribute("workdayfactor")) > 0) {
            workdays += parseFloat(checkElement.getAttribute("workdayfactor"));
            days.push(checkElement.getAttribute("date"));
          }
        }
      }
      if (state.saveToStore === true) {
        store.dispatch({ type: "SET_SELECTED_WORKDAYS", payload: workdays });
        store.dispatch({
          type: "SET_SELECTED_DAYS",
          payload: { startDate: firstDate, endDate: lastDate, days: days },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const UserDays = () => {
    var days = [];
    monthStatic?.forEach(function (E) {
      let currentMoment = moment(E.date);
      let currentState = userOffdays.find((YD) => YD.date === E.date);
      let staticState = monthStatic.find((YD) => YD.date === E.date);

      let isMarkable = false;
      let specialDayKey = "";
      let isHalfDay = false;
      let isOffDay = false;
      let isPublished = true;
      let offDay;
      let offDayName = "";
      let offDayKey = "";
      let workdayfactor = 1;
      let showTooltip = !useSpecialDayTooltips;
      var settingsColor = "";
      let segmentcolor = new Array(8);
      for (var i = 0; i < 8; i++) {
        segmentcolor[i] = ["", true];
      }
      var dayColor = workdayColor;
      var fontcolor;
      var backcolor;
      var iconColor;
      var birthdayIconPath;
      var birthdayIconColor;
      let nextSegment = 0;
      let tooltipTexts = [];
      let isInactive = false;
      let isBirthday = false;
      let reminderIds = [];
      let reminderStartEnd;
      let reminderNext;
      let reminderPrev;
      var age =
        parseInt(today.format("YYYY")) -
        parseInt(moment(currentUser?.birthday).format("YYYY"));

      // Reminder?
      if (userId === loggedUser.id) {
        reminderIds = showRemindersOnlyFuture
          ? reminders?.filter(
              (R) =>
                currentMoment.isBetween(
                  R.startDateTime,
                  R.endDateTime,
                  "day",
                  "[]"
                ) &&
                moment(R.endDateTime).isSameOrAfter(
                  today,
                  "day"
                )
            )
          : reminders?.filter((R) =>
              currentMoment.isBetween(
                R.startDateTime,
                R.endDateTime,
                "day",
                "[]"
              )
            );
        reminderStartEnd = reminderIds.filter(
          (R) =>
            currentMoment.isSame(R.startDateTime, "days") ||
            currentMoment.isSame(R.endDateTime, "days")
        ).length > 0;
        reminderPrev = reminderIds.find((R) =>
          currentMoment.isBetween(R.startDateTime, R.endDateTime, "days", "(]")
        );
        reminderNext = reminderIds.find((R) =>
          currentMoment.isBetween(R.startDateTime, R.endDateTime, "days", "[)")
        );
      }

      // Weekend?
      if (
        (currentMoment.format("d") === "6" && !workOnSaturday) ||
        currentMoment.format("d") === "0"
      ) {
        workdayfactor = 0;
        dayColor = weekendColor;
      }

      // Today?
      if (
        currentMoment.isSame(moment(), "days")
      ) {
        backcolor = hexToRGB(todayColor, 0.3);
      }

      // is this day markable?
      isMarkable =
        loggedUser.isadmin ||
        loggedUser.isassistant ||
        currentMoment.isSameOrAfter(moment(), "day");

      if (staticState) {
        // School holidays
        if (
          staticState["holiday"] !== "" &&
          showSchoolHolidays &&
          (showSchoolHolidaysOnlyApprentices === false ||
            (showSchoolHolidaysOnlyApprentices && userIsApprentice))
        ) {
          backcolor = hexToRGB(schoolHolidaysBackColor, 0.3);
          if (schoolHolidaysShowInTooltip) {
            tooltipTexts.push({
              text: staticState["holiday"],
              backColor: schoolHolidaysBackColor,
              fontColor: schoolHolidaysFontColor,
              personal: false,
            });
            showTooltip = true;
          }
        }

        // Special days
        if (
          staticState.isRemarkableDay &&
          staticState.useRemarkableDay &&
          showRemarkableDays
        ) {
          backcolor = hexToRGB(specialDaysBackColor, 0.3);
          if (specialDaysShowInTooltip) {
            tooltipTexts.push({
              text: staticState.remarkableDayName,
              backColor: specialDaysBackColor,
              fontColor: specialDaysTooltipFontColor,
              personal: false,
            });
            showTooltip = true;
          }
        }

        // Holidays
        if (
          staticState["isSpecialDay"] &&
          (staticState["useSpecialDay"] || staticState["alwaysUseThisDay"])
        ) {
          isHalfDay =
            parseFloat(staticState["dayFactor"]) > 0 &&
            parseFloat(staticState["dayFactor"]) < 1;
          if (staticState["isOffDay"]) {
            backcolor = hexToRGB(holidaysBackColor, 0.3);
          }
          if (holidaysShowInTooltip) {
            tooltipTexts.push({
              text: staticState["specialDayName"],
              backColor: holidaysBackColor,
              fontColor: holidaysTooltipFontColor,
              personal: false,
            });
            showTooltip = true;
          }

          workdayfactor = isHalfDay ? 0.5 : 1;
        }
      }

      // Is user active?
      if (props.user) {
        isInactive =
          moment(props.user.startDate).isAfter(currentMoment, "day") ||
          moment(props.user.endDate).isBefore(moment(E.date), "day");
      }

      // Birthday?
      if (
        props.loggedAdmin ||
        props.loggedAssistant ||
        props.loggedUserId === props.userid
      ) {
        if (
          currentUser.birthday.slice(-5) === moment(E.date).format("MM-DD") &&
          currentUser.showBirthday
        ) {
          isBirthday = true;

          birthdayIconPath = birthdayIcon;
          birthdayIconColor = birthdayFontColor;
          age =
            currentUser.showAge || props.loggedUserId === props.userid
              ? age
              : 0;
          tooltipTexts.push({
            text: age + ". " + t("Birthday"),
            backColor: "",
            fontColor: birthdayFontColor,
            personal: true,
          });
          showTooltip = true;
        }
      }

      if (currentState) {
        // personal days
        offDay = offDayTypes.find((OT) => OT.id === currentState.offDayTypeId);
        offDayName = offDay?.names.find(
          (OTN) => OTN.language === language
        )?.offDayType;
        offDayKey = offDay?.names.find(
          (OTN) => OTN.language === language
        )?.offDayKey;

        isOffDay = true;
        isPublished = currentState.published === "1";
      }

      // absence day
      if (isOffDay && (isPublished || loggedUser.id === userId)) {
        if (nextSegment === 8) {
          nextSegment = 0;
        }
        var stateBackColor = getDisplaySetting(
          currentState.offDayTypeId,
          "backgroundColor"
        );
        var stateBackFactor1 = getDisplaySetting(
          currentState.offDayTypeId,
          "backgroundFactor1"
        );
        var stateBackFactor2 = getDisplaySetting(
          currentState.offDayTypeId,
          "backgroundFactor2"
        );
        var stateFontColor = getDisplaySetting(
          currentState.offDayTypeId,
          "fontColor"
        );
        var stateFontFactor = getDisplaySetting(
          currentState.offDayTypeId,
          "fontFactor"
        );
        var stateTooltipFontColor = getDisplaySetting(
          currentState.offDayTypeId,
          "tooltipFontColor"
        );
        switch (currentState.state) {
          case "1":
            offDayName +=
              " (" +
              t("planned") +
              ")" +
              (!isPublished ? " " + t("not published") : "");
            settingsColor = hexToRGB(stateBackColor, stateBackFactor2);
            break;
          case "2":
            offDayName += " (" + t("requested") + ")";
            settingsColor = hexToRGB(stateBackColor, stateBackFactor1);
            break;
          case "3":
            offDayName += " (" + t("approved") + ")";
            settingsColor = stateBackColor;
            break;
          case "4":
            offDayName += " (" + t("registered") + ")";
            settingsColor = stateBackColor;
            break;
          default:
        }

        segmentcolor[nextSegment][0] = settingsColor;
        segmentcolor[nextSegment][1] = isPublished;
        if (!isHalfDay) {
          segmentcolor[nextSegment + 1][0] = settingsColor;
          segmentcolor[nextSegment + 1][1] = isPublished;
        }

        fontcolor = hexToRGB(stateFontColor, stateFontFactor);

        specialDayKey = offDayKey;
        if (isHalfDay && specialDayKey?.slice(0, 1) !== "½") {
          specialDayKey = "½" + specialDayKey;
        }
        tooltipTexts.push({
          text: offDayName,
          additionalText: currentState.additionalText,
          backColor: stateBackColor,
          fontColor: stateTooltipFontColor,
          personal: true,
        });
        showTooltip = true;
      }

      days.push(
        <td key={userId + "-" + E.date.slice(-3)}>
          <CalendarDayFrame
            id={"d" + userId + E.date.slice(-3)}
            date={E.date}
            startId={state.startCellId}
            endId={state.endCellId}
            username={currentUser.listName}
            ismarkable={isMarkable}
            // today={isToday}
            todayColor={todayColor}
            tooltipTexts={tooltipTexts}
            specialdaykey={specialDayKey}
            daycolor={dayColor}
            backColor={backcolor}
            textColor={fontcolor}
            segmentColor={segmentcolor}
            markercolor={iconColor}
            workdayfactor={workdayfactor}
            showtooltip={showTooltip}
            onMouseDown={(id) => handleMouseDown(id)}
            onMouseUp={(id) => handleMouseUp(id)}
            onMouseEnter={(id) => handleMouseEnter(id)}
            onMouseMove={(id) => handleMouseMove(id)}
            onHover={(reminder, date) => handleHover(reminder, date)}
            onResetSelection={clearSelection}
            isbirthday={isBirthday}
            birthdayIcon={birthdayIconPath}
            birthdayColor={birthdayIconColor}
            published={isPublished}
            inactive={isInactive}
            reminders={reminderIds}
            reminderStartEnd={reminderStartEnd}
            reminderPrev={reminderPrev}
            reminderNext={reminderNext}
          />
        </td>
      );
    });
    return days;
  };

  return (
    <>
      <tr className={classes.userLine}>
        <td
          key={-1}
          className={classes.personName}
          onClick={() => handleClick(userId)}
        >
          <span className={userId === loggedUser.id ? classes.highlight : ""}>
            {currentUser?.listName}
          </span>
        </td>
        <UserDays />
        {openStatsForm > 0 &&
          (loggedUser.isadmin ||
            loggedUser.isassistant ||
            loggedUser.id === openStatsForm) && (
            <UserStats
              closeStatsForm={() => {
                setOpenStatsForm(0);
              }}
            />
          )}
      </tr>
      {showReminderDate && userId === loggedUser.id && <RemindersDialog />}
    </>
  );
};

export default connect(mapStateToProps)(UserLine);
