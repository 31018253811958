import {
  FETCH_SPECIALDAYS_SUCCESS,
  SET_SPECIALDAY_ID,
  UPDATE_SPECIALDAY,
  SAVE_SPECIALDAY_COUNTRIES,
  FETCH_REMARKABLEDAYS_SUCCESS,
  SET_REMARKABLEDAY_ID,
  UPDATE_REMARKABLEDAY,
  FETCH_HOLIDAYS_SUCCESS,
  UPDATE_HOLIDAY,
  ADD_HOLIDAY,
  DELETE_HOLIDAY,
  FETCH_HOLIDAYTYPES_SUCCESS,
  SET_HOLIDAY_ID,
  FETCH_SPECIALDAYS_REQUEST,
  SPECIALDAYS_FAILURE,
  SET_DISPLAYSETTING_ID,
} from "actions/types";

import produce from "immer";

const initialState = {
  SpecialDays: [],
  RemarkableDays: [],
  Holidays: [],
  HolidayTypes: [],
  displaySettingsId: -1,
  specialDayId: -1,
  remarkableDayId: -1,
  holidayId: -1,
  loading: false,
  error: "",
};

const specialDaysReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    switch (action.type) {
      case FETCH_SPECIALDAYS_REQUEST:
        draft.loading = true;
        break;
      case FETCH_SPECIALDAYS_SUCCESS:
        draft.SpecialDays = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case SPECIALDAYS_FAILURE:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case SET_SPECIALDAY_ID:
        draft.displaySettingsId = -1;
        draft.remarkableDayId = -1;
        draft.holidayId = -1;
        draft.specialDayId = action.payload;
        break;
      case UPDATE_SPECIALDAY:
        key = draft.SpecialDays.findIndex((SD) => SD.id === action.payload.id);
        draft.SpecialDays[key] = action.payload;
        draft.specialDayId = -1;
        break;
      case SAVE_SPECIALDAY_COUNTRIES:
        key = draft.SpecialDays.findIndex((SD) => SD.id === action.payload.id);
        const countryKey = draft.SpecialDays[key].countriesRegions.findIndex(
          (CR) => CR.country === action.payload.key
        );
        if (countryKey === -1) {
          draft.SpecialDays[key].countriesRegions = [
            { country: action.payload.key, regions: action.payload.value },
          ];
        } else {
          draft.SpecialDays[key].countriesRegions[countryKey] = {
            country: action.payload.key,
            regions: action.payload.value,
          };
        }
        break;
      case FETCH_REMARKABLEDAYS_SUCCESS:
        draft.RemarkableDays = action.payload;
        break;
      case SET_REMARKABLEDAY_ID:
        draft.displaySettingsId = -1;
        draft.specialDayId = -1;
        draft.holidayId = -1;
        draft.remarkableDayId = action.payload;
        break;
      case UPDATE_REMARKABLEDAY:
        key = draft.RemarkableDays.findIndex(
          (RD) => RD.id === action.payload.id
        );
        draft.RemarkableDays[key] = action.payload;
        draft.remarkableDayId = -1;
        break;
      case FETCH_HOLIDAYS_SUCCESS:
        draft.Holidays = action.payload;
        break;
      case SET_HOLIDAY_ID:
        draft.displaySettingsId = -1;
        draft.specialDayId = -1;
        draft.remarkableDayId = -1;
        draft.holidayId = action.payload;
        break;
      case UPDATE_HOLIDAY:
        key = draft.Holidays.findIndex((HD) => HD.id === action.payload.id);
        draft.Holidays[key] = action.payload;
        draft.holidayId = -1;
        break;
      case ADD_HOLIDAY:
        draft.Holidays = [...draft.Holidays, action.payload];
        draft.holidayId = -1;
        break;
      case DELETE_HOLIDAY:
        key = draft.Holidays.findIndex((HD) => HD.id === action.payload.id);
        draft = { Holidays: draft.Holidays.splice(key, 1) };
        draft.holidayId = -1;
        break;
      case FETCH_HOLIDAYTYPES_SUCCESS:
        draft.HolidayTypes = action.payload;
        break;
      case SET_DISPLAYSETTING_ID:
        draft.displaySettingsId = action.payload;
        draft.specialDayId = -1;
        draft.remarkableDayId = -1;
        draft.holidayId = -1;
        break;
      default:
        return state;
    }
  });

export default specialDaysReducer;
