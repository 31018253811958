import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getGlobalSetting } from "Components/Utilities";
import { createMovement, setSelectedArticleId } from "redux/index";

import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import makeStyles from "@mui/styles/makeStyles";
import SomuraAutocomplete from "Components/SomuraSelectField";
import SomuraDataGrid from "Components/SomuraDataGrid";
import SomuraTextField from "Components/SomuraTextField";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import moment from "moment";

const useStyles = makeStyles(() => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  const person = state.persons.PersonsMin?.find(
    (P) => P.id === state.persons.personId
  );
  const articles = state.articles.Articles.filter(
    (A) => A.movements[0].isFreeStock
  );
  const types = state.articles.ArticleTypes;
  const models = state.articles.ArticleModels;
  const reasons = state.articles.Reasons;
  const states = state.articles.States;
  return {
    person,
    articles,
    types,
    models,
    reasons,
    states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setArticleId: (id) => dispatch(setSelectedArticleId(id)),
    createMovement: (values) => dispatch(createMovement(values)),
  };
};

const LoanAssetForm = ({
  person,
  articles,
  types,
  models,
  reasons,
  states,
  createMovement,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const useInventoryNumbers =
    parseInt(getGlobalSetting("articles", "useInventoryNumbers")) === 1;

  const [displayArticles, setDisplayArticles] = useState([]);
  const uniqueTypes = [...new Set(articles.map((A) => A.typeId))];
  const uniqueModels = [...new Set(articles.map((A) => A.modelId))];
  const formHeader = t("New loan asset to") + " " + person?.listName;

  const emptyState = {
    articleId: "0",
    typeId: "0",
    modelId: "0",
    movementTypeId: "0",
    movementDate: moment().format("DD.MM.YYYY"),
    locationId: "0",
    personId: person?.id,
    reasonId: "0",
    stateId: "0",
  };
  const [state, setState] = useState(emptyState);
  const [selectedArticle, setSelectedArticle] = useState("");
  const [valid, setValid] = useState(false);

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Article type"),
      headerClassName: "tableHeader",
      field: "articleTypeName",
      flex: 0.2,
    },
    {
      headerName: t("Model"),
      headerClassName: "tableHeader",
      field: "modelName",
      flex: 0.2,
    },
    {
      headerName: t("Inventory number"),
      headerClassName: "tableHeader",
      field: "inventoryNumber",
      flex: 0.2,
      hide: !useInventoryNumbers,
    },
    {
      headerName: t("Manufacturer serial number"),
      headerClassName: "tableHeader",
      field: "manufacturerSerialNumber",
      flex: 0.2,
    },
  ];

  const rows = displayArticles.map((DA) => ({
    id: DA.id,
    modelName: DA.modelName,
    articleTypeName: DA.articleTypeName,
    inventoryNumber: DA.inventoryNumber,
    manufacturerSerialNumber: DA.manufacturerSerialNumber,
    locationName:
      DA.movements.length > 0
        ? DA.movements[0].personId === "0"
          ? DA.movements[0].locationName
          : DA.movements[0].personName
        : "",
    typeId: DA.typeId,
  }));

  const LoanAssetSchema = Yup.object().shape({
    movementDate: Yup.date().required(t("Required")),
  });

  var typeOptions = types
    .filter((T) => uniqueTypes.includes(T.id))
    .map((T) => ({
      label: T.articleTypeName,
      value: T.id,
    }));
  typeOptions.unshift({ label: t("All article types"), value: "0" });

  const [modelOptions, setModelOptions] = useState([
    { label: t("All models"), value: "0" },
  ]);

  var reasonOptions = reasons.map((R) => ({
    label: R.reason,
    value: R.id,
  }));
  reasonOptions.unshift({ label: "", value: "0", first: false });

  var stateOptions = states.map((S) => ({
    label: S.status,
    value: S.id,
  }));
  stateOptions.unshift({ label: "", value: "0", first: false });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await LoanAssetSchema.isValid(state));
  });

  useEffect(() => {
    setState(emptyState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  useEffect(() => {
    if (articles) {
      setDisplayArticles(articles);
      setModelOptions([{ label: t("All models"), value: "0" }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleRowClick = (params) => {
    const selectedArticle = articles?.find((T) => T.id === params);
    setSelectedArticle(
      selectedArticle.articleTypeName + " > " + selectedArticle?.modelName
    );
    setState({ ...state, articleId: params });
    // setId(params);
  };

  const handleSubmit = () => {
    createMovement({
      ...state,
      movementTypeId: getGlobalSetting("articles", "moveToUser"),
      locationId: getGlobalSetting("articles", "moveToUserLocation"),
      personId: person.id,
    });
    handleCancel();
  };

  const handleChange = (event) => {
    const name = event.target ? event.target.name : event.name;
    const value = event.target ? event.target.value : event.value;
    setState({ ...state, [name]: value });
    if (name === "typeId") {
      props.setAssetTypeId(value);
    }
  };

  const handleAutoCompleteChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    const value = state.typeId;
    if (value === "0") {
      setDisplayArticles(articles);
    } else {
      setDisplayArticles(articles.filter((A) => A.typeId === value));
    }
    setState({
      ...state,
      modelId: "0",
      movementDate: "",
      reasonId: "0",
      stateId: "0",
    });
    setSelectedArticle("");
    setModelOptions([
      { label: t("All models"), value: "0" },
      ...models
        .filter((M) => M.articleTypeId === value && uniqueModels.includes(M.id))
        .map((M) => ({
          label: M.modelName,
          value: M.id,
        })),
    ]);
    setValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.typeId]);

  useEffect(() => {
    const value = state.modelId;
    setState({ ...state, movementDate: "", reasonId: "0", stateId: "0" });
    if (value === "0") {
      if (state.typeId === "0") {
        setDisplayArticles(
          articles.filter((A) => A.typeId !== "-1" && A.modelId !== "-1")
        );
      } else {
        setDisplayArticles(
          articles.filter(
            (A) => A.typeId === state.typeId && A.modelId !== "-1"
          )
        );
      }
    } else {
      if (state.typeId === "0") {
        setDisplayArticles(
          articles.filter((A) => A.typeId !== "-1" && A.modelId === value)
        );
      } else {
        setDisplayArticles(
          articles.filter(
            (A) => A.typeId === state.typeId && A.modelId === value
          )
        );
      }
    }
    setSelectedArticle("");
    setValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.modelId]);

  const handleCancel = () => {
    props.setArticleId(-1);
  };

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={"subLine"}>{t("Available articles")}</div>
          </Grid>
          <Grid item xs={6}>
            <SomuraAutocomplete
              name="typeId"
              label={t("Type")}
              options={typeOptions}
              value={state.typeId}
              onChange={(values) => handleAutoCompleteChange(values)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraAutocomplete
              name="modelId"
              label={t("Model")}
              defaultValue=""
              value={state.modelId}
              options={modelOptions}
              variant="outlined"
              size="small"
              onChange={(values) => handleAutoCompleteChange(values)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <SomuraDataGrid
              tableHeight={"30vh"}
              rows={rows}
              columns={columns}
              defaultSortModel={[
                {
                  field: "modelName",
                  sort: "asc",
                },
              ]}
              onRowClick={(params) => handleRowClick(params)}
              noToolbar
              noContainerPadding
            />
          </Grid>
          <Grid item xs={12}>
            <div className={"subLine"}>
              {t("Handout") +
                (selectedArticle !== "" ? ": " + selectedArticle : "")}
            </div>
          </Grid>
          <Grid item xs={3}>
            <SomuraTextField
              label={t("Date")}
              type="date"
              name="movementDate"
              value={state.movementDate}
              onChange={handleChange}
              disabled={selectedArticle === ""}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <SomuraAutocomplete
              name="reasonId"
              label={t("Reason")}
              options={reasonOptions}
              onChange={(values) => handleChange(values)}
              value={state.reasonId}
              disabled={selectedArticle === ""}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <SomuraAutocomplete
              name="stateId"
              label={t("Condition")}
              options={stateOptions}
              onChange={(values) => handleChange(values)}
              value={state.stateId}
              disabled={selectedArticle === ""}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <div className="formBottom">
          <CancelButton onClick={handleCancel} />
          <SaveButton disabled={!valid} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanAssetForm);
