import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import BackButton from "Components/Buttons/BackButton";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OffDayTextForm from "./OffDayTextForm";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "0 10px 6px 10px",
  },
  formHeader: {
    textAlign: "center",
    borderBottom: "2px solid grey",
    marginBottom: "10px",
    padding: "4px",
    fontSize: "22px",
    minHeight: "35px",
    lineHeight: "25px",
    color: theme.palette.text.screenTitle,
  },
  listItem: {
    width: "100%",
    height: "2.5rem",
    padding: "0 8px",
    marginBottom: "4px",
    backgroundColor: theme.palette.background.listItem,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1rem",
    gridTemplateRows: "1fr",
    alignItems: "center",
  },
  listItemText: {
    gridColumn: "1",
  },
  listIcon: {
    gridColumn: "3",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  const offDayTypeId = state.offdaytypes.offDayTypeId;
  const offDayType = state.offdaytypes.OffDayTypes.find(
    (OT) => OT.id === offDayTypeId
  );
  return {
    offDayTypeId,
    offDayType,
  };
};

const OffDayTexts = ({ offDayType, displayName, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedTextId, setSelectedTextId] = useState(-1);

  const additionalTexts = offDayType?.additionalTexts || [];
  const numAdditionalTexts = additionalTexts?.length;

  const handleSelect = (id) => {
    setSelectedTextId(id);
  };

  const textListLeft = () => {
    var listItems = [];
    for (let i = 0; i < Math.round(numAdditionalTexts / 2); i++) {
      listItems.push(
        <ListItem
          key={i}
          className={classes.listItem}
          onClick={() => {
            handleSelect(additionalTexts[i].id);
          }}
          id={additionalTexts[i].text}
        >
          <div className={classes.listItemText}>
            {additionalTexts[i].text}
            {additionalTexts[i].numAssigned > 0
              ? " (" + additionalTexts[i].numAssigned + ")"
              : ""}
          </div>
          <ChevronRightIcon className={classes.listIcon} />
        </ListItem>
      );
    }
    return listItems;
  };

  const textListRight = () => {
    var listItems = [];
    for (
      let i = Math.round(numAdditionalTexts / 2);
      i < numAdditionalTexts;
      i++
    ) {
      listItems.push(
        <ListItem
          key={i}
          className={classes.listItem}
          onClick={() => {
            handleSelect(additionalTexts[i].id);
          }}
          id={additionalTexts[i].text}
        >
          <div className={classes.listItemText}>
            {additionalTexts[i].text}
            {additionalTexts[i].numAssigned > 0
              ? " (" + additionalTexts[i].numAssigned + ")"
              : ""}
          </div>
          <ChevronRightIcon className={classes.listIcon} />
        </ListItem>
      );
    }
    return listItems;
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Collapse in={selectedTextId === -1} timeout={"auto"}>
        <div className={classes.thisForm}>
          <div>
            <div className={classes.formHeader}>
              {t("Absence type") +
                ": " +
                displayName +
                " > " +
                t("Additional texts")}
            </div>
            <Grid container spacing={2}>
              {additionalTexts.length === 0 ? (
                <Grid item xs={12}>
                  <div className="noEntries">
                    {t("No entries in this list")}
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid item xs={6} lg={4}>
                    {textListLeft()}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    {textListRight()}
                  </Grid>
                </>
              )}
            </Grid>
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              className={classes.fab}
              onClick={() => setSelectedTextId(0)}
            >
              <AddIcon />
              {t("New additional text")}
            </Fab>
          </div>
          <div className="formBottom">
            <BackButton onClick={handleClose} />
          </div>
        </div>
      </Collapse>
      <Collapse in={selectedTextId > -1} timeout={"auto"}>
        <OffDayTextForm
          displayName={displayName}
          offDayTypeId={offDayType?.id}
          textId={selectedTextId}
          offDayText={
            selectedTextId === 0
              ? ""
              : offDayType?.additionalTexts.find(
                  (AT) => AT.id === selectedTextId
                )?.text
          }
          closeTextForm={() => {
            setSelectedTextId(-1);
          }}
        />
      </Collapse>
    </>
  );
};

export default connect(mapStateToProps)(OffDayTexts);
