import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

const EditButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      startIcon={<EditIcon />}
      color="edit"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {t("Edit")}
    </Button>
  );
};

export default EditButton
