import Fab from "@mui/material/Fab";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SomuraTooltip from "Components/SomuraTooltip";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1rem",
    position: "relative",
    marginRight: "4px"
  },
}));

const TerminateButton = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <SomuraTooltip title={t("Day termination")} placement="top" arrow={true}>
      <Fab
        variant="extended"
        color="primary"
        size="medium"
        className={classes.root}
        onClick={props.onClick}
      >
        <CheckCircleOutlineOutlinedIcon />
      </Fab>
    </SomuraTooltip>
  );
};

export default TerminateButton;
