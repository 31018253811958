import React from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import { useTranslation } from "react-i18next";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  menuTitle: {
    fontSize: "0.8rem",
    textAlign: "center",
    lineHeight: "1.5rem",
    padding: "0 4px",
    color: theme.palette.text.screenTitle,
  },
}));

const mapStateToProps = (state) => {
  return {
    anchorData: state.general.showContextMenu,
    reminders: state.reminders.displayReminder,
  };
};

const ContextMenu = ({ anchorData, reminders }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const anchorElement = document.getElementById(anchorData.element);
  const thisMoment = moment(anchorData.date);
  const numReminders = reminders.length;

  const handleEdit = () => {
    store.dispatch({ type: "OPEN_REMINDER_DIALOG", payload: "x" });
    store.dispatch({
      type: "SHOW_CONTEXT_MENU",
      payload: null,
    });
  };

  const handleNew = () => {
    store.dispatch({
      type: "OPEN_REMINDER_DIALOG",
      payload: thisMoment.format("YYYY-MM-DD"),
    });
    store.dispatch({
      type: "SHOW_CONTEXT_MENU",
      payload: null,
    });
  };

  const handleClose = () => {
    store.dispatch({
      type: "OPEN_REMINDER_DIALOG",
      payload: null,
    });
    store.dispatch({
      type: "SET_DISPLAY_REMINDER",
      payload: {reminder: 0, displayDate: null},
    });
    store.dispatch({
      type: "SHOW_CONTEXT_MENU",
      payload: null,
    });
  };

  return (
    <div>
      <Menu
        id="context-menu"
        anchorEl={anchorElement}
        open={true}
        onClose={handleClose}
      >
        <div disabled={true} className={classes.menuTitle}>
          {thisMoment.format("dddd, DD.MMMM YYYY")}
        </div>
        <MenuItem
          onClick={handleNew}
          disabled={thisMoment.isBefore(moment({ hour: 0, minute: 0 }))}
        >
          {t("New reminder")}
        </MenuItem>
        <MenuItem
          onClick={handleEdit}
          disabled={
            reminders.filter(
              (R) =>
                moment(R.startDateTime).format("YYYY-MM-DD") <=
                  thisMoment.format("YYYY-MM-DD") &&
                moment(R.endDateTime).format("YYYY-MM-DD") >=
                  thisMoment.format("YYYY-MM-DD")
            ).length === 0
          }
        >
          {numReminders > 1 ? t("Show reminders") : t("Show reminder")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default connect(mapStateToProps)(ContextMenu);
