import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { store } from "redux/store";
import { getGlobalSetting } from "Components/Utilities";
import { fetchYear } from "redux/index";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import makeStyles from "@mui/styles/makeStyles";

import ClearIcon from "@mui/icons-material/Clear";

import DialogTitle from "Calendars/Components/DialogTitle";
import Approvement from "./Approvement";
import NonApprovement from "./NonApprovement";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: 700,
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
  cancelButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-selectionform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    loggedAdmin: state.loggeduser.LoggedUser.isadmin,
    loggedAssistant: state.loggeduser.LoggedUser.isassistant,
    mayDeletePlanned: state.loggeduser.LoggedUser.deletePlannedAbsence,
    userId: state.yearcalendar.displayUserId,
    displayyear: state.general.DisplayYear,
    editMode: state.general.EditMode,
    yeardata: state.year.Year,
    yearstaticdata: state.year.YearStatic,
    startDate: state.yearcalendar.selectedDays.startDate,
    endDate: state.yearcalendar.selectedDays.endDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchYear: (values) => dispatch(fetchYear(values)),
  };
};

const SelectionForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
  };

  const title = () => {
    let title = "";
    const firstDate = moment(props.startDate).format("dddd, LL");
    const lastDate = moment(props.endDate).format("dddd, LL");

    if (firstDate === lastDate) {
      title = firstDate;
    } else {
      title = firstDate + " " + t("to") + " " + lastDate;
    }

    return <span>{title}</span>;
  };

  const selectDay = (checkDate) => {
    const selectedDay = props.yeardata.find(
      (YD) => YD.date === checkDate && YD.isPersonOffDay === "1"
    );
    return selectedDay;
  };

  const selectStaticDay = (checkDate) => {
    const selectedDay =
      props.yearstaticdata.find((YD) => YD.date === checkDate) || null;
    return selectedDay;
  };

  const body = () => {
    const numDays =
      moment(props.endDate).diff(moment(props.startDate), "days") + 1;
    let lines = [];
    let selectedDates = [];
    let approvementId = -1;
    let thisApprovementId = 0;
    for (var i = 0; i < numDays; i++) {
      let currentDate = moment(props.startDate)
        .add(i, "days")
        .format("YYYY-MM-DD");
      let selectedDay = selectDay(currentDate);
      let selectedStaticDay = selectStaticDay(currentDate);

      if (selectedDay) {
        // This date is already an absence day
        thisApprovementId = selectedDay.approvementId;
        if (thisApprovementId !== approvementId) {
          if (thisApprovementId > 0) {
            lines.push(
              <Approvement key={"A" + i} approvementId={thisApprovementId} />
            );
          }
        }
        approvementId = thisApprovementId;
      }
      if (
        !selectedDay ||
        (selectedDay &&
          (selectedDay.published === "0" ||
            (selectedDay.published === "1" &&
              selectedDay.state === "1" &&
              props.mayDeletePlanned)))
      ) {
        // check if day should be counted
        // is it weekend?
        let isWeekend =
          moment(currentDate).format("d") === "0" ||
          (moment(currentDate).format("d") === "6" &&
            parseInt(getGlobalSetting("system", "workOnSaturday")) === 0);
        let dayFactor = 1;
        if (selectedDay) {
          dayFactor = parseFloat(selectedDay.dayFactor);
        }
        if (selectedStaticDay) {
          dayFactor = parseFloat(selectedStaticDay.dayFactor);
        }

        if (!isWeekend && dayFactor > 0) {
          selectedDates.push({
            date: currentDate,
            approvementId: selectedDay ? approvementId : 0,
            workday: dayFactor,
          });
        }
        if (
          selectedDates.length > 0 &&
          currentDate === moment(props.endDate).format("YYYY-MM-DD")
        ) {
          lines.push(
            <NonApprovement
              key={"NA" + i}
              dates={selectedDates}
              numDays={selectedDates.length}
            />
          );
          selectedDates = [];
          thisApprovementId = 0;
        }
      }
    }
    return lines;
  };

  useEffect(() => {
    return () => {
      // Clear selection when form is closed
      props.fetchYear({
        year: props.displayyear,
        userid: props.userId,
      });
      store.dispatch({
        type: "SET_SELECTED_IDS",
        payload: { startId: -1, endId: -1 },
      });
      store.dispatch({ type: "SET_SELECTED_WORKDAYS", payload: 0 });
      store.dispatch({ type: "SET_SELECTED_DAYS", payload: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-selectionform-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-selectionform-title"
        dialogTitle={t("Edit selection")}
        closeForm={handleClose}
      />
      <DialogContent className="dialogContent" style={{ padding: "0px" }}>
        <div className="timeFrame">{title()}</div>
        <div>{body()}</div>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button
          id="cancelselection"
          className={classes.cancelButton}
          startIcon={<ClearIcon />}
          variant="outlined"
          color="cancel"
          onClick={handleClose}
        >
          {t("Abort")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionForm);
