import React from "react";
import { store } from "redux/store";
import { emptyRecycleBin } from "redux/index";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";
import NoButton from "Components/Buttons/NoButton";
import YesButton from "Components/Buttons/YesButton";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "10rem",
  },
  topScrollPaper: {
    height: "100vh",
    alignItems: "center",
  },
  topPaperScrollBody: {
    verticalAlign: "center",
  },
  title: {
    width: "15rem",
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    textAlign: "center",
    margin: "0 4px",
  },
  body: {
    padding: "4px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  folderName: {
    fontSize: "1.2rem",
    color: theme.palette.text.screenTitle,
    textAlign: "center",
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
}));

const mapStateToProps = (state) => {
  return {
    logged: state.loggeduser.LoggedUser.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    emptyRecycleBin: (values) => dispatch(emptyRecycleBin(values)),
  };
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ minHeight: "8rem", maxWidth: 700 }} />
    </Draggable>
  );
}

const EmptyRecycleBin = ({ logged, folderId, close, emptyRecycleBin }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const folderName = t("Recycle bin");

  const title = () => {
    return <span className={classes.title}>{t("Empty recycle bin")}</span>;
  };

  const body = () => {
    return (
      <>
        <div className={classes.folderName}>{folderName}</div>
        <div className={classes.body}>
          {t("Permanently delete messages in recycle bin?")}
        </div>
      </>
    );
  };

  const handleClose = () => {
    close();
  };

  const handleEmptyBin = () => {
    emptyRecycleBin({ userId: logged });
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
    close();
  };

  return (
    <Dialog
      classes={{
        root: classes.root,
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-dialog-title"
        dialogTitle={title()}
        closeForm={handleClose}
        width="25rem"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent className="dialogContent">
        <div>{body()}</div>
      </DialogContent>
      <DialogActions className="dialogActions">
        <NoButton
          onClick={() => {
            handleClose();
          }}
        />
        <YesButton
          onClick={() => {
            handleEmptyBin();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyRecycleBin);
