import React from 'react'
import MuiAlert from "@mui/material/Alert";

const SomuraAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props}>
      <span>{props.message || 'Error!'}</span>
    </MuiAlert>
  );
})

export default SomuraAlert