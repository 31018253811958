import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { connect } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DashboardItem from "Components/DashboardItem";
import HowToRegTwoToneIcon from "@mui/icons-material/HowToRegTwoTone";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontSize: "1rem",
    color: theme.palette.dashBoard.title,
  },
  tableCell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const mapStateToProps = (state) => {
  return {
    offdaytypes: state.offdaytypes.OffDayTypes,
  };
};

function AtWorkToday({ offdaytypes, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");

  const title = t("Present today");
  const subtitle = t("of total ") + props.total;
  const noData =
    props.data && props.data.length === 0
      ? t("All employees are present.")
      : "";

  const dataTable = () => {
    if (offdaytypes && props.data && props.data.length > 0) {
      return (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>
                {t("Absent")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>
                  {row.lastName + ", " + row.firstName}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {
                    offdaytypes
                      .find((OT) => OT.id === row.offDayTypeId)?.names.find((N) => N.language === language)?.offDayType
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <DashboardItem
      title={title}
      subtitle={subtitle}
      value={props.value}
      iconColor="#0288D1"
      icon={<HowToRegTwoToneIcon style={{ viewBox: "0 0 100 100" }} />}
      table={dataTable()}
      nodata={noData}
    />
  );
}

export default connect(mapStateToProps, null)(AtWorkToday);
