import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { addRemark, updateRemark, deleteRemark } from "redux/index";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import SomuraTextField from "../SomuraTextField";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.scrollBar,
  },
  dialogTitle: {
    width: "30rem",
    padding: "4px 10px",
    fontWeight: "200",
    color: theme.palette.text.screenTitle,
  },
  dialogText: {
    color: theme.palette.text.calendars,
  },
  dialogContent: {
    padding: "10px 10px 6px 10px",
    backgroundColor: theme.palette.dialog.content,
  },
  dialogActions: {
    padding: "0 8px",
  },
}));

const mapStateToProps = (state) => {
  const loggedId = state.loggeduser.LoggedUser.id;
  const remarks = state.remarks.Remarks;
  const selectedId = state.remarks.selectedRemarkId;
  return { loggedId, remarks, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (values) => dispatch(addRemark(values)),
    update: (values) => dispatch(updateRemark(values)),
    remove: (values) => dispatch(deleteRemark(values)),
  };
};

const RemarkDialog = ({
  primaryId,
  loggedId,
  remarks,
  selectedId,
  create,
  update,
  close,
  remove,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const emptyState = {
    id: "0",
    text: "",
    tableName: "persons",
    primaryId,
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);

  const remarkSchema = Yup.object().shape({
    text: Yup.string().required(t("Required")),
  });

  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    if (state.id === "0") {
      // Create new remark
      create({
        ...state,
        primaryId,
        createdOn: moment().format("YYYY-MM-DD HH:mm:ss"),
        createdById: loggedId,
        modifiedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
        modifiedById: loggedId,
      });
    } else {
      // Update remark
      update({
        ...state,
        primaryId,
        modifiedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
        modifiedById: loggedId,
      });
    }
    handleClose();
  };

  const handleDelete = () => {
    remove({ tableName: "persons", id: selectedId });
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await remarkSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (parseInt(selectedId) > 0) {
      const remark = remarks.find((R) => R.id === selectedId);
      setState({ ...emptyState, ...remark });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(event, reason);
          }
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {parseInt(selectedId) > 0 ? t("Remark") : t("New remark")}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <div className={classes.dialogContent}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SomuraTextField
                  label={t("Text")}
                  type="text"
                  name="text"
                  value={state.text}
                  onChange={handleChange}
                  required
                  multiline
                  rows={4}
                  maxRows={8}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CancelButton onClick={handleCancel} />
          {selectedId > 0 && (
            <DeleteButton
              onClick={handleDelete}
              disabled={loggedId !== state.createdById}
            />
          )}
          <SaveButton disabled={!valid} onClick={handleSubmit} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemarkDialog);
