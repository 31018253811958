import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchCountries,
  setSelectedCountryId,
  setSelectedRegionId,
} from "redux/index";
import SomuraDataGrid from "Components/SomuraDataGrid";
import CountryForm from "./CountryForm";
import RegionForm from "./RegionForm";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
  TableRow: {
    cursor: "pointer",
  },
}));

const mapStateToProps = (state) => {
  return {
    countries: state.countries.Countries,
    editMode: state.general.EditMode,
    selectedCountryId: state.countries.selectedCountryId,
    selectedRegionId: state.countries.selectedRegionId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCountries: () => dispatch(fetchCountries()),
    setSelectedCountryId: (id) => dispatch(setSelectedCountryId(id)),
    setSelectedRegionId: (id) => dispatch(setSelectedRegionId(id)),
  };
};

function CountriesTable({
  countries,
  selectedCountryId,
  selectedRegionId,
  setSelectedCountryId,
  setSelectedRegionId,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "ID",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
    },
    {
      headerName: t("Country"),
      headerClassName: "tableHeader",
      field: "ownName",
      flex: 0.3,
    },
    {
      headerName: t("Abbreviation"),
      headerClassName: "tableHeader",
      field: "short2",
      flex: 0.3,
    },
    {
      headerName: t("Number of regions"),
      headerClassName: "tableHeader",
      field: "numRegions",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
    },
  ];

  const rows = countries.map((C, index) => ({
    key: index,
    ...C,
  }));

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "new":
        // props.newDepartment();
        setSelectedCountryId(0);
        break;
      default:
    }
  };

    const handleRowClick = (params) => {
      setSelectedCountryId(params);
    };

  useEffect(() => {
    props.fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Collapse
        in={selectedCountryId === -1 && selectedRegionId === -1}
        timeout={"auto"}
      >
        <div className={classes.TableContainer}>
          <SomuraDataGrid
            tableHeight="65vh"
            rows={rows}
            columns={columns}
            defaultSortModel={[
              {
                field: "ownName",
                sort: "asc",
              },
            ]}
            onRowClick={(params) => handleRowClick(params)}
            csvFileName={t("Countries")}
          />
          <Fab
            variant="extended"
            color="primary"
            size="medium"
            className={classes.fab}
            onClick={() => handleButtonClick("new")}
          >
            <AddIcon />
            {t("New country")}
          </Fab>
        </div>
      </Collapse>
      <Collapse
        in={selectedCountryId >= 0 && selectedRegionId === -1}
        timeout={"auto"}
      >
        <CountryForm countryId={selectedCountryId} />
      </Collapse>
      <Collapse in={selectedRegionId >= 0} timeout={"auto"}>
        <RegionForm />
      </Collapse>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CountriesTable);
