import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchOffDayTypes, setOffDayTypeId } from "redux/index";

import Fab from "@mui/material/Fab";
import makeStyles from "@mui/styles/makeStyles";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import OffDayTypesForm from "./OffDayTypesForm";

import ScreenTitle from "Components/ScreenTitle";
import OffDaysIcon from "@mui/icons-material/PublicTwoTone";

import StyledTableCell from "Components/StyledTableCell";
import { sortByColumn } from "Components/Utilities";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  TableContainer: {
    maxHeight: "79vh",
    marginBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  TableRow: {
    cursor: "pointer",
  },
  tableText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fixedCell: {
    whiteSpace: "nowrap",
    position: "sticky",
    left: "0",
    backgroundColor: theme.palette.background.table,
    color: theme.palette.text.main,
    zIndex: 100,
  },
  fixedHeaderCell: {
    whiteSpace: "nowrap",
    position: "sticky",
    left: "0",
    backgroundColor: theme.palette.background.table,
    color: theme.palette.text.main,
    zIndex: 101,
  },
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
}));

const mapStateToProps = (state) => {
  const loggedUserId = state.loggeduser.LoggedUser.id;
  const settings = state.calendarsettings;
  const offDayTypes = state.offdaytypes.OffDayTypes;
  const offDayTypeId = state.offdaytypes.offDayTypeId
  return {
    loggedUserId,
    settings,
    offDayTypes,
    offDayTypeId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffDayTypes: () => dispatch(fetchOffDayTypes()),
    setOffDayTypeId: (id) => dispatch(setOffDayTypeId(id)),
  };
};

const OffDayTypes = ({
  loggedUserId,
  settings,
  offDayTypes,
  offDayTypeId,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("offDayType");

    useEffect(() => {
      props.fetchOffDayTypes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const language = localStorage.getItem("lang");
  const [displayTypeName, setDisplayTypeName] = useState("");

  const columns = [
    {
      name: "offDayType",
      label: t("Designation"),
    },
    {
      label: t("Identifier"),
      name: "offDayKey",
      align: "center",
    },
    {
      label: t("Vacation days"),
      name: "offDayFactor",
      align: "center",
    },
    {
      label: t("Approvement necessary"),
      name: "mustBeApproved",
      align: "center",
    },
    {
      label: t("Plannable"),
      name: "mayBePlaned",
      align: "center",
    },
    {
      label: t("Registration necessary"),
      name: "mustReport",
      align: "center",
    },
    {
      label: t("Internal"),
      name: "notForUser",
      align: "center",
    },
  ];

  const rows = offDayTypes?.map((ODT, index) => ({
    key: index,
    id: ODT.id,
    names: ODT.names,
    offDayType: (
      <div className={classes.tableText}>
        {ODT.names.find((OTN) => OTN.language === language).offDayType}
      </div>
    ),
    offDayKey: (
      <div className={classes.tableText}>
        {ODT.names.find((OTN) => OTN.language === language).offDayKey}
      </div>
    ),
    offDayFactor: ODT.offDayFactor,
    mustBeApproved: ODT.mustBeApproved ? <CheckCircleIcon /> : "",
    mayBePlaned: ODT.mayBePlaned ? <CheckCircleIcon /> : "",
    mustReport: ODT.mustReport ? <CheckCircleIcon /> : "",
    notForUser: ODT.notForUser ? <CheckCircleIcon /> : "",
  }));

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
  };

  const sortedRows = () => {
    return rows.sort(
      sortByColumn(
        order === "desc" ? "-" + orderBy : orderBy,
        orderBy === "nextDate" ? "date" : ""
      )
    );
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "new":
        props.setOffDayTypeId(0);
        setDisplayTypeName("");
        break;
      default:
    }
  };

  return (
    <div className="content">
      <Paper elevation={8}>
        <ScreenTitle
          title={t("Settings: absence types")}
          class="settings"
          icon={
            <OffDaysIcon
              viewBox="0 0 24 24"
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <Collapse in={offDayTypeId < 0} timeout={"auto"}>
          <div className={classes.TableContainer}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.name}
                        align={column.align}
                        className={column.hidden ? "hidden" : ""}
                      >
                        <TableSortLabel
                          active={orderBy === column.name}
                          direction={orderBy === column.name ? order : "asc"}
                          onClick={() => handleSort(column.name)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRows().map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => {
                          props.setOffDayTypeId(row.id);
                          setDisplayTypeName(row.offDayType.props.children);
                        }}
                      >
                        {columns.map((column) => {
                          const value = row[column.name];
                          return (
                            <StyledTableCell
                              key={column.name}
                              align={column.align}
                              className={column.hidden ? "hidden" : ""}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Fab
            variant="extended"
            color="primary"
            size="medium"
            className={classes.fab}
            onClick={() => handleButtonClick("new")}
          >
            <AddIcon />
            {t("New absence type")}
          </Fab>
        </Collapse>
        <Collapse in={offDayTypeId >= 0} timeout={"auto"}>
          <OffDayTypesForm displayName={displayTypeName} />
        </Collapse>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OffDayTypes);
