import React from "react";
import Slider from "@mui/material/Slider";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    padding: "0 20px"
  },
  normal: {
    "&>span": {
      fontStyle: "normal",
      color: theme.palette.text.main,
    },
  },
  slider: {
    padding: "0 10px",
    color: (props) => `${props.color}`,
    backgroundColor: theme.palette.disabled
  },
}));

const SomuraSlider = ({
  labelLeft,
  labelRight,
  name,
  disabled,
  value,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <div className={classes.sliderWrapper}>
      <span>{(props.label)}</span>
      <Slider
        className={classes.slider}
        value={value}
        onChange={props.onChange}
        valueLabelDisplay="auto"
        valueLabelFormat={props.valueLabelFormat}
        step={props.step}
        marks={props.marks}
        min={props.min}
        max={props.max}
      />
    </div>
  );
};

export default SomuraSlider;
