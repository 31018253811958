import React from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  segment: {
    width: "100%",
    height: "100%",
  },
  dayFrame: {
    width: "100%",
    height: "3.5vh",
    border: "1px solid " + theme.palette.background.calendarDayFrame,
  },
  today: {
    gridColumn: "1 / 5",
    gridRow: "1 / 6",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  animatedTodayIcon: {
    animation: "$glowing 2600ms infinite",
  },
  todayFrame: {
    borderTop: (props) => `2px solid ${props.markercolor}`,
    borderLeft: (props) => `2px solid ${props.markercolor}`,
    borderRight: (props) => `2px solid ${props.markercolor}`,
  },
  birthday: {
    gridColumn: "4",
    gridRow: "1",
    display: "flex",
    justifyContent: "end",
    alignContent: "end",
    position: "relative",
  },
  calendarweek: {
    gridColumn: "1",
    gridRow: "1",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "relative",
    marginTop: "4px",
    fontSize: "0.6rem",
    color: "black",
  },
  toolTipTitle: {
    color: "#243573",
    fontSize: "1rem",
    backgroundColor: "#E0E0E0",
    padding: "2px 8px",
  },
  subLine: {
    fontSize: "1rem",
    margin: "1px 8px",
    padding: "0px 4px",
    borderRadius: "0.5rem",
  },
  unpublished: {
    backgroundImage:
      "linear-gradient(90deg, transparent 50%, rgba(255,255,255,1) 50%)",
    backgroundSize: "4px 2px",
  },
  "@keyframes glowing": {
    "0%": { transform: "perspective(300px) rotateY(0deg)" },
    "25%": { transform: "perspective(300px) rotateY(90deg)" },
    "50%": { transform: "perspective(300px) rotateY(180deg)" },
    "75%": { transform: "perspective(300px) rotateY(270deg)" },
    "100%": { transform: "perspective(300px) rotateY(360deg)" },
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    startId: state.yearcalendar.startId,
    endId: state.yearcalendar.endId,
  };
};

function CalendarDayFrame(props) {
  const classes = useStyles(props);

  function handleMouseEnter(event) {
    const element = event.currentTarget;
    var rect = element.getBoundingClientRect();
    const TTelement = document.getElementById("TT");
    if (TTelement) {
      TTelement.style.top = rect.top + 10 + "px";
      TTelement.style.left = rect.left + "px";
    }
    store.dispatch({
      type: "SET_DAY_INFO",
      payload: { date: props.date, tooltipTexts: props.tooltipTexts },
    });
    if (event.button === 0 && props.ismarkable) {
      props.onMouseEnter(element.id);
    }
  }

  function handleMouseLeave() {
    store.dispatch({ type: "SET_DAY_INFO", payload: {} });
  }

  const segments = () => {
    let colorSegments = [];
    for (var i = 0; i < 8; i++) {
      colorSegments.push(
        <Box
          sx={{
            gridArea: "segment" + i,
            backgroundColor: props.segmentColor[i][0],
          }}
          key={i}
        ></Box>
      );
    }
    return colorSegments;
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "3.3vh",
        backgroundColor: props.daycolor,
        // borderLeft: props.today ? "2px solid " + props.todayColor : "",
        // borderRight: props.today ? "2px solid " + props.todayColor : "",
        // borderTop: props.today ? "2px solid " + props.todayColor : "",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "3.5vh",
          backgroundColor: props.backColor,
        }}
        id={"d" + props.id}
        date={props.date}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          sx={{
            height: "100%",
            display: "grid",
            gridTemplateColumns: "15% 35% 35% 15%",
            gridTemplateRows: "15% 55% 15% 15%",
            gridTemplateAreas: `"segment6 segment6 segment7 segment7"
                                "segment4 segment4 segment5 segment5"
                                "segment2 segment2 segment3 segment3"
                                "segment0 segment0 segment1 segment1"`,
            userSelect: "none",
          }}
          date={props.date}
        >
          <Box
            component="span"
            sx={{
              gridArea: "1 / 2 / 5 / 4",
              justifySelf: "center",
              alignSelf: "center",
              zIndex: "10",
              fontWeight: "normal",
              fontSize: "14px",
              color: props.textColor,
            }}
          >
            {props.id}
          </Box>
          <>{segments(props)}</>
          {props.today && (
            <div className={classes.today}>
              <svg
                className={
                  props.todayAnimated
                    ? classes.animatedTodayIcon
                    : classes.todayIcon
                }
                height="2rem"
                viewBox="0 0 24 24"
              >
                <path fill={props.markercolor} d={props.todayIcon} />
              </svg>
            </div>
          )}
          {props.isbirthday && (
            <Box
              sx={{
                gridArea: "1 / 3 / 2 / 4",
                marginLeft: "-2px",
                width: "1rem",
                height: "1rem",
              }}
            >
              <svg viewBox="0 0 24 24">
                <path fill={props.birthdayColor} d={props.birthdayIcon} />
              </svg>
            </Box>
          )}
          {props.week && (
            <Box
              sx={{
                gridArea: "segment6",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                position: "relative",
                marginTop: "0.2rem",
                fontSize: "0.6rem",
                color: "black",
              }}
            >
              {props.week}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default connect(mapStateToProps, null)(CalendarDayFrame);
