import React from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import makeStyles from "@mui/styles/makeStyles";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import BlurOnTwoToneIcon from "@mui/icons-material/BlurOnTwoTone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  segment: {
    width: "100%",
    height: "100%",
  },
  dayFrame: {
    width: "100%",
    height: "3.5vh",
    border: "1px solid " + theme.palette.background.calendarDayFrame,
  },
  today: {
    gridColumn: "1 / 5",
    gridRow: "1 / 6",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  animatedTodayIcon: {
    animation: "$glowing 2600ms infinite",
  },
  inactive: {
    gridColumn: "1 / 5",
    gridRow: "1 / 6",
    display: "flex",
    justifySelf: "center",
    alignSelf: "center",
    color: "red",
  },
  birthday: {
    gridColumn: "4",
    gridRow: "1",
    display: "flex",
    justifyContent: "end",
    alignContent: "end",
    position: "relative",
  },
  calendarweek: {
    gridColumn: "1",
    gridRow: "1",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "relative",
    marginTop: "6px",
    fontSize: "0.6rem",
    color: "black",
  },
  toolTipTitle: {
    color: "#243573",
    fontSize: "1rem",
    backgroundColor: "#E0E0E0",
    padding: "2px 8px",
  },
  subLine: {
    fontSize: "1rem",
    margin: "1px 8px",
    padding: "0px 4px",
    borderRadius: "0.5rem",
  },
  additionalLine: {
    fontSize: "0.6rem",
    lineHeight: "0.6rem",
    paddingBottom: "2px",
  },
  unpublished: {
    backgroundImage:
      "linear-gradient(90deg, transparent 50%, rgba(255,255,255,1) 50%)",
    backgroundSize: "4px 2px",
  },
  "@keyframes glowing": {
    "0%": { transform: "perspective(300px) rotateY(0deg)" },
    "25%": { transform: "perspective(300px) rotateY(90deg)" },
    "50%": { transform: "perspective(300px) rotateY(180deg)" },
    "75%": { transform: "perspective(300px) rotateY(270deg)" },
    "100%": { transform: "perspective(300px) rotateY(360deg)" },
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    startId: state.yearcalendar.startId,
    endId: state.yearcalendar.endId,
  };
};

function CalendarDayFrame({ date, username, tooltipTexts, ...props }) {
  const classes = useStyles(props);

  function handleMouseDown(event) {
    const element = event.currentTarget;
    if (element.id?.substring(0, 1) === "d") {
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
      if (event.button === 0 && props.ismarkable) {
        props.onMouseDown(element.id);
      }
      if (event.button === 2) {
        store.dispatch({
          type: "SHOW_CONTEXT_MENU",
          payload: { element: element.id, date: element.getAttribute("date") },
        });
      }
    }
    // }
    // props.onResetSelection();
  }

  function handleMouseUp(event) {
    if (event.button === 0 && props.ismarkable) {
      props.onMouseUp(event.currentTarget.id);
    }
  }

  function handleMouseEnter(event) {
    const element = event.currentTarget;
    var rect = element.getBoundingClientRect();
    const TTelement = document.getElementById("TT");
    if (TTelement) {
      TTelement.style.top = rect.top + 10 + "px";
      TTelement.style.left = rect.left + "px";
    }
    if (props.showtooltip) {
      store.dispatch({
        type: "SET_DAY_INFO",
        payload: { date: date, user: username, tooltipTexts: tooltipTexts },
      });
    }
    props.onHover(props.reminders, date);
    if (event.button === 0 && props.ismarkable) {
      props.onMouseEnter(element.id);
    }
  }

  function handleMouseLeave(event) {
    store.dispatch({ type: "SET_DAY_INFO", payload: {} });
  }

  const segments = (props) => {
    let colorSegments = [];
    for (var i = 0; i < 8; i++) {
      let color =
        props.segmentColor[i][0] === "" ? "" : props.segmentColor[i][0];
      let published = props.segmentColor[i][1];
      let row;
      let column;
      switch (i) {
        case 0:
          row = "5";
          column = "1 / 3";
          break;
        case 1:
          row = "5";
          column = "3 / 5";
          break;
        case 2:
          row = "4";
          column = "1 / 3";
          break;
        case 3:
          row = "4";
          column = "3 / 5";
          break;
        case 4:
          row = "3";
          column = "1 / 3";
          break;
        case 5:
          row = "3";
          column = "3 / 5";
          break;
        case 6:
          row = "1 / 3";
          column = "1 / 3";
          break;
        case 7:
          row = "1 / 3";
          column = "3 / 5";
          break;
        default:
      }
      colorSegments.push(
        <div
          key={i}
          className={
            classes.segment + " " + (published ? "" : classes.unpublished)
          }
          style={{
            gridRow: row,
            gridColumn: column,
            backgroundColor: color,
          }}
        ></div>
      );
    }
    return colorSegments;
  };

  return (
    <>
      <div
        className={classes.dayFrame + " day-container"}
        id={"d" + props.id}
        date={date}
        workdayfactor={props.workdayfactor}
        // onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div date={props.date} className={"date-frame"}>
          <span className="date-text" style={{ color: props.textColor }}>
            {props.specialdaykey ? props.specialdaykey : props.offdaykey}
          </span>
          {segments(props)}
          {props.id === props.startId && (
            <div className="date-arrow date-arrow-left">
              <ArrowRightIcon />
            </div>
          )}
          {props.id === props.endId && (
            <div className="date-arrow date-arrow-right">
              <ArrowLeftIcon />
            </div>
          )}
          {props.reminderStartEnd && (
            <div className="remind-arrow">
              <ArrowDropDownIcon />
            </div>
          )}
          {props.reminderPrev && <div className="remind-bar-prev" />}
          {props.reminderNext && <div className="remind-bar-next" />}
          {props.today && (
            <div className={classes.today}>
              <svg
                className={
                  props.todayAnimated
                    ? classes.animatedTodayIcon
                    : classes.todayIcon
                }
                height="2rem"
                viewBox="0 0 24 24"
              >
                <path fill={props.markercolor} d={props.todayIcon} />
              </svg>
            </div>
          )}
          {props.isbirthday && (
            <div className={classes.birthday}>
              <svg
                className="birthdayIcon"
                width="14"
                height="14"
                viewBox="0 0 22 22"
              >
                <path fill={props.birthdayColor} d={props.birthdayIcon} />
              </svg>
            </div>
          )}
          {props.week && (
            <div className={classes.calendarweek}>{props.week}</div>
          )}
          {props.inactive && (
            <div className={classes.inactive}>
              <BlurOnTwoToneIcon />
            </div>
          )}
        </div>
        <div
          id={"o" + props.id}
          className={
            "date-overlay " +
            (props.highlight ? "highlight-approvement " : "") +
            (props.highlight ||
            (props.id >= props.startId && props.id <= props.endId)
              ? ""
              : classes.hidden)
          }
        ></div>
      </div>
      {/* </SomuraTooltip> */}
      {/* )} */}
      {/* // </div> */}
    </>
  );
}

export default connect(mapStateToProps, null)(CalendarDayFrame);
