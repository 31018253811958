import React from "react";
import { setCovStateId } from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { sortByDate } from "Components/Utilities";
import SomuraDataGrid from "Components/SomuraDataGrid";

const mapStateToProps = (state) => {
  const persons = state.persons.PersonsMin;
  const personId = state.persons.personId;
  const covStates = state.persons.Person.covstates;
  return { persons, personId, covStates };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCovStateId: (id) => dispatch(setCovStateId(id)),
  };
};

function CovStatesTable({
  persons,
  covStates,
  setCovStateId,
}) {
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
    },
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "userId",
      hide: true,
    },
    {
      headerName: t("State"),
      headerClassName: "tableHeader",
      field: "state",
      renderCell: (params) => {
        switch (params.value) {
          case "1":
            return t("Vaccinated");
          case "2":
            return t("Recovered");
          case "3":
            return t("Tested");
          default:
            return t("Unknown");
        }
      },
      filterable: false,
      sortable: false,
      flex: 0.2,
    },
    {
      headerName: t("State as of"),
      headerClassName: "tableHeader",
      field: "proofDate",
      filterable: false,
      sortable: false,
      flex: 0.2,
    },
    {
      headerName: t("Proved on"),
      headerClassName: "tableHeader",
      field: "proofSubmitted",
      filterable: false,
      sortable: false,
      flex: 0.2,
    },
    {
      headerName: t("Controlled by"),
      headerClassName: "tableHeader",
      field: "controlledById",
      renderCell: (params) => {
        return persons.find((P) => P.id === params.value)?.listName;
      },
      filterable: false,
      sortable: false,
      flex: 0.2,
    },
    {
      headerName: t("Valid until"),
      headerClassName: "tableHeader",
      field: "validUntil",
      flex: 0.2,
      filterable: false,
      sortable: false,
    },
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "timeStamp",
      type: "date",
      sortComparator: (a, b) => sortByDate(a, b),
      hide: true,
    },
  ];

  const rows = covStates.map((CS, index) => ({
    id: CS.id,
    userId: CS.userId,
    state: CS.state,
    proofDate: moment(CS.proofDate).format("L"),
    proofSubmitted: moment(CS.proofSubmitted).format("L"),
    controlledById: CS.controlledById,
    validUntil: moment(CS.validUntil).isValid()
      ? moment(CS.validUntil).format("L")
      : "-",
    timeStamp: CS.timeStamp,
  }));

  const handleRowClick = (params) => {
    setCovStateId(params);
  };

  return (
    <SomuraDataGrid
      tableHeight={"calc(100vh - 420px)"}
      rows={rows}
      columns={columns}
      defaultSortModel={[
        {
          field: "timeStamp",
          sort: "desc",
        },
      ]}
      onRowClick={(params) => handleRowClick(params)}
      csvFileName={t("Covid state")}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CovStatesTable);
