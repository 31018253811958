import { connect } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import SumDayFrame from "./SumDayFrame";

const mapStateToProps = (state) => {
  return {
    monthStatic: state.month.MonthStatic,
    monthSums: state.month.MonthSums,
    companyName: state.company.Company.companyName,
  };
};

const CompanySumLine = ({
  workOnSaturday,
  monthStatic,
  monthSums,
  companyName,
}) => {
  const dayCells = () => {
    var days = [];
    monthStatic?.forEach(function (E) {
      var currentMoment = moment(E.date);
      days.push(
        <td key={"SC" + currentMoment.format("DDD")}>
          <SumDayFrame
            key={"C" + currentMoment.format("DDD")}
            numPersons={
              currentMoment.format("d") > 0 &&
              (currentMoment.format("d") < 6 || workOnSaturday)
                ? monthSums?.find(
                    (MS) => MS.date === currentMoment.format("YYYY-MM-DD")
                  )?.companyNumPersons
                : ""
            }
          />
        </td>
      );
    });
    return days;
  };

  return (
    <Box
      component="tr"
      sx={{
        height: "1.5rem",
        display: "grid",
        gridTemplateColumns: "repeat(32, 1fr)",
      }}
      key={"C"}
    >
      <Box
        component="td"
        sx={{
          fontSize: "0.8rem",
          minHeight: "1.4rem",
          width: "15vw",
          backgroundColor: "background.paperBottom",
          gridColumn: "1",
          alignSelf: "center",
          paddingLeft: "4px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        key={"CN"}
      >
        {companyName}
      </Box>
      {dayCells()}
    </Box>
  );
};

export default connect(mapStateToProps)(CompanySumLine);
