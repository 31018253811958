

import { utility } from "../api";
import { dataFailure, dataSuccess } from "./general";

export const runUtility = () => {
  return async (dispatch) => {
    try {
      await dispatch(utility());
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};