import {
  FETCH_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPLOAD_LOGO,
  FETCH_COMPANY_REQUEST,
  COMPANY_FAILURE
} from 'actions/types'
import produce from 'immer'

const initialState = {
  Company: {},
  loading: false, 
  error: ''
}

const companyReducer = (state = initialState, action) => 
  produce(state, draft => {
    switch (action.type) {
      case FETCH_COMPANY_REQUEST:
        draft.loading = true
        break
      case FETCH_COMPANY_SUCCESS: 
          draft.Company = action.payload
          draft.loading = false
          draft.error = ''
          return
      case COMPANY_FAILURE:
        draft.loading = false
        draft.error = action.payload
        break
      case UPDATE_COMPANY:
          draft.Company = action.payload
          return
      case UPLOAD_LOGO:
          draft.Company = {...draft.Company, companyLogo: action.payload}
          return
      default:
          return draft
    }
  })


export default companyReducer    