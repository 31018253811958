import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setSelectedArticleId,
  createArticle,
  updateArticle,
} from "redux/index";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import makeStyles from "@mui/styles/makeStyles";
import AddButton from "Components/Buttons/AddButton";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import moment from "moment";
import ModelDialog from "./ModelDialog";
import TypeDialog from "./TypeDialog";
import MovementsList from "./MovementsList";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
  location: {
    fontSize: "1.2rem",
    textAlign: "center",
  },
  movements: {
    textAlign: "center",
    height: "10.6rem",
    overflowY: "auto",
  },
  freeStock: {
    color: theme.palette.lightgreen,
  },
  double: {
    color: theme.palette.red,
    marginTop: "0.5rem"
  }
}));

const mapStateToProps = (state) => {
  const articles = state.articles.Articles;
  const types = state.articles.ArticleTypes;
  const models = state.articles.ArticleModels;
  const movementTypes = state.articles.MovementTypes;
  const locations = state.articles.Locations;
  const persons = state.persons.PersonsMin.filter(
    (P) => P.lastName !== "" && P.active === true
  );
  const selectedId = state.articles.selectedArticleId;
  const typeId = state.articles.selectedArticleTypeId;
  const modelId = state.articles.selectedArticleModelId;
  const selectedArticle = articles?.find((A) => A.id === selectedId);
  const movements = selectedArticle?.movements || [];
  const reasons = state.articles.Reasons;
  const states = state.articles.States;
  return {
    articles,
    types,
    models,
    movementTypes,
    locations,
    persons,
    selectedId,
    typeId,
    modelId,
    selectedArticle,
    movements,
    reasons,
    states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedId: (id) => dispatch(setSelectedArticleId(id)),
    create: (values) => dispatch(createArticle(values)),
    update: (values) => dispatch(updateArticle(values)),
  };
};

const ArticlesForm = ({
  articles,
  types,
  models,
  movementTypes,
  locations,
  persons,
  selectedId,
  typeId,
  modelId,
  setSelectedId,
  selectedArticle,
  movements,
  reasons,
  states,
  create,
  update,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const selectedArticle = articles.find((A) => A.id === selectedId);
  const firstMovementType = movementTypes.find(
    (MT) => MT.firstMovement === true
  );

  const emptyState = {
    id: "0",
    typeId: "0",
    articleTypeName: "",
    modelId: "0",
    modelName: "",
    locationId: "0",
    locationName: "",
    locationPerson: "",
    personId: "0",
    manufacturerSerialNumber: "",
    purchaseDate: moment().format("YYYY-MM-DD"),
    inventoryNumber: null,
    movements: movements,
    firstMovementTypeId: firstMovementType?.id,
    firstLocationId: firstMovementType?.defaultLocationId,
    needsPerson: false,
    needsLocation: false,
    nextMovementDate: moment().format("YYYY-MM-DD"),
    nextPersonId: "0",
    nextLocationId: "0",
    inventoryNumberRequired:
      types?.find((T) => T.id === selectedArticle?.typeId)
        ?.needsInventoryNumber || false,
  };
  const emptyMovementState = {
    movementDate: moment().format("YYYY-MM-DD"),
    movementTypeId: "0",
    movementTypeName: "",
    locationId: "0",
    locationName: "",
    isFreeStock: true,
    personId: "0",
    personName: "",
    reasonId: "0",
    stateId: "0",
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const [openTypeDialog, setOpenTypeDialog] = useState(false);
  const [needsPerson, setNeedsPerson] = useState(false);
  const [needsLocation, setNeedsLocation] = useState(false);
  const [firstMovement, setFirstMovement] = useState();
  const [nextMovement, setNextMovement] = useState(emptyMovementState);
  const [isDouble, setIsDouble] = useState(false)

  var typeOptions = types.map((T) => ({
    label: T.articleTypeName,
    value: T.id,
  }));
  typeOptions.unshift({ label: "", value: "0" });

  var modelOptions =
    models
      .filter((M) => M.articleTypeId === state.typeId)
      .map((M) => ({
        label: M.modelName,
        value: M.id,
      })) || [];
  modelOptions.unshift({ label: "", value: "0" });

  var movementOptions = movementTypes.map((MT) => ({
    label: MT.movementTypeName,
    value: MT.id,
    first: MT.firstMovement,
  }));
  movementOptions.unshift({ label: "", value: "0", first: false });

  var personOptions = persons.map((P) => ({
    label: P.listName,
    value: P.id,
  }));
  personOptions.unshift({ label: "", value: "0", first: false });

  var locationOptions = locations.map((L) => ({
    label: L.locationName,
    value: L.id,
  }));

  var reasonOptions = reasons.map((R) => ({
    label: R.reason,
    value: R.id,
  }));
  reasonOptions.unshift({ label: "", value: "0", first: false });

  var stateOptions = states.map((S) => ({
    label: S.status,
    value: S.id,
  }));
  stateOptions.unshift({ label: "", value: "0", first: false });

  const formHeader =
    selectedId > 0
      ? t("Article") +
        ": " +
        state.modelName +
        " " +
        state.manufacturerSerialNumber
      : t("New article");

  const handleClose = () => {
    setSelectedId(-1);
  };

  const handleSubmit = () => {
    if (selectedId === 0) {
      // Create new article
      create({ ...state, firstMovement, nextMovement });
    } else {
      // Update article
      update({ ...state, nextMovement });
    }
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "inventoryNumber"){
      setIsDouble(articles.findIndex(A => A.inventoryNumber === value) >= 0)
    }
      setState({
        ...state,
        [name]: value,
      });
  };

  const handleArticleTypeChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({
      ...state,
      [name]: value,
      articleTypeName:
        value > 0 ? types.find((T) => T.id === value).articleTypeName : "",
      inventoryNumberRequired:
        types.find((T) => T.id === value)?.needsInventoryNumber || false,
    });
    modelOptions = models
      .filter((M) => M.articleTypeId === value)
      .map((M) => ({
        label: M.modelName,
        value: M.id,
      }));
    modelOptions.unshift({ label: "", value: "0" });
  };

  const handleArticleModelChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({
      ...state,
      [name]: value,
      modelName: value > 0 ? models.find((M) => M.id === value).modelName : "",
    });
  };

  const handleFirstMovementChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({ ...state, [name]: value });
  };

  const handleFirstLocationChange = (params) => {
    const value = params.value === "" ? "0" : params.value;
    const location = locations.find((L) => L.id === value);
    setState({
      ...state,
      firstLocationId: value,
      firstLocationName: location.locationName,
    });
    setFirstMovement({
      ...firstMovement,
      locationId: value,
      locationName: location.locationName,
      isFreeStock: location.isFreeStock,
    });
  };

  const handleNextMovementChange = (params) => {
    const value = params.value === "" ? "0" : params.value;
    const movementType = movementTypes.find((M) => M.id === value);
    const location = locations.find(
      (L) => L.id === movementType?.defaultLocationId
    );
    setNextMovement({
      movementDate: moment().format("YYYY-MM-DD"),
      movementTypeId: value,
      movementTypeName: movementType.movementTypeName,
      locationId: movementType.defaultLocationId,
      locationName: location.locationName,
      isFreeStock: location.isFreeStock,
      personId: "0",
      reasonId: "0",
      stateId: "0",
    });
    setState({
      ...state,
      locationId: movementType.defaultLocationId,
      needsPerson: movementType.needsUser,
      needsLocation: movementType.needsLocation,
      personId: "0",
    });
    setNeedsLocation(movementType.needsLocation);
    setNeedsPerson(movementType.needsUser);
  };

  const handleNextDateChange = (event) => {
    const value = event.target.value;
    setState({
      ...state,
      nextMovmentDate: value,
    });
    setNextMovement({
      ...nextMovement,
      movementDate: value,
    });
  };
  const handleNextPersonChange = (params) => {
    const value = params.value === "" ? "0" : params.value;
    const personName = persons.find((P) => P.id === value)?.listName;
    setState({
      ...state,
      nextPersonId: value,
      personId: value,
      locationPerson: personName,
    });
    setNextMovement({
      ...nextMovement,
      personId: value,
      personName: personName,
    });
  };

  const handleNextLocationChange = (params) => {
    const value = params.value === "" ? "0" : params.value;
    const location = locations.find((L) => L.id === value);
    setState({
      ...state,
      nextLocationId: value,
      locationName: location.locationName,
    });
    setNextMovement({
      ...nextMovement,
      locationId: value,
      locationName: location.locationName,
      isFreeStock: location.isFreeStock,
    });
  };

  const handleNextReasonChange = (params) => {
    const value = params.value;
    setNextMovement({
      ...nextMovement,
      reasonId: value,
    });
  };

  const handleNextStateChange = (params) => {
    const value = params.value;
    setNextMovement({
      ...nextMovement,
      stateId: value,
    });
  };

  const articleSchema = Yup.object().shape({
    inventoryNumberRequired: Yup.boolean(),
    typeId: Yup.number().min(1).required(t("Required")),
    modelId: Yup.number().min(1).required(t("Required")),
    inventoryNumber: Yup.string().when("inventoryNumberRequired", {
      is: true,
      then: Yup.string().required(t("Required")),
      otherwise: Yup.string().nullable(true),
    }),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await articleSchema.isValid(state));
  });

  useEffect(() => {
    setState({
      ...state,
      typeId: typeId,
      articleTypeName: types?.find((T) => T.id === typeId)?.articleTypeName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId]);

  useEffect(() => {
    setState({
      ...state,
      modelId: modelId,
      modelName: models?.find((M) => M.id === modelId)?.modelName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setNextMovement(emptyMovementState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedId > 0) {
      setState({
        ...selectedArticle,
      });
    } else {
      setState(emptyState);
    }
    setNextMovement(emptyMovementState);
    setNeedsLocation(false);
    setNeedsPerson(false);
    setIsDouble(false)
    if (selectedId === 0) {
      setFirstMovement({
        movementDate: moment().format("YYYY-MM-DD"),
        movementTypeId: firstMovementType?.id,
        movementTypeName: firstMovementType.movementTypeName,
        locationId: firstMovementType?.defaultLocationId,
        locationName: locations.find(
          (L) => L.id === firstMovementType?.defaultLocationId
        ).locationName,
        isFreeStock: true,
        personId: "0",
        personName: "",
        reasonId: "0",
        stateId: "0",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <SomuraAutocomplete
                  name="typeId"
                  label={t("Article type")}
                  options={typeOptions}
                  onChange={(values) => handleArticleTypeChange(values)}
                  value={state.typeId}
                  disabled={selectedId > 0}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                />
              </Grid>
              {selectedId === 0 && (
                <Grid item xs={2}>
                  <AddButton onClick={() => setOpenTypeDialog(true)} />
                </Grid>
              )}
              <Grid item xs={10}>
                <SomuraAutocomplete
                  name="modelId"
                  label={t("Model")}
                  options={modelOptions}
                  onChange={(values) => handleArticleModelChange(values)}
                  value={state.modelId}
                  disabled={selectedId > 0}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                />
              </Grid>
              {selectedId === 0 && (
                <Grid item xs={2}>
                  <AddButton onClick={() => setOpenModelDialog(true)} />
                </Grid>
              )}
              <Grid item xs={12}>
                <SomuraTextField
                  label={t("Manufacturer serial number")}
                  type="text"
                  name="manufacturerSerialNumber"
                  value={state.manufacturerSerialNumber}
                  maxlength="50"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SomuraTextField
                  label={t("Purchase date")}
                  type="date"
                  name="purchaseDate"
                  value={state.purchaseDate}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraTextField
                  label={t("Inventory number")}
                  type="text"
                  name="inventoryNumber"
                  value={state.inventoryNumber}
                  maxlength="50"
                  onChange={handleChange}
                  required={state.inventoryNumberRequired}
                />
              </Grid>
              <Grid item xs={6}>
                {isDouble && (
                  <div className={classes.double}>
                    {t("Number already exists") + "!"}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className={"subLine"}>{t("Location")}</div>
                <div
                  className={[
                    classes.location,
                    movements[0]?.isFreeStock ? classes.freeStock : "",
                  ].join(" ")}
                >
                  {movements[0]?.personName
                    ? movements[0]?.personName
                    : movements[0]?.locationName
                    ? movements[0]?.locationName
                    : t("Unknown location")}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={"subLine"}>{t("Condition")}</div>
                <div className={classes.location}>
                  {movements[0]?.stateId !== "0"
                    ? states?.find((S) => S.id === movements[0]?.stateId)
                        ?.status
                    : t("Unknown condition")}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Movements")}</div>
                <div className={classes.movements}>
                  <MovementsList articleId={selectedId} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {parseInt(selectedId) < 1 && (
                    <>
                      <Grid item xs={6}>
                        {firstMovementType ? (
                          <SomuraTextField
                            label={t("First movement")}
                            type="text"
                            name="firstMovementType"
                            value={firstMovementType.movementTypeName}
                            disabled={true}
                          />
                        ) : (
                          <SomuraAutocomplete
                            name="firstMovementTypeId"
                            label={t("First movement")}
                            options={movementOptions}
                            onChange={(values) =>
                              handleFirstMovementChange(values)
                            }
                            value={state.firstMovementTypeId}
                            // disabled={modelOptions.length <= 1}
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {firstMovementType ? (
                          <SomuraTextField
                            label={t("First location")}
                            type="text"
                            name="firstLocation"
                            value={firstMovement?.locationName}
                            disabled={true}
                          />
                        ) : (
                          <SomuraAutocomplete
                            name="firstLocationId"
                            label={t("First location")}
                            options={locationOptions}
                            onChange={(values) =>
                              handleFirstLocationChange(values)
                            }
                            value={state.firstLocationId}
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                          />
                        )}
                      </Grid>
                    </>
                  )}
                  {/* </Grid> */}
                  <Grid item xs={6}>
                    <SomuraAutocomplete
                      name="movementTypeId"
                      label={t("Next movement")}
                      options={movementOptions.filter((MO) => !MO.first)}
                      onChange={(values) => handleNextMovementChange(values)}
                      value={nextMovement.movementTypeId}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  {nextMovement.movementTypeId !== "0" && (
                    <Grid item xs={3}>
                      <SomuraTextField
                        label={t("Date")}
                        type="date"
                        name="nextMovementDate"
                        value={nextMovement.movementDate}
                        onChange={handleNextDateChange}
                        required
                      />
                    </Grid>
                  )}
                  {needsPerson && (
                    <Grid item xs={3}>
                      <SomuraAutocomplete
                        name="nextPersonId"
                        label={t("Person")}
                        options={personOptions}
                        onChange={(values) => handleNextPersonChange(values)}
                        value={nextMovement.personId}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required={needsPerson}
                      />
                    </Grid>
                  )}
                  {needsLocation && (
                    <Grid item xs={3}>
                      <SomuraAutocomplete
                        name="nextLocationId"
                        label={t("Location")}
                        options={locationOptions}
                        onChange={(values) => handleNextLocationChange(values)}
                        value={nextMovement.locationId}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required={needsLocation}
                      />
                    </Grid>
                  )}
                  {nextMovement.movementTypeId !== "0" && (
                    <>
                      <Grid item xs={3}>
                        <SomuraAutocomplete
                          name="reasonId"
                          label={t("Reason")}
                          options={reasonOptions}
                          onChange={(values) => handleNextReasonChange(values)}
                          value={nextMovement.reasonId}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SomuraAutocomplete
                          name="stateId"
                          label={t("State")}
                          options={stateOptions}
                          onChange={(values) => handleNextStateChange(values)}
                          value={nextMovement.stateId}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton onClick={handleCancel} />
        <SaveButton onClick={handleSubmit} disabled={!valid || isDouble} />
      </div>
      {openModelDialog && (
        <ModelDialog
          typeId={state.typeId}
          close={() => setOpenModelDialog(false)}
        />
      )}
      {openTypeDialog && <TypeDialog close={() => setOpenTypeDialog(false)} />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesForm);
