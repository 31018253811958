import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { store } from "redux/store";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DashboardItem from "Components/DashboardItem";
import ThumbsUpDownTwoToneIcon from "@mui/icons-material/ThumbsUpDownTwoTone";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontSize: "1rem",
    color: theme.palette.dashBoard.title,
  },
  tableCell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const mapStateToProps = (state) => {
  return {
    data: state.dashboard.Dashboard.openApprovements,
    absenceTypes: state.offdaytypes.OffDayTypes,
  };
};

const OpenApprovements = ({ data, absenceTypes, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const language = localStorage.getItem("lang");

  const value = data?.length;

  const noData = value === 0 ? t("No open approvements.") : "";

  const openYearCalendar = (rowData) => {
    store.dispatch({
      type: "SET_DISPLAY_USER",
      payload: rowData.userId,
    });
    store.dispatch({
      type: "SET_DISPLAY_APPROVEMENT",
      payload: rowData.id,
    });
    history.push(`/YearCalendar`);
  };

  const OpenTableRow = (props) => {
    const rowData = props.rowData
    if (absenceTypes) {
      const absenceType =
        absenceTypes &&
        absenceTypes.find((AT) => AT.id === rowData.offDayTypeId);
      const absenceName =
        absenceType &&
        absenceType.names.find((ATN) => ATN.language === language).offDayType;
      return (
        <TableRow key={rowData.id} onClick={() => openYearCalendar(rowData)}>
          <TableCell className={classes.tableCell}>
            {rowData.lastName + ", " + rowData.firstName}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {rowData.requestDate}
          </TableCell>
          <TableCell className={classes.tableCell}>{absenceName}</TableCell>
          <TableCell className={classes.tableCell}>
            {rowData.startDate}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {rowData.endDate}
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {rowData.numDays}
          </TableCell>
        </TableRow>
      );
    }
  };

  const dataTable = () => {
    if (value > 0) {
      return (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>
                {t("Applicant")}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t("Requested")}
              </TableCell>
              <TableCell className={classes.tableHeader}>{t("Type")}</TableCell>
              <TableCell className={classes.tableHeader}>
                {t("Start")}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t("End")}
              </TableCell>
              <TableCell className={classes.tableHeader} align="right">
                {t("Days")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <OpenTableRow rowData={row} key={index}/>
            ))}
          </TableBody>
        </Table>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <DashboardItem
      title={t("Open approvements")}
      value={value}
      iconColor="#90A4AE"
      icon={<ThumbsUpDownTwoToneIcon style={{ viewBox: "0 0 100 100" }} />}
      table={dataTable()}
      nodata={noData}
    />
  );
};

export default connect(mapStateToProps, null)(OpenApprovements);
