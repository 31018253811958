import {
  FETCH_PERSON_REQUEST,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSONS_MIN_REQUEST,
  FETCH_PERSONS_MIN_SUCCESS,
  UPDATE_PERSON,
  ADD_PERSON,
  PERSONS_FAILURE,
  SET_PERSON_ID,
} from "./types";

import {
  person,
  personsMinimal,
  personsUpdate,
  personsAdd,
} from "../api";
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";

const personsFailure = (err) => {
  return {
    type: PERSONS_FAILURE,
    payload: err,
  };
};

const fetchPersonRequest = () => {
  return {
    type: FETCH_PERSON_REQUEST,
  };
};

const fetchPersonsMinRequest = () => {
  return {
    type: FETCH_PERSONS_MIN_REQUEST,
  };
};

const fetchPersonSuccess = (values) => {
  return {
    type: FETCH_PERSON_SUCCESS,
    payload: values,
  };
};

const fetchPersonsMinSuccess = (Persons) => {
  return {
    type: FETCH_PERSONS_MIN_SUCCESS,
    payload: Persons,
  };
};

export const fetchPerson = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchPersonRequest());
    try {
      const personsJSONData = await dispatch(person(values));
      await dispatch(fetchPersonSuccess(personsJSONData));
      dispatch(dataEndLoading())
    } catch (err) {
      await dispatch(personsFailure(err));
      dispatch(dataEndLoading())
    }
  };
};

export const fetchPersonsMinimal = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchPersonsMinRequest());
    try {
      const personsJSONData = await dispatch(personsMinimal());
      await dispatch(fetchPersonsMinSuccess(personsJSONData));
      dispatch(dataEndLoading())
    } catch (err) {
      await dispatch(personsFailure(err));
      dispatch(dataEndLoading())
    }
  };
};

const updatePersonData = (values) => {
  return {
    type: UPDATE_PERSON,
    payload: values,
  };
};

export const updatePerson = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personsUpdate(values));
      await dispatch(updatePersonData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(personsFailure(err));
      await dispatch(dataFailure());
    }
  };
};

const addPersonData = (values) => {
  return {
    type: ADD_PERSON,
    payload: values,
  };
};

export const addPerson = (values) => {
  return async (dispatch) => {
    try {
      const personsJSONData = await dispatch(personsAdd(values));
      await dispatch(addPersonData({ ...values, id: personsJSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(personsFailure(err));
      await dispatch(dataFailure());
    }
  };
};

export const setPersonId = (id) => {
  return {
    type: SET_PERSON_ID,
    payload: id,
  };
};

