import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import UnauthenticatedRoute from "./Components/UnauthenticatedRoute";

import SignIn from "SignIn/SignIn";
import MainMenu from "MainMenu/MainMenu";
import Dashboard from "Dashboard/Dashboard";

import MonthCalendar from "Calendars/MonthCalendar/MonthCalendar";
import YearCalendar from "Calendars/YearCalendar/YearCalendar";
import Persons from "MasterData/Persons/Persons";
import TimeTracking from "MasterData/TimeTracking/TimeTracking";
import OffDayTypes from "Settings/OffDayTypes/OffDayTypes";
import Messages from "Messages/Messages";
import CompanySettings from "Settings/Company/Company";
import Addresses from "Settings/Addresses/Addresses";
import CalendarSettings from "Settings/Calendar/Calendar";
import ArticleSettings from "Settings/Articles/Articles";
import GlobalSettings from "Settings/GlobalSettings/GlobalSettings";
import TimeTrackingSettings from "Settings/TimeTracking/TimeTrackingSettings";
import PersonalSettings from "Settings/PersonalSettings/PersonalSettings";
import Reminders from "Reminders/Reminders";
import Test from "MainMenu/Test";
import Person from "Person/Person";
import PersonalTimeTracking from "Person/TimeTracking";

const Routes = ({ childProps }) => {
  return (
    <Switch>
      <AuthenticatedRoute
        path="/"
        exact
        component={MainMenu}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Dashboard"
        exact
        component={Dashboard}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/MonthCalendar"
        exact
        component={MonthCalendar}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/YearCalendar"
        exact
        component={YearCalendar}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Persons"
        exact
        component={Persons}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/TimeTracking"
        exact
        component={TimeTracking}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Messages"
        exact
        component={Messages}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/CompanySettings"
        exact
        component={CompanySettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Addresses"
        exact
        component={Addresses}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/CalendarSettings"
        exact
        component={CalendarSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/OffDayTypes"
        exact
        component={OffDayTypes}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/TimeTrackingSettings"
        exact
        component={TimeTrackingSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/GlobalSettings"
        exact
        component={GlobalSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/PersonalSettings"
        exact
        component={PersonalSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/ArticleSettings"
        exact
        component={ArticleSettings}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Reminders"
        exact
        component={Reminders}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Test"
        exact
        component={Test}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Person"
        exact
        component={Person}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/Worktime"
        exact
        component={PersonalTimeTracking}
        props={childProps}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={SignIn}
        props={childProps}
      />
    </Switch>
  );
};

Routes.propTypes = {
  childProps: PropTypes.object,
};

export default Routes;
