import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setPersonalSettings } from "redux/index";
import makeStyles from "@mui/styles/makeStyles";
import { fetchMessages, fetchMessageFolders } from "redux/index";
import MailIcon from "@mui/icons-material/Mail";
import Paper from "@mui/material/Paper";
import ScreenTitle from "Components/ScreenTitle";
import { Grid } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import MessageFolders from "./MessageFolders";
import FolderMessages from "./FolderMessages";
import MessageFrame from "./MessageFrame";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: "0 10px"
  },
  columns: {
    width: (showFolders) => showFolders ? "calc(100% - 16rem)" : "calc(100% - 2rem)",
    display: "grid",
    gridTemplateColumns: "18rem 1fr",
  },
}));
const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;
  const foldersPinned = state.settings.PersonalSettings
    ?.find((PS) => PS.component === "messages")
    ?.settings["foldersPinned"]?.toString();
  return {
    loggedUser,
    foldersPinned,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMessages: (id) => dispatch(fetchMessages({ userId: id })),
    fetchMessageFolders: (id) => dispatch(fetchMessageFolders({ userId: id })),
    setPersonalSettings: (values) => dispatch(setPersonalSettings(values)),
  };
};

function Messages({
  loggedUser,
  foldersPinned,
  fetchPersons,
  fetchMessages,
  fetchMessageFolders,
  setPersonalSettings,
}) {
  // const classes = useStyles();
  const { t } = useTranslation();

  const [showFolders, setShowFolders] = useState(false);
  const [pinned, setPinned] = useState(false);
  const classes = useStyles(showFolders);

  useEffect(() => {
    fetchMessages(loggedUser.id);
    fetchMessageFolders(loggedUser.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (foldersPinned) {
      setPinned(foldersPinned === "1");
      setShowFolders(foldersPinned === "1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foldersPinned]);

  const handleDetachFolders = () => {
    setShowFolders(false);
    setPinned(false);
    setPersonalSettings({
      userId: loggedUser.id,
      component: "messages",
      key: "foldersPinned",
      value: "0",
      noSuccessMessage: true,
    });
  };

  const handleAttachFolders = () => {
    setShowFolders(true);
    setPinned(true);
    setPersonalSettings({
      userId: loggedUser.id,
      component: "messages",
      key: "foldersPinned",
      value: "1",
      noSuccessMessage: true,
    });
  };

  const handleMouseEnter = () => {
    setShowFolders(true);
  };

  const handleMouseLeave = () => {
    if (!pinned) {
      setShowFolders(false);
    }
  };

  return (
    <div className="content">
      <Paper square elevation={8} className="contentPaper">
        <ScreenTitle
          title={t("Messages")}
          class="messages"
          icon={
            <MailIcon
              viewBox="0 0 24 24"
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.container}>
            <Collapse
              orientation="horizontal"
              in={showFolders}
              collapsedSize={"3rem"}
            >
              <MessageFolders
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                show={showFolders}
                pinned={pinned}
                handleAttachFolders={handleAttachFolders}
                handleDetachFolders={handleDetachFolders}
              />
            </Collapse>
            <div className={classes.columns}>
              <FolderMessages style={{ gridColumn: "1" }} />
              <MessageFrame style={{ gridColumn: "2" }} />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
