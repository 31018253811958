import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPersonalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getPersonalSetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalSettings: (values) => dispatch(setPersonalSettings(values)),
  };
};

const DashboardForm = ({ setPersonalSettings, loggedUserId, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "dashboard";
  const emptyState = {
    showAbsenceStart: true,
    numEventDays: 30,
    hideEventsWhenZero: false,
    hideMessagesWhenZero: false,
  };
  const [state, setState] = useState(emptyState);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
    setPersonalSettings({
      userId: loggedUserId,
      component: component,
      key: name,
      value: value,
    });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked ? 1 : 0;
    setState({ ...state, [name]: value });
    setPersonalSettings({
      userId: loggedUserId,
      component: component,
      key: name,
      value: value,
    });
  };

  useEffect(() => {
    setState({
      showAbsenceStart: parseInt(
        getPersonalSetting(component, "showAbsenceStart")
      ),
      numEventDays: parseInt(getPersonalSetting(component, "numEventDays")),
      hideEventsWhenZero: parseInt(
        getPersonalSetting(component, "hideEventsWhenZero")
      ),
      hideMessagesWhenZero: parseInt(
        getPersonalSetting(component, "hideMessagesWhenZero")
      ),
    });
  }, []);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Events")}</div>
            <Grid container spacing={2}>
              <Grid item>
                <SomuraSwitchField
                  name="hideEventsWhenZero"
                  checked={state.hideEventsWhenZero === 1}
                  onChange={handleSwitchChange}
                  label={t("Hide when value is '0'")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <SomuraSwitchField
                  name="showAbsenceStart"
                  checked={state.showAbsenceStart === 1}
                  onChange={handleSwitchChange}
                  label={t("Show absence start")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  type="number"
                  inputProps={{ min: "5", max: "365", step: "5" }}
                  defaultValue={state.numEventDays}
                  onChange={handleChange}
                  name="numEventDays"
                  label={t("Number of days")}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Messages")}</div>
            <Grid container spacing={2}>
              <Grid item>
                <SomuraSwitchField
                  name="hideMessagesWhenZero"
                  checked={state.hideMessagesWhenZero === 1}
                  onChange={handleSwitchChange}
                  label={t("Hide when value is '0'")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardForm);
