import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE
} from 'actions/types'

import { dashboard } from '../api'
import {
  dataStartLoading,
  dataEndLoading,
} from "./general";

const dashboardFailure = (err) => {
  return {
    type: FETCH_DASHBOARD_FAILURE,
    payload: err
  }
}

const fetchDashboardRequest = () => {
  return {
    type: FETCH_DASHBOARD_REQUEST
  }
}

const fetchDashboardSuccess = (DashboardData) => {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    payload: DashboardData
  }
}
 
export const fetchDashboard = (id) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchDashboardRequest())
    try {
      const DashboardJSONData = await dispatch(dashboard(id))
      await dispatch(fetchDashboardSuccess(DashboardJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(dashboardFailure(err))
      dispatch(dataEndLoading())
    }
  }
}