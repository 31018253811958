import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPersonalDisplaySettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getDisplaySetting } from "Components/Utilities";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ColorExample from "Components/ColorExample";
import ToolTipExample from "Components/ToolTipExample";
import BackButton from "Components/Buttons/BackButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  back: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "3.5rem",
    marginBottom: "6px",
  },
  line: {
    paddingTop: "6px",
  },
  preview: {
    display: "grid",
    alignContent: "center",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    offDayTypes: state.offdaytypes.OffDayTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalDisplaySettings: (values) =>
      dispatch(setPersonalDisplaySettings(values)),
  };
};

const AbsenceSubForm = ({
  loggedUserId,
  offDayTypes,
  component,
  setPersonalDisplaySettings,
  closeSubform,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");

  const emptyState = {
    useBackgroundColor: 0,
    backgroundColor: "",
    backgroundFactor1: 0,
    backgroundFactor2: 0,
    useFontColor: 0,
    fontColor: "",
    fontFactor: 0,
    tooltipFontColor: 0,
    showAsBar: 1,
  };
  const [state, setState] = useState(emptyState);

  const absenceKey = offDayTypes
    .find((OT) => OT.id === component)
    ?.names.find((OTN) => OTN.language === language)?.offDayKey;
  const absenceName = offDayTypes
    .find((OT) => OT.id === component)
    ?.names.find((OTN) => OTN.language === language)?.offDayType;
  const mustBeApproved = offDayTypes.find(
    (OT) => OT.id === component
  )?.mustBeApproved;

  const handleChange = (event) => {
    const value = event.target.value;
    setState({ ...state, [event.target.name]: value });
    setPersonalDisplaySettings({
      userId: loggedUserId,
      component: component,
      key: event.target.name,
      value: value,
    });
  };

  const handleClose = () => {
    closeSubform();
  };

  useEffect(() => {
    if (component) {
      setState({
        useBackgroundColor: parseInt(
          getDisplaySetting(component, "useBackgroundColor")
        ),
        backgroundColor: getDisplaySetting(component, "backgroundColor"),
        backgroundFactor1: parseFloat(
          getDisplaySetting(component, "backgroundFactor1")
        ),
        backgroundFactor2: parseFloat(
          getDisplaySetting(component, "backgroundFactor2")
        ),
        useFontColor: parseInt(getDisplaySetting(component, "useFontColor")),
        fontColor: getDisplaySetting(component, "fontColor"),
        fontFactor: parseFloat(getDisplaySetting(component, "fontFactor")),
        tooltipFontColor: getDisplaySetting(component, "tooltipFontColor"),
        showAsBar: 1,
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);

  return (
    <>
      <div className={"subLine"}>
        {t("Presentation")}: {t(absenceName)}
      </div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  type="color"
                  name="backgroundColor"
                  label={t("Background")}
                  value={state.backgroundColor}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoFocus
                />
              </Grid>

              {mustBeApproved ? (
                <>
                  <Grid item xs={4}>
                    <TextField
                      type="number"
                      name="backgroundFactor1"
                      label={t("Requested")}
                      value={state.backgroundFactor1}
                      inputProps={{ min: "0.1", max: "0.9", step: "0.1" }}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="number"
                      name="backgroundFactor2"
                      label={t("Planned")}
                      value={state.backgroundFactor2}
                      inputProps={{ min: "0.1", max: "0.9", step: "0.1" }}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={8}></Grid>
              )}
              <Grid item xs={4}>
                <TextField
                  type="color"
                  name="fontColor"
                  label={
                    state.useBackgroundColor === 1 ? t("Font") : t("Color")
                  }
                  value={state.fontColor}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="number"
                  name="fontFactor"
                  label={t("Intensity")}
                  value={state.fontFactor}
                  inputProps={{ min: "0.1", max: "1.0", step: "0.1" }}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.preview}>
            <ColorExample
              className={classes.colorExample}
              component={component}
              componentProps={{
                identifier: absenceKey,
                mustBeApproved: mustBeApproved,
                ...state,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={"subLine"}>{"Tooltip"}</div>
          </Grid>
          <Grid item xs={6} className={classes.preview}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  type="color"
                  name="tooltipFontColor"
                  label={t("Font")}
                  value={state.tooltipFontColor}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.preview}>
            <ToolTipExample
              className={classes.tooltipExample}
              component={absenceName}
              componentProps={state}
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <BackButton onClick={handleClose} />
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceSubForm);
