import React from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "100%",
    marginBottom: "2px",
    color: theme.palette.text.main,
  },
  notchedOutline: {
    borderWidth: "1px",
  },
  adornment: {
    color: theme.palette.text.main,
  },
  required: {
    borderRadius: "4px",
    boxShadow: "inset 0 0 5px " + theme.palette.secondary.main,
  },
}));

const SomuraTextField = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const additionalClass = props.className;
  const input = props.value === 0 || props.value ? props.value : "";
  return (
    <FormControl fullWidth>
      <TextField
        className={
          classes.TextField + " " + (props.required ? classes.required : null)
        }
        required={props.required}
        disabled={props.disabled}
        type={props.type}
        name={props.name}
        value={input}
        label={t(props.label)}
        autoComplete={props.autocomplete}
        onChange={props.onChange}
        onBlur={props.onBlur}
        variant="outlined"
        size="small"
        autoFocus={props.autoFocus}
        multiline={props.multiline}
        rows={props.rows}
        InputLabelProps={{
          shrink:
            props.type === "date" || props.value !== "" || props.autoFocus,
        }}
        InputProps={{
          className: additionalClass,
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          endAdornment: props.adornmentText ? (
            <InputAdornment className={classes.adornment} position="end">
              {props.adornmentText}
            </InputAdornment>
          ) : (
            ""
          ),
          inputProps: {
            maxLength: props.maxlength,
            min: props.min,
            max: props.max,
            step: props.step,
            autoComplete: props.autocomplete,
            style: { textAlign: props.textAlign || "left" },
          },
        }}
      />
    </FormControl>
  );
};

export default SomuraTextField;
