import React, { useState } from "react";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { store } from "redux/store";
import {
  setRecipientMessageFolder,
  setSenderMessageFolder,
  deleteMessageFolder,
  setRead,
} from "redux/index";
import { ItemTypes } from "Constants";
import { useDrop } from "react-dnd";
import Paper from "@mui/material/Paper";
import FolderMenu from "./FolderMenu";

import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "4px",
    cursor: "pointer",
    display: "grid",
    alignItems: "center",
    gridTemplateRows: "2.5rem",
    gridTemplateColumns: "1.8rem 1fr 1.5rem",
  },
  listPaper: {
    padding: "0 !important",
    margin: (props) => `${"2px 2px 2px " + props.padding + "px"}`,
    backgroundColor: theme.palette.background.messageListItem,
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    borderRadius: "4px",
  },
  folderName: {
    fontSize: "1rem",
    gridRow: "1",
    gridColumn: "2 / -2",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: (props) => `${props.systemFolder ? "500" : "normal"}`,
  },
  systemFolderIcon: {
    width: "1.5rem",
    height: "1.5rem",
    gridRow: "1",
    gridColumn: "1",
  },
  folderButton: {
    padding: "0",
    "&:disabled": {
      color: theme.palette.disabled,
    },
  },
  numMessages: {
    fontSize: "1rem",
    textAlign: "right",
    marginRight: "4px",
    justifySelf: "end",
    alignSelf: "center",
    gridRow: "1",
    gridColumn: "3",
  },
  selected: {
    backgroundColor: theme.palette.background.messageListHighlight,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
    opacity: 0.5,
    backgroundColor: theme.palette.green,
  },
}));

const mapStateToProps = (state) => {
  return {
    logged: state.loggeduser.LoggedUser.id,
    folders: state.messagefolders.MessageFolders,
    folder: state.messagefolders.selectedFolderId,
    selectedMessageId: state.messages.selectedMessageId,
    messages: state.messages.Messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRecipientMessageFolder: (values) =>
      dispatch(setRecipientMessageFolder(values)),
    setSenderMessageFolder: (values) =>
      dispatch(setSenderMessageFolder(values)),
    deleteMessageFolder: (id) => dispatch(deleteMessageFolder(id)),
    setRead: (values) => dispatch(setRead(values)),
  };
};

const MessageFolder = ({ logged, folders, folder, messages, ...props }) => {
  const classes = useStyles(props);
  const [contextMenu, setContextMenu] = useState(null);

  const folderId = props.folderId;
  var numMessages;
  var folderMessages = messages.filter(
    (M) =>
      M.recipients?.findIndex(
        (R) => R.recipientFolderId === folderId && R.recipientId === logged
      ) >= 0
  );
  if (
    messages.findIndex(
      (M) => M.fromUserId === logged && M.senderFolderId === folderId
    ) >= 0
  ) {
    folderMessages.push(
      messages.filter(
        (M) => M.fromUserId === logged && M.senderFolderId === folderId
      )
    );
  }

  const hasSubfolder = folders.findIndex((F) => F.parentId === folderId) >= 0;

  const numUnreadMessages =
    messages.length > 0
      ? messages.filter(
          (M) =>
            M.senderFolderId !== "-4" &&
            M.senderFolderId !== "-5" &&
            (M.recipients
              ? M.recipients.findIndex(
                  (R) =>
                    R.recipientFolderId === folderId &&
                    R.readTime === null &&
                    R.recipientId === logged
                ) >= 0
              : [])
        ).length
      : 0;
  const numDraftMessages =
    messages.length > 0
      ? messages.filter(
          (M) => M.senderFolderId === "-4" && M.fromUserId === logged
        ).length
      : 0;
  const numSentMessages =
    messages.length > 0
      ? messages.filter(
          (M) => M.senderFolderId === "-2" && M.fromUserId === logged
        ).length
      : 0;

  switch (folderId) {
    case "-4":
      numMessages = numDraftMessages;
      break;
    case "-2":
      numMessages = numSentMessages;
      break;
    default:
      numMessages = folderMessages.length;
  }

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRightClick = (event) => {
    setContextMenu(null);
    event.preventDefault();
    document.oncontextmenu = function () {
      return false;
    };
    store.dispatch({ type: "SET_SELECTED_FOLDER_ID", payload: folderId });
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClick = () => {
    if (props.selectedMessageId !== "0") {
      props.setRead({
        messageId: props.selectedMessageId,
        recipientId: logged,
      });
    }
    store.dispatch({ type: "SET_SELECTED_FOLDER_ID", payload: folderId });
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
    store.dispatch({ type: "CLEAR_MESSAGE_IDS" });
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    store.dispatch({ type: "SET_SELECTED_FOLDER_ID", payload: "0" });
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
    props.deleteMessageFolder(folderId);
  };

  const handleAdd = (event) => {
    event.stopPropagation();
    store.dispatch({ type: "SET_SELECTED_FOLDER_ID", payload: folderId });
    props.setNewFolder();
    setContextMenu(null);
  };

  const moveMessage = (message) => {
    if (
      message.recipients &&
      message.recipients.findIndex((R) => R.recipientId === logged) !== -1
    ) {
      props.setRecipientMessageFolder({
        id: message.id,
        recipientFolderId: folderId,
        recipientId: logged,
      });
    } else {
      props.setSenderMessageFolder({
        id: message.id,
        senderFolderId: folderId,
      });
    }
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
    // props.fetchMessages(logged);
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.MESSAGE,
    drop: (message) => moveMessage(message.dragMessage),
    canDrop: (message) => {
      const recipient = message.dragMessage.recipients
        ? message.dragMessage.recipients.find((R) => R.recipientId === logged)
        : null;
      if (recipient) {
        // Received Message
        return (
          parseInt(folderId) > -2 && folderId !== recipient.recipientFolderId
        );
      } else {
        // Sent Message
        return folderId === "-1";
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <Paper
      square
      elevation={8}
      className={
        classes.listPaper + " " + (props.highlight ? classes.selected : "")
      }
    >
      <div
        className={classes.root}
        ref={drop}
        onClick={handleClick}
        onContextMenu={handleRightClick}
      >
        {props.folderId === "0" && (
          <MoveToInboxIcon className={classes.systemFolderIcon} />
        )}
        {props.folderId === "-4" && (
          <DraftsIcon className={classes.systemFolderIcon} />
        )}
        {props.folderId === "-2" && (
          <LocalShippingOutlinedIcon className={classes.systemFolderIcon} />
        )}
        {props.folderId === "-1" && (
          <DeleteIcon className={classes.systemFolderIcon} />
        )}
        {props.level > 1 && <SubdirectoryArrowRightIcon />}
        <span className={classes.folderName}>{props.folderName}</span>
        {folderId !== "-4" && numUnreadMessages > 0 && (
          <div className={classes.numMessages}>{numUnreadMessages}</div>
        )}
        {folderId === "-4" && numDraftMessages > 0 && (
          <div className={classes.numMessages}>{numDraftMessages}</div>
        )}
        {isOver && canDrop && <div className={classes.overlay} />}
      </div>
      <FolderMenu
        contextMenu={contextMenu}
        folderId={folderId}
        open={contextMenu !== null}
        close={handleClose}
        numMessages={numMessages}
        systemFolder={props.systemFolder}
        hasSubfolder={hasSubfolder}
        handleCreate={() => handleAdd}
        handleRename={(id) => handleAdd}
        handleDelete={(id) => handleDelete}
        handleEmpty={(id) => handleDelete}
        handleEmptyBin={(id) => handleAdd}
      />
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageFolder);
