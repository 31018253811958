import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { stringToHslColor } from "Components/Utilities";
import moment from "moment";
import parse from "html-react-parser";
import { setRecipientMessageFolder } from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { store } from "redux/store";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DateRangeIcon from "@mui/icons-material/DateRangeTwoTone";

import NewPassword from "./NewPassword";
import NewAccount from "./NewAccount";

const useStyles = makeStyles((theme) => ({
  messageHeaderRoot: {
    padding: "1rem 1rem 0 1rem",
    display: "grid",
    gridTemplateColumns: "3rem 1fr 1fr",
    gridTemplateRows: "1.8rem 1.2rem 1.2rem 1.8rem 1.2rem",
  },
  messageRoot: {
    marginTop: "10px",
    padding: "1rem",
    display: "grid",
    gridTemplateColumns: "4rem 1fr 1fr",
    gridTemplateRows: "1fr",
  },
  paper: {
    backgroundColor: theme.palette.background.messageList,
    padding: "4px",
    borderRadius: "4px",
    margin: "4px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  messagePaper: {
    backgroundColor: theme.palette.background.messageList,
    padding: "4px",
    borderRadius: "4px",
    margin: "4px",
  },
  messageTitle: {
    fontSize: "1.2rem",
    gridRow: "1",
    gridColumn: "1 / 4",
    overflow: "hidden",
  },
  messageTime: {
    gridRow: "3",
    gridColumn: "2 / 4",
    alignSelf: "center",
    fontSize: "0.8rem",
  },
  messageSender: {
    fontSize: "1rem",
    gridRow: "2",
    gridColumn: "2 / 4",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  avatarText: {
    fontSize: "1rem",
  },
  avatar: {
    gridRow: "2 / 4",
    gridColumn: "1",
    alignSelf: "center",
    width: "2rem",
    height: "2rem",
    marginRight: "6px",
    color: "white",
  },
  messageRecipents: {
    gridRow: "4",
    gridColumn: "1 / -1",
    alignSelf: "end",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  messageCCRecipents: {
    gridRow: "5",
    gridColumn: "1 / -1",
    alignSelf: "end",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  red: {
    backgroundColor: theme.palette.red,
  },
  messageTextBackground: {
    borderRadius: "4px",
    backgroundColor: theme.palette.background.messageList,
    boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
  },
  messageText: {
    fontSize: "1rem",
    gridRow: "1",
    gridColumn: "1 / 5",
    whiteSpace: "pre-line",
  },
  messageData: {
    display: "grid",
    gridTemplateRows: "repeat(4, 1.5rem)",
    gridTemplateColumns: "7rem 1fr",
  },
  notCorrect: {
    color: theme.palette.red
  },
  obsolete: {
    fontSize: "1rem",
    gridRow: "1",
    gridColumn: "1 / 5",
    textAlign: "center",
    color: "white",
    backgroundColor: theme.palette.red,
  },
  Test: {
    backgroundColor: "red",
  },
  buttonsRight: {
    marginTop: "10px",
    gridColumn: "2",
    gridRow: "1",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    persons: state.persons.Persons,
    logged: state.loggeduser.LoggedUser.id,
    folders: state.messagefolders.MessageFolders,
    messages: state.messages.Messages,
    messageId: state.messages.selectedMessageId,
    offDayTypes: state.offdaytypes.OffDayTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRecipientMessageFolder: (values) =>
      dispatch(setRecipientMessageFolder(values)),
  };
};

const Message = ({
  persons,
  logged,
  folders,
  messages,
  messageId,
  offDayTypes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const showMessage = messages.find((M) => M.id === messageId);

  const messageRecipients = showMessage.recipients.filter(
    (R) => R.isCC === "0"
  );
  const messageCCRecipients = showMessage.recipients.filter(
    (R) => R.isCC === "1"
  );

  const readTime = messageRecipients.find(
    (R) => R.recipientId === logged
  )?.readTime;

  var messageRecipientsString = "";
  if (messageRecipients) {
    messageRecipients.forEach((recipient, index) => {
      if (messageRecipientsString !== "") {
        messageRecipientsString += "; ";
      }
      messageRecipientsString +=
        recipient.lastName + ", " + recipient.firstName;
    });
  }

  var messageCCRecipientsString = "";
  if (messageCCRecipients) {
    messageCCRecipients.forEach((recipient, index) => {
      if (messageCCRecipientsString !== "") {
        messageCCRecipientsString += "; ";
      }
      messageCCRecipientsString +=
        recipient.lastName + ", " + recipient.firstName;
    });
  }

  let displayClass = classes.avatar;
  var recipientNames = "";

  const additionalText = offDayTypes.map(
    (OT) =>
      OT.additionalTexts.find((AT) => AT.id === showMessage.offDayTextId)?.text
  );

  const [newPassword, openNewPassword] = useState(false);
  const [newAccount, openNewAccount] = useState(false);

  const openYearCalendar = () => {
    store.dispatch({
      type: "SET_DISPLAY_USER",
      payload: showMessage.approvementUserId,
    });
    store.dispatch({
      type: "SET_DISPLAY_APPROVEMENT",
      payload: showMessage.approvementId,
    });
    history.push(`/YearCalendar`);
  };

  const handleAllowAccess = () => {
    openNewAccount(true);
  };

  const handleAllowNewPassword = () => {
    openNewPassword(true);
  };

  if (parseInt(showMessage.toMode) >= 0) {
    // Recipient
    recipientNames = t("To") + ": " + messageRecipientsString;
  } else {
    switch (showMessage.toMode) {
      case "-1":
        recipientNames = t("To") + ": " + t("Administrators");
        break;
      case "-2":
        recipientNames = t("To") + ": " + t("Assistants");
        break;
      case "-3":
        recipientNames =
          t("To") + ": " + t("Administrators") + " + " + t("Assistants");
        break;
      default:
        recipientNames = "[" + t("Draft") + "]";
    }
    displayClass = classes.avatar + " " + classes.grey;
  }

  // Sender
  const senderName = t("From") + ": " + showMessage.fromUserName;
  const senderInitials =
    showMessage.fromUserId === "0" ? "SS" : showMessage.fromInitials;
  const avatarColor = stringToHslColor(senderInitials);

  const message = showMessage.message.replace(/(<br>|<\/br>|<br \/>)/gim, "\n");

  return showMessage && showMessage.senderFolderId !== "-4" ? (
    <Fragment>
      <div className={classes.messageBackground}>
        {readTime && (
          <Paper square elevation={8} className={classes.paper}>
            <div className={classes.readtimeRoot}>
              <div className={classes.messageText}>
                {t("Message read") +
                  ": " +
                  moment(readTime).format("dddd, LL HH:mm")}
              </div>
            </div>
          </Paper>
        )}
        <Paper square elevation={8} className={classes.messagePaper}>
          <div className={classes.messageHeaderRoot}>
            <div className={classes.messageTitle}>
              {showMessage.title ? showMessage.title : t("(No subject)")}
            </div>
            <div className={classes.avatar}>
              <Avatar
                className={displayClass}
                style={{ backgroundColor: avatarColor }}
              >
                <span className={classes.avatarText}>{senderInitials}</span>
              </Avatar>
            </div>
            <div className={classes.messageSender}>{senderName}</div>
            <div className={classes.messageTime}>
              {showMessage.creationDateTime}
            </div>

            <div className={classes.messageRecipents}>{recipientNames}</div>
            {messageCCRecipientsString && (
              <div className={classes.messageCCRecipents}>
                CC: {messageCCRecipientsString}
              </div>
            )}
          </div>
          <div className={classes.messageTextBackground}>
            <div className={classes.messageRoot}>
              {showMessage.startDate !== "0000-00-00" ? (
                <div className={classes.messageText}>
                  {parse(message)}
                  <div className={classes.messageData}>
                    <span style={{ gridRow: "1", gridColumn: "1" }}>
                      Erster Tag:
                    </span>
                    {moment(showMessage.startDate).format("dddd, LL")}
                    <br />
                    <span style={{ gridRow: "2", gridColumn: "1" }}>
                      Letzter Tag:
                    </span>
                    {moment(showMessage.endDate).format("dddd, LL")}
                    <br />
                    <span style={{ gridRow: "3", gridColumn: "1" }}>
                      Dauer:
                    </span>
                    {showMessage.numDays}{" "}
                    {showMessage.numDays > 1 ? t("days") : t("day")}
                    {showMessage.offDayTextId > 0 && (
                      <>
                        <span style={{ gridRow: "4", gridColumn: "1" }}>
                          Zusatztext:
                        </span>
                        {additionalText}
                      </>
                    )}
                  </div>

                  {parseInt(showMessage.approvementId) === 0 && (
                    <div className={classes.notCorrect}>{t("notCorrect")}</div>
                  )}
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<DateRangeIcon />}
                    onClick={openYearCalendar}
                  >
                    {t("Go to year-calendar")}
                  </Button>
                </div>
              ) : (
                <div className={classes.messageText}>{parse(message)}</div>
              )}
            </div>
          </div>
        </Paper>
        {(showMessage.allowAccess || showMessage.allowNewPassword) && (
          <Paper square elevation={8} className={classes.paper}>
            <div className={classes.buttonsRight}>
              {showMessage.allowAccess &&
                moment(showMessage.allowAccessCompleted).isValid() && (
                  <span>
                    {t("Approved")}
                    {": "}
                    {moment(showMessage.allowAccessCompleted).format(
                      "dddd, LL HH:mm"
                    )}
                  </span>
                )}
              {showMessage.allowAccess &&
                !moment(showMessage.allowAccessCompleted).isValid() && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={handleAllowAccess}
                  >
                    {t("Allow access")}
                  </Button>
                )}
              {showMessage.allowNewPassword &&
                showMessage.allowNewPasswordCompleted && (
                  <span>
                    {t("Allowed")}
                    {": "}
                    {moment(showMessage.allowNewPasswordCompleted).format(
                      "dddd, LL HH:mm"
                    )}
                  </span>
                )}
              {showMessage.allowNewPassword &&
                !showMessage.allowAccess &&
                !showMessage.allowNewPasswordCompleted && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={handleAllowNewPassword}
                  >
                    {t("Allow new password")}
                  </Button>
                )}
            </div>
          </Paper>
        )}

        {newPassword && (
          <NewPassword
            messageId={messageId}
            requestMail={showMessage.messageMailAdress}
            setNewPassword={openNewPassword}
          />
        )}
        {newAccount && (
          <NewAccount
            messageId={messageId}
            requestMail={showMessage.messageMailAdress}
            close={() => openNewAccount(false)}
          />
        )}
      </div>
    </Fragment>
  ) : (
    <div></div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
