import React, { useEffect, useState, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import { updateMovement, deleteMovement } from "redux/index";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import CancelButton from "Components/Buttons/CancelButton";
import DeleteButton from "Components/Buttons/DeleteButton";
import SaveButton from "Components/Buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.scrollBar,
  },
  dialogTitle: {
    width: "30rem",
    padding: "4px 10px",
    fontWeight: "200",
    color: theme.palette.text.screenTitle,
  },
  dialogText: {
    color: theme.palette.text.calendars,
  },
  dialogContent: {
    padding: "10px 10px 6px 10px",
    backgroundColor: theme.palette.dialog.content,
  },
  dialogActions: {
    padding: "0 8px",
  },
}));

const mapStateToProps = (state) => {
  const articles = state.articles.Articles;
  const types = state.articles.MovementTypes;
  const locations = state.articles.Locations;
  const persons = state.persons.PersonsMin.filter(
    (P) => P.lastName !== "" && P.active === true
  );
  const reasons = state.articles.Reasons;
  const states = state.articles.States;
  return { articles, types, locations, persons, reasons, states };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (values) => dispatch(updateMovement(values)),
    remove: (values) => dispatch(deleteMovement(values)),
  };
};

const MovementDialog = ({
  articles,
  types,
  locations,
  persons,
  reasons,
  states,
  articleId,
  movementId,
  movementKey,
  update,
  remove,
  close,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const article = articles?.find((A) => A.id === articleId);
  const movement = article?.movements.find((M) => M.movementId === movementId);
  const movementType = types.find((T) => T.id === movement.movementTypeId);

  const [state, setState] = useState({ ...movement });
  const [valid, setValid] = useState(false);

  var personOptions = persons.map((P) => ({
    label: P.listName,
    value: P.id,
  }));

  var locationOptions = locations.map((L) => ({
    label: L.locationName,
    value: L.id,
  }));

  var reasonOptions = reasons.map((R) => ({
    label: R.reason,
    value: R.id,
  }));
  reasonOptions.unshift({ label: "", value: "0", first: false });

  var stateOptions = states.map((S) => ({
    label: S.status,
    value: S.id,
  }));
  stateOptions.unshift({ label: "", value: "0", first: false });

  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    update({ ...state, articleId: articleId });
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleDelete = () => {
    remove({ ...state, articleId: articleId });
    handleClose();
  };

  const handlePersonChange = (params) => {
    const value = params.value === "" ? "0" : params.value;
    const personName = persons.find((P) => P.id === value).listName;
    setState({
      ...state,
      personId: value,
      locationPerson: personName,
      personName: personName,
    });
  };

  const handleLocationChange = (params) => {
    const value = params.value === "" ? "0" : params.value;
    const locationName = locations.find((L) => L.id === value).locationName;
    setState({
      ...state,
      locationId: value,
      locationName: locationName,
    });
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({ ...state, [name]: value });
  };

  const movementSchema = Yup.object().shape({
    movementDate: Yup.date().required(t("Required")),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await movementSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setState({ ...movement });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(event, reason);
          }
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {t("Modify movement")}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <div className={classes.dialogContent}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SomuraTextField
                  label={t("Movement type")}
                  type="text"
                  name="movementTypeName"
                  value={state.movementTypeName}
                  onChange={handleChange}
                  disabled={true}
                />
              </Grid>
              {movementType?.needsUser && (
                <Grid item xs={6}>
                  <SomuraAutocomplete
                    name="personId"
                    label={t("Person")}
                    options={personOptions}
                    onChange={(values) => handlePersonChange(values)}
                    value={state.personId}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required={true}
                    disableClearable={true}
                  />
                </Grid>
              )}
              {movementType?.needsLocation && (
                <Grid item xs={6}>
                  <SomuraAutocomplete
                    name="locationId"
                    label={t("Location")}
                    options={locationOptions}
                    onChange={(values) => handleLocationChange(values)}
                    value={state.locationId}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required={true}
                    disableClearable={true}
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <SomuraTextField
                  label={t("Date")}
                  type="date"
                  name="movementDate"
                  value={state.movementDate}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraAutocomplete
                  name="reasonId"
                  label={t("Reason")}
                  options={reasonOptions}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  value={state.reasonId}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disableClearable={true}
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraAutocomplete
                  name="stateId"
                  label={t("Condition")}
                  options={stateOptions}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  value={state.stateId}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disableClearable={true}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CancelButton onClick={handleCancel} />
          <DeleteButton
            onClick={handleDelete}
            disabled={
              parseInt(movementKey) > 0 || article.movements.length <= 1
            }
          />
          <SaveButton disabled={!valid} onClick={handleSubmit} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MovementDialog);
