import {
  FETCH_GLOBAL_SETTINGS_REQUEST,
  FETCH_GLOBAL_SETTINGS_SUCCESS,
  SET_GLOBAL_SETTING,
  FETCH_PERSONAL_SETTINGS_REQUEST,
  FETCH_PERSONAL_SETTINGS_SUCCESS,
  SET_PERSONAL_SETTING,
  FETCH_PERSONAL_DISPLAYSETTINGS_REQUEST,
  FETCH_PERSONAL_DISPLAYSETTINGS_SUCCESS,
  SET_PERSONAL_DISPLAYSETTING,
} from "actions/types";
import produce from "immer";

const initialState = {
  GlobalSettings: [],
  PersonalSettings: [],
  DisplaySettings: [],
  loading: false,
  error: "",
};

const settingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key
    switch (action.type) {
      case FETCH_GLOBAL_SETTINGS_REQUEST:
        draft.loading = true;
        break;
      case FETCH_GLOBAL_SETTINGS_SUCCESS:
        draft.GlobalSettings = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case SET_GLOBAL_SETTING:
        key = draft.GlobalSettings.findIndex(GS => GS.component === action.payload.component)
        draft.GlobalSettings[key].settings[action.payload.key] = action.payload.value
        break;
      case FETCH_PERSONAL_SETTINGS_REQUEST:
        draft.loading = true;
        break;
      case FETCH_PERSONAL_SETTINGS_SUCCESS:
        draft.PersonalSettings = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case SET_PERSONAL_SETTING:
        key = draft.PersonalSettings.findIndex(PS => PS.userId === action.payload.userid && PS.component === action.payload.component)
        // if(key === -1){
        //   // Add new setting
        //   draft.PersonalSettings.splice(0, 0, action.payload)
        // } else {
          draft.PersonalSettings[key].settings[action.payload.key] = action.payload.value
        // }
        break;
      case FETCH_PERSONAL_DISPLAYSETTINGS_REQUEST:
        draft.loading = true;
        break;
      case FETCH_PERSONAL_DISPLAYSETTINGS_SUCCESS:
        draft.DisplaySettings = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case SET_PERSONAL_DISPLAYSETTING:
        key = draft.DisplaySettings.findIndex(PS => PS.component === action.payload.component)
        draft.DisplaySettings[key].settings[action.payload.key] = action.payload.value
        break;
      default:
        return draft;
    }
  });

export default settingsReducer;
