import React, { useEffect, useState, useCallback } from "react";
import {
  setSelectedCountryId,
  setSelectedRegionId,
  updateCountry,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";

import * as Yup from "yup";
import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import makeStyles from "@mui/styles/makeStyles";

import { languages } from "Constants";

import SomuraListItem from "Components/SomuraListItem";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";
import AddIcon from "@mui/icons-material/Add";

const CountrySchema = Yup.object().shape({
  ownName: Yup.string().required("Pflichtfeld"),
  languageName: Yup.string().required("Pflichtfeld"),
  short2: Yup.string().required("Pflichtfeld"),
});

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  subLine: {
    fontSize: "1rem",
    paddingLeft: "1rem",
    color: theme.palette.text.screenTitle,
  },
  fabWrapper: {
    height: "18px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    bottom: "16px",
    right: "16px",
  },
  regionsList: {
    marginTop: "6px",
    maxHeight: "58vh",
    overflow: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.messageList,
    padding: "4px",
    borderRadius: "4px",
    margin: "4px",
    // display: "flex",
    // justifyContent: "center",
    // textAlign: "center",
  },
  summary: {
    // backgroundColor: theme.palette.background.default,
    lineHeight: "1rem",
    height: "2rem",
  },
  details: {
    backgroundColor: theme.palette.background.accordion,
  },
  accordion: {
    width: "100%",
    margin: "6px 0",
  },
}));

const mapStateToProps = (state) => {
  const countries = state.countries.Countries;
  const expandedAccordion = state.general.accordionExpanded;
  return {
    countries,
    expandedAccordion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCountryId: (id) => dispatch(setSelectedCountryId(id)),
    setSelectedRegionId: (id) => dispatch(setSelectedRegionId(id)),
    updateCountry: (values) => dispatch(updateCountry(values)),
  };
};

const CountryForm = ({
  countries,
  expandedAccordion,
  setSelectedCountryId,
  updateCountry,
  ...props
}) => {
  const selectedCountry =
    countries.length > 0 && countries.find((c) => c.id === props.countryId);
  const regions = selectedCountry?.regions;
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: 0,
    germanName: "",
    englishName: "",
    ownName: "",
    languageName: "de-DE",
    short2: "",
    short3: "",
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);
  const formHeader = selectedCountry
    ? selectedCountry.ownName
    : t("New country");

  const RegionsList = () => {
    return (
      <>
        <div className={classes.regionsList}>
          {regions?.map((R, index) => (
            <SomuraListItem
              region={R}
              value={R.id}
              country={selectedCountry}
              key={index}
              itemText={
                R.regionLong +
                (R.parentRegionId > 0
                  ? " (" +
                    regions?.find((SR) => SR.id === R.parentRegionId)
                      ?.regionLong +
                    ")"
                  : "")
              }
              onClick={handleRegionClick}
            />
          ))}
        </div>
      </>
    );
  };

  const handleSubmit = () => {
    updateCountry(state);
    handleClose();
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "cancel":
        handleClose();
        break;
      case "delete":
        // props.deleteDepartment(selectedDepartment.id);
        props.setEditMode(false);
        break;
      default:
        handleClose();
    }
  };

  const handleRegionClick = (id) => {
    props.setSelectedRegionId(id);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    setState({ ...state, [name]: value });
  };

  const handleClose = () => {
    setSelectedCountryId(-1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await CountrySchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (selectedCountry) {
      setState({
        ...selectedCountry,
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <>
        <div className={classes.thisForm}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SomuraTextField
                    type="text"
                    name="ownName"
                    label={t("Country")}
                    value={state.ownName}
                    maxlength="50"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <SomuraAutocomplete
                    name="languageName"
                    label={t("Language")}
                    options={languages.map((L) => ({
                      label: L.languageLongName,
                      value: L.languageName,
                    }))}
                    value={state.languageName}
                    onChange={(values) => handleAutoCompleteChange(values)}
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <SomuraTextField
                    type="text"
                    name="short2"
                    label={t("ISO 3166 ALPHA 2")}
                    maxlength="2"
                    value={state.short2}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SomuraTextField
                    type="text"
                    name="short3"
                    label={t("ISO 3166 ALPHA 3")}
                    maxlength="3"
                    value={state.short3}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("Regions")}</div>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12}>
                <RegionsList />
                <div className={classes.fabWrapper}>
                  <Fab
                    variant="extended"
                    className={classes.fab}
                    size="small"
                    color="primary"
                    onClick={() => {
                      props.setSelectedRegionId(0);
                    }}
                  >
                    <AddIcon />
                    {t("New region")}
                  </Fab>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="formBottom">
          <CancelButton
            onClick={() => {
              handleButtonClick("cancel");
            }}
          />
          <DeleteButton
            disabled={true}
            onClick={() => {
              handleButtonClick("delete");
            }}
          />
          <SaveButton
            disabled={!valid || expandedAccordion !== false}
            onClick={handleSubmit}
          />
        </div>
      </>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryForm);
