import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import SomuraTooltip from "Components/SomuraTooltip";

const useStyles = makeStyles((theme) => ({
  toolTipTitle: {
    color: "#243573",
    fontSize: "1rem",
    backgroundColor: "#E0E0E0",
    padding: "2px 8px",
  },
  userName: {
    color: "#243573",
    fontSize: "0.9rem",
    backgroundColor: "#ECECEC",
    padding: "2px 8px",
  },
  noBack: {
    height: "1.3rem",
    fontSize: "0.9rem",
    padding: "0px 8px",
  },
  subLine: {
    // height: "1.3rem",
    fontSize: "0.9rem",
    margin: "1px 8px",
    padding: "0px 8px",
    borderRadius: "0.75rem",
  },
  additionalLine: {
    height: "1.1rem",
    width: "75%",
    fontSize: "0.7rem",
    borderRadius: "0.55rem",
    padding: "0px 8px",
    backgroundColor: "white",
    margin: "-0.3rem auto 0 auto",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const mapStateToProps = (state) => {
  return {
    dayInfo: state.month.dayInfo,
  };
};

const CalToolTip = ({ dayInfo, ...props }) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const title = () => {
    if (dayInfo.date) {
      let renderTitle = moment(dayInfo.date).format("dddd, LL");
      if (dayInfo.date === moment().format("YYYY-MM-DD")) {
        renderTitle = t("Today") + ": " + renderTitle;
      }
      let sublines = [];
      for (var i = 0; i < dayInfo.tooltipTexts.length; i++) {
        sublines.push(
          <div key={i}>
            <div
              className={
                dayInfo.tooltipTexts[i].backColor === ""
                  ? classes.noBack
                  : classes.subLine
              }
              style={{
                color: dayInfo.tooltipTexts[i].fontColor,
                backgroundColor: dayInfo.tooltipTexts[i].backColor,
              }}
            >
              {dayInfo.tooltipTexts[i].text}
            </div>
            {dayInfo.tooltipTexts[i].additionalText && (
              <div
                className={classes.additionalLine}
                style={{
                  border: "2px solid " + dayInfo.tooltipTexts[i].backColor,
                }}
              >
                {dayInfo.tooltipTexts[i].additionalText}
              </div>
            )}
          </div>
        );
      }
      return (
        <>
          <div className={classes.toolTipTitle}>{renderTitle}</div>
          {dayInfo.user && (
            <div className={classes.userName}>{dayInfo.user}</div>
          )}
          {sublines.length > 0 && <div>{sublines}</div>}
        </>
      );
    } else {
      return "";
    }
  };
  return (
    <SomuraTooltip
      title={title()}
      placement="top"
      open={Object.entries(dayInfo).length > 0}
      leaveDelay={10000}
      calendar
    >
      <Box
        id="TT"
        sx={{
          position: "absolute",
          width: "2.4vw",
          height: "2px",
          backgroundColor: "red",
          visibility: "hidden",
        }}
      ></Box>
    </SomuraTooltip>
  );
};

export default connect(mapStateToProps)(CalToolTip);
