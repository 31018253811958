import {
  SET_ENTITLEMENT_ID,
  SET_SALARY_ID,
  SET_COVSTATE_ID,
  SET_DISPLAY_USER,
  SET_DISPLAY_DEPARTMENT,
} from "actions/types";

import produce from "immer";

const initialState = {
  selectedEntitlementId: -1,
  selectedSalaryId: -1,
  selectedCovStateId: -1,
  selectedUserId: -1,
  selectedDepartmentId: -1,
};

const selectionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const PL = action.payload;
    // eslint-disable-next-line
    switch (action.type) {
      case SET_ENTITLEMENT_ID:
        draft.selectedEntitlementId = PL;
        break;
      case SET_SALARY_ID:
        draft.selectedSalaryId = PL;
        break;
      case SET_COVSTATE_ID:
        draft.selectedCovStateId = PL;
        break;
      case SET_DISPLAY_USER:
        draft.selectedUserId = PL;
        break;
      case SET_DISPLAY_DEPARTMENT:
        draft.selectedDepartmentId = PL;
        break;
      default:
        return state;
    }
  });

export default selectionsReducer;
