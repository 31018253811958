import React from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import Box from "@mui/material/Box";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import BlurOnTwoToneIcon from "@mui/icons-material/BlurOnTwoTone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const mapStateToProps = (state) => {
  return {
    loggedUserName: state.loggeduser.LoggedUser.listName,
  };
};

function CalendarDayFrame({
  date,
  username,
  tooltipTexts,
  loggedUserName,
  ...props
}) {
  function handleMouseDown(event) {
    const element = event.currentTarget;
    if (element.id?.substring(0, 1) === "d") {
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
      if (event.button === 0 && props.ismarkable) {
        props.onMouseDown(element.id);
      }
      if (event.button === 2) {
        if (username === loggedUserName) {
          store.dispatch({
            type: "SHOW_CONTEXT_MENU",
            payload: {
              name: username,
              element: element.id,
              date: element.getAttribute("date"),
            },
          });
        } else {
          store.dispatch({
            type: "SHOW_CONTEXT_MENU",
            payload: null,
          });
        }
      }
      // props.onResetSelection();
    }
  }

  function handleMouseUp(event) {
    const id = event.currentTarget.id;
    if (event.button === 0) {
      props.onMouseUp(id);
    }
  }

  function handleMouseEnter(event) {
    const element = event.currentTarget;
    var rect = element.getBoundingClientRect();
    const TTelement = document.getElementById("TT");
    if (TTelement) {
      TTelement.style.top = rect.top + 10 + "px";
      TTelement.style.left = rect.left + "px";
    }
    const id = element.id;
    if (props.showtooltip) {
      store.dispatch({
        type: "SET_DAY_INFO",
        payload: { date: date, user: username, tooltipTexts: tooltipTexts },
      });
    }
    props.onHover(props.reminders, date);
    if (event.button === 0 && props.ismarkable) {
      props.onMouseEnter(id);
    }
  }

  function handleMouseMove(event) {
    const id = event.currentTarget.id;
    // store.dispatch({
    //   type: "SET_DAY_INFO",
    //   payload: { date: date, user: username, tooltipTexts: tooltipTexts },
    // });
    if (event.button === 0 && props.ismarkable) {
      props.onMouseEnter(id);
    }
  }

  function handleMouseLeave() {
    store.dispatch({ type: "SET_DAY_INFO", payload: {} });
  }

  const segments = (props) => {
    let colorSegments = [];
    for (var i = 0; i < 8; i++) {
      colorSegments.push(
        <Box
          sx={{
            gridArea: "segment" + i,
            backgroundColor: props.segmentColor[i][0],
            backgroundImage: !props.segmentColor[i][1]
              ? "linear-gradient(90deg, transparent 50%, rgba(255,255,255,1) 50%)"
              : "",
            backgroundSize: "4px 2px",
          }}
          key={i}
        ></Box>
      );
    }
    return colorSegments;
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "3.3vh",
          backgroundColor: props.daycolor,
        }}
      >
        <div
          id={props.id}
          date={date}
          workdayfactor={props.workdayfactor}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
        >
          <Box
            component="div"
            sx={{
              width: "100%",
              height: "3.5vh",
              border: "1",
              borderColor: "background.calendarDayFrame",
              backgroundColor: props.backColor,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "absolute",
                display: "grid",
                gridTemplateColumns: "15% 32% 6% 32% 15%",
                gridTemplateRows: "15% 40% 15% 15% 15%",
                gridTemplateAreas: `"left-top top-bar-left top-middle top-bar-right right-top"
                                "left left-text middle-text right-text right" 
                                "segment4 segment4 segment5 segment5 segment5"
                                "segment2 segment2 segment3 segment3 segment3"
                                "segment0 segment0 segment1 segment1 segment1"`,
                userSelect: "none",
              }}
            >
              <Box
                component="span"
                sx={{
                  gridArea: "2 / 2 / 5 / 5",
                  justifySelf: "center",
                  alignSelf: "center",
                  zIndex: "10",
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: props.textColor,
                }}
              >
                {props.specialdaykey ? props.specialdaykey : props.offdaykey}
              </Box>
              {segments(props)}
              {props.id === props.startId && (
                <Box
                  sx={{
                    gridArea: "left",
                    color: "orangered",
                    width: "0.4rem",
                  }}
                >
                  <ArrowRightIcon viewBox="10 0 24 24" />
                </Box>
              )}
              {props.id === props.endId && (
                <Box
                  sx={{
                    gridArea: "right",
                    color: "orangered",
                    width: "0.4rem",
                  }}
                >
                  <ArrowLeftIcon
                    viewBox="9 0 24 24"
                    style={{ marginLeft: "-1px" }}
                  />
                </Box>
              )}
              {props.reminderStartEnd && (
                <Box
                  sx={{
                    gridArea: "top-middle",
                    color: "orangered",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowDropDownIcon />
                </Box>
              )}
              {props.reminderPrev && (
                <>
                  <Box
                    sx={{
                      gridArea: "top-bar-left",
                      borderTop: "2px solid orangered",
                    }}
                  />
                  <Box
                    sx={{
                      gridArea: "left-top",
                      borderTop: "2px solid orangered",
                    }}
                  />
                </>
              )}
              {props.reminderNext && (
                <>
                  <Box
                    sx={{
                      gridArea: "top-middle",
                      borderTop: "2px solid orangered",
                    }}
                  />
                  <Box
                    sx={{
                      gridArea: "top-bar-right",
                      borderTop: "2px solid orangered",
                    }}
                  />
                  <Box
                    sx={{
                      gridArea: "right-top",
                      borderTop: "2px solid orangered",
                    }}
                  />
                </>
              )}
              {props.isbirthday && (
                <Box
                  sx={{
                    gridArea: "right-top",
                    marginLeft: "-12px",
                    width: "1rem",
                    height: "1rem",
                  }}
                >
                  <svg viewBox="0 0 24 24">
                    <path fill={props.birthdayColor} d={props.birthdayIcon} />
                  </svg>
                </Box>
              )}
              {props.inactive && (
                <Box
                  sx={{
                    gridArea: "1 / 1 / 6 / 5",
                    display: "flex",
                    alignItems: "center",
                    color: "red",
                  }}
                >
                  <BlurOnTwoToneIcon />
                </Box>
              )}
            </Box>
            <Box
              id={"o" + props.id}
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "yellow",
                opacity: "0.2",
                position: "absolute",
                zIndex: "1000",
                display:
                  props.id >= props.startId && props.id <= props.endId
                    ? "block"
                    : "none",
              }}
            ></Box>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default connect(mapStateToProps)(CalendarDayFrame);
