import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "redux/store";
import {
  setUnread,
  deleteMessage,
  setRecipientMessageFolder,
  setSenderMessageFolder,
} from "redux/index";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconMenuItem, NestedMenuItem } from "mui-nested-menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const mapStateToProps = (state) => {
  return {
    logged: state.loggeduser.LoggedUser.id,
    selectedMessageIds: state.messages.selectedMessageIds,
    messageFolders: state.messagefolders.MessageFolders,
    messages: state.messages.Messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUnread: (values) => dispatch(setUnread(values)),
    deleteMessage: (id) => dispatch(deleteMessage(id)),
    setRecipientMessageFolder: (values) =>
      dispatch(setRecipientMessageFolder(values)),
    setSenderMessageFolder: (values) =>
      dispatch(setSenderMessageFolder(values)),
  };
};

const MessageMenu = ({
  logged,
  selectedMessageIds,
  messageFolders,
  messages,
  messageId,
  open,
  close,
  contextMenu,
  ...props
}) => {
  const { t } = useTranslation();

  const numMessages = selectedMessageIds?.length;
  const numSelected = selectedMessageIds?.filter(
    (SM) => SM.selected === true
  ).length;

  const handleClose = () => {
    close();
  };

  const handleDelete = () => {
    selectedMessageIds
      .filter((SM) => SM.selected === true)
      // eslint-disable-next-line array-callback-return
      .map(function (SM) {
        props.setSenderMessageFolder({
          id: SM.id,
          senderFolderId: "-1",
        });
        props.setRecipientMessageFolder({
          id: SM.id,
          recipientId: logged,
          recipientFolderId: "-1",
        });
      });
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
  };

  const handleRemove = () => {
    selectedMessageIds
      .filter((SM) => SM.selected === true)
      // eslint-disable-next-line array-callback-return
      .map(function (SM) {
        props.setSenderMessageFolder({
          id: SM.id,
          senderFolderId: "-3",
        });
        props.setRecipientMessageFolder({
          id: SM.id,
          recipientId: logged,
          recipientFolderId: "-3",
        });
      });
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
  };

  const handleUnread = () => {
    selectedMessageIds
      .filter((SM) => SM.selected === true)
      // eslint-disable-next-line array-callback-return
      .map(function (SM) {
        props.setUnread({ messageId: SM.id, recipientId: logged });
      });

    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
    close();
  };

  const handleMove = (event) => {
    const targetFolder = event.target.id;
    let message;
    selectedMessageIds
      .filter((SM) => SM.selected === true)
      // eslint-disable-next-line array-callback-return
      .map(function (SM) {
        message = messages.find((M) => M.id === SM.id);
        if (message.fromUserId === logged) {
          props.setSenderMessageFolder({
            id: message.id,
            senderFolderId: targetFolder,
          });
        } else {
          props.setRecipientMessageFolder({
            id: message.id,
            recipientFolderId: targetFolder,
            recipientId: logged,
          });
        }
      });
    close();
  };

  const handleSelectAll = () => {
    store.dispatch({ type: "SELECT_ALL_MESSAGE_IDS" });
    close();
  };

  const handleUnselectAll = () => {
    store.dispatch({ type: "UNSELECT_ALL_MESSAGE_IDS" });
    close();
  };

  const FolderList = () => {
    let folders = [];
    folders.push(
      <MenuItem
        onClick={handleMove}
        id={"0"}
        key={"0"}
        disabled={props.folderId === "0"}
      >
        {t("Inbox")}
      </MenuItem>
    );
    messageFolders.forEach((F) => {
      folders.push(
        <MenuItem
          onClick={handleMove}
          id={F.id}
          key={F.id}
          disabled={F.id === props.folderId}
        >
          <ListItemIcon>
            <SubdirectoryArrowRightIcon />
          </ListItemIcon>
          {F.folderName}
        </MenuItem>
      );
    });
    // folders.push(
    //   <MenuItem
    //     onClick={handleMove}
    //     id={"-4"}
    //     key={"-4"}
    //     disabled={props.folderId === "-4"}
    //   >
    //     {t("Drafts")}
    //   </MenuItem>
    // );
    // folders.push(
    //   <MenuItem
    //     onClick={handleMove}
    //     id={"-2"}
    //     key={"-2"}
    //     disabled={props.folderId === "-2"}
    //   >
    //     {t("Outbox")}
    //   </MenuItem>
    // );
    folders.push(
      <MenuItem
        onClick={handleMove}
        id={"-1"}
        key={"-1"}
        disabled={props.folderId === "-1"}
      >
        {t("Recycle bin")}
      </MenuItem>
    );
    return folders;
  };

  return (
    <>
      <div>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          onContextMenu={handleClose}
        >
          {/* <MenuItem>{messageId}</MenuItem> */}
          <IconMenuItem
            disabled={props.readTime === null}
            onClick={handleUnread}
            leftIcon={<MarkEmailUnreadIcon />}
            label={
              t("Mark as unread") +
              (numSelected > 1 ? " (" + numSelected + ")" : "")
            }
          />
          {props.folderId !== "-1" && (
            <IconMenuItem
              onClick={handleDelete}
              leftIcon={<DeleteIcon />}
              label={
                t("Delete") + (numSelected > 1 ? " (" + numSelected + ")" : "")
              }
            />
          )}
          {props.folderId === "-1" && (
            <IconMenuItem
              onClick={handleRemove}
              leftIcon={<DeleteForeverIcon />}
              label={
                t("Permanently delete") +
                (numSelected > 1 ? " (" + numSelected + ")" : "")
              }
            />
          )}
          <NestedMenuItem
            label={t("Move")}
            parentMenuOpen={!!contextMenu}
            leftIcon={<DriveFileMoveOutlinedIcon />}
          >
            <FolderList />
          </NestedMenuItem>
          <IconMenuItem
            disabled={numMessages === numSelected}
            onClick={handleSelectAll}
            leftIcon={<CheckCircleOutlineIcon />}
            label={t("Select all")}
          />
          <IconMenuItem
            disabled={numSelected === 0}
            onClick={handleUnselectAll}
            leftIcon={<HighlightOffIcon />}
            label={t("Unselect all")}
          />
        </Menu>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageMenu);
