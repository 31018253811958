import React from "react";
import { connect } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import { icons } from "Constants";
import { hexToRGB } from "Components/Utilities"
import ExampleDayFrame from "./ExampleDayFrame";

const useStyles = makeStyles((theme) => ({
  colorExample: {
    display: "inline-flex",
    width: "10rem",
    alignSelf: "center"
  },
}));

const mapStateToProps = (state) => {
  return {
    normalDay: state.settings.DisplaySettings.find(
      (D) => D.component === "Working days"
    ).settings,
  };
};

function ColorExample({ component, componentProps, ...props }) {
  const classes = useStyles();
  const normalColor = props.normalDay?.backgroundColor;

  // Get componentProps props
  const identifier = componentProps.identifier;
  const backgroundColor = componentProps.backgroundColor;
  const backgroundFactor1 = componentProps.backgroundFactor1;
  const backgroundFactor2 = componentProps.backgroundFactor2;
  const useFontColor = componentProps.useFontColor === 1;
  const fontColor = componentProps.fontColor;
  const fontFactor = componentProps.fontFactor;
  const useIcon = componentProps.useIcon === 1;
  const iconId = componentProps.iconId;
  const animate = componentProps.animate === 1;
  const showAsBar = componentProps.showAsBar === 1;
  const mustBeApproved = componentProps.mustBeApproved || false

  const iconPath = useIcon ? icons.find((I) => parseInt(I.id) === iconId)?.path : "";
  return (
    <div className={classes.colorExample}>
      <ExampleDayFrame
        backgroundColor={normalColor}
        color={useFontColor ? fontColor : "#000000"}
      />
      <ExampleDayFrame
        backgroundColor={
          !showAsBar && component !== "Today" && component !== "Birthdays"
            ? backgroundColor
            : normalColor
        }
        barcolor={showAsBar && component !== "Today" ? backgroundColor : ""}
        color={useFontColor ? hexToRGB(fontColor, fontFactor) : "#000000"}
        shortkey={identifier}
      />
      <ExampleDayFrame
        // backgroundColor={
        //   props.showasbar && props.backgroundcolor === ""
        //     ? normalColor
        //     : props.backgroundcolor
        // }
        backgroundColor={
          !showAsBar && component !== "Today" && component !== "Birthdays"
            ? backgroundColor
            : // : props.type === "Today"
              // ? hexToRGB(props.color, 0.3)
              normalColor
        }
        barcolor={
          showAsBar && component !== "Today"
            ? mustBeApproved
              ? hexToRGB(backgroundColor, backgroundFactor1)
              : backgroundColor
            : ""
        }
        markerColor={backgroundColor}
        color={useFontColor ? hexToRGB(fontColor, fontFactor) : "#000000"}
        shortkey={component === "Working days" ? "XX" : identifier}
        type={component}
        icon={iconPath}
        animate={animate}
      ></ExampleDayFrame>
      <ExampleDayFrame
        backgroundColor={
          !showAsBar &&
          component !== "Weekend" &&
          component !== "Today" &&
          component !== "Birthdays"
            ? backgroundColor
            : normalColor
        }
        barcolor={
          showAsBar && component !== "Today"
            ? mustBeApproved
              ? hexToRGB(backgroundColor, backgroundFactor2)
              : backgroundColor
            : ""
        }
        color={useFontColor ? hexToRGB(fontColor, fontFactor) : "#000000"}
        shortkey={identifier}
      />
      <ExampleDayFrame
        backgroundColor={normalColor}
        color={useFontColor ? fontColor : "#000000"}
      />
    </div>
  );
}

export default connect(mapStateToProps, null)(ColorExample);
