import {
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE,
  FETCH_ABSENCE_STATS_REQUEST,
  FETCH_ABSENCE_STATS_SUCCESS,
} from 'actions/types'

import { userStats, absenceStats } from '../api'

import {
  dataStartLoading,
  dataEndLoading,
} from "./general";

const fetchStatsFailure = (err) => {
  return {
    type: FETCH_STATS_FAILURE,
    payload: err
  }
}

const fetchStatsRequest = () => {
  return {
    type: FETCH_STATS_REQUEST
  }
}

const fetchStatsSuccess = (stats) => {
  return {
    type: FETCH_STATS_SUCCESS,
    payload: stats
  }
}

export const fetchStats = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchStatsRequest())
    try {
      const statsJSONData = await dispatch(userStats(values))
      await dispatch(fetchStatsSuccess(statsJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(fetchStatsFailure(err))
      dispatch(dataEndLoading())
    }
  }
}
const fetchAbsenceStatsRequest = () => {
  return {
    type: FETCH_ABSENCE_STATS_REQUEST
  }
}

const fetchAbsenceStatsSuccess = (stats) => {
  return {
    type: FETCH_ABSENCE_STATS_SUCCESS,
    payload: stats
  }
}

export const fetchAbsenceStats = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchAbsenceStatsRequest())
    try {
      const statsJSONData = await dispatch(absenceStats(values))
      await dispatch(fetchAbsenceStatsSuccess(statsJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(fetchStatsFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

