import {
  FETCH_REMINDERS_REQUEST,
  FETCH_REMINDERS_SUCCESS,
  SAVE_REMINDER,
  DELETE_REMINDER,
  REMINDERS_FAILURE,
  SET_SELECTED_REMINDER,
  SET_DISPLAY_REMINDER,
  SET_REMINDER_ALERT,
  OPEN_REMINDER_DIALOG,
} from "actions/types";
import produce from "immer";

const initialState = {
  Reminders: [],
  loading: false,
  error: "",
  displayReminder: 0,
  displayDate: null,
  selectedReminder: -2,
  showReminderDate: null
};

const remindersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_REMINDERS_REQUEST:
        draft.loading = true;
        break;
      case FETCH_REMINDERS_SUCCESS:
        draft.Reminders = action.payload;
        draft.selectedReminder = -2;
        draft.loading = false;
        draft.error = "";
        break;
      case SAVE_REMINDER:
        if (action.payload.id === 0) {
          // insert new reminder
          draft.Reminders = [...draft.Reminders, action.payload];
        } else {
          // update reminder
          key = draft.Reminders.findIndex((R) => R.id === action.payload.id);
          draft.Reminders[key] = action.payload;
        }
        draft.selectedReminder = -2;
        break;
      case DELETE_REMINDER:
        key = draft.Reminders.findIndex((R) => R.id === action.payload.id);
        draft = { Reminders: draft.Reminders.splice(key, 1) };
        draft.selectedReminder = -2;
        break;
      case REMINDERS_FAILURE:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case SET_SELECTED_REMINDER:
        draft.selectedReminder = action.payload;
        break;
      case SET_DISPLAY_REMINDER:
        draft.displayReminder = action.payload.reminder;
        draft.displayDate = action.payload.displayDate;
        break;
      case SET_REMINDER_ALERT:
        key = draft.Reminders.findIndex((R) => R.id === action.payload.id);
        draft.Reminders[key].alertSent = true;
        break;
      case OPEN_REMINDER_DIALOG:
        draft.showReminderDate = action.payload
        break
      default:
        return state;
    }
  });

export default remindersReducer;
