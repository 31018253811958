import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "redux/store";
import moment from "moment";
import { publishOffDays, fetchUnpublishedDays, fetchYear } from "redux/index";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

import Paper from "@mui/material/Paper";

import makeStyles from "@mui/styles/makeStyles";

import DialogTitle from "Calendars/Components/DialogTitle";
import { Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "hidden",
  },
  title: {
    width: 550,
    marginTop: "6px",
    fontSize: "1.5rem",
    textAlign: "center",
    paddingBottom: "0",
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
  loginFrame: {
    textAlign: "center",
    fontSize: "1rem",
  },
  username: {
    fontSize: "1rem",
    textAlign: "center",
    paddingTop: "0",
    width: "100%",
  },
  change: {
    paddingLeft: "6px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  displayDate: {
    fontSize: "1rem",
  },
  displayDays: {
    fontSize: "0.8rem",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ width: 550 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    displayyear: state.general.DisplayYear,
    selectedPersonId: state.yearcalendar.displayUserId,
    logged: state.loggeduser.LoggedUser.id,
    loginState: state.loggeduser.LoginState,
    offDayTypes: state.offdaytypes.OffDayTypes,
    unpublishedDays: state.unpublisheddays.UnpublishedDays,
    dataSuccess: state.general.dataSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    publishOffDays: (values) =>
      dispatch(publishOffDays({ approvementId: values })),
    fetchUnpublishedDays: (id) =>
      dispatch(fetchUnpublishedDays({ userId: id })),
    fetchYear: (values) => dispatch(fetchYear(values)),
  };
};

const UnpublishedDays = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const DayList = () => {
    let items = [];
    let UD;
    let OT;
    let UId;
    let displayDate;
    let displayDays;
    for (var i = 0; i < props.unpublishedDays.length; i++) {
      UD = props.unpublishedDays[i];

      OT =
        props.offDayTypes &&
        // eslint-disable-next-line no-loop-func
        props.offDayTypes.filter((O) => O.id === UD.offDayTypeId);
      UId = UD.approvementId;
      displayDate = moment(UD.startDate).format("dd. LL");
      displayDays = UD.numDays;
      if (UD.numDays !== "1.0") {
        displayDate +=
          " " + t("to") + " " + moment(UD.endDate).format("dd. LL");
        displayDays += " " + t("days") + " ";
      } else {
        displayDays += " " + t("day") + " ";
      }
      displayDays += OT[0].names.find(
        // eslint-disable-next-line no-loop-func
        (OTN) => OTN.language === language
      ).offDayType;
      const labelId = `checkbox-list-label-${UId}`;
      items.push(
        <ListItem
          key={UId}
          role={undefined}
          dense
          button
          onClick={handleToggle(UId)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(UId) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
              color="secondary"
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={
              <Fragment>
                <div className={classes.displayDate}>{displayDate}</div>
                <div className={classes.displayDays}>{displayDays}</div>
              </Fragment>
            }
          />
        </ListItem>
      );
    }
    return <List>{items}</List>;
  };

  const handleSubmit = (values) => {
    for (var i = 0; i < checked.length; i++) {
      props.publishOffDays(checked[i]);
    }
    props.fetchUnpublishedDays(props.logged);
    store.dispatch({ type: "DATA_SUCCESS" });
    props.closeUnpublishedDays();
    props.fetchYear({
      year: props.displayyear,
      userid: props.selectedPersonId,
    });
  };

  const handleCancel = () => {
    props.closeUnpublishedDays();
  };

  return (
    <Dialog
      className={classes.root}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        dialogTitle={t("Unpublished days")}
        closeForm={handleCancel}
      />
      <DialogContent className="dialogContent" style={{ padding: "0px" }}>
        <div>{DayList()}</div>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button
          variant="outlined"
          color="cancel"
          startIcon={<ClearIcon />}
          onClick={handleCancel}
        >
          {t("Abort")}
        </Button>
        <Button
          startIcon={<VisibilityIcon />}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={checked.length === 0}
        >
          {t("Publish")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnpublishedDays);
