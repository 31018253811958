import {
  FETCH_REMARKS_REQUEST,
  FETCH_REMARKS_SUCCESS,
  ADD_REMARK,
  UPDATE_REMARK,
  DELETE_REMARK,
  SET_REMARK_ID,
  SET_DISPLAY_REMARKS_IDS
} from "actions/types";
import produce from "immer";

const initialState = {
  Remarks: [],
  loading: false,
  error: "",
  selectedRemarkId: -1,
  displayRemarksIds: []
};

const remarksReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_REMARKS_REQUEST:
        draft.loading = true;
        break;
      case FETCH_REMARKS_SUCCESS:
        draft.Remarks = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case ADD_REMARK:
        draft.Remarks = [...draft.Remarks, action.payload];
        draft.displayRemarksIds = [...draft.displayRemarksIds, action.payload.id]
        draft.selectedRemarkId = -1;
        break;
      case UPDATE_REMARK:
        key = draft.Remarks.findIndex((R) => R.id === action.payload.id);
        draft.Remarks[key] = action.payload;
        draft.selectedRemarkId = -1;
        break;
      case DELETE_REMARK:
        key = draft.Remarks.findIndex((R) => R.id === action.payload.id);
        draft = { Remarks: draft.Remarks.splice(key, 1) };
        draft.selectedRemarkId = -1;
        break;
      case SET_REMARK_ID:
        draft.selectedRemarkId = action.payload;
        break;
      case SET_DISPLAY_REMARKS_IDS:
        draft.displayRemarksIds = action.payload
        break
      default:
        return state;
    }
  });

export default remarksReducer;
