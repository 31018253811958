import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getGlobalSetting } from "Components/Utilities";
import moment from "moment";
import {
  fetchArticles,
  fetchArticleTypes,
  fetchArticleModels,
  fetchMovementTypes,
  fetchLocations,
  fetchReasons,
  fetchStates,
  setSelectedArticleId,
} from "redux/index";
import { sortByDate } from "Components/Utilities";
import SomuraDataGrid from "Components/SomuraDataGrid";
import ReturnLoanAssetDialog from "./ReturnLoanAssetDialog";

const mapStateToProps = (state) => {
  const personId = state.persons.personId;
  const articleId = state.articles.selectedArticleId;
  const articles = state.articles.Articles.filter(
    (A) => A.movements[0].personId === personId
  );
  return { articleId, articles };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticles: () => dispatch(fetchArticles()),
    fetchArticleTypes: () => dispatch(fetchArticleTypes()),
    fetchArticleModels: () => dispatch(fetchArticleModels()),
    fetchMovementTypes: () => dispatch(fetchMovementTypes()),
    fetchLocations: () => dispatch(fetchLocations()),
    fetchReasons: () => dispatch(fetchReasons()),
    fetchStates: () => dispatch(fetchStates()),
    setArticleId: (id) => dispatch(setSelectedArticleId(id)),
  };
};

function LoanAssetsTable({
  articleId,
  articles,
  fetchArticles,
  fetchArticleTypes,
  fetchArticleModels,
  fetchMovementTypes,
  fetchLocations,
  fetchReasons,
  fetchStates,
  setArticleId,
}) {
  const { t } = useTranslation();
  const useInventoryNumbers =
    parseInt(getGlobalSetting("articles", "useInventoryNumbers")) === 1;

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Article type"),
      headerClassName: "tableHeader",
      field: "articleTypeName",
      flex: 0.2,
    },
    {
      headerName: t("Model"),
      headerClassName: "tableHeader",
      field: "modelName",
      flex: 0.2,
    },

    {
      headerName: t("Inventory number"),
      headerClassName: "tableHeader",
      field: "inventoryNumber",
      flex: 0.2,
      hide: !useInventoryNumbers,
    },
    {
      headerName: t("Manufacturer serial number"),
      headerClassName: "tableHeader",
      field: "manufacturerSerialNumber",
      flex: 0.2,
    },
    {
      headerName: t("Handout date"),
      headerClassName: "tableHeader",
      field: "handoutDate",
      sortComparator: (a, b) => sortByDate(a, b),
      flex: 0.2,
    },
  ];

  const rows = articles?.map((L) => ({
    id: L.id,
    modelName: L.modelName,
    articleTypeName: L.articleTypeName,
    manufacturerSerialNumber: L.manufacturerSerialNumber,
    inventoryNumber: L.inventoryNumber,
    handoutDate: moment(L.movements[0].movementDate).format("L"),
    typeId: L.typeId,
    invalidMovements:
      L.movements.length > 0 &&
      L.movements[L.movements.length - 1].firstMovement === false,
  }));

  const handleRowClick = (params) => {
    setArticleId(params);
  };

  const handleCloseDialog = () => {
    setArticleId("-1");
  };

  useEffect(() => {
    fetchArticles();
    fetchArticleTypes();
    fetchArticleModels();
    fetchMovementTypes();
    fetchLocations();
    fetchReasons();
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SomuraDataGrid
        tableHeight={"calc(100vh - 420px)"}
        rows={rows}
        columns={columns}
        defaultSortModel={[
          {
            field: "handoutDate",
            sort: "asc",
          },
        ]}
        onRowClick={(params) => handleRowClick(params)}
        csvFileName={t("Employees")}
      />
      {articleId > 0 && <ReturnLoanAssetDialog close={handleCloseDialog} />}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanAssetsTable);
