import Fab from "@mui/material/Fab";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1rem",
    position: "relative",
  },
}));

const AddButton = (props) => {
  const classes = useStyles();
  return (
    <Fab
      variant="extended"
      color="primary"
      size="medium"
      className={classes.root}
      onClick={props.onClick}
    >
      <AddIcon />
    </Fab>
  );
};

export default AddButton;
