import React from "react";
import { connect } from "react-redux";
import { setNewPasswordCompleted } from "redux/index";
import { makeStyles } from "@mui/styles";
import { Grid, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "1rem",
    textAlign: "center",
    paddingBottom: "0",
  },
  signInButton: {
    width: "50%",
    marginLeft: "25% !important",
    marginTop: "20px !important",
    marginBottom: "20px !important",
  },
  change: {
    textDecoration: "none",
    color: theme.palette.text.screenTitle,
    cursor: "pointer",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 500 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    loginName: state.loggeduser.LoginName,
    mailAdmin: state.company.Company.emailAdmin,
    persons: state.persons.Persons,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewPasswordCompleted: (values) =>
      dispatch(setNewPasswordCompleted(values)),
  };
};

const NewPassword = (props) => {
  const classes = useStyles();
  const userId = props.persons.find((P) => P.emailCompany === props.requestMail)
    .id;

  const handleOK = () => {
    props.setNewPasswordCompleted({ id: props.messageId, userId: userId });
    props.setNewPassword(false);
  };

  const handleCancel = () => {
    props.setNewPassword(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle dialogTitle="Passwort vergessen" />
      <DialogContent>
        <div className={classes.loginFrame}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} className={classes.text}>
              <span>
                Bitte senden Sie jetzt eine Information an <br />
                <b>
                  <a
                    href={"mailto:" + props.requestMail}
                    className={classes.change}
                  >
                    {props.requestMail}
                  </a>
                </b>
                <br />
                dass beim nächsten Login ein neues Passwort vergeben werden
                muss.
                <br />
              </span>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<ClearIcon />}
                onClick={() => {
                  handleCancel();
                }}>
                Abbrechen
              </Button>
              <Button
                className={classes.signInButton}
                color="primary"
                size="large"
                variant="contained"
                onClick={handleOK}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
