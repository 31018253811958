import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { initializeDatabase, fetchGlobalSettings } from "redux/index";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { AppContext } from "./Components/context";

const drawerWidth = 240;
const appBarHeight = 64;

const styles = (theme) => ({
  root: {
    display: "flex",
    flex: 1,
    backgroundColor: theme.palette.background.default,
    fontWeight: 300,
    overflow: "hidden",
  },
  "@global": {
    ".hidden": {
      display: "none",
    },
    ".formHeader": {
      textAlign: "center",
      borderBottom: "2px solid grey",
      padding: "4px",
      fontSize: "22px",
      minHeight: "35px",
      lineHeight: "25px",
      color: theme.palette.text.screenTitle,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    ".content": {
      width: `calc(100% - ${drawerWidth}px)`,
      marginTop: appBarHeight,
      flexGrow: 1,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    ".contentPaper": {
      maxHeight: "calc(100vh - 112px)",
    },
    ".contentShift": {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    ".tabPanel": {
      backgroundColor: theme.palette.background.paperBottom,
    },
    ".paperBottom": {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "10px",
      margin: "10px 0 -10px",
      backgroundColor: theme.palette.background.paperBottom,
    },
    ".formBottom": {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "10px",
      margin: "10px -10px -10px",
      backgroundColor: theme.palette.background.paperBottom,
    },
    ".centeredColumnHead": {
      "& > span": {
        justifyContent: "center",
      },
    },
    ".dialogTitle": {
      padding: "4px 10px !important",
      fontWeight: "200",
      color: theme.palette.text.screenTitle,
    },
    ".dialogContent": {
      padding: "0px !important",
      // padding: "4px 10px !important",
      backgroundColor: theme.palette.dialog.content,
    },
    ".dialogActions": {
      padding: "4px 10px !important",
      backgroundColor: theme.palette.dialog.bottom,
    },
    ".timeFrame": {
      textAlign: "center",
      padding: "4px",
      fontSize: "1rem",
      minHeight: "35px",
      color: theme.palette.text.screenTitle,
    },
    ".subLine": {
      fontSize: "1rem",
      height: "1.5rem",
      lineHeight: "1.6rem",
      paddingLeft: "1rem",
      marginBottom: "6px",
      color: theme.palette.text.screenTitle,
      backgroundColor: theme.palette.background.paperBottom,
      borderRadius: "5px",
      // margin: "0 -1rem",
      boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
    },
    ".noEntries": {
      fontSize: "1rem",
      height: "2.5rem",
      lineHeight: "1.6rem",
      paddingLeft: "1rem",
      margin: "0 2rem 6px 2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.screenTitle,
      backgroundColor: theme.palette.background.paperBottom,
      borderRadius: "5px",
      // margin: "0 -1rem",
      boxShadow: "1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
    },
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    initializeDatabase: () => dispatch(initializeDatabase()),
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
  };
};

const App = ({ classes, ...props }) => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  const routesProps = {
    isAuthenticated,
    userHasAuthenticated,
  };

  useEffect(() => {
    props.initializeDatabase();
    props.fetchGlobalSettings();
    userHasAuthenticated(localStorage.getItem("jwt"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Routes childProps={routesProps} />
      </AppContext.Provider>
    </div>
  );
};

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles)(App))
);
