import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchCompany, updateCompany } from "redux/index";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";

import * as Yup from "yup";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import EditButton from "Components/Buttons/EditButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  TableContainer: {
    maxHeight: "70vh",
    marginBottom: 8,
  },
  TableRow: {
    cursor: "pointer",
  },
  thisForm: {
    paddingBottom: "6px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    opacity: "0.8",
  },
  tabPanel: {
    backgroundColor: "#ECECEC",
  },
}));

const CompanySchema = Yup.object().shape({
  companyName: Yup.string().required("Pflichtfeld"),
  emailAdmin: Yup.string().required("Pflichtfeld"),
});

const mapStateToProps = (state) => {
  return {
    companyData: state.company.Company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompany: () => dispatch(fetchCompany()),
    updateCompany: (values) => dispatch(updateCompany(values)),
  };
};

function CompanyForm({companyData, ...props}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [valid, setValid] = useState(false);
  const emptyState = {
    companyName: "",
    companyStreet: "",
    companyNumber: "",
    companyCountry: "",
    companyZip: "",
    companyCity: "",
    companyTelephone: "",
    companyFax: "",
    companyWeb: "",
    companyMail: "",
    emailAdmin: "",
  };
  const [state, setState] = useState(emptyState);

  const [formDisabled, setFormDisabled] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await CompanySchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSubmit = () => {
    props.updateCompany(state);
    setFormDisabled(true);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "edit":
        setFormDisabled(false);
        break;
      case "cancel":
        setFormDisabled(true);
        break;
      default:
    }
  };

  useEffect(() => {
    props.fetchCompany();
    setFormDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyData) {
      setState({ ...companyData });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  return (
    <>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <SomuraTextField
              type="text"
              name="companyName"
              label={t("Company name")}
              value={state.companyName}
              maxlength="50"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="companyStreet"
              label={t("Street")}
              value={state.companyStreet}
              maxlength="50"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={2}>
            <SomuraTextField
              type="text"
              name="companyNumber"
              label={t("Number")}
              value={state.companyNumber}
              maxlength="10"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <SomuraTextField
              type="text"
              name="companyCountry"
              label={t("Country")}
              value={state.companyCountry}
              maxlength="3"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={2}>
            <SomuraTextField
              type="text"
              name="companyZip"
              label={t("ZIP")}
              value={state.companyZip}
              maxlength="6"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraTextField
              type="text"
              name="companyCity"
              label={t("City")}
              value={state.companyCity}
              maxlength="50"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="companyTelephone"
              label={t("Phone number")}
              value={state.companyTelephone}
              maxlength="30"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="companyFax"
              label={t("Fax number")}
              value={state.companyFax}
              maxlength="30"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="companyWeb"
              label={t("Web address")}
              value={state.companyWeb}
              maxlength="100"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="email"
              name="companyMail"
              label={t("Mail address")}
              value={state.companyMail}
              maxlength="100"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="email"
              name="emailAdmin"
              label={t("Administrator mail address")}
              value={state.emailAdmin}
              maxlength="255"
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
        </Grid>
        {!formDisabled ? (
          <div className="paperBottom">
            <CancelButton
              onClick={() => {
                handleButtonClick("cancel");
              }}
            />
            <SaveButton disabled={!valid} onClick={handleSubmit} />
          </div>
        ) : (
          <div className="formBottom">
            <EditButton onClick={() => handleButtonClick("edit")} />
          </div>
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
