import React from 'react'
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import palette from 'theme/palette'

const useStyles = makeStyles((theme) => ({
  screenTitle: {
    top: "-1.5em",
    marginLeft: "10px",
    marginTop: "1em",
    position: "relative",
    gridRow: '1',
    gridColumn: '1',
    display: 'grid',
    gridTemplateColumns: '5em 1fr',
    gridTemplateRows: '2em 2em'
  },
  screenTitleIcon: {
    gridRow: '1 / 4',
    gridColumn: '1',
    width: '4rem',
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    padding: '1em',
    borderRadius: '3px',
    '&.calendar': {
      background: `linear-gradient(60deg, ${palette.calendarIcon.default}, ${palette.calendarIcon.gradient})`
    },
    '&.masterdata': {
      background: `linear-gradient(60deg, ${palette.masterdataIcon.default}, ${palette.masterdataIcon.gradient})`
    },
    '&.dashboard': {
      background: `linear-gradient(60deg, ${palette.dashboardIcon.default}, ${palette.dashboardIcon.gradient})`
    },
    '&.settings': {
      background: `linear-gradient(60deg, ${palette.settingsIcon.default}, ${palette.settingsIcon.gradient})`
    },
    '&.articles': {
      background: `linear-gradient(60deg, ${palette.articlesIcon.default}, ${palette.articlesIcon.gradient})`
    },
    '&.messages': {
      background: `linear-gradient(60deg, ${palette.messageIcon.default}, ${palette.messageIcon.gradient})`
    }             
  },
  screenTitleText: {
    gridRow: '2',
    gridColumn: '2',
    fontWeight: '100',
    fontSize: '2rem',
    color: theme.palette.text.screenTitle,
    alignSelf: 'center',
    marginLeft: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem'
    } 
  },
}))

function ScreenTitle(props){

  const classes = useStyles()

  const { t } = useTranslation();

  return(
    <>
    <div className={classes.screenTitle}>
      <span 
        className={classes.screenTitleIcon + " " + props.class}
        // viewBox="0 0 24 24"
      >
        {props.icon}
      </span>
      <div className={classes.screenTitleText}>
        <span>{t(props.title)}</span>
      </div>
    </div>
    </>
  )
}

export default ScreenTitle