import React, { useState } from "react";
import { connect } from "react-redux";
import { setGlobalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getGlobalSetting } from "Components/Utilities";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSwitchField from "Components/SomuraSwitchField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalSettings: (values) => dispatch(setGlobalSettings(values)),
  };
};

const PasswordsForm = ({ setGlobalSettings }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "passwords";
  const [state, setState] = useState({
    minLength: parseInt(getGlobalSetting(component, "minLength")),
    useCapitalChars:
      parseInt(getGlobalSetting(component, "useCapitalChars")) === 1,
    minCapitalChars: getGlobalSetting(component, "minCapitalChars"),
    useNormalChars:
      parseInt(getGlobalSetting(component, "useNormalChars")) === 1,
    minNormalChars: getGlobalSetting(component, "minNormalChars"),
    useNumbers: parseInt(getGlobalSetting(component, "useNumbers")) === 1,
    minNumbers: getGlobalSetting(component, "minNumbers"),
    useSpecialChars:
      parseInt(getGlobalSetting(component, "useSpecialChars")) === 1,
    minSpecialChars: getGlobalSetting(component, "minSpecialChars"),
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value.toString(),
    });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value ? "1" : "0",
    });
  };

  return (
    <div className={classes.thisForm}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <div className="lineLabel">{t("Password length")}</div>
        </Grid>
        <Grid item xs={3}>
          <SomuraTextField
            type="number"
            value={state.minLength}
            onChange={handleChange}
            min="1"
            max="100"
            name="minLength"
            label={t("Minimum length")}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <div className="lineLabel">{t("Capital letters")}</div>
        </Grid>
        <Grid item xs={3}>
          <SomuraSwitchField
            name="useCapitalChars"
            checked={state.useCapitalChars}
            onChange={handleSwitchChange}
            label={t("required")}
          />
        </Grid>
        <Grid item xs={3}>
          <SomuraTextField
            type="number"
            value={state.minCapitalChars}
            onChange={handleChange}
            min="1"
            max="10"
            name="minCapitalChars"
            label={t("Minimum number")}
            disabled={!state.useCapitalChars}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <div className="lineLabel">{t("Lowercase letters")}</div>
        </Grid>
        <Grid item xs={3}>
          <SomuraSwitchField
            name="useNormalChars"
            checked={state.useNormalChars}
            onChange={handleSwitchChange}
            label={t("required")}
          />
        </Grid>
        <Grid item xs={3}>
          <SomuraTextField
            type="number"
            value={state.minNormalChars}
            onChange={handleChange}
            min="1"
            max="10"
            name="minNormalChars"
            label={t("Minimum number")}
            disabled={!state.useNormalChars}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <div className="lineLabel">{t("Digits")}</div>
        </Grid>
        <Grid item xs={3}>
          <SomuraSwitchField
            name="useNumbers"
            checked={state.useNumbers}
            onChange={handleSwitchChange}
            label={t("required")}
          />
        </Grid>
        <Grid item xs={3}>
          <SomuraTextField
            type="number"
            value={state.minNumbers}
            onChange={handleChange}
            min="1"
            max="10"
            name="minNumbers"
            label={t("Minimum number")}
            disabled={!state.useNumbers}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <div className="lineLabel">{t("Special characters")}</div>
        </Grid>
        <Grid item xs={3}>
          <SomuraSwitchField
            name="useSpecialChars"
            checked={state.useSpecialChars}
            onChange={handleSwitchChange}
            label={t("required")}
          />
        </Grid>
        <Grid item xs={3}>
          <SomuraTextField
            type="number"
            value={state.minSpecialChars}
            onChange={handleChange}
            min="1"
            max="10"
            name="minSpecialChars"
            label={t("Minimum number")}
            disabled={!state.useSpecialChars}
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(PasswordsForm);
