import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as Yup from "yup";
import { terminateDay, terminateDayUser } from "redux/index";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";

import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";

import Paper from "@mui/material/Paper";
import CancelButton from "Components/Buttons/CancelButton";
import OKButton from "Components/Buttons/OKButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    maxWidth: 700,
  },
  dateInput: {
    // margin: "20px 0",
    // "& Input": {
    //   height: "1.4rem",
    //   fontSize: "1rem",
    //   textAlign: "center",
    //   padding: 0,
    // },
  },
  dialogContent: {
    width: 400,
    padding: "10px 5px",
    backgroundColor: theme.palette.dialog.content,
  },
  formTitle: {
    textAlign: "center",
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
  okButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-selectionform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    persons: state.persons.PersonsMin.filter(
      (P) => P.lastName !== "" && P.active === true
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    terminateDayUser: (values) => dispatch(terminateDayUser(values)),
    terminateDay: (values) => dispatch(terminateDay(values)),
  };
};

const DayTerminationDialog = ({
  persons,
  terminateDay,
  terminateDayUser,
  ...props
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    date: moment().subtract(1, "days").format("DD.MM.YYYY"),
    userId: props.userId,
  });
  const [valid, setValid] = useState(false);
  const { t } = useTranslation();

  var personOptions = [{ label: t("All employees"), value: "0" }];
  personOptions = personOptions.concat(
    persons?.map((P) => ({
      label: P.listName,
      value: P.id,
    }))
  );

  const terminateSchema = Yup.object().shape({
    date: Yup.date().max(moment().subtract(1, "days")).required(t("Required")),
  });

  const handleClose = () => {
    store.dispatch({ type: "OPEN_TERMINATION", payload: false });
  };

  const handleSave = () => {
    if (state.userId === "0") {
      terminateDay(state);
    } else {
      terminateDayUser(state);
    }
    store.dispatch({ type: "OPEN_TERMINATION", payload: false });
    props.onClose();
  };

  const handleDateChange = (event) => {
    setState({ ...state, date: event.target.value });
  };

  const handleAutoCompleteChange = (params) => {
    const value = params.value;
    setState({ ...state, userId: value });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await terminateSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setState({
      date: moment().subtract(1, "days").format("YYYY-MM-DD"),
      userId: props.userId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      // classes={{ paper: classes.dialogPosition }}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-title"
        dialogTitle={t("Day termination")}
        closeForm={handleClose}
        backgroundColor="#243573"
      />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.formTitle}>
              {t("Run day termination for")}
            </div>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <SomuraAutocomplete
              name="person"
              label={t("Employee")}
              value={state.userId}
              variant="outlined"
              size="small"
              fullWidth
              disableClearable={true}
              options={personOptions}
              onChange={(value) => handleAutoCompleteChange(value)}
              disabled={props.userId === "0"}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <SomuraTextField
              className={classes.dateInput}
              autoFocus
              type="date"
              name="date"
              value={state.date}
              max={moment().subtract(1, "days").format("YYYY-MM-DD")}
              label={t("Date")}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialogActions">
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <OKButton onClick={handleSave} disabled={!valid} />
      </DialogActions>
    </Dialog>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DayTerminationDialog);
