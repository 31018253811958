import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { sortByDate } from "Components/Utilities";
import { setSelectedArticleId } from "redux/index";
import { getGlobalSetting } from "Components/Utilities";
import Collapse from "@mui/material/Collapse";
import makeStyles from "@mui/styles/makeStyles";
import SomuraTooltip from "Components/SomuraTooltip";
import SomuraDataGrid from "Components/SomuraDataGrid";
import Fab from "@mui/material/Fab";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddIcon from "@mui/icons-material/Add";
import ArticlesForm from "./ArticlesForm";

const useStyles = makeStyles((theme) => ({
  freeStock: {
    color: theme.palette.lightgreen,
  },
  errorIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "4px",
    marginRight: "4px",
    color: theme.palette.red,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  const articles = state.articles.Articles;
  const selectedId = state.articles.selectedArticleId;
  return { articles, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setId: (id) => dispatch(setSelectedArticleId(id)),
  };
};

function ArticlesTable({ articles, selectedId, setId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const useInventoryNumbers =
    parseInt(getGlobalSetting("articles", "useInventoryNumbers")) === 1;

  const title = () => {
    return (
      <span className={classes.errorSpan}>
        <ErrorOutlineIcon className={classes.errorIcon} />
        {t("Invalid movement sequence!")}
      </span>
    );
  };

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Article type"),
      headerClassName: "tableHeader",
      field: "articleTypeName",
      flex: 0.2,
    },
    {
      headerName: t("Model"),
      headerClassName: "tableHeader",
      field: "modelName",
      flex: 0.2,
    },
    {
      headerName: t("Inventory number"),
      headerClassName: "tableHeader",
      field: "inventoryNumber",
      flex: 0.2,
      hide: !useInventoryNumbers,
    },
    {
      headerName: t("Manufacturer serial number"),
      headerClassName: "tableHeader",
      field: "manufacturerSerialNumber",
      flex: 0.2,
    },
    {
      headerName: t("Current location"),
      headerClassName: "tableHeader",
      field: "locationName",
      renderCell: (params) => {
        return (
          <span className={params.row.isFreeStock ? classes.freeStock : ""}>
            {params.value}
            {params.row.invalidMovements && (
              <SomuraTooltip title={title()} placement="right">
                <ErrorOutlineIcon className={classes.errorIcon} />
              </SomuraTooltip>
            )}
          </span>
        );
      },
      flex: 0.2,
    },
    {
      headerName: t("Handout date"),
      headerClassName: "tableHeader",
      field: "handoutDate",
      sortComparator: (a, b) => sortByDate(a, b),
      hide: true,
    },
  ];

  const rows = articles?.map((L) => ({
    id: L.id,
    modelName: L.modelName,
    articleTypeName: L.articleTypeName,
    inventoryNumber: L.inventoryNumber,
    manufacturerSerialNumber: L.manufacturerSerialNumber,
    locationName:
      L.movements.length > 0
        ? L.movements[0].personId === "0"
          ? L.movements[0].locationName
          : L.movements[0].personName
        : "",
    isFreeStock: L.movements.length > 0 ? L.movements[0].isFreeStock : "",
    handoutDate: moment(L.handoutDate).format("L"),
    typeId: L.typeId,
    invalidMovements:
      L.movements.length > 0 &&
      L.movements[L.movements.length - 1].firstMovement === false,
  }));

  const handleRowClick = (params) => {
    setId(params);
  };

  const handleCreate = () => {
    setId(0);
  };

  return (
    <div>
      <Collapse in={selectedId < 0} timeout={"auto"}>
        <SomuraDataGrid
          tableHeight={"calc(100vh - 260px)"}
          rows={rows}
          columns={columns}
          defaultSortModel={[
            {
              field: "articleTypeName",
              sort: "asc",
            },
          ]}
          onRowClick={(params) => handleRowClick(params)}
          csvFileName={t("Articles")}
        />
        <Fab
          variant="extended"
          color="primary"
          size="medium"
          className={classes.fab}
          onClick={handleCreate}
        >
          <AddIcon />
          {t("New article")}
        </Fab>
      </Collapse>
      <Collapse in={selectedId >= 0} timeout={"auto"}>
        <ArticlesForm />
      </Collapse>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesTable);
