import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchRemarkableDays, setRemarkableDayId } from "redux/index";
import { sortByDate } from "Components/Utilities";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import "moment/locale/de.js";
import SomuraDataGrid from "Components/SomuraDataGrid";

import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
  alwaysShow: {
    color: theme.palette.blue,
  },
  showDay: {
    color: theme.palette.green,
  },
  hideDay: {
    color: theme.palette.red,
  },
}));

const mapStateToProps = (state) => {
  return {
    remarkableDays: state.specialdays.RemarkableDays,
    loggedAdmin: state.loggeduser.LoggedUser.isadmin,
    loggedAssistant: state.loggeduser.LoggedUser.isassistant,
    dataLoading: state.specialdays.loading,
    dataError: state.specialdays.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRemarkableDays: () => dispatch(fetchRemarkableDays()),
    setRemarkableDayId: (id) => dispatch(setRemarkableDayId(id)),
  };
};

const RemarkableDaysTable = ({
  remarkableDays,
  setRemarkableDayId,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: t("Show"),
      headerClassName: "tableHeader",
      field: "showDay",
      filterable: true,
      renderCell: (params) => {
        switch (params.value) {
          case 1:
            return <LockRoundedIcon className={classes.alwaysShow} />;
          case 2:
            return <VisibilityRoundedIcon className={classes.showDay} />;
          default:
            return <VisibilityOffRoundedIcon className={classes.hideDay} />;
        }
      },
      flex: 0.2,
    },
    {
      headerName: t("Designation"),
      headerClassName: "tableHeader",
      field: "dayName",
      filterable: false,
      flex: 0.5,
    },
    {
      headerName: t("Next occurence"),
      headerClassName: "tableHeader",
      field: "nextDate",
      sortComparator: (a, b) => sortByDate(a, b),
      type: "date",
      filterable: true,
      flex: 0.25,
    },
  ];

  const rows = remarkableDays.map((RD) => ({
    id: RD.id,
    showDay: RD.alwaysUseThisDay ? 1 : RD.useThisDay ? 2 : 3,
    dayName: RD.dayName,
    nextDate: moment(RD.nextDate).format("L"),
  }));

  const handleRowClick = (params) => {
    setRemarkableDayId(params);
  };

  useEffect(() => {
    props.fetchRemarkableDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SomuraDataGrid
        tableHeight="65vh"
        rows={rows}
        columns={columns}
        defaultSortModel={[
          {
            field: "nextDate",
            sort: "asc",
          },
        ]}
        onRowClick={(params) => handleRowClick(params)}
        csvFileName={t("Special days")}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemarkableDaysTable);
