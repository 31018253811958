import React, {useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {createSystemMessage}  from 'Components/Utilities'
import { connect } from 'react-redux'
import { store } from 'redux/store'
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Button
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    textAlign: "center",
    paddingBottom: "0",
  },
  signInButton: {
    width: "100%",
    margin: "20px 0 !important",
  },
}));

const mapStateToProps = state => {
  return {
    loginName: state.loggeduser.LoginName,
    mailAdmin: state.company.Company.emailAdmin
  }
}

const WaitForPassword = (props) => {
  const classes = useStyles()
  const { t } = useTranslation();

  const handleOK = () => {
    store.dispatch({type:'SET_LOGIN_STATE', payload: '0'})
  }

  useEffect(() => {
    createSystemMessage({
      messageType: 'newPassword',
      senderMail: props.loginName
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  

  return (
    <div className={classes.loginFrame}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <div className={classes.title}>
            {t("I can't remember my password")}
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <span className={classes.text}>
            {t(
              "Once the administrator has approved your request for a new password, you will receive an email with appropriate information."
            )}
            <br />
            <br />
            {t(
              "In case you didn't receive this email, check your spam-folder or contact "
            )}
            <br />
            <a href={"mailto:" + props.mailAdmin} className={classes.change}>
              {props.mailAdmin}
            </a>
            <br />
            <br />
            {t("For security reasons your old password was deleted.")}
          </span>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Button
            className={classes.signInButton}
            color="primary"
            size="large"
            variant="contained"
            onClick={handleOK}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default connect(mapStateToProps, null)(WaitForPassword)