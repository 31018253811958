import {
  SET_EDIT_MODE,
  SET_DRAWER_OPEN,
  SET_DISPLAY_YEAR,
  SET_DISPLAY_MONTH,
  SET_HOVER_DATE,
  SET_COUNTER,
  SET_SYSTEM_LANGUAGE,
  SHOW_MESSAGE,
  DATA_FAILURE,
  DATA_SUCCESS,
  DATA_RESET,
  DATA_LOADING,
  DATA_NOT_LOADING,
  SET_PROGRESS_VALUE,
  SHOW_CONTEXT_MENU,
  ACTION_SUCCESS
} from "actions/types";

import produce from "immer";
import moment from "moment";

const initialState = {
  EditMode: false,
  drawerOpen: true,
  DisplayYear: moment().format("YYYY"),
  DisplayMonth: moment().format("M"),
  mouseOverDate: "",
  counter: 0,
  showMessage: null,
  loading: false,
  dataLoading: 0,
  error: "",
  dataError: false,
  dataSuccess: false,
  actionSuccess: false,
  progressValue: 0,
  language: "de-DE",
  languageShort: "DEU",
  accordionExpanded: false,
  showContextMenu: null
};

const generalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    // eslint-disable-next-line
    switch (action.type) {
      case DATA_SUCCESS:
        draft.dataError = false;
        draft.dataSuccess = true;
        if (draft.dataLoading > 0) {
          draft.dataLoading--;
        }
        draft.error = "";
        break;
      case DATA_FAILURE:
        draft.dataError = true;
        draft.dataSuccess = false;
        draft.error = action.payload;
        break;
      case DATA_RESET:
        draft.dataError = false;
        draft.dataSuccess = false;
        draft.actionSuccess = false;
        break;
      case SET_EDIT_MODE:
        draft.EditMode = action.payload;
        break;
      case SET_DRAWER_OPEN:
        draft.drawerOpen = action.payload;
        break;
      case SET_DISPLAY_YEAR:
        draft.DisplayYear = action.payload;
        break;
      case SET_DISPLAY_MONTH:
        draft.DisplayMonth = action.payload;
        break;
      case SET_HOVER_DATE:
        draft.mouseOverDate = action.payload;
        break;
      case SET_COUNTER:
        draft.counter = action.payload;
        break;
      case SET_SYSTEM_LANGUAGE:
        draft.language = action.payload.language;
        draft.languageShort = action.payload.languageShort;
        break;
      case SHOW_MESSAGE:
        draft.showMessage = action.payload;
        break;
      case DATA_LOADING:
        draft.loading = action.payload;
        draft.dataLoading++;
        break;
      case DATA_NOT_LOADING:
        if (draft.dataLoading > 0) {
          draft.dataLoading--;
        }
        break;
      case SET_PROGRESS_VALUE:
        draft.progressValue = action.payload;
        break;
      case SHOW_CONTEXT_MENU:
        draft.showContextMenu = action.payload;
        break;
      case ACTION_SUCCESS:
        draft.actionSuccess = true;
        break;
      default:
        return state;
    }
  });

export default generalReducer;
