import React, { useEffect, useState, useCallback } from "react";
import { updatePerson, setAccessCompleted } from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

import * as Yup from "yup";
import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import SomuraSwitchField from "Components/SomuraSwitchField";
import makeStyles from "@mui/styles/makeStyles";

const PersonSchema = Yup.object().shape({
  lastName: Yup.string().required("Pflichtfeld").nullable(),
  firstName: Yup.string().required("Pflichtfeld").nullable(),
  initials: Yup.string()
    .max(2, "Maximal 2 Zeichen")
    .required("Pflichtfeld")
    .nullable(),
  emailCompany: Yup.string()
    .email("Ungültige EMail-Adresse")
    .required("Pflichtfeld")
    .nullable(),
  startDate: Yup.date().required("Pflichtfeld"),
});

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "1rem",
    textAlign: "center",
    paddingBottom: "0",
  },
  formContent: {
    backgroundColor: theme.palette.background.paperBottom,
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
  formFooter: {
    height: "54px",
    display: "grid",
    gridTemplateColumns: "1fr 10rem 12rem",
    alignItems: "center",
    justifyContent: "center",
    padding: 4,
  },
  cancelButton: {
    gridColumn: 3,
  },
  saveButton: {
    gridColumn: 4,
  },
  change: {
    textDecoration: "none",
    color: theme.palette.text.screenTitle,
    cursor: "pointer",
  },
}));

const mapStateToProps = (state) => {
  const logged = state.loggeduser.LoggedUser.id;
  const isadmin = state.loggeduser.LoggedIser.isadmin;
  const isassistant = state.loggeduser.LoggedUser.isassistant;
  const persons = state.persons.Persons;
  const messageId = state.messages.selectedMessageId;
  return {
    logged,
    isadmin,
    isassistant,
    persons,
    messageId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePerson: (values) => dispatch(updatePerson(values)),
    setAccessCompleted: (id) => dispatch(setAccessCompleted(id)),
  };
};

const NewAccount = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const personId = props.persons.find(
    (P) => P.emailCompany === props.requestMail
  ).id;
  const formHeader = "Neue(r) Mitarbeiter(in)";
  const emptyState = {
    id: personId,
    salutationKey: "0",
    firstName: "",
    lastName: "",
    initials: "",
    startDate: "",
    emailCompany: "",
    accountLocked: false,
    changePW: true,
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);

  const handleSubmit = (values) => {
    props.updatePerson(values);
    props.setAccessCompleted({ id: props.messageId });
    props.setNewAccount(false);
  };

  const handleClose = () => {
    props.close();
  };

  const handleChange = (event) => {
    const name = event.target ? event.target.name : event.name;
    const value = event.target ? event.target.value : event.value;
    setState({ ...state, [name]: value });
  };

    const handleSwitchChange = (event) => {
      const name = event.target.name;
      const value = event.target.checked;
      setState({ ...state, [name]: value });
    };

  useEffect(() => {
    setState(emptyState);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await PersonSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose(event, reason);
        }
      }}
    >
      <DialogTitle dialogTitle="Neue(r) Mitarbeiter(in)" />
      <DialogContent className={classes.formContent}>
        <div className={classes.formHeader}>{formHeader}</div>
        <Grid container spacing={3}>
          <Grid item xs={2} lg={2}>
            <SomuraAutocomplete
              name="salutationKey"
              label={t("Salutation")}
              options={[
                { label: "", value: "0" },
                { label: t("Mr"), value: "1" },
                { label: t("Mrs"), value: "2" },
              ]}
              value={state.salutationKey}
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <SomuraTextField
              type="text"
              name="firstName"
              label="Vorname"
              value={state.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <SomuraTextField
              type="text"
              name="lastName"
              label="Nachname"
              value={state.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2} lg={2}>
            <SomuraTextField
              type="text"
              name="initials"
              label="Initialen"
              value={state.initials}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4} lg={4}>
            <SomuraTextField
              label="Eintritt"
              type="date"
              name="startDate"
              value={state.startDate}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} lg={6}>
            <SomuraTextField
              type="email"
              name="emailCompany"
              label="EMail"
              value={state.emailCompany}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} lg={6}>
            <SomuraSwitchField
              name="accountLocked"
              checked={state.accountLocked}
              onChange={handleSwitchChange}
              label={t("Account is locked")}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} lg={6}>
            <SomuraSwitchField
              name="changePW"
              checked={state.changePW}
              onChange={handleSwitchChange}
              label={t("User must assign a new password")}
              disabled={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        w
        <CancelButton onClick={handleClose} />
        <SaveButton disabled={!valid} onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAccount);
