import {
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  SET_SELECTED_MESSAGE_ID,
  SET_MESSAGE_READ,
  SET_MESSAGE_UNREAD,
  SET_MESSAGE_ACCESS,
  SET_MESSAGE_PASSWORD,
  SET_MESSAGE_SENDABLE,
  COPY_MESSAGE_TO_DRAFT,
  COPY_DRAFT_TO_MESSAGE,
  MOVE_MESSAGE_TO_RECIPIENTFOLDER,
  MOVE_MESSAGE_TO_SENDERFOLDER,
  ADD_MESSAGE,
  SAVE_MESSAGE,
  SAVE_MESSAGE_RECIPIENTS,
  SAVE_DRAFT_MESSAGE,
  UPDATE_DRAFT_MESSAGE,
  DELETE_DRAFT_MESSAGE,
  UPDATE_DRAFT_RECIPIENTS,
  ANSWER_MESSAGE,
  DELETE_MESSAGE,
  EMPTY_MESSAGEFOLDER,
  EMPTY_SENDERFOLDER,
  EMPTY_RECYCLEBIN,
  ADD_MESSAGE_ID,
  SET_FIRST_MESSAGE_ID,
  REMOVE_MESSAGE_ID,
  SELECT_MESSAGE_ID,
  UNSELECT_MESSAGE_ID,
  CLEAR_MESSAGE_IDS,
  SELECT_ALL_MESSAGE_IDS,
  UNSELECT_ALL_MESSAGE_IDS,
} from "actions/types";
import moment from "moment";
import produce from "immer";

const initialState = {
  loading: false,
  Messages: [],
  error: "",
  selectedMessageId: "0",
  selectedMessageIds: [],
  firstSelectedMessageId: "0",
  messageSendable: false,
  draftMessage: {},
};

const messagesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    let message;
    let messages;
    let recipient;
    var saveMessageKey;
    var prevMessageIds;
    switch (action.type) {
      case FETCH_MESSAGES_REQUEST:
        draft.loading = true;
        break;
      case FETCH_MESSAGES_SUCCESS:
        draft.loading = false;
        draft.Messages = action.payload;
        draft.error = "";
        break;
      case FETCH_MESSAGES_FAILURE:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case SET_SELECTED_MESSAGE_ID:
        draft.selectedMessageId = action.payload;
        break;
      case SET_MESSAGE_READ:
        message = draft.Messages.find((M) => M.id === action.payload.messageId);
        recipient = message.recipients.find(
          (R) => R.recipientId === action.payload.recipientId
        );
        recipient.readTime = moment().format("YYYY-MM-DD HH:mm:ss");
        break;
      case SET_MESSAGE_UNREAD:
        message = draft.Messages.find((M) => M.id === action.payload.messageId);
        recipient = message.recipients.find(
          (R) => R.recipientId === action.payload.recipientId
        );
        recipient.readTime = null;
        break;
      case SET_MESSAGE_ACCESS:
        message = draft.Messages.find((M) => M.id === action.payload.id);
        message.allowAccessCompleted = moment().format("YYYY-MM-DD HH:mm:ss");
        break;
      case SET_MESSAGE_PASSWORD:
        message = draft.Messages.find((M) => M.id === action.payload.id);
        message.allowNewPasswordCompleted = moment().format(
          "YYYY-MM-DD HH:mm:ss"
        );
        break;
      case SET_MESSAGE_SENDABLE:
        draft.messageSendable = action.payload;
        break;
      case COPY_MESSAGE_TO_DRAFT:
        draft.draftMessage = action.payload;
        break;
      case COPY_DRAFT_TO_MESSAGE:
        key = draft.Messages.findIndex((M) => M.id === draft.draftMessage.id);
        draft.Messages[key] = draft.draftMessage;
        draft.draftMessage = {};
        break;
      case MOVE_MESSAGE_TO_RECIPIENTFOLDER:
        message = draft.Messages.find((M) => M.id === action.payload.id);
        recipient = message.recipients.find(
          (R) => R.recipientId === action.payload.recipientId
        );
        recipient.recipientFolderId = action.payload.recipientFolderId;
        draft.selectedMessageId = 0;
        break;
      case MOVE_MESSAGE_TO_SENDERFOLDER:
        const moveSMessage = draft.Messages.find(
          (M) => M.id === action.payload.id
        );
        moveSMessage.senderFolderId = action.payload.senderFolderId;
        draft.selectedMessageId = 0;
        break;
      case ADD_MESSAGE || ANSWER_MESSAGE:
        draft.Messages = [...draft.Messages, action.payload];
        draft.selectedMessageId = action.payload.id;
        break;
      case SAVE_MESSAGE:
        saveMessageKey = draft.Messages.findIndex(
          (M) => M.id === action.payload.id
        );
        draft.Messages[saveMessageKey] = action.payload;
        break;
      case SAVE_MESSAGE_RECIPIENTS:
        saveMessageKey = draft.Messages.findIndex(
          (M) => M.id === action.payload[0].messageId
        );
        draft.Messages[saveMessageKey].recipients = action.payload;
        break;
      case SAVE_DRAFT_MESSAGE:
        key = draft.Messages.findIndex((M) => M.id === draft.draftMessage.id);
        draft.Messages[key] = draft.draftMessage;
        break;
      case UPDATE_DRAFT_MESSAGE:
        draft.draftMessage = action.payload;
        break;
      case DELETE_DRAFT_MESSAGE:
        draft.draftMessage = {};
        break;
      case UPDATE_DRAFT_RECIPIENTS:
        draft.draftMessage.recipients = action.payload;
        break;
      case DELETE_MESSAGE:
        draft.selectedMessageId = 0;
        key = draft.Messages.findIndex((M) => M.id === action.payload);
        draft = { Messages: draft.Messages.splice(key, 1) };
        break;
      case EMPTY_MESSAGEFOLDER:
        messages = draft.Messages.filter(
          (M) =>
            M.recipients?.findIndex(
              (R) =>
                R.recipientFolderId === action.payload.folderId &&
                R.recipientId === action.payload.userId
            ) >= 0
        );
        messages.forEach((MSG) => {
          recipient = MSG.recipients.find(
            (R) => R.recipientFolderId === action.payload.folderId
          );
          recipient.recipientFolderId = "-1";
        });
        draft.selectedMessageId = 0;
        break;
      case EMPTY_SENDERFOLDER:
        messages = draft.Messages.filter(
          (M) =>
            M.senderFolderId === action.payload.folderId &&
            M.fromUserId === action.payload.userId
        );
        messages.forEach((MSG) => {
          MSG.senderFolderId = "-1";
        });
        draft.selectedMessageId = 0;
        break;
      case EMPTY_RECYCLEBIN:
        messages = draft.Messages.filter(
          (M) =>
            M.senderFolderId === "-1" && M.fromUserId === action.payload.userId
        );
        messages.forEach((MSG) => {
          key = draft.Messages.findIndex((M) => M.id === MSG.id);
          draft = { Messages: draft.Messages.splice(key, 1) };
        });
        // Delete recipient datasets
        messages = draft.Messages.filter(
          (M) =>
            M.recipients?.findIndex(
              (R) =>
                R.recipientFolderId === "-1" &&
                R.recipientId === action.payload.userId
            ) >= 0
        );
        messages.forEach((MSG) => {
          key = MSG.recipients.findIndex((R) => R.recipientFolderId === "-1");
          MSG.recipients = MSG.recipients.splice(key, 1);
        });
        draft.selectedMessageId = 0;
        break;
      case ADD_MESSAGE_ID:
        draft.selectedMessageIds = [
          ...draft.selectedMessageIds,
          { ...action.payload, selected: false },
        ];
        break;
      case SET_FIRST_MESSAGE_ID:
        draft.firstSelectedMessageId = action.payload;
        break;
      case REMOVE_MESSAGE_ID:
        key = draft.selectedMessageIds.findIndex(
          (SM) => SM.id === action.payload
        );
        draft.selectedMessageIds.splice(key, 1);
        break;
      case SELECT_MESSAGE_ID:
        key = draft.selectedMessageIds.findIndex(
          (SM) => SM.id === action.payload
        );
        draft.selectedMessageIds[key] = {
          ...draft.selectedMessageIds[key],
          selected: true,
        };
        break;
      case UNSELECT_MESSAGE_ID:
        key = draft.selectedMessageIds.findIndex(
          (SM) => SM.id === action.payload
        );
        draft.selectedMessageIds[key] = {
          ...draft.selectedMessageIds[key],
          selected: false,
        };
        break;
      case CLEAR_MESSAGE_IDS:
        draft.selectedMessageIds = [];
        break;
      case SELECT_ALL_MESSAGE_IDS:
        prevMessageIds = draft.selectedMessageIds;
        draft.selectedMessageIds = prevMessageIds.map((SM) => ({
          ...SM,
          selected: true,
        }));
        break;
      case UNSELECT_ALL_MESSAGE_IDS:
        prevMessageIds = draft.selectedMessageIds;
        draft.selectedMessageIds = prevMessageIds.map((SM) => ({
          ...SM,
          selected: false,
        }));
        break;
      default:
        return state;
    }
  });

export default messagesReducer;
