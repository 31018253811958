import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  deleteOffDays,
  fetchUnpublishedDays,
} from "redux/index";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

import { store } from "redux/store";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import SomuraSelectField from "Components/SomuraSelectField";
import TextField from "@mui/material/TextField";

import makeStyles from '@mui/styles/makeStyles';

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ClearIcon from "@mui/icons-material/Clear";

import DateList from "Calendars/Components/DateList";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: 700,
  },
  cancelButton: {
    gridColumn: 3,
  },
  saveButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-deleteform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  const logged = state.loggeduser.LoggedUser.id;
  const yeardata = state.year.Year;
  const displayyear = state.general.DisplayYear;
  const offDayTypes = state.offdaytypes.OffDayTypes;
  const offDayTypeOptions = offDayTypes.map((ODT) => ({
    label: ODT.names.find(
      (OTN) => OTN.language === localStorage.getItem("lang")
    ).offDayType,
    value: ODT.id,
  }));
  offDayTypeOptions.push({ label: "", value: "0" });
  const userId = state.yearcalendar.displayUserId;
  return {
    logged,
    yeardata,
    displayyear,
    offDayTypeOptions,
    userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteOffDays: (values) => dispatch(deleteOffDays(values)),
    fetchUnpublishedDays: (id) =>
      dispatch(fetchUnpublishedDays({ userId: id })),
  };
};

const DeleteForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const state = {
    loggedUserId: props.logged,
    offDayTypeId: props.offDayTypeId,
    dates: props.dates,
    userId: props.userId,
    approvementId: props.approvementId,
    offDayState: "1",
    published: "0",
    dayFactor: "1",
  };

  const offDayTypeOptions = props.offDayTypeOptions;

  const handleSubmit = () => {
    props.deleteOffDays(state);
    // Check for unpublished days
    if (props.userId === props.logged) {
      props.fetchUnpublishedDays(props.logged);
    }
    handleClose();
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    props.closeDeleteForm();
  };

  const title = () => {
    let title = "";
    let subtitle = "";
    const firstDate = moment(props.startDate).format("dddd, LL");
    const lastDate = moment(props.endDate).format("dddd, LL");

    if (firstDate === lastDate) {
      title = firstDate;
    } else {
      title = firstDate + " " + t("to") + " " + lastDate;
    }

    subtitle =
      props.numWorkdays +
      " " +
      (props.numWorkdays === 1 ? t("Working day") : t("Working days")) +
      " (";
    subtitle +=
      props.numDays + " " + (props.numDays === 1 ? t("day") : t("days")) + ")";

    return (
      <span>
        {title}
        <br />
        {subtitle}
      </span>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-deleteform-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-deleteform-title"
        dialogTitle={t("Delete / cancel absence")}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="red"
      />
            <DialogContent className="dialogContent">
              <div className="timeFrame">{title()}</div>
              <fieldset
                className={classes.fieldset}
                disabled={props.formDisabled}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6} lg={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <DateList dates={props.dates} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SomuraSelectField
                          name="offDayTypeId"
                          label={t("Absence type")}
                          options={offDayTypeOptions}
                          style={{ marginTop: "4px" }}
                          disabled={true}
                          value={state.offDayTypeId}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                      {props.additionalText?.length > 0 && (
                        <Grid item xs={12}>
                          <TextField
                            name="additionalText"
                            label={t("Additional text")}
                            disabled={true}
                            value={props.additionalText}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </fieldset>
            </DialogContent>
            <DialogActions className="dialogActions">
              <Button
                id="cancelselection"
                className={classes.cancelButton}
                startIcon={<ClearIcon />}
                variant="outlined"
                color="cancel"
                onClick={() => {
                  handleClose();
                }}
              >
                {t("Abort")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={handleSubmit}
                startIcon={<DeleteOutlineIcon />}
              >
                {t("Delete")}
              </Button>
            </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteForm);
