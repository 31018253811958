import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import MovementDialog from "./MovementDialog"
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "1.3rem 1.3rem",
    gridTemplateColumns: "6rem repeat(2, 1fr)",
    "&:hover $delete": {
      display: "block",
    },
  },
  listPaper: {
    width: "100%",
    padding: "0 4px !important",
    margin: "0 2px 6px 2px",
    backgroundColor: theme.palette.background.messageListItem,
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
      cursor: "pointer",
    },
    borderRadius: "4px",
  },
  date: {
    gridRow: "1 / -1",
    gridColumn: "1",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "0.9rem",
    height: "0.9rem",
    marginRight: "2px",
    display: "inline-block",
  },
  type: {
    gridRow: "1",
    gridColumn: "2 / 4",
    fontSize: "0.9rem",
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  location: {
    gridRow: "2",
    gridColumn: "2",
    fontSize: "0.9rem",
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  person: {
    gridRow: "2",
    gridColumn: "3",
    fontSize: "0.9rem",
    textAlign: "right",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const MovementItem = (props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog ] = useState(false)

  const handleClick = () => {
    setOpenDialog(true)
  };

  return (
    <>
      <Paper
        square
        elevation={8}
        className={classes.listPaper}
        // onClick={handleClick}
      >
        <div className={classes.root} onClick={handleClick}>
          <div className={classes.date}>{props.date}</div>
          <div className={classes.type}>
            <ModeOfTravelIcon className={classes.icon} />
            <span>{props.type}</span>
          </div>
          <div className={classes.location}>
            <LocationOnIcon className={classes.icon} />
            <span>{props.location}</span>
          </div>
          {props.person?.length > 0 && (
            <div className={classes.person}>
              <PersonIcon className={classes.icon} />
              <span>{props.person}</span>
            </div>
          )}
        </div>
      </Paper>
      {openDialog && (
        <MovementDialog
          articleId = {props.articleId}
          movementId = {props.id}
          movementKey={props.movementKey}
          close={() => setOpenDialog(false)}
        />
      )}
    </>
  );
};

export default MovementItem;
