import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { fetchIndustries, setSelectedIndustryId } from "redux/index";
import SomuraDataGrid from "Components/SomuraDataGrid";
import IndustryForm from "./IndustryForm";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
  TableRow: {
    cursor: "pointer",
  },
}));

const mapStateToProps = (state) => {
  return {
    industries: state.industries.Industries,
    selectedIndustryId: state.industries.selectedIndustryId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIndustries: () => dispatch(fetchIndustries()),
    setSelectedIndustryId: (values) => dispatch(setSelectedIndustryId(values)),
  };
};

function IndustriesTable({
  industries,
  selectedIndustryId,
  setSelectedIndustryId,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "ID",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
    },
    {
      headerName: t("Industry"),
      headerClassName: "tableHeader",
      field: "industryName",
      flex: 0.5,
    },
    {
      headerName: t("Number of companies"),
      headerClassName: "tableHeader",
      field: "numAssigned",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
    },
  ];

  const rows = industries.map((I, index) => ({
    key: index,
    id: I.id,
    industryName: I.industryName,
    numAssigned: parseInt(I.numAssigned),
  }));

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "new":
        setSelectedIndustryId("0");
        break;
      default:
    }
  };

  const handleRowClick = (params) => {
    setSelectedIndustryId(params);
  };

  useEffect(() => {
    props.fetchIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Collapse in={selectedIndustryId === -1} timeout={"auto"}>
        <div className={classes.TableContainer}>
          <SomuraDataGrid
            tableHeight="65vh"
            rows={rows}
            columns={columns}
            defaultSortModel={[
              {
                field: "industryName",
                sort: "asc",
              },
            ]}
            onRowClick={(params) => handleRowClick(params)}
            csvFileName={t("Industries")}
          />
          <Fab
            variant="extended"
            color="primary"
            size="medium"
            className={classes.fab}
            onClick={() => handleButtonClick("new")}
          >
            <AddIcon />
            {t("New industry")}
          </Fab>
        </div>
      </Collapse>
      <Collapse in={selectedIndustryId >= 0} timeout={"auto"}>
        <IndustryForm handleClose={() => setSelectedIndustryId(-1)} />
      </Collapse>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustriesTable);
