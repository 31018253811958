import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setGlobalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getGlobalSetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    globalSettings: state.settings.GlobalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalSettings: (values) => dispatch(setGlobalSettings(values)),
  };
};

const SystemForm = ({ globalSettings, setGlobalSettings, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "system";
  const systemSettings = globalSettings?.find(
    (GS) => GS.component === component
  )?.settings;
  const [state, setState] = useState({
    firstDayOfWeek:
      parseInt(getGlobalSetting(component, "firstDayOfWeek")) || 0,
    useLoanModule: parseInt(getGlobalSetting(component, "useLoanModule")) === 1,
    useCovModule: parseInt(getGlobalSetting(component, "useCovModule")) === 1,
    useTimeTrackModule:
      parseInt(getGlobalSetting(component, "useTimeTrackModule")) === 1,
    workOnSaturday:
      parseInt(getGlobalSetting(component, "workOnSaturday")) === 1,
  });

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    setState({ ...state, [name]: value });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value ? "1" : "0",
    });
  };

  useEffect(() => {
    setState({
      firstDayOfWeek:
        parseInt(getGlobalSetting(component, "firstDayOfWeek")) || 0,
      workOnSaturday:
        parseInt(getGlobalSetting(component, "workOnSaturday")) === 1 || false,
      workingDays: getGlobalSetting(component, "workingDays"),
      useLoanModule:
        parseInt(getGlobalSetting(component, "useLoanModule")) === 1 || false,
      useCovModule:
        parseInt(getGlobalSetting(component, "useCovModule")) === 1 || false,
      useTimeTrackModule:
        parseInt(getGlobalSetting(component, "useTimeTrackModule")) === 1 || false,
    });
  }, [systemSettings]);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="firstDayOfWeek"
              label={t("First day of week")}
              value={state.firstDayOfWeek}
              onChange={(values) => handleAutoCompleteChange(values)}
              options={[
                { label: t("Sunday"), value: 0 },
                { label: t("Monday"), value: 1 },
              ]}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="workOnSaturday"
              checked={state.workOnSaturday}
              onChange={handleSwitchChange}
              label={t("Saturday is a working day (6-days-week)")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="useLoanModule"
              checked={state.useLoanModule}
              onChange={handleSwitchChange}
              label={t("Use module 'loan assets'")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="useCovModule"
              checked={state.useCovModule}
              onChange={handleSwitchChange}
              label={t("Use module 'covid state'")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="useTimeTrackModule"
              checked={state.useTimeTrackModule}
              onChange={handleSwitchChange}
              label={t("Use module 'time tracking'")}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemForm);
