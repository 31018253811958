import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "100%",
    marginBottom: "2px",
  },
  required: {
    borderRadius: "4px",
    boxShadow: "inset 0 0 5px " + theme.palette.secondary.main,
  },
}));

const SomuraAutocomplete = ({
  name,
  label,
  options,
  value,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...props}
        options={options}
        onChange={(event, newValue) => {
          props.onChange({ name: name, value: newValue?.value || "" });
        }}
        value={options.find((O) => O.value === value) || null}
        isOptionEqualToValue={(option, value) =>
          value === "" || !value || option.value === value.value
        }
        renderInput={(params) => (
          <TextField
            {...params}
            className={
              classes.TextField +
              " " +
              (props.required ? classes.required : null)
            }
            label={label ? label + (props.required ? " *" : "") : ""}
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export default SomuraAutocomplete;
