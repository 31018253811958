import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { requestOffDays } from "redux/index";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

import { store } from "redux/store";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import SomuraSelectField from "Components/SomuraSelectField";
import TextField from "@mui/material/TextField";

import makeStyles from "@mui/styles/makeStyles";

import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";

import DateList from "Calendars/Components/DateList";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: 700,
  },
  cancelButton: {
    gridColumn: 3,
  },
  saveButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-requestform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state, props) => {
  const logged = state.loggeduser.LoggedUser.id;
  const yeardata = state.year.Year;
  const displayyear = state.general.DisplayYear;
  const offDayTypes = state.offdaytypes.OffDayTypes.filter(
    (OT) => OT.onlyOneDay === false || props.numDays === 1
  );
  const offDayTypeOptions = offDayTypes.map((ODT) => ({
    label: ODT.names.find(
      (OTN) => OTN.language === localStorage.getItem("lang")
    ).offDayType,
    value: ODT.id,
  }));
   offDayTypeOptions.sort((a, b) =>
     a.label > b.label ? 1 : b.label > a.label ? -1 : 0
   );
  offDayTypeOptions.push({ label: "", value: "0" });
  const userId = state.yearcalendar.displayUserId;
  return {
    logged,
    yeardata,
    displayyear,
    offDayTypes,
    offDayTypeOptions,
    userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestOffDays: (values) => dispatch(requestOffDays(values)),
  };
};

const RequestForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const state = {
    loggedUserId: props.logged,
    offDayTypeId: props.offDayTypeId,
    dates: props.dates,
    userId: props.userId,
    approvementId: props.approvementId,
    offDayState: "2",
    published: "0",
    dayFactor: "1",
    noMessages: false,
    language: localStorage.getItem("lang"),
  };
  var offDayTypeOptions = props.offDayTypeOptions;

  const handleSubmit = () => {
    props.requestOffDays(state);
    handleClose();
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    props.closeRequestForm();
  };

  const title = () => {
    let title = "";
    let subtitle = "";
    const firstDate = moment(props.startDate).format("dddd, LL");
    const lastDate = moment(props.endDate).format("dddd, LL");

    if (firstDate === lastDate) {
      title = firstDate;
    } else {
      title = firstDate + " " + t("to") + " " + lastDate;
    }

    subtitle =
      props.numWorkdays +
      " " +
      (props.numWorkdays === 1 ? t("Working day") : t("Working days")) +
      " (";
    subtitle +=
      props.numDays + " " + (props.numDays === 1 ? t("day") : t("days")) + ")";

    return (
      <span>
        {title}
        <br />
        {subtitle}
      </span>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-requestform-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-requestform-title"
        dialogTitle={t("Request / register")}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="#9B59B6"
      />
      <DialogContent className="dialogContent">
        <div className="timeFrame">{title()}</div>
        <fieldset className={classes.fieldset} disabled={props.formDisabled}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DateList dates={props.dates} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SomuraSelectField
                    name="offDayTypeId"
                    label={t("Absence type")}
                    options={offDayTypeOptions}
                    style={{ marginTop: "4px" }}
                    disabled={true}
                    value={state.offDayTypeId}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                {props.additionalText?.length > 0 && (
                  <Grid item xs={12}>
                    <TextField
                      name="additionalText"
                      label={t("Additional text")}
                      disabled={true}
                      value={props.additionalText}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </fieldset>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button
          id="cancelselection"
          className={classes.cancelButton}
          startIcon={<ClearIcon />}
          variant="outlined"
          color="cancel"
          onClick={() => {
            handleClose();
          }}
        >
          {t("Abort")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          disabled={state.offDayTypeId < 1}
          startIcon={<SendIcon />}
          onClick={handleSubmit}
        >
          {t("Request")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
