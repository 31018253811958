import React from "react";
import preval from "preval.macro"
import moment from "moment"
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  aboutDialog: {
    backgroundColor: theme.palette.background.scrollBar,
  },
  dialogText: {
    color: theme.palette.text.calendars,
  },
  img: {
    width: "50%",
    display: "flex",
    margin: "auto",
    marginBottom: "1rem",
  },
}));

const AboutSomura = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const buildTimestamp = preval`module.exports = new Date().getTime();`;
  const buildDateTime = moment.unix(buildTimestamp /  1000).format("DD.MM.YYYY HH:mm:ss")

  const handleClose = () => {
    props.closeAbout()
  }
  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle className={classes.aboutDialog}>
          <img
            className={classes.img}
            alt="Logo"
            src={"/images/Somura-logo-default.svg"}
          />
        </DialogTitle>
        <DialogContent className={classes.aboutDialog}>
          <DialogContentText className={classes.dialogText}>
            {t("Build date")}: {buildDateTime}
          </DialogContentText>
          <DialogContentText className={classes.dialogText}>
            &copy; 2021 NETitwork GmbH. All rights reserved
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.aboutDialog}>
          <Button
            className={classes.OKButton}
            color="primary"
            variant="contained"
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AboutSomura
