import {
  FETCH_MONTH_STATIC_SUCCESS,
  FETCH_MONTH_SUMS_SUCCESS,
  FETCH_MONTH_OFFDAYS_SUCCESS,
  SET_DISPLAY_DEPARTMENT,
  SET_DAY_INFO
} from 'actions/types'

import produce from 'immer'

const initialState = {
  MonthStatic: [],
  MonthSums: [],
  MonthOffdays: [],
  displayDepartmentId: '-1',
  dayInfo: {}
}

const monthReducer = (state = initialState, action) =>
  produce(state, draft => {
    const PL = action.payload
    switch (action.type) {
      case FETCH_MONTH_STATIC_SUCCESS:
        draft.MonthStatic = PL
        break    
      case FETCH_MONTH_SUMS_SUCCESS:
        draft.MonthSums = PL
        break    
      case FETCH_MONTH_OFFDAYS_SUCCESS:
        draft.MonthOffdays = PL
        break       
      case SET_DISPLAY_DEPARTMENT:
        draft.displayDepartmentId = PL
        break 
      case SET_DAY_INFO:
        draft.dayInfo = PL
        break
      default: return state      
    }
  })

export default monthReducer