import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.red,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.green,
  },
}))(LinearProgress);

const mapStateToProps = (state) => {
  return {
    progressValue: state.general.progressValue
  };
};

const SomuraProgress = ({progressValue, ...props}) => {
  return <BorderLinearProgress variant="determinate" value={progressValue} color="primary" />;
}

export default connect(mapStateToProps, null)(SomuraProgress)