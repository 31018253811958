import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import OpenWithIcon from "@mui/icons-material/OpenWith";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: 0,
    cursor: "move",
    borderBottom: "2px solid grey",
    display: "grid",
    gridTemplateColumns: "24px 1fr 32px",
    gridTemplateRows: "2rem",
    minWidth: (props) => `${props.width}`,
    backgroundColor: (props) => `${props.backgroundColor}`,
  },
  title: {
    gridRow: "1",
    gridColumn: "2",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    fontSize: "1.2rem",
    fontWeight: "400",

    color: (props) => `${props.color}`,
  },
  moveIcon: {
    width: "24px",
    height: "24px",
    margin: "2px",
    gridColumn: "1",
    gridRow: "1",
    color: (props) => `${props.color}`,
    backgroundColor: (props) => `${props.backgroundColor}`,
  },
  close: {
    width: "1.5rem",
    height: "1.5rem",
    margin: "4px",
    gridColumn: "3",
    gridRow: "1",
    cursor: "default",
    color: (props) => `${props.color}`,
  },
}));

const DialogTitle = (props) => {
  const classes = useStyles(props);
  let lastColor = "";

  function handleClose() {
    props.closeForm();
  }

  function redBackground(e) {
    if (lastColor === "") {
      lastColor = e.target.style.color;
    }
    e.target.style.background = "red";
    e.target.style.color = "white";
  }

  function normalBackground(e) {
    e.target.style.background = "transparent";
    e.target.style.color = lastColor;
  }

  return (
    <div
      className={classes.dialogTitle}
      style={{
        minWidth: props.width,
        color: props.color,
      }}
      id={props.id || "draggable-dialog-title"}
    >
      <OpenWithIcon className={classes.moveIcon} />
      <div className={classes.title}>{props.dialogTitle}</div>
      {!props.noCloseIcon && (
        <div>
          <CloseIcon
            className={classes.close}
            onMouseEnter={redBackground}
            onMouseLeave={normalBackground}
            onClick={handleClose}
          />
        </div>
      )}
    </div>
  );
};

export default DialogTitle;
