import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchDepartments,
  fetchDepartment,
  setEditMode,
  newDepartment,
  setDepartmentId,
} from "redux/index";

import SomuraDataGrid from "Components/SomuraDataGrid";

const useStyles = makeStyles((theme) => ({
  TableContainer: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
  TableRow: {
    cursor: "pointer",
  },
}));

const mapStateToProps = (state) => {
  return {
    departmentsData: state.departments.Departments,
    editMode: state.general.EditMode,
    selectedDepartment: state.departments.selectedDepartmentId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDepartments: () => dispatch(fetchDepartments()),
    fetchDepartment: (id) => dispatch(fetchDepartment(id)),
    setEditMode: (EditMode) => dispatch(setEditMode(EditMode)),
    newDepartment: () => dispatch(newDepartment()),
    setDepartmentId: (id) => dispatch(setDepartmentId(id)),
  };
};

function Departments({ departmentsData, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Department"),
      headerClassName: "tableHeader",
      field: "department",
      flex: 0.8,
    },
    {
      headerName: t("Number of employees"),
      headerClassName: "tableHeader",
      field: "numPersons",
      flex: 0.2,
    },
  ];

  const rows = departmentsData.map((D, index) => ({
    key: index,
    id: D.id,
    department: D.department,
    numPersons: parseInt(D.numPersons),
  }));

  const handleRowClick = (params) => {
    props.setDepartmentId(params);
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "new":
        props.setDepartmentId(-1);
        props.newDepartment();
        props.setEditMode(true);
        break;
      default:
    }
  };

  useEffect(() => {
    props.fetchDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SomuraDataGrid
        tableHeight="65vh"
        rows={rows}
        columns={columns}
        defaultSortModel={[
          {
            field: "department",
            sort: "asc",
          },
        ]}
        onRowClick={(params) => handleRowClick(params)}
        csvFileName={t("Departments")}
      />
      <Fab
        variant="extended"
        color="primary"
        size="medium"
        className={classes.fab}
        onClick={() => handleButtonClick("new")}
      >
        <AddIcon />
        {t("New department")}
      </Fab>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
