import {
  UPDATE_OFFDAYTEXT,
  ADD_OFFDAYTEXT,
  DELETE_OFFDAYTEXT,
} from "actions/types";

import {
  offDayTextUpdate,
  offDayTextAdd,
  offDayTextDelete,
} from "../api";
import { dataFailure, dataSuccess } from './general'

const updateOffDayTextData = (values) => {
  return {
    type: UPDATE_OFFDAYTEXT,
    payload: values
  }
}

export const updateOffDayText = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(offDayTextUpdate(values));
      await dispatch(updateOffDayTextData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err))
    }
  }
}

const addOffDayTextData = (values) => {
  return {
    type: ADD_OFFDAYTEXT,
    payload: values
  }
}

export const addOffDayText = (values) => {
  return async (dispatch) => {
    try {
      const responseJSON = await dispatch(offDayTextAdd(values))
      await dispatch(addOffDayTextData({...values, id:responseJSON.id}))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err))
    }
  }
}

const deleteOffDayTextData = (values) => {
  return {
    type: DELETE_OFFDAYTEXT,
    payload: values
  }
}

export const deleteOffDayText = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(offDayTextDelete(values))
      await dispatch(deleteOffDayTextData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err))
    }
  }
}

