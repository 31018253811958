import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import "./index.css";
import App from "App";
import { store } from "./redux/store";
import theme from "theme/Theme";
import "./i18n";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function main() {
  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </Provider>
      </DndProvider>
    </StyledEngineProvider>,
    document.getElementById("root")
  );
}

main();
