import React from "react";
import Tooltip from "@mui/material/Tooltip";
import withStyles from '@mui/styles/withStyles';

const StyledTooltip = withStyles(() => ({
  tooltip: {
    padding: (props) => `${props.padding}`,
    margin: "0",
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.9rem",
    border: (props) => `${props.border}`,
    borderRadius: "5px",
    boxShadow: "5px 5px 3px 1px rgba(0, 0, 0, 0.4)",
  },
  arrow: {
    "&::before": {
      backgroundColor: "#f5f5f9",
      border: (props) => `${props.border}`,
    },
  },
}))(Tooltip);

const SomuraTooltip = ({ children, calendar, ...props }) => {

  return (
    <StyledTooltip
      {...props}
      enterDelay={200}
      enterNextDelay={200}
      arrow
      border={calendar ? "2px solid #BDBDBD" : "1px solid #f5f5f9"}
      padding={calendar ? "0" : ""}
      disableInteractive
    >
      {children}
    </StyledTooltip>
  );
};

export default SomuraTooltip;
