import {
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_REQUEST,
  UPDATE_COUNTRY,
  UPDATE_REGION,
  DELETE_REGION,
  SET_COUNTRY_ID,
  SET_REGION_ID,
} from "actions/types";
import produce from "immer";

const initialState = {
  Countries: [],
  loading: false,
  selectedCountryId: -1,
  selectedRegionId: -1,
};

const countriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    var regionKey;
    switch (action.type) {
      case FETCH_COUNTRIES_REQUEST:
        draft.loading = true;
        break;
      case FETCH_COUNTRIES_SUCCESS:
        draft.Countries = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case UPDATE_COUNTRY:
        key = draft.Countries.findIndex((C) => C.id === action.payload.id);
        if(key === -1){
          draft.Countries = [...draft.Countries, action.payload]
        } else {
          draft.Countries[key] = action.payload
        }
        break;
      case UPDATE_REGION:
        key = draft.Countries.findIndex(
          (C) => C.id === action.payload.countryId
        );        
        if(action.payload.id === 0){
          // Add new region
          draft.Countries[key].regions = [...draft.Countries[key].regions, action.payload];
        } else {
          // Update existing region
          regionKey = draft.Countries[key].regions.findIndex(
            (R) => R.id === action.payload.id
          );
          draft.Countries[key].regions[regionKey] = action.payload;
        }
        break;
      case DELETE_REGION:
        key = draft.Countries.findIndex(
          (C) => C.id === action.payload.countryId
        );
        regionKey = draft.Countries[key].regions.findIndex(
          (R) => R.id === action.payload.id
        );
        const regionArr = draft.Countries[key].regions;
        draft.Countries[key] = {
          ...draft.Countries[key],
          regions: regionArr.splice(regionKey, 1),
        };
        // draft.Countries[key].regions[regionKey] = action.payload
        break;
      case SET_COUNTRY_ID:
        draft.selectedCountryId = action.payload;
        break;
      case SET_REGION_ID:
        draft.selectedRegionId = action.payload;
        break;
      default:
        return draft;
    }
  });

export default countriesReducer;
