import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';
const somurablue = '#243573';

// eslint-disable-next-line
export default {
  black,
  white,
  somurablue,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange['A400'],
    light: colors.orange['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#E0E0E0',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  calendarIcon: {
    default: '#ffa726',
    gradient: '#fb8c00'
  },
  masterdataIcon: {
    default: '#66bb6a',
    gradient: '#43a047'
  },
  dashboardIcon: {
    default: '#243573',
    gradient: '#243573'
  },
  settingsIcon: {
    default: '#D10027',
    gradient: '#840119'
  },
  articlesIcon: {
    default: '#757DE8',
    gradient: '#3F51B5'
  },
  messageIcon: {
    default: '#963FB5',
    gradient: '#580084'
  },
  action: {
    disabled: 'red'
  }     
};
