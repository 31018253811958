import React, { useState } from "react";
import { connect } from "react-redux";
import { setGlobalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getGlobalSetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
  recipientsWrapper: {
    marginBottom: "4px",
    minHeight: "21rem",
  },
  settingsLine: {
    height: "1.5rem",
    marginLeft: "0.4rem",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  settingsLineItem: {
    display: "flex",
    alignItems: "center",
  },
  settingsCheckbox: {
    display: "flex",
    justifyContent: "center",
  },
  settingsHeadline: {
    fontWeight: "bold",
    height: "1.5rem",
    paddingTop: "0.5rem",
    marginLeft: "0.4rem",
  },
}));

const mapStateToProps = (state) => {
  return {
    globalSettings: state.settings.GlobalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalSettings: (values) => dispatch(setGlobalSettings(values)),
  };
};

const PersonsForm = ({ globalSettings, setGlobalSettings }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "persons";
  const [state, setState] = useState({
    usePersonNumbers:
      parseInt(getGlobalSetting(component, "usePersonNumbers")) === 1,
  });

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value ? "1" : "0",
    });
  };

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="usePersonNumbers"
              checked={state.usePersonNumbers}
              onChange={handleSwitchChange}
              label={t("Personnel number is required field")}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonsForm);
