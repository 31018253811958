import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { languages } from "Constants";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import BackButton from "Components/Buttons/BackButton";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OffDayNameForm from "./OffDayNameForm";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "0 10px 6px 10px",
  },
  formHeader: {
    textAlign: "center",
    borderBottom: "2px solid grey",
    marginBottom: "10px",
    padding: "4px",
    fontSize: "22px",
    minHeight: "35px",
    lineHeight: "25px",
    color: theme.palette.text.screenTitle,
  },
  listItem: {
    width: "100%",
    height: "2.5rem",
    padding: "0 8px",
    marginBottom: "4px",
    backgroundColor: theme.palette.background.listItem,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1rem",
    gridTemplateRows: "1fr",
    alignItems: "center",
  },
  listItemText: {
    gridColumn: "1",
  },
  listIcon: {
    gridColumn: "3",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  const offDayTypeId = state.offdaytypes.offDayTypeId;
  const offDayType = state.offdaytypes.OffDayTypes.find(
    (OT) => OT.id === offDayTypeId
  );
  return {
    offDayTypeId,
    offDayType,
  };
};

const OffDayTexts = ({ offDayType, displayName, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedNameId, setSelectedNameId] = useState(-1);

  const names = offDayType?.names;

  const handleSelect = (id) => {
    setSelectedNameId(id);
  };

  const namesList = () => {
    var listItems = [];
    for (let i = 0; i < names?.length; i++) {
      listItems.push(
        <ListItem
          key={i}
          className={classes.listItem}
          onClick={() => {
            handleSelect(names[i].id);
          }}
          id={names[i].offDayType}
        >
          <div className={classes.listItemText}>
            {
              languages.find((L) => L.languageName === names[i].language)
                ?.languageMenuName
            }
            {": "}
            {names[i].offDayType}
          </div>
          <ChevronRightIcon className={classes.listIcon} />
        </ListItem>
      );
    }
    if (listItems.length === 0)
      return (
        <Grid item xs={12}>
          <div className="noEntries">{t("No entries in this list")}</div>
        </Grid>
      );
    return (
      <Grid item xs={6} lg={4}>
        {listItems}
      </Grid>
    );
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Collapse in={selectedNameId === -1} timeout={"auto"}>
        <div className={classes.thisForm}>
          <div>
            <div className={classes.formHeader}>
              {displayName === ""
                ? t("New absence type") + " > " + t("No designations")
                : t("Absence type") +
                  ": " +
                  displayName +
                  " > " +
                  t("Designations and identifiers")}
            </div>
            <Grid container spacing={2}>
              {namesList()}
            </Grid>
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              className={classes.fab}
              onClick={() => setSelectedNameId(0)}
            >
              <AddIcon />
              {t("New designation")}
            </Fab>
          </div>
          <div className="formBottom">
            <BackButton onClick={handleClose} />
          </div>
        </div>
      </Collapse>
      <Collapse in={selectedNameId > -1} timeout={"auto"}>
        <OffDayNameForm
          displayName={displayName}
          offDayTypeId={offDayType?.id}
          nameId={selectedNameId}
          offDayName={
            offDayType?.names.find((N) => N.id === selectedNameId)?.offDayType
          }
          language={
            offDayType?.names.find((N) => N.id === selectedNameId)?.language
          }
          closeNameForm={() => {
            setSelectedNameId(-1);
          }}
        />
      </Collapse>
    </>
  );
};

export default connect(mapStateToProps)(OffDayTexts);
