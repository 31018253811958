import React, { useState } from "react";
import { connect } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import { store } from "redux/store";
import {
  setRecipientMessageFolder,
  deleteMessageFolder,
  addMessageFolder,
} from "redux/index";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "4px",
    cursor: "pointer",
    display: "grid",
    alignItems: "center",
    gridTemplateRows: "2.5rem",
    gridTemplateColumns: "1.8rem 1fr",
  },
  listPaper: {
    padding: "0 !important",
    margin: (props) => `${"2px 2px 2px " + props.padding + "px"}`,
    backgroundColor: theme.palette.background.messageListItem,
    borderRadius: "4px",
  },
  folder: {
    fontSize: "1.2rem",
    width: "100%",
    marginLeft: "4px",
  },
}));

const mapStateToProps = (state) => {
  return {
    logged: state.loggeduser.LoggedUser.id,
    folder: state.messagefolders.selectedFolderId,
    messages: state.messages.Messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRecipientMessageFolder: (values) =>
      dispatch(setRecipientMessageFolder(values)),
    deleteMessageFolder: (id) => dispatch(deleteMessageFolder(id)),
    addMessageFolder: (values) => dispatch(addMessageFolder(values)),
  };
};

const NewMessageFolder = (props) => {
  const classes = useStyles(props);

  const [folderName, setFolderName] = useState("");

  const checkInput = () => {
    if (folderName.length > 0) {
      props.addMessageFolder({
        parentId: props.parentFolderId,
        userId: props.logged,
        folderName: folderName,
        folderLevel: props.level,
      });
    }
    store.dispatch({ type: "SET_SELECTED_FOLDER_ID", payload: -1 });
    props.setNewFolder(-1)
  };

  return (
    <Paper square elevation={8} className={classes.listPaper}>
      <div className={classes.root}>
        {props.level > 1 && <SubdirectoryArrowRightIcon />}
        <TextField
          id={props.NewfolderId}
          type="text"
          variant="outlined"
          size="small"
          autoFocus={true}
          value={folderName}
          maxLength="50"
          onChange={(event) => setFolderName(event.target.value)}
          onBlur={checkInput}
          onKeyUp={(e, value) => {
            if (e.keyCode === 13 || e.keyCode === 27) {
              checkInput();
            }
          }}
        />
      </div>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMessageFolder);
