import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPersonalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getGlobalSetting, getPersonalSetting } from "Components/Utilities";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggeduser.LoggedUser,
    personalSettings: state.settings.PersonalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalSettings: (values) => dispatch(setPersonalSettings(values)),
  };
};

const GeneralForm = ({ setPersonalSettings, loggedUser, personalSettings }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "general";
  const emptyState = {
    startPage: "",
  };
  const [state, setState] = useState(emptyState);

  var startPageOptions = [
    { label: t("Dashboard"), value: "1" },
    { label: t("Year calendar"), value: "2" },
  ];

  if (loggedUser.showMonthCalendar) {
    startPageOptions.push({ label: t("Month calendar"), value: "3" });
  }

  if (loggedUser.isadmin || loggedUser.isassistant) {
    startPageOptions.push({ label: t("Persons"), value: "4" });
  }

  if (getGlobalSetting("messages", "disableMessages") === "0") {
    startPageOptions.push({ label: t("Messages"), value: "5" });
  }

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    setPersonalSettings({
      userId: loggedUser.id,
      component: component,
      key: name,
      value: value,
    });
    setState({ ...state, [name]: value });
    switch (value) {
      case "1":
        localStorage.setItem("startPage", "Dashboard");
        break;
      case "2":
        localStorage.setItem("startPage", "YearCalendar");
        break;
      case "3":
        localStorage.setItem("startPage", "MonthCalendar");
        break;
      case "4":
        localStorage.setItem("startPage", "Persons");
        break;
      case "5":
        localStorage.setItem("startPage", "Messages");
        break;
      default:
        localStorage.setItem("startPage", "YearCalendar");
    }
  };

  useEffect(() => {
    if (personalSettings) {
      setState({
        startPage: getPersonalSetting(component, "startPage"),
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalSettings]);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Start page")}</div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SomuraAutocomplete
                  style={{ marginTop: "4px" }}
                  name="startPage"
                  label={t("Start page on this device")}
                  value={state.startPage}
                  options={startPageOptions}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralForm);
