import React from "react";
import Paper from "@mui/material/Paper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  item: {
    width: "100%",
    height: "2.5rem",
    padding: "0 8px",
    marginBottom: "4px",
    backgroundColor: theme.palette.background.listItem,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1rem",
    gridTemplateRows: "1fr",
    alignItems: "center",
  },
  itemText: {
    gridColumn: 1,
    display: "flex",
    alignItems: "center",
  },
  listIcon: {
    gridColumn: "3",
  },
}));

const SomuraListItem = (props) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!props.hideChevron) props.onClick(props.value);
  }

  return (
    <Paper square elevation={8} className={classes.item} onClick={handleClick}>
      <div className={classes.itemText}>{props.itemText}</div>
      {!props.hideChevron && <ChevronRightIcon className={classes.listIcon} />}
    </Paper>
  );
};

export default SomuraListItem;
