import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  reminder: {
    fontSize: "1rem",
    marginTop: "0.2rem",
    height: "1.6rem",
    color: theme.palette.main,
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  reminderFixed: {
    height: "1.6rem",
    width: "16rem",
    lineHeight: "1.6rem",
    padding: "0 10px",
    textAlign: "center",
    borderRadius: "1rem 0 0 1rem",
    backgroundColor: theme.palette.red,
    color: "white",
    display: "inline-block",
    position: "absolute",
    zIndex: 10,
    animation: "$sliding 0.2s linear",
  },
  reminderFixedRight: {
    height: "0",
    width: "0",
    marginLeft: "16rem",
    display: "inline-block",
    position: "absolute",
    borderLeft: "0.8rem solid " + theme.palette.red,
    borderTop: "0.8rem solid transparent",
    borderBottom: "0.8rem solid transparent",
    zIndex: 10,
    animation: "$sliding 0.2s linear",
  },
  numReminders: {
    position: "absolute",
    marginTop: "-10px",
    marginLeft: "-45px",
    fontSize: "0.8rem",
    width: "2rem",
    textAlign: "right",
  },
  singleReminder: {
    display: "inline-block",
    borderRadius: "4px",
    padding: "0 4px 0 4px",
    marginTop: "1.85rem",
    marginLeft: "18rem",
    fontSize: "1rem",
    maxWidth: "calc(100% - 18rem)",
    backgroundColor: theme.palette.background.messageList,
    boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    animation: "$moving 5s linear infinite",
  },
  reminderTime: {
    height: "1.8rem",
    fontSize: "1rem",
    color: "white",
    backgroundColor: theme.palette.blue,
    padding: "0 4px",
    margin: "0 4px",
    borderRadius: "4px",
  },
  reminderTitle: {
    display: "inline-block",
    fontWeight: "400",
  },
  "@keyframes moving": {
    "0%": { transform: "translateY(0rem)" },
    "20%": { transform: "translateY(-1.8rem)" },
    "80%": { transform: "translateY(-1.8rem)" },
    "100%": { transform: "translateY(-4rem)" },
  },
  "@keyframes sliding": {
    "0%": { transform: "translateX(-16rem)" },
    "100%": { transform: "translateX(0rem)" },
  },
}));

const mapStateToProps = (state) => {
  return {
    displayDate: state.reminders.displayDate,
    displayReminder: state.reminders.displayReminder,
  };
};

const RemindersBar = ({ displayReminder, currentReminders, displayDate }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [seq, setSeq] = useState(0);

  var reminderDate = moment(displayDate).format("dddd, DD. MMMM YYYY");

  const onIteration = () => {
    if (seq < displayReminder.length - 1) {
      setSeq(seq + 1);
    } else {
      setSeq(0);
    }
  };

  const showReminder = () => {
    const thisReminder = displayReminder[seq];

    const showTime = () => {
      if (
        thisReminder?.isWholeDay ||
        moment(displayDate).isBetween(
          thisReminder?.startDateTime,
          thisReminder?.endDateTime,
          "day",
          "()"
        )
      ) {
        return t("Whole day");
      }
      if (moment(displayDate).isSame(moment(thisReminder?.startDateTime), "day")) {
        return (
          t("starting ") + moment(thisReminder?.startDateTime).format("HH:mm")
        );
      }
      if (moment(displayDate).isSame(thisReminder?.endDateTime, "day")) {
        return (
          t("ending ") + moment(thisReminder?.endDateTime).format("HH:mm")
        );
      }
      return moment(thisReminder?.startDateTime).format("HH:mm");
    };

    return (
      <span
        className={classes.singleReminder}
        onAnimationIteration={onIteration}
      >
        <span className={classes.reminderTime}>{showTime()}</span>
        <span className={classes.reminderTitle}>{thisReminder?.title}</span>
        {thisReminder?.text && ": "}
        {thisReminder?.text}
      </span>
    );
  };

  return (
    <Paper square elevation={8} style={{ borderRadius: "1rem" }}>
      <span className={classes.reminder}>
        <span className={classes.reminderFixed}>{reminderDate}</span>
        <span className={classes.reminderFixedRight}>
          {displayReminder.length > 1 && (
            <span className={classes.numReminders}>
              {displayReminder.length}
            </span>
          )}
        </span>
        {showReminder()}
      </span>
    </Paper>
  );
};

export default connect(mapStateToProps)(RemindersBar);
// export default RemindersBar;
