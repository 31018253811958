import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ScreenTitle from "Components/ScreenTitle";

import SystemForm from "./SystemForm";
import LanguagesForm from "./LanguagesForm";
import MessagesForm from "./MessagesForm";
import PasswordsForm from "./PasswordsForm";
import PersonsForm from "./PersonsForm";

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const GlobalSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content">
      <Paper elevation={8}>
        <ScreenTitle
          title={t("Settings: somura")}
          class="settings"
          icon={
            <img alt="Logo" src="/images/Somura-icon_2tone-light_512.svg" />
          }
        />
        <Collapse in={true} timeout={"auto"}>
          <Tabs
            className="tabPanel"
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabsChange}
          >
            <Tab label={t("Common")} />
            <Tab label={t("Languages")} />
            <Tab label={t("Messages")} />
            <Tab label={t("Passwords")} />
            <Tab label={t("Persons")} />
          </Tabs>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <SystemForm />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <LanguagesForm />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <MessagesForm />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <PasswordsForm />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <PersonsForm />
          </TabPanel>
        </Collapse>
      </Paper>
    </div>
  );
};

export default GlobalSettings;
