import {
  FETCH_TIMES_SUCCESS,
  FETCH_TIMES_REQUEST,
  FETCH_USERTIMES_SUCCESS,
  FETCH_USERTIMES_REQUEST,
  FETCH_USERTIMES_CUM_REQUEST,
  FETCH_USERTIMES_CUM_SUCCESS,
  FETCH_TIMES_FAULTY_REQUEST,
  FETCH_TIMES_FAULTY_SUCCESS,
  SET_TIME,
  END_TIME,
  SET_SELECTED_DATE,
  SET_SELECTED_TIME,
  SET_SELECTED_TIME_FORM_ID,
  SET_START_TIME,
  SET_END_TIME,
  OPEN_START_TIME,
  OPEN_END_TIME,
  UPDATE_TIME,
  DELETE_TIME,
  ADD_TIME,
  TERMINATE_DAY_REQUEST,
  TERMINATE_DAY_SUCCESS,
  OPEN_TERMINATION,
} from "actions/types";
import produce from "immer";

const initialState = {
  Times: [],
  UserTimes: [],
  UserTimesCum: [],
  loading: false,
  selectedDate: "",
  selectedTimeId: -1,
  selectedTimeFormId: -1,
  startTime: null,
  endTime: null,
  openStartTime: false,
  openEndTime: false,
  terminationRecords: 0,
  openTermination: false
};

const timesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_TIMES_REQUEST:
        draft.loading = true;
        break;
      case FETCH_TIMES_SUCCESS:
        draft.Times = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case FETCH_USERTIMES_REQUEST:
        draft.loading = true;
        break;
      case FETCH_USERTIMES_SUCCESS:
        draft.UserTimes = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case FETCH_USERTIMES_CUM_REQUEST:
        draft.loading = true;
        break;
      case FETCH_USERTIMES_CUM_SUCCESS:
        draft.UserTimesCum = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case FETCH_TIMES_FAULTY_REQUEST:
        draft.loading = true;
        break;
      case FETCH_TIMES_FAULTY_SUCCESS:
        draft.UserTimesCum = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case SET_TIME:
        draft.Times = [...draft.Times, { ...action.payload, endTime: null }];
        draft.selectedTimeId = action.payload.id;
        break;
      case END_TIME:
        key = draft.Times.findIndex((T) => T.id === action.payload.id);
        draft.Times[key] = { ...draft.Times[key], ...action.payload };
        break;
      case UPDATE_TIME:
        key = draft.UserTimes.findIndex((T) => T.id === action.payload.id);
        draft.UserTimes[key] = { ...draft.UserTimes[key], ...action.payload };
        draft.selectedTimeId = -1;
        break;
      case DELETE_TIME:
        key = draft.UserTimes.findIndex((T) => T.id === action.payload.id);
        draft = { Times: draft.UserTimes.splice(key, 1) };
        draft.selectedTimeId = -1;
        break;
      case ADD_TIME:
        draft.UserTimes = [...draft.UserTimes, { ...action.payload }];
        draft.selectedTimeId = action.payload.id;
        break;
      case SET_SELECTED_DATE:
        draft.selectedDate = action.payload;
        break;
      case SET_SELECTED_TIME:
        draft.selectedTimeId = action.payload;
        break;
      case SET_SELECTED_TIME_FORM_ID:
        draft.selectedTimeFormId = action.payload;
        break;
      case SET_START_TIME:
        draft.startTime = action.payload;
        break;
      case SET_END_TIME:
        draft.endTime = action.payload;
        break;
      case OPEN_START_TIME:
        draft.openStartTime = action.payload;
        break;
      case OPEN_END_TIME:
        draft.openEndTime = action.payload;
        break;
      case TERMINATE_DAY_REQUEST:
        draft.loading = true;
        break;
      case TERMINATE_DAY_SUCCESS:
        draft.loading = false;
        draft.terminationRecords = action.payload.num_inserted;
        break;
      case OPEN_TERMINATION:
        draft.openTermination = action.payload
        break
      default:
        return draft;
    }
  });

export default timesReducer;
