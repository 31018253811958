import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAbsenceStats } from "redux/index";
import { getDisplaySetting } from "Components/Utilities";
import { Box } from "@mui/system";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";

import Paper from "@mui/material/Paper";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    absenceStats: state.stats.absenceStats,
    offDayTypes: state.offdaytypes.OffDayTypes,
    year: parseInt(state.general.DisplayYear),
    user: state.persons.PersonsMin.find(
      (P) => P.id === state.yearcalendar.displayUserId
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAbsenceStats: (values) => dispatch(fetchAbsenceStats(values)),
  };
};

const AbsenceStats = ({
  absenceStats,
  offDayTypes,
  year,
  user,
  closeStatsForm,
  fetchAbsenceStats,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    closeStatsForm();
  };

  const [displayTypes, setDisplayTypes] = useState([]);

  useEffect(() => {
    if (absenceStats) {
      setDisplayTypes([...new Set(absenceStats.map((S) => S.offDayTypeId))]);
    }
  }, [absenceStats]);

  const title = () => {
    return <span>{user && <Box>{user.listName}</Box>}</span>;
  };

  const Headline = () => {
    const elements = [
      <Box
        key={"hh"}
        sx={{ width: "9rem", height: "2rem", textAlign: "center" }}
      ></Box>,
    ];
    for (var month = 0; month < 12; month++) {
      elements.push(
        <Box
          key={"h" + month}
          sx={{
            
            width: "1.8rem",
            height: "3.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            transform: "rotate(270deg)",
          }}
        >
          {moment([1, month, 1]).format("MMM")}
        </Box>
      );
    }
    elements.push(
      <Box
        key={"h"}
        sx={{
          width: "3.5rem",
          textAlign: "center",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        {year}
      </Box>
    );
    return (
      <Box sx={{ display: "flex", alignItems: "center", mt: "2px",fontWeight: "500", }}>
        {elements}
      </Box>
    );
  };

  const Lines = (props) => {
    const typeId = props.typeId;
    const statsArray = absenceStats.filter((S) => S.offDayTypeId === typeId);
    const typeName = offDayTypes
      ?.find((OT) => OT.id === typeId)
      ?.names.find((N) => N.language === "de-DE")?.offDayType;
    var elements = [];
    var typeArray = Array(12).fill("");
    var yearSum = 0;
    for (var i = 0; i < statsArray.length; i++) {
      var stat = statsArray[i];
      var numDays =
        stat.numDays % 1 === 0 ? parseInt(stat.numDays) : stat.numDays;
      typeArray[stat.month - 1] = numDays;
    }
    for (var month = 0; month < 12; month++) {
      elements.push(
        <Box
          key={"e" + i + "-" + month}
          sx={{
            fontSize: "0.9rem",
            width: "1.8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "2rem",
            border: 1,
            borderColor: "background.highlight",
          }}
        >
          {typeArray[month]}
        </Box>
      );
      if (typeArray[month] !== "") {
        yearSum += parseFloat(typeArray[month]);
      }
    }
    elements.push(
      <Box
        key={"e" + i}
        sx={{
          width: "3.5rem",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "2rem",
          border: 1,
          borderColor: "background.highlight",
        }}
      >
        {yearSum}
      </Box>
    );
    return (
      <Box sx={{ display: "flex", alignItems: "center", mt: "2px" }}>
        <Box sx={{ width: "9rem", height: "2rem" }}>
          <Box
            sx={{
              width: "8rem",
              textAlign: "center",
              p: "0 4px",
              borderRadius: "1rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              m: "4px",
              bgcolor: getDisplaySetting(typeId, "backgroundColor"),
              color: getDisplaySetting(typeId, "fontColor"),
            }}
          >
            {typeName}
          </Box>
        </Box>
        {elements}
      </Box>
    );
  };

  const SumLine = () => {
    const sumArray = Array(13).fill(0);
    for (var i = 0; i < absenceStats.length; i++) {
      var numDays =
        absenceStats[i].numDays % 1 === 0
          ? parseInt(absenceStats[i].numDays)
          : parseFloat(absenceStats[i].numDays);
      sumArray[absenceStats[i].month - 1] += numDays;
      sumArray[12] += numDays;
    }
    const elements = [
      <Box
        key={"sh"}
        sx={{ width: "9rem", textAlign: "center", height: "2rem" }}
      ></Box>,
    ];
    for (var month = 0; month < 12; month++) {
      elements.push(
        <Box
          key={"s" + month}
          sx={{
            fontSize: "0.9rem",
            fontWeight: "500",
            width: "1.8rem",
            textAlign: "center",
            height: "2rem",
          }}
        >
          {sumArray[month]}
        </Box>
      );
    }
    elements.push(
      <Box
        key={"e" + i}
        sx={{
          fontWeight: "500",
          width: "3.5rem",
          textAlign: "center",
          height: "2rem",
        }}
      >
        {sumArray[12]}
      </Box>
    );
    return (
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "2px" }}>
        {elements}
      </Box>
    );
  };

  useEffect(() => {
    fetchAbsenceStats({
      year: year,
      userId: user.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, user]);

  return (
    <Dialog
      sx={{ p: 6 }}
      // className={classes.root}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="dialogTitle"
        dialogTitle={title()}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent sx={{ fontWeight: 200 }}>
        <Box>
          {absenceStats.length > 0 ? (
            <>
              <Headline />
              {displayTypes.length > 0 &&
                displayTypes.map((DT, index) => (
                  <Lines key={"L" + index} typeId={DT} />
                ))}
              <SumLine />
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                fontSize: "1.2rem",
                lineHeight: "1.2rem",
                textAlign: "center",
              }}
            >
              {t("No absence days")}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceStats);
