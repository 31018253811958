import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setGlobalSettings } from "redux/index";
import { getGlobalSetting } from "Components/Utilities";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import SomuraTextField from "Components/SomuraTextField";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    globalSettings: state.settings.GlobalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalSettings: (values) => dispatch(setGlobalSettings(values)),
  };
};

const CommonForm = ({ globalSettings, setGlobalSettings }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "timetracking";
  const [state, setState] = useState({
    // minWorkMinutes: parseInt(getGlobalSetting(component, "minWorkMinutes")),
    ignoreMinutes: parseInt(getGlobalSetting(component, "ignoreMinutes")),
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value.toString(),
    });
  };

  useEffect(() => {
    setState({
      // minWorkMinutes: parseInt(getGlobalSetting(component, "minWorkMinutes")),
      ignoreMinutes: parseInt(getGlobalSetting(component, "ignoreMinutes")),
    });
  }, [globalSettings]);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                {t("Delete records with time less than")}
              </Grid>
              <Grid item xs={3}>
                <SomuraTextField
                  type="number"
                  value={state.ignoreMinutes}
                  onChange={handleChange}
                  min="0"
                  max="15"
                  // step="1"
                  name="ignoreMinutes"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonForm);
