import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import RemarkDialog from "Components/Remarks/RemarkDialog";
import Remark from "./Remark";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    width: "100%",
    maxHeight: "calc(100vh - 380px)",
    display: "flex",
    justifyContent: "center",
  },
  list: {
    width: "40vw",
    minHeight: "20rem",
    overflowY: "auto",
  },
}));

const mapStateToProps = (state) => {
  return {
    remarks: state.remarks.Remarks,
    displayIds: state.remarks.displayRemarksIds || [],
  };
};

const RemarksList = ({ primaryId, displayIds, remarks }) => {
  const classes = useStyles();
  const [openRemarkDialog, setOpenRemarkDialog] = useState(false);
  const [displayRemarks, setDisplayRemarks] = useState(remarks);
  var list = [];

  const handleClick = () => {
    setOpenRemarkDialog(true);
  };

  useEffect(() => {
    setDisplayRemarks(
      remarks.slice().sort(function (a, b) {
        return moment(b.modifiedOn) - moment(a.modifiedOn);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remarks]);

  if (displayIds.length > 0) {
    for (var i = 0; i < displayRemarks.length; i++) {
      if (displayIds.includes(displayRemarks[i].id)) {
        list.push(
          <Remark
            key={i}
            primaryId={primaryId}
            remark={displayRemarks[i]}
            remarkClick={handleClick}
          />
        );
      }
    }
  }

  return (
    <>
      <div className={classes.listContainer}>
        <div className={classes.list}>{list}</div>
      </div>
      {openRemarkDialog && (
        <RemarkDialog close={() => setOpenRemarkDialog(false)} />
      )}
    </>
  );
};

export default connect(mapStateToProps)(RemarksList);
