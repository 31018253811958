import {
  FETCH_UNPUBLISHED_DAYS_SUCCESS
} from 'actions/types'

import produce from 'immer'

const initialState = {
  UnpublishedDays: [],
}

const unpublishedDaysReducer = (state = initialState, action) =>
  produce(state, draft => {
    const PL = action.payload
    // eslint-disable-next-line
    switch (action.type) {
      case FETCH_UNPUBLISHED_DAYS_SUCCESS:
        draft.UnpublishedDays = PL
        break
    }
  })

export default unpublishedDaysReducer