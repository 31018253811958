import React, { useEffect, useState, useCallback } from "react";
import {
  updateRegion,
  deleteRegion,
  setSelectedRegionId,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSelectField from "Components/SomuraSelectField";
import makeStyles from "@mui/styles/makeStyles";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const RegionSchema = Yup.object().shape({
  regionLong: Yup.string().required("Pflichtfeld"),
});

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  const countries = state.countries.Countries;
  const selectedCountryId = state.countries.selectedCountryId;
  const selectedRegionId = state.countries.selectedRegionId;
  return {
    countries,
    selectedCountryId,
    selectedRegionId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRegion: (values) => dispatch(updateRegion(values)),
    deleteRegion: (values) => dispatch(deleteRegion(values)),
    setSelectedRegionId: (id) => dispatch(setSelectedRegionId(id)),
  };
};

const RegionForm = ({
  countries,
  selectedCountryId,
  selectedRegionId,
  setSelectedRegionId,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const regions = countries?.find((C) => C.id === selectedCountryId)?.regions;
  const selectedRegion = regions?.find((R) => R.id === selectedRegionId);
  var regionOptions =
    regions
      ?.filter((R) => R.parentRegionId === "0" && R.id !== selectedRegionId)
      .map((R) => ({
        label: R.regionLong,
        value: R.id,
      })) || [];
  regionOptions.push({ label: "", value: 0 });
  const emptyState = {
    id: 0,
    regionShort: "",
    regionLong: "",
    parentRegionId: 0,
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);
  const formHeader =
    countries?.find((C) => C.id === selectedCountryId)?.ownName +
    ": " +
    (selectedRegion ? selectedRegion.regionLong : t("New region"));

  const handleSubmit = () => {
    props.updateRegion({ ...state, countryId: selectedCountryId });
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const handleClose = () => {
    setSelectedRegionId(-1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await RegionSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (selectedRegionId > 0) {
      setState(selectedRegion);
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegionId]);

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "cancel":
        handleClose();
        break;
      case "delete":
        props.deleteRegion({ id: selectedRegionId });
        handleClose();
        break;
      default:
        handleClose();
    }
  };

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <SomuraTextField
              type="text"
              name="regionLong"
              label={t("Region")}
              value={state.regionLong}
              maxlength="50"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraTextField
              type="text"
              name="regionShort"
              label={t("Abbreviation")}
              value={state.regionShort}
              maxlength="5"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={8}>
            <SomuraSelectField
              name="parentRegionId"
              label={t("Subregion of")}
              options={regionOptions}
              value={state.parentRegionId}
              onChange={handleChange}
              // onChange={(event) => handleChange(event.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton
          onClick={() => {
            handleButtonClick("cancel");
          }}
        />
        <DeleteButton
          disabled={true}
          onClick={() => {
            handleButtonClick("delete");
          }}
        />
        <SaveButton onClick={handleSubmit} disabled={!valid} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionForm);
