import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPersonalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getPersonalSetting } from "Components/Utilities";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import SomuraSwitchField from "Components/SomuraSwitchField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    countries: state.countries.Countries,
    personalSettings: state.settings.PersonalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalSettings: (values) => dispatch(setPersonalSettings(values)),
  };
};

const AllCalendarsForm = ({
  setPersonalSettings,
  loggedUserId,
  countries,
  personalSettings,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "calendars";
  const emptyState = {
    showOnlyActive: 0,
    useCalendarTooltips: 0,
    useDayInfo: 0,
    useSpecialDayTooltips: 0,
    showCalendarweeks: 0,
    showHolidays: 0,
    showHolidaysOnlyApprentices: 0,
    showRemarkableDays: 0,
    countryId: "",
    regionId: "",
  };
  const [state, setState] = useState(emptyState);

  const countryOptions = countries.map((C) => ({
    label: C.ownName,
    value: C.id,
  }));

  const regions = countries?.find((C) => C.id === state.countryId)?.regions;

  var regionOptions =
    regions?.map((R) => ({
      label:
        R.regionLong +
        (R.parentRegionId > 0
          ? " (" +
            regions?.find((SR) => SR.id === R.parentRegionId)?.regionLong +
            ")"
          : ""),
      value: R.id,
    })) || [];

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    setPersonalSettings({
      userId: loggedUserId,
      component: component,
      key: name,
      value: value,
    });
    if (name === "countryId") {
      setState({ ...state, [name]: value, regionId: "" });
      setPersonalSettings({
        userId: loggedUserId,
        component: component,
        key: "regionId",
        value: "",
      });
      regionOptions = countries
        .find((C) => C.id === value)
        .regions.map((R) => ({
          label: R.regionLong,
          value: R.id,
        }));
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked ? 1 : 0;
    setState({ ...state, [name]: value });
    setPersonalSettings({
      userId: loggedUserId,
      component: component,
      key: name,
      value: value,
    });
  };

  useEffect(() => {
    if (personalSettings) {
      setState({
        showOnlyActive: parseInt(
          getPersonalSetting(component, "showOnlyActive")
        ),
        useCalendarTooltips: parseInt(
          getPersonalSetting(component, "useCalendarTooltips")
        ),
        useDayInfo: parseInt(
          getPersonalSetting(component, "useDayInfo")
        ),
        useSpecialDayTooltips: parseInt(
          getPersonalSetting(component, "useSpecialDayTooltips")
        ),
        showCalendarweeks: parseInt(
          getPersonalSetting(component, "showCalendarweeks")
        ),
        showHolidays: parseInt(getPersonalSetting(component, "showHolidays")),
        showHolidaysOnlyApprentices: parseInt(
          getPersonalSetting(component, "showHolidaysOnlyApprentices")
        ),
        showRemarkableDays: parseInt(
          getPersonalSetting(component, "showRemarkableDays")
        ),
        countryId: getPersonalSetting(component, "countryId"),
        regionId: getPersonalSetting(component, "regionId"),
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalSettings]);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className={"subLine"}>Filter</div>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="showOnlyActive"
                  checked={state.showOnlyActive === 1}
                  onChange={handleSwitchChange}
                  label={t("Show only active employees")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={"subLine"}>{t("Day details")}</div>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="useCalendarTooltips"
                  checked={state.useCalendarTooltips === 1}
                  onChange={handleSwitchChange}
                  label={t("Use tooltips")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="useDayInfo"
                  checked={state.useDayInfo === 1}
                  onChange={handleSwitchChange}
                  label={t("Use info bar")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="useSpecialDayTooltips"
                  checked={state.useSpecialDayTooltips === 1}
                  disabled={state.useCalendarTooltips === 0 && state.useDayInfo === 0}
                  onChange={handleSwitchChange}
                  label={t("No details for normal days")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={"subLine"}>{t("Calendar weeks")}</div>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="showCalendarweeks"
                  checked={state.showCalendarweeks === 1}
                  onChange={handleSwitchChange}
                  label={t("Show calendar weeks")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={"subLine"}>{t("School holidays")}</div>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="showHolidays"
                  checked={state.showHolidays === 1}
                  onChange={handleSwitchChange}
                  label={t("Show school holidays")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="showHolidaysOnlyApprentices"
                  checked={state.showHolidaysOnlyApprentices === 1}
                  disabled={state.showHolidays === 0}
                  onChange={handleSwitchChange}
                  label={t("Show school holidays only for apprentices")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Special days")}</div>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="showRemarkableDays"
                  checked={state.showRemarkableDays === 1}
                  onChange={handleSwitchChange}
                  label={t("Show special days")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Location")}</div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SomuraAutocomplete
                  name="countryId"
                  label={t("Country")}
                  value={state.countryId}
                  options={countryOptions}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SomuraAutocomplete
                  name="regionId"
                  label={t("Region")}
                  value={state.regionId}
                  options={regionOptions}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCalendarsForm);
