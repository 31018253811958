import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FlipMove from "react-flip-move";
import MovementItem from "./MovementItem";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 190px)",
    backgroundColor: theme.palette.background.messageList,
    marginRight: "4px",
    marginLeft: "4px",
    display: "grid",
    gridTemplateRows: "2rem 1fr",
  },
  listTop: {
    paddingLeft: "4px",
    display: "grid",
    gridTemplateColumns: "1fr 1rem",
    gridTemplateRows: "2.3rem 1fr",
    alignItems: "center",
  },
  listTitle: {
    gridColumn: "1 / 3",
    fontSize: "1.3em",
    paddingLeft: "10px",
    color: theme.palette.text.screenTitle,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  listContainer: {
    overflowY: "auto",
    gridRow: "2",
  },
  new: {
    gridRow: "1",
    gridColumn: "2",
    alignSelf: "end",
  },
  messagesList: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    paddingTop: "0 !important",
  },
  listItem: {
    padding: "0 0 4px 0 !important",
  },
  listPaper: {
    width: "100%",
    padding: "0 !important",
    margin: "2px 6px",
    backgroundColor: theme.palette.background.messageListItem,
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
      opacity: "0.5",
    },
    borderRadius: "4px",
  },
}));

const mapStateToProps = (state) => {
  const articles = state.articles.Articles;
  const selectedId = state.articles.selectedArticleId;
  return {
    articles,
    selectedId,
  };
};

const MovementsList = ({ articles, selectedId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const article = articles?.find((A) => A.id === selectedId);
  var articleMovements = article?.movements
  articleMovements = articleMovements?.slice()?.sort(
    (a, b) =>
      b.movementDate?.localeCompare(a.movementDate) ||
      b.timeStamp?.localeCompare(a.timeStamp)
  ) || [];
  if (article?.movements.length > 0) {
    const movements = articleMovements.map((M, index) => (
      <ListItem
        // button
        disableGutters
        key={index}
        className={classes.listItem}
      >
        <MovementItem
          key={index}
          movementKey={index}
          id={M.movementId}
          articleId={selectedId}
          date={moment(M.movementDate).format("L")}
          type={M.movementTypeName}
          location={M.locationName}
          person={M.personName}
        />
      </ListItem>
    ));
    return (
      <List>
        <FlipMove>{movements}</FlipMove>
      </List>
    );
  } else {
    return <div>{t("No movements")}</div>;
  }
};

export default connect(mapStateToProps)(MovementsList);
