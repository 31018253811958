import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setGlobalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { languages } from "Constants";
import { getGlobalSetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalSettings: (values) => dispatch(setGlobalSettings(values)),
  };
};

const LanguagesForm = ({ setGlobalSettings, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "language";
  const [state, setState] = useState({
    multilingual: parseInt(getGlobalSetting(component, "multilingual")) === 1,
    systemLanguage: getGlobalSetting(component, "systemLanguage"),
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value.toString(),
    });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value ? "1" : "0",
    });
  };

  useEffect(() => {
    setState({
      multilingual: parseInt(getGlobalSetting(component, "multilingual")) === 1,
      systemLanguage: getGlobalSetting(component, "systemLanguage"),
    });
  }, []);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="multilingual"
              checked={state.multilingual}
              onChange={handleSwitchChange}
              label={t("Support for multiple languages")}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            {!state.multilingual && (
              <SomuraAutocomplete
                name="systemLanguage"
                label={t("System language")}
                value={state.systemLanguage}
                onChange={handleChange}
                options={languages.map((L) => ({
                  label: L.languageLongName,
                  value: L.languageName,
                }))}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(LanguagesForm);
