import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "Constants";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import "moment/locale/de";

import { store } from "redux/store";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    padding: "6px",
  },
  dialogContent: {
    fontSize: "0.8rem",
    fontWeight: "200",
  },
  nameHeadline: {
    fontSize: "1.2rem",
    textAlign: "center",
    color: "white",
    margin: "0 4px",
  },
}));

const LanguageSelection = ({ settings, close, ...props }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [state, setState] = useState({language: localStorage.getItem("lang")})

  const handleSubmit = () => {
    const languageShort = languages.find((L) => L.languageName === state.language)
      .languageMenuName;
    store.dispatch({
      type: "SET_SYSTEM_LANGUAGE",
      payload: { language: state.language, languageShort: languageShort },
    });
    i18n.changeLanguage(state.language);
    moment.locale(languageShort.substr(0, 2));
    window.localStorage.setItem("lang", state.language);
    window.localStorage.setItem("langShort", languageShort);
    props.handleChange(languageShort);
    close();
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value
    setState({ ...state, [name]: value });
  };

  const handleClose = () => {
    close()
  };

  const title = () => {
    return <div className={classes.nameHeadline}>{t("Select a language")}</div>;
  };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose(event, reason);
        }
      }}
    >
      <DialogTitle
        className="dialogTitle"
        dialogTitle={title()}
        closeForm={handleClose}
        width="400px"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent className={classes.dialogContent}>
        <div>
          <SomuraAutocomplete
            name="language"
            label={t("Language")}
            options={languages.map((L) => ({
              label: L.languageLongName,
              value: L.languageName,
            }))}
            value={state.language}
            onChange={(values) => handleAutoCompleteChange(values)}
            variant="outlined"
            size="small"
            fullWidth
            required
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CancelButton onClick={handleClose} />
        <SaveButton onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default LanguageSelection;
