import {
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_MINIMAL_SUCCESS,
  FETCH_DEPARTMENT,
  ADD_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_MINIMAL_REQUEST,
  DEPARTMENTS_FAILURE,
  RESET_DEPARTMENT,
  NEW_DEPARTMENT,
  SET_DEPARTMENT_ID
} from 'actions/types'
import produce from 'immer'

const initialState = {
  Departments: [],
  DepartmentsMin: [],
  loading: false,
  error: '',
  selectedDepartmentId: -2
}

const departmentReducer = (state = initialState, action) => 
produce(state, draft => {
  let key = 0
  switch (action.type) {
    case FETCH_DEPARTMENTS_REQUEST:
      draft.loading = true
      break
    case FETCH_DEPARTMENTS_SUCCESS: 
      draft.Departments = action.payload
      draft.loading = false
      draft.error = ''
      break
    case FETCH_DEPARTMENTS_MINIMAL_REQUEST:
      draft.loading = true
      break
    case FETCH_DEPARTMENTS_MINIMAL_SUCCESS: 
      draft.DepartmentsMin = action.payload
      draft.loading = false
      draft.error = ''
      break
    case DEPARTMENTS_FAILURE:
      draft.loading = false
      draft.error = action.payload
      break
    case FETCH_DEPARTMENT:
      draft.selectedDepartmentId = action.payload
      break
    case UPDATE_DEPARTMENT:
      key = draft.Departments.findIndex(Departments => Departments.id === action.payload.id)
      draft.Departments[key] = action.payload
      draft.selectedDepartmentId = -2
      break      
    case ADD_DEPARTMENT:
      draft.Departments = [...draft.Departments, action.payload]
      draft.selectedDepartmentId = -2
      draft.loading = false;
      draft.error = "";
      break      
    case DELETE_DEPARTMENT:
      key = draft.Departments.findIndex(Departments => Departments.id === action.payload)
      draft = {Departments: draft.Departments.splice(key, 1)}
      draft.selectedDepartmentId = -2
      break      
    case RESET_DEPARTMENT:
      draft.selectedDepartmentId = -2
      break        
    case NEW_DEPARTMENT:
      draft.selectedDepartmentId = 0
      break
    case SET_DEPARTMENT_ID:
      draft.selectedDepartmentId = action.payload;
      break
    default: return state
  }
})

export default departmentReducer    