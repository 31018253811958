

import {
  addLog
} from '../api'

const LogFailure = (err) => {
  return {
    type: 'LOG_FAILURE',
    payload: err
  }
}

export const newLogEntry = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(addLog(values))
    } catch (err) {
      await dispatch(LogFailure(err))
    }
  }
}
