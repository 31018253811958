import {
  FETCH_GROUPTYPES_REQUEST,
  FETCH_GROUPTYPES_SUCCESS,
  GROUPTYPES_FAILURE
} from 'actions/types'
import produce from 'immer'

const initialState = {
  loading: false,
  GroupTypes: [],
  error: ''
}

const groupTypesReducer = (state = initialState, action) => 
  produce(state, draft => {
    switch (action.type) {
      case FETCH_GROUPTYPES_REQUEST:
        draft.loading = true
        return
      case FETCH_GROUPTYPES_SUCCESS: 
        draft.loading = false
        draft.GroupTypes = action.payload
        draft.error = ''
        return
      case GROUPTYPES_FAILURE:
        draft.loading = false
        draft.error = action.payload
        return
      default:
        return draft
    }
  })


export default groupTypesReducer    