import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchHolidays,
  setHolidayId,
  fetchHolidayTypes,
  setEditMode,
} from "redux/index";
import makeStyles from "@mui/styles/makeStyles";
import { sortByDate } from "Components/Utilities";
import moment from "moment";
import "moment/locale/de.js";
import SomuraDataGrid from "Components/SomuraDataGrid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
}));

const mapStateToProps = (state) => {
  return {
    holidays: state.specialdays.Holidays,
    countries: state.countries.Countries,
    loggedAdmin: state.loggeduser.LoggedUser.isadmin,
    loggedAssistant: state.loggeduser.LoggedUser.isassistant,
    dataLoading: state.specialdays.loading,
    dataError: state.general.error,
    holidaytypes: state.specialdays.HolidayTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHolidays: () => dispatch(fetchHolidays()),
    setHolidayId: (id) => dispatch(setHolidayId(id)),
    fetchHolidayTypes: () => dispatch(fetchHolidayTypes()),
    setEditMode: (editMode) => dispatch(setEditMode(editMode)),
  };
};

const HolidaysTable = ({ holidays, countries, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: t("Designation"),
      headerClassName: "tableHeader",
      field: "holidayTypeId",
      options: {
        customBodyRender: (value) =>
          props.holidaytypes.find((x) => x.id === value).holidayName,
      },
      flex: 0.3,
    },
    {
      headerName: t("Country"),
      headerClassName: "tableHeader",
      field: "country",
      flex: 0.3,
    },
    {
      headerName: t("Region"),
      headerClassName: "tableHeader",
      field: "region",
      flex: 0.3,
    },
    {
      headerName: t("First day of vacation"),
      headerClassName: "tableHeader",
      field: "startDate",
      type: "date",
      sortComparator: (a, b) => sortByDate(a, b),
      renderCell: (params) => moment(params.value).format("L"),
      flex: 0.3,
    },
    {
      headerName: t("Last day of vacation"),
      headerClassName: "tableHeader",
      field: "endDate",
      type: "date",
      sortComparator: (a, b) => sortByDate(a, b),
      renderCell: (params) => moment(params.value).format("L"),
      flex: 0.3,
    },
  ];

  const rows = holidays.map((H, index) => ({
    key: index,
    id: H.id,
    holidayTypeId: props.holidaytypes?.find((HT) => HT.id === H.holidayTypeId)
      ?.holidayName,
    country: countries.find((C) => C.id === H.countryId).germanName,
    region: countries.find((C) => C.id === H.countryId).regions.find(R => R.id === H.regionId)?.regionLong || "",
    startDate: H.startDate,
    endDate: H.endDate,
  }));

  const handleRowClick = (params) => {
    props.setHolidayId(params);
  };

  useEffect(() => {
    props.fetchHolidayTypes();
    props.fetchHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "new":
        props.setEditMode(true);
        props.setHolidayId(0);
        break;
      default:
    }
  };

  return (
    <div>
      <SomuraDataGrid
        tableHeight="65vh"
        rows={rows}
        columns={columns}
        defaultSortModel={[
          {
            field: "startDate",
            sort: "asc",
          },
        ]}
        filterModel={{
          items: [
            {
              columnField: "endDate",
              operatorValue: "onOrAfter",
              value: moment().format("YYYY-MM-DD"),
            },
          ],
        }}
        onRowClick={(params) => handleRowClick(params)}
        csvFileName={t("School holidays")}
      />
      <Fab
        variant="extended"
        color="primary"
        size="medium"
        className={classes.fab}
        onClick={() => handleButtonClick("new")}
      >
        <AddIcon />
        {t("New school holidays")}
      </Fab>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidaysTable);
