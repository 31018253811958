import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { fetchReasons, fetchStates, updateArticle } from "redux/index";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getGlobalSetting } from "Components/Utilities";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.scrollBar,
  },
  dialogTitle: {
    width: "30rem",
    padding: "4px 10px",
    fontWeight: "200",
    color: theme.palette.text.screenTitle,
  },
  dialogText: {
    color: theme.palette.text.calendars,
  },
  dialogContent: {
    padding: "10px 10px 6px 10px",
    backgroundColor: theme.palette.dialog.content,
  },
  dialogActions: {
    padding: "0 8px",
  },
}));

const mapStateToProps = (state) => {
  const articles = state.articles.Articles;
  const articleId = state.articles.selectedArticleId;
  const reasons = state.articles.Reasons;
  const states = state.articles.States;
  return { articles, articleId, reasons, states };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReasons: () => dispatch(fetchReasons()),
    fetchStates: () => dispatch(fetchStates()),
    update: (values) => dispatch(updateArticle(values)),
  };
};

const ReturnLoanAssetDialog = ({
  articleId,
  close,
  articles,
  reasons,
  states,
  fetchReasons,
  fetchStates,
  update,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const article = articles?.find((A) => A.id === articleId);

  const movementState = {
    movementDate: moment().format("YYYY-MM-DD"),
    movementTypeId: getGlobalSetting("articles", "moveFromUser"),
    movementTypeName: "",
    locationId: getGlobalSetting("articles", "moveFromUserLocation"),
    locationName: "",
    isFreeStock: true,
    personId: "0",
    personName: "",
    reasonId: "0",
    stateId: "0",
  };
  const [state, setState] = useState(movementState);
  const [valid, setValid] = useState(false);

  var reasonOptions = reasons.map((R) => ({
    label: R.reason,
    value: R.id,
  }));
  reasonOptions.unshift({ label: "", value: "0" });

  var stateOptions = states.map((S) => ({
    label: S.status,
    value: S.id,
  }));
  stateOptions.unshift({ label: "", value: "0" });

  const articleSchema = Yup.object().shape({
    movementDate: Yup.date().required(t("Required")),
  });

  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    update({ ...article, nextMovement: state });
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setState({ ...state, movementDate: value });
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    setState({ ...state, [name]: value });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await articleSchema.isValid(state));
  });

  useEffect(() => {
    fetchReasons();
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div>
      <Dialog
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(event, reason);
          }
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {t("Return article from user")}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <div className={classes.dialogContent}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SomuraTextField
                  name="articleTypeName"
                  label={t("Article type")}
                  value={article.articleTypeName}
                  disabled={true}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <SomuraTextField
                  label={t("Model")}
                  type="text"
                  name="modelName"
                  value={article.modelName}
                  disabled={true}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraTextField
                  label={t("Inventory number")}
                  type="text"
                  name="inventoryNumber"
                  value={article.inventoryNumber}
                  disabled={true}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraTextField
                  label={t("Manufacturer serial number")}
                  type="text"
                  name="manufacturerSerialNumber"
                  value={article.manufacturerSerialNumber}
                  disabled={true}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraTextField
                  label={t("Handout date")}
                  type="text"
                  name="movementDate"
                  value={moment(article.movements[0].movementDate).format("L")}
                  disabled={true}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraTextField
                  label={t("Return date")}
                  type="date"
                  name="movementDate"
                  value={state.movementDate}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraAutocomplete
                  name="reasonId"
                  label={t("Reason")}
                  options={reasonOptions}
                  value={state.reasonId}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraAutocomplete
                  name="stateId"
                  label={t("Condition")}
                  options={stateOptions}
                  value={state.stateId}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CancelButton onClick={handleCancel} />
          <SaveButton disabled={!valid} onClick={handleSubmit} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnLoanAssetDialog);
