import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DisplaySubForm from "./DisplaySubForm";
import AbsenceSubForm from "./AbsenceSubForm";

const useStyles = makeStyles((theme) => ({
  listItem: {
    width: "100%",
    height: "2.5rem",
    padding: "0 8px",
    marginBottom: "4px",
    backgroundColor: theme.palette.background.listItem,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1rem",
    gridTemplateRows: "1fr",
    alignItems: "center",
  },
  listItemText: {
    gridColumn: "1",
  },
  listIcon: {
    gridColumn: "3",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    displaySettings: state.settings.DisplaySettings,
    offDayTypes: state.offdaytypes.OffDayTypes,
  };
};

const DisplayForm = ({ displaySettings, offDayTypes, fetchOffDayTypes }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");

  const [selectedComponent, setComponent] = useState("");
  const [selectedAbsenceComponent, setAbsenceComponent] = useState("");

  const handleSelect = (component) => {
    setAbsenceComponent("");
    setComponent(component);
  };

  const handleAbsenceSelect = (component) => {
    setComponent("");
    setAbsenceComponent(component);
  };

  const handleClose = () => {
    setComponent("");
    setAbsenceComponent("");
  };

  return (
    <div>
      <Collapse
        in={selectedComponent === "" && selectedAbsenceComponent === ""}
        // orientation={"horizontal"}
        timeout={"auto"}
      >
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <List>
                {displaySettings
                  .filter((DS) => DS.isAbsence === "0")
                  ?.map((DS, index) => (
                    <ListItem
                      key={index}
                      className={classes.listItem}
                      onClick={() => {
                        handleSelect(DS.component);
                      }}
                      id={DS.component}
                    >
                      <div className={classes.listItemText}>
                        {t(DS.component)}
                      </div>
                      <ChevronRightIcon className={classes.listIcon} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <List>
                {displaySettings
                  .filter((DS) => DS.isAbsence === "1" && DS.component !== "standardAbsence")
                  ?.map((DS, index) => (
                    <ListItem
                      key={index}
                      className={classes.listItem}
                      onClick={() => {
                        handleAbsenceSelect(DS.component);
                      }}
                      id={DS.component}
                    >
                      <div className={classes.listItemText}>
                        {
                          offDayTypes
                            .find((OT) => OT.id === DS.component)
                            ?.names.find((OTN) => OTN.language === language)
                            ?.offDayType
                        }
                      </div>
                      <ChevronRightIcon className={classes.listIcon} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
        </div>
      </Collapse>
      <Collapse
        in={selectedComponent !== ""}
        // orientation={"horizontal"}
        timeout={"auto"}
      >
        <DisplaySubForm
          component={selectedComponent}
          closeSubform={handleClose}
        />
      </Collapse>
      <Collapse
        in={selectedAbsenceComponent !== ""}
        // orientation={"horizontal"}
        timeout={"auto"}
      >
        <AbsenceSubForm
          component={selectedAbsenceComponent}
          closeSubform={handleClose}
        />
      </Collapse>
    </div>
  );
};

export default connect(mapStateToProps)(DisplayForm);
