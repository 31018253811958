import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "redux/store";
import { fetchStats, setYearWorkdays } from "redux/index";
import {
  hexToRGB,
  getGlobalSetting,
  getDisplaySetting,
  getPersonalSetting,
} from "Components/Utilities";
import moment from "moment";
import { icons } from "Constants";
import makeStyles from "@mui/styles/makeStyles";
import "../Calendars.scss";
import CalendarDayFrame from "./CalendarDayFrame";

import SelectionForm from "./Components/SelectionForm";

import RemindersDialog from "../../Reminders/RemindersDialog";

const useStyles = makeStyles((theme) => ({
  monthname: {
    gridColumn: "1",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px",
  },
  workdays: {
    gridColumn: "2",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
    paddingRight: "4px",
    fontSize: "0.8rem",
    justifySelf: "end",
  },
  currentMonth: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
}));

const mapStateToProps = (state) => {
  return {
    offDayTypes: state.offdaytypes.OffDayTypes,
    editMode: state.general.EditMode,
    yearData: state.year.Year,
    yearStaticData: state.year.YearStatic,
    settings: state.settings.PersonalSettings?.find(
      (GS) => GS.component === "calendars"
    )?.settings,
    loggedUserId: state.loggeduser.LoggedUser.id,
    loggedAdmin: state.loggeduser.LoggedUser.isadmin,
    loggedAssistant: state.loggeduser.LoggedUser.isassistant,
    displayyear: state.general.DisplayYear,
    userid: state.yearcalendar.displayUserId,
    user: state.persons.PersonsMin.find(
      (P) => P.id === state.yearcalendar.displayUserId
    ),
    reminders: state.reminders.Reminders,
    highlightApprovement: state.yearcalendar.displayApprovementId,
    showReminderDate: state.reminders.showReminderDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStats: (values) => dispatch(fetchStats(values)),
    setYearWorkdays: (value) => dispatch(setYearWorkdays(value)),
  };
};

function YearCalDays({
  yearData,
  yearStaticData,
  settings,
  highlightApprovement,
  showReminderDate,
  setYearWorkdays,
  ...props
}) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");

  // Variables for marking frames
  const [state, setState] = useState({
    mouseDown: false,
    startId: "",
    startCellId: "",
    endId: "",
    endCellId: "",
  });

  const userIsApprentice = props.user ? props.user.isapprentice : false;
  const today = moment();
  let days = [];

  // Get personal settings
  const useSpecialDayTooltips = parseInt(settings?.useSpecialDayTooltips) === 1;
  const showCalendarweeks = parseInt(settings?.showCalendarweeks) === 1;
  const showHolidays = parseInt(settings?.showHolidays) === 1;
  const showHolidaysOnlyApprentices =
    parseInt(settings?.showHolidaysOnlyApprentices) === 1;
  const showRemarkableDays = parseInt(settings?.showRemarkableDays) === 1;
  const showRemindersOnlyFuture =
    parseInt(getPersonalSetting("reminders", "showOnlyFuture")) === 1;

  const clearSelection = () => {
    store.dispatch({
      type: "SET_SELECTED_IDS",
      payload: { startId: -1, endId: -1 },
    });
    store.dispatch({ type: "SET_SELECTED_WORKDAYS", payload: 0 });
    store.dispatch({ type: "SET_SELECTED_DAYS", payload: [] });
  };

  useEffect(() => {
    const startSel = Math.min(state.startId, state.endId);
    const endSel = Math.max(state.startId, state.endId);
    if (startSel > 0 && endSel > 0) {
      store.dispatch({
        type: "SET_SELECTED_IDS",
        payload: { startId: startSel, endId: endSel },
      });

      store.dispatch({
        type: "SET_DAY_INFO",
        payload: {
          startDate: document
            .getElementById(state.startCellId)
            ?.getAttribute("date"),
          endDate: document
            .getElementById(state.endCellId)
            ?.getAttribute("date"),
          user: props.user.listName,
        },
      });

      let checkElement = {};
      let workdays = 0;
      let startDate = "";
      let endDate = "";
      let dates = [];
      let days = [];

      for (var id = startSel; id <= endSel; id++) {
        checkElement = document.getElementById("d" + id);
        if (checkElement) {
          if (parseFloat(checkElement.getAttribute("workdayfactor")) > 0) {
            workdays += parseFloat(checkElement.getAttribute("workdayfactor"));
            dates.push(checkElement.getAttribute("date"));
          }
          if (id === startSel) {
            startDate = checkElement.getAttribute("date");
          }
          if (id === endSel) {
            endDate = checkElement.getAttribute("date");
          }
        }
      }
      days = yearStaticData.filter(function (item) {
        return dates.includes(item.date);
      });
      if (state.saveToStore === true) {
        store.dispatch({ type: "SET_SELECTED_WORKDAYS", payload: workdays });
        store.dispatch({
          type: "SET_SELECTED_DAYS",
          payload: { startDate: startDate, endDate: endDate, days: days },
        });
      }
    }
    // eslint-disable-next-line
  }, [state]);

  const handleMouseDown = (id) => {
    store.dispatch({ type: "SET_DISPLAY_APPROVEMENT", payload: 0 });
    setState({
      mouseDown: true,
      saveToStore: false,
      startId: id.replace("d", ""),
      startCellId: id,
      endId: id.replace("d", ""),
      endCellId: id,
    });
  };

  const handleMouseUp = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: true });
    setState({
      ...state,
      mouseDown: false,
      saveToStore: true,
    });
  };

  const handleMouseEnter = (id) => {
    if (state.mouseDown) {
      setState({
        ...state,
        saveToStore: false,
        endId: id.replace("d", ""),
        endCellId: id,
      });
      // setSelection(userId, false);
    }
  };

  const handleMouseMove = (id) => {
    if (state.mouseDown) {
      setState({
        ...state,
        saveToStore: false,
        endId: id.replace("d", ""),
        endCellId: id,
      });
      // setSelection(userId, false);
    }
  };

  const handleHover = (reminder, date) => {
    store.dispatch({
      type: "SET_DISPLAY_REMINDER",
      payload: { reminder: reminder, displayDate: date },
    });
  };

  const monthLine = (month, year, mode) => {
    let modeKey = "";
    let modeFactor = 0;
    let monthNameYear = "";
    if (mode === "prev") {
      year--;
      modeKey = "P";
      modeFactor = -365;
      monthNameYear = " " + year;
    }
    if (mode === "next") {
      year++;
      modeKey = "N";
      modeFactor = +366;
      monthNameYear = " " + year;
    }
    let monthdays = [];
    let workdays = 0;
    let monthName =
      moment([year, month, 1, 0, 0, 0, 0]).format("MMMM") + monthNameYear;
    let isCurrentMonth =
      moment([year, month, 1, 0, 0, 0, 0]).format("YYYY-MM") ===
      moment().format("YYYY-MM");
    let currentMoment = moment([year, month, 1, 0, 0, 0, 0]).startOf("month");
    let monthEnd = moment([year, month, 1, 0, 0, 0, 0]).endOf("month");
    // Fetch settings
    const workOnSaturday =
      parseInt(getGlobalSetting("system", "workOnSaturday")) === 1;
    const firstDayOfWeek = getGlobalSetting("system", "firstDayOfWeek");
    const workdayColor = getDisplaySetting("Working days", "backgroundColor");
    const weekendColor = getDisplaySetting("Weekend", "backgroundColor");
    const schoolHolidaysShowAsBar =
      parseInt(getDisplaySetting("School holidays", "showAsBar")) === 1;
    const schoolHolidaysBackColor = getDisplaySetting(
      "School holidays",
      "backgroundColor"
    );
    const schoolHolidaysFontColor = getDisplaySetting(
      "School holidays",
      "fontColor"
    );
    const schoolHolidaysShowInTooltip =
      parseInt(getDisplaySetting("School holidays", "showInTooltip")) === 1;
    const specialDaysShowAsBar =
      parseInt(getDisplaySetting("Special days", "showAsBar")) === 1;
    const specialDaysBackColor = getDisplaySetting(
      "Special days",
      "backgroundColor"
    );
    const specialDaysShowInTooltip =
      parseInt(getDisplaySetting("Special days", "showInTooltip")) === 1;
    const specialDaysTooltipFontColor = getDisplaySetting(
      "Special days",
      "tooltipFontColor"
    );
    const specialDaysIdentifier = getDisplaySetting(
      "Special days",
      "identifier"
    );
    const specialDaysFontColor = getDisplaySetting("Special days", "fontColor");
    const holidaysShowAsBar =
      parseInt(getDisplaySetting("Holidays", "showAsBar")) === 1;
    const holidaysBackColor = getDisplaySetting("Holidays", "backgroundColor");
    const holidaysShowInTooltip =
      parseInt(getDisplaySetting("Holidays", "showInTooltip")) === 1;
    const holidaysTooltipFontColor = getDisplaySetting(
      "Holidays",
      "tooltipFontColor"
    );
    const holidaysIdentifier = getDisplaySetting("Holidays", "identifier");
    const holidaysFontColor = getDisplaySetting("Holidays", "fontColor");
    const birthdayIcon = icons.find(
      (I) =>
        parseInt(I.id) === parseInt(getDisplaySetting("Birthdays", "iconId"))
    )?.path;
    const birthdayFontColor = getDisplaySetting("Birthdays", "fontColor");
    const birthdayTooltipFontColor = getDisplaySetting(
      "Birthdays",
      "tooltipFontColor"
    );

    while (currentMoment.isSame(monthEnd, "month")) {
      let key = currentMoment.dayOfYear();
      let currentDate = currentMoment.format("YYYY-MM-DD");
      let currentState = yearData.find((YD) => YD.date === currentDate);
      let staticState = yearStaticData.find((YD) => YD.date === currentDate);
      let isToday = currentMoment.isSame(today, "day");

      let isMarkable = false;
      let specialDayKey = "";
      let isHalfDay = false;
      let isOffDay = false;
      let isPublished = true;
      let offDay;
      let offDayName = "";
      let offDayKey = "";
      let week = "";
      let workdayfactor = 1;
      let showTooltip = !useSpecialDayTooltips;
      var settingsColor = "";
      let segmentcolor = new Array(8);
      for (var i = 0; i < 8; i++) {
        segmentcolor[i] = ["", true];
      }
      var fontcolor;
      var iconPath;
      var iconColor;
      var iconAnimated;
      var birthdayIconPath;
      var birthdayIconColor;
      var j;
      var max = 0;
      let nextSegment = 0;
      let tooltipTexts = [];
      let isInactive = false;
      let isBirthday = false;
      let reminderIds = [];
      let reminderStartEnd;
      let reminderPrev;
      let reminderNext;
      let age = 0;

      workdays++;

      // Highlight this day?
      const highlight = currentState?.approvementId === highlightApprovement;

      // Normal days
      for (j = 0; j < 8; j++) {
        segmentcolor[j][0] = workdayColor;
      }

      // Reminder?
      if (props.userid === props.loggedUserId) {
        reminderIds = showRemindersOnlyFuture
          ? props.reminders?.filter(
              (R) =>
                currentMoment.isBetween(
                  R.startDateTime,
                  R.endDateTime,
                  "day",
                  "[]"
                ) && moment(R.endDateTime).isSameOrAfter(today, "day")
            )
          : props.reminders?.filter((R) =>
              currentMoment.isBetween(
                R.startDateTime,
                R.endDateTime,
                "day",
                "[]"
              )
            );
        reminderStartEnd = reminderIds.find(
          (R) =>
            currentMoment.isSame(R.startDateTime, "day") ||
            currentMoment.isSame(R.endDateTime, "day")
        );
        reminderPrev = reminderIds.find((R) =>
          currentMoment.isBetween(R.startDateTime, R.endDateTime, "day", "(]")
        );
        reminderNext = reminderIds.find((R) =>
          currentMoment.isBetween(R.startDateTime, R.endDateTime, "day", "[)")
        );
      }

      // Weekend?
      if (
        (currentMoment.format("d") === "6" && !workOnSaturday) ||
        currentMoment.format("d") === "0"
      ) {
        workdayfactor = 0;
        for (j = 0; j < 8; j++) {
          segmentcolor[j][0] = weekendColor;
        }
        workdays--;
      }

      // Calendarweeks
      if (showCalendarweeks && currentMoment.format("d") === firstDayOfWeek) {
        week = currentMoment.format("w");
      }

      // is this day markable?
      isMarkable =
        props.loggedAdmin ||
        props.loggedAssistant ||
        currentMoment.isSameOrAfter(moment(), "day");

      if (staticState) {
        // School holidays
        if (
          staticState["holiday"] !== "" &&
          showHolidays &&
          (showHolidaysOnlyApprentices === false ||
            (showHolidaysOnlyApprentices && userIsApprentice))
        ) {
          max = schoolHolidaysShowAsBar ? 2 : 8;
          for (j = 0; j < max; j++) {
            segmentcolor[j][0] = schoolHolidaysBackColor;
          }
          nextSegment = max === 2 ? 2 : 0;
          fontcolor = schoolHolidaysFontColor;
          if (schoolHolidaysShowInTooltip) {
            tooltipTexts.push({
              text: staticState["holiday"],
              backColor: schoolHolidaysBackColor,
              fontColor: schoolHolidaysFontColor,
              personal: false,
            });
            showTooltip = true;
          }
        }

        // Special days
        if (
          staticState.isRemarkableDay &&
          staticState.useRemarkableDay &&
          showRemarkableDays
        ) {
          max = specialDaysShowAsBar ? nextSegment + 2 : 8;
          for (j = nextSegment; j < max; j++) {
            segmentcolor[j][0] = specialDaysBackColor;
          }
          nextSegment = max === 8 ? nextSegment : max;
          if (specialDaysShowInTooltip) {
            tooltipTexts.push({
              text: staticState.remarkableDayName,
              backColor: specialDaysBackColor,
              fontColor: specialDaysTooltipFontColor,
              personal: false,
            });
            showTooltip = true;
          }
          specialDayKey = specialDaysIdentifier;
          fontcolor = specialDaysFontColor;
        }

        // Holidays
        if (
          staticState["isSpecialDay"] &&
          (staticState["useSpecialDay"] || staticState["alwaysUseThisDay"])
        ) {
          isHalfDay =
            parseFloat(staticState["dayFactor"]) > 0 &&
            parseFloat(staticState["dayFactor"]) < 1;
          if (staticState["isOffDay"] || isHalfDay) {
            if (
              (currentMoment.format("d") !== "6" || workOnSaturday) &&
              currentMoment.format("d") !== "0"
            ) {
              workdays -= isHalfDay ? 0.5 : 1;
            }
          }

          if (staticState["isOffDay"]) {
            max = holidaysShowAsBar ? nextSegment + (isHalfDay ? 1 : 2) : 8;
            for (j = nextSegment; j < max; j += isHalfDay ? 2 : 1) {
              segmentcolor[j][0] = holidaysBackColor;
            }
            nextSegment = max;
          }
          if (holidaysShowInTooltip) {
            tooltipTexts.push({
              text: staticState["specialDayName"],
              backColor: holidaysBackColor,
              fontColor: holidaysTooltipFontColor,
              personal: false,
            });
            showTooltip = true;
          }

          workdayfactor = isHalfDay ? 0.5 : 1;
          specialDayKey = holidaysIdentifier;
          fontcolor = holidaysFontColor;
        }
      }

      // Is user active?
      if (props.user) {
        isInactive =
          moment(props.user.startDate).isAfter(currentMoment, "day") ||
          moment(props.user.endDate).isBefore(moment(currentDate), "day");
      }

      if (currentState) {
        // personal days
        offDay = props.offDayTypes.find(
          (OT) => OT.id === currentState.offDayTypeId
        );
        offDayName = offDay?.names.find(
          (OTN) => OTN.language === language
        )?.offDayType;
        offDayKey = offDay?.names.find(
          (OTN) => OTN.language === language
        )?.offDayKey;

        if (currentState.isPersonOffDay === "1") {
          isOffDay = true;
          isPublished = currentState.published === "1";
        }

        // Birthday?
        if (
          props.loggedAdmin ||
          props.loggedAssistant ||
          props.loggedUserId === props.userid
        ) {
          if (currentState.birthday.showBirthday === "1") {
            isBirthday = true;

            birthdayIconPath = birthdayIcon;
            birthdayIconColor = birthdayFontColor;
            age =
              currentState.birthday.showAge === "1" ||
              props.loggedUserId === props.userid
                ? currentState.birthday.age
                : 0;
            tooltipTexts.push({
              text: age + ". " + t("Birthday"),
              backColor: "",
              fontColor: birthdayTooltipFontColor,
              personal: true,
            });
            showTooltip = true;
          }
        }
      }

      if (isToday) {
        iconPath =
          // eslint-disable-next-line
          icons.find(
            (I) =>
              parseInt(I.id) === parseInt(getDisplaySetting("Today", "iconId"))
          )?.path;
        iconAnimated = parseInt(getDisplaySetting("Today", "animate")) === 1;
        iconColor = getDisplaySetting("Today", "fontColor");
      }

      // absence day
      if (isOffDay && (isPublished || props.loggedUserId === props.userid)) {
        if (nextSegment === 8) {
          nextSegment = 0;
        }
        var stateBackColor = getDisplaySetting(
          currentState.offDayTypeId,
          "backgroundColor"
        );
        var stateBackFactor1 = getDisplaySetting(
          currentState.offDayTypeId,
          "backgroundFactor1"
        );
        var stateBackFactor2 = getDisplaySetting(
          currentState.offDayTypeId,
          "backgroundFactor2"
        );
        var stateFontColor = getDisplaySetting(
          currentState.offDayTypeId,
          "fontColor"
        );
        var stateFontFactor = getDisplaySetting(
          currentState.offDayTypeId,
          "fontFactor"
        );
        var stateTooltipFontColor = getDisplaySetting(
          currentState.offDayTypeId,
          "tooltipFontColor"
        );
        switch (currentState.state) {
          case "1":
            offDayName +=
              " (" +
              t("planned") +
              ")" +
              (!isPublished ? " " + t("not published") : "");
            settingsColor = hexToRGB(stateBackColor, stateBackFactor2);
            break;
          case "2":
            offDayName += " (" + t("requested") + ")";
            settingsColor = hexToRGB(stateBackColor, stateBackFactor1);
            break;
          case "3":
            offDayName += " (" + t("approved") + ")";
            settingsColor = stateBackColor;
            break;
          case "4":
            offDayName += " (" + t("registered") + ")";
            settingsColor = stateBackColor;
            break;
          default:
        }

        segmentcolor[nextSegment][0] = settingsColor;
        segmentcolor[nextSegment][1] = isPublished;
        if (!isHalfDay) {
          segmentcolor[nextSegment + 1][0] = settingsColor;
          segmentcolor[nextSegment + 1][1] = isPublished;
        }

        fontcolor = hexToRGB(stateFontColor, stateFontFactor);

        specialDayKey = offDayKey;
        if (isHalfDay && specialDayKey?.slice(0, 1) !== "½") {
          specialDayKey = "½" + specialDayKey;
        }
        tooltipTexts.push({
          text: offDayName,
          additionalText: currentState.additionalText,
          backColor: stateBackColor,
          fontColor: stateTooltipFontColor,
          personal: true,
        });
        showTooltip = true;
      }

      monthdays.push(
        <CalendarDayFrame
          id={key + 30 + modeFactor}
          key={modeKey + key}
          date={currentDate}
          username={props.user?.listName}
          ismarkable={isMarkable}
          today={isToday}
          todayIcon={iconPath}
          todayAnimated={iconAnimated}
          tooltipTexts={tooltipTexts}
          specialdaykey={specialDayKey}
          textColor={fontcolor}
          segmentColor={segmentcolor}
          markercolor={iconColor}
          workdayfactor={workdayfactor}
          showtooltip={showTooltip}
          onMouseDown={(id) => handleMouseDown(id)}
          onMouseUp={(id) => handleMouseUp(id)}
          onMouseEnter={(id) => handleMouseEnter(id)}
          onMouseMove={(id) => handleMouseMove(id)}
          onResetSelection={clearSelection}
          onHover={(reminder, date) => handleHover(reminder, date)}
          isbirthday={isBirthday}
          birthdayIcon={birthdayIconPath}
          birthdayColor={birthdayIconColor}
          published={isPublished}
          week={week}
          inactive={isInactive}
          highlight={highlight}
          reminders={reminderIds}
          reminderStartEnd={reminderStartEnd}
          reminderPrev={reminderPrev}
          reminderNext={reminderNext}
        />
      );
      currentMoment.add(1, "days");
    }
    days.push(
      <div className={"calendar-row " + modeKey} key={"R" + modeKey + month}>
        <div className={classes.monthname} key={"M" + modeKey + month}>
          <span className={isCurrentMonth ? classes.currentMonth : ""}>
            {monthName}
          </span>
        </div>
        <div className={classes.workdays} key={"WD" + modeKey + month}>
          <span className={isCurrentMonth ? classes.currentMonth : ""}>
            {workdays}
          </span>
        </div>
        {monthdays}
      </div>
    );
  };

  const cells = () => {
    const year = parseInt(props.displayyear);
    monthLine(11, year, "prev");
    for (let month = 0; month < 12; month++) {
      monthLine(month, year, "");
    }
    monthLine(0, year, "next");
    return (
      <>
        {days}
        {props.editMode && <SelectionForm />}
      </>
    );
  };

  useEffect(() => {
    clearSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>{cells()}</div>
      {showReminderDate && <RemindersDialog />}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(YearCalDays);
