import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  required: {
    borderRadius: "4px",
    boxShadow: "inset 0 0 5px " + theme.palette.secondary.main,
  },
}));

const SomuraSelectField = (props) => {
  const classes = useStyles();
  const items = props.options?.map((O, index) => (
    <MenuItem key={index} value={O.value}>
      {O.label}
    </MenuItem>
  ));
  return (
    <TextField
      select
      {...props}
      className={props.required ? classes.required : null}
    >
      {items}
    </TextField>
  );
};

export default SomuraSelectField;
