import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/de";
import {languages} from "Constants"
import SomuraTooltip from "Components/SomuraTooltip";
import makeStyles from '@mui/styles/makeStyles';
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    width: "100%",
    marginLeft: "0",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  IconButton: {
    width: "4rem",
    right: 0,
  },
  language: {
    fontSize: "1rem",
  },
  avatar: {
    color: "white",
  },
  grey: {
    backgroundColor: "grey",
  },
  img: {
    height: "30px",
    gridColumn: 1,
  },
  userName: {
    fontSize: "1.5rem",
    color: theme.palette.text.userMenuTitle,
  },
}));

const mapStateToProps = (state) => {
  const loggedUserId = state.loggeduser.LoggedUser.id;
  const loggedUser = state.persons.Persons.find((P) => P.id === loggedUserId);
  // const languages = state.languages.Languages;
  return {
    loginState: state.loggeduser.LoginState,
    loggedUser,
    // languages,
  };
};

const LanguageMenu = ({ loginState, loggedUser,  ...props }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [anchorE2, setAnchorE2] = useState(null);

  const openUser = Boolean(anchorE2);

  const handleLanguageMenu = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const language = languages.find(
    (L) => L.languageName === window.localStorage.getItem("lang")
  );

  const setNewLanguage = (language) => {
    i18n.changeLanguage(language);
    moment.locale(language.substr(0, 2));
    window.localStorage.setItem("lang", language);
    window.localStorage.setItem(
      "langShort",
      languages.find((L) => L.languageName === language).languageMenuName
    );
    setAnchorE2(null);
  };

  const handleClose = () => {
    setAnchorE2(null);
  };

  return (
    <Fragment>
      {language && (
        <>
          <SomuraTooltip
            title={t("Language") + ": " + language.languageLongName}
            placement="left"
          >
            <IconButton
              className={classes.language}
              edge="end"
              aria-label="language"
              aria-controls="languagemenu"
              aria-haspopup="true"
              onClick={handleLanguageMenu}
              color="inherit"
              size="large">
              {language.languageMenuName}
            </IconButton>
          </SomuraTooltip>
          <Menu
            id="languagemenu"
            anchorEl={anchorE2}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openUser}
            onClose={handleClose}
          >
            {languages.map((L) => (
              <MenuItem
                key={L.languageName}
                disabled={L.languageName === language.languageName}
                onClick={() => setNewLanguage(L.languageName)}
              >
                {L.languageLongName}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, null)(LanguageMenu);
