import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setSelectedStateId,
  createArticleState,
  updateArticleState,
} from "redux/index";

import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import makeStyles from "@mui/styles/makeStyles";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
}));

const mapStateToProps = (state) => {
  const states = state.articles.States;
  const selectedId = state.articles.selectedStateId;
  return { states, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedId: (id) => dispatch(setSelectedStateId(id)),
    create: (values) => dispatch(createArticleState(values)),
    update: (values) => dispatch(updateArticleState(values)),
  };
};

const StatesForm = ({
  states,
  selectedId,
  setSelectedId,
  create,
  update,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: "0",
    status: "",
  };
  const [state, setState] = useState(emptyState);
  const formHeader =
    selectedId > 0
      ? t("Condition") + ": " + state.status
      : t("New condition");

  const handleClose = () => {
    setSelectedId(-1);
  };

  const handleSubmit = () => {
    if (selectedId === 0) {
      // Create new location
      create(state);
    } else {
      // Update location
      update(state);
    }
    handleClose();
  };

  const handleDelete = () => {
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    if (selectedId > 0) {
      setState(states.find((S) => S.id === selectedId));
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <SomuraTextField
              label={t("Condition")}
              type="text"
              name="status"
              value={state.status}
              maxlength="50"
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton onClick={handleCancel} />
        {selectedId > 0 && (
          <DeleteButton onClick={handleDelete} disabled={state.neverDelete} />
        )}
        <SaveButton onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StatesForm);
