import React, { useState } from "react";
import { displayDate, stringToHslColor } from "Components/Utilities";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setRead } from "redux/index";

import SomuraTooltip from "Components/SomuraTooltip";
import { ItemTypes } from "Constants";
import { useDrag } from "react-dnd";

import makeStyles from "@mui/styles/makeStyles";
import { store } from "redux/store";
import MessageMenu from "./MessageMenu";
import Avatar from "@mui/material/Avatar";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "1.3rem 1.3rem 1.3rem",
    gridTemplateColumns: "10px 1fr 6fr 6px 2fr 1fr",
    "&:hover $delete": {
      display: "block",
    },
  },
  unread: {
    gridColumn: "1",
    gridRow: "1 / 4",
    width: "6px",
    height: "100%",
    backgroundColor: theme.palette.text.screenTitle,
  },
  messageTitle: {
    fontSize: "1rem",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    gridRow: "2",
    gridColumn: "3 / 4",
  },
  unreadTitle: {
    color: theme.palette.text.screenTitle,
  },
  messageTime: {
    gridRow: "2",
    gridColumn: "5 / 7",
    textAlign: "right",
    paddingRight: "4px",
    fontSize: "0.8rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  messageSender: {
    fontSize: "0.8rem",
    fontWeight: "100",
    gridRow: "1",
    gridColumn: "3",
    overflow: "hidden",
    whiteSpace: "nowrap",
    alignSelf: "end",
  },
  messageText: {
    fontSize: "0.8rem",
    fontWeight: "100",
    fontStyle: "italic",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: "4px",
    gridRow: "3",
    gridColumn: "3 / 7",
  },
  avatarText: {
    fontSize: "1rem",
  },
  avatar: {
    alignSelf: "center",
    width: "2rem",
    height: "2rem",
    marginRight: "6px",
    gridRow: "1 / 4",
    gridColumn: "2",
    color: "white",
  },
  delete: {
    gridRow: "1",
    gridColumn: "6",
    display: "none",
    "&:hover": {
      color: theme.palette.red,
    },
  },
  important: {
    color: theme.palette.orange,
    gridRow: "1",
    gridColumn: "5",
  },
  red: {
    backgroundColor: theme.palette.red,
  },
  grey: {
    backgroundColor: theme.palette.text.disabled,
  },
}));

const mapStateToProps = (state) => {
  return {
    persons: state.persons.Persons,
    logged: state.loggeduser.LoggedUser.id,
    messages: state.messages.Messages,
    selectedMessageId: state.messages.selectedMessageId,
    selectedMessageIds: state.messages.selectedMessageIds,
    firstSelectedMessageId: state.messages.firstSelectedMessageId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRead: (values) => dispatch(setRead(values)),
  };
};

const FolderMessage = ({
  persons,
  logged,
  messages,
  messageId,
  selectedMessageId,
  selectedMessageIds,
  firstSelectedMessageId,
  folderId,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [contextMenu, setContextMenu] = useState(null);
  const showMessage = messages.find((M) => M.id === messageId);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.MESSAGE,
      messageId: messageId,
      dragMessage: showMessage,
    },
    type: ItemTypes.MESSAGE,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  if (showMessage) {
    const messageRecipient =
      showMessage && showMessage.recipients
        ? showMessage.recipients.find((R) => R.recipientId === logged)
        : {};

    const messageText =
      showMessage.message.length > 0
        ? showMessage.message.replace(/<br \/>/g, " ")
        : "";
    const sender = persons.find(
      (P) => P.id === (showMessage ? showMessage.fromUserId : 0)
    );
    const recipient = persons.find(
      (P) =>
        P.id ===
        (showMessage.recipients && showMessage.recipients.length > 0
          ? showMessage.recipients[0].recipientId
          : 0)
    );
    let displayName;
    let displayShort;
    let displayClass = classes.avatar;
    let displayColor;
    if (
      showMessage.fromUserId === logged &&
      (showMessage.senderFolderId === "-4" ||
        showMessage.senderFolderId === "-2")
    ) {
      if (recipient && parseInt(showMessage.toMode) > 0) {
        displayName =
          t("To") + ": " + recipient.lastName + ", " + recipient.firstName;
        displayShort = recipient.initials;
        displayClass = classes.avatar;
        displayColor = stringToHslColor(displayShort);
      } else {
        switch (showMessage.toMode) {
          case "-1":
            displayName = t("To") + ": " + t("Administrators");
            displayShort = "AD";
            break;
          case "-2":
            displayName = t("To") + ": " + t("Assistants");
            displayShort = "AS";
            break;
          case "-3":
            displayName =
              t("To") + ": " + t("Administrators") + " + " + t("Assistants");
            displayShort = "AA";
            break;
          default:
            if (recipient) {
              displayName =
                t("To") +
                ": " +
                recipient.lastName +
                ", " +
                recipient.firstName;
              displayShort = recipient.initials;
              displayClass = classes.avatar;
              displayColor = stringToHslColor(displayShort);
            } else {
              displayName = "";
              displayShort = "?";
            }
        }
        if (showMessage.senderFolderId === "-4") {
          displayName = "[" + t("Draft") + "] " + displayName;
        }
        displayClass = classes.avatar + " " + classes.grey;
      }
    }
    if (
      showMessage.recipients &&
      showMessage.recipients.find(
        (R) => R.recipientId === logged && parseInt(R.recipientFolderId) >= -1
      )
    ) {
      if (sender) {
        displayName =
          t("From") + ": " + sender.lastName + ", " + sender.firstName;
        displayShort = sender.initials;
        displayClass = classes.avatar;
        displayColor = stringToHslColor(displayShort);
      } else {
        displayName = t("From") + ": Somura System";
        displayShort = "S";
        displayClass = classes.avatar + " " + classes.red;
      }
    }

    const handleClose = () => {
      setContextMenu(null);
    };

    const handleClick = (event) => {
      const shift = event.shiftKey;
      const ctrl = event.ctrlKey;
      if (selectedMessageId !== "0") {
        if (
          messages
            .find((M) => M.id === selectedMessageId)
            ?.recipients?.find((R) => R.recipientId === logged)?.readTime ===
          null
        ) {
          props.setRead({
            messageId: selectedMessageId,
            recipientId: logged,
          });
        }
      }
      // Update array of selected messages
      var startPos, clickPos, i, checkPos
      if (selectedMessageIds.find((SM) => SM.id === messageId)?.selected) {
        // Message is already selected
        if (ctrl) {
          store.dispatch({ type: "UNSELECT_MESSAGE_ID", payload: messageId });
        } else if (shift) {
          startPos = selectedMessageIds.find(
            (SM) => SM.id === firstSelectedMessageId
          ).position;
          clickPos = selectedMessageIds.find(
            (SM) => SM.id === messageId
          ).position;
          for (i = 0; i < selectedMessageIds.length; i++) {
            checkPos = selectedMessageIds[i].position;
            switch (true) {
              case checkPos < clickPos && checkPos < startPos:
                store.dispatch({
                  type: "UNSELECT_MESSAGE_ID",
                  payload: selectedMessageIds[i].id,
                });
                break;
              case checkPos >= clickPos && checkPos <= startPos:
                store.dispatch({
                  type: "SELECT_MESSAGE_ID",
                  payload: selectedMessageIds[i].id,
                });
                break;
              case checkPos >= startPos && checkPos <= clickPos:
                store.dispatch({
                  type: "SELECT_MESSAGE_ID",
                  payload: selectedMessageIds[i].id,
                });
                break;
              case checkPos > clickPos && checkPos > startPos:
                store.dispatch({
                  type: "UNSELECT_MESSAGE_ID",
                  payload: selectedMessageIds[i].id,
                });
                break;

              default:
            }
          }
        } else {
          store.dispatch({ type: "UNSELECT_ALL_MESSAGE_IDS" });
        }
      } else {
        if (ctrl) {
          store.dispatch({ type: "SELECT_MESSAGE_ID", payload: messageId });
        } else if (shift) {
          startPos = selectedMessageIds.find(
            (SM) => SM.id === firstSelectedMessageId
          ).position;
          clickPos = selectedMessageIds.find(
            (SM) => SM.id === messageId
          ).position;
          for (i = 0; i < selectedMessageIds.length; i++) {
            checkPos = selectedMessageIds[i].position;
            switch (true) {
              case checkPos < clickPos && checkPos < startPos:
                store.dispatch({
                  type: "UNSELECT_MESSAGE_ID",
                  payload: selectedMessageIds[i].id,
                });
                break;
              case checkPos >= clickPos && checkPos <= startPos:
                store.dispatch({
                  type: "SELECT_MESSAGE_ID",
                  payload: selectedMessageIds[i].id,
                });
                break;
              case checkPos >= startPos && checkPos <= clickPos:
                store.dispatch({
                  type: "SELECT_MESSAGE_ID",
                  payload: selectedMessageIds[i].id,
                });
                break;
              case checkPos > clickPos && checkPos > startPos:
                store.dispatch({
                  type: "UNSELECT_MESSAGE_ID",
                  payload: selectedMessageIds[i].id,
                });
                break;

              default:
            }
          }
        } else {
          store.dispatch({ type: "UNSELECT_ALL_MESSAGE_IDS" });
          store.dispatch({ type: "SELECT_MESSAGE_ID", payload: messageId });
          store.dispatch({ type: "SET_FIRST_MESSAGE_ID", payload: messageId });
        }
      }

      store.dispatch({
        type: "SET_SELECTED_MESSAGE_ID",
        payload: messageId === selectedMessageId ? "0" : messageId,
      });
    };

    const handleRightClick = (event) => {
      // setContextMenu(null)
      event.preventDefault();
      store.dispatch({
        type: "SET_SELECTED_MESSAGE_ID",
        payload: messageId,
      });
      store.dispatch({ type: "SELECT_MESSAGE_ID", payload: messageId });
      document.oncontextmenu = function () {
        return false;
      };

      setContextMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    };

    return (
      <>
        <div
          className={classes.root}
          onClick={handleClick}
          onContextMenu={handleRightClick}
          ref={drag}
          style={{
            opacity: isDragging ? 0.5 : 1,
          }}
        >
          {messageRecipient?.readTime === null && (
            <div className={classes.unread}></div>
          )}
          {displayName === "Somura System" ? (
            <Avatar className={displayClass}>
              <span className={classes.avatarText}>{displayShort}</span>
            </Avatar>
          ) : (
            <Avatar
              className={displayClass}
              style={{ backgroundColor: displayColor }}
            >
              <span className={classes.avatarText}>{displayShort}</span>
            </Avatar>
          )}
          <div className={classes.messageSender}>{displayName}</div>
          {((showMessage.allowAccess && !showMessage.allowAccessCompleted) ||
            (showMessage.allowNewPassword &&
              !showMessage.allowNewPasswordCompleted)) && (
            <SomuraTooltip title={t("Open request")} placement="top">
              <div className={classes.important}>
                <PriorityHighIcon />
              </div>
            </SomuraTooltip>
          )}
          <div
            className={classes.messageTitle + " " +
              (messageRecipient && messageRecipient.readTime === null
                ? classes.unreadTitle
                : "")
            }
          >
            {showMessage.title ? showMessage.title : t("(No subject)")}
          </div>
          <div className={classes.messageTime}>
            {displayDate(showMessage.creationTime)}
          </div>
          <div className={classes.messageText}>{messageText}</div>
        </div>
        <MessageMenu
          contextMenu={contextMenu}
          messageId={showMessage.id}
          folderId={folderId}
          open={contextMenu !== null}
          close={handleClose}
          readTime={
            showMessage.recipients?.find((R) => R.recipientId === logged)
              ?.readTime
          }
        />
      </>
    );
  } else {
    return <div></div>;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderMessage);
