import React from "react";
import Switch from "@mui/material/Switch";
import makeStyles from "@mui/styles/makeStyles";
import FormControlLabel from "@mui/material/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  bold: {
    "&>span": {
      fontStyle: "normal",
      color: theme.palette.text.main,
    },
  },
  normal: {
    "&>span": {
      fontStyle: "italic",
      color: theme.palette.text.disabled,
    },
  },
}));

const SomuraSwitchField = ({
  labelLeft,
  labelRight,
  name,
  disabled,
  value,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={props.checked ? classes.bold : classes.normal}
      label={props.label}
      labelPlacement="end"
      disabled={props.disabled}
      control={
        <Switch
          value={props.checked ? 'on' : 'off'}
          disabled={disabled}
          checked={props.checked}
          onChange={props.onChange}
          name={name}
          color="secondary"
        />
      }
    />
  );
};

export default SomuraSwitchField;
