import React from "react";
import Grid from "@mui/material/Grid";

const Test = () => {
  return (
    <div className="content">
      <Grid container spacing={2}>
        <Grid item xs={4}>

        </Grid>
      </Grid>
    </div>
  );
};

export default Test;
