import React from "react";
import { setEntitlementId } from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import SomuraDataGrid from "Components/SomuraDataGrid";

const mapStateToProps = (state) => {
  const entitlements =
    state.persons.Person.entitlements
  return { entitlements };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEntitlementId: (id) => dispatch(setEntitlementId(id)),
  };
};

function EntitlementsTable({ entitlements, setEntitlementId, ...props }) {
  const { t } = useTranslation();

  const nf = new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("From year"),
      headerClassName: "tableHeader",
      field: "year",
      flex: 0.5,
    },
    {
      headerName: t("Entitlement"),
      headerClassName: "tableHeader",
      field: "numDays",
      flex: 0.5,
    },
  ];

  const rows = entitlements.map((E, index) => ({
    id: E.id,
    year: E.year,
    numDays: nf.format(E.numDays),
  }));

  const handleRowClick = (params) => {
    setEntitlementId(params);
  };

  return (
    <SomuraDataGrid
      tableHeight={"calc(100vh - 420px)"}
      rows={rows}
      columns={columns}
      defaultSortModel={[
        {
          field: "year",
          sort: "desc",
        },
      ]}
      onRowClick={(params) => handleRowClick(params)}
      csvFileName={t("Entitlements")}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitlementsTable);
