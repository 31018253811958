import {
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_MINIMAL_REQUEST,
  FETCH_DEPARTMENTS_MINIMAL_SUCCESS,
  FETCH_DEPARTMENT,
  ADD_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  DEPARTMENTS_FAILURE,
  RESET_DEPARTMENT,
  NEW_DEPARTMENT,
  SET_DEPARTMENT_ID,
} from 'actions/types'

import {
  departments,
  departmentsMinimal, 
  departmentsAdd,
  departmentsUpdate,
  departmentsDelete,
} from "../api";
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";

const departmentsFailure = (err) => {
  return {
    type: DEPARTMENTS_FAILURE,
    payload: err
  }
}

const fetchDepartmentsRequest = () => {
  return {
    type: FETCH_DEPARTMENTS_REQUEST
  }
}

const fetchDepartmentsSuccess = (Departments) => {
  return {
    type: FETCH_DEPARTMENTS_SUCCESS,
    payload: Departments
  }
}

export const fetchDepartments = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchDepartmentsRequest())
    try {
      const departmentsJSONData = await dispatch(departments())
      await dispatch(fetchDepartmentsSuccess(departmentsJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(departmentsFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

const fetchDepartmentsMinimalRequest = () => {
  return {
    type: FETCH_DEPARTMENTS_MINIMAL_REQUEST
  }
}

const fetchDepartmentsMinimalSuccess = (Departments) => {
  return {
    type: FETCH_DEPARTMENTS_MINIMAL_SUCCESS,
    payload: Departments
  }
}

export const fetchDepartmentsMinimal = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchDepartmentsMinimalRequest())
    try {
      const departmentsJSONData = await dispatch(departmentsMinimal())
      await dispatch(fetchDepartmentsMinimalSuccess(departmentsJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(departmentsFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

export const fetchDepartment = (id) => {
  return {
    type: FETCH_DEPARTMENT,
    payload: id
  }
}

const addDepartmentData = (values) => {
  return {
    type: ADD_DEPARTMENT,
    payload: values
  }
}

export const addDepartment = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(departmentsAdd(values))
      await dispatch(addDepartmentData({ ...values, id: JSONData }));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(departmentsFailure(err))
      await dispatch(dataFailure())
    }
  }
}

const updateDepartmentData = (values) => {
  return {
    type: UPDATE_DEPARTMENT,
    payload: values
  }
}

export const updateDepartment = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(departmentsUpdate(values));
      await dispatch(updateDepartmentData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update department data', err); // eslint-disable-line no-console
      await dispatch(departmentsFailure(err));
      await dispatch(dataFailure())
    }
  }
}

const deleteDepartmentData = (id) => {
  return {
    type: DELETE_DEPARTMENT,
    payload: id
  }
}

export const deleteDepartment = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(departmentsDelete(id));
      await dispatch(deleteDepartmentData(id))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to delete department data', err); // eslint-disable-line no-console
      await dispatch(departmentsFailure(err));
      await dispatch(dataFailure())
    }
  }
}

export const resetDepartment = () => {
  return {
    type: RESET_DEPARTMENT
  }
}

export const newDepartment = () => {
  return {
    type: NEW_DEPARTMENT
  }
}

export const setDepartmentId = (id) => {
  return {
    type: SET_DEPARTMENT_ID,
    payload: id,
  };
};