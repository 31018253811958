import {
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPLOAD_LOGO,
  COMPANY_FAILURE
} from 'actions/types'

import { company, companyUpdate, logoUpload } from '../api'
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";

const companyFailure = (err) => {
  return {
    type: COMPANY_FAILURE,
    payload: err
  }
}

const fetchCompanyRequest = () => {
  return {
    type: FETCH_COMPANY_REQUEST
  }
}

const fetchCompanySuccess = (Company) => {
  return {
    type: FETCH_COMPANY_SUCCESS,
    payload: Company
  }
}

export const fetchCompany = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchCompanyRequest())
    try {
      const companyJSONData = await dispatch(company())
      await dispatch(fetchCompanySuccess(companyJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(companyFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

const updateCompanyData = (values) => {
  return {
    type: UPDATE_COMPANY,
    payload: values
  }
}

export const updateCompany = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(companyUpdate(values));
      await dispatch(updateCompanyData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update company data', err); // eslint-disable-line no-console
      await dispatch(companyFailure(err));
      await dispatch(dataFailure())
    }
  }
}

const uploadLogoData = (values) => {
  return {
    type: UPLOAD_LOGO,
    payload: values
  }
}

export const uploadLogo = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    try {
      const fileName = await dispatch(logoUpload(values));
      await dispatch(uploadLogoData(fileName))
      await dispatch(dataSuccess())
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(companyFailure(err));
      await dispatch(dataFailure())
      dispatch(dataEndLoading())
    }
  }
}

