import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import DeleteFolder from "./DeleteFolder";
import EmptyFolder from "./EmptyFolder";
import EmptyRecycleBin from "./EmptyRecycleBin";
import RenameFolder from "./RenameFolder";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const FolderMenu = ({ folderId, open, close, contextMenu, ...props }) => {
  const { t } = useTranslation();

  const [deleteFolder, setDeleteFolder] = useState(false);
  const [emptyFolder, setEmptyFolder] = useState(false);
  const [emptyBin, setEmptyBin] = useState(false);
  const [renameFolder, setRenameFolder] = useState(false);

  const handleClose = () => {
    close();
  };

  const handleDeleteFolder = () => {
    setDeleteFolder(true);
    close();
  };

  const handleEmptyFolder = () => {
    setEmptyFolder(true);
    close();
  };

  const handleEmptyBin = () => {
    setEmptyBin(true);
    close();
  };

  const handleRenameFolder = () => {
    setRenameFolder(true);
    close();
  };

  return (
    <>
      <div>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          onContextMenu={handleClose}
        >
          <MenuItem
            disabled={folderId === "-1" || folderId === "-4"}
            onClick={props.handleCreate(folderId)}
          >
            <ListItemIcon>
              <CreateNewFolderIcon />
            </ListItemIcon>
            {t("Create subfolder")}
          </MenuItem>
          <MenuItem disabled={props.systemFolder} onClick={handleRenameFolder}>
            <ListItemIcon>
              <DriveFileRenameOutlineIcon />
            </ListItemIcon>
            {t("Rename folder")}
          </MenuItem>
          <MenuItem
            disabled={
              props.systemFolder || props.numMessages > 0 || props.hasSubfolder
            }
            onClick={handleDeleteFolder}
          >
            <ListItemIcon>
              <FolderDeleteIcon />
            </ListItemIcon>
            {t("Delete folder")}
          </MenuItem>
          {folderId !== "-1" && props.numMessages > 0 && (
            <MenuItem onClick={handleEmptyFolder}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              {t("Empty folder")}
            </MenuItem>
          )}
          {folderId === "-1" && props.numMessages > 0 && (
            <MenuItem onClick={handleEmptyBin}>
              <ListItemIcon>
                <DeleteForeverIcon />
              </ListItemIcon>
              {t("Empty recycle bin")}
            </MenuItem>
          )}
        </Menu>
      </div>
      {deleteFolder && <DeleteFolder close={() => setDeleteFolder(false)} />}
      {emptyFolder && <EmptyFolder folderId={folderId} close={() => setEmptyFolder(false)} />}
      {emptyBin && <EmptyRecycleBin close={() => setEmptyBin(false)} />}
      {renameFolder && <RenameFolder close={() => setRenameFolder(false)} />}
    </>
  );
};

export default FolderMenu;
