import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getGlobalSetting } from "Components/Utilities";
import { setGlobalSettings } from "redux/index";
import Grid from "@mui/material/Grid";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  label: {
    fontSize: "1.2rem",
    height: "2.4rem",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.screenTitle,
  },
}));

const mapStateToProps = (state) => {
  const movementTypes = state.articles.MovementTypes;
  const locations = state.articles.Locations;
  return { movementTypes, locations };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalSettings: (values) => dispatch(setGlobalSettings(values)),
  };
};

const SettingsForm = ({ movementTypes, locations, setGlobalSettings }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formHeader = t("Settings");

  const [state, setState] = useState({
    useInventoryNumbers: parseInt(getGlobalSetting("articles", "useInventoryNumbers")) === 1,
    moveToInventory: getGlobalSetting("articles", "moveToInventory"),
    moveToInventoryLocation: getGlobalSetting(
      "articles",
      "moveToInventoryLocation"
    ),
    moveToUser: getGlobalSetting("articles", "moveToUser"),
    moveToUserLocation: getGlobalSetting("articles", "moveToUserLocation"),
    moveFromUser: getGlobalSetting("articles", "moveFromUser"),
    moveFromUserLocation: getGlobalSetting("articles", "moveFromUserLocation"),
  });

  const movementTypeOptions = movementTypes.map((MT) => ({
    label: MT.movementTypeName,
    value: MT.id,
  }));

  const locationOptions = locations.map((L) => ({
    label: L.locationName,
    value: L.id,
  }));

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: "articles",
      key: name,
      value: value,
    });
  };

    const handleSwitchChange = (event) => {
      const name = event.target.name;
      const value = event.target.checked;
      setState({ ...state, [name]: value });
      setGlobalSettings({
        component: "articles",
        key: name,
        value: value ? "1" : "0",
      });
    };

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="useInventoryNumbers"
              checked={state.useInventoryNumbers}
              onChange={handleSwitchChange}
              label={t("Use inventory numbers")}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <div className={classes.label}>
              {t("Move article to inventory")}
            </div>
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="moveToInventory"
              label={t("Movement type")}
              options={movementTypeOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.moveToInventory}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="moveToInventoryLocation"
              label={t("Location")}
              options={locationOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.moveToInventoryLocation}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.label}>{t("Provide article to user")}</div>
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="moveToUser"
              label={t("Movement type")}
              options={movementTypeOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.moveToUser}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="moveToUserLocation"
              label={t("Location")}
              options={locationOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.moveToUserLocation}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.label}>{t("Return article from user")}</div>
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="moveFromUser"
              label={t("Movement type")}
              options={movementTypeOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.moveFromUser}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="moveFromUserLocation"
              label={t("Location")}
              options={locationOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.moveFromUserLocation}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
      {/* <div className="formBottom">
        <CancelButton onClick={handleCancel} />
        <SaveButton onClick={handleSubmit} />
      </div> */}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);
