import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import {
  setSelectedModelId,
  createArticleModel,
  updateArticleModel,
  deleteArticleModel,
} from "redux/index";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.scrollBar,
  },
  dialogTitle: {
    width: "30rem",
    padding: "4px 10px",
    fontWeight: "200",
    color: theme.palette.text.screenTitle,
  },
  dialogText: {
    color: theme.palette.text.calendars,
  },
  dialogContent: {
    padding: "10px 10px 6px 10px",
    backgroundColor: theme.palette.dialog.content,
  },
  dialogActions: {
    padding: "0 8px",
  },
}));

const mapStateToProps = (state) => {
  const models = state.articles.ArticleModels;
  const types = state.articles.ArticleTypes;
  const selectedId = state.articles.selectedArticleModelId;
  const selectedTypeId = state.articles.selectedArticleTypeId
  return { models, types, selectedId, selectedTypeId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedId: (id) => dispatch(setSelectedModelId(id)),
    create: (values) => dispatch(createArticleModel(values)),
    update: (values) => dispatch(updateArticleModel(values)),
    remove: (values) => dispatch(deleteArticleModel(values)),
  };
};

const ModelDialog = ({
  models,
  types,
  selectedId,
  selectedTypeId,
  create,
  update,
  close,
  remove
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const emptyState = {
    id: "0",
    modelName: "",
    articleTypeId: selectedTypeId,
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);

  const modelSchema = Yup.object().shape({
    articleTypeId: Yup.number().min(1).required(t("Required")),
    modelName: Yup.string().required(t("Required")),
  });

  var typeOptions = types
    .filter((AT) => AT.id !== selectedId && !AT.notSelectable)
    .map((AT) => ({
      label: AT.articleTypeName,
      value: AT.id,
    }));
  typeOptions.unshift({ label: "", value: "0" });

  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    if (state.id === "0") {
      // Create new model
      create(state);
    } else {
      // Update model
      update(state);
    }
    handleClose();
  };

  const handleDelete = () => {
    remove({id: selectedId})
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({ ...state, [name]: value });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await modelSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (selectedId > 0) {
      setState(models.find((M) => M.id === selectedId));
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <div>
      <Dialog
        open={true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(event, reason);
          }
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {selectedId > 0
            ? t("Model") + ": " + state.modelName
            : t("New model")}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <div className={classes.dialogContent}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SomuraAutocomplete
                  name="articleTypeId"
                  label={t("Article type")}
                  options={typeOptions}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  value={state.articleTypeId}
                  disabled={parseInt(selectedId) < 1 && state.articleTypeId > 0}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <SomuraTextField
                  label={t("Model")}
                  type="text"
                  name="modelName"
                  value={state.modelName}
                  maxlength="50"
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CancelButton onClick={handleClose} />
          {selectedId > 0 && (
            <DeleteButton
              onClick={handleDelete}
              disabled={parseInt(state.numAssigned) > 0}
            />
          )}
          <SaveButton disabled={!valid} onClick={handleSubmit} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelDialog);
