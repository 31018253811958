import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { hexToRGB, getDisplaySetting } from "Components/Utilities";
import Button from "@mui/material/Button";

import makeStyles from "@mui/styles/makeStyles";
import SomuraTooltip from "Components/SomuraTooltip";

import CategoryIcon from "@mui/icons-material/Category";
import SendIcon from "@mui/icons-material/Send";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import PlanForm from "../../Components/PlanForm";
import RequestDirectForm from "./RequestDirectForm";
import ApproveDirectForm from "./ApproveDirectForm";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: "700px",
    fontSize: "1rem",
    padding: "4px",
  },
  selectionButton: {
    minWidth: "2rem",
    maxWidth: "2.5rem",
    height: "2rem",
    gridRow: "2",
    alignSelf: "center",
  },
}));

const mapStateToProps = (state) => {
  return {
    startDate: state.yearcalendar.selectedDays.startDate,
    endDate: state.yearcalendar.selectedDays.endDate,
    isApprover: state.loggeduser.LoggedUser.isapprover,
  };
};

const NonApprovement = ({
  startDate,
  endDate,
  isApprover,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectedDays = props.dates;

  const [openRequestDirectForm, setOpenRequestDirectForm] = useState(false);
  const [openApproveDirectForm, setOpenApproveDirectForm] = useState(false);
  const [openPlanForm, setOpenPlanForm] = useState(false);

  const backColor = getDisplaySetting("Working days", "backgroundColor");
  const frontColor = "#000000";

  const firstDay = selectedDays[0].date;
  const lastDay = selectedDays[selectedDays.length - 1].date;

  var workdays = 0;
  for (var i = 0; i < selectedDays.length; i++) {
    var checkDate = selectedDays[i];
    workdays += checkDate.workday;
  }
  let fullDayName = "";
  if (workdays > 0) {
    fullDayName =
      workdays +
      " " +
      (workdays === 1 ? t("working day") : t("working days")) +
      " (";
  }
  fullDayName +=
    props.numDays + " " + (props.numDays === 1 ? t("day") : t("days"));
  if (workdays > 0) {
    fullDayName += ")";
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          border: "4px solid " + backColor,
          display: "grid",
          gridTemplateColumns: "33.8rem 3rem 3rem 3rem",
          gridTemplateRows: "8px 2.4rem 8px",
        }}
      >
        <div
          style={{
            padding: "4px",
            backgroundColor: hexToRGB(backColor, 0.5),
            gridColumn: "1 / 5",
            gridRow: "1 / -1",
          }}
        ></div>
        <div
          style={{
            gridColumn: "1",
            gridRow: "2",
            color: frontColor,
            fontSize: "1rem",
            lineHeight: "1.2rem",
            opacity: "1",
            padding: "0 12px",
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <span>{moment(firstDay).format("dddd, LL")}</span>
            {selectedDays.length > 1 && (
              <span>{" bis " + moment(lastDay).format("dddd, LL")}</span>
            )}
          </div>
          {fullDayName}
        </div>
        {workdays > 0 && (
          <SomuraTooltip placement="top" title={t("Plan")}>
            <Button
              className={classes.selectionButton}
              id="btn-plan"
              style={{
                gridColumn: isApprover ? "2" : "3",
                backgroundColor: "#F1C40F",
                color: "white",
              }}
              onClick={() => {
                setOpenPlanForm(true);
              }}
              variant="contained"
            >
              <CategoryIcon />
            </Button>
          </SomuraTooltip>
        )}
        <SomuraTooltip placement="top" title={t("Request / register")}>
          <Button
            className={classes.selectionButton}
            id="btn-request"
            style={{
              gridColumn: isApprover ? "3" : "4",
              backgroundColor: "#9B59B6",
              color: "white",
            }}
            onClick={() => {
              setOpenRequestDirectForm(true);
            }}
            variant="contained"
          >
            <SendIcon />
          </Button>
        </SomuraTooltip>
        {isApprover && (
          <SomuraTooltip placement="top" title={t("Register approved absence")}>
            <Button
              className={classes.selectionButton}
              id="btn-request"
              style={{
                gridColumn: "4",
                backgroundColor: "#00897B",
                color: "white",
              }}
              onClick={() => {
                setOpenApproveDirectForm(true);
              }}
              variant="contained"
            >
              <VerifiedUserIcon />
            </Button>
          </SomuraTooltip>
        )}
      </div>
      {openPlanForm === true && (
        <PlanForm
          startDate={firstDay}
          endDate={lastDay}
          numWorkdays={workdays}
          numDays={selectedDays.length}
          dates={selectedDays}
          closePlanForm={() => {
            setOpenPlanForm(false);
          }}
        />
      )}
      {openRequestDirectForm === true && (
        <RequestDirectForm
          startDate={firstDay}
          endDate={lastDay}
          numWorkdays={workdays}
          numDays={selectedDays.length}
          dates={selectedDays}
          closeRequestDirectForm={() => {
            setOpenRequestDirectForm(false);
          }}
        />
      )}
      {openApproveDirectForm === true && (
        <ApproveDirectForm
          startDate={firstDay}
          endDate={lastDay}
          numWorkdays={workdays}
          numDays={selectedDays.length}
          dates={selectedDays}
          closeApproveDirectForm={() => {
            setOpenApproveDirectForm(false);
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, null)(NonApprovement);
