import React, { Fragment } from "react";
import { connect } from "react-redux";
import "../Calendars.scss";
import makeStyles from "@mui/styles/makeStyles";
import { getGlobalSetting, getDisplaySetting } from "Components/Utilities";
import { icons } from "Constants";
import MonthCalHeadline from "./MonthCalHeadline";
import CompanySumLine from "./CompanySumLine";
import DepartmentSumLine from "./DepartmentSumLine";
import UserLine from "./UserLine";

import SelectionForm from "./Components/SelectionForm";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "4px",
    width: "90vw",
  },
  monthHead: {
    display: "block",
    width: "calc(100% - 10px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0px",
      height: "0px",
    },
  },
  monthBody: {
    display: "block",
    width: "100%",
    maxHeight: "61vh",
    overflowY: "scroll",
  },
  monthFoot: {
    display: "block",
    width: "calc(100% - 10px)",
    height: "1.5rem",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0px",
      height: "0px",
    },
  },
  monthLine: {
    height: "3.5vh",
    display: "grid",
    gridTemplateColumns: "repeat(32, 1fr)",
  },
  departmentLine: {
    height: "1.5rem",
    display: "grid",
    gridTemplateColumns: "repeat(32, 1fr)",
  },
  staticLine: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(32, 1fr)",
    position: "absolute",
  },
  personName: {
    fontSize: "1rem",
    width: "15vw",
    gridColumn: "1",
    textAlign: "right",
    alignSelf: "center",
    padding: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  highlight: {
    fontWeight: "500",
    color: theme.palette.text.important,
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  departmentName: {
    fontSize: "0.8rem",
    backgroundColor: theme.palette.background.paperBottom,
    gridColumn: "1",
    alignSelf: "center",
    paddingLeft: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const mapStateToProps = (state) => {
  return {
    editMode: state.general.EditMode,
    loggedUser: state.loggeduser.LoggedUser,
    monthData: state.month.MonthStatic,
    monthSums: state.month.MonthSums,
    monthoffdays: state.month.MonthOffdays,
    settings: state.settings.PersonalSettings,
    departments: state.departments.DepartmentsMin,
    persons: state.persons.PersonsMin,
  };
};

const MonthCalRows = ({
  startDate,
  endDate,
  displaymonth,
  displaydepartment,
  loggedUser,
  monthData,
  monthSums,
  settings,
  monthoffdays,
  departments,
  persons,
  ...props
}) => {
  const classes = useStyles();
  const calSettings = settings.find(
    (GS) => GS.component === "calendars"
  )?.settings;
  const displayPersons = persons.filter(
    (P) =>
      P.startDate <= endDate && (P.endDate >= startDate || P.endDate === "")
  );

  // // Personal settings
  const useSpecialDayTooltips =
    parseInt(calSettings?.useSpecialDayTooltips) === 1;
  const showHolidays = parseInt(calSettings?.showHolidays) === 1;
  const showHolidaysOnlyApprentices =
    parseInt(calSettings?.showHolidaysOnlyApprentices) === 1;
  const showRemarkableDays = parseInt(calSettings?.showRemarkableDays) === 1;
  // Fetch settings
  const workOnSaturday =
    parseInt(getGlobalSetting("system", "workOnSaturday")) === 1;
  const workdayColor = getDisplaySetting("Working days", "backgroundColor");
  const weekendColor = getDisplaySetting("Weekend", "backgroundColor");
  const todayColor = getDisplaySetting("Today", "fontColor");

  const schoolHolidaysShowAsBar =
    parseInt(getDisplaySetting("School holidays", "showAsBar")) === 1;
  const schoolHolidaysBackColor = getDisplaySetting(
    "School holidays",
    "backgroundColor"
  );
  const schoolHolidaysFontColor = getDisplaySetting(
    "School holidays",
    "fontColor"
  );
  const schoolHolidaysShowInTooltip =
    parseInt(getDisplaySetting("School holidays", "showInTooltip")) === 1;
  const specialDaysShowAsBar =
    parseInt(getDisplaySetting("Special days", "showAsBar")) === 1;
  const specialDaysBackColor = getDisplaySetting(
    "Special days",
    "backgroundColor"
  );
  const specialDaysShowInTooltip =
    parseInt(getDisplaySetting("Special days", "showInTooltip")) === 1;
  const specialDaysTooltipFontColor = getDisplaySetting(
    "Special days",
    "tooltipFontColor"
  );
  const specialDaysIdentifier = getDisplaySetting("Special days", "identifier");
  const specialDaysFontColor = getDisplaySetting("Special days", "fontColor");
  const holidaysShowAsBar =
    parseInt(getDisplaySetting("Holidays", "showAsBar")) === 1;
  const holidaysBackColor = getDisplaySetting("Holidays", "backgroundColor");
  const holidaysShowInTooltip =
    parseInt(getDisplaySetting("Holidays", "showInTooltip")) === 1;
  const holidaysTooltipFontColor = getDisplaySetting(
    "Holidays",
    "tooltipFontColor"
  );
  const holidaysIdentifier = getDisplaySetting("Holidays", "identifier");
  const holidaysFontColor = getDisplaySetting("Holidays", "fontColor");
  const birthdayIcon = icons.find(
    (I) => parseInt(I.id) === parseInt(getDisplaySetting("Birthdays", "iconId"))
  )?.path;
  const birthdayFontColor = getDisplaySetting("Birthdays", "fontColor");
 
  return (
    <>
      <div className={classes.wrapper}>
        <table className={classes.daysTable}>
          <thead className={classes.monthHead}>
            <MonthCalHeadline
              workOnSaturday={workOnSaturday}
              workdayColor={workdayColor}
              weekendColor={weekendColor}
              todayColor={todayColor}
            />
          </thead>
          <tbody className={classes.monthBody}>
            <CompanySumLine workOnSaturday={workOnSaturday} />
            {departments
              .filter(
                (D) => D.id === displaydepartment || displaydepartment === "-1"
              )
              .map((D, index) => {
                return (
                  // (!loggedUser.showOwnDepartment ||
                  //   loggedUser.departmentId === D.id) && (
                  <Fragment key={"S" + D.id}>
                    <DepartmentSumLine
                      key={"D" + D.id}
                      departmentId={D.id}
                      departmentName={D.department}
                      workOnSaturday={workOnSaturday}
                    />
                    {displayPersons
                      .filter((P) => P.departmentId === D.id)
                      .map((P, index) => (
                        <UserLine
                          key={"U" + P.id}
                          startDate={startDate}
                          userId={P.id}
                          userIsApprentice={P.isapprentice}
                          workOnSaturday={workOnSaturday}
                          workdayColor={workdayColor}
                          weekendColor={weekendColor}
                          todayColor={todayColor}
                          useSpecialDayTooltips={useSpecialDayTooltips}
                          showSchoolHolidays={showHolidays}
                          showSchoolHolidaysOnlyApprentices={
                            showHolidaysOnlyApprentices
                          }
                          schoolHolidaysShowAsBar={schoolHolidaysShowAsBar}
                          schoolHolidaysBackColor={schoolHolidaysBackColor}
                          schoolHolidaysFontColor={schoolHolidaysFontColor}
                          schoolHolidaysShowInTooltip={
                            schoolHolidaysShowInTooltip
                          }
                          showSpecialDays={showRemarkableDays}
                          specialDaysShowAsBar={specialDaysShowAsBar}
                          specialDaysBackColor={specialDaysBackColor}
                          specialDaysShowInTooltip={specialDaysShowInTooltip}
                          specialDaysTooltipFontColor={
                            specialDaysTooltipFontColor
                          }
                          specialDaysIdentifier={specialDaysIdentifier}
                          specialDaysFontColor={specialDaysFontColor}
                          holidaysShowAsBar={holidaysShowAsBar}
                          holidaysBackColor={holidaysBackColor}
                          holidaysShowInTooltip={holidaysShowInTooltip}
                          holidaysTooltipFontColor={holidaysTooltipFontColor}
                          holidaysIdentifier={holidaysIdentifier}
                          holidaysFontColor={holidaysFontColor}
                          showRemarkableDays={showRemarkableDays}
                          birthdayIcon={birthdayIcon}
                          birthdayFontColor={birthdayFontColor}
                        />
                      ))}
                  </Fragment>
                  // )
                );
              })}
          </tbody>
        </table>
      </div>
      {props.editMode && <SelectionForm/>}
    </>
  );
};

export default connect(mapStateToProps, null)(MonthCalRows);
