import { connect } from "react-redux";
import Box from "@mui/material/Box";
import SumDayFrame from "./SumDayFrame";

const mapStateToProps = (state) => {
  return {
    monthSums: state.month.MonthSums,
  };
};

const DepartmentSumLine = ({
  workOnSaturday,
  monthSums,
  departmentId,
  departmentName,
}) => {
  return (
    <Box
      component="tr"
      sx={{
        height: "1.5rem",
        display: "grid",
        gridTemplateColumns: "repeat(32, 1fr)",
      }}
    >
      <Box
        component="td"
        key={"D" + departmentId}
        sx={{
          fontSize: "0.8rem",
          minHeight: "1.4rem",
          width: "15vw",
          backgroundColor: "background.paperBottom",
          gridColumn: "1",
          alignSelf: "center",
          paddingLeft: "4px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {departmentName}
      </Box>
      {monthSums.map((MS) => (
        <td key={MS.date}>
          <SumDayFrame
            numPersons={
              MS.weekday > 0 && (MS.weekday < 6 || workOnSaturday)
                ? MS.departmentNumPersons.find(
                    (DM) => DM.departmentId === departmentId
                  )?.numPersons
                : ""
            }
          />
        </td>
      ))}
    </Box>
  );
};

export default connect(mapStateToProps)(DepartmentSumLine);
