import React from "react";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import MessageButtonBar from "./MessageButtonBar";
import Message from "./Message";
import NewMessage from "./NewMessage";

const useStyles = makeStyles((theme) => ({
  messageFrameRoot: {
    height: "calc(100vh - 190px)",
    padding: "10px 1rem",
    backgroundColor: theme.palette.background.messageList,
  },
}));

const mapStateToProps = (state) => {
  return {
    messages: state.messages.Messages,
    messageId: state.messages.selectedMessageId,
    messageIds: state.messages.selectedMessageIds.filter(
      (SM) => SM.selected === true
    ),
  };
};

const MessageFrame = (props) => {
  const classes = useStyles();
  const showMessage = props.messages?.find((M) => M.id === props.messageId);

  return showMessage && props.messageIds.length === 1 ? (
    <Paper square elevation={0}>
      <div className={classes.messageFrameRoot}>
        <MessageButtonBar />
        {showMessage &&
          (showMessage.senderFolderId === "-5" ||
          showMessage.senderFolderId === "-4" ? (
            <NewMessage />
          ) : (
            <Message />
          ))}
      </div>
    </Paper>
  ) : (
    <></>
  );
};

export default connect(mapStateToProps, null)(MessageFrame);
