import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchArticles,
  fetchArticleTypes,
  fetchArticleModels,
  fetchMovementTypes,
  fetchLocations,
  fetchReasons,
  fetchStates,
} from "redux/index";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import ScreenTitle from "Components/ScreenTitle";
import DevicesIcon from "@mui/icons-material/Devices";

import ArticlesTable from "./ArticlesTable";
import TypesForm from "./TypesForm";
import MovementTypesTable from "./MovementTypesTable";
import LocationsTable from "./LocationsTable";
import ReasonsTable from "./ReasonsTable";
import StatesTable from "./StatesTable";
import SettingsForm from "./SettingsForm";

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticles: () => dispatch(fetchArticles()),
    fetchArticleTypes: () => dispatch(fetchArticleTypes()),
    fetchArticleModels: () => dispatch(fetchArticleModels()),
    fetchMovementTypes: () => dispatch(fetchMovementTypes()),
    fetchLocations: () => dispatch(fetchLocations()),
    fetchReasons: () => dispatch(fetchReasons()),
    fetchStates: () => dispatch(fetchStates()),
  };
};

function Articles({
  fetchArticles,
  fetchArticleTypes,
  fetchMovementTypes,
  fetchArticleModels,
  fetchLocations,
  fetchReasons,
  fetchStates,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchArticles();
    fetchArticleTypes();
    fetchArticleModels();
    fetchMovementTypes();
    fetchLocations();
    fetchReasons();
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <Paper elevation={8}>
        <ScreenTitle
          title={t("Settings: articles")}
          class="settings"
          icon={<DevicesIcon style={{ width: "1.8em", height: "1.8em" }} />}
        />
        <Tabs
          className="tabPanel"
          value={value}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={handleTabsChange}
        >
          <Tab label={t("Articles")} />
          <Tab label={t("Structure")} />
          <Tab label={t("Movement types")} />
          <Tab label={t("Locations")} />
          <Tab label={t("Reasons")} />
          <Tab label={t("Statuses")} />
          <Tab label={t("Settings")} />
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ArticlesTable />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TypesForm />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <MovementTypesTable />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <LocationsTable />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <ReasonsTable />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <StatesTable />
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          <SettingsForm />
        </TabPanel>
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
