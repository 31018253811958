import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedReasonId } from "redux/index";
import Collapse from "@mui/material/Collapse";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";
import SomuraDataGrid from "Components/SomuraDataGrid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ReasonsForm from "./ReasonsForm"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  const reasons = state.articles.Reasons;
  const selectedId = state.articles.selectedReasonId;
  return { reasons, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setId: (id) => dispatch(setSelectedReasonId(id)),
  };
};

function ReasonsTable({ reasons, selectedId, setId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Name"),
      headerClassName: "tableHeader",
      field: "reason",
      flex: 0.2,
    },
    {
      headerName: t("Handout"),
      headerClassName: "tableHeader",
      field: "isHandoutReason",
      renderCell: (params) => {
        return params.value ? <CheckIcon /> : "";
      },
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: t("Return"),
      headerClassName: "tableHeader",
      field: "isReturnReason",
      renderCell: (params) => {
        return params.value ? <CheckIcon /> : "";
      },
      filterable: false,
      flex: 0.2,
    },
  ];

  const rows = reasons?.map((R) => ({
    id: R.id,
    reason: R.reason,
    isHandoutReason: R.isHandoutReason,
    isReturnReason: R.isReturnReason
  }));

  const handleRowClick = (params) => {
    setId(params);
  };

  const handleCreate = () => {
    setId(0)
  }

  return (
    <>
      <Collapse in={selectedId < 0} timeout={"auto"}>
        <SomuraDataGrid
          tableHeight={"calc(100vh - 260px)"}
          rows={rows}
          columns={columns}
          defaultSortModel={[
            {
              field: "reason",
              sort: "asc",
            },
          ]}
          onRowClick={(params) => handleRowClick(params)}
          csvFileName={t("Reasons")}
        />
        <Fab
          variant="extended"
          color="primary"
          size="medium"
          className={classes.fab}
          onClick={handleCreate}
        >
          <AddIcon />
          {t("New reason")}
        </Fab>
      </Collapse>
      <Collapse in={selectedId >= 0} timeout={"auto"}>
        <ReasonsForm />
      </Collapse>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasonsTable);
