import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "redux/store";

import { getGlobalSetting } from "Components/Utilities";

import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SomuraTooltip from "Components/SomuraTooltip";
import makeStyles from "@mui/styles/makeStyles";
import ListItemIcon from "@mui/material/ListItemIcon";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import OffDaysIcon from "@mui/icons-material/PublicTwoTone";
import DevicesIcon from "@mui/icons-material/Devices";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

const useStyles = makeStyles((theme) => ({
  adminTitle: {
    fontSize: "1.5rem",
    color: theme.palette.text.adminMenuTitle,
  },
  menu: {
    marginTop: "-1rem",
  },
  iconButton: {
    width: "4rem",
  },
  img: {
    width: "24px",
    height: "20px",
    display: "flex",
  },
}));

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;
  return {
    loginState: state.loggeduser.LoginState,
    loggedUser,
  };
};

const SettingsMenu = ({ loggedUser, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const useTimeTrackModule =
    parseInt(getGlobalSetting("system", "useTimeTrackModule")) === 1;

  const [anchorEl, setAnchorEl] = useState(null);

  const openSettings = Boolean(anchorEl);

  const handleSettingsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <SomuraTooltip title={t("System settings")} placement="left">
        <IconButton
          className={classes.iconButton}
          edge="end"
          aria-label="settings"
          aria-controls="settingsmenu"
          aria-haspopup="true"
          onClick={handleSettingsMenu}
          color="inherit"
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      </SomuraTooltip>
      <Menu
        className={classes.menu}
        id="settingsmenu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSettings}
        onClose={handleClose}
      >
        <MenuItem disabled={true} className={classes.adminTitle}>
          {t("Settings")}
        </MenuItem>
        {(props.admin || props.assistant) && (
          <MenuItem
            name="CompanySettings"
            component={NavLink}
            onClick={handleClose}
            to={"/CompanySettings"}
          >
            <ListItemIcon>
              <BusinessTwoToneIcon />
            </ListItemIcon>
            {t("Company")}
          </MenuItem>
        )}
        {props.admin && (
          <MenuItem
            name="Addresses"
            component={NavLink}
            onClick={handleClose}
            to={"/Addresses"}
          >
            <ListItemIcon>
              <HomeWorkIcon />
            </ListItemIcon>
            {t("Addresses")}
          </MenuItem>
        )}
        <MenuItem
          name="CalendarSettings"
          component={NavLink}
          onClick={handleClose}
          to={"/CalendarSettings"}
        >
          <ListItemIcon>
            <CalendarTodayTwoToneIcon />
          </ListItemIcon>
          {t("Calendar")}
        </MenuItem>

        {(props.admin || props.assistant) && (
          <MenuItem
            name="OffDayTypes"
            component={NavLink}
            onClick={handleClose}
            to={"/OffDayTypes"}
          >
            <ListItemIcon>
              <OffDaysIcon className={classes.drawerIcon} />
            </ListItemIcon>
            {t("Absence types")}
          </MenuItem>
        )}
        <MenuItem
          name="ArticleSettings"
          component={NavLink}
          onClick={handleClose}
          to={"/ArticleSettings"}
        >
          <ListItemIcon>
            <DevicesIcon className={classes.drawerIcon} />
          </ListItemIcon>
          {t("Articles")}
        </MenuItem>
        {props.admin && useTimeTrackModule && (
          <MenuItem
            name="TimeTrackingSettings"
            component={NavLink}
            onClick={handleClose}
            to={"/TimeTrackingSettings"}
          >
            <ListItemIcon>
              <TimerOutlinedIcon className={classes.drawerIcon} />
            </ListItemIcon>
            {t("Time tracking")}
          </MenuItem>
        )}
        {props.admin && (
          <MenuItem
            name="GlobalSettings"
            component={NavLink}
            onClick={handleClose}
            to={"/GlobalSettings"}
          >
            <ListItemIcon>
              <img
                className={classes.img}
                alt="Logo"
                src="images/Somura-icon_2tone-light_512.svg"
              />
            </ListItemIcon>
            Somura
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

export default connect(mapStateToProps)(SettingsMenu);
