import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { setTime, endTime } from "redux/index";
import { useTranslation } from "react-i18next";
import { getPersonalSetting } from "Components/Utilities";

import SomuraTooltip from "Components/SomuraTooltip";

import IconButton from "@mui/material/IconButton";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import CommitOutlinedIcon from "@mui/icons-material/CommitOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { secondsToTime, SecondsFromTimeDiff } from "Components/Utilities";
import StartTimeDialog from "../MasterData/TimeTracking/StartTimeDialog";
import EndTimeDialog from "../MasterData/TimeTracking/EndTimeDialog";

const useStyles = makeStyles((theme) => ({
  timeFrame: {
    gridColumn: "2",
    width: "fit-content",
    height: "2.2rem",
    margin: "auto 5px auto 15px",
    padding: "10px 10px",
    borderRadius: "0.5rem",
    boxShadow: "inset 3px 3px 3px 1px rgba(0, 0, 0, 0.4)",
    display: "flex",
    alignItems: "center",
  },
  timeDisplay: {
    fontSize: "1.2rem",
    fontWeight: "400",
    whiteSpace: "nowrap",
  },
  iconButton: {
    width: "0.8rem",
    height: "0.8rem",
    color: "white",
  },
  iconButtonLeft: {
    marginRight: "12px",
    width: "0.8rem",
    height: "0.8rem",
    color: "white",
  },
  iconButtonRight: {
    marginLeft: "12px",
    width: "0.8rem",
    height: "0.8rem",
    color: "white",
  },
  placeHolder: {
    // margin: "0 6px",
    color: "grey",
  },
  dotWrapper: {
    width: "20px",
    height: "1rem",
    marginRight: "-16px",
  },
  dot: {
    width: "4px",
    height: "4px",
    borderRadius: "2px",
    marginTop: "1.3rem",
    transition: "1s",
  },
  work: {
    animation: "$MoveLeftRight 4s linear infinite",
  },
  break: {
    animation: "$MoveLeftRightSmall 4s linear infinite",
  },
  placeHolderRotating: {
    margin: "0 6px",
    transition: "1s",
    animation: "$rotating 30s steps(30, end) infinite",
  },
  "@keyframes rotating": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(180deg)",
    },
  },
  "@keyframes MoveUpDown": {
    "0%, 100%": {
      marginTop: "0px",
    },
    "50%": {
      marginTop: "1rem",
    },
  },
  "@keyframes MoveLeftRight": {
    "0%, 100%": {
      marginLeft: "-3.8rem",
    },
    "50%": {
      marginLeft: "1.2rem",
    },
  },
  "@keyframes MoveLeftRightSmall": {
    "0%, 100%": {
      marginLeft: "-2rem",
    },
    "50%": {
      marginLeft: "1.2rem",
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggeduser.LoggedUser,
    globalSettings: state.settings.GlobalSettings,
    personalSettings: state.settings.PersonalSettings,
    times: state.times.Times,
    startTime: state.times.startTime,
    endTime: state.times.endTime,
    openStartTime: state.times.openStartTime,
    openEndTime: state.times.openEndTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTime: (values) => dispatch(setTime(values)),
    setEndTime: (values) => dispatch(endTime(values)),
  };
};

const ShowTime = ({
  loggedUser,
  globalSettings,
  personalSettings,
  times,
  setTime,
  endTime,
  setEndTime,
  startTime,
  openStartTime,
  openEndTime,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const timeRef = useRef();

  let recordId = useRef(0);
  let workTimeRunning = useRef(false);
  let breakTimeRunning = useRef(false);
  let timeStart = useRef(0);
  let timeCurrent = useRef(0);
  let breakCurrent = useRef(0);
  let dateCurrent = useRef("");
  let noSeconds = useRef(
    parseInt(getPersonalSetting("timetracking", "noSeconds")) === 1
  );
  let blinkingColon = useRef(
    parseInt(getPersonalSetting("timetracking", "blinkingColon")) === 1
  );
  let autoStart = useRef(
    parseInt(getPersonalSetting("timetracking", "autoStart")) === 1
  );
  const [displayWorkTime, setDisplayWorkTime] = useState(
    noSeconds.current ? "00:00" : "00:00:00"
  );
  const [displayBreakTime, setDisplayBreakTime] = useState(
    noSeconds.current ? "00:00" : "00:00:00"
  );
  const [displayTimes, setDisplayTimes] = useState(true);

  const handleDisplayTimes = () => {
    setDisplayTimes(!displayTimes);
  };

  const startWorkTime = () => {
    // Play-Button pressed
    if (breakTimeRunning.current) {
      // Stop Break
      setEndTime({
        id: recordId.current,
        startTime: timeStart.current,
        endTime: moment().format("HH:mm:ss"),
        endFromMobile: false,
        wasAutoEnded: false,
        modifiedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
        modifiedBy: loggedUser.id,
      });
      breakTimeRunning.current = false;
    }
    // Start Work time
    setTime({
      userId: loggedUser.id,
      date: moment().format("YYYY-MM-DD"),
      startTime: moment().format("HH:mm:ss"),
      isWorktime: true,
      isBreak: false,
      startFromMobile: false,
      minutes: 0,
      seconds: 0,
      createdOn: moment().format("YYYY-MM-DD HH:mm:ss"),
      createdBy: loggedUser.id,
    });
    workTimeRunning.current = true;
  };

  const endWorkTime = () => {
    // Stop-Button pressed
    setEndTime({
      id: recordId.current,
      startTime: timeStart.current,
      endTime: moment().format("HH:mm:ss"),
      endFromMobile: false,
      wasAutoEnded: false,
      modifiedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
      modifiedBy: loggedUser?.id,
    });
  };

  const startBreak = () => {
    // Pause-Button pressed
    // Stop Work time
    setEndTime({
      id: recordId.current,
      startTime: timeStart.current,
      endTime: moment().format("HH:mm:ss"),
      endFromMobile: false,
      wasAutoEnded: false,
      modifiedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
      modifiedBy: loggedUser?.id,
    });
    workTimeRunning.current = false;
    // Start Break
    setTime({
      userId: loggedUser.id,
      date: moment().format("YYYY-MM-DD"),
      startTime: moment().format("HH:mm:ss"),
      isWorktime: false,
      isBreak: true,
      startFromMobile: false,
      minutes: 0,
      seconds: 0,
      createdOn: moment().format("YYYY-MM-DD HH:mm:ss"),
      createdBy: loggedUser?.id,
    });
    breakTimeRunning.current = true;
  };

  const setDisplayTime = () => {
    const currTime = moment().format("HH:mm:ss");
    if (!workTimeRunning.current && !breakTimeRunning.current) {
      setDisplayWorkTime(
        secondsToTime(
          timeCurrent.current,
          blinkingColon.current,
          noSeconds.current
        )
      );
      setDisplayBreakTime(
        secondsToTime(
          breakCurrent.current,
          blinkingColon.current,
          noSeconds.current
        )
      );
    }
    if (workTimeRunning.current) {
      setDisplayWorkTime(
        secondsToTime(
          timeCurrent.current +
            SecondsFromTimeDiff(timeStart.current, currTime),
          blinkingColon.current,
          noSeconds.current
        )
      );
      setDisplayBreakTime(
        secondsToTime(
          breakCurrent.current,
          blinkingColon.current,
          noSeconds.current
        )
      );
    }
    if (breakTimeRunning.current) {
      setDisplayBreakTime(
        secondsToTime(
          breakCurrent.current +
            SecondsFromTimeDiff(timeStart.current, currTime),
          blinkingColon.current,
          noSeconds.current
        )
      );
      setDisplayWorkTime(
        secondsToTime(
          timeCurrent.current,
          blinkingColon.current,
          noSeconds.current
        )
      );
    }
  };

  useEffect(() => {
    if (loggedUser.id > 0) {
      const todayTimes = times.filter(
        (T) => T.date === moment().format("YYYY-MM-DD") && !T.isAutoBreak
      );
      if (todayTimes.length > 0) {
        // Get latest set in store
        const maxID = Math.max(...todayTimes.map((T) => T.id));
        const currentRecord = times.find((T) => parseInt(T.id) === maxID);
        recordId.current = maxID;
        // Sum the seconds
        const sumWorkSeconds = todayTimes
          .filter((T) => T.isWorktime)
          .reduce((a, b) => a + parseInt(b.seconds), 0);
        const sumBreakSeconds = todayTimes
          .filter((T) => T.isBreak)
          .reduce((a, b) => a + parseInt(b.seconds), 0);
        timeCurrent.current = sumWorkSeconds;
        breakCurrent.current = sumBreakSeconds;
        dateCurrent.current = currentRecord.date;
        if (currentRecord?.endTime === null) {
          timeStart.current = currentRecord.startTime;
          if (currentRecord.isWorktime) {
            // worktime running
            workTimeRunning.current = true;
          } else {
            // breaktime running
            breakTimeRunning.current = true;
          }
        } else {
          setDisplayWorkTime(
            secondsToTime(
              timeCurrent.current,
              blinkingColon.current,
              noSeconds.current
            )
          );
          setDisplayBreakTime(
            secondsToTime(
              breakCurrent.current,
              blinkingColon.current,
              noSeconds.current
            )
          );
        }
      } else {
        // no time records up to now
        // timeStart.current = moment().format("HH:mm:ss");
        if (autoStart) {
          setTime({
            userId: loggedUser.id,
            date: moment().format("YYYY-MM-DD"),
            startTime: moment().format("HH:mm:ss"),
            isWorktime: true,
            isBreak: false,
            startFromMobile: false,
            minutes: 0,
            seconds: 0,
            createdOn: moment().format("YYYY-MM-DD HH:mm:ss"),
            createdBy: loggedUser.id,
          });
          workTimeRunning.current = true;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, autoStart]);

  useEffect(() => {
    if (personalSettings) {
      autoStart.current =
        parseInt(getPersonalSetting("timetracking", "autoStart")) === 1;
    }
  }, [personalSettings]);

  useEffect(() => {
    timeRef.current = setInterval(() => {
      setDisplayTime();
    }, 1000);
    return () => {
      clearInterval(timeRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.timeFrame}>
        {!workTimeRunning.current && (
          <SomuraTooltip title={t("Start work time")} placement="bottom">
            <IconButton
              className={classes.iconButtonLeft}
              onClick={startWorkTime}
            >
              <PlayCircleOutlinedIcon />
            </IconButton>
          </SomuraTooltip>
        )}

        {workTimeRunning.current && (
          <SomuraTooltip title={t("Start break")} placement="bottom">
            <IconButton className={classes.iconButtonLeft} onClick={startBreak}>
              <PauseCircleOutlineOutlinedIcon />
            </IconButton>
          </SomuraTooltip>
        )}

        {workTimeRunning.current && (
          <SomuraTooltip title={t("End work time")} placement="bottom">
            <IconButton
              className={classes.iconButtonLeft}
              onClick={endWorkTime}
            >
              <StopCircleOutlinedIcon />
            </IconButton>
          </SomuraTooltip>
        )}
        {displayTimes && (
          <SomuraTooltip title={t("Work time")} placement="bottom">
            <div
              className={classes.timeDisplay}
              style={{ color: workTimeRunning.current ? "#7cb342" : "grey" }}
            >
              {displayWorkTime}
            </div>
          </SomuraTooltip>
        )}
        {displayTimes &&
          (workTimeRunning.current || breakTimeRunning.current) && (
            <CommitOutlinedIcon
              className={classes.placeHolderRotating}
              style={{ color: workTimeRunning.current ? "#7cb342" : "#fb8c00" }}
            />
          )}
        {displayTimes &&
          !workTimeRunning.current &&
          !breakTimeRunning.current && (
            <CommitOutlinedIcon className={classes.placeHolder} />
          )}
        {!displayTimes && workTimeRunning.current && (
          <div className={classes.dotWrapper}>
            <div
              className={classes.dot + " " + classes.work}
              style={{
                backgroundColor: "#7cb342",
              }}
            ></div>
          </div>
        )}
        {!displayTimes && breakTimeRunning.current && (
          <div className={classes.dotWrapper}>
            <div
              className={classes.dot + " " + classes.break}
              style={{
                backgroundColor: "#fb8c00",
              }}
            ></div>
          </div>
        )}
        {displayTimes && (
          <SomuraTooltip title={t("Break time")} placement="bottom">
            <div
              className={classes.timeDisplay}
              style={{ color: breakTimeRunning.current ? "#fb8c00" : "grey" }}
            >
              {displayBreakTime}
            </div>
          </SomuraTooltip>
        )}
        {displayTimes && (
          <SomuraTooltip title={t("Hide times")} placement="bottom">
            <IconButton
              className={classes.iconButtonRight}
              style={{ color: "#360f8a" }}
              onClick={handleDisplayTimes}
            >
              <VisibilityOffOutlinedIcon />
            </IconButton>
          </SomuraTooltip>
        )}
        {!displayTimes && (
          <SomuraTooltip title={t("Show times")} placement="bottom">
            <IconButton
              className={classes.iconButton}
              style={{ color: "#360f8a" }}
              onClick={handleDisplayTimes}
            >
              <VisibilityOutlinedIcon />
            </IconButton>
          </SomuraTooltip>
        )}
      </div>
      {openStartTime && <StartTimeDialog />}
      {openEndTime && <EndTimeDialog />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowTime);
