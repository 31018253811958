import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  setSalaryId,
  addSalary,
  updateSalary,
  deleteSalary,
} from "redux/index";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";

import makeStyles from "@mui/styles/makeStyles";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSwitchField from "Components/SomuraSwitchField";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
    const selectedSalaryId = state.selections.selectedSalaryId;
    const personId = state.persons.personId;
    const person = state.persons.Person
  return {
    selectedSalaryId, 
    salary: state.persons.Person?.wages?.find(
      (W) => W.id === selectedSalaryId
    ),
    personId,
    person,
    loggedUserId: state.loggeduser.LoggedUser.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSalaryId: (id) => dispatch(setSalaryId(id)),
    addSalary: (values) => dispatch(addSalary(values)),
    updateSalary: (values) => dispatch(updateSalary(values)),
    deleteSalary: (values) => dispatch(deleteSalary(values)),
  };
};

const SalaryForm = ({ selectedSalaryId, salary, person, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formHeader =
    person?.listName +
    ": " +
    (salary ? t("Salary since") + " " + moment(salary.startDate).format("L") : t("New salary"));

  const [valid, setValid] = useState(false);

  const emptyState = {
    id: "",
    userId: props.personId,
    startDate: moment().format("YYYY-MM-DD"),
    salary: 0,
    isMonthlySalary: false,
    comment: "",
    createdBy: props.loggedUserId,
  };
  const [state, setState] = useState(emptyState);

  const SalarySchema = Yup.object().shape({
    startDate: Yup.date().required(t("Required")),
    salary: Yup.number().required(t("Required")),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await SalarySchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSubmit = () => {
    if (state.id > 0) {
      props.updateSalary(state);
    } else {
      props.addSalary(state);
    }
    handleCancel();
  };

  const handleDelete = () => {
    props.deleteSalary(state);
    handleCancel();
  };

  const handleCancel = () => {
    props.setSalaryId(-1);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (selectedSalaryId > 0) {
      setState({
        ...salary,
        createdBy: props.loggedUserId,
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalaryId, salary, props.personId, props.loggedUserId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={4} lg={3}>
            <SomuraTextField
              label={t("Start date")}
              type="date"
              name="startDate"
              required
              value={state.startDate}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <SomuraTextField
              type="number"
              name="salary"
              label={t("Salary")}
              required
              value={state.salary}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <SomuraSwitchField
              name="isMonthlySalary"
              checked={state.isMonthlySalary}
              onChange={handleSwitchChange}
              label={t("Monthly salary")}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="comment"
              label={t("Comment")}
              multiline
              value={state.comment}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <div className="formBottom">
          <CancelButton onClick={handleCancel} />
          <DeleteButton
            disabled={salary ? false : true}
            onClick={handleDelete}
          />
          <SaveButton disabled={!valid} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SalaryForm);
