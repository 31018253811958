import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { addLogEntry } from "Components/Utilities";
import { connect } from "react-redux";
import { store } from "redux/store";
import { login, clearPassword } from "redux/index";
import { makeStyles } from "@mui/styles";
import { Grid, Button } from "@mui/material";
import SomuraTextField from "Components/SomuraTextField";

var sha256 = require("js-sha256");

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    textAlign: "center",
    paddingBottom: "0",
    width: "100%",
  },
  username: {
    fontSize: "1rem",
    textAlign: "center",
    paddingTop: "0",
    width: "100%",
  },
  change: {
    paddingLeft: "6px",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  forgotten: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  signInButton: {
    width: "100%",
    margin: "20px 0 !important",
  },
}));

const mapStateToProps = (state) => {
  return {
    loginState: state.loggeduser.LoginState,
    loginName: state.loggeduser.LoginName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials)),
    clearPassword: (userid) => dispatch(clearPassword(userid)),
  };
};

const Password = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [state, setState] = useState({
    username: props.loginName,
    password: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    addLogEntry({
      who: props.loginName,
      what: "Password entered",
    });
    props.login({
      username: props.loginName,
      password: sha256(state.password),
    });
  };

  const handleBack = () => {
    addLogEntry({
      who: props.loginName,
      what: "Cancelled Passwort Entry",
    });
    store.dispatch({ type: "SET_LOGIN_STATE", payload: "0" });
  };

  const handleForgotten = () => {
    addLogEntry({
      who: props.loginName,
      what: "Forgot Password - Requested new Password",
    });
    props.clearPassword({ username: props.loginName });
    store.dispatch({ type: "SET_LOGIN_STATE", payload: "4" });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setState({
      username: props.loginName,
      password: "",
    });
  }, [props.loginName]);

    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSubmit();
        }
      };
      document.addEventListener("keydown", keyDownHandler);
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    });

  return (
    <div className={classes.loginFrame}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <div className={classes.title}>{t("Login")}</div>
          <div className={classes.username}>
            {props.loginName}
            <span className={classes.change} onClick={handleBack}>
              {t("Change")}
            </span>
          </div>
          <Grid item xs={12} lg={12}>
            <SomuraTextField
              label={t("Password")}
              type="password"
              name="password"
              autocomplete="current-password"
              value={state.password}
              onChange={handleChange}
            />
          </Grid>
          <div className={classes.username}>
            <span className={classes.forgotten} onClick={handleForgotten}>
              {t("I can't remember my password")}
            </span>
          </div>
          <Grid item xs={12} lg={12}>
            <Button
              className={classes.signInButton}
              disabled={state.password.length === 0}
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              {t("Login")}
            </Button>
          </Grid>
        </Grid>
        </form>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
