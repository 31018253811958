import React from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { terminateMonth } from "redux/index";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";

import SomuraTextField from "Components/SomuraTextField";

import Paper from "@mui/material/Paper";
import CancelButton from "Components/Buttons/CancelButton";
import OKButton from "Components/Buttons/OKButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    maxWidth: 700,
  },
  dialogContent: {
    width: 400,
    padding: "10px 5px",
    backgroundColor: theme.palette.dialog.content,
  },
  formTitle: {
    textAlign: "center",
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
  okButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-selectionform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    terminateMonth: (values) => dispatch(terminateMonth(values)),
  };
};

const MonthTerminationDialog = ({ terminateMonth, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    store.dispatch({ type: "OPEN_TERMINATION", payload: false });
  };

  const handleSave = () => {
    terminateMonth({ month: props.month, year: props.year });
    store.dispatch({ type: "OPEN_TERMINATION", payload: false });
    props.onClose();
  };

  return (
    <Dialog
      // classes={{ paper: classes.dialogPosition }}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-title"
        dialogTitle={t("Month termination")}
        closeForm={handleClose}
        backgroundColor="#243573"
      />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.formTitle}>
              {t("Run month termination for")}
            </div>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <SomuraTextField
              className={classes.dateInput}
              autoFocus
              type="text"
              name="month"
              value={moment(props.year + "-" + props.month + "-1").format(
                "MMMM YYYY"
              )}
              disabled={true}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialogActions">
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <OKButton onClick={handleSave} />
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, mapDispatchToProps)(MonthTerminationDialog);
