import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  setEntitlementId,
  addEntitlement,
  updateEntitlement,
  deleteEntitlement,
} from "redux/index";

import Grid from "@mui/material/Grid";

import makeStyles from "@mui/styles/makeStyles";
import * as Yup from "yup";
import SomuraTextField from "Components/SomuraTextField";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles(() => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  const selectedEntitlementId = state.selections.selectedEntitlementId;
  const entitlement = state.persons.Person?.entitlements?.find(
    (E) => E.id === selectedEntitlementId
  );
  const person = state.persons.Person
  return {
    selectedEntitlementId,
    entitlement,
    personId: state.persons.personId,
    person,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEntitlementId: (id) => dispatch(setEntitlementId(id)),
    addEntitlement: (values) => dispatch(addEntitlement(values)),
    updateEntitlement: (values) => dispatch(updateEntitlement(values)),
    deleteEntitlement: (values) => dispatch(deleteEntitlement(values)),
  };
};

const EntitlementForm = ({
  selectedEntitlementId,
  entitlement,
  personId,
  person,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formHeader =
    person?.listName +
    ": " +
    (entitlement
      ? t("Entitlement starting ") + entitlement.year
      : t("New entitlement"));

  const emptyState = {
    id: "",
    userId: personId,
    year: moment().format("YYYY"),
    numDays: 0,
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);

  const EntitlementSchema = Yup.object().shape({
    year: Yup.number().required(t("Required")),
    numDays: Yup.number().min(1).required(t("Required")),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await EntitlementSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSubmit = () => {
    if (state.id > 0) {
      props.updateEntitlement(state);
    } else {
      props.addEntitlement(state);
    }
    handleCancel();
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleCancel = () => {
    props.setEntitlementId(-1);
  };

  const handleDelete = () => {
    props.deleteEntitlement(state);
    handleCancel();
  };

  useEffect(() => {
    if (selectedEntitlementId > 0) {
      setState({
        ...entitlement,
      });
    } else {
      setState({
        ...emptyState,
        userId: personId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntitlementId, entitlement, personId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SomuraTextField
              type="number"
              name="year"
              label={t("Year")}
              step="1"
              value={state.year}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraTextField
              type="number"
              name="numDays"
              label={t("Vacation days")}
              step="1"
              value={state.numDays}
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>

        <div className="formBottom">
          <CancelButton onClick={handleCancel} />
          <DeleteButton
            disabled={entitlement ? false : true}
            onClick={handleDelete}
          />
          <SaveButton disabled={!valid} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntitlementForm);
