import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { saveReminder, deleteReminder } from "redux/index";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import makeStyles from "@mui/styles/makeStyles";
import SomuraTextField from "Components/SomuraTextField";
import SomuraTextFieldLargeMUI from "Components/SomuraTextFieldLargeMUI";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraSelectField from "Components/SomuraSelectField";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  formHeader: {
    textAlign: "center",
    borderBottom: "2px solid grey",
    padding: "4px",
    fontSize: "22px",
    minHeight: "35px",
    lineHeight: "25px",
    fontWeight: "bold",
    color: theme.palette.text.screenTitle,
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
  fieldset: {
    border: "none",
    padding: "0px",
    margin: "0",
  },
  label: {
    fontSize: "1rem",
    height: "2.4rem",
    display: "flex",
    alignItems: "center",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    reminders: state.reminders.Reminders,
    selectedReminder: state.reminders.selectedReminder,
    reminderTimes: state.remindertimes.ReminderTimes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReminder: (values) => dispatch(saveReminder(values)),
    deleteReminder: (values) => dispatch(deleteReminder(values)),
  };
};

const RemindersForm = ({
  loggedUserId,
  reminders,
  selectedReminder,
  reminderTimes,
  saveReminder,
  deleteReminder,
  handleClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: 0,
    userId: loggedUserId,
    description: "",
    text: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    enableAlert: false,
    alertTimeId: 4,
    alertDate: "",
    alertTime: "",
    isWholeDay: false,
  };
  const [state, setState] = useState(emptyState);
  const reminder = reminders?.find((R) => R.id === selectedReminder);
  const formHeader = reminder
    ? t("Reminder") + ": " + reminder.title
    : t("New reminder");

  const reminderTimeOptions = reminderTimes.map((RT) => ({
    label: RT.reminderTimeText,
    value: RT.id,
  }));

  const handleSubmit = () => {
    saveReminder({
      ...state,
      startDateTime: state.startDate + " " + state.startTime,
      endDateTime: state.isWholeDay
        ? state.startDate + " 23:59:59"
        : moment(state.endDate).isValid()
        ? state.endDate + " " + state.endTime
        : state.startDate + " " + state.startTime,
      alertDateTime: state.enableAlert
        ? moment(state.alertDate).isValid()
          ? state.alertDate + " " + state.alertTime
          : state.startDate + " " + state.startTime
        : state.startDate + " " + state.startTime,
    });
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target ? event.target.name : event.name;
    var value = event.target ? event.target.value : event.value;
    setState({ ...state, [name]: value });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
  };

  const handleDelete = () => {
    deleteReminder({ id: state.id });
    handleClose();
  };

  useEffect(() => {
    if (reminder) {
      setState({
        ...reminder,
        startDate: moment(reminder.startDateTime).format("YYYY-MM-DD"),
        startTime: moment(reminder.startDateTime).format("HH:mm"),
        endDate: moment(reminder.endDateTime).format("YYYY-MM-DD"),
        endTime: moment(reminder.endDateTime).format("HH:mm"),
        alertDate: moment(reminder.alertDateTime).format("YYYY-MM-DD"),
        alertTime: moment(reminder.alertDateTime).format("HH:mm"),
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminder]);

  return (
    <Paper square elevation={8}>
      <div className={classes.formHeader}>{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Target date")}</div>
              </Grid>
              <Grid item xs={8}>
                <SomuraTextField
                  type="text"
                  name="title"
                  label={t("Title")}
                  value={state.title}
                  maxlength="50"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraSwitchField
                  name="isWholeDay"
                  label={t("All-day")}
                  checked={state.isWholeDay}
                  onChange={handleSwitchChange}
                />
              </Grid>
              <Grid item xs={2}>
                <div className={classes.label}>{t("Start")}:</div>
              </Grid>
              <Grid item xs={6}>
                <SomuraTextField
                  type="date"
                  name="startDate"
                  label={"Date"}
                  value={state.startDate}
                  onChange={handleChange}
                  min={moment().format("YYYY-MM-DD")}
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="time"
                  name="startTime"
                  label={"Time"}
                  value={state.startTime}
                  onChange={handleChange}
                  disabled={state.isWholeDay}
                />
              </Grid>

              <Grid item xs={2}>
                <div className={classes.label}>{t("End")}:</div>
              </Grid>
              <Grid item xs={6}>
                <SomuraTextField
                  type="date"
                  name="endDate"
                  label={"Date"}
                  value={state.endDate}
                  onChange={handleChange}
                  min={state.startDate}
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="time"
                  name="endTime"
                  label={"Time"}
                  value={state.endTime}
                  onChange={handleChange}
                  disabled={state.isWholeDay}
                />
              </Grid>
              <Grid item xs={12}>
                <SomuraTextFieldLargeMUI
                  type="text"
                  name="description"
                  label={"Description"}
                  rows="4"
                  value={state.description}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Reminder")}</div>
              </Grid>
              <Grid item xs={12}>
                <SomuraSwitchField
                  name="enableAlert"
                  label={t("Remind me")}
                  checked={state.enableAlert}
                  onChange={handleSwitchChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SomuraSelectField
                  name="alertTimeId"
                  label={t("Timeframe")}
                  options={reminderTimeOptions}
                  style={{ marginTop: "4px" }}
                  disabled={!state.enableAlert}
                  value={state.alertTimeId}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SomuraTextField
                  type="date"
                  name="alertDate"
                  label={"Date"}
                  value={state.alertDate}
                  onChange={handleChange}
                  min={moment().format("YYYY-MM-DD")}
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="time"
                  name="alertTime"
                  label={"Time"}
                  value={state.alertTime}
                  onChange={handleChange}
                  disabled={state.isWholeDay}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="paperBottom">
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <DeleteButton
          disabled={reminder ? false : true}
          onClick={() => {
            handleDelete();
          }}
        />
        <SaveButton onClick={handleSubmit} />
      </div>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindersForm);
