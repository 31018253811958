import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addOffDayText, updateOffDayText, deleteOffDayText } from "redux/index";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import SomuraSwitchField from "Components/SomuraSwitchField";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "0 10px 6px 10px",
  },
  formHeader: {
    textAlign: "center",
    borderBottom: "2px solid grey",
    marginBottom: "10px",
    padding: "4px",
    fontSize: "22px",
    minHeight: "35px",
    lineHeight: "25px",
    color: theme.palette.text.screenTitle,
  },
}));

const mapStateToProps = (state) => {
  return {
    offDayTypes: state.offdaytypes.OffDayTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addOffDayText: (values) => dispatch(addOffDayText(values)),
    updateOffDayText: (values) => dispatch(updateOffDayText(values)),
    deleteOffDayText: (values) => dispatch(deleteOffDayText(values)),
  };
};

const OffDayTextForm = ({
  displayName,
  offDayTypeId,
  textId,
  offDayTypes,
  addOffDayText,
  updateOffDayText,
  deleteOffDayText,
  closeTextForm,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    textId: 0,
    text: "",
    selectable: true,
    numAssigned: 0,
  };
  const [state, setState] = useState(emptyState);

  const handleSubmit = () => {
    if (textId === 0) {
      addOffDayText({
        offDayTypeId: offDayTypeId,
        text: state.text,
        selectable: state.selectable,
      });
    } else {
      updateOffDayText({
        offDayTypeId: offDayTypeId,
        id: state.textId,
        text: state.text,
        selectable: state.selectable,
      });
    }
    closeTextForm();
  };

  const handleDelete = () => {
    deleteOffDayText({
      offDayTypeId: offDayTypeId,
      id: state.textId,
    });
    closeTextForm();
  };

  const handleClose = () => {
    closeTextForm();
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setState({ ...state, [event.target.name]: value });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    setState({
      textId: textId,
      text:
        offDayTypes
          ?.find((OT) => OT.id === offDayTypeId)
          ?.additionalTexts.find((AT) => AT.id === textId)?.text ?? "",
      selectable:
        offDayTypes
          ?.find((OT) => OT.id === offDayTypeId)
          ?.additionalTexts.find((AT) => AT.id === textId)?.selectable ?? true,
      numAssigned:
        offDayTypes
          ?.find((OT) => OT.id === offDayTypeId)
          ?.additionalTexts.find((AT) => AT.id === textId)?.numAssigned ?? 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offDayTypes, textId]);

  return (
    <div className={classes.thisForm}>
      <div>
        <div className={classes.formHeader}>
          {t("Absence type") +
            ": " +
            displayName +
            " > " +
            t("Additional text")}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={4}>
            <TextField
              type="text"
              name="text"
              label={"Text"}
              value={state.text}
              maxLength="255"
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <SomuraSwitchField
              name="selectable"
              checked={state.selectable}
              onChange={handleSwitchChange}
              label={t("Selectable")}
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton onClick={handleClose} />
        <DeleteButton disabled={state.numAssigned > 0} onClick={handleDelete} />
        <SaveButton disabled={state.text === ""} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OffDayTextForm);
