import React, { useEffect, useState, useCallback } from "react";
import {
  fetchHolidayTypes,
  fetchCountries,
  updateHoliday,
  addHoliday,
  deleteHoliday,
  setEditMode,
  setHolidayId,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import * as Yup from "yup";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import SomuraTextField from "Components/SomuraTextField";
import makeStyles from "@mui/styles/makeStyles";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
  fieldset: {
    border: "none",
    padding: "20px 10px 0px 10px",
    margin: "0",
  },
  dayDescription: {
    padding: "10px",
    fontSize: "18px",
  },
}));

const mapStateToProps = (state) => {
  const holidayId = state.specialdays.holidayId;
  const holiday = state.specialdays.Holidays.find((HD) => HD.id === holidayId);

  const types = state.specialdays.HolidayTypes;
  const typeOptions = types
    ? types.map((d) => ({ label: d.holidayName, value: d.id }))
    : { label: "", value: "" };

  const holidayName = holiday
    ? types.find((d) => d.id === holiday.holidayTypeId).holidayName
    : "";

  const formDisabled = !state.general.EditMode;

  const countries = state.countries.Countries;

  return {
    holiday,
    typeOptions,
    formDisabled,
    holidayName,
    countries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateHoliday: (values) => dispatch(updateHoliday(values)),
    addHoliday: (values) => dispatch(addHoliday(values)),
    deleteHoliday: (id) => dispatch(deleteHoliday({ id: id })),
    fetchHolidayTypes: () => dispatch(fetchHolidayTypes()),
    fetchCountries: () => dispatch(fetchCountries()),
    setEditMode: (editMode) => dispatch(setEditMode(editMode)),
    setHolidayId: (id) => dispatch(setHolidayId(id)),
  };
};

const HolidaysForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const HolidaySchema = Yup.object().shape({
    holidayTypeId: Yup.number().integer().moreThan(0).required(),
    countryId: Yup.number().integer().moreThan(0).required(),
    startDate: Yup.date().required(t("Required")),
    endDate: Yup.date().required(t("Required")),
  });
  const emptyState = {
    id: 0,
    holidayTypeId: "",
    holidayTypeName: "",
    countryId: 0,
    regionId: 0,
    startDate: "",
    endDate: "",
  };
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(emptyState);
  const [regions, setRegions] = useState([]);
  const selectedHoliday = props.holiday;
  const holidaytypes = props.typeOptions;
  const formHeader = selectedHoliday
    ? t("School holidays") + ": " + props.holidayName
    : t("New school holidays");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await HolidaySchema.isValid(state));
  });

  const handleSubmit = () => {
    if (state.id === 0) {
      props.addHoliday(state);
    } else {
      props.updateHoliday(state);
    }
    handleClose();
  };

  const handleClose = () => {
    props.setHolidayId(-1);
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "edit":
        props.setEditMode(true);
        break;
      case "cancel":
        handleClose();
        break;
      case "delete":
        props.deleteHoliday(selectedHoliday.id);
        handleClose();
        break;
      default:
        handleClose();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    props.fetchHolidayTypes();
    props.fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedHoliday) {
      setState({
        ...selectedHoliday,
        holidayTypeName: holidaytypes?.find(
          (HT) => HT.id === selectedHoliday.holidayTypeId
        )?.holidayName,
      });
      setRegions(
        props.countries
          .find((C) => C.id === selectedHoliday.countryId)
          ?.regions.map((R) => ({
            label: R.regionLong,
            value: R.id,
          }))
      );
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHoliday]);

  useEffect(() => {
    const currentCountry = props.countries.find(
      (C) => parseInt(C.id) === parseInt(state.countryId)
    );
    if (currentCountry?.regions.length > 0) {
      setRegions(
        currentCountry?.regions.map((R) => ({
          label: R.regionLong,
          value: R.id,
        }))
      );
      setRegions((prevRegions) => [...prevRegions, { label: "", value: "0" }]);
    } else {
      setRegions([{ label: "", value: "0" }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.countryId]);

   useEffect(() => {
     validate();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [state]);

  return (
    <div>
      <Paper square elevation={8}>
        <div className="formHeader">{formHeader}</div>
        <div className={classes.thisForm}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SomuraAutocomplete
                name="holidayTypeId"
                value={state.holidayTypeId}
                onChange={(values) => handleAutoCompleteChange(values)}
                label={t("Designation")}
                options={holidaytypes}
                variant="outlined"
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={4}>
              <SomuraAutocomplete
                name="countryId"
                label={t("Country")}
                value={state.countryId}
                options={props.countries?.map((C) => ({
                  label: C.ownName,
                  value: C.id,
                }))}
                onChange={(values) => handleAutoCompleteChange(values)}
                required
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              {props.countries && (
                <SomuraAutocomplete
                  name="regionId"
                  label={t("Region")}
                  options={regions}
                  value={state.regionId}
                  onChange={(values) => handleAutoCompleteChange(values)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <SomuraTextField
                label={t("First day of vacation")}
                type="date"
                name="startDate"
                value={state.startDate}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <SomuraTextField
                label={t("Last day of vacation")}
                type="date"
                name="endDate"
                value={state.endDate}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>
          <div className="formBottom">
            <CancelButton
              onClick={() => {
                handleButtonClick("cancel");
              }}
            />
            {selectedHoliday && (
              <DeleteButton
                disabled={moment(state.endDate) >= moment()}
                onClick={() => {
                  handleButtonClick("delete");
                }}
              />
            )}
            <SaveButton onClick={handleSubmit} disabled={!valid} />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidaysForm);
