import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { acceptOffDays } from "redux/index";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

import { store } from "redux/store";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import makeStyles from "@mui/styles/makeStyles";

import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ClearIcon from "@mui/icons-material/Clear";

import DateList from "Calendars/Components/DateList";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: 700,
  },
  cancelButton: {
    gridColumn: 3,
  },
  saveButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-acceptform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  const logged = state.loggeduser.LoggedUser.id;
  const displayyear = state.general.DisplayYear;
  const offDayTypes = state.offdaytypes.OffDayTypes;

  const userId = state.yearcalendar.displayUserId;
  return {
    logged,
    displayyear,
    offDayTypes,
    userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acceptOffDays: (values) => dispatch(acceptOffDays(values)),
  };
};

const AcceptForm = ({
  logged,
  displayyear,
  offDayTypes,
  offDayTextId,
  dates,
  userId,
  approvementId,
  acceptOffDays,
  closeAcceptForm,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const offDayTypeName = offDayTypes
    .find((ODT) => ODT.id === props.offDayTypeId)
    ?.names.find(
      (OTN) => OTN.language === localStorage.getItem("lang")
    )?.offDayType;

  const [state] = useState({
    loggedUserId: logged,
    approvementId: approvementId,
  });

  const handleSubmit = () => {
    acceptOffDays(state);
    handleClose();
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    closeAcceptForm();
  };

  const title = () => {
    let title = "";
    let subtitle = "";
    const firstDate = moment(props.startDate).format("dddd, LL");
    const lastDate = moment(props.endDate).format("dddd, LL");

    if (firstDate === lastDate) {
      title = firstDate;
    } else {
      title = firstDate + " bis " + lastDate;
    }

    subtitle =
      props.numWorkdays +
      (props.numWorkdays === 1
        ? " " + t("working day") + " ("
        : " " + t("working days") + " (");
    subtitle +=
      props.numDays +
      (props.numDays === 1 ? " " + t("day") + ")" : " " + t("days") + ")");

    return (
      <span>
        {title}
        <br />
        {subtitle}
      </span>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-acceptform-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-acceptform-title"
        dialogTitle={t("Approve absence")}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="green"
      />
      <DialogContent className="dialogContent">
        <div className="timeFrame">{title()}</div>

        <fieldset className={classes.fieldset} disabled={props.formDisabled}>
          <Grid container spacing={3}>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DateList dates={dates} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="offDayTypeName"
                    label={t("Absence type")}
                    disabled={true}
                    value={offDayTypeName}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                {props.additionalText?.length > 0 && (
                  <Grid item xs={12}>
                    <TextField
                      name="additionalText"
                      label={t("Additional text")}
                      disabled={true}
                      value={props.additionalText}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </fieldset>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button
          id="cancelselection"
          className={classes.cancelButton}
          startIcon={<ClearIcon />}
          variant="outlined"
          color="cancel"
          onClick={() => {
            handleClose();
          }}
        >
          {t("Abort")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          startIcon={<ThumbUpAltIcon />}
          onClick={handleSubmit}
        >
          {t("Approve")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptForm);
