import {
  FETCH_MONTH_FAILURE,
  FETCH_MONTH_STATIC_SUCCESS,
  FETCH_MONTH_SUMS_SUCCESS,
  FETCH_MONTH_OFFDAYS_SUCCESS,
  SET_DISPLAY_DEPARTMENT,
} from "./types";

import { monthOffDays, monthStaticData, monthSumData } from "../api";

import {
  dataStartLoading,
  dataEndLoading,
} from "./general";

const fetchMonthFailure = (err) => {
  return {
    type: FETCH_MONTH_FAILURE,
    payload: err,
  };
};

const fetchMonthStaticSuccess = (monthData) => {
  return {
    type: FETCH_MONTH_STATIC_SUCCESS,
    payload: monthData,
  };
};

const fetchMonthSumsSuccess = (monthData) => {
  return {
    type: FETCH_MONTH_SUMS_SUCCESS,
    payload: monthData,
  };
};

const fetchMonthOffdaysSuccess = (monthData) => {
  return {
    type: FETCH_MONTH_OFFDAYS_SUCCESS,
    payload: monthData,
  };
};

export const fetchMonth = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    try {
      const monthStatic = await dispatch(monthStaticData(values));
      await dispatch(fetchMonthStaticSuccess(monthStatic));
      const monthSums = await dispatch(monthSumData(values));
      await dispatch(fetchMonthSumsSuccess(monthSums));
      const monthOffdays = await dispatch(monthOffDays(values));
      await dispatch(fetchMonthOffdaysSuccess(monthOffdays));
      dispatch(dataEndLoading());
    } catch (err) {
      await dispatch(fetchMonthFailure(err));
      dispatch(dataEndLoading());
    }
  };
};

const setDisplayDepartmentData = (id) => {
  return {
    type: SET_DISPLAY_DEPARTMENT,
    payload: id,
  };
};

export const setDisplayDepartment = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(setDisplayDepartmentData(id));
    } catch (err) {
      await dispatch(fetchMonthFailure(err));
    }
  };
};
