import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setEditMode } from "redux/index";

import { useTheme } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ScreenTitle from "Components/ScreenTitle";
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";

import Box from "@mui/material/Box";

import SpecialDaysTable from "./SpecialDays/SpecialDaysTable";
import RemarkableDaysTable from "./SpecialDays/RemarkableDaysTable";
import HolidaysTable from "./SpecialDays/HolidaysTable";
import SpecialDaysForm from "./SpecialDaysForm";
import RemarkableDaysForm from "./RemarkableDaysForm";
import HolidaysForm from "./HolidaysForm";

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const mapStateToProps = (state) => {
  return {
    editMode: state.general.EditMode,
    displaySettingsId: state.specialdays.displaySettingsId,
    specialDayId: state.specialdays.specialDayId,
    remarkableDayId: state.specialdays.remarkableDayId,
    holidayId: state.specialdays.holidayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditMode: (editMode) => dispatch(setEditMode(editMode)),
  };
};

function Calendar(props) {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  useEffect(() => {
    props.setEditMode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content">
      <Paper elevation={8}>
        <ScreenTitle
          title={t("Settings: calendar")}
          class="settings"
          icon={
            <CalendarTodayTwoToneIcon
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <Collapse
          in={
            props.specialDayId < 0 &&
            props.remarkableDayId < 0 &&
            props.holidayId < 0
          }
          timeout={"auto"}
        >
          <Tabs
            className="tabPanel"
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleTabsChange}
          >
            <Tab label={t("Holidays")} />
            <Tab label={t("Special days")} />
            <Tab label={t("School holidays")} />
          </Tabs>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <SpecialDaysTable />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <RemarkableDaysTable />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <HolidaysTable />
          </TabPanel>
        </Collapse>
        <Collapse in={props.specialDayId >= 0} timeout={"auto"}>
          <SpecialDaysForm />
        </Collapse>
        <Collapse in={props.remarkableDayId >= 0} timeout={"auto"}>
          <RemarkableDaysForm />
        </Collapse>
        <Collapse in={props.holidayId >= 0} timeout={"auto"}>
          <HolidaysForm />
        </Collapse>
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
