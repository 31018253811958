import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { fetchStats, setOffDays } from "redux/index";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

import { store } from "redux/store";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SomuraSelectField from "Components/SomuraSelectField";

import makeStyles from "@mui/styles/makeStyles";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

import DateList from "Calendars/Components/DateList";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: 700,
  },
  cancelButton: {
    gridColumn: 3,
  },
  saveButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-approvedirectform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state, props) => {
  const loggedAdmin = state.loggeduser.LoggedUser.isadmin;
  const loggedAssistant = state.loggeduser.LoggedUser.isassistant;
  const yeardata = state.year.Year;
  const displayyear = state.general.DisplayYear;
  const displaymonth = state.general.DisplayMonth;
  const displayDepartment = state.month.DisplayDepartmentId;
  const offDayTypes = state.offdaytypes.OffDayTypes.filter(
    (ODT) => ODT.notForUser === false || loggedAdmin || loggedAssistant
  ).filter((OT) => OT.onlyOneDay === false || props.numDays === 1);
  const offDayTypeOptions = offDayTypes.map((ODT) => ({
    label: ODT.names.find(
      (OTN) => OTN.language === localStorage.getItem("lang")
    ).offDayType,
    value: ODT.id,
  }));
   offDayTypeOptions.sort((a, b) =>
     a.label > b.label ? 1 : b.label > a.label ? -1 : 0
   );
  offDayTypeOptions.push({ label: "", value: "0" });
  const userId = state.yearcalendar.displayUserId;
  return {
    yeardata,
    displayyear,
    displaymonth,
    displayDepartment,
    offDayTypes,
    offDayTypeOptions,
    userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStats: (values) => dispatch(fetchStats(values)),
    setOffDays: (values) => dispatch(setOffDays(values)),
  };
};

const ApproveDirectForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [state, setState] = useState({
    offDayTypeId: "0",
    offDayTextId: "0",
    dates: props.dates,
    userId: props.userId,
    approvementId: "0",
    offDayState: "3",
    published: "1",
    dayFactor: "1",
    noMessages: true,
    language: localStorage.getItem("lang"),
    requireAdditionalText: false,
  });

  const [offDayTextOptions, setOffDayTextOptions] = useState([]);

  const offDayTypeOptions = props.offDayTypeOptions;
  const offDayTypes = props.offDayTypes;

  var selectedType;

  const handleSubmit = () => {
    props.setOffDays(state);
    handleClose();
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    props.closeApproveDirectForm();
  };

const handleTypeChange = (event) => {
  const value = event.target.value;
  const name = event.target.name;
  selectedType = offDayTypes.find((OT) => OT.id === event.target.value);
  setState({
    ...state,
    // dayFactor: selectedType.offDayFactor,
    offDayTextId: 0,
    [name]: value,
    requireAdditionalText: selectedType?.requireAdditionalText,
  });

  var textOptions = selectedType?.additionalTexts
    .filter((AT) => AT.selectable)
    .map((AT) => ({
      label: AT.text,
      value: AT.id,
    }));
  if (textOptions.length > 0 && selectedType?.requireAdditionalText === false) {
    textOptions.push({ label: "", value: "0" });
  }
  setOffDayTextOptions(textOptions);
};


  const handleTextChange = (event) => {
    const value = event.target.value;
    setState({ ...state, [event.target.name]: value });
  };

  const title = () => {
    let title = "";
    let subtitle = "";
    const firstDate = moment(props.startDate).format("dddd, LL");
    const lastDate = moment(props.endDate).format("dddd, LL");

    if (firstDate === lastDate) {
      title = firstDate;
    } else {
      title = firstDate + " " + t("to") + " " + lastDate;
    }

    subtitle =
      props.numWorkdays +
      " " +
      (props.numWorkdays === 1 ? t("Working day") : t("Working days")) +
      " (";
    subtitle +=
      props.numDays + " " + (props.numDays === 1 ? t("day") : t("days")) + ")";

    return (
      <span>
        {title}
        <br />
        {subtitle}
      </span>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-approvedirectform-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-approvedirectform-title"
        dialogTitle={t("Register approved absence")}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="#00897B"
      />
      <DialogContent className="dialogContent">
        <div className="timeFrame">{title()}</div>
        <fieldset className={classes.fieldset}>
          <Grid container spacing={3}>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DateList dates={props.dates} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SomuraSelectField
                    name="offDayTypeId"
                    label={t("Absence type")}
                    value={state.offDayTypeId}
                    options={offDayTypeOptions}
                    onChange={handleTypeChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                {/* </Grid> */}
                {offDayTextOptions.length > 0 && (
                  <Grid item xs={12}>
                    <SomuraSelectField
                      name="offDayTextId"
                      label={t("Additional text")}
                      options={offDayTextOptions}
                      value={state.offDayTextId}
                      onChange={handleTextChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </fieldset>
      </DialogContent>
      <DialogActions className="dialogActions">
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <SaveButton
          disabled={
            state.offDayTypeId < 1 ||
            (offDayTextOptions.length > 0 &&
              state.offDayTextId < 1 &&
              state.requireAdditionalText)
          }
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveDirectForm);
