import React, { useEffect, useState } from "react";
import {
  setRecipientMessageFolder,
  saveMessage,
  saveDraftMessage,
  updateDraftMessage,
  updateDraftRecipients,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import SomuraTextField from "Components/SomuraTextField";
import moment from "moment";
import { store } from "redux/store";

const useStyles = makeStyles((theme) => ({
  newMessageRoot: {
    padding: "1rem",
  },
  paper: {
    backgroundColor: theme.palette.background.messageList,
    borderRadius: "4px",
    padding: "1rem",
  },
  grey: {
    backgroundColor: theme.palette.text.disabled,
  },
  creationTime: {
    fontSize: "0.8rem",
    textAlign: "right",
  },
}));

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;
  const messageId = state.messages.selectedMessageId;
  const message = state.messages.Messages.find((M) => M.id === messageId);
  const draftMessage = state.messages.draftMessage
  const messageSendable = state.messages.messageSendable
  return {
    persons: state.persons.PersonsMin,
    loggedUser,
    message,
    draftMessage,
    messageId,
    messageSendable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRecipientMessageFolder: (values) =>
      dispatch(setRecipientMessageFolder(values)),
    saveMessage: (values) => dispatch(saveMessage(values)),
    saveDraftMessage: () => dispatch(saveDraftMessage()),
    updateDraftMessage: (values) => dispatch(updateDraftMessage(values)),
    updateDraftRecipients: (values) => dispatch(updateDraftRecipients(values)),
  };
};

const NewMessage = ({
  persons,
  loggedUser,
  messageId,
  draftMessage,
  messageSendable,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: 0,
    fromUserId: loggedUser.id,
    toMode: "0",
    sender: "",
    title: "",
    message: "",
    isImportant: false,
    senderFolderId: "-4",
    recipients: [],
    creationTime: moment().format("YYYY-MM-DD HH:mm:ss"),
  };
  const [state, setState] = useState(emptyState);
  var recipients = [];

    if (loggedUser.messagesToAllUsers === true) {
      recipients = persons.filter(
        (P) =>
          P.active === true &&
          P.accountLocked === false &&
          P.id !== loggedUser.id
      ).map((P) => ({
        label: P.listName,
        value: P.id,
      }));
    } else {
      recipients = persons.filter(
        (P) =>
          P.active === true &&
          P.accountLocked === false &&
          (P.isadmin === true || P.isapprover === true) &&
          P.id !== loggedUser.id
      ).map((P) => ({
        label: P.listName,
        value: P.id,
      }));
    }    
    recipients.push({ label: t("Administrators"), value: "-1" });
    recipients.push({ label: t("Assistants"), value: "-2" });
    recipients.push({
      label: t("Administrators") + " + " + t("Assistants"),
      value: "-3",
    });

  const showMessage = props.message;
  const admins = persons.filter((P) => P.isadmin === true);
  const assistants = persons.filter((P) => P.isassistant === true);

  // let recipientName;
  // let recipientShort;
  // let recipientClass;
  // let recipient;
  let messageRecipients;

  const sender = persons.find((P) => P.id === loggedUser.id);
  const senderName = sender.lastName + ", " + sender.firstName;

  // const recipientId =
  //   showMessage.recipients && showMessage.recipients.length > 0
  //     ? showMessage.recipients[0].recipientId
  //     : 0;
  // if (showMessage) {
  //   recipient = persons.find((P) => P.id === recipientId);
  //   if (recipient) {
  //     recipientName = recipient.lastName + ", " + recipient.firstName;
  //     recipientShort = recipient.initials;
  //     recipientClass = classes.avatar;
  //   } else {
  //     recipientName = "";
  //     recipientShort = "";
  //     recipientClass = classes.avatar + " " + classes.grey;
  //   }
  // }

  const checkSendable = (value) => {
    if(value !== messageSendable){
      store.dispatch({
        type: "SET_MESSAGE_SENDABLE",
        payload: !messageSendable,
      });
    }
  }

  const handleTitleChange = (event) => {
    const value = event.target.value;
    checkSendable(parseInt(state.toMode) !== 0 && value.length > 0);
    props.updateDraftMessage({ ...state, title: value });
    setState({ ...state, title: value });
  };

  const handleRecipientsChange = (params) => {
    const value = params.value;
    checkSendable(value !== "" && parseInt(value) !== 0 && state.title.length > 0);
    props.updateDraftMessage({ ...state, toMode: value });
    handleRecipients(value)
    setState({ ...state, toMode: value });
  };

  const handleMessageChange = (event) => {
    const value = event.target.value;
    props.updateDraftMessage({ ...state, message: value });
    setState({ ...state, message: value });
  };

  useEffect(() => {
    store.dispatch({ type: "COPY_MESSAGE_TO_DRAFT", payload: showMessage });
    checkSendable(
      showMessage.toMode !== "" &&
        parseInt(showMessage.toMode) !== 0 &&
        showMessage.title.length > 0
    );    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[messageId])

  useEffect(() => {
    return () => {
      props.saveDraftMessage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleRecipients = (mode) => {
      switch (mode) {
        case "-1": // Admins
          messageRecipients = admins.map((A) => ({
            messageId: messageId,
            recipientId: A.id,
            recipientName: A.lastName + ", " + A.firstName,
            recipientFolderId: 0,
          }));
          break;
        case "-2": // Assistants
          messageRecipients = assistants.map((A) => ({
            messageId: messageId,
            recipientId: A.id,
            recipientName: A.lastName + ", " + A.firstName,
            recipientFolderId: 0,
          }));
          break;
        case "-3": // Admins + Assistants
          messageRecipients = admins.map((A) => ({
            messageId: messageId,
            recipientId: A.id,
            recipientName: A.lastName + ", " + A.firstName,
            recipientFolderId: 0,
          }));
          var messageRecipients2 = assistants.map((A) => ({
            messageId: messageId,
            recipientId: A.id,
            recipientName: A.lastName + ", " + A.firstName,
            recipientFolderId: 0,
          }));
          messageRecipients.push(...messageRecipients2);
          break;
        default:
          var recipient = persons.find((P) => P.id === mode);
          if (recipient) {
            messageRecipients = [
              {
                messageId: messageId,
                recipientId: mode,
                recipientName: recipient.lastName + ", " + recipient.firstName,
                recipientFolderId: 0,
              },
            ];
          }
      }
      props.updateDraftRecipients(messageRecipients);
  };

  useEffect(() => {
    if (draftMessage) {
      setState({
        ...draftMessage,
        message: typeof draftMessage.message === "string" ? parse(draftMessage.message) : "",
        sender: senderName,
        senderFolderId: "-4",
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftMessage]);

  return (
    <Paper square elevation={8} className={classes.paper}>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.creationTime}>
              {showMessage.id}{" "}
              {t("Draft created") +
                ": " +
                moment(state.creationTime).format("L LT")}
            </div>
          </Grid>
          <Grid item xs={12}>
            <SomuraTextField
              className={classes.sender}
              type="text"
              name="sender"
              label={t("From")}
              value={loggedUser.listName}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SomuraAutocomplete
              className={classes.recipient}
              name="toMode"
              label={t("To")}
              value={state.toMode}
              options={recipients}
              variant="outlined"
              size="small"
              onChange={handleRecipientsChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <SomuraTextField
              className={classes.title}
              type="text"
              name="title"
              label={t("Subject")}
              value={state.title}
              maxlength="255"
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SomuraTextField
              className={classes.message}
              type="text"
              multiline
              rows="15"
              name="message"
              label={t("Message")}
              value={state.message}
              onChange={handleMessageChange}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMessage);
