import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { fetchUserTimes, setSelectedDate } from "redux/index";
import { secondsToTime } from "Components/Utilities";

import SomuraDataGrid from "Components/SomuraDataGrid";

import makeStyles from "@mui/styles/makeStyles";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import BackButton from "Components/Buttons/BackButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  errorSpan: {
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "4px",
    marginRight: "4px",
    color: theme.palette.red,
  },
}));

const mapStateToProps = (state) => {
  return {
    times: state.times.UserTimes,
    persons: state.persons.PersonsMin,
    loggedUser: state.loggeduser.LoggedUser,
    selectedDate: state.times.selectedDate,
    selectedTimeId: state.times.selectedTimeId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserTimes: (values) => dispatch(fetchUserTimes(values)),
    setSelectedDate: (id) => dispatch(setSelectedDate(id)),
  };
};

const TimeTrackForm = ({
  userId,
  selectedDate,
  times,
  persons,
  fetchUserTimes,
  setSelectedDate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const person = persons.find((P) => P.id === userId);
  const formHeader =
    t("Time record for") +
    " " +
    person?.listName +
    " " +
    t("as of") +
    " " +
    moment(selectedDate).format("dddd, D. MMMM YYYY");

  const columns = [
    {
      headerName: "ID",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Start"),
      headerClassName: "tableHeader",
      field: "startTime",
      flex: 0.1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.row.isAutoBreak ? "" : params.value}</span>;
      },
    },
    {
      headerName: t("End"),
      headerClassName: "tableHeader",
      field: "endTime",
      flex: 0.1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.row.isAutoBreak ? "" : params.value}</span>;
      },
    },
    {
      headerName: t("Work time"),
      headerClassName: "tableHeader",
      field: "isWorktime",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.value ? (
                secondsToTime(params.row.seconds, false, true)
              ) : (
                <></>
              )}
            </span>
            {params.value &&
            (params.row.seconds <= 0 || params.row.minutes <= 0) ? (
              <span className={classes.errorSpan}>
                <HelpOutlineOutlinedIcon className={classes.errorIcon} />
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      headerName: t("Break"),
      headerClassName: "tableHeader",
      field: "isBreak",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.value ? (
                secondsToTime(params.row.seconds, false, true)
              ) : (
                <></>
              )}
            </span>
            {params.value &&
            (params.row.seconds <= 0 || params.row.minutes <= 0) ? (
              <span className={classes.errorSpan}>
                <HelpOutlineOutlinedIcon className={classes.errorIcon} />
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      headerName: t("Break deduction"),
      headerClassName: "tableHeader",
      field: "deduction",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.value ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {secondsToTime(params.row.seconds, false, true)}
                  <span style={{ marginLeft: "10px" }}>
                    <AutoFixHighIcon />
                  </span>
                </div>
              ) : (
                <></>
              )}
            </span>
          </>
        );
      },
    },
    {
      headerName: "Created",
      headerClassName: "tableHeader",
      field: "createdOn",
      hide: true,
      filterable: false,
    },
  ];

  const rows = times.map((TD) => ({
    id: TD.id,
    date: moment(TD.date).format("dd., L"),
    startTime: TD.startTime,
    endTime: TD.endTime ? TD.endTime : null,
    minutes: TD.minutes,
    seconds: TD.seconds,
    deduction: TD.isAutoBreak ? TD.seconds : 0,
    isWorktime: TD.isWorktime,
    isBreak: TD.isBreak,
    isAutoBreak: TD.isAutoBreak,
    createdOn: TD.createdOn,
  }));

  const handleBack = () => {
    setSelectedDate("");
  };

  useEffect(() => {
    if (selectedDate && userId) {
      fetchUserTimes({
        date: selectedDate,
        userId: userId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, userId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <SomuraDataGrid
          tableHeight={"calc(100vh - 600px)"}
          rows={rows}
          columns={columns}
          defaultSortModel={[
            {
              field: "id",
              sort: "asc",
            },
          ]}
        />
        <div className="formBottom">
          <BackButton onClick={handleBack} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackForm);
