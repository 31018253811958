import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

const YesButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      startIcon={<CheckIcon />}
      color="primary"
      onClick={props.onClick}
    >
      {t("Yes")}
    </Button>
  );
};

export default YesButton
