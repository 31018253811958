import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setSelectedArticleTypeId,
  setSelectedModelId,
  createArticleType,
  updateArticleType,
  deleteArticleType,
} from "redux/index";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import makeStyles from "@mui/styles/makeStyles";
import AddButton from "Components/Buttons/AddButton";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

import ModelsList from "./ModelsList";
import TypesTree from "./TypesTree";
import ModelDialog from "./ModelDialog";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  subline: {
    display: "grid",
    gridTemplateRows: "2rem",
    gridTemplateColumns: "1fr 2.8rem",
    height: "2rem !important",
    alignItems: "center",
  },
  sublineText: {
    gridColumn: "1",
    height: "1.4rem",
  },
  sublineFab: {
    gridColumn: "2",
  },
}));

const mapStateToProps = (state) => {
  const articleTypes = state.articles.ArticleTypes;
  const models = state.articles.ArticleModels;
  const selectedId = state.articles.selectedArticleTypeId;
  return { articleTypes, models, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedId: (id) => dispatch(setSelectedArticleTypeId(id)),
    setModelId: (id) => dispatch(setSelectedModelId(id)),
    create: (values) => dispatch(createArticleType(values)),
    update: (values) => dispatch(updateArticleType(values)),
    remove: (values) => dispatch(deleteArticleType(values)),
  };
};

const MovementTypesForm = ({
  articleTypes,
  models,
  selectedId,
  setSelectedId,
  setModelId,
  create,
  update,
  remove,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: "0",
    articleTypeName: "",
    needsInventoryNumber: false,
    parentId: "0",
    notSelectable: false,
    numAssigned: "0",
    numSubitems: "0",
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false)
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const formHeader =
    selectedId > 0
      ? t("Article type") + ": " + state.articleTypeName
      : t("New article type");
  var typeOptions = articleTypes
    .filter((AT) => AT.id !== selectedId)
    .map((AT) => ({
      label: AT.articleTypeName,
      value: AT.id,
    }));
  typeOptions.unshift({ label: "", value: "0" });

  const typeNames = articleTypes.map(AT => (AT.articleTypeName))

  const typesSchema = Yup.object().shape({
    articleTypeName: Yup.string()
      .test((value) => !typeNames.includes(value))
      .required(t("Required")),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await typesSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleClose = () => {
    setSelectedId(-1);
  };

  const handleSubmit = () => {
    if (state.id === "0") {
      // Create new article type
      create(state);
    } else {
      // Update article type
      update(state);
    }
    handleClose();
  };

  const handleDelete = () => {
    remove({ id: selectedId });
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({ ...state, [name]: value });
  };

  const handleNewType = () => {
    setSelectedId(0);
  };

  const handleNewModel = () => {
    setModelId(0);
    setOpenModelDialog(true);
  };

  useEffect(() => {
    if (selectedId > 0) {
      setState(articleTypes.find((MT) => MT.id === selectedId));
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <>
      <div>
        <div className={classes.thisForm}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.subline + " subLine"}>
                    <span className={classes.sublineText}>
                      {t("Article types")}
                    </span>
                    <AddButton
                      className={classes.sublineFab}
                      onClick={handleNewType}
                    />
                  </div>
                  <TypesTree />
                </Grid>
              </Grid>
            </Grid>
            {parseInt(selectedId) >= 0 && (
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div className="formHeader">{formHeader}</div>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <SomuraTextField
                          label={t("Article type")}
                          type="text"
                          name="articleTypeName"
                          value={state.articleTypeName}
                          maxlength="50"
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SomuraSwitchField
                          name="notSelectable"
                          checked={state.notSelectable}
                          onChange={handleSwitchChange}
                          label={t("Not selectable (group headline)")}
                          disabled={state.numAssigned > 0}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SomuraSwitchField
                          name="needsInventoryNumber"
                          checked={state.needsInventoryNumber}
                          onChange={handleSwitchChange}
                          label={t("Needs inventory number")}
                          disabled={state.notSelectable}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SomuraAutocomplete
                          name="parentId"
                          label={t("Belongs to")}
                          options={typeOptions}
                          onChange={(values) =>
                            handleAutoCompleteChange(values)
                          }
                          value={state.parentId}
                          variant="outlined"
                          size="small"
                          fullWidth
                          // disabled={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {parseInt(selectedId) > 0 && (
                    <Grid item xs={6}>
                      <div className={classes.subline + " subLine"}>
                        <span className={classes.sublineText}>
                          {t("Models")}
                        </span>
                        <AddButton
                          className={classes.sublineFab}
                          onClick={handleNewModel}
                        />
                      </div>
                      <ModelsList />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
        <div className="formBottom">
          <CancelButton onClick={handleCancel} />
          {selectedId > 0 && (
            <DeleteButton
              onClick={handleDelete}
              disabled={
                models?.filter((M) => M.articleTypeId === selectedId).length >
                  0 || parseInt(state.numSubitems) > 0
              }
            />
          )}
          <SaveButton onClick={handleSubmit} disabled={!valid} />
        </div>
      </div>
      {openModelDialog && (
        <ModelDialog close={() => setOpenModelDialog(false)} />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MovementTypesForm);
