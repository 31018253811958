import {
  CHECK_MAIL,
  SET_LOGIN_STATE,
  SET_LOGIN_NAME,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  SET_LOGGED_USER,
} from "actions/types";
import produce from "immer";

const initialState = {
  LoggedUser: {},
  LoginState: "0",
  error: "",
};

const loggedUserReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHECK_MAIL:
        draft.LoginState = action.payload.loginState;
        draft.LoginName = action.payload.loginName;
        break;
      case SET_LOGIN_STATE:
        draft.LoginState = action.payload;
        break;
      case SET_LOGIN_NAME:
        draft.LoginName = action.payload;
        break;
      case LOGIN_SUCCESS:
        draft.LoggedUser = action.payload
        // draft.LoggedUserId = action.payload.userid;
        // draft.LoggedAdmin = action.payload.loggedAdmin;
        // draft.LoggedAssistant = action.payload.loggedAssistant;
        // draft.LoggedApprentice = action.payload.loggedApprentice;
        // draft.LoggedApprover = action.payload.loggedApprover;
        draft.LoginState = action.payload.loginState;
        break;
      case LOGIN_FAILURE:
        draft.LoggedUser = {}
        // draft.LoginName = "";
        // draft.LoggedUserId = 0;
        // draft.LoggedAdmin = 0;
        // draft.LoggedAssistant = 0;
        draft.error = action.payload;
        break;
      case LOGOUT_SUCCESS:
        draft.LoggedUser = {};
        // draft.LoggedUserId = 0;
        // draft.LoggedAdmin = 0;
        // draft.LoggedAssistant = 0;
        break;
      case SET_LOGGED_USER:
        draft.LoggedUser = action.payload;
        break;
      default:
        return draft;
    }
  });

export default loggedUserReducer;
