import React from "react";
import { setSalaryId } from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { sortByDate } from "Components/Utilities";
import moment from "moment";

import SomuraDataGrid from "Components/SomuraDataGrid";

const mapStateToProps = (state) => {
  const personId = state.persons.personId;
  const wages = state.persons.Person.wages;
  return { personId, wages };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSalaryId: (id) => dispatch(setSalaryId(id)),
  };
};

function SalaryTable({ personId, wages, fetchWages, setSalaryId }) {
  const { t } = useTranslation();

  var formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
    },
    {
      headerName: "Y",
      headerClassName: "tableHeader",
      field: "isMonthlySalary",
      hide: true,
    },
    {
      headerName: t("From date"),
      headerClassName: "tableHeader",
      field: "startDate",
      type: "date",
      sortComparator: (a, b) => sortByDate(a, b),
      flex: 0.2,
    },
    {
      headerName: t("Monthly salary"),
      headerClassName: "tableHeader",
      field: "monthlySalary",
      align: "left",
      sortable: false,
      flex: 0.2,
    },
    {
      headerName: t("Annual salary"),
      headerClassName: "tableHeader",
      field: "annualSalary",
      align: "left",
      sortable: false,
      flex: 0.2,
    },
    {
      headerName: t("Comment"),
      headerClassName: "tableHeader",
      field: "comment",
      align: "left",
      sortable: false,
      flex: 0.2,
    },
  ];

  const rows = wages.map((W) => ({
    id: W.id,
    isMonthlySalary: W.isMonthlySalary,
    startDate: moment(W.startDate).format("L"),
    monthlySalary: formatter.format(
      W.isMonthlySalary ? parseFloat(W.salary) : parseFloat(W.salary) / 12
    ),
    annualSalary: formatter.format(
      !W.isMonthlySalary ? parseFloat(W.salary) : parseFloat(W.salary) * 12
    ),
    comment: W.comment,
  }));

  const handleRowClick = (params) => {
    setSalaryId(params);
  };

  return (
    <SomuraDataGrid
      tableHeight={"calc(100vh - 420px)"}
      rows={rows}
      columns={columns}
      defaultSortModel={[
        {
          field: "startDate",
          sort: "desc",
        },
      ]}
      onRowClick={(params) => handleRowClick(params)}
      csvFileName={t("Salary")}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SalaryTable);
