import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { resetDepartment } from "redux/index";
import Departments from "./Departments/Departments.js";

import { useTheme } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ScreenTitle from "Components/ScreenTitle";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";

import Box from "@mui/material/Box";

import CompanyForm from "./Company/CompanyForm";
import Logo from "./Logo/Logo";
import DepartmentForm from "./Departments/DepartmentForm";

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const mapStateToProps = (state) => {
  return {
    editMode: state.general.EditMode,
    settings: state.settings.GlobalSettings,
    selectedDepartment: state.departments.selectedDepartmentId,
    persons: state.persons.PersonsMin.filter(P => P.active === true)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetDepartment: () => dispatch(resetDepartment()),
  };
};

function Company(props) {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  let selectedDepartmentId = props.selectedDepartment;
  const theme = useTheme();

  const handleTabsChange = (event, newValue) => {
    props.resetDepartment();
    setValue(newValue);
  };

  useEffect(() => {
    props.resetDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <Paper elevation={8}>
        <ScreenTitle
          title={t("Settings: company")}
          class="settings"
          icon={
            <BusinessTwoToneIcon style={{ width: "1.8em", height: "1.8em" }} />
          }
        />
        <Collapse
          in={selectedDepartmentId < 0 }
          timeout={"auto"}
        >
          <Tabs
            className="tabPanel"
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleTabsChange}
          >
            <Tab label={t("Address")} />
            <Tab label="Logo" />
            <Tab label={t("Departments")} />
          </Tabs>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <CompanyForm />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Logo />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Departments />
          </TabPanel>
        </Collapse>
        <Collapse in={selectedDepartmentId >= 0} timeout={"auto"}>
          <DepartmentForm />
        </Collapse>
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Company);
