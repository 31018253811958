import {
  FETCH_REMINDERTIMES_REQUEST,
  FETCH_REMINDERTIMES_SUCCESS,
  REMINDERS_FAILURE
} from "actions/types";
import { remindertimes } from "../api";

const remindersFailure = (err) => {
  return {
    type: REMINDERS_FAILURE,
    payload: err,
  };
};

const fetchReminderTimesRequest = () => {
  return {
    type: FETCH_REMINDERTIMES_REQUEST
  }
}

const fetchReminderTimesSuccess = (remindertimes) => {
  return {
    type: FETCH_REMINDERTIMES_SUCCESS,
    payload: remindertimes
  }
}

export const fetchReminderTimes = () => {
  return async (dispatch) => {
    await dispatch(fetchReminderTimesRequest())
    try {
      const returnJSONData = await dispatch(remindertimes())
      await dispatch(fetchReminderTimesSuccess(returnJSONData))
    } catch(err) {
      await dispatch(remindersFailure(err))
    }
  }
}
