import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPersonalDisplaySettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getDisplaySetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ColorExample from "Components/ColorExample";
import ToolTipExample from "Components/ToolTipExample";
import IconSelect from "Components/IconSelect";
import BackButton from "Components/Buttons/BackButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  back: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "3.5rem",
    marginBottom: "6px",
  },
  line: {
    paddingTop: "6px",
  },
  preview: {
    display: "grid",
    gridTemplateRows: "1fr 2rem 2rem",
    alignContent: "center",
  },
  colorExample: {
    gridRow: "1",
  },
  tooltipExample: {
    gridRow: "3",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    displaySettings: state.settings.DisplaySettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalDisplaySettings: (values) =>
      dispatch(setPersonalDisplaySettings(values)),
  };
};

const DisplaySubForm = ({
  loggedUserId,
  displaySettings,
  component,
  setPersonalDisplaySettings,
  closeSubform,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [state, setState] = useState({
    useIdentifier: 0,
    identifier: "",
    useBackgroundColor: 0,
    backgroundColor: "",
    backgroundFactor1: 0,
    backgroundFactor2: 0,
    useFontColor: 0,
    fontColor: "",
    fontFactor: 0,
    tooltipFontColor: 0,
    useIcon: 0,
    iconId: 0,
    animate: 0,
    showAsBar: 0,
    showInTooltip: 0,
  });

  const handleChange = (event) => {
    const value = event.target.value;
    setState({ ...state, [event.target.name]: value });
    setPersonalDisplaySettings({
      userId: loggedUserId,
      component: component,
      key: event.target.name,
      value: value,
    });
  };

  const handleSwitchChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setState({ ...state, [event.target.name]: value });
    setPersonalDisplaySettings({
      userId: loggedUserId,
      component: component,
      key: event.target.name,
      value: value,
    });
  };

  const handleClose = () => {
    closeSubform();
  };

  useEffect(() => {
    setState({
      useIdentifier: parseInt(getDisplaySetting(component, "useIdentifier")),
      identifier: getDisplaySetting(component, "identifier"),
      useBackgroundColor: parseInt(
        getDisplaySetting(component, "useBackgroundColor")
      ),
      backgroundColor: getDisplaySetting(component, "backgroundColor"),
      backgroundFactor1: parseFloat(
        getDisplaySetting(component, "backgroundFactor1")
      ),
      backgroundFactor2: parseFloat(
        getDisplaySetting(component, "backgroundFactor2")
      ),
      useFontColor: parseInt(getDisplaySetting(component, "useFontColor")),
      fontColor: getDisplaySetting(component, "fontColor"),
      fontFactor: parseFloat(getDisplaySetting(component, "fontFactor")),
      tooltipFontColor: getDisplaySetting(component, "tooltipFontColor"),
      useIcon: parseInt(getDisplaySetting(component, "useIcon")),
      iconId: parseInt(getDisplaySetting(component, "iconId")),
      animate: parseInt(getDisplaySetting(component, "animate")),
      showAsBar: parseInt(getDisplaySetting(component, "showAsBar")),
      showInTooltip: parseInt(getDisplaySetting(component, "showInTooltip")),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);

  return (
    <>
      <div className={"subLine"}>
        {t("Presentation")}: {t(component)}
      </div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {state.useIdentifier === 1 && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      value={state.identifier}
                      onChange={handleChange}
                      name="identifier"
                      label={t("Identifier")}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={8}></Grid>
                </>
              )}
              {state.useBackgroundColor === 1 && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      type="color"
                      name="backgroundColor"
                      label={t("Background")}
                      value={state.backgroundColor}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={8}></Grid>
                </>
              )}
              {state.useFontColor === 1 && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      type="color"
                      name="fontColor"
                      label={
                        state.useBackgroundColor === 1 ? t("Font") : t("Color")
                      }
                      value={state.fontColor}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={8}></Grid>
                </>
              )}
              {state.useIcon === 1 && (
                <>
                  <Grid item xs={3}>
                    <IconSelect
                      name="iconId"
                      value={state.iconId}
                      color={state.fontColor}
                      onChange={handleChange}
                    />
                  </Grid>
                  {component === "Today" && (
                    <Grid item xs={12}>
                      <SomuraSwitchField
                        name="animate"
                        checked={state.animate === 1}
                        onChange={handleSwitchChange}
                        label={t("Animate icon")}
                      />
                    </Grid>
                  )}
                </>
              )}
              {state.useBackgroundColor === 1 && state.useIdentifier === 1 && (
                <Grid item xs={12}>
                  <SomuraSwitchField
                    name="showAsBar"
                    checked={state.showAsBar === 1}
                    onChange={handleSwitchChange}
                    label={t("Show as bar")}
                  />
                </Grid>
              )}
              {state.useIdentifier === 1 && (
                <>
                  <Grid item xs={6}>
                    <SomuraSwitchField
                      name="showInTooltip"
                      checked={state.showInTooltip === 1}
                      onChange={handleSwitchChange}
                      label={t("Show in tooltips")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="color"
                      name="tooltipFontColor"
                      label={t("Font")}
                      value={state.tooltipFontColor}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.preview}>
            <ColorExample
              className={classes.colorExample}
              component={component}
              componentProps={state}
            />
            {state.showInTooltip === 1 && (
              <ToolTipExample
                className={classes.tooltipExample}
                component={component}
                componentProps={state}
              />
            )}
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <BackButton onClick={handleClose} />
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySubForm);
