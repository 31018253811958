import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  fetchUserTimes,
  addTime,
  updateTime,
  deleteTime,
  setSelectedDate,
  setSelectedTimeFormId,
} from "redux/index";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import SomuraDataGrid from "Components/SomuraDataGrid";
import Collapse from "@mui/material/Collapse";

import makeStyles from "@mui/styles/makeStyles";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraSlider from "Components/SomuraSlider";

import BackButton from "Components/Buttons/BackButton";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";
import {
  SecondsFromTimeDiff,
  secondsToTime,
  twoDigits,
} from "../../Components/Utilities";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  errorSpan: {
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "4px",
    marginRight: "4px",
    color: theme.palette.red,
  },
}));

const mapStateToProps = (state) => {
  return {
    times: state.times.UserTimes,
    persons: state.persons.PersonsMin,
    loggedUser: state.loggeduser.LoggedUser,
    selectedDate: state.times.selectedDate,
    selectedTimeFormId: state.times.selectedTimeFormId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserTimes: (values) => dispatch(fetchUserTimes(values)),
    addTime: (values) => dispatch(addTime(values)),
    updateTime: (values) => dispatch(updateTime(values)),
    deleteTime: (values) => dispatch(deleteTime(values)),
    setSelectedDate: (id) => dispatch(setSelectedDate(id)),
    setSelectedTimeFormId: (id) => dispatch(setSelectedTimeFormId(id)),
  };
};

const TimeTrackForm = ({
  userId,
  selectedDate,
  times,
  persons,
  loggedUser,
  fetchUserTimes,
  addTime,
  updateTime,
  deleteTime,
  setSelectedDate,
  selectedTimeFormId,
  setSelectedTimeFormId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const time = times?.find((T) => T.id === selectedTimeFormId);
  const person = persons.find((P) => P.id === userId);
  const formHeader =
    t("Time record for") +
    " " +
    person?.listName +
    " " +
    t("as of") +
    " " +
    moment(selectedDate).format("dddd, D. MMMM YYYY");

  const [valid, setValid] = useState(false);

  const emptyState = {
    id: "",
    userId: userId,
    date: moment().format("YYYY-MM-DD"),
    startTime: "",
    endTime: null,
    startHours: 0,
    startMinutes: 0,
    endHours: 0,
    endMinutes: 0,
    seconds: 0,
    duration: 0,
    isWorktime: true,
    isBreak: false,
    startFromMobile: false,
    endFromMobile: false,
    createdOn: moment().format("YYYY-MM-DD HH:mm:ss"),
    createdBy: loggedUser.id,
    modifiedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
    modifiedBy: loggedUser.id,
  };
  const [state, setState] = useState(emptyState);

  const TimeSchema = Yup.object({
    startTime: Yup.string().required(t("Required")),
    endTime: Yup.string().required(t("Required")),
  });

  const columns = [
    {
      headerName: "ID",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Start"),
      headerClassName: "tableHeader",
      field: "startTime",
      flex: 0.1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.row.isAutoBreak ? "" : params.value}</span>;
      },
    },
    {
      headerName: t("End"),
      headerClassName: "tableHeader",
      field: "endTime",
      flex: 0.1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.row.isAutoBreak ? "" : params.value}</span>;
      },
    },
    {
      headerName: t("Work time"),
      headerClassName: "tableHeader",
      field: "isWorktime",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.value ? (
                secondsToTime(params.row.seconds, false, true)
              ) : (
                <></>
              )}
            </span>
            {params.value &&
            (params.row.seconds <= 0 || params.row.minutes <= 0) ? (
              <span className={classes.errorSpan}>
                <HelpOutlineOutlinedIcon className={classes.errorIcon} />
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      headerName: t("Break"),
      headerClassName: "tableHeader",
      field: "isBreak",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.value ? (
                secondsToTime(params.row.seconds, false, true)
              ) : (
                <></>
              )}
            </span>
            {params.value &&
            (params.row.seconds <= 0 || params.row.minutes <= 0) ? (
              <span className={classes.errorSpan}>
                <HelpOutlineOutlinedIcon className={classes.errorIcon} />
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      headerName: t("Break deduction"),
      headerClassName: "tableHeader",
      field: "deduction",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.value ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {secondsToTime(params.row.seconds, false, true)}
                  <span style={{ marginLeft: "10px" }}>
                    <AutoFixHighIcon />
                  </span>
                </div>
              ) : (
                <></>
              )}
            </span>
          </>
        );
      },
    },
    {
      headerName: "Created",
      headerClassName: "tableHeader",
      field: "createdOn",
      hide: true,
      filterable: false,
    },
  ];

  const rows = times.map((TD) => ({
    id: TD.id,
    date: moment(TD.date).format("dd., L"),
    startTime: TD.startTime,
    endTime: TD.endTime ? TD.endTime : null,
    minutes: TD.minutes,
    seconds: TD.seconds,
    deduction: TD.isAutoBreak ? TD.seconds : 0,
    isWorktime: TD.isWorktime,
    isBreak: TD.isBreak,
    isAutoBreak: TD.isAutoBreak,
    createdOn: TD.createdOn,
  }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await TimeSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSubmit = () => {
    if (selectedTimeFormId === 0) {
      addTime(state);
    } else {
      updateTime(state);
    }
    handleCancel();
  };

  const handleDelete = () => {
    deleteTime(state);
    handleCancel();
  };

  const handleBack = () => {
    setSelectedDate("");
  };

  const handleCancel = () => {
    setSelectedTimeFormId(-1);
  };

  const handleChange = (event) => {
    const element = event.target.name;
    const value = event.target.value;
    var timeDiff;
    if (element === "startTime") {
      timeDiff = SecondsFromTimeDiff(value + ":00", state.endTime + ":00");
      setState({
        ...state,
        [event.target.name]: event.target.value,
        startHours: parseInt(value.substring(0, 2)),
        startMinutes: parseInt(value.substring(3, 5)),
        duration: secondsToTime(timeDiff),
      });
    } else if (element === "endTime") {
      timeDiff = SecondsFromTimeDiff(state.startTime + ":00", value + ":00");
      if (timeDiff > 0) {
        setState({
          ...state,
          [event.target.name]: event.target.value,
          endHours: parseInt(value.substring(0, 2)),
          endMinutes: parseInt(value.substring(3, 5)),
          duration: secondsToTime(timeDiff),
        });
      }
    } else {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        duration: secondsToTime(timeDiff),
      });
    }
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    if ((name === "isWorktime") & value) {
      setState({ ...state, [name]: value, isBreak: !value });
    }
    if ((name === "isBreak") & value) {
      setState({ ...state, [name]: value, isWorktime: !value });
    }
  };

  const handleStartHoursSliderChange = (event, newValue) => {
    const timeDiff = SecondsFromTimeDiff(
      twoDigits(newValue) + ":" + twoDigits(state.startMinutes) + ":00",
      state.endTime + ":00"
    );
    if (timeDiff > 0) {
      setState({
        ...state,
        startHours: newValue,
        startTime: twoDigits(newValue) + ":" + twoDigits(state.startMinutes),
        duration: secondsToTime(
          SecondsFromTimeDiff(
            twoDigits(newValue) + ":" + twoDigits(state.startMinutes) + ":00",
            state.endTime + ":00"
          ),
          false,
          true
        ),
      });
    }
  };
  const handleStartMinutesSliderChange = (event, newValue) => {
    setState({
      ...state,
      startMinutes: newValue,
      startTime: twoDigits(state.startHours) + ":" + twoDigits(newValue),
      duration: secondsToTime(
        SecondsFromTimeDiff(
          twoDigits(state.startHours) + ":" + twoDigits(newValue) + ":00",
          state.endTime + ":00"
        ),
        false,
        true
      ),
    });
  };

  const handleEndHoursSliderChange = (event, newValue) => {
    setState({
      ...state,
      endHours: newValue,
      endTime: twoDigits(newValue) + ":" + twoDigits(state.endMinutes),
      duration: secondsToTime(
        SecondsFromTimeDiff(
          twoDigits(newValue) + ":" + twoDigits(state.endMinutes) + ":00",
          state.startTime + ":00"
        ),
        false,
        true
      ),
    });
  };
  const handleEndMinutesSliderChange = (event, newValue) => {
    setState({
      ...state,
      endMinutes: newValue,
      endTime: twoDigits(state.endHours) + ":" + twoDigits(newValue),
      duration: secondsToTime(
        SecondsFromTimeDiff(
          twoDigits(state.endHours) + ":" + twoDigits(newValue) + ":00",
          state.startTime + ":00"
        ),
        false,
        true
      ),
    });
  };

  const handleRowClick = (params) => {
    if (selectedTimeFormId === -1) {
      if (!times.find((T) => T.id === params).isAutoBreak) {
        setSelectedTimeFormId(params);
      } else {
        setSelectedTimeFormId(-1);
      }
    }
  };

  useEffect(() => {
    if (selectedDate && userId) {
      fetchUserTimes({
        date: selectedDate,
        userId: userId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, userId]);

  useEffect(() => {
    if (selectedTimeFormId > 0) {
      setState({
        ...time,
        startTime: time?.startTime.substring(0, 5),
        startHours: parseInt(time?.startTime.substring(0, 2)),
        startMinutes: parseInt(time?.startTime.substring(3, 5)),
        endTime: time?.endTime ? time?.endTime.substring(0, 5) : "00:00",
        endHours: parseInt(time?.endTime ? time?.endTime.substring(0, 2) : 0),
        endMinutes: parseInt(time?.endTime ? time?.endTime.substring(3, 5) : 0),
        duration:
          time?.seconds > 0
            ? secondsToTime(time?.seconds).substring(0, 5)
            : "-",
        modifiedBy: loggedUser.id,
      });
    } else {
      setState(emptyState);
    }
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeFormId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <SomuraDataGrid
          tableHeight={"calc(100vh - 600px)"}
          rows={rows}
          columns={columns}
          defaultSortModel={[
            {
              field: "id",
              sort: "asc",
            },
          ]}
          isRowSelectable={() => selectedTimeFormId === -1}
          onRowClick={handleRowClick}
          csvFileName={t("Times")}
        />
        <Collapse in={selectedTimeFormId > 0} timeout={"auto"}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("Start")}</div>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <SomuraTextField
                    label={t("Time")}
                    type="text"
                    name="startTime"
                    required
                    value={state.startTime}
                    onChange={handleChange}
                    textAlign="center"
                  />
                </Grid>

                <Grid item xs={3}></Grid>
                <Grid item xs={12}>
                  <SomuraSlider
                    label={t("Hours")}
                    value={state.startHours}
                    onChange={handleStartHoursSliderChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => <div>{twoDigits(value)}</div>}
                    step={1}
                    min={0}
                    max={23}
                    color={state.isWorktime ? "#7cb342" : "#fb8c00"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SomuraSlider
                    label={t("Minutes")}
                    value={state.startMinutes}
                    onChange={handleStartMinutesSliderChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => <div>{twoDigits(value)}</div>}
                    step={1}
                    min={0}
                    max={59}
                    color={state.isWorktime ? "#7cb342" : "#fb8c00"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("End")}</div>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <SomuraTextField
                    type="text"
                    name="endTime"
                    label={t("Time")}
                    required
                    value={state.endTime}
                    onChange={handleChange}
                    textAlign="center"
                  />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={12}>
                  <SomuraSlider
                    label={t("Hours")}
                    value={state.endHours}
                    onChange={handleEndHoursSliderChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => <div>{twoDigits(value)}</div>}
                    step={1}
                    min={0}
                    max={23}
                    color={state.isWorktime ? "#7cb342" : "#fb8c00"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SomuraSlider
                    label={t("Minutes")}
                    value={state.endMinutes}
                    onChange={handleEndMinutesSliderChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => <div>{twoDigits(value)}</div>}
                    step={1}
                    min={0}
                    max={59}
                    color={state.isWorktime ? "#7cb342" : "#fb8c00"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("Duration")}</div>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <SomuraTextField
                    type="text"
                    name="duration"
                    disabled={true}
                    value={state.duration}
                    textAlign="center"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("Type")}</div>
                </Grid>
                <Grid item xs={12}>
                  <SomuraSwitchField
                    disabled={selectedTimeFormId > 0}
                    name="isWorktime"
                    checked={state.isWorktime}
                    onChange={handleSwitchChange}
                    label={t("Work time")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SomuraSwitchField
                    disabled={selectedTimeFormId > 0}
                    name="isBreak"
                    checked={state.isBreak}
                    onChange={handleSwitchChange}
                    label={t("Break")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        <div className="formBottom">
          {selectedTimeFormId === -1 && <BackButton onClick={handleBack} />}
          {selectedTimeFormId > 0 && (
            <>
              <CancelButton onClick={handleCancel} />
              <DeleteButton
                disabled={time ? false : true}
                onClick={handleDelete}
              />
              <SaveButton
                disabled={
                  !valid
                  // !valid ||
                  // timeToSeconds(state.startTime + ":00") >=
                  //   timeToSeconds(state.endTime + ":00")
                }
                onClick={handleSubmit}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackForm);
