import React, { useState } from "react";
import { store } from "redux/store";
import { renameMessageFolder } from "redux/index";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";
import SomuraTextField from "Components/SomuraTextField";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "10rem",
  },
  topScrollPaper: {
    height: "100vh",
    alignItems: "center",
  },
  topPaperScrollBody: {
    verticalAlign: "center",
  },
  title: {
    width: "15rem",
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    textAlign: "center",
    margin: "0 4px",
  },
  body: {
    height: "5rem",
    padding: "4px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  folderName: {
    fontSize: "1.2rem",
    color: theme.palette.text.screenTitle,
    textAlign: "center"
  },
  textField: {
    padding: "4px 10px"
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
}));

const mapStateToProps = (state) => {
  return {
    logged: state.loggeduser.LoggedUser.id,
    folder: state.messagefolders.MessageFolders.find(
      (F) => F.id === state.messagefolders.selectedFolderId
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    renameMessageFolder: (values) => dispatch(renameMessageFolder(values)),
  };
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ minHeight: "8rem", maxWidth: 700 }} />
    </Draggable>
  );
}

const RenameFolder = ({ logged, folder, close, renameMessageFolder }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [folderName, setFolderName] = useState(folder?.folderName);
  const title = () => {
    return <span className={classes.title}>{t("Rename folder")}</span>;
  };

  const body = () => {
    return (
      <>
        <div className={classes.folderName}>{folder?.folderName}</div>
        <div className={classes.textField}>
          <SomuraTextField
            type="text"
            variant="outlined"
            size="small"
            autoFocus={true}
            value={folderName}
            label={t("Folder name")}
            onChange={(event) => setFolderName(event.target.value)}
          />
        </div>
      </>
    );
  };

  const handleClose = () => {
    close();
  };

  const handleRenameFolder = () => {
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
    renameMessageFolder({ userId: logged, folderId: folder.id, folderName: folderName });
    close();
  };

  return (
    <Dialog
      classes={{
        root: classes.root,
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-dialog-title"
        dialogTitle={title()}
        closeForm={handleClose}
        width="25rem"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent className="dialogContent">
        <div>{body()}</div>
      </DialogContent>
      <DialogActions className="dialogActions">
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <SaveButton
          disabled={folderName === ""}
          onClick={() => {
            handleRenameFolder();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RenameFolder);
