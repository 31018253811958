import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setSelectedLocationId,
  createLocation,
  updateLocation,
} from "redux/index";

import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import makeStyles from "@mui/styles/makeStyles";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
}));

const mapStateToProps = (state) => {
  const locations = state.articles.Locations;
  const selectedId = state.articles.selectedLocationId;
  return { locations, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedId: (id) => dispatch(setSelectedLocationId(id)),
    create: (values) => dispatch(createLocation(values)),
    update: (values) => dispatch(updateLocation(values)),
  };
};

const LocationsForm = ({
  locations,
  selectedId,
  setSelectedId,
  create,
  update,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: "0",
    locationName: "",
    isFreeStock: false,
    isLocked: false,
    isRetired: false,
    parentId: "0",
  };
  const [state, setState] = useState(emptyState);
  const formHeader =
    selectedId > 0
      ? t("Location") + ": " + state.locationName
      : t("New location");
  var locationOptions = locations.filter(L => L.id !== selectedId).map(L => ({
    label: L.locationName,
    value: L.id
  }))
  locationOptions.unshift({ label: "", value: "0" });

  const handleClose = () => {
    setSelectedId(-1);
  };

  const handleSubmit = () => {
    if (selectedId === 0) {
      // Create new location
      create(state);
    } else {
      // Update location
      update(state);
    }
    handleClose();
  };

  const handleDelete = () => {
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (selectedId > 0) {
      setState(locations.find((MT) => MT.id === selectedId));
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SomuraTextField
              label={t("Location")}
              type="text"
              name="locationName"
              value={state.locationName}
              maxlength="50"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="isFreeStock"
              checked={state.isFreeStock}
              onChange={handleSwitchChange}
              label={t("Free stock")}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="isLocked"
              checked={state.isLocked}
              onChange={handleSwitchChange}
              label={t("Locked")}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="isRetired"
              checked={state.isRetired}
              onChange={handleSwitchChange}
              label={t("Retired")}
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="parentId"
              label={t("Belongs to")}
              options={locationOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.parentId}
              variant="outlined"
              size="small"
              fullWidth
              disabled={true}
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton onClick={handleCancel} />
        {selectedId > 0 && (
          <DeleteButton onClick={handleDelete} disabled={state.neverDelete} />
        )}
        <SaveButton onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsForm);
