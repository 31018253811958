import React, { useState, useEffect, useCallback } from "react";
import {
  updateOffDayType,
  addOffDayType,
  deleteOffDayType,
  setOffDayTypeId,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { languages } from "Constants";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import SomuraSwitchField from "Components/SomuraSwitchField";
import makeStyles from "@mui/styles/makeStyles";
import Collapse from "@mui/material/Collapse";

import SomuraTextField from "Components/SomuraTextField";
import SomuraSelectField from "Components/SomuraSelectField";

import OffDayNames from "./OffDayNames";
import OffDayTexts from "./OffDayTexts";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  formHeader: {
    textAlign: "center",
    borderBottom: "2px solid grey",
    padding: "4px",
    fontSize: "22px",
    minHeight: "35px",
    lineHeight: "25px",
    color: theme.palette.text.screenTitle,
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
  fieldset: {
    border: "none",
    padding: "20px 10px 0px 10px",
    margin: "0",
  },
  listItem: {
    width: "100%",
    height: "2.5rem",
    padding: "0 8px",
    marginBottom: "4px",
    fontSize: "1rem",
    backgroundColor: theme.palette.background.listItem,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "1fr 1rem",
    gridTemplateRows: "1fr",
    alignItems: "center",
  },
}));

const mapStateToProps = (state) => {
  const offDayTypeId = state.offdaytypes.offDayTypeId;
  const offDayType = state.offdaytypes.OffDayTypes.find(
    (OT) => OT.id === offDayTypeId
  );
  const formDisabled = !state.general.EditMode;
  return {
    offDayTypeId,
    offDayType,
    formDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOffDayType: (values) => dispatch(updateOffDayType(values)),
    addOffDayType: (values) => dispatch(addOffDayType(values)),
    deleteOffDayType: (values) => dispatch(deleteOffDayType(values)),
    setOffDayTypeId: (id) => dispatch(setOffDayTypeId(id)),
  };
};

const OffDayTypesForm = ({ offDayType, ...props }) => {
  const selectedOffDayType = offDayType;
  const language = localStorage.getItem("lang");
  const classes = useStyles();
  const { t } = useTranslation();
  const [valid, setValid] = useState(false);

  const OffDayTypesSchema = Yup.object().shape({
    language: Yup.string().when("dummy", {
      is: (value) => !offDayType,
      then: Yup.string().required("Pflichtfeld"),
    }),
    offDayType: Yup.string().when("dummy", {
      is: (value) => !offDayType,
      then: Yup.string().required("Pflichtfeld"),
    }),
    offDayKey: Yup.string().when("dummy", {
      is: (value) => !offDayType,
      then: Yup.string().required("Pflichtfeld"),
    }),
  });

  const [typeNames, setTypeNames] = useState(selectedOffDayType?.names);
  const emptyState = selectedOffDayType
    ? {
        ...selectedOffDayType,
        offDayType: typeNames
          ? typeNames.find((OTN) => OTN.language === language).offDayType
          : "",
        offDayKey: typeNames
          ? typeNames.find((OTN) => OTN.language === language).offDayKey
          : "",
      }
    : {
        id: 0,
        language: "",
        offDayType: "",
        offDayKey: "",
        text: "",
        offDayFactor: 0,
        isOffDay: true,
        mustBeApproved: false,
        mayBePlaned: false,
        mustReport: true,
        notForUser: false,
        notDeleteable: false,
        isDisease: false,
        isVacation: false,
        isBusinessTrip: false,
        onlyOneDay: false,
        requireAdditionalText: false,
        numUser: 0.0,
      };
  const [state, setState] = useState(emptyState);
  const [openTypeInput, setOpenTypeInput] = useState(false);
  const [openTextInput, setOpenTextInput] = useState(false);
  const languageOptions = languages.map((L) => ({
    label: L.languageLongName,
    value: L.languageName,
  }));
  const formHeader =
    props.displayName !== ""
      ? t("Absence type") + ": " + props.displayName
      : t("New absence type");

  const numAdditionalTexts = selectedOffDayType?.additionalTexts?.length;
  const numDesignations = selectedOffDayType?.names?.length;

  const handleSubmit = () => {
    if (state.id === 0) {
      props.addOffDayType({
        ...state,
        names: [
          {
            language: state.language,
            offDayType: state.offDayType,
            offDayKey: state.offDayKey,
          },
        ],
      });
    } else {
      props.updateOffDayType({ ...state, names: typeNames });
    }
    props.setOffDayTypeId(0);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
    if ((name === "mustReport") & value) {
      setState({ ...state, [name]: value, mustBeApproved: false });
    }
    if ((name === "mustBeApproved") & value) {
      setState({ ...state, [name]: value, mustReport: false });
    }
    if ((name === "isVacation") & value) {
      setState({
        ...state,
        [name]: value,
        isDisease: false,
        isBusinessTrip: false,
      });
    }
    if ((name === "isDisease") & value) {
      setState({
        ...state,
        [name]: value,
        isVacation: false,
        isBusinessTrip: false,
      });
    }
    if ((name === "isBusinessTrip") & value) {
      setState({
        ...state,
        [name]: value,
        isDisease: false,
        isVacation: false,
      });
    }
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "cancel":
        props.setOffDayTypeId(-1);
        break;
      case "delete":
        props.deleteOffDayType(selectedOffDayType);
        props.setOffDayTypeId(-1);
        break;
      default:
        props.setOffDayTypeId(-1);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await OffDayTypesSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (offDayType?.names.length > 0) {
      setTypeNames(offDayType.names);
    } else {
      setTypeNames(
        languages &&
          languages.map((L) => ({
            offDayTypeId: "0",
            offDayType: "",
            offDayKey: "",
            language: L.languageName,
          }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offDayType]);

  useEffect(() => {
    if (selectedOffDayType) {
      setState({
        ...selectedOffDayType,
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffDayType]);

  return (
    <>
      <Collapse
        in={openTypeInput === false && openTextInput === false}
        timeout={"auto"}
      >
        <div>
          <div className={classes.formHeader}>{formHeader}</div>

          <div className={classes.thisForm}>
            <Grid container spacing={2}>
              {offDayType ? (
                <>
                  <Grid item xs={6} lg={4}>
                    <div
                      className={classes.listItem}
                      onClick={() => {
                        setOpenTypeInput(true);
                      }}
                    >
                      <div className={classes.listItemText}>
                        {t("Designations and identifiers")}
                        {numDesignations > 0
                          ? " (" + numDesignations + ")"
                          : ""}
                      </div>
                      <ChevronRightIcon className={classes.listIcon} />
                    </div>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2} className={classes.language}>
                    <SomuraSelectField
                      name="language"
                      label={t("Language")}
                      value={state.language}
                      options={languageOptions}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SomuraTextField
                      type="text"
                      name="offDayType"
                      label={t("Designation")}
                      value={state.offDayType}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SomuraTextField
                      type="text"
                      name="offDayKey"
                      label={t("Identifier")}
                      value={state.offDayKey}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                </>
              )}
              {!offDayType ? (
                <Grid item xs={6}>
                  <SomuraTextField
                    type="text"
                    name="text"
                    label={t("Additional text")}
                    value={state.text}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              ) : (
                <Grid item xs={6} lg={4}>
                  <div
                    className={classes.listItem}
                    onClick={() => {
                      setOpenTextInput(true);
                    }}
                  >
                    <div className={classes.listItemText}>
                      {t("Additional texts")}
                      {numAdditionalTexts > 0
                        ? " (" + numAdditionalTexts + ")"
                        : ""}
                    </div>
                    <ChevronRightIcon className={classes.listIcon} />
                  </div>
                </Grid>
              )}
              {numAdditionalTexts > 0 && (
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    name="requireAdditionalText"
                    checked={state.requireAdditionalText}
                    onChange={handleSwitchChange}
                    label={t("Text has to be selected")}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="isOffDay"
                  checked={state.isOffDay}
                  onChange={handleSwitchChange}
                  label={t("Absence")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="isVacation"
                  checked={state.isVacation}
                  onChange={handleSwitchChange}
                  label={t("Count as vacation days")}
                />
              </Grid>
              <Grid item xs={2} lg={2}>
                {state.isVacation === true && (
                  <SomuraTextField
                    type="number"
                    name="offDayFactor"
                    label={t("Vacation days")}
                    min="0.0"
                    max="1.0"
                    step="0.1"
                    value={state.offDayFactor}
                    onChange={handleChange}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="isDisease"
                  checked={state.isDisease}
                  onChange={handleSwitchChange}
                  label={t("Count as sick days")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="isBusinessTrip"
                  checked={state.isBusinessTrip}
                  onChange={handleSwitchChange}
                  label={t("Count as business trip")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="mustBeApproved"
                  checked={state.mustBeApproved}
                  onChange={handleSwitchChange}
                  label={t("Approvement necessary")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="mustReport"
                  checked={state.mustReport}
                  onChange={handleSwitchChange}
                  label={t("Only report required")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="onlyOneDay"
                  checked={state.onlyOneDay}
                  onChange={handleSwitchChange}
                  label={t("Only for single days")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="mayBePlaned"
                  checked={state.mayBePlaned}
                  onChange={handleSwitchChange}
                  label={t("Plannable by user")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <SomuraSwitchField
                  name="notForUser"
                  checked={state.notForUser}
                  onChange={handleSwitchChange}
                  label={t("Not reportable by user")}
                />
              </Grid>
            </Grid>
          </div>
          <div className="paperBottom">
            <CancelButton
              onClick={() => {
                handleButtonClick("cancel");
              }}
            />
            {selectedOffDayType && (
              <DeleteButton
                disabled={state.notDeleteable || state.numUser > 0}
                onClick={() => {
                  handleButtonClick("delete");
                }}
              />
            )}
            <SaveButton disabled={!valid} onClick={handleSubmit} />
          </div>
        </div>
      </Collapse>
      <Collapse in={openTypeInput === true} timeout={"auto"}>
        <OffDayNames
          displayName={props.displayName}
          onClose={() => {
            setOpenTypeInput(false);
          }}
        />
      </Collapse>
      <Collapse in={openTextInput === true} timeout={"auto"}>
        <OffDayTexts
          displayName={props.displayName}
          onClose={() => {
            setOpenTextInput(false);
          }}
        />
      </Collapse>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OffDayTypesForm);
