import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { sortByDate } from "Components/Utilities";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { setEditMode, setSelectedReminder, fetchReminderTimes } from "redux/index";
import Paper from "@mui/material/Paper";
import ScreenTitle from "Components/ScreenTitle";
import Collapse from "@mui/material/Collapse";
import SomuraDataGrid from "Components/SomuraDataGrid";
import RemindersForm from "./RemindersForm";
import Fab from "@mui/material/Fab";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& .tableHeader": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.background.contrastText,
    },
  },
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
}));

const mapStateToProps = (state) => {
  return {
    editMode: state.general.EditMode,
    reminders: state.reminders.Reminders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedReminder: (id) => dispatch(setSelectedReminder(id)),
    setEditMode: (editMode) => dispatch(setEditMode(editMode)),
    fetchReminderTimes: () => dispatch(fetchReminderTimes()),
  };
};

const Reminders = ({
  editMode,
  reminders,
  setEditMode,
  setSelectedReminder,
  fetchReminderTimes,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: t("Titel"),
      headerClassName: "tableHeader",
      field: "title",
      flex: 0.2,
    },
    {
      headerName: t("Start"),
      headerClassName: "tableHeader",
      field: "startDateTime",
      type: "date",
      sortComparator: (a, b) => sortByDate(a, b),
      flex: 0.2,
    },
    {
      headerName: t("End"),
      headerClassName: "tableHeader",
      field: "endDateTime",
      type: "date",
      sortComparator: (a, b) => sortByDate(a, b),
      flex: 0.2,
    },
    {
      headerName: t("All-day"),
      headerClassName: "tableHeader",
      field: "isWholeDay",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <span>{params.value ? <CheckCircleIcon /> : <></>}</span>;
      },
      flex: 0.2,
    },
  ];

  const rows = reminders.map((R, index) => ({
    key: index,
    id: R.id,
    title: R.title,
    startDateTime: moment(R.startDateTime, "YYYY-MM-DD").isValid()
      ? R.isWholeDay
        ? moment(R.startDateTime).format("L")
        : moment(R.startDateTime).format("L LT")
      : "",
    endDateTime: moment(R.endDateTime, "YYYY-MM-DD HH:mm:ss").isValid()
      ? R.isWholeDay
        ? moment(R.endDateTime).format("L")
        : moment(R.endDateTime).format("L LT")
      : "",
    isWholeDay: R.isWholeDay,
  }));

  const handleRowClick = (params) => {
    setSelectedReminder(params);
    setEditMode(true);
  };

  const handleAdd = () => {
    setSelectedReminder(0);
    setEditMode(true);
  };

  const handleFormClose = () => {
    setSelectedReminder(-2);
    setEditMode(false);
  };

    useEffect(() => {
      fetchReminderTimes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  return (
    <div className="content">
      <Paper square elevation={8}>
        <ScreenTitle
          title="Reminders"
          class="masterdata"
          icon={
            <NotificationsNoneTwoToneIcon
              viewBox="0 0 24 24"
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <Collapse in={editMode === false} timeout={"auto"}>
          <SomuraDataGrid
            tableHeight="65vh"
            rows={rows}
            columns={columns}
            defaultSortModel={[
              {
                field: "startDateTime",
                sort: "desc",
              },
            ]}
            // filterModel={{
            //   items: [
            //     {
            //       columnField: "startDateTime",
            //       operatorValue: "onOrAfter",
            //       value: moment().format("YYYY-MM-DD HH:mm:ss"),
            //     },
            //   ],
            // }}
            onRowClick={(params) => handleRowClick(params)}
            csvFileName={t("Reminders")}
          />
          <Fab
            variant="extended"
            color="primary"
            size="medium"
            className={classes.fab}
            onClick={handleAdd}
          >
            <AddIcon />
            {t("New reminder")}
          </Fab>
        </Collapse>
        <Collapse in={editMode} timeout={"auto"}>
          <RemindersForm handleClose={handleFormClose} />
        </Collapse>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Reminders);
