import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setRead,
  fetchMessageFolders,
  setRecipientMessageFolder,
  setSenderMessageFolder,
  addMessage,
  answerMessage,
  sendMessage,
  deleteMessage,
  saveDraftMessage,
  deleteDraftMessage,
} from "redux/index";
import { store } from "redux/store";
import makeStyles from "@mui/styles/makeStyles";
import SomuraTooltip from "Components/SomuraTooltip";
import IconButton from "@mui/material/IconButton";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "2.5rem",
    marginBottom: "10px",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    width: "2rem",
    height: "2rem",
    marginLeft: "1rem",
  },
  buttonIcon: {
    width: "1.5rem",
    height: "1.5rem",
  },
  paper: {
    borderRadius: "4px",
  },
}));

const mapStateToProps = (state) => {
  return {
    logged: state.loggeduser.LoggedUser.id,
    messages: state.messages.Messages,
    messageId: state.messages.selectedMessageId,
    messageSendable: state.messages.messageSendable,
    draftMessage: state.messages.draftMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRead: (values) => dispatch(setRead(values)),
    fetchMessageFolders: (userId) => dispatch(fetchMessageFolders(userId)),
    setRecipientMessageFolder: (values) =>
      dispatch(setRecipientMessageFolder(values)),
    setSenderMessageFolder: (values) =>
      dispatch(setSenderMessageFolder(values)),
    addMessage: (userId) => dispatch(addMessage(userId)),
    answerMessage: (id) => dispatch(answerMessage(id)),
    sendMessage: (id) => dispatch(sendMessage(id)),
    deleteMessage: (id) => dispatch(deleteMessage(id)),
    saveDraftMessage: () => dispatch(saveDraftMessage()),
    deleteDraftMessage: () => dispatch(deleteDraftMessage()),
  };
};

const MessageButtonBar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const showMessage =
    props.messages.length > 0
      ? props.messages.find((M) => M.id === props.messageId)
      : null;
  let canBeAnswered = true;
  if (showMessage && showMessage.fromUserId === props.logged) {
    // Draft
    canBeAnswered = false;
  }

  const handleClose = () => {
    if (
      props.messages
        .find((M) => M.id === props.messageId)
        ?.recipients?.find((R) => R.recipientId === props.logged)?.readTime ===
      null
    ) {
      props.setRead({
        messageId: props.messageId,
        recipientId: props.logged,
      });
    }
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
  };

  const handleDelete = () => {
    switch (showMessage.senderFolderId) {
      case "-1":
        // Permanently delete message
        props.setSenderMessageFolder({
          id: props.messageId,
          senderFolderId: "-3",
        });
        break;
      case "-2":
        props.setSenderMessageFolder({
          id: props.messageId,
          senderFolderId: "-1",
        });
        break;
      case "-4":
        // Delete the draft
        props.deleteDraftMessage();
        // Move message to tray
        props.setSenderMessageFolder({
          id: props.messageId,
          senderFolderId: "-1",
        });
        break;
      default:
        props.setRecipientMessageFolder({
          id: props.messageId,
          recipientId: props.logged,
          recipientFolderId: "-1",
        });
    }
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
  };

  const handleAnswer = () => {
    props.answerMessage({ id: props.messageId, fromUserId: props.logged });
  };

  const handleSend = () => {
    if (props.draftMessage.id) {
      props.saveDraftMessage();
      // Send the message
      props.sendMessage(props.draftMessage.id);
      // Delete the message
      props.deleteDraftMessage();
    }
    store.dispatch({ type: "SET_SELECTED_MESSAGE_ID", payload: "0" });
  };

  return (
    // <Paper square elevation={8} className={classes.paper}>
    <div className={classes.root}>
      <SomuraTooltip title={t("Answer")} placement="top">
        <span>
          <IconButton
            className={classes.button}
            onClick={handleAnswer}
            disabled={
              showMessage?.fromUserId === "0" ||
              !canBeAnswered ||
              showMessage?.senderFolderId === "-4"
            }
          >
            <ReplyOutlinedIcon className={classes.buttonIcon} />
          </IconButton>
        </span>
      </SomuraTooltip>
      <SomuraTooltip title={t("Delete")} placement="top">
        <span>
          <IconButton
            className={classes.button}
            onClick={handleDelete}
            disabled={showMessage?.recipientFolderId === "-1"}
          >
            <DeleteOutlineOutlinedIcon className={classes.buttonIcon} />
          </IconButton>
        </span>
      </SomuraTooltip>
      <SomuraTooltip title={t("Send")} placement="top">
        <span>
          <IconButton
            className={classes.button}
            onClick={handleSend}
            disabled={props.messageSendable === false}
          >
            <SendIcon className={classes.buttonIcon} />
          </IconButton>
        </span>
      </SomuraTooltip>
      <SomuraTooltip title={t("Close")} placement="top">
        <IconButton className={classes.button} onClick={handleClose}>
          <ClearIcon className={classes.buttonIcon} />
        </IconButton>
      </SomuraTooltip>
      {/* {showMessage && (
          <div className={classes.buttonsRight}>
            {showMessage.fromUserId !== "0" &&
              canBeAnswered &&
              showMessage.senderFolderId !== "-4" && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<ReplyOutlinedIcon />}
                  disabled={showMessage.fromUserId === "0"}
                  onClick={handleAnswer}
                >
                  {t("Answer")}
                </Button>
              )}
            {showMessage.recipientFolderId === "-1" && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DeleteForeverOutlinedIcon />}
                disabled={false}
                onClick={handleRemove}
              >
                {t("Remove")}
              </Button>
            )}
            {showMessage.recipientFolderId !== "-1" &&
              showMessage.senderFolderId !== "-4" && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  disabled={false}
                  onClick={handleDelete}
                >
                  {t("Delete")}
                </Button>
              )}
            {showMessage.senderFolderId === "-4" && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<DeleteForeverOutlinedIcon />}
                onClick={handleThrowAway}
              >
                {t("Discard")}
              </Button>
            )}
            {showMessage.senderFolderId === "-4" && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<SendIcon />}
                disabled={!props.messageSendable}
                onClick={handleSend}
              >
                {t("Send")}
              </Button>
            )}
          </div>
        )} */}
    </div>
    // </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageButtonBar);
