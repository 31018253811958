import React from "react";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  item: {
    width: "100%",
    height: "2.5rem",
    padding: "0 8px",
    marginBottom: "4px",
    backgroundColor: theme.palette.background.listItem,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "2rem 1fr 1rem",
    gridTemplateRows: "1fr",
    alignItems: "center",
  },
  itemCheckbox: {
    gridColumn: 1
  },
  itemText: {
    gridColumn: 2,
    display: "flex",
    alignItems: "center",
  },
  listIcon: {
    gridColumn: "3",
  },
}));

const SomuraCheckListItem = (props) => {
  const classes = useStyles();

  const handleClick = () => {
    props.onClick(props.value)
  }

  const handleCheckboxClick = () => {
    props.onCheckboxClick(props.value)
  }

  return (
    <Paper square elevation={8} className={classes.item}>
      <Checkbox
        color="secondary"
        className={classes.itemCheckbox}
        checked={props.checked}
        indeterminate={props.indeterminate}
        onChange={handleCheckboxClick}
      />
      <div className={classes.itemText} onClick={props.hideChevron ? handleCheckboxClick : handleClick}>
        {props.itemText}
      </div>
      {!props.hideChevron && <ChevronRightIcon className={classes.listIcon} />}
    </Paper>
  );
};

export default SomuraCheckListItem;
