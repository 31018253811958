import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import {
  hexToRGB,
  getGlobalSetting,
  getDisplaySetting,
} from "Components/Utilities";
import moment from "moment";
import "../Calendars.scss";
import { icons } from "Constants";
import HeadlineDayFrame from "./HeadlineDayFrame";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(32, 1fr)",
    width: "100%",
    fontSize: "0.8rem",
    height: "3.5vh",
    marginBottom: "2px",
  },
  filler: {
    width: "15vw",
  },
  headlineCell: {
    fontSize: "0.8rem",
    backgroundColor: theme.palette.background.paperBottom,
    height: "3.5vh",
    alignSelf: "center",
    // paddingLeft: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const mapStateToProps = (state) => {
  return {
    monthStatic: state.month.MonthStatic,
    settings: state.settings.PersonalSettings?.find(
      (GS) => GS.component === "calendars"
    )?.settings,
  };
};

function MonthCalHeadline({
  monthStatic,
  settings,
  workOnSaturday,
  workdayColor,
  weekendColor,
  todayColor,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const today = moment();

  // Get personal settings
  const useCalendarTooltips = parseInt(settings?.useCalendarTooltips) === 1;
  const useSpecialDayTooltips = parseInt(settings?.useSpecialDayTooltips) === 1;
  const showCalendarweeks = parseInt(settings?.showCalendarweeks) === 1;
  const showRemarkableDays = parseInt(settings?.showRemarkableDays) === 1;

  const headline = () => {
    var days = [];
    monthStatic?.forEach(function (E) {
      var currentDay = moment(E.date);
      // defaults
      var segmentcolor = new Array(8);
      for (var i = 0; i < segmentcolor.length; i++) {
        segmentcolor[i] = ["", true];
      }
      var nextSegment = 0;
      var max = 0;
      var tooltipTexts = [];
      var showTooltip = !useSpecialDayTooltips;
      var fontcolor = getDisplaySetting("Working days", "fontColor");
      var backcolor = getDisplaySetting("Working days", "backgroundColor");
      var iconPath;
      var iconColor;
      var iconAnimated;
      var isWeekend = false;
      var week = "";
      var isBirthday = false;
      var birthdayIconPath;
      var birthdayIconColor;
      var specialDayKey;
      var isHalfDay;

      // eslint-disable-next-line
      var staticState = monthStatic.find((MD) => MD.date === E.date);
      // Weekend?
      if (
        (currentDay.format("d") === "6" && !workOnSaturday) ||
        currentDay.format("d") === "0"
      ) {
        // for (var j = 0; j < 8; j++) {
        //   segmentcolor[j][0] = weekendColor;
        // }
        backcolor = weekendColor;
      }
      // Calendarweeks
      if (
        showCalendarweeks &&
        (currentDay.format("d") ===
          getGlobalSetting("system", "firstDayOfWeek") ||
          currentDay.format("D") === "1")
      ) {
        week = currentDay.format("w");
      }
      // Today?
      var isToday = currentDay.isSame(today, "day");
      if (isToday) {
        iconPath =
          // eslint-disable-next-line
          icons.find(
            (I) =>
              parseInt(I.id) === parseInt(getDisplaySetting("Today", "iconId"))
          )?.path;
        iconAnimated = parseInt(getDisplaySetting("Today", "animate")) === 1;
        iconColor = getDisplaySetting("Today", "fontColor");
        for (var j = 0; j < 8; j++) {
          segmentcolor[j][0] = hexToRGB(todayColor, 0.3);
        }
      }

      // if (staticState) {
      // Birthdays
      isBirthday = E.birthdays > 0;
      if (isBirthday) {
        birthdayIconPath =
          // eslint-disable-next-line
          icons.find(
            (I) =>
              parseInt(I.id) ===
              parseInt(getDisplaySetting("Birthdays", "iconId"))
          )?.path;
        birthdayIconColor = getDisplaySetting("Birthdays", "fontColor");
        for (var b = 0; b < E.birthdayNames.length; b++) {
          tooltipTexts.push({
            text: t("Birthday") + ": " + E.birthdayNames[b].name,
            backColor: "",
            fontColor: getDisplaySetting("Birthdays", "tooltipFontColor"),
          });
        }
        showTooltip = useCalendarTooltips;
      }

      // School holidays
      if (staticState["holiday"] !== "" && settings?.showHolidays) {
        max =
          parseInt(getDisplaySetting("School holidays", "showAsBar")) === 1
            ? 2
            : 8;

        for (j = 0; j < max; j++) {
          segmentcolor[j][0] = getDisplaySetting(
            "School holidays",
            "backgroundColor"
          );
        }
        nextSegment = max === 2 ? 2 : 0;
        fontcolor = getDisplaySetting("School holidays", "fontColor");
        if (
          parseInt(getDisplaySetting("School holidays", "showInTooltip")) === 1
        ) {
          tooltipTexts.push({
            text: staticState["holiday"],
            backColor: getDisplaySetting("School holidays", "backgroundColor"),
            fontColor: getDisplaySetting("School holidays", "tooltipFontColor"),
          });
          showTooltip = useCalendarTooltips;
        }
      }

      // Remarkable day?
      if (
        staticState["isRemarkableDay"] &&
        staticState["useRemarkableDay"] &&
        showRemarkableDays
      ) {
        specialDayKey =
          specialDayKey === "" ? settings.remarkableDayKey : specialDayKey;
        if (useCalendarTooltips && useSpecialDayTooltips) {
          showTooltip = true;
        }
        max =
          parseInt(getDisplaySetting("Special days", "showAsBar")) === 1
            ? nextSegment + 2
            : 8;
        for (j = nextSegment; j < max; j++) {
          segmentcolor[j][0] = getDisplaySetting(
            "Special days",
            "backgroundColor"
          );
        }
        nextSegment = max;
        if (
          parseInt(getDisplaySetting("Special days", "showInTooltip")) === 1
        ) {
          tooltipTexts.push({
            text: staticState["remarkableDayName"],
            backColor: getDisplaySetting("Special days", "backgroundColor"),
            fontColor: getDisplaySetting("Special days", "tooltipFontColor"),
          });
        }
        specialDayKey = getDisplaySetting("Special days", "identifier");
        fontcolor = getDisplaySetting("Special days", "fontColor");
      }

      // Holidays (Special days)
      if (
        staticState["isSpecialDay"] &&
        (staticState["useSpecialDay"] || staticState["alwaysUseThisDay"])
      ) {
        specialDayKey = settings?.specialDayKey;
        isHalfDay = parseFloat(staticState["dayFactor"]) > 0;
        if (useCalendarTooltips && useSpecialDayTooltips) {
          showTooltip = true;
        }
        max =
          parseInt(getDisplaySetting("Holidays", "showAsBar")) === 1
            ? nextSegment + (isHalfDay ? 1 : 2)
            : 8;
        for (j = nextSegment; j < max; j += isHalfDay ? 2 : 1) {
          segmentcolor[j][0] = getDisplaySetting("Holidays", "backgroundColor");
        }
        nextSegment = max;
        if (parseInt(getDisplaySetting("Holidays", "showInTooltip")) === 1) {
          tooltipTexts.push({
            text: staticState["specialDayName"],
            backColor: getDisplaySetting("Holidays", "backgroundColor"),
            fontColor: getDisplaySetting("Holidays", "tooltipFontColor"),
          });
        }
        specialDayKey = getDisplaySetting("Holidays", "identifier");
        fontcolor = getDisplaySetting("Holidays", "fontColor");
      }
      // }

      days.push(
        <th className={classes.headlineCell} key={"H" + currentDay.format("D")}>
          <HeadlineDayFrame
            id={currentDay.format("D")}
            key={currentDay.format("D")}
            date={currentDay.format("YYYY-MM-DD")}
            backColor={backcolor}
            today={isToday}
            todayColor={todayColor}
            todayIcon={iconPath}
            todayAnimated={iconAnimated}
            isbirthday={isBirthday}
            birthdayIcon={birthdayIconPath}
            birthdayColor={birthdayIconColor}
            tooltipTexts={tooltipTexts}
            specialdaykey={specialDayKey}
            textColor={fontcolor}
            segmentColor={segmentcolor}
            markercolor={iconColor}
            showtooltip={showTooltip}
            weekend={isWeekend}
            week={week}
          />
        </th>
      );
    });
    return days;
  };

  return (
    <tr className={classes.root}>
      <td className={classes.filler}></td>
      {headline()}
    </tr>
  );
}

export default connect(mapStateToProps, null)(MonthCalHeadline);
