import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { checkUsername } from "redux/index";
import { makeStyles } from "@mui/styles";
import { Grid, Button } from "@mui/material";
import SomuraTextField from "Components/SomuraTextField";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    textAlign: "center",
    paddingBottom: "0",
    width: "100%",
  },
  username: {
    fontSize: "1rem",
    textAlign: "center",
    paddingTop: "0",
    width: "100%",
  },
  signInButton: {
    width: "100%",
    margin: "20px 0 !important",
  },
}));

const mapStateToProps = (state) => {
  return {
    loginState: state.loggeduser.LoginState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUsermail: (mail) => dispatch(checkUsername(mail)),
  };
};

const Username = ({ loginState, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lastLoginName = localStorage.getItem("lastSomuraLogin");

  const [state, setState] = useState({
    username:
      lastLoginName && lastLoginName !== "undefined" ? lastLoginName : "",
  });

  const handleSubmit = (event) => {
    props.checkUsermail(state);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setState({
      username:
        lastLoginName && lastLoginName !== "undefined" ? lastLoginName : "",
    });
  }, [lastLoginName]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  return (
    <div className={classes.loginFrame}>
      {/* <form onSubmit={handleSubmit}> */}
        <Grid container spacing={3}>
          <div className={classes.title}>{t("Login or register")}</div>
          <div className={classes.username}>
            {t("Please enter your mail address")}
          </div>
          <Grid item xs={12} lg={12}>
            <SomuraTextField
              autoFocus
              type="email"
              name="username"
              autocomplete="username"
              value={state.username}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Button
              className={classes.signInButton}
              disabled={state.username.length === 0}
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              {t("Continue")}
            </Button>
          </Grid>
        </Grid>
      {/* </form> */}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Username);
