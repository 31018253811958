import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import MouseIcon from "@mui/icons-material/Mouse";
import EastIcon from "@mui/icons-material/East";

const useStyles = makeStyles((theme) => ({
  noData: {
    color: "#243573",
    fontSize: "1rem",
    width: "100%",
    display: "inline-flex",
    justifyContent: "center",
  },
  infoWrapper: {
    fontSize: "1rem",
    width: "100%",
    display: "inline-flex",
    justifyContent: "center",
  },
  toolTipTitle: {
    color: "#243573",
    fontSize: "1rem",
    display: "inline-flex",
    alignItems: "center",
    marginRight: "4px",
  },
  userName: {
    color: "#243573",
    fontSize: "1rem",
    display: "inline-flex",
    alignItems: "center",
    marginRight: "4px",
  },
  icon: {
    color: "black",
    display: "inline-flex",
    alignItems: "center",
  },
  subLine: {
    fontSize: "1rem",
    margin: "1px 8px",
    padding: "0px 8px",
    borderRadius: "0.75rem",
    display: "inline-block",
  },
  additionalLine: {
    fontSize: "0.6rem",
    lineHeight: "0.6rem",
    paddingBottom: "2px",
    display: "inline-block",
  },
}));

const mapStateToProps = (state) => {
  return {
    dayInfo: state.month.dayInfo,
    year: state.general.DisplayYear
  };
};

const DayInfo = ({ dayInfo, year }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  var noData = "";
  var renderTitle = "";
  if (dayInfo.date) {
    renderTitle = moment(dayInfo.date).format("dddd, LL");
    if (moment().format("YYYY-MM-DD") === dayInfo.date) {
      renderTitle = t("Today") + ", " + renderTitle;
    }
  }
  if (dayInfo.startDate) {
    var renderStartDate, renderEndDate;
    if (dayInfo.startDate !== dayInfo.endDate) {
      if (moment(dayInfo.startDate).isBefore(moment(dayInfo.endDate))) {
        renderStartDate = moment(dayInfo.startDate).format("dddd, LL");
        renderEndDate = moment(dayInfo.endDate).format("dddd, LL");
      } else {
        renderStartDate = moment(dayInfo.endDate).format("dddd, LL");
        renderEndDate = moment(dayInfo.startDate).format("dddd, LL");
      }
    } else {
      renderStartDate = moment(dayInfo.startDate).format("dddd, LL");
      renderEndDate = "";
    }
  }
  if (!dayInfo.date && !dayInfo.startDate) {
    noData = year;
  }
  var sublineItem;
  var sublines = [];
  var personalSublines = [];
  for (var i = 0; i < dayInfo.tooltipTexts?.length; i++) {
    sublineItem = (
      <div
        className={classes.subLine}
        key={i}
        style={{
          color: dayInfo.tooltipTexts[i].fontColor,
          backgroundColor: dayInfo.tooltipTexts[i].backColor,
        }}
      >
        {dayInfo.tooltipTexts[i].text}
        <div className={classes.additionalLine}>
          {dayInfo.tooltipTexts[i].additionalText}
        </div>
      </div>
    );
    if (dayInfo.tooltipTexts[i].personal) {
      personalSublines.push(sublineItem);
    } else {
      sublines.push(sublineItem);
    }
  }
  return noData !== "" ? (
    <div className={classes.noData}>
      <span className={classes.icon}>
        <KeyboardDoubleArrowLeftIcon />
      </span>
      {noData}
      <span className={classes.icon}>
        <KeyboardDoubleArrowRightIcon />
      </span>
    </div>
  ) : (
    <div className={classes.infoWrapper}>
      <div className={classes.userName}>
        {dayInfo.user?.length > 0 && (
          <span className={classes.icon}>
            <PersonIcon />
          </span>
        )}
        {dayInfo.user}
      </div>
      <span>{personalSublines}</span>
      {dayInfo.startDate ? (
        <div className={classes.toolTipTitle}>
          <span className={classes.icon}>
            <MouseIcon />
          </span>
          {renderStartDate}
          {renderEndDate !== "" && (
            <>
              <span className={classes.icon}>
                <EastIcon />
              </span>
              <span>{renderEndDate}</span>
            </>
          )}
        </div>
      ) : (
        <div className={classes.toolTipTitle}>
          {renderTitle !== "" && (
            <span className={classes.icon}>
              <EventIcon />
            </span>
          )}
          {renderTitle}
        </div>
      )}
      {sublines}
    </div>
  );
};

export default connect(mapStateToProps)(DayInfo);
