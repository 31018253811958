import {
  FETCH_ENTITLEMENTS_REQUEST,
  FETCH_ENTITLEMENTS_SUCCESS,  
  SET_ENTITLEMENT_ID,
  PERSON_ENTITLEMENTS_FAILURE,
  UPDATE_PERSON_ENTITLEMENT,
  ADD_PERSON_ENTITLEMENT,
  DELETE_PERSON_ENTITLEMENT,
  RESET_ENTITLEMENT
} from 'actions/types'

import { entitlements, entitlementAdd, entitlementUpdate, entitlementDelete } from '../api'
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";


const entitlementsFailure = (err) => {
  return {
    type: PERSON_ENTITLEMENTS_FAILURE,
    payload: err
  }
}

const fetchEntitlementsRequest = () => {
  return {
    type: FETCH_ENTITLEMENTS_REQUEST
  }
}

const fetchEntitlementsSuccess = (Entitlements) => {
  return {
    type: FETCH_ENTITLEMENTS_SUCCESS,
    payload: Entitlements
  }
}
 
export const fetchEntitlements = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchEntitlementsRequest())
    try {
      const entitlementsJSONData = await dispatch(entitlements())
      await dispatch(fetchEntitlementsSuccess(entitlementsJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(entitlementsFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

const updateEntitlementData = (values) => {
  return {
    type: UPDATE_PERSON_ENTITLEMENT,
    payload: values
  }
}

export const updateEntitlement = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(entitlementUpdate(values));
      await dispatch(updateEntitlementData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update entitlement', err); // eslint-disable-line no-console
      await dispatch(entitlementsFailure(err));
      await dispatch(dataFailure())
    }
  }
}

const addEntitlementData = (values) => {
  return {
    type: ADD_PERSON_ENTITLEMENT,
    payload: values
  }
}

export const addEntitlement = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(entitlementAdd(values));
      await dispatch(addEntitlementData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(entitlementsFailure(err));
      await dispatch(dataFailure())
    }
  }
}

const deleteEntitlementData = (values) => {
  return {
    type: DELETE_PERSON_ENTITLEMENT,
    payload: values
  }
}

export const deleteEntitlement = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(entitlementDelete(values));
      await dispatch(deleteEntitlementData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to delete entitlement', err); // eslint-disable-line no-console
      await dispatch(entitlementsFailure(err));
      await dispatch(dataFailure())
    }
  }
}

export const setEntitlementId = (id) => {
  return {
    type: SET_ENTITLEMENT_ID,
    payload: id
  }
}

export const resetEntitlement = () => {
  return {
    type: RESET_ENTITLEMENT
  }
}