import React from 'react';
import Box from "@mui/material/Box";

function SumDayFrame({numPersons, id}){
  return (
    <Box
      sx={{
        backgroundColor: "background.paperBottom",
        color: "inherit",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.8rem",
        borderLeft: "2px solid transparent",
        borderRight: "2px solid transparent",
        minHeight: "1.4rem",
      }}
      id={"d" + id}
    >
      {numPersons > 0 ? numPersons : ""}
    </Box>
  );
}

export default SumDayFrame

