import {
  FETCH_INDUSTRIES_REQUEST,
  FETCH_INDUSTRIES_SUCCESS,
  INDUSTRIES_FAILURE,
  SET_INDUSTRY_ID,
  SAVE_INDUSTRY,
  DELETE_INDUSTRY,
} from "actions/types";

import { industriesRead, industrySave, industryDelete } from '../api'
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";

const industriesFailure = (err) => {
  return {
    type: INDUSTRIES_FAILURE,
    payload: err
  }
}

const fetchIndustriesRequest = () => {
  return {
    type: FETCH_INDUSTRIES_REQUEST
  }
}

const fetchIndustriesSuccess = (Industries) => {
  return {
    type: FETCH_INDUSTRIES_SUCCESS,
    payload: Industries
  }
}

export const fetchIndustries = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchIndustriesRequest())
    try {
      const industriesJSONData = await dispatch(industriesRead())
      await dispatch(fetchIndustriesSuccess(industriesJSONData))
      await dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(industriesFailure(err))
      await dispatch(dataEndLoading())
    }
  }
}

const saveIndustryData = (values) => {
  return {
    type: SAVE_INDUSTRY,
    payload: values,
  };
};

export const saveIndustry = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(industrySave(values));
      await dispatch(saveIndustryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};

const deleteIndustryData = (values) => {
  return {
    type: DELETE_INDUSTRY,
    payload: values,
  };
};

export const deleteIndustry = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(industryDelete(values));
      await dispatch(deleteIndustryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};

export const setSelectedIndustryId = (id) => {
  return {
    type: SET_INDUSTRY_ID,
    payload: id
  };
}
