import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  "en-US": {
    translation: {
      addHeadline: "Add {{item}}",
      addItemText: "Enter {{item}}",
      addTextfield: "Enter new {{item}}",
      editHeadline: "Edit {{item}}",
      inventory: "Inventorynumber",
      location: "Location",
      manufacturer: "Manufacturer",
      manufacturerSerial: "Serialnumber",
      maxChars: "{{numChars}} characters max.",
      model: "Model",
      notCorrect: "ATTENTION: Data is not valid anymore!",
      purchaseDate: "Purchase Date",
      SortLabel: "Sort by '{{item}}'",
      Status: "State",
      Statuses: "States",
      supplier: "Supplier",
      type: "Type",
    },
  },

  // import { useTranslation } from "react-i18next";
  // const { t } = useTranslation();

  "de-DE": {
    translation: {
      maxChars: "Maximal {{numChars}} Zeichen",
      // "2 characters max.": "Maximal 2 Zeichen",
      // "3 characters max.": "Maximal 3 Zeichen",
      "0 = any": "0 = beliebig",
      Abbreviation: "Abkürzung",
      Abort: "Abbrechen",
      "About somura": "Über somura",
      Absence: "Abwesenheit",
      "Absence requests": "Genehmigungspflichtige Abwesenheit",
      "Absence statistics": "Abwesenheitsstatistik",
      "Absence type": "Abwesenheitsart",
      "Absence types": "Abwesenheitsarten",
      Absent: "Abwesend",
      "Account is locked": "Benutzerkonto ist gesperrt",
      Active: "Aktiv",
      "Additional information": "Zusatzangaben",
      "Additional text": "Zusatztext",
      "Additional texts": "Zusatztexte",
      Address: "Anschrift",
      Addresses: "Adressen",
      Administrator: "Administrator",
      "Administrator mail address": "Administrator-E-Mail",
      Administrators: "Administratoren",
      after: "nach",
      Age: "Alter",
      "Age visible": "Alter sichtbar",
      All: "Alle",
      "All-day": "Ganztägig",
      "All article types": "Alle Artikelgruppen",
      "All calendars": "Alle Kalender",
      "All countries": "Alle Länder",
      "All departments": "Alle Abteilungen",
      "All employees": "Alle Angestellten",
      "All employees are present.": "Alle Beschäftigten sind anwesend.",
      "All models": "Alle Modelle",
      "All regions": "Alle Regionen",
      Allow: "Erlauben",
      "Allow access": "Zugriff erlauben",
      "Allow new password": "Neues Passwort erlauben",
      Amount: "Betrag",
      "Andrew Jackson": "Noel Coward",
      Animate: "Animieren",
      "Animate icon": "Symbol animieren",
      "Annual salary": "Jahresgehalt",
      Answer: "Antworten",
      Applicant: "Antragsteller",
      Approve: "Genehmigen",
      approved: "genehmigt",
      Approved: "Genehmigt",
      "Approve absence": "Abwesenheit genehmigen",
      "Approvement necessary": "Genehmigung erforderlich",
      Approver: "Genehmiger",
      Article: "Artikel",
      Articles: "Artikel",
      "Article type": "Artikelgruppe",
      "Article types": "Artikelgruppen",
      "as of": "von",
      Assistant: "Assistent/-in",
      Assistants: "Assistenz",
      "Attach folders": "Ordner anheften",
      "Automatic break deduction": "Automatischer Pausenabzug",
      Automation: "Automatik",
      Available: "Verfügbar",
      "Available articles": "Verfügbare Artikel",
      Back: "Zurück",
      Background: "Hintergrund",
      " because an administrator-account has already been created.":
        " anmelden, weil bereits ein Administrator angelegt wurde.",
      before: "vor",
      "Belongs to": "Gehört zu",
      Bicycle: "Fahrrad",
      Billing: "Abrechnung",
      Birthday: "Geburtstag",
      Birthdays: "Geburtstage",
      birthday: "Geburtstag",
      birthdays: "Geburtstage",
      "Birthday visible": "Geburtstag sichtbar",
      "Blinking Colon": "Blinkender Doppelpunkt",
      "Blocks article": "Blockiert Artikel",
      Break: "Pause",
      "Break deduction": "Pausenabzug",
      "Break time": "Pausenzeit",
      "Build date": "Erstellungsdatum",
      Calendar: "Kalender",
      "Calendar weeks": "Kalenderwochen",
      Cancel: "Stornieren",
      "Capital accumulation benefits": "Vermögenswirksame Leistungen",
      "capital letters": "Großbuchstaben",
      "Capital letters": "Großbuchstaben",
      Car: "PKW",
      Change: "Ändern",
      "Change password": "Passwort ändern",
      Changed: "Geändert",
      "Changes affect only new planned absences.":
        "Änderungen wirken sich nur auf neu geplante Abwesenheiten aus.",
      "Changes were NOT saved!": "Daten wurden NICHT gespeichert.",
      "Changes were successfully saved.": "Daten erfolgreich gespeichert.",
      characters: "Zeichen",
      City: "Ort",
      Close: "Schließen",
      Color: "Farbe",
      Comment: "Bemerkung",
      Common: "Allgemein",
      Companies: "Firmen",
      company: "geschäftlich",
      Company: "Firma",
      "Company anniversary": "Firmenjubiläum",
      "Company mail address": "Firmen-Email",
      "Company name": "Firmenname",
      "Company vehicle": "Firmenfahrzeug",
      Condition: "Zustand",
      Conditions: "Zustände",
      Continue: "Weiter",
      "Controlled by": "Geprüft von",
      "Count as business trip": "Als Dienstreise zählen",
      "Count as sick days": "Als Krankheitstage zählen",
      "Count as vacation days": "Als Urlaubstage zählen",
      Countries: "Länder",
      "Countries / Regions": "Länder / Regionen",
      Country: "Land",
      "Covid state": "Covid-Status",
      "Covid state as of": "Covid-Status vom",
      Created: "Erstellt",
      "Create subfolder": "Unterordner erstellen",
      "Created by": "Erstellt von",
      "Current location": "Aktueller Standort",
      "Daily working time (minutes)": "Tägliche Arbeitszeit (Minuten)",
      "Dark mode": "Dunkles Design",
      Date: "Datum",
      day: "Tag",
      Day: "Tag",
      "Day details": "Tages-Details",
      days: "Tage",
      Days: "Tage",
      "Days before absence starts": "Tage vor Abwesenheitsbeginn",
      "Day termination": "Tagesabschluss",
      Decline: "Ablehnen",
      declined: "abgelehnt",
      Declined: "Abgelehnt",
      Deduction: "Abzug",
      "Default location": "Standard Standort",
      Delete: "Löschen",
      "Delete / cancel": "Löschen / Stornieren",
      "Delete / cancel absence": "Abwesenheit löschen / stornieren",
      "Delete folder": "Ordner löschen",
      "Delete folder content": "Inhalt des Ordner löschen",
      "Delete records with time less than":
        "Zeiteinträge löschen, die kürzer sind als",
      "Delete selected Row(s)": "Ausgewählte Zeile(n) löschen",
      Deleted: "Gelöscht",
      deleted: "gelöscht",
      Department: "Abteilung",
      Departments: "Abteilungen",
      Deputy: "Vertretung",
      Description: "Beschreibung",
      Designation: "Bezeichnung",
      Designations: "Bezeichnungen",
      "Designation and identifier": "Bezeichnung und Kennung",
      "Designations and identifiers": "Bezeichnungen und Kennungen",
      digits: "Ziffern",
      Digits: "Ziffern",
      "Disable internal message-system":
        "Internes Nachrichtensystem deaktivieren",
      Discard: "Verwerfen",
      Display: "Anzeige",
      Draft: "Entwurf",
      "Draft created": "Entwurf erstellt",
      Drafts: "Entwürfe",
      "Drop image here or click": "Bild hierher verschieben oder klicken",
      Duration: "Dauer",
      "Easter Sunday": "Ostersonntag",
      Edit: "Bearbeiten",
      "Edit selection": "Auswahl bearbeiten",
      "Emergency contact": "Notfall-Kontakt",
      Employee: "Angestellte:r",
      Employees: "Angestellte",
      Employer: "Arbeitgeber",
      "Employer-funded pension": "Betriebliche Altersvorsorge",
      "Empty folder": "Ordner leeren",
      "Empty recycle bin": "Papierkorb leeren",
      End: "Ende",
      "End work time when SOMURA closes":
        "Arbeitszeit beenden wenn SOMURA beendet wird",
      "ending ": "bis ",
      "End work time": "Arbeitszeit beenden",
      Entitlement: "Anspruch ",
      "Entitlement on ": "Anspruch am ",
      "Entitlement starting ": "Urlaubsanspruch ab ",
      "Entry date": "Eintritt",
      Errors: "Fehler",
      Event: "Ereignis",
      Events: "Ereignisse",
      "Exit date": "Austritt",
      "Expand sidebar": "Menü vergrößern",
      "Fax number": "Fax",
      File: "Datei",
      FILTER: "FILTER",
      "Filter table": "Tabelle filtern",
      "First login as": "Erstes Login mit",
      "First day of ": "Erster Tag ",
      "First day of vacation": "Erster Ferientag",
      "First day of week": "Erster Tag der Woche",
      "First location": "Erster Standort",
      "First movement": "Erste Bewegung",
      "First name": "Vorname",
      "First working day": "Erster Arbeitstag",
      "Fixed date": "Festes Datum",
      "Folder name": "Ordnername",
      Folders: "Ordner",
      Font: "Schrift",
      for: "für",
      "For security reasons your old password was deleted.":
        "Aus Sicherheitsgründen wurde Ihr bisheriges Passwort gelöscht.",
      "For this day, half a day of vacation has to be requested.":
        "An diesem Tag ist lediglich ein halber Urlaubstag erforderlich.",
      "Fourth Advent": "Vierter Advent",
      "Free stock": "Verfügbarer Bestand",
      From: "Von",
      "From date": "Ab Datum",
      "From year": "Ab Jahr",
      "From month": "Ab Monat",
      "Funding by": "Finanzierung durch",
      General: "Allgemein",
      "General data": "Allgemeine Daten",
      "Global settings": "Systemweite Einstellungen",
      "Go to year-calendar": "Zum Jahreskalender",
      "Half vacation day": "Halber Urlaubstag",
      Handout: "Überlassung",
      "Handout date": "Überlassen am",
      "Has additional text": "Hat Zusatztext",
      "Have fun with ": "Viel Erfolg mit ",
      "Health insurance": "Krankenversicherung",
      "Hide folders": "Ordner ausblenden",
      "Hide times": "Zeiten verbergen",
      "Hide when value is '0'": "Verbergen, wenn Wert '0' ist",
      History: "Historie",
      Holiday: "Feiertag",
      Holidays: "Feiertage",
      Hours: "Stunden",
      "I can't remember my password": "Passwort vergessen",
      Icon: "Symbol",
      Identifier: "Kennung",
      "Immediately show vacation statistics when opening calendar":
        "Beim öffnen des Kalenders sofort Urlaubsstatistik anzeigen",
      Inbox: "Nachrichteneingang",
      "In case you didn't receive this email, check your spam-folder or contact ":
        "Sollten Sie keine solche Email erhalten, prüfen Sie bitte den Spam-Ordner ihres Mail-Programms oder wenden Sie sich an ",
      Industries: "Branchen",
      Industry: "Branche",
      industry: "Branche",
      Internal: "Intern",
      "Internet grant": "Internetzuschuss",
      Initials: "Initialen",
      "Insurance carrier": "Träger",
      "Insurance company": "Versicherungsgesellschaft",
      "Invalid movement sequence!": "Ungültige Bewegungsreihenfolge!",
      "Insurance number": "Versicherungs-Nr.",
      Intensity: "Intensität",
      "Invalid date": "Ungültiges Datum",
      "Invalid mail address": "Ungültige EMail-Adresse",
      "Inventory number": "Inventarnummer",
      "Is always first movement": "Ist immer die erste Bewegung",
      "is not possible. Use your company mail address and the password you provided.":
        "anmelden. Verwenden Sie stattdessen die hier eingegebene Firmen-Email-Adresse und das Passwort, das Sie sich vergeben haben.",
      Item: "Gegenstand",
      Language: "Sprache",
      Languages: "Sprachen",
      "Last day of vacation": "Letzter Ferientag",
      "Last name": "Nachname",
      "Last working day": "Letzter Arbeitstag",
      "License number": "Kennzeichen",
      "Light mode": "Helles Design",
      "Loan asset": "Leihartikel",
      "Loan asset: ": "Überlassung: ",
      "Loan asset type": "Leihartikelart",
      "Loan assets": "Überlassungen",
      Location: "Standort",
      Locations: "Standorte",
      Locked: "Gesperrt",
      "Logged in as ": "Angemeldet als ",
      Login: "Anmelden",
      "Login not allowed": "Anmeldung nicht erlaubt",
      "Login not possible": "Anmeldung nicht möglich",
      "Login or register": "Anmelden oder Registrieren",
      "Login to somura is not possible because no administrator-accout has been created.":
        "Die Anmeldung bei Somura ist nicht möglich, weil noch kein Administrator angelegt wurde.",
      Logo: "Logo",
      Logout: "Abmelden",
      "lowercase letters": "Kleinbuchstaben",
      "Lowercase letters": "Kleinbuchstaben",
      "Mail address": "EMail",
      "Manufacturer serial number": "Hersteller-Seriennummer",
      "Mark as unread": "Als ungelesen markieren",
      "Maximum allowed change": "Maximal erlaubte Änderung",
      "May approve": "Darf genehmigen",
      "May delete own planned absence":
        "Darf eigene geplante Abwesenheit löschen",
      "May modify work times": "Darf Arbeitszeiten bearbeiten",
      "May only see own department": "Darf nur eigene Abteilung sehen",
      "May open month calendar": "Darf Monatskalender sehen",
      "May see vacation statistics": "Darf Urlaubsstatistik sehen",
      "May send messages to all users":
        "Darf Nachrichten an alle Benutzer schicken",
      "Message read": "Nachricht gelesen",
      "Message recipients": "Nachrichtenempfänger",
      Message: "Nachricht",
      Messages: "Nachrichten",
      "Messages are disabled": "Nachrichten sind deaktiviert",
      Minimum: "Mindestens",
      "Minimum length": "Mindestlänge",
      "Minimum number": "Mindestanzahl",
      "Minimum working time": "Mindest-Arbeitszeit",
      Minors: "Minderjährige",
      Minutes: "Minuten",
      "Mobile number": "Mobil",
      Model: "Modell",
      Models: "Modelle",
      "Modification not allowed": "Bearbeitung nicht möglich",
      "Modified by": "Bearbeitet von",
      "Modify movement": "Bewegung bearbeiten",
      Monday: "Montag",
      Month: "Monat",
      "Month calendar": "Monatskalender",
      "Month termination": "Monatsabschluss",
      "Monthly salary": "Monatsgehalt",
      "Mother's Day": "Muttertag",
      Move: "Verschieben",
      "Move article to inventory": "Artikel in Bestand aufnehmen",
      Movements: "Bewegungen",
      "Movement type": "Bewegungsart",
      "Movement types": "Bewegungsarten",
      Mr: "Herr",
      Mrs: "Frau",
      "Must be approved by": "Wird genehmigt von",
      "My saved data": "Meine gespeicherten Daten",
      Name: "Name",
      "Needs inventory number": "Inventarnummer erforderlich",
      "Needs location": "Standort erforderlich",
      "Needs user": "Benutzer erforderlich",
      "New absence type": "Neue Abwesenheitsart",
      "New additional text": "Neuer Zusatztext",
      "New article": "Neuer Artikel",
      "New article type": "Neue Artikelgruppe",
      "New company": "Neue Firma",
      "New condition": "Neuer Zustand",
      "New country": "Neues Land",
      "New covid state": "Neuer Covid-Status",
      "New department": "Neue Abteilung",
      "New designation": "Neue Bezeichnung",
      "New employee": "Neue(r) Beschäftigte(r)",
      "New entitlement": "Neuer Urlaubsanspruch",
      "New industry": "Neue Branche",
      "New loan asset": "Neue Überlassung",
      "New loan asset to": "Neue Überlassung an",
      "New loan asset type": "Neue Leihartikelart",
      "New location": "Neuer Standort",
      "New message": "Neue Nachricht",
      "New model": "Neues Modell",
      "New movement type": "Neue Bewegungsart",
      "New password for": "Neues Passwort für",
      "New reason": "Neue Begründung",
      "New region": "Neue Region",
      "New remark": "Neue Bemerkung",
      "New reminder": "Neue Erinnerung",
      "New salary": "Neues Gehalt",
      "New school holidays": "Neue Schulferien",
      "New special day": "Neuer besonderer Tag",
      "New state": "Neuer Status",
      "New subfolder": "Neuer Unterordner",
      "New time entry": "Neuer Zeiteintrag",
      "Next month": "Nächster Monat",
      "Next movement": "Nächste Bewegung",
      "Next occurence": "Nächstes Auftreten",
      "Next page": "Nächste Seite",
      "Next year": "Nächstes Jahr",
      No: "Nein",
      "No absence days": "Keine Abwesenheitstage",
      "No approver assigned!": "Kein Genehmiger eingetragen!",
      "No department assigned": "Ohne Abteilung",
      "No designations": "Keine Bezeichnungen",
      "No details for normal days": "Details nicht für normale Tage anzeigen",
      "No entries in this list": "Diese Liste ist leer",
      "No models": "Keine Modelle",
      "No movements": "Keine Bewegungen",
      "No open approvements.": "Keine unbearbeiteten Urlaubsanträge.",
      "No records found.": "Keine Datensätze gefunden.",
      "(No subject)": "(Kein Betreff)",
      "No unread messages.": "Keine ungelesenen Nachrichten.",
      "No upcoming events.": "Keine anstehenden Ereignisse.",
      "Not active": "Nicht aktiv",
      notCorrect: "ACHTUNG: Diese Angaben sind nicht mehr aktuell!",
      "Not reportable by user": "Nicht durch Benutzer verwendbar",
      "Not selectable (group headline)": "Nicht wählbar (Gruppenüberschrift)",
      "not published": "nicht veröffentlicht",
      Number: "Nummer",
      "Number already exists": "Nummer existiert bereits",
      "Number of articles": "Anzahl Artikel",
      "Number of companies": "Anzahl Firmen",
      "Number of days": "Anzahl Tage",
      "Number of employees": "Anzahl Mitarbeiter",
      "Number of persons": "Anzahl Personen",
      "Number of regions": "Anzahl Regionen",
      of: "von",
      "Off work": "Arbeitsfrei",
      "of total ": "von insgesamt ",
      "Once the administrator has approved your request for a new password, you will receive an email with appropriate information.":
        "Sobald der Administrator die Erlaubnis erteilt hat, dass Sie sich ein neues Passwort vergeben dürfen, erhalten Sie eine entsprechende Email.",
      "Once your account has been created, further login with ":
        "Nach der Einrichtung Ihres Accounts können Sie sich nicht mehr mit ",
      "Only for single days": "Nur für einzelne Tage",
      "Only report required": "Nur Meldung erforderlich",
      "Open approvements": "Unbearbeitete Urlaubsanträge",
      "Open request": "Offene Anfrage",
      Outbox: "Gesendete Nachrichten",
      "Parent type": "Übergeordnete Gruppe",
      Password: "Passwort",
      "Password length": "Passwortlänge",
      "Password (repeat)": "Passwort (Wiederholung)",
      "Password requirements": "Passwort-Anforderungen",
      Passwords: "Passwörter",
      "Permanently delete": "Dauerhaft löschen",
      "Permanently delete messages in recycle bin?":
        "Nachrichten im Papierkorb dauerhaft löschen?",
      Permissions: "Berechtigungen",
      "Person / Month": "Person / Monat",
      Personal: "Persönlich",
      "Personal data": "Meine Daten",
      "Personal worktimes": "Meine Arbeitszeiten",
      "Personnel nr.": "Personalnr.",
      "Personnel number": "Personalnummer",
      "Personnel number is required field": "Personalnummer ist Pflichtfeld",
      Persons: "Beschäftigte",
      "Phone number": "Telefon",
      Plan: "Planen",
      "Plan absence": "Abwesenheit planen",
      Plannable: "Planbar",
      "Plannable by user": "Planung durch Benutzer möglich",
      planned: "geplant",
      Planned: "Geplant",
      "Planned absences": "Geplante Abwesenheiten",
      "Please be aware": "Bitte beachten Sie",
      "Please enter your mail address": "Geben Sie Ihre Email-Adresse ein",
      "Please fill out the fields completely so that we can create an administrator account for you.":
        "Bitte füllen Sie die Felder vollständig aus, damit wir einen Administrator-Account für Sie einrichten können.",
      "Please select a person that is allowed to approve vaction for this employee.":
        "Es wurde noch keine Person festgelegt, die für diesen Benutzer Urlaube genehmigen darf.",
      "Present today": "Heute anwesend",
      Presentation: "Darstellung",
      Preview: "Vorschau",
      "Previous month": "Voriger Monat",
      "Previous page": "Vorige Seite",
      "Previous year": "Voriges Jahr",
      Print: "Drucken",
      Private: "Privat",
      "Private data": "Private Daten",
      Properties: "Eigenschaften",
      "Proved on": "Nachgewiesen am",
      "Provide article to user": "Artikel zur Verfügung stellen",
      Publish: "Veröffentlichen",
      "Publish planned absence": "Geplante Abwesenheit veröffentlichen",
      Published: "Veröffentlicht",
      published: "veröffentlicht",
      "Purchase date": "Kaufdatum",
      "Really delete this folder?": "Ordner wirklich löschen?",
      "Really move content of this folder to recicle bin?":
        "Ordnerinhalt in den Papierkorb verschieben?",
      Reason: "Begründung",
      Reasons: "Begründungen",
      Received: "Empfangen",
      Recovered: "Genesen",
      "Recycle bin": "Papierkorb",
      Refresh: "Aktualisieren",
      region: "Region",
      Regions: "Regionen",
      regions: "Regionen",
      "Register approved absence": "Genehmigte Abwesenheit eintragen",
      registered: "eingetragen",
      Registered: "Gemeldet",
      Registration: "Registrierung",
      "Registration necessary": "Meldung erforderlich",
      Remark: "Bemerkung",
      Remarks: "Bemerkungen",
      "Remind me": "Erinnerung",
      "Remind me to request absence": "An Beantragung erinnern",
      Reminder: "Erinnerung",
      reminder: "Erinnerung",
      Reminders: "Erinnerungen",
      reminders: "Erinnerungen",
      Remove: "Entfernen",
      "Rename folder": "Ordner umbenennen",
      "Repeat password": "Passwort (Wiederholung)",
      Reported: "Gemeldet",
      "Request / register": "Beantragen / Eintragen",
      "Request / register absence": "Abwesenheit beantragen / eintragen",
      Request: "Beantragen",
      "Request state": "Antragsstatus",
      requested: "beantragt",
      Requested: "Beantragt",
      required: "erforderlich",
      Required: "Pflichtfeld",
      RESET: "ZURÜCKSETZEN",
      "Reset state": "Status zurücksetzen",
      "Reset absence state": "Abwesenheitsstatus zurücksetzen",
      Retired: "Verschrottet / entsorgt",
      Return: "Rückgabe",
      "Return article from user": "Rücknahme von Mitarbeiter",
      "Return date": "Zurück am",
      Roll: "Rolle",
      "Rows per page:": "Zeilen pro Seite:",
      "Row(s) selected": "Zeile(n) ausgewählt",
      "Run day termination for": "Tagesabschluss durchführen für",
      "Run month termination for": "Monatsabschluss durchführen für",
      Salary: "Gehalt",
      "Salary since": "Gehalt seit",
      Salutation: "Anrede",
      "Saturday is a working day (6-days-week)":
        "Samstag ist Arbeitstag (6-Tage-Woche)",
      Save: "Speichern",
      "Save as CSV": "Als CSV-Datei speichern",
      "School holidays": "Schulferien",
      Search: "Suchen",
      "Select a language": "Wählen Sie eine Sprache",
      "Select all": "Alle auswählen",
      "Select logo file": "Logo-Datei auswählen",
      Selectable: "Wählbar",
      Send: "Senden",
      "Set start time": "Startzeit einstellen",
      "Set end time": "Endezeit einstellen",
      Settings: "Einstellungen",
      "Settings: absence types": "Einstellungen: Abwesenheitsarten",
      "Settings: addresses": "Einstellungen: Adressen",
      "Settings: articles": "Einstellungen: Artikel",
      "Settings: calendar": "Einstellungen: Kalender",
      "Settings: company": "Einstellungen: Firma",
      "Settings: passwords": "Einstellungen: Passwörter",
      "Settings: somura": "Einstellungen: somura",
      "Settings: time tracking": "Einstellungen: Zeiterfassung",
      "Short time format (no seconds)": "Kurzes Zeitformat (keine Sekunden)",
      Show: "Anzeigen",
      "Show absence start": "Abwesenheitsbeginn anzeigen",
      "Show as bar": "Als Balken anzeigen",
      "Show calendar weeks": "Kalenderwochen anzeigen",
      "Show columns": "Spalten anzeigen",
      "Show company sum row": "Firmen-Summenzeile anzeigen",
      "Show folders": "Ordner einblenden",
      "Show / hide columns": "Spalten anzeigen / ausblenden",
      "Show in calendar": "Im Kalender anzeigen",
      "Show in tooltips": "In Tooltips anzeigen",
      "Show only active employees": "Nur aktive Mitarbeiter anzeigen",
      "Show only future reminders": "Nur zukünftige Erinnerungen anzeigen",
      "Show reminder": "Erinnerung anzeigen",
      "Show reminders": "Erinnerungen anzeigen",
      "Show school holidays": "Schulferien im Kalender anzeigen",
      "Show school holidays only for trainees":
        "Schulferien nur bei Auszubildenden anzeigen",
      "Show special days": "Besondere Tage im Kalender anzeigen",
      "Show times": "Zeiten anzeigen",
      "Shrink sidebar": "Menü verkleinern",
      "Social insurance number": "SV-Nummer",
      SortLabel: "Nach '{{item}}' sortieren",
      "special characters": "Sonderzeichen",
      "Special characters": "Sonderzeichen",
      "Special day": "Besonderer Tag",
      "Special days": "Besondere Tage",
      Start: "Beginn",
      "Start ": "Beginn ",
      "Start break": "Pause beginnen",
      "Start date": "Anfangsdatum",
      "Start earlier (Minutes)": "Früher starten (Minuten)",
      "Start page": "Startseite",
      "Start page on this device": "Startseite auf diesem Gerät",
      "starting ": "ab ",
      "Start work time": "Arbeitszeit beginnen",
      "Start work time when SOMURA starts":
        "Arbeitszeit beginnen wenn SOMURA startet",
      State: "Status",
      "State as of": "Status vom",
      "State proved on": "Status nachgewiesen am",
      Statistics: "Statistik",
      "Statistics graph": "Statistik-Balken",
      Status: "Zustand",
      Statuses: "Zustände",
      Street: "Straße",
      Structure: "Struktur",
      Subject: "Betreff",
      "Subregion of": "Übergeordnete Region",
      "Sum rows": "Summenzeilen",
      Sunday: "Sonntag",
      "Support for multiple languages": "Unterstützung für mehrere Sprachen",
      "System language": "Systemweite Sprache",
      "System settings": "System-Einstellungen",
      Taken: "Genommen",
      "Taken up to now ": "Bisher genommen ",
      "Target date": "Termin",
      "Tax id": "Steuer-ID",
      "Test date": "Testdatum",
      Tested: "Getestet",
      "Text has to be selected": "Es muss ein Text ausgewählt werden",
      "The action was successfully completed.":
        "Die Aktion wurde erfolgreich beendet.",
      "The administrator received your request to signin to somura. You will receive an email with further information.":
        "Der Administrator wurde über Ihren Wunsch, sich bei Somura anzumelden informiert. Sie erhalten in Kürze eine Email mit weiteren Informationen.",
      "There's no pleasure in having nothing to do. The fun is having lots to do... and not doing it.":
        "Nichtstun macht nur dann Spaß, wenn man eigentlich viel zu tun hätte.",
      "This day is half a day of vacation":
        "An diesem Tag einen halben Urlaubstag abziehen",
      "This day is off work. No vacation needs to be requested.":
        "Dies ist ein arbeitsfreier Tag. Es muss kein Urlaub beantragt werden.",
      "This day is off work. No vacation will be calculated.":
        "Dies ist ein arbeitsfreier Tag. Es erfolgt kein Urlaubsabzug.",
      "This folder is empty": "Dieser Ordner ist leer",
      "This holiday is nationwide.": "Dies ist ein landesweiter Feiertag.",
      "This holiday is regional and therefore probably not off work.":
        "Dies ist ein regionaler bzw. nicht zwingend arbeitsfreier Feiertag.",
      "This month": "Dieser Monat",
      "This request is outdated!": "Dieser Antrag ist nicht mehr gültig!",
      "This year": "Dieses Jahr",
      Time: "Zeit",
      Timeframe: "Zeitraum",
      "Time record for": "Zeiteintrag für",
      "Time tracking": "Zeiterfassung",
      "Time tracking is disabled": "Zeiterfassung ist deaktiviert",
      "times duration of absence": "mal Dauer der Abwesenheit",
      Title: "Titel",
      To: "An",
      to: "bis",
      Today: "Heute",
      "Total time": "Gesamtzeit",
      Trainee: "Auszubildende:r",
      Trainees: "Auszubildende",
      Type: "Art",
      Types: "Arten",
      Unknown: "Unbekannt",
      "Unknown condition": "Zustand nicht bekannt",
      "Unknown location": "Standort nicht bekannt",
      "unpublished days": "unveröffentlichte Tage",
      "Unpublished days": "Unveröffentlichte Tage",
      "Unselect all": "Auswahl aufheben",
      "Unused vacation end of ": "Resturlaub aus ",
      "Unread messages": "Ungelesene Nachrichten",
      "Upload logo": "Logo hochladen",
      "Use automatic break deduction": "Automatischen Pausenabzug verwenden",
      "Use info bar": "Info-Leiste verwenden",
      "Use inventory numbers": "Inventarnummern verwenden",
      "Use module 'loan assets'": "Modul 'Überlassungen' verwenden",
      "Use module 'covid state'": "Modul 'Covid-Status' verwenden",
      "Use module 'time tracking'": "Modul 'Zeiterfassung' verwenden",
      "Use reason when handing out article":
        "Begründung wenn Artikel ausgegeben wird",
      "Use reason when user returns article":
        "Begründung wenn Artikel zurückgegeben wird",
      "Use tooltips": "Tooltips verwenden",
      User: "Benutzer",
      "User must assign a new password":
        "Benutzer muss neues Passwort vergeben",
      Username: "Benutzername",
      "Users may change start and end times":
        "Benutzer dürfen Start- und Endezeiten ändern",
      Vacation: "Urlaub",
      "Vacation deduction": "Urlaubsabzug",
      "Vacation statistics": "Urlaubsstatistik",
      "Vacation days": "Urlaubstage",
      "Vacation entitlement": "Urlaubsanspruch",
      Vaccinated: "Geimpft",
      "Valid until": "Gültig bis",
      "Web address": "Internet",
      Weekend: "Wochenende",
      "Welcome to": "Herzlich willkommen bei",
      "Whole day": "Ganztägig",
      "within the next ": "der nächsten ",
      "Work time": "Arbeitszeit",
      "Work time net": "Arbeitszeit netto",
      "working day": "Arbeitstag",
      "Working day": "Arbeitstag",
      "working days": "Arbeitstage",
      "Working days": "Arbeitstage",
      "Working time more than": "Arbeitszeit mehr als",
      Year: "Jahr",
      "Year calendar": "Jahreskalender",
      Yes: "Ja",
      Yesterday: "Gestern",
      "You are not allowed to login as ": "Sie dürfen sich nicht mehr als ",
      ZIP: "Plz",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en-US",
  fallbackLng: "en-US",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  keySeparator: ">",
  nsSeparator: "|",
});
export default i18n;
