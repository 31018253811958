import {
  INIT_DATABASE,
  SET_EDIT_MODE,
  SET_DRAWER_OPEN,
  SET_DISPLAY_YEAR,
  SET_DISPLAY_MONTH,
  DATA_FAILURE,
  DATA_SUCCESS,
  DATA_RESET,
  DATA_LOADING,
  DATA_NOT_LOADING,
  ACTION_SUCCESS
} from "actions/types";

import {
  initDatabase,
} from "../api";

export const dataFailure = (err) => {
  return {
    type: DATA_FAILURE,
    payload: err
  };
};

export const dataSuccess = () => {
  return {
    type: DATA_SUCCESS,
  };
};

export const dataReset = () => {
  return {
    type: DATA_RESET,
  };
};

export const dataStartLoading = () => {
  return {
    type: DATA_LOADING,
  };
};

export const dataEndLoading = () => {
  return {
    type: DATA_NOT_LOADING,
  };
};

const initialzeDatabaseSuccess = () => {
  return {
    type: INIT_DATABASE,
  };
};

export const initializeDatabase = () => {
  return (dispatch) => {
    try {
      dispatch(initDatabase());
      dispatch(initialzeDatabaseSuccess());
    } catch (err) {
      dispatch(dataFailure());
    }
  };
};

export const setEditMode = (editMode) => {
  return {
    type: SET_EDIT_MODE,
    payload: editMode,
  };
};

export const setDrawerOpen = (open) => {
  return {
    type: SET_DRAWER_OPEN,
    payload: open,
  };
};

export const setDisplayYear = (year) => {
  return {
    type: SET_DISPLAY_YEAR,
    payload: year,
  };
};

export const setDisplayMonth = (month) => {
  return {
    type: SET_DISPLAY_MONTH,
    payload: month,
  };
};

export const setDataLoading = (loading) => {
  return {
    type: DATA_LOADING,
    payload: loading,
  };
};

export const actionSuccess = () => {
  return {
    type: ACTION_SUCCESS,
  };
};