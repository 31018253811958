import React from "react";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid " + theme.palette.background.highlight,
    borderRadius: "4px",
    boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
  },
  listWrapper: {
    maxHeight: "10rem",
    overflowY: "auto",
    margin: "3px 0 2px 0",
  },
  daysList: {
    fontSize: "1rem",
    padding: "4px 1rem",
    listStyleType: "none",
    margin: 0,
  },
}));

const DateList = (props) => {
  const classes = useStyles();

  const DaysList = () => {
    let listItems = [];
    for (var i = 0; i < props.dates.length; i++) {
      listItems.push(
        <li key={i}>{moment(props.dates[i].date).format("dddd, LL")}</li>
      );
    }
    return listItems;
  };

  return (
    <div className={classes.root}>
      <div className={classes.listWrapper}>
        <ul className={classes.daysList}>{DaysList()}</ul>
      </div>
    </div>
  );
};

export default DateList;
