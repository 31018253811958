import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { setSelectedCountryId, saveSpecialDayRegions } from "redux/index";
import Grid from "@mui/material/Grid";
import SomuraCheckListItem from "Components/SomuraCheckListItem";
import BackButton from "Components/Buttons/BackButton";
import SaveButton from "Components/Buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  listWrapper: {
    overflow: "auto",
    marginTop: "6px",
    maxHeight: "58vh",
  },
}));

const mapStateToProps = (state) => {
  const selectedCountryId = state.countries.selectedCountryId;
  const country = state.countries.Countries.find(
    (C) => C.id === selectedCountryId
  );
  return {
    country,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCountryId: (id) => dispatch(setSelectedCountryId(id)),
    saveSpecialDayRegions: (values) => dispatch(saveSpecialDayRegions(values)),
  };
};

const RegionsSelect = ({ country, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const regions = country?.regions;
  const [allRegions, setAllRegions] = useState();

  const formHeader =
    t("Holiday") +
    ": " +
    props.selectedDay?.dayName +
    " > " +
    country?.ownName +
    " > " +
    t("Regions");

  const handleRegionSelect = (value) => {
    var prevState = [...allRegions];
    const key = prevState.findIndex((AC) => AC.value === value);

    var region = prevState.find((AC) => AC.value === value);
    region.selected = !region.selected;
    prevState[key] = region;
    if (value === 0) {
      prevState.map((P) => {
        if (P.value > 0) {
          P.selected = prevState[key].selected;
        }
        return P;
      });
    }
    setAllRegions(prevState);
  };

  const RegionsListLeft = () => {
    const listItems = [];
    allRegions
      ?.filter((R) => R.value > 0)
      // eslint-disable-next-line array-callback-return
      .map((R, index) => {
        if (index < allRegions.length / 2) {
          listItems.push(
            <SomuraCheckListItem
              key={R.value}
              value={R.value}
              itemText={R.label}
              onCheckboxClick={handleRegionSelect}
              checked={R.selected}
              hideChevron={true}
            />
          );
        }
      });
    return listItems;
  };

  const RegionsListRight = () => {
    const listItems = [];
    allRegions
      ?.filter((R) => R.value > 0)
      // eslint-disable-next-line array-callback-return
      .map((R, index) => {
        if (index >= allRegions.length / 2) {
          listItems.push(
            <SomuraCheckListItem
              key={R.value}
              value={R.value}
              itemText={R.label}
              onCheckboxClick={handleRegionSelect}
              checked={R.selected}
              hideChevron={true}
            />
          );
        }
      });
    return listItems;
  };

  const handleClose = () => {
    props.setSelectedCountryId(-1);
  };

  const handleSubmit = () => {
    props.saveSpecialDayRegions(
      {id: props.selectedDay.id,
      key: country.id,
      value: allRegions.filter(R => R.value > 0 && R.selected).map(R => R.value)})
    props.setSelectedCountryId(-1);
  };

  useEffect(() => {
    var allRegions = [
      {
        label: t("All regions"),
        value: 0,
        selected: true,
      },
    ];
    regions?.map((R) =>
      allRegions.push({
        label:
          R.regionLong +
          (R.parentRegionId > 0
            ? " (" +
              regions?.find((SR) => SR.id === R.parentRegionId)?.regionLong +
              ")"
            : ""),
        value: R.id,
        selected:
          props.selectedDay?.countriesRegions
            .find((CR) => CR.country === country.id)
            ?.regions.findIndex((RG) => RG === R.id) > -1,
      })
    );
    setAllRegions(allRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions]);

  return (
    <>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SomuraCheckListItem
              key={0}
              value={0}
              itemText={t("All regions")}
              onCheckboxClick={handleRegionSelect}
              checked={
                allRegions?.filter(
                  (AR) => AR.value !== 0 && AR.selected === true
                ).length > 0
              }
              indeterminate={
                allRegions?.filter(
                  (AR) => AR.value !== 0 && AR.selected === true
                ).length > 0 &&
                allRegions?.filter(
                  (AR) => AR.value !== 0 && AR.selected === true
                ).length !== allRegions?.filter((AR) => AR.value !== 0).length
              }
              hideChevron={true}
            />
          </Grid>
        </Grid>
        <div className={classes.listWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RegionsListLeft />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RegionsListRight />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className="formBottom">
          <BackButton onClick={handleClose} />
          <SaveButton onClick={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionsSelect);
