import {
  SET_OFFDAYS_REQUEST,
  SET_OFFDAYS_SUCCESS,
  SET_OFFDAYS_FAILURE
} from 'actions/types'


import produce from 'immer'

const initialState = {
  OffDays: [],
  loading: false,
  error: ''
}

const offDaysReducer = (state = initialState, action) => 
produce(state, draft => {
  // let key = 0
  switch (action.type) {
    case SET_OFFDAYS_REQUEST:
      draft.loading = true
      break
    case SET_OFFDAYS_SUCCESS:
      draft.OffDays = action.payload
      draft.loading = false
      draft.error = ''
      break 
    case SET_OFFDAYS_FAILURE:
      draft.loading = false
      draft.error = ''
      break  
    default: return state
  }
})

  
  export default offDaysReducer      