import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material"
import { fetchCountries } from "redux/index";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ScreenTitle from "Components/ScreenTitle";
import HomeWorkIcon from "@mui/icons-material/HomeWork";

import Box from "@mui/material/Box";

import CompaniesTable from "./Companies/CompaniesTable"
import IndustriesTable from "./Industries/IndustriesTable"
import CountriesTable from "./Countries/CountriesTable"

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const mapStateToProps = (state) => {
  return {
    editMode: state.general.EditMode,
    selectedDepartment: state.departments.selectedDepartmentId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCountries: () => dispatch(fetchCountries()),
  };
};

function Addresses(props) {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <Paper square elevation={8}>
        <ScreenTitle
          title={t("Settings: addresses")}
          class="settings"
          icon={<HomeWorkIcon style={{ width: "1.8em", height: "1.8em" }} />}
        />
        <Collapse in={props.editMode === false} timeout={"auto"}>
          <Tabs
            className="tabPanel"
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleTabsChange}
          >
            <Tab label={t("Companies")} />
            <Tab label={t("Industries")} />
            <Tab label={t("Countries")} />
          </Tabs>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <CompaniesTable />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <IndustriesTable />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <CountriesTable />
          </TabPanel>
        </Collapse>
        {/* <Collapse
          in={selectedDepartmentId >= 0 && props.editMode}
          timeout={"auto"}
        >
          <DepartmentForm departmentId={selectedDepartmentId} />
        </Collapse>
        <Collapse
          in={selectedLoanAssetId >= 0 && props.editMode}
          timeout={"auto"}
        >
          <LoanAssetForm loanassetId={selectedLoanAssetId} />
        </Collapse> */}
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
