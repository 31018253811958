import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchStats, fetchYear } from "redux/index";
import { hexToRGB, getDisplaySetting } from "Components/Utilities";
import SomuraTooltip from "Components/SomuraTooltip";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "6px",
    backgroundColor: theme.palette.background.paper,
    height: "2rem",
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "10rem 1fr",
    gridTemplateRows: "1.5rem",
    padding: "4px",
  },
  barText: {
    gridRow: "1",
    gridColumn: "1",
    paddingLeft: "4px",
  },
  barFrame: {
    gridRow: "1",
    gridColumn: "2",
    display: "flex",
    padding: "3px 1px",
  },
  frameWrapper: {
    gridRow: "1",
    gridColumn: "2",
    height: "100%",
  },
  correctFrame: {
    height: "100%",
    border: "1px solid" + theme.palette.text.main,
    backgroundColor: theme.palette.background.statsBar,
    display: "inline-flex",
  },
  incorrectFrame: {
    height: "100%",
    border: "2px solid" + theme.palette.red,
    backgroundColor: theme.palette.red,
    display: "inline-flex",
  },
  dayBar: {
    display: "inline-block",
    height: "100%",
    border: "1px solid" + theme.palette.background.statsBar,
    fontSize: "0.9rem",
    lineHeight: "1rem",
    textAlign: "right",
    paddingRight: "4px",
  },
  freeBar: {
    display: "inline-block",
    height: "100%",
    backgroundColor: "transparent",
    color: theme.palette.text.main,
    fontSize: "0.9rem",
    lineHeight: "1rem",
    textAlign: "right",
    paddingRight: "4px",
  },
  overBar: {
    display: "inline-block",
    height: "100%",
    backgroundColor: theme.palette.red,
    color: "white",
    fontSize: "0.9rem",
    lineHeight: "1rem",
    textAlign: "right",
    paddingRight: "4px",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    yeardata: state.year.Year,
    userStats: state.yearcalendar.userStats,
    vacationDays: state.year.Year.filter(
      (Y) =>
        Y.isVacation === "1" &&
        moment(Y.date).format("YYYY") === state.general.DisplayYear
    ),
    year: parseInt(state.general.DisplayYear),
    userid: state.yearcalendar.displayUserId,
    usePersonNumbers: state.company.Company.usePersonNumbers,
    user: state.persons.PersonsMin.find(
      (P) => P.id === state.yearcalendar.displayUserId
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchYear: (values) => dispatch(fetchYear(values)),
    fetchStats: (values) => dispatch(fetchStats(values)),
  };
};

const StatsBar = ({ userStats, vacationDays, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [state, setState] = useState({});

  useEffect(() => {
    props.fetchStats({
      year: props.year,
      userId: props.userid,
      loggedUserId: props.loggedUserId,
    });
    BarDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.year, props.userid]);

  useEffect(() => {
    if (userStats) {
      setState({
        totalVacation:
          parseFloat(userStats.entitlement_old) +
          parseFloat(userStats.entitlement_now),
        planned: parseFloat(userStats.vacation_planed),
        requested: parseFloat(userStats.vacation_requested),
        approved:
          parseFloat(userStats.vacation_approved) +
          parseFloat(userStats.vacation_old),
        unused:
          parseFloat(userStats.entitlement_old) +
          parseFloat(userStats.entitlement_now) -
          parseFloat(userStats.vacation_planed) -
          parseFloat(userStats.vacation_requested) -
          parseFloat(userStats.vacation_approved) -
          parseFloat(userStats.vacation_old),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStats]);

  const BarDays = () => {
    const foreColor = getDisplaySetting("1", "fontColor");
    const backColor = getDisplaySetting("1", "backgroundColor");
    const plannedBackColor = hexToRGB(
      backColor,
      getDisplaySetting("1", "backgroundFactor2")
    );
    const requestedBackColor = hexToRGB(
      backColor,
      getDisplaySetting("1", "backgroundFactor1")
    );
    const allVacation = Math.max(
      state.planned + state.requested + state.approved,
      state.totalVacation
    );
    return (
      <>
        <div className={classes.frameWrapper}>
          <div
            className={classes.correctFrame}
            style={{
              width:
                (Math.min(state.totalVacation, allVacation) * 100) /
                  allVacation +
                "%",
            }}
          ></div>
          {state.unused < 0 && (
            <div
              className={classes.incorrectFrame}
              style={{
                width:
                  ((allVacation - state.totalVacation) * 100) / allVacation +
                  "%",
              }}
            ></div>
          )}
        </div>

        <div className={classes.barFrame}>
          {state.approved > 0 && (
            <SomuraTooltip
              title={t("Approved") + ": " + state.approved}
              placement="top"
              followCursor
            >
              <div
                className={classes.dayBar}
                style={{
                  width: (state.approved * 100) / allVacation + "%",
                  backgroundColor: backColor,
                  color: foreColor,
                }}
              >
                {state.approved}
              </div>
            </SomuraTooltip>
          )}
          {state.requested > 0 && (
            <SomuraTooltip
              title={t("Requested") + ": " + state.requested}
              placement="top"
              followCursor
            >
              <div
                className={classes.dayBar}
                style={{
                  width: (state.requested * 100) / allVacation + "%",
                  backgroundColor: requestedBackColor,
                  color: foreColor,
                }}
              >
                {state.requested}
              </div>
            </SomuraTooltip>
          )}
          {state.planned > 0 && (
            <SomuraTooltip
              title={t("Planned") + ": " + state.planned}
              placement="top"
              followCursor
            >
              <div
                className={classes.dayBar}
                style={{
                  width: (state.planned * 100) / allVacation + "%",
                  backgroundColor: plannedBackColor,
                  color: foreColor,
                }}
              >
                {state.planned}
              </div>
            </SomuraTooltip>
          )}

          {state.unused > 0 && (
            <SomuraTooltip
              title={t("Available") + ": " + state.unused}
              placement="top"
              followCursor
            >
              <div
                className={classes.freeBar}
                style={{ width: (state.unused * 100) / allVacation + "%" }}
              >
                {state.unused}
              </div>
            </SomuraTooltip>
          )}
        </div>
      </>
    );
  };

  return (
    <Paper className={classes.root} square elevation={8}>
      <div className={classes.wrapper}>
        <div className={classes.barText}>
          {t("Vacation")}
          {": " + state.totalVacation + " "}
          {t(state.totalVacation === 1 ? "day" : "days")}
        </div>
        <BarDays />
      </div>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsBar);
