import {
  FETCH_YEAR_REQUEST,
  FETCH_YEAR_SUCCESS,
  FETCH_YEAR_FAILURE,
  FETCH_YEAR_STATIC_SUCCESS,
  SET_DISPLAY_USER,
  SET_DISPLAY_APPROVEMENT,
  SET_YEAR_WORKDAYS,
} from "./types";

import { yearData, yearStaticData } from "../api";

import {
  dataStartLoading,
  dataEndLoading,
} from "./general";

const fetchYearRequest = (status = true) => {
  return {
    type: FETCH_YEAR_REQUEST,
    status,
  };
};

const fetchYearSuccess = (yearData) => {
  return {
    type: FETCH_YEAR_SUCCESS,
    payload: yearData,
  };
};

const fetchYearFailure = (err) => {
  return {
    type: FETCH_YEAR_FAILURE,
    payload: err,
  };
};

export const fetchYear = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchYearRequest(true));
    try {
      const yearJSONData = await dispatch(yearData(values));
      await dispatch(fetchYearSuccess(yearJSONData));
      dispatch(dataEndLoading())
    } catch (err) {
      await dispatch(fetchYearFailure(err));
      dispatch(dataEndLoading())
    }
  };
};

const fetchYearStaticSuccess = (yearData) => {
  return {
    type: FETCH_YEAR_STATIC_SUCCESS,
    payload: yearData,
  };
};

export const fetchYearStatic = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchYearRequest(true));
    try {
      const yearJSONData = await dispatch(yearStaticData(values));
      await dispatch(fetchYearStaticSuccess(yearJSONData));
      dispatch(dataEndLoading())
    } catch (err) {
      await dispatch(fetchYearFailure(err));
      dispatch(dataEndLoading())
    }
  };
};

const setDisplayUserData = (id) => {
  return {
    type: SET_DISPLAY_USER,
    payload: id,
  };
};

export const setDisplayUser = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(setDisplayUserData(id));
    } catch (err) {
      console.error("failed to set display user", err); // eslint-disable-line no-console
      await dispatch(fetchYearFailure(err));
    }
  };
};

const setDisplayApprovementData = (id) => {
  return {
    type: SET_DISPLAY_APPROVEMENT,
    payload: id,
  };
};

export const setDisplayApprovement = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(setDisplayApprovementData(id));
    } catch (err) {
      console.error("failed to set display approvement", err); // eslint-disable-line no-console
      await dispatch(fetchYearFailure(err));
    }
  };
};

export const setYearWorkdays = (value) => {
  return {
    type: SET_YEAR_WORKDAYS,
    payload: value
  };
}