import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { fetchDashboard } from "redux/index";
import { getPersonalSetting } from "Components/Utilities";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";

import AtWorkToday from "./AtWorkToday";
import OpenApprovements from "./OpenApprovements";
import NextEvents from "./NextEvents";
import UnreadMessages from "./UnreadMessages";

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser;
  return {
    loggedUser,
    dashboard: state.dashboard.Dashboard,
    unread: state.messages.Messages.filter(
      (M) =>
        M.recipients &&
        M.recipients.findIndex(
          (R) =>
            R.recipientId === loggedUser.LoggedUser.id &&
            R.recipientFolderId === "0" &&
            R.readTime === null
        ) > -1
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboard: (values) => dispatch(fetchDashboard(values)),
  };
};

function Dashboard({ loggedUser, dashboard, ...props }) {
  const component = "dashboard";
  const hideEventsWhenZero =
    parseInt(getPersonalSetting(component, "hideEventsWhenZero")) === 1;
  const hideMessagesWhenZero =
    parseInt(getPersonalSetting(component, "hideMessagesWhenZero")) === 1;
  const nextEvents = dashboard.nextEvents;

  useEffect(() => {
    // fetch dashboard data
    props.fetchDashboard({ userId: loggedUser.LoggedUser.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  return loggedUser && dashboard ? (
    <div className="content">
      <Grid container spacing={3}>
        {(loggedUser.LoggedUser.isadmin ||
          loggedUser.LoggedUser.isassistant) && (
          <Grid item xs={12} sm={6} lg={4}>
            <AtWorkToday
              value={dashboard.numAtWork}
              total={dashboard.numActive}
              data={dashboard.offPersons}
            />
          </Grid>
        )}
        {loggedUser.LoggedUser.isapprover && (
          <Grid item xs={12} sm={6} lg={4}>
            <OpenApprovements />
          </Grid>
        )}
        {(props.unread.length > 0 || !hideMessagesWhenZero) && (
          <Grid item xs={12} sm={6} lg={4}>
            <UnreadMessages value={props.unread.length} data={props.unread} />
          </Grid>
        )}
        {(loggedUser.LoggedUser.isadmin || loggedUser.LoggedUser.isassistant) &&
          (props.unread.length > 0 || !hideEventsWhenZero) && (
            <Grid item xs={12} sm={6} lg={4}>
              <NextEvents value={dashboard.numNextEvents} data={nextEvents} />
            </Grid>
          )}
      </Grid>
    </div>
  ) : (
    <Redirect to="/SignIn" />
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
