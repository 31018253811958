import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_REQUEST,
  COMPANIES_FAILURE,
  SET_COMPANY_ID,
  SAVE_COMPANIES,
  DELETE_COMPANIES,
} from "actions/types";
import produce from "immer";

const initialState = {
  Companies: [],
  loading: false,
  error: "",
  selectedCompanyId: -1
};

const companiesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_COMPANIES_REQUEST:
        draft.loading = true;
        break;
      case FETCH_COMPANIES_SUCCESS:
        draft.Companies = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case COMPANIES_FAILURE:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case SET_COMPANY_ID:
        draft.selectedCompanyId = action.payload;
        break;
      case SAVE_COMPANIES:
        if (action.payload.id === 0) {
          // insert new company
          draft.Companies = [...draft.Companies, action.payload];
        } else {
          // update company
          key = draft.Companies.findIndex((C) => C.id === action.payload.id);
          draft.Companies[key] = action.payload;
        }
        draft.selectedCompanyId = -1;
        break;
      case DELETE_COMPANIES:
        key = draft.Companies.findIndex((C) => C.id === action.payload.id);
        draft = { Companies: draft.Companies.splice(key, 1) };
        draft.selectedCompanyId = -1;
        break;
      default:
        return draft;
    }
  });

export default companiesReducer;
