import React, { useEffect, useState, useCallback } from "react";
import { setEditMode, saveIndustry, deleteIndustry } from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";
import SomuraTextField from "Components/SomuraTextField";
import makeStyles from "@mui/styles/makeStyles";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const IndustrySchema = Yup.object().shape({
  industryName: Yup.string().required("Pflichtfeld"),
});

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  const industries = state.industries.Industries;
  const selectedIndustryId = state.industries.selectedIndustryId;
  return {
    industries,
    selectedIndustryId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveIndustry: (values) => dispatch(saveIndustry(values)),
    deleteIndustry: (values) => dispatch(deleteIndustry(values)),
    setEditMode: (editMode) => dispatch(setEditMode(editMode)),
  };
};

const IndustryForm = ({ industries, selectedIndustryId, ...props }) => {
  const selectedIndustry =
    industries.length > 0 &&
    industries.find((I) => I.id === selectedIndustryId);
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: 0,
    industryName: "",
    numAssigned: 0,
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);
  const formHeader = selectedIndustry
    ? selectedIndustry.industryName
    : t("New industry");

  const handleSubmit = () => {
    props.saveIndustry(state);
    props.handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await IndustrySchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (selectedIndustry) {
      setState({
        ...selectedIndustry,
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndustry]);

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "cancel":
        props.handleClose();
        break;
      case "delete":
        props.deleteIndustry({ id: selectedIndustryId });
        props.handleClose();
        break;
      default:
        props.handleClose();
    }
  };

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SomuraTextField
              type="text"
              name="industryName"
              label={t("Industry")}
              value={state.industryName}
              maxlength="50"
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton
          onClick={() => {
            handleButtonClick("cancel");
          }}
        />
        <DeleteButton
          disabled={!selectedIndustry || state.numAssigned > 0}
          onClick={() => {
            handleButtonClick("delete");
          }}
        />
        <SaveButton onClick={handleSubmit} disabled={!valid} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryForm);
