import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setPersonId } from "redux/index";
import makeStyles from "@mui/styles/makeStyles";
import { sortByDate } from "Components/Utilities";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import moment from "moment";
import SomuraTooltip from "Components/SomuraTooltip";
import PersonsForm from "./PersonsForm";

import ScreenTitle from "Components/ScreenTitle";
import PersonsIcon from "@mui/icons-material/PeopleTwoTone";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import SomuraDataGrid from "Components/SomuraDataGrid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& .tableHeader": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.background.contrastText,
    },
  },
  contentPaper: {
    height: "calc(100vh - 112px)"
  },
  errorSpan: {
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "4px",
    marginRight: "4px",
    color: theme.palette.red,
  },
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "26px",
  },
}));

const mapStateToProps = (state) => {
  return {
    isadmin: state.loggeduser.LoggedUser.isadmin,
    personId: state.persons.personId,
    loading: state.persons.loading,
    error: state.persons.error,
    personsData: state.persons.PersonsMin.filter((P) => P.fullName !== " "),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonId: (id) => dispatch(setPersonId(id)),
  };
};

function Persons(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const title = () => {
    return (
      <span className={classes.errorSpan}>
        <ErrorOutlineIcon className={classes.errorIcon} />
        {t("No approver assigned!")}
      </span>
    );
  };

  const columns = [
    {
      headerName: "ID",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Personnel nr."),
      headerClassName: "tableHeader",
      field: "personNumber",
      flex: 0.2,
    },
    {
      headerName: "Name",
      headerClassName: "tableHeader",
      field: "listName",
      renderCell: (params) => {
        return (
          <span className={classes.errorSpan}>
            {params.value}
            {params.row.approverId === "0" ? (
              <SomuraTooltip title={title()} placement="right">
                <ErrorOutlineIcon className={classes.errorIcon} />
              </SomuraTooltip>
            ) : (
              <></>
            )}
          </span>
        );
      },
      flex: 0.3,
    },
    {
      headerName: t("Initials"),
      headerClassName: "tableHeader",
      field: "initials",
      flex: 0.1,
    },
    {
      headerName: t("Position"),
      headerClassName: "tableHeader",
      field: "position",
      flex: 0.2,
    },
    {
      headerName: t("Entry date"),
      headerClassName: "tableHeader",
      field: "startDate",
      sortComparator: (a, b) => sortByDate(a, b),
      flex: 0.2,
    },
    {
      headerName: t("Exit date"),
      headerClassName: "tableHeader",
      field: "endDate",
      sortComparator: (a, b) => sortByDate(a, b),
      flex: 0.2,
    },
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "approverId",
      hide: true,
      filterable: false,
    },
  ];

  const rows = props.personsData.map((PD) => ({
    id: PD.id,
    personNumber: PD.personNumber,
    listName: PD.listName,
    initials: PD.initials,
    position: PD.position,
    startDate: moment(PD.startDate).format("L"),
    endDate: isNaN(PD.endDate) ? moment(PD.endDate).format("L") : null,
    approverId: PD.approverId,
  }));

  useEffect(() => {
    props.setPersonId(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (params) => {
    props.setPersonId(params);
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "new":
        props.setPersonId(0);
        break;
      default:
    }
  };

  return (
    <div className="content">
      <Paper square elevation={8} className="contentPaper">
        <ScreenTitle
          title="Employees"
          class="masterdata"
          icon={
            <PersonsIcon
              viewBox="0 0 24 24"
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <Collapse in={props.personId === -1} timeout={"auto"}>
          <SomuraDataGrid
            tableHeight={"calc(100vh - 200px)"}
            rows={rows}
            columns={columns}
            defaultSortModel={[
              {
                field: "listName",
                sort: "asc",
              },
            ]}
            filterModel={{
              items: [
                {
                  columnField: "endDate",
                  operatorValue: "isEmpty",
                },
              ],
            }}
            onRowClick={(params) => handleRowClick(params)}
            csvFileName={t("Employees")}
          />
          <Fab
            variant="extended"
            color="primary"
            size="medium"
            className={classes.fab}
            onClick={() => handleButtonClick("new")}
            disabled={!props.isadmin}
          >
            <AddIcon />
            {t("New employee")}
          </Fab>
        </Collapse>
        <Collapse in={props.personId >= 0} timeout={"auto"}>
          <PersonsForm />
        </Collapse>
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Persons);
