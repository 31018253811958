import {
  FETCH_OFFDAYTYPES_REQUEST,
  FETCH_OFFDAYTYPES_SUCCESS,
  FETCH_OFFDAYTYPESUSER_REQUEST,
  FETCH_OFFDAYTYPESUSER_SUCCESS,
  OFFDAYTYPES_FAILURE,
  UPDATE_OFFDAYTYPE,
  ADD_OFFDAYTYPE,
  DELETE_OFFDAYTYPE,
  SET_OFFDAYTYPE_ID,
  SAVE_OFFDAYTYPENAME
} from "actions/types";

import {
  offDayTypes,
  offDayTypesUser,
  offDayTypeUpdate,
  offDayTypeAdd,
  offDayTypeDelete,
  saveOffDayTypeNames,
} from "../api";
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";

const fetchOffDayTypesRequest = () => {
  return {
    type: FETCH_OFFDAYTYPES_REQUEST
  }
}

const fetchOffDayTypesSuccess = (offDayTypes) => {
  return {
    type: FETCH_OFFDAYTYPES_SUCCESS,
    payload: offDayTypes
  }
}

const fetchOffDayTypesUserRequest = () => {
  return {
    type: FETCH_OFFDAYTYPESUSER_REQUEST
  }
}

const fetchOffDayTypesUserSuccess = (offDayTypes) => {
  return {
    type: FETCH_OFFDAYTYPESUSER_SUCCESS,
    payload: offDayTypes
  }
}

const offDayTypesFailure = (err) => {
  return {
    type: OFFDAYTYPES_FAILURE,
    payload: err
  }
}

export const fetchOffDayTypes = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchOffDayTypesRequest())
    try {
      const offDayTypesJSONData = await dispatch(offDayTypes())
      await dispatch(fetchOffDayTypesSuccess(offDayTypesJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(offDayTypesFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

export const fetchOffDayTypesUser = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchOffDayTypesUserRequest())
    try {
      const offDayTypesJSONData = await dispatch(offDayTypesUser())
      await dispatch(fetchOffDayTypesUserSuccess(offDayTypesJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(offDayTypesFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

export const setOffDayTypeId = (id) => {
  return {
    type: SET_OFFDAYTYPE_ID,
    payload: id
  }
}

const updateOffDayTypeData = (values) => {
  return {
    type: UPDATE_OFFDAYTYPE,
    payload: values
  }
}

export const updateOffDayType = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(offDayTypeUpdate(values));
      await dispatch(updateOffDayTypeData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update offday type', err); // eslint-disable-line no-console
      await dispatch(offDayTypesFailure(err));
      await dispatch(dataFailure())
    }
  }
}

const addOffDayTypesData = (values) => {
  return {
    type: ADD_OFFDAYTYPE,
    payload: values
  }
}

export const addOffDayType = (values) => {
  return async (dispatch) => {
    try {
      const responseJSON = await dispatch(offDayTypeAdd(values))
      await dispatch(addOffDayTypesData({...values, id:responseJSON.id}))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(offDayTypesFailure(err))
      await dispatch(dataFailure())
    }
  }
}

const deleteOffDayTypeData = (id) => {
  return {
    type: DELETE_OFFDAYTYPE,
    payload: id
  }
}

export const deleteOffDayType = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(offDayTypeDelete(id));
      await dispatch(deleteOffDayTypeData(id))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to delete offday type data', err); // eslint-disable-line no-console
      await dispatch(offDayTypesFailure(err))
      await dispatch(dataFailure())
    }
  }
}

const saveOffDayTypeNamesSuccess = (offDayTypeNames) => {
  return {
    type: SAVE_OFFDAYTYPENAME,
    payload: offDayTypeNames,
  };
};

export const saveOffDayTypeName = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(saveOffDayTypeNames(values));
      await dispatch(saveOffDayTypeNamesSuccess(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};