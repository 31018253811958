import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setGlobalSettings } from "redux/index";
import { getGlobalSetting } from "Components/Utilities";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraTextField from "Components/SomuraTextField";
import SomuraLabel from "Components/SomuraLabel";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    globalSettings: state.settings.GlobalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalSettings: (values) => dispatch(setGlobalSettings(values)),
  };
};

const BreakDeductionForm = ({ globalSettings, setGlobalSettings }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "timetracking";
  const [state, setState] = useState({
    useBreakDeduction:
      parseInt(getGlobalSetting(component, "useBreakDeduction")) === 1,
    minWorkMinutes: parseInt(getGlobalSetting(component, "minWorkMinutes")),
    minutesEmp1: parseInt(getGlobalSetting(component, "minutesEmp1")),
    deductionEmp1: parseInt(getGlobalSetting(component, "deductionEmp1")),
    minutesEmp2: parseInt(getGlobalSetting(component, "minutesEmp2")),
    deductionEmp2: parseInt(getGlobalSetting(component, "deductionEmp2")),
    minutesTra1: parseInt(getGlobalSetting(component, "minutesTra1")),
    deductionTra1: parseInt(getGlobalSetting(component, "deductionTra1")),
    minutesTra2: parseInt(getGlobalSetting(component, "minutesTra2")),
    deductionTra2: parseInt(getGlobalSetting(component, "deductionTra2")),
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value.toString(),
    });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: name,
      value: value ? "1" : "0",
    });
  };

  useEffect(() => {
    setState({
      useBreakDeduction:
        parseInt(getGlobalSetting(component, "useBreakDeduction")) === 1,
      minWorkMinutes: parseInt(getGlobalSetting(component, "minWorkMinutes")),
      minutesEmp1: parseInt(getGlobalSetting(component, "minutesEmp1")),
      deductionEmp1: parseInt(getGlobalSetting(component, "deductionEmp1")),
      minutesEmp2: parseInt(getGlobalSetting(component, "minutesEmp2")),
      deductionEmp2: parseInt(getGlobalSetting(component, "deductionEmp2")),
      minutesTra1: parseInt(getGlobalSetting(component, "minutesTra1")),
      deductionTra1: parseInt(getGlobalSetting(component, "deductionTra1")),
      minutesTra2: parseInt(getGlobalSetting(component, "minutesTra2")),
      deductionTra2: parseInt(getGlobalSetting(component, "deductionTra2")),
    });
  }, [globalSettings]);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SomuraSwitchField
              name="useBreakDeduction"
              checked={state.useBreakDeduction}
              onChange={handleSwitchChange}
              label={t("Use automatic break deduction")}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Employees")}</div>
              </Grid>
              <Grid item xs={8}>
                <SomuraLabel
                  text={t("Working time more than")}
                  disabled={!state.useBreakDeduction}
                  alignLeft
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraLabel
                  text={t("Deduction")}
                  disabled={!state.useBreakDeduction}
                  alignLeft
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="number"
                  value={state.minutesEmp1}
                  onChange={handleChange}
                  disabled={!state.useBreakDeduction}
                  min="5"
                  max="600"
                  step="5"
                  name="minutesEmp1"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="number"
                  value={state.deductionEmp1}
                  onChange={handleChange}
                  disabled={!state.useBreakDeduction}
                  min="5"
                  max="600"
                  step="5"
                  name="deductionEmp1"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="number"
                  value={state.minutesEmp2}
                  onChange={handleChange}
                  disabled={!state.useBreakDeduction}
                  min="5"
                  max="600"
                  step="5"
                  name="minutesEmp2"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="number"
                  value={state.deductionEmp2}
                  onChange={handleChange}
                  disabled={!state.useBreakDeduction}
                  min="5"
                  max="600"
                  step="5"
                  name="deductionEmp2"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Minors")}</div>
              </Grid>
              <Grid item xs={8}>
                <SomuraLabel
                  text={t("Working time more than")}
                  disabled={!state.useBreakDeduction}
                  alignLeft
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraLabel
                  text={t("Deduction")}
                  disabled={!state.useBreakDeduction}
                  alignLeft
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="number"
                  value={state.minutesTra1}
                  onChange={handleChange}
                  disabled={!state.useBreakDeduction}
                  min="5"
                  max="600"
                  step="5"
                  name="minutesTra1"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="number"
                  value={state.deductionTra1}
                  onChange={handleChange}
                  disabled={!state.useBreakDeduction}
                  min="5"
                  max="600"
                  step="5"
                  name="deductionTra1"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="number"
                  value={state.minutesTra2}
                  onChange={handleChange}
                  disabled={!state.useBreakDeduction}
                  min="5"
                  max="600"
                  step="5"
                  name="minutesTra2"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <SomuraTextField
                  type="number"
                  value={state.deductionTra2}
                  onChange={handleChange}
                  disabled={!state.useBreakDeduction}
                  min="5"
                  max="600"
                  step="5"
                  name="deductionTra2"
                  label={t("Minutes")}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakDeductionForm);
