import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchCompanies,
  fetchCountries,
  fetchIndustries,
  setSelectedCompanyId,
} from "redux/index";
import SomuraDataGrid from "Components/SomuraDataGrid";
import CompanyForm from "./CompanyForm";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  TableRow: {
    cursor: "pointer",
  },
}));

const mapStateToProps = (state) => {
  return {
    companies: state.companies.Companies,
    selectedCompanyId: state.companies.selectedCompanyId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanies: () => dispatch(fetchCompanies()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchIndustries: () => dispatch(fetchIndustries()),
    setSelectedCompanyId: (id) => dispatch(setSelectedCompanyId(id)),
    // fetchRegions: () => dispatch(fetchRegions()),
  };
};

function CompaniesTable({
  companies,
  selectedCompanyId,
  setSelectedCompanyId,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "ID",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
    },
    {
      headerName: "CITY",
      headerClassName: "tableHeader",
      field: "city",
      hide: true,
    },
    {
      headerName: t("Company"),
      headerClassName: "tableHeader",
      field: "name",
      flex: 0.2,
    },
    {
      headerName: t("Address"),
      headerClassName: "tableHeader",
      field: "zip",
      flex: 0.2,
    },
    {
      headerName: t("Industry"),
      headerClassName: "tableHeader",
      field: "industryName",
      flex: 0.2,
    },
    {
      headerName: t("Number of persons"),
      headerClassName: "tableHeader",
      field: "numAssigned",
      headerAlign: "center",
      align: "center",
      flex: 0.2,
    },
  ];

  const rows = companies.map(C => ({
    ...C,
    zip: C.zip + " " + C.city
  }))

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "new":
        // props.newDepartment();
        setSelectedCompanyId("0");
        break;
      default:
    }
  };

    const handleRowClick = (params) => {
      setSelectedCompanyId(params);
    };

  useEffect(() => {
    props.fetchCompanies();
    props.fetchIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Collapse in={selectedCompanyId === -1} timeout={"auto"}>
        <div className={classes.TableContainer}>
          <SomuraDataGrid
            tableHeight="65vh"
            rows={rows}
            columns={columns}
            defaultSortModel={[
              {
                field: "name",
                sort: "asc",
              },
            ]}
            onRowClick={(params) => handleRowClick(params)}
            csvFileName={t("Companies")}
          />
          <Fab
            variant="extended"
            color="primary"
            size="medium"
            className={classes.fab}
            onClick={() => handleButtonClick("new")}
          >
            <AddIcon />
            {t("New company")}
          </Fab>
        </div>
      </Collapse>
      <Collapse in={selectedCompanyId >= 0} timeout={"auto"}>
        <CompanyForm handleClose={() => setSelectedCompanyId(-1)} />
      </Collapse>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);
