import {
  FETCH_INDUSTRIES_SUCCESS,
  FETCH_INDUSTRIES_REQUEST,
  INDUSTRIES_FAILURE,
  SET_INDUSTRY_ID,
  SAVE_INDUSTRY,
  DELETE_INDUSTRY,
} from "actions/types";
import produce from "immer";

const initialState = {
  Industries: [],
  loading: false,
  error: "",
  selectedIndustryId: -1
};

const industriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key
    switch (action.type) {
      case FETCH_INDUSTRIES_REQUEST:
        draft.loading = true;
        break;
      case FETCH_INDUSTRIES_SUCCESS:
        draft.Industries = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      case INDUSTRIES_FAILURE:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case SAVE_INDUSTRY:
        if (action.payload.id === 0) {
          // insert new industry
          draft.Industries = [...draft.Industries, action.payload];
        } else {
          // update industry
          key = draft.Industries.findIndex((I) => I.id === action.payload.id);
          draft.Industries[key] = action.payload;
        }
        draft.selectedIndustryId = -1;
        break;
      case DELETE_INDUSTRY:
        key = draft.Industries.findIndex(I => I.id === action.payload.id)
        draft = {Industries: draft.Industries.splice(key, 1)}
        draft.selectedIndustryId = -1;       
        break
      case SET_INDUSTRY_ID:
        draft.selectedIndustryId = action.payload;
        break;
      default:
        return draft;
    }
  });

export default industriesReducer;
