import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchMessages, fetchMessageFolders } from "redux/index";
import makeStyles from "@mui/styles/makeStyles";
import SomuraTooltip from "Components/SomuraTooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MessageFolder from "./MessageFolder";
import NewMessageFolder from "./NewMessageFolder";
import FlipMove from "react-flip-move";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PushPinIcon from "@mui/icons-material/PushPin";
import IconButton from "@mui/material/IconButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 190px)",
    marginLeft: "10px",
    width: "16rem",
    backgroundColor: theme.palette.background.messageList,
    display: "grid",
    gridTemplateRows: "2.3rem 1fr",
  },
  listTop: {
    paddingLeft: "4px",
    display: "grid",
    gridTemplateColumns: "1.5rem 1fr 1rem 1rem",
    gridTemplateRows: "1",
    alignItems: "center",
  },
  listTitle: {
    gridRow: "1",
    fontSize: "1.3em",
    lineHeight: "1.3rem",
    paddingLeft: "10px",
    color: theme.palette.text.screenTitle,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  listContainer: {
    overflowY: "auto",
    gridRow: "2",
  },
  folderList: {
    padding: "0 4px 0 4px!important",
  },
  listItem: {
    padding: "0 0 4px 0 !important",
    display: "block",
  },
  listPaper: {
    padding: "0 !important",
    margin: "2px 6px",
    backgroundColor: theme.palette.background.messageListItem,
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    borderRadius: "4px",
  },
  folderIcon: {},
  folder: {
    fontSize: "1.2em",
    height: "2rem",
    width: "100%",
    margin: "4px",
    display: "flex",
    cursor: "pointer",
  },
  selected: {
    backgroundColor: theme.palette.background.messageListHighlight,
  },
  customHandleE: {
    top: "50%",
    marginTop: "-4px",
    cursor: "ew-resize",
  },
}));

const mapStateToProps = (state) => {
  return {
    logged: state.loggeduser.LoggedUser.id,
    folders: state.messagefolders.MessageFolders,
    folder: state.messagefolders.selectedFolderId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMessageFolders: (id) => dispatch(fetchMessageFolders({ userId: id })),
    fetchMessages: (id) => dispatch(fetchMessages({ userId: id })),
  };
};

const MessageFolders = ({ logged, folders, folder, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [newParentFolder, setNewParentFolder] = useState();

  const createFolder = () => {
    setNewParentFolder(folder);
  };

  const handleRefresh = () => {
    props.fetchMessageFolders(logged);
    props.fetchMessages(logged);
  };

  const folderList =
    folders.length > 0 ? (
      folders.map((F) => (
        <div key={"F" + F.id}>
          <ListItem
            button
            disableGutters
            key={F.id}
            className={classes.listItem}
          >
            <MessageFolder
              folderId={F.id}
              folderName={F.folderName}
              padding={(F.folderLevel - 1) * 15}
              level={F.folderLevel}
              hasSubFolders={
                folders.filter((SF) => SF.parentId === F.id).length > 0
              }
              highlight={folder === F.id}
              deleteable={true}
              systemFolder={false}
              setNewFolder={(folderId) => createFolder(folderId)}
            />
          </ListItem>
          {newParentFolder === F.id && (
            <ListItem
              button
              disableGutters
              key={"I" + F.id}
              className={classes.listItem}
            >
              <NewMessageFolder
                padding={F.folderLevel * 15}
                level={parseInt(F.folderLevel) + 1}
                highlight={folder === F.id}
                parentFolderId={newParentFolder}
                setNewFolder={createFolder}
              />
            </ListItem>
          )}
        </div>
      ))
    ) : (
      <div></div>
    );

  return (
    <div
      className={classes.root}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className={classes.listTop}>
        <SomuraTooltip title={t("Refresh")} placement="top">
          <IconButton
            className={classes.button}
            style={{ gridColumn: props.show ? "3" : "1" }}
            onClick={handleRefresh}
          >
            <AutorenewIcon className={classes.buttonIcon} />
          </IconButton>
        </SomuraTooltip>
        {props.pinned ? (
          <SomuraTooltip title={t("Hide folders")} placement="top">
            <IconButton
              className={classes.button}
              style={{ gridColumn: "-1" }}
              onClick={props.handleDetachFolders}
            >
              {props.show && <ChevronLeftIcon className={classes.buttonIcon} />}
            </IconButton>
          </SomuraTooltip>
        ) : (
          <SomuraTooltip title={t("Attach folders")} placement="top">
            <IconButton
              className={classes.button}
              style={{ gridColumn: "-1" }}
              onClick={props.handleAttachFolders}
            >
              {props.show && <PushPinIcon className={classes.buttonIcon} />}
            </IconButton>
          </SomuraTooltip>
        )}
        <div
          className={classes.listTitle}
          style={{ gridColumn: props.show ? "1 / 3" : "2" }}
        >
          {t("Folders")}
        </div>
      </div>
      <div className={classes.listContainer}>
        <List className={classes.folderList} component="nav">
          <FlipMove>
            <ListItem
              button
              disableGutters
              key={"0"}
              className={classes.listItem}
            >
              <MessageFolder
                folderId={"0"}
                folderName={t("Inbox")}
                padding={0}
                deleteable={false}
                systemFolder={true}
                highlight={folder === "0"}
                setNewFolder={createFolder}
              />
            </ListItem>
            {newParentFolder === "0" && (
              <ListItem
                button
                key={"I01"}
                className={
                  classes.listItem +
                  " " +
                  (folder === "I01" ? classes.selected : "")
                }
              >
                <NewMessageFolder
                  NewfolderId={"I0"}
                  parentFolderId="0"
                  padding={0}
                  level={2}
                  highlight={folder === "I01"}
                  setNewFolder={(id) => createFolder(id)}
                />
              </ListItem>
            )}
            {folderList}
            <ListItem button key={"-4"} className={classes.listItem}>
              <MessageFolder
                folderId={"-4"}
                folderName={t("Drafts")}
                padding={0}
                highlight={folder === "-4"}
                deleteable={false}
                systemFolder={true}
              />
            </ListItem>
            <ListItem button key={"-2"} className={classes.listItem}>
              <MessageFolder
                folderId={"-2"}
                folderName={t("Outbox")}
                padding={0}
                highlight={folder === "-2"}
                deleteable={false}
                systemFolder={true}
              />
            </ListItem>
            <ListItem button key={"-1"} className={classes.listItem}>
              <MessageFolder
                folderId={"-1"}
                folderName={t("Recycle bin")}
                padding={0}
                highlight={folder === "-1"}
                deleteable={false}
                systemFolder={true}
              />
            </ListItem>
          </FlipMove>
        </List>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageFolders);
