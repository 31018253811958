import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedMovementTypeId } from "redux/index";
import Collapse from "@mui/material/Collapse";
import makeStyles from "@mui/styles/makeStyles";
import SomuraDataGrid from "Components/SomuraDataGrid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import MovementTypesForm from "./MovementTypesForm";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  const movementTypes = state.articles.MovementTypes;
  const selectedId = state.articles.selectedMovementTypeId;
  return { movementTypes, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setId: (id) => dispatch(setSelectedMovementTypeId(id)),
  };
};

function MovementTypesTable({ movementTypes, selectedId, setId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Name"),
      headerClassName: "tableHeader",
      field: "movementTypeName",
      flex: 0.2,
    },
  ];

  const rows = movementTypes?.map((L) => ({
    id: L.id,
    movementTypeName: L.movementTypeName,
  }));

  const handleRowClick = (params) => {
    setId(params);
  };

  const handleCreate = () => {
    setId(0);
  };

  return (
    <>
      <Collapse in={selectedId < 0} timeout={"auto"}>
        <SomuraDataGrid
          tableHeight={"calc(100vh - 260px)"}
          rows={rows}
          columns={columns}
          defaultSortModel={[
            {
              field: "movementTypeName",
              sort: "asc",
            },
          ]}
          onRowClick={(params) => handleRowClick(params)}
          csvFileName={t("Movement types")}
        />
        <Fab
          variant="extended"
          color="primary"
          size="medium"
          className={classes.fab}
          onClick={handleCreate}
        >
          <AddIcon />
          {t("New movement type")}
        </Fab>
      </Collapse>
      <Collapse in={selectedId >= 0} timeout={"auto"}>
        <MovementTypesForm />
      </Collapse>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MovementTypesTable);
