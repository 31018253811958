import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setSelectedReasonId,
  createArticleReason,
  updateArticleReason,
} from "redux/index";

import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSwitchField from "Components/SomuraSwitchField";
import makeStyles from "@mui/styles/makeStyles";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
}));

const mapStateToProps = (state) => {
  const reasons = state.articles.Reasons;
  const selectedId = state.articles.selectedReasonId;
  return { reasons, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedId: (id) => dispatch(setSelectedReasonId(id)),
    create: (values) => dispatch(createArticleReason(values)),
    update: (values) => dispatch(updateArticleReason(values)),
  };
};

const ReasonsForm = ({
  reasons,
  selectedId,
  setSelectedId,
  create,
  update,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: "0",
    reason: "",
    isHandoutReason: false,
    isReturnReason: false
  };
  const [state, setState] = useState(emptyState);
  const formHeader =
    selectedId > 0
      ? t("Reason") + ": " + state.reason
      : t("New reason");

  const handleClose = () => {
    setSelectedId(-1);
  };

  const handleSubmit = () => {
    if (selectedId === 0) {
      // Create new location
      create(state);
    } else {
      // Update location
      update(state);
    }
    handleClose();
  };

  const handleDelete = () => {
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

      const handleSwitchChange = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        setState({ ...state, [name]: value });
      };

  useEffect(() => {
    if (selectedId > 0) {
      setState(reasons.find((MT) => MT.id === selectedId));
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <SomuraTextField
              label={t("Reason")}
              type="text"
              name="reason"
              value={state.reason}
              maxlength="50"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            <SomuraSwitchField
              name="isHandoutReason"
              checked={state.isHandoutReason}
              onChange={handleSwitchChange}
              label={t("Use reason when handing out article")}
            />
          </Grid>
          <Grid item xs={12}>
            <SomuraSwitchField
              name="isReturnReason"
              checked={state.isReturnReason}
              onChange={handleSwitchChange}
              label={t("Use reason when user returns article")}
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton onClick={handleCancel} />
        {selectedId > 0 && (
          <DeleteButton onClick={handleDelete} disabled={state.neverDelete} />
        )}
        <SaveButton onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReasonsForm);
