import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setSelectedMovementTypeId,
  createMovementType,
  updateMovementType,
} from "redux/index";

import Grid from "@mui/material/Grid";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import makeStyles from "@mui/styles/makeStyles";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
}));

const mapStateToProps = (state) => {
  const movementTypes = state.articles.MovementTypes;
  const locations = state.articles.Locations;
  const selectedId = state.articles.selectedMovementTypeId;
  return { movementTypes, locations, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedId: (id) => dispatch(setSelectedMovementTypeId(id)),
    create: (values) => dispatch(createMovementType(values)),
    update: (values) => dispatch(updateMovementType(values)),
  };
};

const MovementTypesForm = ({
  movementTypes,
  locations,
  selectedId,
  setSelectedId,
  create,
  update,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: "0",
    movementTypeName: "",
    firstMovement: false,
    needsLocation: false,
    needsUser: false,
    blocksArticle:false,
    neverDelete: false,
    defaultLocationId: "0",
  };
  const [state, setState] = useState(emptyState);
  const formHeader =
    selectedId > 0
      ? t("Movement type") + ": " + state.movementTypeName
      : t("New movement type");

   var locationOptions = locations
    .map((L) => ({
      label: L.locationName,
      value: L.id,
    }));
    locationOptions.unshift({ label: "", value: "0" });


  const handleClose = () => {
    setSelectedId(-1);
  };

  const handleSubmit = () => {
    if (selectedId === 0) {
      // Create new article type
      create(state);
    } else {
      // Update article type
      update(state);
    }
    handleClose();
  };

  const handleDelete = () => {
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (selectedId > 0) {
      setState(movementTypes.find((MT) => MT.id === selectedId));
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SomuraTextField
              label={t("Movement type")}
              type="text"
              name="movementTypeName"
              value={state.movementTypeName}
              maxlength="50"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="firstMovement"
              checked={state.firstMovement}
              onChange={handleSwitchChange}
              label={t("Is always first movement")}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="needsLocation"
              checked={state.needsLocation}
              onChange={handleSwitchChange}
              label={t("Needs location")}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="needsUser"
              checked={state.needsUser}
              onChange={handleSwitchChange}
              label={t("Needs user")}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <SomuraSwitchField
              name="blocksArticle"
              checked={state.blocksArticle}
              onChange={handleSwitchChange}
              label={t("Blocks article")}
            />
          </Grid> */}
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="defaultLocationId"
              label={t("Default location")}
              options={locationOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.defaultLocationId}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton onClick={handleCancel} />
        {selectedId > 0 && (
          <DeleteButton onClick={handleDelete} disabled={state.neverDelete} />
        )}
        <SaveButton onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MovementTypesForm);
