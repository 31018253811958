import React from "react";
import { useTranslation } from "react-i18next";
import { store } from "redux/store";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DashboardItem from "Components/DashboardItem";
import MailIcon from "@mui/icons-material/Mail";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontSize: "1rem",
    color: theme.palette.dashBoard.title,
  },
  tableCell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const mapStateToProps = (state) => {
  return {
    persons: state.persons.Persons,
  };
};

function UnreadMessages(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const noData =
    props.data && props.data.length === 0 ? t("No unread messages.") : "";

  function handleClick(value) {
    store.dispatch({
      type: "SET_SELECTED_FOLDER_ID",
      payload: 0,
    });
    store.dispatch({
      type: "SET_SELECTED_MESSAGE_ID",
      payload: value.messageId,
    });
    history.push("/MainMenu/Messages");
  }

  const title = t("Unread messages");

  const dataTable = () => {
    if (props.data) {
      return (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>{t("From")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("Received")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("Subject")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row.id} onClick={() => handleClick(row)}>
                <TableCell className={classes.tableCell}>
                  {row.fromUserName}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.creationDate}
                </TableCell>
                <TableCell className={classes.tableCell}>{row.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <DashboardItem
      title={title}
      value={props.value}
      iconColor="#C1651D"
      icon={<MailIcon style={{ viewBox: "0 0 100 100" }} />}
      table={dataTable()}
      nodata={noData}
    />
  );
}

export default connect(mapStateToProps, null)(UnreadMessages);
