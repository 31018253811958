import {
  FETCH_REMINDERTIMES_REQUEST,
  FETCH_REMINDERTIMES_SUCCESS
} from "actions/types";
import produce from "immer";

const initialState = {
  ReminderTimes: [],
  loading: false,
  error: "",
};

const remindertimesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_REMINDERTIMES_REQUEST:
        draft.loading = true;
        break;
      case FETCH_REMINDERTIMES_SUCCESS:
        draft.ReminderTimes = action.payload;
        draft.loading = false;
        draft.error = "";
        break;
      default:
        return state;
    }
  });

export default remindertimesReducer;
