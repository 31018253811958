import React, { useEffect, useState } from "react";
import {
  updateSpecialDay,
  setSpecialDayId,
  fetchSpecialDays,
  setSelectedCountryId,
  saveSpecialDayRegions,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraCheckListItem from "Components/SomuraCheckListItem";
import makeStyles from "@mui/styles/makeStyles";

import RegionsSelect from "./RegionsSelect";

import { easterDate, fourthAdventDate } from "Components/Utilities";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  dayDescription: {
    padding: "10px",
    fontSize: "18px",
  },
  days: {
    height: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nextOccurence: {
    textAlign: "center",
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
  },
}));

const mapStateToProps = (state) => {
  const specialDayId = state.specialdays.specialDayId;
  const specialDay = state.specialdays.SpecialDays.find(
    (SD) => SD.id === specialDayId
  );
  const countries = state.countries.Countries;
  const selectedCountryId = state.countries.selectedCountryId;
  return {
    specialDay,
    countries,
    selectedCountryId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSpecialDay: (values) => dispatch(updateSpecialDay(values)),
    setSpecialDayId: (id) => dispatch(setSpecialDayId(id)),
    fetchSpecialDays: () => dispatch(fetchSpecialDays()),
    setSelectedCountryId: (id) => dispatch(setSelectedCountryId(id)),
    saveSpecialDayRegions: (values) => dispatch(saveSpecialDayRegions(values)),
  };
};

const SpecialDaysForm = ({
  specialDay,
  countries,
  selectedCountryId,
  updateSpecialDay,
  setSpecialDayId,
  fetchSpecialDays,
  setSelectedCountryId,
  saveSpecialDayRegions,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    dayName: "",
    dayFactor: 0.0,
    isSpecialDay: false,
    isOffDay: false,
    isHalfDay: false,
    isMothersDay: false,
    useThisDay: false,
    alwaysUseThisDay: false,
    hasFixedDate: false,
    fixedDay: 0,
    fixedMonth: 0,
    referenceDay: "",
    referenceNumber: 0,
    numDays: 0,
    beforeAfter: "b",
    countryId: 0,
    regionId: 0,
    regionIds: [],
  };
  const [state, setState] = useState(emptyState);
  const [allCountries, setAllCountries] = useState();

  const selectedDay = specialDay;

  const formHeader = selectedDay
    ? t("Holiday") + ": " + selectedDay.dayName
    : t("New holiday");

  const handleSubmit = () => {
    updateSpecialDay(state);
    fetchSpecialDays();
    handleClose();
  };

  const handleClose = () => {
    setSpecialDayId(-1);
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "edit":
        handleClose();
        break;
      case "cancel":
        handleClose();
        break;
      default:
        handleClose();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "numDays") {
      setState({
        ...state,
        [name]: value,
        referenceNumber: state.beforeAfter === "b" ? value * -1 : value,
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    if (name === "beforeAfter") {
      setState({
        ...state,
        [name]: value,
        referenceNumber: state.beforeAfter === "b" ? value * -1 : value,
      });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
  };

  const handleCountryClick = (value) => {
    setSelectedCountryId(value);
  };

  const handleCountrySelect = (value) => {
    let prevState = [...allCountries];
    const key = prevState.findIndex((AC) => AC.value === value);
    saveSpecialDayRegions({
      id: selectedDay.id,
      key: value,
      value:
        prevState[key].numRegions > 0
          ? []
          : countries?.find((C) => C.id === value).regions.map((R) => R.id),
    });
    prevState[key].all = !prevState[key].all;
    prevState[key].none = !prevState[key].none;
    prevState[key].checked = prevState[key].all && !prevState[key].none;
    setAllCountries(prevState);
  };

  const handleAllClick = () => {
    const allSelected = allCountries.filter((AC) => AC.all).length > 1;
    let prevState = [...allCountries];
    // eslint-disable-next-line array-callback-return
    prevState.map((PS) => {
      if (PS.value > 0) {
        saveSpecialDayRegions({
          id: selectedDay.id,
          key: PS.id,
          value: allSelected
            ? []
            : countries?.find((C) => C.id === PS.id).regions.map((R) => R.id),
        });
      }
      PS.all = !allSelected;
      PS.none = allSelected;
      PS.checked = !allSelected;
      setAllCountries(prevState);
    });
  };

  const CountryList = () => {
    const listItems = [];
    // eslint-disable-next-line array-callback-return
    allCountries?.map((C) => {
      var itemText = C.label + " ";
      if (C.numRegions > 0) {
        switch (C.numRegions) {
          case 1:
            itemText += "(1 " + t("region") + ")";
            break;
          case countries.find((CO) => CO.id === C.value).numRegions:
            itemText +=
              "(" + t("All") + " " + C.numRegions + " " + t("regions") + ")";
            break;
          default:
            itemText += "(" + C.numRegions + " " + t("regions") + ")";
        }
      }
      listItems.push(
        <SomuraCheckListItem
          key={C.value}
          value={C.value}
          itemText={itemText}
          onClick={C.value === 0 ? handleAllClick : handleCountryClick}
          onCheckboxClick={C.value === 0 ? handleAllClick : handleCountrySelect}
          checked={C.checked}
          indeterminate={!C.all && !C.none && C.numRegions > 0}
          hideChevron={C.value === 0}
        />
      );
    });

    return listItems;
  };

  const nextOccurence = () => {
    var currentYear = moment().format("YYYY");
    var date;
    if (state.hasFixedDate) {
      date = moment(
        currentYear + "-" + state.fixedMonth + "-" + state.fixedDay
      );
    } else {
      switch (state.referenceDay) {
        case "easter":
          date = easterDate(currentYear);
          date.add(state.referenceNumber, "d");
          if (date < moment()) {
            date = easterDate(parseInt(currentYear) + 1);
            date.add(state.referenceNumber, "d");
          }
          break;
        case "fourthadvent":
          date = fourthAdventDate(currentYear);
          date.add(state.referenceNumber, "d");
          if (date < moment()) {
            date = fourthAdventDate(parseInt(currentYear) + 1);
            date.add(state.referenceNumber, "d");
          }
          break;
        default:
          date = moment();
      }
    }
    if (date < moment()) {
      date.add(1, "y");
    }
    return (
      <div className={classes.nextOccurence}>
        {date.isValid() ? date.format("dddd, D. MMMM YYYY") : t("Invalid date")}
      </div>
    );
  };

  useEffect(() => {
    if (selectedDay) {
      setState({
        ...selectedDay,
        fixedDay: parseInt(selectedDay.fixedDay),
        numDays: Math.abs(selectedDay.referenceNumber),
        beforeAfter: selectedDay.referenceNumber < 0 ? "b" : "a",
        countryId: 0,
        regionId: 0,
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  useEffect(() => {
    var allCountries = [
      {
        label: t("All countries"),
        value: 0,
        selected: true,
        numRegions: 0,
      },
    ];
    if (selectedDay) {
      // eslint-disable-next-line array-callback-return
      countries.map((C) => {
        const selectedRegions =
          selectedDay.countriesRegions?.find((CR) => CR.country === C.id)
            ?.regions?.length || 0;
        const countryRegions = countries.find(
          (CO) => CO.id === C.id
        ).numRegions;
        const all = selectedRegions === countryRegions && countryRegions > 0;
        const none = selectedRegions === 0 && countryRegions > 0;
        allCountries.push({
          label: C.ownName,
          value: C.id,
          checked: all && ((countryRegions === 0 && none) || !none),
          numRegions: selectedRegions,
          all: all,
          none: none,
        });
      });
      setAllCountries(allCountries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, countries]);

  return (
    <>
      <Paper square elevation={8}>
        <Collapse in={selectedCountryId <= 0} timeout={"auto"}>
          <div className="formHeader">{formHeader}</div>

          <div className={classes.thisForm}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={"subLine"}>{t("Date")}</div>
                    <SomuraSwitchField
                      disabled={selectedDay?.alwaysUseThisDay}
                      name="hasFixedDate"
                      checked={state.hasFixedDate}
                      onChange={handleSwitchChange}
                      label={t("Fixed date")}
                    />
                  </Grid>
                  {state.hasFixedDate ? (
                    <>
                      <Grid item xs={3}>
                        <SomuraTextField
                          type="number"
                          value={state.fixedDay}
                          onChange={handleChange}
                          min="1"
                          max="31"
                          name="fixedDay"
                          label={t("Day")}
                          variant="outlined"
                          size="small"
                          disabled={selectedDay?.alwaysUseThisDay}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SomuraTextField
                          type="number"
                          value={state.fixedMonth}
                          onChange={handleChange}
                          min="1"
                          max="12"
                          name="fixedMonth"
                          label={t("Month")}
                          variant="outlined"
                          size="small"
                          disabled={selectedDay?.alwaysUseThisDay}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={3}>
                        <SomuraTextField
                          type="number"
                          value={state.numDays}
                          onChange={handleChange}
                          min="1"
                          max="364"
                          name="numDays"
                          // label={t("Number")}
                          variant="outlined"
                          size="small"
                          disabled={selectedDay?.alwaysUseThisDay}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <div className={classes.days}>{t("Tage")}</div>
                      </Grid>
                      <Grid item xs={3}>
                        <SomuraAutocomplete
                          name="beforeAfter"
                          options={[
                            { label: t("before"), value: "b" },
                            { label: t("after"), value: "a" },
                          ]}
                          onChange={(values) =>
                            handleAutoCompleteChange(values)
                          }
                          value={state.beforeAfter}
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          disabled={selectedDay?.alwaysUseThisDay}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <SomuraAutocomplete
                          name="referenceDay"
                          options={[
                            { label: t("Easter Sunday"), value: "easter" },
                            {
                              label: t("Fourth Advent"),
                              value: "fourthadvent",
                            },
                          ]}
                          onChange={(values) =>
                            handleAutoCompleteChange(values)
                          }
                          value={state.referenceDay}
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          disabled={selectedDay?.alwaysUseThisDay}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("Properties")}</div>
                  <SomuraSwitchField
                    disabled={selectedDay && selectedDay.alwaysUseThisDay}
                    name="useThisDay"
                    checked={state.useThisDay}
                    onChange={handleSwitchChange}
                    label={t("Show in calendar")}
                  />
                </Grid>
                {selectedDay && selectedDay.isSpecialDay && (
                  <>
                    <Grid item xs={12}>
                      <SomuraSwitchField
                        disabled={selectedDay && selectedDay.alwaysUseThisDay}
                        name="isOffDay"
                        checked={state.isOffDay}
                        onChange={handleSwitchChange}
                        label={t(
                          "This day is off work. No vacation needs to be requested."
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SomuraTextField
                        type="number"
                        value={state.dayFactor}
                        onChange={handleChange}
                        min="0.0"
                        max="1.0"
                        step="0.1"
                        name="dayFactor"
                        label={t("Vacation deduction")}
                        variant="outlined"
                        size="small"
                        disabled={selectedDay?.isOffDay}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={"subLine"}>{t("Countries / Regions")}</div>
                  </Grid>
                  <Grid item xs={12}>
                    <CountryList />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Next occurence")}</div>
              </Grid>
              <Grid item xs={12}>
                {nextOccurence()}
              </Grid>
            </Grid>
            <div className="formBottom">
              <CancelButton
                onClick={() => {
                  handleButtonClick("cancel");
                }}
              />
              <SaveButton onClick={handleSubmit} />
            </div>
          </div>
        </Collapse>
        <Collapse in={selectedCountryId > 0} timeout={"auto"}>
          <RegionsSelect selectedDay={selectedDay} />
        </Collapse>
      </Paper>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialDaysForm);
