import React, { useEffect, useState, useCallback } from "react";
import {
  // fetchDepartments,
  updateDepartment,
  addDepartment,
  deleteDepartment,
  setDepartmentId,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import SomuraDataGrid from "Components/SomuraDataGrid";

import * as Yup from "yup";
import SomuraTextField from "Components/SomuraTextField";
import makeStyles from "@mui/styles/makeStyles";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const DepartmentSchema = Yup.object().shape({
  department: Yup.string().required("Pflichtfeld"),
});

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  const departments = state.departments.Departments;
  const departmentId = state.departments.selectedDepartmentId;
  const persons = state.persons.Persons.filter(P => P.active && P.departmentId === departmentId)
  return {
    departments,
    departmentId,
    persons
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // fetchDepartments: () => dispatch(fetchDepartments()),
    updateDepartment: (values) => dispatch(updateDepartment(values)),
    addDepartment: (values) => dispatch(addDepartment(values)),
    deleteDepartment: (id) => dispatch(deleteDepartment({ id: id })),
    setDepartmentId: (id) => dispatch(setDepartmentId(id)),
  };
};

const DepartmentForm = ({ persons, ...props }) => {
  const selectedDepartment = props.departments?.find(
    (d) => d.id === props.departmentId
  ) || null;
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: 0,
    numPersons: 0,
    department: "",
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);

  const formHeader = selectedDepartment
    ? t("Department") + ": " + selectedDepartment.department
    : t("New department");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await DepartmentSchema.isValid(state));
  });

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
    },
    {
      headerName: "Name",
      headerClassName: "tableHeader",
      field: "listName",
      filterable: false,
      sortable: false,
      flex: 1,
    },
  ];

  const rows = persons
    ?.map((P) => ({
      id: P.id,
      listName: P.listName,
    }));

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSubmit = () => {
    if (state.id === 0) {
      props.addDepartment(state);
    } else {
      props.updateDepartment(state);
    }
    props.setDepartmentId(-1);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "cancel":
        props.setDepartmentId(-1);
        break;
      case "delete":
        props.deleteDepartment(selectedDepartment.id);
        props.setDepartmentId(-1);
        break;
      default:
        props.setDepartmentId(-1);
    }
  };

  useEffect(() => {
    if (props.departmentId > 0) {
      setState({
        ...selectedDepartment,
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.departmentId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="department"
              label={t("Department")}
              value={state.department}
              maxlength="255"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <div className={"subLine"}>{t("Employees")}</div>
            </Grid>
            <SomuraDataGrid
              tableHeight={"calc(100vh - 340px)"}
              rows={rows}
              columns={columns}
              defaultSortModel={[
                {
                  field: "listName",
                  sort: "asc",
                },
              ]}
              csvFileName={t("Employees")}
              noToolbar
            />
          </Grid>
        </Grid>
      </div>
      <div className="paperBottom">
        <CancelButton
          onClick={() => {
            handleButtonClick("cancel");
          }}
        />
        <DeleteButton
          disabled={
            !selectedDepartment ||
            (selectedDepartment && selectedDepartment.numPersons > 0)
          }
          onClick={() => {
            handleButtonClick("delete");
          }}
        />
        <SaveButton onClick={handleSubmit} disabled={!valid} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentForm);
