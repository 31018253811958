import {
  FETCH_UNPUBLISHED_DAYS_REQUEST,
  FETCH_UNPUBLISHED_DAYS_SUCCESS,
  FETCH_UNPUBLISHED_DAYS_FAILURE
} from  'actions/types'

import { 
  unpublishedDays
} from '../api'

const unpublishedDaysFailure = (err) => {
  return {
    type: FETCH_UNPUBLISHED_DAYS_FAILURE,
    payload: err
  }
}

const fetchUnpublishedDaysRequest = () => {
  return {
    type: FETCH_UNPUBLISHED_DAYS_REQUEST
  }
}

const fetchUnpublishedDaysSuccess = (unpublishedDays) => {
  return {
    type: FETCH_UNPUBLISHED_DAYS_SUCCESS,
    payload: unpublishedDays
  }
}

export const fetchUnpublishedDays = (userId) => {
  return async (dispatch) => {
    await dispatch(fetchUnpublishedDaysRequest())
    try {
      const unpublishedDaysJSONData = await dispatch(unpublishedDays(userId))
      await dispatch(fetchUnpublishedDaysSuccess(unpublishedDaysJSONData))
    } catch(err) {
      await dispatch(unpublishedDaysFailure(err))
    }
  }
}