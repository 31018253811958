import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedDate, fetchUserTimesCum } from "redux/index";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { sortByDate } from "Components/Utilities";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { secondsToTime } from "Components/Utilities";
import TimeTrackForm from "./TimeTrackForm";

import ScreenTitle from "Components/ScreenTitle";
import TimeTrackIcon from "@mui/icons-material/TimerOutlined";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import SomuraAutocomplete from "Components/SomuraAutocomplete";
import RefreshButton from "Components/Buttons/RefreshButton";

import SomuraDataGrid from "Components/SomuraDataGrid";

const useStyles = makeStyles((theme) => ({
  timetrackheader: {
    marginBottom: "6px",
    height: "54px",
    textTransform: "uppercase",
    fontSize: "1.2rem",
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  column: {
    display: "flex",
    height: "2vh",
    maxWidth: "100%",
    alignItems: "center",
  },
  headerIcon: {
    cursor: "pointer",
    marginTop: "6px",
  },
  headerHomeIcon: {
    cursor: "pointer",
    marginTop: "6px",
    marginRight: "6px",
  },
  headerYear: {
    cursor: "pointer",
  },
  headerMonth: {
    minWidth: "7rem",
    textAlign: "center",
    cursor: "pointer",
  },
  root: {
    width: 500,
    "& .tableHeader": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.background.contrastText,
    },
  },
  contentPaper: {
    height: "calc(100vh - 112px)",
  },
  errorBadge: {
    padding: "0 4px",
    minWidth: "1.2rem",
    color: "white",
    fontSize: "0.8rem",
    lineHeight: "1.3rem",
    backgroundColor: theme.palette.red,
    height: "1.2rem",
    borderRadius: "0.6rem",
    textAlign: "center",
  },
  todayArrow: {
    color: "#7cb342",
    width: "40px",
    height: "40px",
  },
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "26px",
  },
  select: {
    marginLeft: "6px",
  },
  refresh: {
    width: "6rem",
    display: "flex",
    justifyContent: "center",
  },
}));

const TimeTrackTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggeduser.LoggedUser,
    timeData: state.times.UserTimesCum,
    persons: state.persons.PersonsMin.filter(
      (P) => P.lastName !== "" && P.active === true
    ),
    selectedDate: state.times.selectedDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserTimesCum: (values) => dispatch(fetchUserTimesCum(values)),
    setSelectedDate: (id) => dispatch(setSelectedDate(id)),
  };
};

function TimeTracking({
  loggedUser,
  timeData,
  persons,
  selectedDate,
  fetchUserTimesCum,
  setSelectedDate,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const emptyState = {
    year: moment().format("YYYY"),
    month: moment().format("MM"),
    userId: loggedUser?.id,
    reload: false,
  };
  const [state, setState] = useState(emptyState);

  const columns = [
    {
      headerName: "ID",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Date"),
      headerClassName: "tableHeader",
      field: "date",
      flex: 0.1,
      filterable: false,
      sortable: false,
    },
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "numFaulty",
      width: 30,
      minWidth: 30,
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.row.date === moment().format("dd., L") ? (
              <div className={classes.todayArrow}>
                <ArrowRightIcon style={{ width: "100%", height: "100%" }} />
              </div>
            ) : params.value ? (
              <div className={classes.errorBadge}>{params.value}</div>
            ) : (
              <></>
            )}
          </span>
        );
      },
    },
    {
      headerName: t("Start"),
      headerClassName: "tableHeader",
      field: "startTime",
      flex: 0.1,
      filterable: false,
      sortable: false,
    },
    {
      headerName: t("End"),
      headerClassName: "tableHeader",
      field: "endTime",
      flex: 0.1,
      filterable: false,
      sortable: false,
    },
    {
      headerName: t("Work time"),
      headerClassName: "tableHeader",
      field: "w_seconds",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.value ? secondsToTime(params.value, false, true) : <></>}
          </span>
        );
      },
    },
    {
      headerName: t("Break"),
      headerClassName: "tableHeader",
      field: "b_seconds",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.value ? secondsToTime(params.value, false, true) : <></>}
          </span>
        );
      },
    },
    {
      headerName: t("Break deduction"),
      headerClassName: "tableHeader",
      field: "deduction",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.value ? secondsToTime(params.value, false, true) : <></>}
          </span>
        );
      },
    },
    {
      headerName: t("Work time net"),
      headerClassName: "tableHeader",
      field: "w_net",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.value ? secondsToTime(params.value, false, true) : <></>}
          </span>
        );
      },
    },
    {
      headerName: "Created",
      headerClassName: "tableHeader",
      field: "createdOn",
      hide: true,
      filterable: false,
      sortComparator: (a, b) => sortByDate(a, b),
    },
  ];

  const rows = timeData.map((TD) => ({
    id: TD.id,
    date: moment(TD.date).format("dd., L"),
    startTime: TD.startTime,
    endTime: TD.endTime ? TD.endTime : null,
    w_seconds: TD.w_seconds,
    b_seconds: TD.b_seconds,
    numFaulty: TD.numFaulty,
    deduction: TD.d_seconds,
    w_net: TD.w_seconds - TD.d_seconds,
    createdOn: TD.createdOn,
  }));

  const handleRefresh = () => {
    setState({ ...state, reload: true });
  };

  const nextYear = () => {
    setState({
      ...state,
      year: parseInt(state.year) + 1,
      reload: true,
    });
  };

  const prevYear = () => {
    setState({
      ...state,
      year: parseInt(state.year) - 1,
      reload: true,
    });
  };

  const thisYear = () => {
    setState({
      ...state,
      year: moment().format("YYYY"),
      reload: true,
    });
  };

  const nextMonth = () => {
    let newMonth = parseInt(state.month) + 1;
    let newYear = parseInt(state.year);
    if (newMonth === 13) {
      newMonth = 1;
      newYear++;
    }
    setState({
      ...state,
      month: newMonth,
      year: newYear,
      reload: true,
    });
  };

  const prevMonth = () => {
    let newMonth = parseInt(state.month) - 1;
    let newYear = parseInt(state.year);
    if (newMonth === 0) {
      newMonth = 12;
      newYear--;
    }
    setState({
      ...state,
      month: newMonth,
      year: newYear,
      reload: true,
    });
  };

  const thisMonth = () => {
    setState({
      ...state,
      month: moment().format("MM"),
      reload: true,
    });
  };

  const today = () => {
    setState({
      ...state,
      year: moment().format("YYYY"),
      month: moment().format("MM"),
      reload: true,
    });
  };

  useEffect(() => {
    setSelectedDate("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState({...emptyState, userId: loggedUser.id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    if(state.userId > 0){
      fetchUserTimesCum({
        userId: state.userId,
        month: state.month,
        year: state.year,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  var personOptions = persons?.map((P) => ({
    label: P.listName,
    value: P.id,
  }));
  personOptions.push({ label: "", value: "0" });

  const handleRowClick = (params) => {
    const selDate = timeData.find((T) => T.id === params).date;
    setSelectedDate(selDate);
  };

  return (
    <div className="content">
      <Paper square elevation={8} className="contentPaper">
        <ScreenTitle
          title="Personal worktimes"
          class="masterdata"
          icon={
            <TimeTrackIcon
              viewBox="0 0 24 24"
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <Collapse in={selectedDate === ""} timeout={"auto"}>
          <div className={classes.timetrackheader}>
            <div className={classes.column}>
              <TimeTrackTooltip
                title={t("Previous year")}
                placement="top"
                arrow={true}
              >
                <div
                  className={classes.headerIcon}
                  onClick={() => {
                    prevYear();
                  }}
                >
                  <ChevronLeftIcon />
                </div>
              </TimeTrackTooltip>
            </div>
            <div className={classes.column}>
              <TimeTrackTooltip
                title={t("This year")}
                placement="top"
                arrow={true}
              >
                <span
                  className={classes.headerYear}
                  onClick={() => {
                    thisYear();
                  }}
                >
                  {state.year}
                </span>
              </TimeTrackTooltip>
            </div>
            <div className={classes.column}>
              <TimeTrackTooltip
                title={t("Next year")}
                placement="top"
                arrow={true}
              >
                <div
                  className={classes.headerIcon}
                  onClick={() => {
                    nextYear();
                  }}
                >
                  <ChevronRightIcon />
                </div>
              </TimeTrackTooltip>
            </div>
            <div className={classes.column}>
              <TimeTrackTooltip
                title={t("Previous month")}
                placement="top"
                arrow={true}
              >
                <div
                  className={classes.headerIcon}
                  onClick={() => {
                    prevMonth();
                  }}
                >
                  <ChevronLeftIcon />
                </div>
              </TimeTrackTooltip>
            </div>
            <div className={classes.column}>
              <TimeTrackTooltip
                title={t("This month")}
                placement="top"
                arrow={true}
              >
                <div
                  className={classes.headerMonth}
                  onClick={() => {
                    thisMonth();
                  }}
                >
                  {moment([2000, state.month - 1, 1]).format("MMMM")}
                </div>
              </TimeTrackTooltip>
            </div>
            <div className={classes.column}>
              <TimeTrackTooltip
                title={t("Next month")}
                placement="top"
                arrow={true}
              >
                <div className={classes.headerIcon} onClick={nextMonth}>
                  <ChevronRightIcon />
                </div>
              </TimeTrackTooltip>
            </div>
            <div className={classes.column}>
              <TimeTrackTooltip title={t("Today")} placement="top" arrow={true}>
                <div className={classes.headerHomeIcon} onClick={today}>
                  <HomeOutlinedIcon />
                </div>
              </TimeTrackTooltip>
            </div>

            <SomuraAutocomplete
              name="person"
              label={t("Employee")}
              value={state.userId}
              variant="outlined"
              size="small"
              fullWidth
              disableClearable={true}
              options={personOptions}
              disabled={true}
            />
            <div className={classes.refresh}>
              <RefreshButton onClick={handleRefresh} />
            </div>
          </div>

          <SomuraDataGrid
            tableHeight={"calc(100vh - 260px)"}
            rows={rows}
            columns={columns}
            defaultSortModel={[
              {
                field: "createdOn",
                sort: "asc",
              },
            ]}
            onRowClick={handleRowClick}
            csvFileName={t("Times")}
          />
        </Collapse>
        <Collapse in={selectedDate !== ""} timeout={"auto"}>
          <TimeTrackForm userId={state.userId} />
        </Collapse>
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTracking);
