import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchOffDayTypes, fetchMonth, fetchReminders } from "redux/index";
import moment from "moment";
import { store } from "redux/store";
import { getPersonalSetting } from "Components/Utilities";

import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "@mui/material/Tooltip";
import ScreenTitle from "Components/ScreenTitle";

import RemindersBar from "Calendars/Components/RemindersBar";
import ContextMenu from "Calendars/Components/ContextMenu";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MonthCalIcon from "@mui/icons-material/TodayTwoTone";
import RefreshButton from "Components/Buttons/RefreshButton";
import MonthCalRows from "./MonthCalRows";
import DayInfo from "./DayInfo";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import CalToolTip from "./CalToolTip";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  yearcalheader: {
    marginBottom: "6px",
    height: "54px",
    textTransform: "uppercase",
    fontSize: "1.2rem",
    paddingTop: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  headerIcon: {
    cursor: "pointer",
    marginTop: "6px",
  },
  headerHomeIcon: {
    cursor: "pointer",
    marginTop: "6px",
    marginRight: "6px",
  },
  headerYear: {
    cursor: "pointer",
  },
  headerMonth: {
    minWidth: "7rem",
    textAlign: "center",
    cursor: "pointer",
  },
  select: {
    marginLeft: "6px",
  },
  refresh: {
    width: "6rem",
    display: "flex",
    justifyContent: "center",
  },
  reminder: {
    fontSize: "1.2rem",
    marginTop: "0.5rem",
    height: "2rem",
    color: theme.palette.main,
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  reminderFixed: {
    height: "2rem",
    width: "18rem",
    lineHeight: "2.1rem",
    padding: "0 10px",
    textAlign: "center",
    borderRadius: "1rem 0 0 1rem",
    backgroundColor: theme.palette.red,
    color: "white",
    display: "inline-block",
    position: "absolute",
    zIndex: 10,
  },
  reminderFixedRight: {
    height: "0",
    width: "0",
    marginLeft: "18rem",
    display: "inline-block",
    position: "absolute",
    borderLeft: "1rem solid " + theme.palette.red,
    borderTop: "1rem solid transparent",
    borderBottom: "1rem solid transparent",
    zIndex: 10,
  },
  singleReminder: {
    display: "inline-block",
    borderRadius: "4px",
    padding: "0 4px 0 4px",
    marginTop: "2rem",
    marginLeft: "20rem",
    fontSize: "1.1rem",
    maxWidth: "calc(100% - 21rem)",
    backgroundColor: theme.palette.background.messageList,
    boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    animation: "$moving 5s linear infinite",
  },
  reminderTime: {
    height: "1.8rem",
    fontSize: "1rem",
    backgroundColor: theme.palette.blue,
    padding: "0 4px",
    margin: "0 4px",
    borderRadius: "4px",
  },
  reminderTitle: {
    display: "inline-block",
    fontWeight: "400",
  },
  "@keyframes moving": {
    "0%": { transform: "translateY(0rem)" },
    "20%": { transform: "translateY(-1.8rem)" },
    "80%": { transform: "translateY(-1.8rem)" },
    "100%": { transform: "translateY(-4rem)" },
  },
  infoWrapper: {
    height: "2rem",
    marginTop: "4px",
    borderRadius: "1rem",
    border: "2px solid " + theme.palette.background.paperBottom,
    backgroundColor: "white",
    padding: "0 4px 0 8px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
  },
}));

const MonthSelectTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const mapStateToProps = (state) => {
  const displayReminder = state.reminders.displayReminder;
  return {
    loggedUser: state.loggeduser.LoggedUser,
    departments: state.departments.DepartmentsMin,
    displayReminder,
    showContextMenu: state.general.showContextMenu
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffDayTypes: () => dispatch(fetchOffDayTypes()),
    fetchMonth: (values) => dispatch(fetchMonth(values)),
    fetchReminders: (id) => dispatch(fetchReminders({ userId: id })),
  };
};

function MonthCalendar({
  loggedUser,
  departments,
  displayReminder,
  showContextMenu,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const emptyState = {
    year: moment().format("YYYY"),
    month: moment().format("MM"),
    departmentId: "0",
    userId: loggedUser ? loggedUser.id : "0",
    reload: false,
  };
  const [state, setState] = useState(emptyState);

  // // Personal settings
  const useCalendarTooltips =
    parseInt(getPersonalSetting("calendars", "useCalendarTooltips")) === 1;
  const useDayInfo =
    parseInt(getPersonalSetting("calendars", "useDayInfo")) === 1;

  var departmentOptions = departments.map((D) => ({
    label: D.department,
    value: D.id,
  }));
  departmentOptions.splice(0, 0, {
    label: t("No department assigned"),
    value: "0",
  });
  departmentOptions.splice(0, 0, { label: t("All departments"), value: "-1" });

  const handleRefresh = () => {
    setState({ ...state, reload: true });
  };

  const nextYear = () => {
    setState({
      ...state,
      year: parseInt(state.year) + 1,
      reload: true,
    });
  };

  const prevYear = () => {
    setState({
      ...state,
      year: parseInt(state.year) - 1,
      reload: true,
    });
  };

  const thisYear = () => {
    setState({
      ...state,
      year: moment().format("YYYY"),
      reload: true,
    });
  };

  const nextMonth = () => {
    let newMonth = parseInt(state.month) + 1;
    let newYear = parseInt(state.year);
    if (newMonth === 13) {
      newMonth = 1;
      newYear++;
    }
    setState({
      ...state,
      month: newMonth,
      year: newYear,
      reload: true,
    });
  };

  const prevMonth = () => {
    let newMonth = parseInt(state.month) - 1;
    let newYear = parseInt(state.year);
    if (newMonth === 0) {
      newMonth = 12;
      newYear--;
    }
    setState({
      ...state,
      month: newMonth,
      year: newYear,
      reload: true,
    });
  };

  const thisMonth = () => {
    setState({
      ...state,
      month: moment().format("MM"),
      reload: true,
    });
  };

  const today = () => {
    setState({
      ...state,
      year: moment().format("YYYY"),
      month: moment().format("MM"),
      reload: true,
    });
  };

  const handleAutoCompleteChange = (params) => {
    const value = params.value;
    setState({
      ...state,
      departmentId: value,
      reload: true,
    });
  };

  useEffect(() => {
    props.fetchOffDayTypes();
    // if (state.month && state.year && state.departmentId !== "0") {
    //   props.fetchMonth(state);
    // }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.reload) {
      store.dispatch({ type: "SET_DISPLAY_MONTH", payload: state.month });
      store.dispatch({ type: "SET_DISPLAY_YEAR", payload: state.year });
      props.fetchMonth(state);
      props.fetchReminders(loggedUser.id);
      setState({ ...state, reload: false });
    }
    // eslint-disable-next-line
  }, [state.reload]);

  useEffect(() => {
    if (loggedUser.id) {
      setState({
        ...emptyState,
        departmentId:
          loggedUser.isadmin || loggedUser.isassistant
            ? "-1"
            : loggedUser.departmentId,
        reload: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  const header = () => {
    return (
      <div className={classes.yearcalheader}>
        <div className="column">
          <MonthSelectTooltip
            title={t("Previous year")}
            placement="top"
            arrow={true}
          >
            <div
              className={classes.headerIcon}
              onClick={() => {
                prevYear();
              }}
            >
              <ChevronLeftIcon />
            </div>
          </MonthSelectTooltip>
        </div>
        <div className="column">
          <MonthSelectTooltip
            title={t("This year")}
            placement="top"
            arrow={true}
          >
            <span
              className={classes.headerYear}
              onClick={() => {
                thisYear();
              }}
            >
              {state.year}
            </span>
          </MonthSelectTooltip>
        </div>
        <div className="column">
          <MonthSelectTooltip
            title={t("Next year")}
            placement="top"
            arrow={true}
          >
            <div
              className={classes.headerIcon}
              onClick={() => {
                nextYear();
              }}
            >
              <ChevronRightIcon />
            </div>
          </MonthSelectTooltip>
        </div>
        <div className="column">
          <MonthSelectTooltip
            title={t("Previous month")}
            placement="top"
            arrow={true}
          >
            <div
              className={classes.headerIcon}
              onClick={() => {
                prevMonth();
              }}
            >
              <ChevronLeftIcon />
            </div>
          </MonthSelectTooltip>
        </div>
        <div className="column">
          <MonthSelectTooltip
            title={t("This month")}
            placement="top"
            arrow={true}
          >
            <div
              className={classes.headerMonth}
              onClick={() => {
                thisMonth();
              }}
            >
              {moment([2000, state.month - 1, 1]).format("MMMM")}
            </div>
          </MonthSelectTooltip>
        </div>
        <div className="column">
          <MonthSelectTooltip
            title={t("Next month")}
            placement="top"
            arrow={true}
          >
            <div className={classes.headerIcon} onClick={nextMonth}>
              <ChevronRightIcon />
            </div>
          </MonthSelectTooltip>
        </div>
        <div className="column">
          <MonthSelectTooltip title={t("Today")} placement="top" arrow={true}>
            <div className={classes.headerHomeIcon} onClick={today}>
              <HomeOutlinedIcon />
            </div>
          </MonthSelectTooltip>
        </div>
        <SomuraAutocomplete
          name="department"
          label={t("Department")}
          value={state.departmentId}
          variant="outlined"
          size="small"
          fullWidth
          disabled={loggedUser?.showOwnDepartment}
          disableClearable={true}
          options={departmentOptions}
          onChange={(values) => handleAutoCompleteChange(values)}
        />
        <div className={classes.refresh}>
          <RefreshButton onClick={handleRefresh} />
        </div>
      </div>
    );
  };

  return (
    <div className="content">
      <Paper square elevation={8}>
        <ScreenTitle
          title={t("Month calendar")}
          class="calendar"
          icon={
            <MonthCalIcon
              viewBox="0 0 24 24"
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <div className={classes.formHeader}>{header()}</div>
        <div id="monthcal" className={classes.monthCalendar}>
          <MonthCalRows
            startDate={state.year + "-" + state.month + "-01"}
            endDate={moment([state.year, state.month, 1])
              .endOf("month")
              .format("YYYY-MM-DD")}
            displaydepartment={state.departmentId}
          />
          {useCalendarTooltips && <CalToolTip />}
        </div>
      </Paper>
      {useDayInfo && (
        <div className={classes.infoWrapper}>
          <DayInfo startDate={state.year + "-" + state.month + "-01"} />
        </div>
      )}
      {displayReminder.length > 0 && (
        <RemindersBar reminders={displayReminder} />
      )}
      {showContextMenu && <ContextMenu />}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthCalendar);
