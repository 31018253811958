import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    width: "100%",
    minHeight: "25vh",
    display: "grid",
    gridTemplateColumns: "10px 4rem 1fr 1rem 5rem 1rem",
    gridTemplateRows: "2.5rem 1.5rem 1.5rem 1rem 1fr",
  },
  itemPaper: {
    borderRadius: "6px",
    width: "100%",
  },
  DashboardIcon: {
    gridRow: 1,
    gridColumn: 2,
    fontSize: "1.5rem",
    width: "4rem",
    height: "4rem",
    color: "white",
    marginTop: "-25px",
    borderRadius: "6px",
    position: "relative",
    backgroundColor: (props) => `${props.iconColor}`,
  },
  svg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  DashboardTitle: {
    paddingLeft: "10px",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    marginTop: "0.5rem",
    color: (props) => `${props.iconColor}`,
    gridColumn: "3 / -1",
    gridRow: "1",
  },
  DashboardSubtitle: {
    paddingLeft: "10px",
    fontSize: "1rem",
    color: theme.palette.dashBoard.title,
    gridColumn: "3",
    gridRow: "2",
  },
  DashboardValue: {
    marginTop: "0.5rem",
    gridRow: "1 / 4",
    gridColumn: "4 / -1",
    fontSize: "5rem",
    fontWeight: "bold",
    color: theme.palette.dashBoard.number,
    paddingRight: "10px",
    justifySelf: "right",
  },
  table: {
    maxHeight: "12rem",
    minHeight: "12rem",
    gridRow: "5 / 6",
    gridColumn: "2 / -2",
    marginLeft: "6px",
    marginBottom: "1rem",
    borderRadius: "6px",
    padding: "4px",
    backgroundColor: theme.palette.background.table,
    boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
    overflow: "auto",
  },
  tableHeader: {
    fontSize: "1rem",
    color: theme.palette.dashBoard.title,
  },
  noData: {
    height: "12rem",
    width: "100%",
    gridRow: "5 / 6",
    gridColumn: "2 / -2",
    margin: "0 0 1rem 6px",
    borderRadius: "6px",
    backgroundColor: theme.palette.background.table,
    boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
    justifySelf: "center",
    alignItems: "center",
  },
}));

function DashboardItem(props) {
  const classes = useStyles(props);

  return (
    <div>
      <Paper square className={classes.itemPaper} elevation={6}>
        <div className={classes.root}>
          <div className={classes.DashboardIcon + " " + props.class}>
            <svg className={classes.svg} viewBox="0 0 24 24">
              {props.icon}
            </svg>
          </div>
          <div className={classes.DashboardTitle}>{props.title}</div>

          {props.subtitle && (
            <div className={classes.DashboardSubtitle}>{props.subtitle}</div>
          )}
          <div className={classes.DashboardValue}>{props.value}</div>
          {props.nodata === "" ? (
            <div className={classes.table}>{props.table}</div>
          ) : (
            <div className={classes.noData}>{props.nodata}</div>
          )}
        </div>
      </Paper>
    </div>
  );
}

export default DashboardItem;
