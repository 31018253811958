import React, { useState } from "react";
import { connect } from "react-redux";
import { uploadLogo } from "redux/index";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SomuraTextField from "Components/SomuraTextField";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: "50vw",
    height: "30vh",
    margin: "auto",
    display: "flex",
    overflow: "hidden",
  },
  logo: {
    margin: "auto",
  },
  buttons: {
    marginTop: "1rem",
    display: "inline-block",
  },
}));

const mapStateToProps = (state) => {
  const logoFileName = state.company.Company.companyLogo;
  return {
    logoFileName,
    editMode: state.general.EditMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadLogo: (values) => dispatch(uploadLogo(values)),
  };
};

const Logo = ({ logoFileName, editMode, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    var data = new FormData();
    data.append("file", selectedFile);
    props.uploadLogo(data);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={8}>
        <img alt="" className={classes.logo} src={"images/" + logoFileName} />
      </Paper>
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: "1fr",
          gridTemplateColumns: "16rem 1fr 16rem",
          marginTop: "1rem",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Box sx={{ gridRow: "1", gridColumn: "1" }}>
          <input
            accept="image/*"
            hidden
            id="upload-button"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="upload-button">
            <Button
              variant="contained"
              component="span"
              startIcon={<ImageSearchIcon />}
            >
              {t("Select logo file")}
            </Button>
          </label>
        </Box>
        <SomuraTextField
          sx={{ gridRow: "1", gridColumn: "2" }}
          value={selectedFile?.name}
          disabled={true}
        />
        <Box sx={{ gridRow: "1", gridColumn: "3" }}>
          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={handleUpload}
            disabled={!selectedFile}
          >
            {t("Upload logo")}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
