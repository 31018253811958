import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchStats } from "redux/index";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";

import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "6px",
  },
  dialogContent: {
    fontWeight: "200",
  },
  columnLeft: {
    display: "inline-block",
    width: "80%",
    textAlign: "right",
  },
  columnRight: {
    display: "inline-block",
    width: "20%",
    textAlign: "right",
  },
  bold: {
    fontWeight: "400",
  },
  red: {
    color: theme.palette.redonblack,
  },
  nameHeadline: {
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    textAlign: "center",
    color: "white",
    margin: "0 4px",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    yeardata: state.year.Year,
    userStats: state.yearcalendar.userStats,
    year: parseInt(state.general.DisplayYear),
    userid: state.yearcalendar.displayUserId,
    usePersonNumbers: state.company.Company.usePersonNumbers,
    user: state.persons.PersonsMin.find(
      (P) => P.id === state.yearcalendar.displayUserId
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStats: (values) => dispatch(fetchStats(values)),
  };
};

const UserStats = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const lastYear = props.year - 1;
  const thisYear = parseInt(moment().format("YYYY"));
  const today = moment().format("DD.MM.YYYY");

  const handleClose = () => {
    props.closeStatsForm();
  };

  const title = () => {
    return (
      <span>
        {props.user && (
          <div className={classes.nameHeadline}>
            {props.user.listName +
              (props.usePersonNumbers && props.user.personNumber
                ? " [" + props.user.personNumber + "]"
                : "")}
          </div>
        )}
      </span>
    );
  };

  useEffect(() => {
    props.fetchStats({
      year: props.year,
      userId: props.userid,
      loggedUserId: props.loggedUserId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.yeardata, props.userid]);

  return (
    <Dialog
      className={classes.root}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="dialogTitle"
        dialogTitle={title()}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="#243573"
      />
      <DialogContent className={classes.dialogContent}>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={6} lg={6}>
              <div className={classes.statslist}>
                <div className={classes.columnLeft}>
                  {t("Unused vacation end of ")}
                  {lastYear}:
                </div>
                <div className={classes.columnRight}>
                  {props.userStats.entitlement_old}
                </div>
                <div className={classes.columnLeft}>
                  {t("Entitlement")} {props.year}:
                </div>
                <div className={classes.columnRight}>
                  {props.userStats.entitlement_now}
                </div>
                {props.year === thisYear && (
                  <>
                    <div className={classes.columnLeft}>
                      {t("Taken up to now ")}:
                    </div>
                    <div className={classes.columnRight}>
                      {props.userStats.vacation_old}
                    </div>
                    <div className={classes.columnLeft + " " + classes.bold}>
                      {t("Entitlement on ")}
                      {today}:
                    </div>
                    <div className={classes.columnRight + " " + classes.bold}>
                      {props.userStats.entitlement_actual}
                    </div>
                  </>
                )}
                {props.year !== thisYear && (
                  <>
                    <div className={classes.columnLeft + " " + classes.bold}>
                      {t("Entitlement on ")}01.01.{props.year}:
                    </div>
                    <div className={classes.columnRight + " " + classes.bold}>
                      {props.userStats.entitlement_yearstart}
                    </div>
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              {props.year < thisYear && (
                <>
                  <div className={classes.columnLeft}>
                    {t("Taken")} {props.year}:
                  </div>
                  <div className={classes.columnRight}>
                    {props.userStats.vacation_old}
                  </div>
                </>
              )}
              {props.year >= thisYear && (
                <>
                  <div className={classes.statslist}>
                    <div className={classes.columnLeft}>
                      {t("Planned")} {props.year}:
                    </div>
                    <div className={classes.columnRight}>
                      {props.userStats.vacation_planed}
                    </div>
                    <div className={classes.columnLeft}>
                      {t("Requested")} {props.year}:
                    </div>
                    <div className={classes.columnRight}>
                      {props.userStats.vacation_requested}
                    </div>
                    <div className={classes.columnLeft}>
                      {t("Approved")} {props.year}:
                    </div>
                    <div className={classes.columnRight}>
                      {props.userStats.vacation_approved}
                    </div>
                  </div>
                </>
              )}
              <div className={classes.statslist}>
                <div className={classes.columnLeft + " " + classes.bold}>
                  {t("Entitlement on ")}31.12.{props.year}:
                </div>
                {props.userStats.entitlement_new &&
                parseFloat(props.userStats.entitlement_new.replace(",", ".")) >=
                  0 ? (
                  <div className={classes.columnRight + " " + classes.bold}>
                    {props.userStats.entitlement_new}
                  </div>
                ) : (
                  <div
                    className={
                      classes.columnRight +
                      " " +
                      classes.bold +
                      " " +
                      classes.red
                    }
                  >
                    {props.userStats.entitlement_new}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStats);
