import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedStateId } from "redux/index";
import Collapse from "@mui/material/Collapse";
import makeStyles from "@mui/styles/makeStyles";
import SomuraDataGrid from "Components/SomuraDataGrid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import StatesForm from "./StatesForm"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  const states = state.articles.States;
  const selectedId = state.articles.selectedStateId;
  return { states, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setId: (id) => dispatch(setSelectedStateId(id)),
  };
};

function StatesTable({ states, selectedId, setId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "",
      headerClassName: "tableHeader",
      field: "id",
      hide: true,
      filterable: false,
    },
    {
      headerName: t("Name"),
      headerClassName: "tableHeader",
      field: "status",
      flex: 0.2,
    },  ];

  const rows = states?.map((S) => ({
    id: S.id,
    status: S.status,
  }));

  const handleRowClick = (params) => {
    setId(params);
  };

  const handleCreate = () => {
    setId(0)
  }

  return (
    <>
      <Collapse in={selectedId < 0} timeout={"auto"}>
        <SomuraDataGrid
          tableHeight={"calc(100vh - 260px)"}
          rows={rows}
          columns={columns}
          defaultSortModel={[
            {
              field: "status",
              sort: "asc",
            },
          ]}
          onRowClick={(params) => handleRowClick(params)}
          csvFileName={t("Conditions")}
        />
        <Fab
          variant="extended"
          color="primary"
          size="medium"
          className={classes.fab}
          onClick={handleCreate}
        >
          <AddIcon />
          {t("New condition")}
        </Fab>
      </Collapse>
      <Collapse in={selectedId >= 0} timeout={"auto"}>
        <StatesForm />
      </Collapse>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StatesTable);
