import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& .tableHeader": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.background.contrastText,
    },
  },
  ToolbarContainer: {
    justifyContent: "flex-end",
  },
  ToolbarButton: {
    color: theme.palette.text.main,
  },
  TableContainer: {
    padding: (props) => `${props.noContainerPadding ? "0" : "0 8px 8px 8px"}`,
  },
  grid: {
    height: (props) => `${props.tableHeight}`,
  },
  FooterContainer: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.background.contrastText,
    justifyContent: "flex-end !important",
    marginTop: (noToolbar) => `${noToolbar ? "-25px" : "0"}`,
  },
}));

const SomuraDataGrid = ({
  rows,
  columns,
  defaultSortModel,
  filterModel,
  onRowClick,
  csvFileName,
  noToolbar,
  ...props
}) => {
  const classes = useStyles(props, noToolbar);
  const [sortModel, setSortModel] = useState(defaultSortModel);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={classes.ToolbarContainer}>
        <GridToolbarColumnsButton className={classes.ToolbarButton} />
        <GridToolbarFilterButton className={classes.ToolbarButton} />
        <GridToolbarDensitySelector className={classes.ToolbarButton} />
        <GridToolbarExport
          className={classes.ToolbarButton}
          csvOptions={{ delimiter: ";", fileName: csvFileName }}
        />
      </GridToolbarContainer>
    );
  };

  const CustomFooter = () => {
    return (
      <GridFooterContainer className={classes.FooterContainer}>
        <GridPagination />
      </GridFooterContainer>
    );
  };

  return (
    <div className={classes.TableContainer}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }} className={classes.root}>
          <DataGrid
            className={classes.grid}
            components={{
              Toolbar: noToolbar ? "" : CustomToolbar,
              // LoadingOverlay: CustomLoadingOverlay,
              Footer: CustomFooter,
            }}
            componentsProps={{
              toolbarContainer: { justifyContent: "flex-end" },
            }}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            initialState={{
              filter: {
                filterModel: filterModel,
              },
            }}
            hideFooterSelectedRowCount
            loading={props.loading}
            rowHeight={30}
            rows={rows}
            columns={columns}
            onRowClick={(params) => (onRowClick ? onRowClick(params.id) : null)}
            isRowSelectable={props.isRowSelectable}
          />
        </div>
      </div>
    </div>
  );
};

export default SomuraDataGrid;
