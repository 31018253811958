import React, { useEffect, useState, useCallback } from "react";
import { saveCompanies, deleteCompanies } from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";
import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraSelectField";
import makeStyles from "@mui/styles/makeStyles";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";

const CompanySchema = Yup.object().shape({
  name: Yup.string().required(),
  industryId: Yup.number().integer().moreThan(0).required(),
  countryId: Yup.number().integer().moreThan(0).required(),
});

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  const companies = state.companies.Companies;
  const selectedCompanyId = state.companies.selectedCompanyId;
  const selectedCompany = companies.find(
    (C) => C.id === state.companies.selectedCompanyId
  );
  const countries = state.countries.Countries;
  const industries = state.industries.Industries;
  return {
    companies,
    selectedCompany,
    countries,
    industries,
    selectedCompanyId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCompanies: (values) => dispatch(saveCompanies(values)),
    deleteCompanies: (values) => dispatch(deleteCompanies(values)),
  };
};

const CompanyForm = ({
  companies,
  selectedCompany,
  selectedCompanyId,
  countries,
  industries,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: 0,
    name: "",
    industryId: "0",
    industryName: "",
    countryId: "0",
    regionId: "0",
    street: "",
    number: "",
    zip: "",
    city: "",
    telephone: "",
    fax: "",
    web: "",
    mail: "",
    numAssigned: 0,
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);
  const [regions, setRegions] = useState(
    countries
      .find((C) => C.id === selectedCompany?.countryId)
      ?.regions.map((R) => ({
        label: R.regionLong,
        value: R.id,
      })) || []
  );

  const formHeader = selectedCompany ? selectedCompany.name : t("New company");

  const handleSubmit = () => {
    props.saveCompanies(state);
  };


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    
    if(name === "industryId"){
      const industryName = industries.find(I => I.id === value)?.industryName
      setState({ ...state, [name]: value, industryName: industryName });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await CompanySchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const currentCountry = countries.find(
      (C) => parseInt(C.id) === parseInt(state.countryId)
    );
    if (currentCountry?.regions.length > 0) {
      setRegions(
        currentCountry?.regions.map((R) => ({
          label: R.regionLong,
          value: R.id,
        }))
      );
      setRegions((prevRegions) => [...prevRegions, { label: "", value: "0" }]);
    } else {
      setRegions([{ label: "", value: "0" }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.countryId]);

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "cancel":
        props.handleClose();
        break;
      case "delete":
        props.deleteCompanies({ id: selectedCompany.id });
        props.handleClose();
        break;
      default:
        props.handleClose();
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      setState({
        ...selectedCompany,
        countryId: parseInt(selectedCompany.countryId),
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="name"
              label={t("Company")}
              value={state.name}
              maxlength="50"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="industryId"
              label={t("Industry")}
              options={industries?.map((I) => ({
                label: I.industryName,
                value: I.id,
              }))}
              value={state.industryId}
              onChange={handleChange}
              required
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="countryId"
              label={t("Country")}
              value={state.countryId}
              options={countries?.map((C) => ({
                label: C.ownName,
                value: C.id,
              }))}
              onChange={handleChange}
              required
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            {countries && (
              <SomuraAutocomplete
                name="regionId"
                label={t("Region")}
                options={regions}
                value={state.regionId}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="street"
              label={t("Street")}
              value={state.street}
              maxlength="50"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <SomuraTextField
              type="text"
              name="number"
              label={t("Number")}
              value={state.number}
              maxlength="10"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <SomuraTextField
              type="text"
              name="zip"
              label={t("ZIP")}
              value={state.zip}
              maxlength="6"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraTextField
              type="text"
              name="city"
              label={t("City")}
              value={state.city}
              maxlength="50"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="telephone"
              label={t("Phone number")}
              value={state.telephone}
              maxlength="30"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="fax"
              label={t("Fax number")}
              value={state.fax}
              maxlength="30"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="text"
              name="web"
              label={t("Web address")}
              value={state.web}
              maxlength="100"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <SomuraTextField
              type="email"
              name="mail"
              label={t("Mail address")}
              value={state.mail}
              maxlength="100"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton
          onClick={() => {
            handleButtonClick("cancel");
          }}
        />
        <DeleteButton
          disabled={!selectedCompany || state.numAssigned > 0}
          onClick={() => {
            handleButtonClick("delete");
          }}
        />
        <SaveButton onClick={handleSubmit} disabled={!valid} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
