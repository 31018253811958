import {
  FETCH_MESSAGEFOLDERS_REQUEST,
  FETCH_MESSAGEFOLDERS_SUCCESS,
  FETCH_MESSAGEFOLDERS_FAILURE,
  EMPTY_MESSAGEFOLDER,
  EMPTY_SENDERFOLDER,
  EMPTY_RECYCLEBIN,
  DELETE_MESSAGEFOLDER,
  ADD_MESSAGEFOLDER,
  RENAME_MESSAGEFOLDER,
} from "actions/types";

import { messageFolders, clearMessageFolder, clearSenderFolder, clearRecycleBin, delMessageFolder, renMessageFolder, insMessageFolder } from "../api";

export const fetchMessageFoldersRequest = () => {
  return {
    type: FETCH_MESSAGEFOLDERS_REQUEST,
  };
};

const fetchMessageFoldersSuccess = (messageFolders) => {
  return {
    type: FETCH_MESSAGEFOLDERS_SUCCESS,
    payload: messageFolders,
  };
};

const fetchMessageFoldersFailure = (error) => {
  return {
    type: FETCH_MESSAGEFOLDERS_FAILURE,
    payload: error,
  };
};

export const fetchMessageFolders = (userId) => {
  return async (dispatch) => {
    await dispatch(fetchMessageFoldersRequest());
    try {
      const messageFoldersJSONData = await dispatch(messageFolders(userId));
      await dispatch(fetchMessageFoldersSuccess(messageFoldersJSONData));
    } catch (err) {
      await dispatch(fetchMessageFoldersFailure(err));
    }
  };
};

const deleteMessageFolderSuccess = (id) => {
  return {
    type: DELETE_MESSAGEFOLDER,
    payload: id,
  };
};

export const deleteMessageFolder = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(delMessageFolder({ id: id }));
      await dispatch(deleteMessageFolderSuccess({ id: id }));
    } catch (err) {
      await dispatch(fetchMessageFoldersFailure(err));
    }
  };
};

const emptyMessageFolderSuccess = (values) => {
  return {
    type: EMPTY_MESSAGEFOLDER,
    payload: values,
  };
};

export const emptyMessageFolder = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(clearMessageFolder(values));
      await dispatch(emptyMessageFolderSuccess(values));
    } catch (err) {
      await dispatch(fetchMessageFoldersFailure(err));
    }
  };
};

const emptySenderFolderSuccess = (values) => {
  return {
    type: EMPTY_SENDERFOLDER,
    payload: values,
  };
};

export const emptySenderFolder = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(clearSenderFolder(values));
      await dispatch(emptySenderFolderSuccess(values));
    } catch (err) {
      await dispatch(fetchMessageFoldersFailure(err));
    }
  };
};

const emptyRecycleBinSuccess = (values) => {
  return {
    type: EMPTY_RECYCLEBIN,
    payload: values,
  };
};

export const emptyRecycleBin = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(clearRecycleBin(values));
      await dispatch(emptyRecycleBinSuccess(values));
    } catch (err) {
      await dispatch(fetchMessageFoldersFailure(err));
    }
  };
};

const renameMessageFolderSuccess = (values) => {
  return {
    type: RENAME_MESSAGEFOLDER,
    payload: values,
  };
};

export const renameMessageFolder = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(renMessageFolder(values));
      await dispatch(renameMessageFolderSuccess(values));
    } catch (err) {
      await dispatch(fetchMessageFoldersFailure(err));
    }
  };
};

const addMessageFolderSuccess = (values) => {
  return {
    type: ADD_MESSAGEFOLDER,
    payload: values,
  };
};

export const addMessageFolder = (values) => {
  return async (dispatch) => {
    try {
      const messagesJSONData = await dispatch(insMessageFolder(values));
      await dispatch(addMessageFolderSuccess({ id: messagesJSONData,...values  }));
    } catch (err) {
      await dispatch(fetchMessageFoldersFailure(err));
    }
  };
};
