import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  COMPANIES_FAILURE,
  SET_COMPANY_ID,
  SAVE_COMPANIES,
  DELETE_COMPANIES,
} from "actions/types";

import { companiesRead, companiesSave, companiesDelete } from '../api'
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";

const companiesFailure = (err) => {
  return {
    type: COMPANIES_FAILURE,
    payload: err
  }
}

const fetchCompaniesRequest = () => {
  return {
    type: FETCH_COMPANIES_REQUEST
  }
}

const fetchCompaniesSuccess = (Companies) => {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    payload: Companies
  }
}

export const fetchCompanies = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchCompaniesRequest())
    try {
      const companiesJSONData = await dispatch(companiesRead())
      await dispatch(fetchCompaniesSuccess(companiesJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(companiesFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

export const setSelectedCompanyId = (id) => {
  return {
    type: SET_COMPANY_ID,
    payload: id
  };
}

const saveCompaniesData = (values) => {
  return {
    type: SAVE_COMPANIES,
    payload: values,
  };
};

export const saveCompanies = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(companiesSave(values));
      await dispatch(saveCompaniesData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};

const deleteCompanyData = (values) => {
  return {
    type: DELETE_COMPANIES,
    payload: values,
  };
};

export const deleteCompanies = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(companiesDelete(values));
      await dispatch(deleteCompanyData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};