import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchSpecialDays, setSpecialDayId } from "redux/index";
import { sortByDate } from "Components/Utilities";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import "moment/locale/de.js";
import SomuraDataGrid from "Components/SomuraDataGrid";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
  alwaysShow: {
    color: theme.palette.blue,
  },
  showDay: {
    color: theme.palette.green,
  },
  hideDay: {
    color: theme.palette.red,
  },
}));

const mapStateToProps = (state) => {
  return {
    specialDays: state.specialdays.SpecialDays,
    selectedSpecialDayKey: state.specialdays.selectedSpecialDayKey,
    loggedAdmin: state.loggeduser.LoggedUser.isadmin,
    loggedAssistant: state.loggeduser.LoggedUser.isassistant,
    dataLoading: state.specialdays.loading,
    dataError: state.specialdays.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSpecialDays: () => dispatch(fetchSpecialDays()),
    setSpecialDayId: (id) => dispatch(setSpecialDayId(id)),
  };
};

const SpecialDaysTable = ({ specialDays, setSpecialDayId, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      headerName: t("Show"),
      headerClassName: "tableHeader",
      field: "showDay",
      filterable: true,
      renderCell: (params) => {
        switch (params.value) {
          case 1:
            return <LockRoundedIcon className={classes.alwaysShow} />;
          case 2:
            return <VisibilityRoundedIcon className={classes.showDay} />;
          default:
            return <VisibilityOffRoundedIcon className={classes.hideDay} />;
        }
      },
      flex: 0.2,
    },
    {
      headerName: t("Designation"),
      headerClassName: "tableHeader",
      field: "dayName",
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: t("Off work"),
      headerClassName: "tableHeader",
      field: "isOffDay",
      align: "center",
      renderCell: (params) => {
        return params.value ? <CheckCircleIcon /> : "";
      },
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: t("Vacation deduction"),
      headerClassName: "tableHeader",
      field: "dayFactor",
      renderCell: (params) => {
        return params.value > 0 ? params.value + " " + t("days") : "";
      },
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: t("Next occurence"),
      headerClassName: "tableHeader",
      field: "nextDate",
      sortComparator: (a, b) => sortByDate(a, b),
      type: "date",
      filterable: true,
      flex: 0.25,
    },
  ];

  const rows = specialDays.map((SD) => ({
    id: SD.id,
    showDay: SD.alwaysUseThisDay ? 1 : SD.useThisDay ? 2 : 3,
    dayName: SD.dayName,
    isOffDay: SD.isOffDay,
    dayFactor: SD.dayFactor,
    nextDate: moment(SD.nextDate).format("L"),
  }));

  const handleRowClick = (params) => {
    setSpecialDayId(params);
  };

  useEffect(() => {
    props.fetchSpecialDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SomuraDataGrid
        tableHeight="65vh"
        rows={rows}
        columns={columns}
        defaultSortModel={[
          {
            field: "nextDate",
            sort: "asc",
          },
        ]}
        onRowClick={(params) => handleRowClick(params)}
        csvFileName={t("Holidays")}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialDaysTable);
