import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addMessage,
  setRecipientMessageFolder,
  setSenderMessageFolder,
} from "redux/index";
import makeStyles from "@mui/styles/makeStyles";
import SomuraTooltip from "Components/SomuraTooltip";
import moment from "moment";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FolderMessage from "./FolderMessage";
import FlipMove from "react-flip-move";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";
import { store } from "redux/store";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 190px)",
    backgroundColor: theme.palette.background.messageList,
    marginRight: "4px",
    marginLeft: "4px",
    display: "grid",
    gridTemplateRows: "2rem 1fr",
  },
  listTop: {
    paddingLeft: "4px",
    display: "grid",
    gridTemplateColumns: "1fr 1rem",
    gridTemplateRows: "2.3rem 1fr",
    alignItems: "center",
  },
  listTitle: {
    gridColumn: "1 / 3",
    fontSize: "1.3em",
    paddingLeft: "10px",
    color: theme.palette.text.screenTitle,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  listContainer: {
    overflowY: "auto",
    gridRow: "2",
  },
  new: {
    gridRow: "1",
    gridColumn: "2",
    alignSelf: "end",
  },
  messagesList: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    paddingTop: "0 !important",
  },
  listItem: {
    padding: "0 0 4px 0 !important",
  },
  listPaper: {
    width: "100%",
    padding: "0 !important",
    margin: "2px 6px",
    backgroundColor: theme.palette.background.messageListItem,
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
      opacity: "0.5",
    },
    borderRadius: "4px",
  },
  folder: {
    fontSize: "1.2rem",
    height: "2rem",
    width: "100%",
    margin: "4px",
    display: "flex",
    cursor: "pointer",
  },
  selected: {
    backgroundColor: theme.palette.background.messageListHighlight,
    boxShadow: "5px 5px 3px 1px rgba(0, 0, 0, 0.4)",
    "&:hover": {
      opacity: "0.8",
    },
  },
  displayDate: {
    padding: "2px 10px",
    color: theme.palette.text.disabled,
  },
  emptyFolder: {
    marginTop: "100%",
    textAlign: "center",
    color: theme.palette.text.screenTitle,
  },
  emptyFolderIcon: {
    width: "4rem",
    height: "4rem",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const mapStateToProps = (state) => {
  return {
    logged: state.loggeduser.LoggedUser.id,
    folders: state.messagefolders.MessageFolders,
    messages: state.messages.Messages, //.filter(M => M.senderFolderId !== "-5"),
    selectedMessageId: state.messages.selectedMessageId,
    selectedMessageIds: state.messages.selectedMessageIds,
    folderId: state.messagefolders.selectedFolderId.toString(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMessage: (userId) => dispatch(addMessage(userId)),
    setRecipientMessageFolder: (values) =>
      dispatch(setRecipientMessageFolder(values)),
    setSenderMessageFolder: (values) =>
      dispatch(setSenderMessageFolder(values)),
  };
};

const FolderMessages = ({
  logged,
  folders,
  messages,
  selectedMessageId,
  selectedMessageIds,
  folderId,
  addMessageId,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleNew = () => {
    props.addMessage(logged);
  };

  const [folderTitle, setFolderTitle] = useState("");
  const [messageList, setMessageList] = useState([]);

  let folderName;
  switch (folderId) {
    case "0":
      folderName = t("Inbox");
      break;
    case "-1":
      folderName = t("Recycle bin");
      break;
    case "-2":
      folderName = t("Outbox");
      break;
    case "-4":
      folderName = t("Drafts");
      break;
    default:
      folderName = folders.find((F) => F.id === folderId).folderName;
  }

  useEffect(() => {
    let messagesInFolder = [];
    var folderMessages;
    // let folderMessages;
    if (messages) {
      if (folderId === "-2" || folderId === "-4") {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        folderMessages =
          messages.length > 0
            ? messages.filter(
                (M) =>
                  (M.senderFolderId.toString() === folderId) &
                  (M.fromUserId === logged)
              )
            : [];
      } else {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        folderMessages =
          messages.length > 0
            ? messages.filter(
                (M) =>
                  (M.senderFolderId !== "-4" &&
                    M.senderFolderId !== "-5" &&
                    M.recipients &&
                    M.recipients.findIndex(
                      (RF) =>
                        (RF.recipientFolderId === folderId) &
                        (RF.recipientId === logged)
                    ) > -1) ||
                  (M.senderFolderId === folderId && M.fromUserId === logged)
              )
            : [];
      }

      // Sort folderMessages by creationDate
      if (folderMessages) {
        folderMessages.sort(function (a, b) {
          var keyA = new Date(a.creationTime),
            keyB = new Date(b.creationTime);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
      }

      const today = moment().format("dddd, LL");
      const yesterday = moment().add(-1, "days").format("dddd, LL");
      let displayDate;
      let showDate = "";
      let messageDate = "";
      let messageIds = [];

      for (let i = 0; i < folderMessages.length; i++) {
        messageIds.push({ id: folderMessages[i].id, selected: false });
        messageDate = moment(folderMessages[i].creationTime).format("dddd, LL");
        if (messageDate !== showDate) {
          if (messageDate === today) {
            displayDate = t("Today");
          } else if (messageDate === yesterday) {
            displayDate = t("Yesterday");
          } else {
            displayDate = messageDate;
          }

          messagesInFolder.push(
            <div className={classes.displayDate} key={displayDate}>
              {displayDate}
            </div>
          );
          showDate = messageDate;
        }
        messagesInFolder.push(
          <ListItem
            button
            disableGutters
            key={folderMessages[i].id}
            className={classes.listItem}
          >
            <Paper
              square
              elevation={8}
              className={
                classes.listPaper +
                " " +
                (selectedMessageIds?.findIndex(
                  (SM) => SM.id === folderMessages[i].id && SM.selected === true
                ) > -1
                  ? classes.selected
                  : "")
              }
            >
              <FolderMessage
                messageId={folderMessages[i].id}
                folderId={folderId}
              />
            </Paper>
          </ListItem>
        );
        if (
          selectedMessageIds?.findIndex(
            (SM) => SM.id === folderMessages[i].id
          ) === -1
        ) {
          store.dispatch({
            type: "ADD_MESSAGE_ID",
            payload: { id: folderMessages[i].id, position: i },
          });
        }
      }
      setMessageList(messagesInFolder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, selectedMessageIds]);

  useEffect(() => {
    let title = folderName;
    const numSelected = selectedMessageIds.filter(
      (SM) => SM.selected === true
    ).length;
    if (numSelected > 1) {
      title = "(" + numSelected + ") " + title;
    }
    setFolderTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMessageIds]);

  return (
    <div className={classes.root}>
      <div className={classes.listTop}>
        <div className={classes.listTitle}>{folderTitle}</div>
        <SomuraTooltip title={t("New message")} placement="top">
          <IconButton
            className={classes.button}
            style={{ gridColumn: "-1" }}
            onClick={handleNew}
          >
            <AddIcon className={classes.buttonIcon} />
          </IconButton>
        </SomuraTooltip>
      </div>
      <div className={classes.listContainer}>
        {messageList.length > 0 ? (
          <List className={classes.messagList} component="nav">
            <FlipMove>{messageList}</FlipMove>
          </List>
        ) : (
          <FlipMove>
            <div className={classes.emptyFolder}>
              <div className={classes.emptyFolderIcon}>
                <MailTwoToneIcon
                  style={{
                    width: "4rem",
                    height: "4rem",
                  }}
                />
              </div>
              {t("This folder is empty")}
            </div>
          </FlipMove>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderMessages);
