import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { declineOffDays } from "redux/index";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

import { store } from "redux/store";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import SomuraTextFieldLargeMUI from "Components/SomuraTextFieldLargeMUI";

import makeStyles from "@mui/styles/makeStyles";

import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ClearIcon from "@mui/icons-material/Clear";

import DateList from "Calendars/Components/DateList";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: 700,
  },
  cancelButton: {
    gridColumn: 3,
  },
  saveButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-acceptform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  const logged = state.loggeduser.LoggedUser.id;
  const displayyear = state.general.DisplayYear;
  const offDayTypes = state.offdaytypes.OffDayTypes;

  const userId = state.yearcalendar.displayUserId;
  return {
    logged,
    displayyear,
    offDayTypes,
    userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    declineOffDays: (values) => dispatch(declineOffDays(values)),
  };
};

const DeclineForm = ({
  logged,
  displayyear,
  offDayTypes,
  offDayTextId,
  dates,
  userId,
  approvementId,
  declineOffDays,
  closeDeclineForm,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const offDayTypeName = offDayTypes
    .find((ODT) => ODT.id === props.offDayTypeId)
    ?.names.find(
      (OTN) => OTN.language === localStorage.getItem("lang")
    )?.offDayType;

  const [state, setState] = useState({
    loggedUserId: logged,
    approvementId: approvementId,
    text: "",
  });

  const handleSubmit = () => {
    declineOffDays(state);
    handleClose();
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    closeDeclineForm();
  };

  const handleBlur = (event) => {
    setState({ ...state, text: event.target.value });
  };

  const title = () => {
    let title = "";
    let subtitle = "";
    const firstDate = moment(props.startDate).format("dddd, LL");
    const lastDate = moment(props.endDate).format("dddd, LL");

    if (firstDate === lastDate) {
      title = firstDate;
    } else {
      title = firstDate + " bis " + lastDate;
    }

    subtitle =
      props.numWorkdays +
      (props.numWorkdays === 1
        ? " " + t("working day") + " ("
        : " " + t("working days") + " (");
    subtitle +=
      props.numDays +
      (props.numDays === 1 ? " " + t("day") + ")" : " " + t("days") + ")");

    return (
      <span>
        {title}
        <br />
        {subtitle}
      </span>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-acceptform-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-declineform-title"
        dialogTitle={t("Decline")}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="red"
      />
      <DialogContent className="dialogContent">
        <div className="timeFrame">{title()}</div>

        <fieldset className={classes.fieldset} disabled={props.formDisabled}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <DateList dates={dates} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        name="offDayTypeName"
                        label={t("Absence type")}
                        disabled={true}
                        value={offDayTypeName}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    {props.additionalText?.length > 0 && (
                      <Grid item xs={12}>
                        <TextField
                          name="additionalText"
                          label={t("Additional text")}
                          disabled={true}
                          value={props.additionalText}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SomuraTextFieldLargeMUI
                    type="text"
                    name="text"
                    label={t("Reason")}
                    rows="4"
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </fieldset>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button
          id="cancelselection"
          className={classes.cancelButton}
          startIcon={<ClearIcon />}
          variant="outlined"
          color="cancel"
          onClick={() => {
            handleClose();
          }}
        >
          {t("Abort")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          startIcon={<ThumbDownAltIcon />}
          onClick={handleSubmit}
        >
          {t("Decline")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclineForm);
