import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { hexToRGB, getDisplaySetting } from "Components/Utilities";
import Button from "@mui/material/Button";

import makeStyles from "@mui/styles/makeStyles";
import SomuraTooltip from "Components/SomuraTooltip";

import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";

import RequestForm from "../../Components/RequestForm";
import PublishForm from "../../Components/PublishForm";
import DeleteForm from "../../Components/DeleteForm";
import AcceptForm from "../../Components/AcceptForm";
import DeclineForm from "../../Components/DeclineForm";
import ResetForm from "../../Components/ResetForm";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1rem",
    padding: "4px",
  },
  selectionButton: {
    minWidth: "2rem",
    maxWidth: "2.5rem",
    height: "2rem",
    gridRow: "1",
    alignSelf: "center",
  },
  unpublished: {
    backgroundImage:
      "linear-gradient(90deg, transparent 50%, rgba(255,255,255,1) 50%)",
    backgroundSize: "4px 2px",
  },
}));

const mapStateToProps = (state) => {
  return {
    yeardata: state.year.Year,
    mayApprove: state.loggeduser.LoggedUser.isapprover,
    mayDeletePlanned: state.loggeduser.LoggedUser.deletePlannedAbsence,
    offDayTypes: state.offdaytypes.OffDayTypes,
  };
};

const Approvement = ({
  yeardata,
  mayApprove,
  mayDeletePlanned,
  offDayTypes,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [openRequestForm, setOpenRequestForm] = useState(false);
  const [openPublishForm, setOpenPublishForm] = useState(false);
  const [openDeclineForm, setOpenDeclineForm] = useState(false);
  const [openAcceptForm, setOpenAcceptForm] = useState(false);
  const [openResetForm, setOpenResetForm] = useState(false);

  const language = localStorage.getItem("lang");
  const approvementId = props.approvementId;
  const approvements = yeardata.filter(
    (YD) => YD.approvementId === approvementId
  );
  const published = approvements[0].published;
  const offDayTypeId = approvements[0].offDayTypeId;
  const additionalText = approvements[0].additionalText;
  // const approvementState = approvements[0].offDayState;
  const firstDay = approvements[0].date;
  const lastDay = approvements[approvements.length - 1].date;
  const backColor = getDisplaySetting(offDayTypeId, "backgroundColor");
  const fontColor = getDisplaySetting(offDayTypeId, "fontColor");
  const frontColor = fontColor;
  const textShadow =
    published === "1"
      ? ""
      : "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white";
  const approvementState = approvements[0].state;

  const offDayType =
    offDayTypes && offDayTypes.find((OT) => OT.id === offDayTypeId);
  let dayName =
    offDayType &&
    offDayType.names.find((OTN) => OTN.language === language).offDayType;

  const buttons = () => {
    if (approvementState !== "1" && !mayApprove) {
      return (
        <div
          style={{
            color: frontColor,
            padding: "0.7rem 1rem",
            lineHeight: "1rem",
            gridColumn: "2 / 6",
            gridRow: "1",
            textAlign: "center",
          }}
        >
          {t("Modification not allowed")}
        </div>
      );
    }
    switch (approvementState) {
      case "1":
        return (
          <Fragment>
            {(published === "0" || mayApprove || mayDeletePlanned) && (
              <SomuraTooltip placement="top" title={t("Delete")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-delete"
                  style={{
                    gridRow: "2",
                    gridColumn: "5",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenDeleteForm(true);
                  }}
                  variant="contained"
                >
                  <DeleteOutlineIcon />
                </Button>
              </SomuraTooltip>
            )}
            <SomuraTooltip placement="top" title={t("Request")}>
              <Button
                className={classes.selectionButton}
                id="btn-request"
                style={{
                  gridRow: "2",
                  gridColumn:
                    published === "0" || mayApprove || mayDeletePlanned
                      ? "4"
                      : "5",
                  backgroundColor: "#9B59B6",
                  color: "white",
                }}
                onClick={() => {
                  setOpenRequestForm(true);
                }}
                variant="contained"
              >
                <SendIcon />
              </Button>
            </SomuraTooltip>
            {published === "0" && (
              <SomuraTooltip placement="top" title={t("Publish")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-publish"
                  style={{
                    gridRow: "2",
                    gridColumn: "3",
                    backgroundColor: "#243573",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenPublishForm(true);
                  }}
                  variant="contained"
                >
                  <VisibilityIcon />
                </Button>
              </SomuraTooltip>
            )}
          </Fragment>
        );
      case "2":
        return (
          <Fragment>
            {mayApprove && (
              <SomuraTooltip placement="top" title={t("Reset state")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-reset"
                  style={{
                    gridRow: "2",
                    gridColumn: "2",
                    backgroundColor: "#1769AA",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenResetForm(true);
                  }}
                  variant="contained"
                >
                  <SettingsBackupRestoreIcon />
                </Button>
              </SomuraTooltip>
            )}
            {mayApprove && (
              <SomuraTooltip placement="top" title={t("Decline")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-decline"
                  style={{
                    gridRow: "2",
                    gridColumn: "3",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenDeclineForm(true);
                  }}
                  variant="contained"
                >
                  <ThumbDownAltIcon />
                </Button>
              </SomuraTooltip>
            )}
            {mayApprove && (
              <SomuraTooltip placement="top" title={t("Approve")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-accept"
                  style={{
                    gridRow: "2",
                    gridColumn: "4",
                    backgroundColor: "green",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenAcceptForm(true);
                  }}
                  variant="contained"
                >
                  <ThumbUpAltIcon />
                </Button>
              </SomuraTooltip>
            )}
            {mayApprove && (
              <SomuraTooltip placement="top" title={t("Delete")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-delete"
                  style={{
                    gridRow: "2",
                    gridColumn: "5",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenDeleteForm(true);
                  }}
                  variant="contained"
                >
                  <DeleteOutlineIcon />
                </Button>
              </SomuraTooltip>
            )}
          </Fragment>
        );
      case "3":
        return (
          <>
            {mayApprove && (
              <SomuraTooltip placement="top" title={t("Reset state")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-reset"
                  style={{
                    gridRow: "2",
                    gridColumn: "4",
                    backgroundColor: "#1769AA",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenResetForm(true);
                  }}
                  variant="contained"
                >
                  <SettingsBackupRestoreIcon />
                </Button>
              </SomuraTooltip>
            )}
            {mayApprove && (
              <SomuraTooltip placement="top" title={t("Cancel")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-delete"
                  style={{
                    gridRow: "2",
                    gridColumn: "5",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenDeleteForm(true);
                  }}
                  variant="contained"
                >
                  <DeleteOutlineIcon />
                </Button>
              </SomuraTooltip>
            )}
          </>
        );
      case "4":
        return (
          <Fragment>
            {mayApprove && (
              <SomuraTooltip placement="top" title={t("Cancel")}>
                <Button
                  className={classes.selectionButton}
                  id="btn-delete"
                  style={{
                    gridRow: "2",
                    gridColumn: "5",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => {
                    setOpenDeleteForm(true);
                  }}
                  variant="contained"
                >
                  <DeleteOutlineIcon />
                </Button>
              </SomuraTooltip>
            )}
          </Fragment>
        );
      default:
    }
  };

  switch (approvementState) {
    case "1":
      dayName += " (" + t("planned") + ")";
      break;
    case "2":
      dayName += " (" + t("requested") + ")";
      break;
    case "3":
      dayName += " (" + t("approved") + ")";
      break;
    case "4":
      dayName += " (" + t("registered") + ")";
      break;
    default:
      dayName += "";
  }

  let pubClassName = {};

  if (published === "0") {
    dayName += " " + t("not published");
    pubClassName = classes.unpublished;
  }

  var numDays = approvements.length;
  // for (var i = 0; i < approvements.length; i++) {
  //   numDays += Number(approvements[i].factor);
  // }

  let fullDayName =
    numDays + " " + (numDays === 1 ? t("day") : t("days")) + " ";
  fullDayName += dayName;

  return (
    <div className={classes.root}>
      <div
        style={{
          border: "4px solid " + backColor,
          backgroundColor: "white",
          display: "grid",
          gridTemplateColumns: "30.8rem 3rem 3rem 3rem 3rem",
          gridTemplateRows: "8px 2.4rem 8px",
        }}
      >
        <div
          className={pubClassName}
          style={{
            padding: "4px",
            backgroundColor: hexToRGB(backColor, 0.3),
            gridColumn: "1 / 6",
            gridRow: "1 / -1",
          }}
        ></div>
        <div
          style={{
            gridColumn: "1",
            gridRow: "2",
            marginLeft: "0.5rem",
            backgroundColor: hexToRGB("#FFFFFF", 0.7),
            paddingLeft: "6px",
            fontSize: "1rem",
            lineHeight: "1.2rem",
            color: frontColor,
            textShadow: textShadow,
            opacity: "1",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <span>{moment(firstDay).format("dddd, LL")}</span>
            {approvements.length > 1 && (
              <span>{" bis " + moment(lastDay).format("dddd, LL")}</span>
            )}
          </div>
          {fullDayName}
        </div>
        {buttons()}
      </div>
      {openDeleteForm === true && (
        <DeleteForm
          startDate={firstDay}
          endDate={lastDay}
          approvementId={approvementId}
          additionalText={additionalText}
          dates={approvements}
          numWorkdays={numDays}
          numDays={approvements.length}
          offDayTypeId={offDayTypeId}
          closeDeleteForm={() => {
            setOpenDeleteForm(false);
          }}
        />
      )}
      {openRequestForm === true && (
        <RequestForm
          startDate={firstDay}
          endDate={lastDay}
          approvementId={approvementId}
          additionalText={additionalText}
          dates={approvements}
          numWorkdays={numDays}
          numDays={approvements.length}
          offDayTypeId={offDayTypeId}
          closeRequestForm={() => {
            setOpenRequestForm(false);
          }}
        />
      )}
      {openPublishForm === true && (
        <PublishForm
          startDate={firstDay}
          endDate={lastDay}
          approvementId={approvementId}
          additionalText={additionalText}
          dates={approvements}
          numWorkdays={numDays}
          numDays={approvements.length}
          numPublishDays={numDays}
          offDayTypeId={offDayTypeId}
          closePublishForm={() => {
            setOpenPublishForm(false);
          }}
        />
      )}
      {openAcceptForm === true && (
        <AcceptForm
          startDate={firstDay}
          endDate={lastDay}
          approvementId={approvementId}
          additionalText={additionalText}
          dates={approvements}
          numWorkdays={numDays}
          numDays={approvements.length}
          offDayTypeId={offDayTypeId}
          closeAcceptForm={() => {
            setOpenAcceptForm(false);
          }}
        />
      )}
      {openDeclineForm === true && (
        <DeclineForm
          startDate={firstDay}
          endDate={lastDay}
          approvementId={approvementId}
          additionalText={additionalText}
          dates={approvements}
          numWorkdays={numDays}
          numDays={approvements.length}
          offDayTypeId={offDayTypeId}
          closeDeclineForm={() => {
            setOpenDeclineForm(false);
          }}
        />
      )}
      {openResetForm === true && (
        <ResetForm
          startDate={firstDay}
          endDate={lastDay}
          approvementId={approvementId}
          additionalText={additionalText}
          approvementState={approvementState}
          dates={approvements}
          numWorkdays={numDays}
          numDays={approvements.length}
          offDayTypeId={offDayTypeId}
          closeResetForm={() => {
            setOpenResetForm(false);
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, null)(Approvement);
