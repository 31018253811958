import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import CommonForm from "./CommonForm";
import BreakDeductionForm from "./BreakDeductionForm";

import ScreenTitle from "Components/ScreenTitle";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

const TimeTrackingSettings = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("1");

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content">
      <Paper elevation={8}>
        <ScreenTitle
          title={t("Settings: time tracking")}
          class="settings"
          icon={
            <TimerOutlinedIcon
              viewBox="0 0 24 24"
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                className="tabPanel"
                onChange={handleTabsChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={t("Common")} value="1"/>
                <Tab label={t("Break deduction")} value="2"/>
              </TabList>
            </Box>
            <TabPanel value="1">
              <CommonForm />
            </TabPanel>
            <TabPanel value="2">
              <BreakDeductionForm />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </div>
  );
};

export default TimeTrackingSettings;
