import { newLogEntry, sendSystemMessage } from "redux/index";
import { store } from "redux/store";
import moment from "moment";

export function displayDate(date) {
  // date-Format for display in Messages
  const today = moment();
  const checkDate = moment(date);
  const dateString = checkDate.format("DD.MM.YYYY");
  const thisWeekString = checkDate.format("dd HH:mm");
  const thisMonthString = checkDate.format("dd DD.MM.");
  const timeString = checkDate.format("HH:mm");
  if (checkDate.isSame(today, "day")) {
    return timeString;
  }
  if (checkDate.isSame(today, "week")) {
    return thisWeekString;
  }
  if (checkDate.isSame(today, "month")) {
    return thisMonthString;
  }
  return dateString;
}

export function addLogEntry(values) {
  store.dispatch(newLogEntry(values));
}

export function stringToHslColor(str, s = 80, l = 30) {
  if (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }
  return "hsl(0, 0%, 75%)";
}

export function hexToRGB(hex, alpha) {
  if (hex) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  } else {
    return "";
  }
}

export function createSystemMessage(values) {
  // create messages depending on several states
  // receives: recipientId
  //           messageType
  //           senderMail

  let mFromUserId = "";
  let mToUserId = "";
  let mSenderMail = "";
  let mSubject = "";
  let mText = "";
  let mStartDate = "";
  let mEndDate = "";
  let mNumDays = 0;
  let mAllowNewPassword = false;
  let mAllowAccess = false;
  switch (values.messageType) {
    case "newAccount":
      mFromUserId = "0";
      mToUserId = "-1";
      mSenderMail = values.senderMail;
      mSubject = "Neue Anmeldung";
      mText =
        "Es wurde versucht, sich mit der Email-Adresse <br /><b>" +
        mSenderMail +
        "</b><br /> anzumelden.<br /><br />";
      mText +=
        "Wenn es sich um einen unberechtigten Anmeldeversuch handelt, müssen Sie nichts weiter unternehmen. Weitere Anmeldungen ";
      mText += "mit dieser Email-Adresse sind blockiert.<br />";
      mText +=
        'Andernfalls klicken Sie auf "Zugriff erlauben". Der Benutzer darf sich dann anmelden und wird sofort aufgefordert, ';
      mText +=
        "sich ein neues Passwort zu vergeben. Der Benutzer erhält eine entsprechende Email.";
      mAllowNewPassword = false;
      mAllowAccess = true;
      break;
    case "newPassword":
      mFromUserId = "0";
      mToUserId = "-3";
      mSenderMail = values.senderMail;
      mSubject = "Neues Passwort";
      mText =
        "Der Benutzer mit der Email-Adresse <br /><b>" +
        mSenderMail +
        "</b><br /> gibt an, sein Passwort vergessen zu haben.<br /><br />";
      mText +=
        'Klicken Sie auf "Neues Passwort erlauben", so dass der Benutzer bei seinem nächsten Anmeldeversuch aufgefordert wird, ';
      mText +=
        "sich ein neues Passwort zu vergeben. Der Benutzer erhält eine entsprechende Email.";
      mText +=
        "Im anderen Fall unternehmen Sie nichts. Der Zugang für diesen Benutzer wurde automatisch gesperrt.";
      mAllowNewPassword = true;
      mAllowAccess = false;
      break;
    default:
  }

  store.dispatch(
    sendSystemMessage({
      fromUserId: mFromUserId,
      toUserId: mToUserId,
      title: mSubject,
      message: mText,
      messageMailAdress: mSenderMail,
      startDate: mStartDate,
      endDate: mEndDate,
      numDays: mNumDays,
      allowAccess: mAllowAccess,
      allowNewPassword: mAllowNewPassword,
    })
  );
}

export function easterDate(Y) {
  var C = Math.floor(Y / 100);
  var N = Y - 19 * Math.floor(Y / 19);
  var K = Math.floor((C - 17) / 25);
  var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor(I / 30);
  I =
    I -
    Math.floor(I / 28) *
      (1 -
        Math.floor(I / 28) *
          Math.floor(29 / (I + 1)) *
          Math.floor((21 - N) / 11));
  var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  var L = I - J;
  var M = 3 + Math.floor((L + 40) / 44);
  var D = L + 28 - 31 * Math.floor(M / 4);

  return moment(Y + "-" + M + "-" + D);
}

export function fourthAdventDate(Y) {
  var christmas = moment(Y + "-12-25");
  return christmas.add(christmas.format("E") * -1, "d");
}

export function parseRedirect(s) {
  if (!s) {
    return "";
  }

  return s.substr(s.search("=") + 1);
}

export function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getGlobalSetting(component, key) {
  const state = store.getState();
  const globalSettings = state.settings.GlobalSettings;
  return (
    globalSettings
      ?.find((GS) => GS.component === component)
      ?.settings[key]?.toString() || null
  );
}

export function getPersonalSetting(component, key) {
  const state = store.getState();
  const personalSettings = state.settings.PersonalSettings;
  return (
    personalSettings
      ?.find((PS) => PS.component === component)
      ?.settings[key]?.toString() || null
  );
}

export function getDisplaySetting(component, key) {
  const state = store.getState();
  const displaySettings = state.settings.DisplaySettings;
  return (
    displaySettings
      ?.find((DS) => DS.component === component)
      ?.settings[key]?.toString() || null
  );
}

export function sortByColumn(property, type) {
  // check if type has been submitted
  // type should be 'date' for sorting by date
  type = typeof type === "undefined" ? "" : type;
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  if (type === "date") {
    return function (a, b) {
      var sortA =
        a[property] === ""
          ? 0
          : moment(
              a[property].substr(6, 4) +
                "-" +
                a[property].substr(3, 2) +
                "-" +
                a[property].substr(0, 2)
            ).format("X");
      var sortB =
        b[property] === ""
          ? 0
          : moment(
              b[property].substr(6, 4) +
                "-" +
                b[property].substr(3, 2) +
                "-" +
                b[property].substr(0, 2)
            ).format("X");
      var result = sortA < sortB ? -1 : sortA > sortB ? 1 : 0;
      return result * sortOrder;
    };
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function sortByDate(a, b) {
  // if(!a || !b) return 0
  var sortA = a
    ? moment(
        a.substr(6, 4) + "-" + a.substr(3, 2) + "-" + a.substr(0, 2)
      ).format("X")
    : 0;
  var sortB = b
    ? moment(
        b.substr(6, 4) + "-" + b.substr(3, 2) + "-" + b.substr(0, 2)
      ).format("X")
    : 0;
  var result = sortA < sortB ? -1 : sortA > sortB ? 1 : 0;
  return result;
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age > 0 ? age : "?";
}

export function isOdd(num) {
  return num % 2 === 1;
}

export function twoDigits(value) {
  if (value < 10) {
    return "0" + value;
  } else {
    return value.toString();
  }
}

export function timeDiff(timeString1, timeString2) {
  const minutes1 =
    parseInt(timeString1.substring(0, 2)) * 60 +
    parseInt(timeString1.substring(3, 5));
  const minutes2 =
    parseInt(timeString2.substring(0, 2)) * 60 +
    parseInt(timeString2.substring(3, 5));
  const minutes = Math.abs(minutes1 - minutes2);
  const tHours = Math.floor(minutes / 60);
  const tMinutes = minutes - tHours * 60;
  return twoDigits(tHours) + ":" + twoDigits(tMinutes);
}

export function timeDiffSec(timeString1, timeString2) {
  const seconds1 =
    parseInt(timeString1.substring(0, 2)) * 3600 +
    parseInt(timeString1.substring(3, 5)) * 60 +
    parseInt(timeString1.substring(6, 8));
  const seconds2 =
    parseInt(timeString2.substring(0, 2)) * 3600 +
    parseInt(timeString2.substring(3, 5)) * 60 +
    parseInt(timeString2.substring(6, 8));
  const seconds = Math.abs(seconds1 - seconds2);
  const tHours = Math.floor(seconds / 3600);
  const tMinutes = Math.floor((seconds - tHours * 3600) / 60);
  const tSeconds = seconds - tMinutes * 60 - tHours * 3600;
  return (
    twoDigits(tHours) + ":" + twoDigits(tMinutes) + ":" + twoDigits(tSeconds)
  );
}

export function SecondsFromTimeDiff(timeString1, timeString2) {
  if (timeString1.length > 0 && timeString2.length > 0) {
    const seconds1 =
      parseInt(timeString1.substring(0, 2)) * 3600 +
      parseInt(timeString1.substring(3, 5)) * 60 +
      parseInt(timeString1.substring(6, 8));
    const seconds2 =
      parseInt(timeString2.substring(0, 2)) * 3600 +
      parseInt(timeString2.substring(3, 5)) * 60 +
      parseInt(timeString2.substring(6, 8));
    // const seconds = Math.abs(seconds1 - seconds2);
    const seconds = seconds2 - seconds1;
    return seconds;
  }
  return 0;
}

export function secondsToTime(seconds, blink = false, noSeconds) {
  var separator = ":";
  const minus = seconds < 0 ? "-" : "";
  const absSeconds = Math.abs(seconds);
  const tHours = Math.floor(absSeconds / 3600);
  const tMinutes = Math.floor((absSeconds - tHours * 3600) / 60);
  const tSeconds = absSeconds - tMinutes * 60 - tHours * 3600;
  if (blink) {
    if (isOdd(tSeconds)) {
      separator = " ";
    } else {
      separator = ":";
    }
  }
  if (noSeconds) {
    return (
      minus +
      twoDigits(tHours) +
      separator +
      twoDigits(tMinutes + (tSeconds > 30 ? 1 : 0))
    );
  } else {
    return (
      minus +
      twoDigits(tHours) +
      separator +
      twoDigits(tMinutes) +
      separator +
      twoDigits(tSeconds)
    );
  }
}

export function timeToMinutes(timeString) {
  var minutes = parseInt(timeString.substring(3, 5));
  minutes += parseInt(timeString.substring(0, 2)) * 60;
  return minutes;
}

export function timeToSeconds(timeString) {
  var seconds = parseInt(timeString.substring(6, 8));
  seconds += parseInt(timeString.substring(3, 5)) * 60;
  seconds += parseInt(timeString.substring(0, 2)) * 3600;
  return seconds;
}

export function minutesToTime(minutes) {
  const tHours = Math.floor(minutes / 60);
  const tMinutes = minutes - tHours * 60;
  return twoDigits(tHours) + ":" + twoDigits(tMinutes);
}
