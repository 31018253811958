import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dayFrame: {
    width: "100%",
  },
  dayContainer: {
    width: "18%",
    height: "100%",
    marginRight: "2%",
  },
  dateFrame: {
    minHeight: "2rem",
    minWidth: "1rem",
    maxHeight: "2rem",
    maxWidth: "2rem",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 12px",
    gridTemplateRows: "30% 1fr 20%",
  },
  today: {
    gridColumn: "1 / 6",
    gridRow: "1 / 6",
    display: "flex",
    justifySelf: "center",
    alignSelf: "center",
    width: "95%",
    // backgroundColor: (props) => `${props.backgroundcolor}`,
  },
  animatedTodayIcon: {
    animation: "$glowing 2600ms infinite",
  },
  birthday: {
    gridColumn: "3",
    gridRow: "1",
    width: "30px",
    height: "30px",
    display: "flex",
    justifySelf: "center",
    alignSelf: "end",
  },
  bar: {
    gridColumn: "1 / 5",
    gridRow: "3 / 4",
  },
  "@keyframes glowing": {
    "0%": { transform: "perspective(300px) rotateY(0deg)" },
    "25%": { transform: "perspective(300px) rotateY(90deg)" },
    "50%": { transform: "perspective(300px) rotateY(180deg)" },
    "75%": { transform: "perspective(300px) rotateY(270deg)" },
    "100%": { transform: "perspective(300px) rotateY(360deg)" },
  },
}));

function ExampleDayFrame(props) {
  const classes = useStyles(props)
  return ( 
    <div 
      className = {classes.dayContainer}
    >
      <div
        className = {classes.dateFrame}
        style = {
          {backgroundColor: props.backgroundColor, color: props.color}
        }
      >
        <span className = 'date-text' > 
          {props.shortkey} 
        </span>
        {props.barcolor !== '' && (
          <div
            className={classes.bar}
            style={{backgroundColor: props.barcolor}}
          />
        )}
        {props.type === 'Today' && (
          <div
            className={classes.today}
          >          
            <svg className={props.animate ? classes.animatedTodayIcon : ""} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" aria-labelledby="title">
              <title id="title">today</title>
              <path fill={props.color} d={props.icon} />
            </svg>
          </div>
        )}
        {props.type === 'Birthdays' && (
          <div
            className={classes.birthday}
          >          
            <svg className='todayIcon' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="-8 -8 40 40" aria-labelledby="title">
              <title id="title">today</title>
              <path fill={props.color} d={props.icon} />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExampleDayFrame
