import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FlipMove from "react-flip-move";
import ModelItem from "./ModelItem";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 190px)",
    backgroundColor: theme.palette.background.messageList,
    marginRight: "4px",
    marginLeft: "4px",
    display: "grid",
    gridTemplateRows: "2rem 1fr",
  },
  list: {
    height: "52vh",
    overflowY: "auto",
    overflowX: "hidden",
    marginLeft: "2px",
    paddingTop: "0 !important",
  },
  listItem: {
    width: "100%",
    padding: "0 0 4px 0 !important",
  },
  listPaper: {
    width: "100%",
    padding: "0 !important",
    margin: "2px 6px",
    backgroundColor: theme.palette.background.messageListItem,
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
      opacity: "0.5",
    },
    borderRadius: "4px",
  },
  emptyText: {
    textAlign: "center"
  }
}));

const mapStateToProps = (state) => {
  const selectedId = state.articles.selectedArticleTypeId;
  const models = state.articles.ArticleModels.filter(A => A.articleTypeId === selectedId);
  return {
    models,
  };
};

const ModelsList = ({ models }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if(models.length > 0){
    const modelslist = models.map((M, index) => (
      <ListItem
        // button
        disableGutters
        key={index}
        className={classes.listItem}
      >
        <ModelItem
          key={index}
          id={M.id}
          name={M.modelName}
        />
      </ListItem>
    ));
    return (
      <List className={classes.list}>
        <FlipMove>{modelslist}</FlipMove>
      </List>
    );
  } else {
    return <div className={classes.emptyText}>{t("No models")}</div>;
  }
};

export default connect(mapStateToProps)(ModelsList);
