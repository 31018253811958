import {
  FETCH_SPECIALDAYS_REQUEST,
  FETCH_SPECIALDAYS_SUCCESS,
  SET_SPECIALDAY_ID,
  UPDATE_SPECIALDAY,
  SPECIALDAYS_FAILURE,
  SAVE_SPECIALDAY_COUNTRIES,
  FETCH_REMARKABLEDAYS_REQUEST,
  FETCH_REMARKABLEDAYS_SUCCESS,
  SET_REMARKABLEDAY_ID,
  UPDATE_REMARKABLEDAY,
  REMARKABLEDAYS_FAILURE,
  FETCH_HOLIDAYS_REQUEST,
  FETCH_HOLIDAYS_SUCCESS,
  UPDATE_HOLIDAY,
  ADD_HOLIDAY,
  DELETE_HOLIDAY,
  FETCH_HOLIDAYTYPES_REQUEST,
  FETCH_HOLIDAYTYPES_SUCCESS,
  HOLIDAYS_FAILURE,
  SET_HOLIDAY_ID,
} from "actions/types";

import { 
  specialDays, 
  specialDayUpdate,
  specialDayRegionsSave,
  remarkableDays,
  remarkableDayUpdate,
  holidays,
  holidayUpdate,
  holidayAdd,
  holidayDelete,
  holidaytypes
} from '../api'
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";

const specialDaysFailure = (err) => {
  return {
    type: SPECIALDAYS_FAILURE,
    payload: err
  }
}

const fetchSpecialDaysRequest = () => {
  return {
    type: FETCH_SPECIALDAYS_REQUEST
  }
}

const fetchSpecialDaysSuccess = (specialDays) => {
  return {
    type: FETCH_SPECIALDAYS_SUCCESS,
    payload: specialDays
  }
}

export const fetchSpecialDays = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchSpecialDaysRequest())
    try {
      const specialDaysJSONData = await dispatch(specialDays())
      await dispatch(fetchSpecialDaysSuccess(specialDaysJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(specialDaysFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

export const setSpecialDayId = (id) => {
  return {
    type: SET_SPECIALDAY_ID,
    payload: id
  }
}

const saveSpecialDayRegionsData = (values) => {
  return {
    type: SAVE_SPECIALDAY_COUNTRIES,
    payload: values
  };
}

export const saveSpecialDayRegions = (values) => {
  return async (dispatch) => {
        try {
          await dispatch(specialDayRegionsSave(values));
          await dispatch(saveSpecialDayRegionsData(values));
          await dispatch(dataSuccess());
        } catch (err) {
          await dispatch(dataFailure(err));
        }
  }
}

const remarkableDaysFailure = (err) => {
  return {
    type: REMARKABLEDAYS_FAILURE,
    payload: err
  }
}

const fetchRemarkableDaysRequest = () => {
  return {
    type: FETCH_REMARKABLEDAYS_REQUEST
  }
}

const fetchRemarkableDaysSuccess = (remarkableDays) => {
  return {
    type: FETCH_REMARKABLEDAYS_SUCCESS,
    payload: remarkableDays
  }
}

export const fetchRemarkableDays = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchRemarkableDaysRequest())
    try {
      const remarkableDaysJSONData = await dispatch(remarkableDays())
      await dispatch(fetchRemarkableDaysSuccess(remarkableDaysJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(remarkableDaysFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

export const setRemarkableDayId = (id) => {
  return {
    type: SET_REMARKABLEDAY_ID,
    payload: id
  }
}

const updateRemarkableDayData = (values) => {
  return {
    type: UPDATE_REMARKABLEDAY,
    payload: values
  }
}

export const updateRemarkableDay = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(remarkableDayUpdate(values));
      await dispatch(updateRemarkableDayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update remarkable days data', err); // eslint-disable-line no-console
      await dispatch(remarkableDaysFailure(err))
      await dispatch(dataFailure())
    }
  }
}

const updateSpecialDayData = (values) => {
  return {
    type: UPDATE_SPECIALDAY,
    payload: values
  }
}

export const updateSpecialDay = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(specialDayUpdate(values));
      await dispatch(updateSpecialDayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update special days data', err); // eslint-disable-line no-console
      await dispatch(specialDaysFailure(err))
      await dispatch(dataFailure())
    }
  }
}

const holidaysFailure = (err) => {
  return {
    type: HOLIDAYS_FAILURE,
    payload: err
  }
}

const fetchHolidaysRequest = () => {
  return {
    type: FETCH_HOLIDAYS_REQUEST
  }
}

const fetchHolidaysSuccess = (holidays) => {
  return {
    type: FETCH_HOLIDAYS_SUCCESS,
    payload: holidays
  }
}

export const fetchHolidays = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchHolidaysRequest())
    try {
      const holidaysJSONData = await dispatch(holidays())
      await dispatch(fetchHolidaysSuccess(holidaysJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(holidaysFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

export const setHolidayId = (id) => {
  return {
    type: SET_HOLIDAY_ID,
    payload: id
  }
}

const updateHolidayData = (values) => {
  return {
    type: UPDATE_HOLIDAY,
    payload: values
  }
}

export const updateHoliday = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(holidayUpdate(values));
      await dispatch(updateHolidayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update holidays data', err); // eslint-disable-line no-console
      await dispatch(holidaysFailure(err))
      await dispatch(dataFailure())
    }
  }
}

const addHolidayData = (values) => {
  return {
    type: ADD_HOLIDAY,
    payload: values
  }
}

export const addHoliday = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(holidayAdd(values))
      await dispatch(addHolidayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(holidaysFailure(err))
      await dispatch(dataFailure())
    }
  }
}

const deleteHolidayData = (id) => {
  return {
    type: DELETE_HOLIDAY,
    payload: id
  }
}

export const deleteHoliday = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(holidayDelete(id));
      await dispatch(deleteHolidayData(id))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to delete holiday data', err); // eslint-disable-line no-console
      await dispatch(holidaysFailure(err))
      await dispatch(dataFailure())
    }
  }
}

const fetchHolidayTypesRequest = () => {
  return {
    type: FETCH_HOLIDAYTYPES_REQUEST
  }
}

const fetchHolidayTypesSuccess = (holidaytypes) => {
  return {
    type: FETCH_HOLIDAYTYPES_SUCCESS,
    payload: holidaytypes
  }
}

export const fetchHolidayTypes = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading())
    await dispatch(fetchHolidayTypesRequest())
    try {
      const holidaytypesJSONData = await dispatch(holidaytypes())
      await dispatch(fetchHolidayTypesSuccess(holidaytypesJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(holidaysFailure(err))
      dispatch(dataEndLoading())
    }
  }
}
