import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dayFrame: {
    border: "1px solid transparent",
    width: "100%",
    height: "2.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: (props) => `${props.color}`,
  },
}));

const mapStateToProps = (state) => {
  return {
    displayyear: state.general.DisplayYear,
  };
};

function HeadlineDayFrame(props) {
  const classes = useStyles(props);
  return (
    <div
      className={classes.dayFrame}
      style={{
        fontSize:
          props.id === parseInt(moment().format("D")) &&
          parseInt(props.displayyear) === parseInt(moment().format("YYYY"))
            ? "1.2rem"
            : "inherit",
        fontWeight:
          props.id === parseInt(moment().format("D")) &&
          parseInt(props.displayyear) === parseInt(moment().format("YYYY"))
            ? "bold"
            : "inherit",
      }}
    >
      {props.id}
    </div>
  );
}

export default connect(mapStateToProps, null)(HeadlineDayFrame);
