import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { saveOffDayTypeName } from "redux/index";
import { languages } from "Constants";
import SomuraSelectField from "Components/SomuraSelectField";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "0 10px 6px 10px",
  },
  formHeader: {
    textAlign: "center",
    borderBottom: "2px solid grey",
    marginBottom: "10px",
    padding: "4px",
    fontSize: "22px",
    minHeight: "35px",
    lineHeight: "25px",
    color: theme.palette.text.screenTitle,
  },
  language: {
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
  },
}));

const mapStateToProps = (state) => {
  return {
    offDayTypes: state.offdaytypes.OffDayTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveOffDayTypeName: (values) => dispatch(saveOffDayTypeName(values)),
  };
};

const OffDayNameForm = ({
  displayName,
  offDayTypeId,
  nameId,
  language,
  offDayTypes,
  saveOffDayTypeName,
  closeNameForm,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    nameId: 0,
    offDayTypeId: 0,
    language: "",
    offDayType: "",
    offDayKey: "",
  };
  const [state, setState] = useState(emptyState);
  const displayLanguage = languages.find(
    (L) => L.languageName === language
  )?.languageLongName;

  const languageOptions = languages.map((L) => ({
    label: L.languageLongName,
    value: L.languageName,
  }));

  const handleSubmit = () => {
    saveOffDayTypeName(state);
    closeNameForm();
  };

  const handleClose = () => {
    closeNameForm();
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setState({ ...state, [event.target.name]: value });
  };

  useEffect(() => {
    setState({
      nameId: nameId,
      offDayTypeId: offDayTypeId,
      offDayType:
        offDayTypes
          ?.find((OT) => OT.id === offDayTypeId)
          ?.names.find((N) => N.id === nameId)?.offDayType ?? "",
      offDayKey:
        offDayTypes
          ?.find((OT) => OT.id === offDayTypeId)
          ?.names.find((N) => N.id === nameId)?.offDayKey ?? "",
      language: language || "de-DE",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offDayTypes, nameId, language]);

  return (
    <div className={classes.thisForm}>
      <div>
        <div className={classes.formHeader}>
          {nameId > 0
            ? t("Absence type") +
              ": " +
              displayName +
              " > " +
              t("Designation and identifier") +
              " > " +
              displayLanguage
            : t("New absence type")}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={2} className={classes.language}>
            <SomuraSelectField
              name="language"
              label={t("Language")}
              value={state.language}
              options={languageOptions}
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <TextField
              type="text"
              name="offDayType"
              label={t("Designation")}
              value={state.offDayType}
              maxLength="255"
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <TextField
              type="text"
              name="offDayKey"
              label={t("Identifier")}
              value={state.offDayKey}
              maxLength="3"
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
            />
          </Grid>
        </Grid>
      </div>
      <div className="formBottom">
        <CancelButton onClick={handleClose} />
        <SaveButton onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OffDayNameForm);
