import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DashboardItem from "Components/DashboardItem";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontSize: "1rem",
    color: theme.palette.dashBoard.title,
  },
  tableCell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "inherit",
    fontWeight: "inherit"
  },
  highlight: {
    fontWeight: "500",
    color: theme.palette.text.important,
  },
}));

const mapStateToProps = (state) => {
  return {
    dashboardSettings: state.settings.PersonalSettings?.find(
      (GS) => GS.component === "dashboard"
    )?.settings,
    offDayTypes: state.offdaytypes.OffDayTypes,
  };
};

function NextEvents(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");

  const showAbsenceStart = parseInt(props.dashboardSettings?.showAbsenceStart);
  const numEventDays = parseInt(props.dashboardSettings?.numEventDays);
  var data = props.data;
  if (data && !showAbsenceStart) {
    data = data.filter((D) => D.type !== "Start ");
  }
  const numItems = data?.length;
  const noData = data && data.length === 0 ? t("No upcoming events.") : "";

  const tableCell = (row) => {
    var cell;
    if (row.number !== "0" && row.typeId === "0") {
      cell = row.number;
    } else {
      cell = "";
    }
    cell += t(row.type);
    if (row.typeId !== "0" && props.offDayTypes && language) {
      const offDayType = props.offDayTypes.find((OT) => OT.id === row.typeId);
      const offDayName = offDayType
        ? offDayType.names.find((OTN) => OTN.language === language).offDayType
        : "";
      cell +=
        offDayName +
        " (" +
        row.number +
        " " +
        (row.number === "1.0" ? t("day") : t("days")) +
        ")";
      cell += " " + row.date + (parseInt(row.number) > 1 ? " " + t("to") + " " + row.endDate : "")
    }
    return cell;
  };

  const dataTable = () => {
    if (props.value > 0) {
      return (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>{t("Date")}</TableCell>
              <TableCell className={classes.tableHeader}>{t("Name")}</TableCell>
              <TableCell className={classes.tableHeader}>
                {t("Event")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                className={
                  row.date === moment().format("DD.MM.YYYY")
                    ? classes.highlight
                    : ""
                }
              >
                <TableCell className={classes.tableCell}>
                  {row.date === moment().format("DD.MM.YYYY") ? t("Today")
                  : row.date}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.lastName + ", " + row.firstName}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {tableCell(row)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <DashboardItem
      title={t("Events")}
      subtitle={t("within the next ") + numEventDays + " " + t("days")}
      value={numItems}
      iconColor="#AFB42B"
      icon={<EmojiEventsTwoToneIcon style={{ viewBox: "0 0 100 100" }} />}
      table={dataTable()}
      nodata={noData}
    />
  );
}

export default connect(mapStateToProps, null)(NextEvents);
