import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { resetApprovementState } from "redux/index";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

import { store } from "redux/store";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SomuraSelectField from "Components/SomuraSelectField";

import makeStyles from "@mui/styles/makeStyles";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

import DateList from "Calendars/Components/DateList";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: 700,
  },
  cancelButton: {
    gridColumn: 3,
  },
  saveButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-acceptform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  const logged = state.loggeduser.LoggedUser.id;
  const displayyear = state.general.DisplayYear;
  const offDayTypes = state.offdaytypes.OffDayTypes;
  const userId = state.yearcalendar.displayUserId;
  return {
    logged,
    displayyear,
    offDayTypes,
    userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetApprovementState: (values) => dispatch(resetApprovementState(values)),
  };
};

const AcceptForm = ({
  logged,
  displayyear,
  offDayTypes,
  offDayTextId,
  dates,
  userId,
  approvementId,
  approvementState,
  acceptOffDays,
  resetApprovementState,
  closeResetForm,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const offDayTypeName = offDayTypes
    .find((ODT) => ODT.id === props.offDayTypeId)
    ?.names.find(
      (OTN) => OTN.language === localStorage.getItem("lang")
    )?.offDayType;

  const [state, setState] = useState({
    loggedUserId: logged,
    approvementId: approvementId,
    approvementState: "",
  });

  let stateOptions = [];
  switch (approvementState) {
    case "2": //requested
      stateOptions.push({ label: t("planned"), value: "1" });
      break;
    case "3": // approved
      stateOptions.push({ label: t("requested"), value: "2" });
      stateOptions.push({ label: t("planned"), value: "1" });
      break;
    default:
  }

  const handleSubmit = () => {
    resetApprovementState(state);
    handleClose();
  };

  const handleStateChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setState({ ...state, [name]: value });
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    closeResetForm();
  };

  const title = () => {
    let title = "";
    let subtitle = "";
    const firstDate = moment(props.startDate).format("dddd, LL");
    const lastDate = moment(props.endDate).format("dddd, LL");

    if (firstDate === lastDate) {
      title = firstDate;
    } else {
      title = firstDate + " bis " + lastDate;
    }

    subtitle =
      props.numWorkdays +
      (props.numWorkdays === 1
        ? " " + t("working day") + " ("
        : " " + t("working days") + " (");
    subtitle +=
      props.numDays +
      (props.numDays === 1 ? " " + t("day") + ")" : " " + t("days") + ")");

    return (
      <span>
        {title}
        <br />
        {subtitle}
      </span>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-acceptform-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-acceptform-title"
        dialogTitle={t("Reset absence state")}
        closeForm={handleClose}
        width="600px"
        color="white"
        backgroundColor="#1769AA"
      />
      <DialogContent className="dialogContent">
        <div className="timeFrame">{title()}</div>

        <fieldset className={classes.fieldset} disabled={props.formDisabled}>
          <Grid container spacing={3}>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DateList dates={dates} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="offDayTypeName"
                    label={t("Absence type")}
                    disabled={true}
                    value={offDayTypeName}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                {props.additionalText?.length > 0 && (
                  <Grid item xs={12}>
                    <TextField
                      name="additionalText"
                      label={t("Additional text")}
                      disabled={true}
                      value={props.additionalText}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <SomuraSelectField
                    name="approvementState"
                    label={t("New state")}
                    value={state.approvementState}
                    options={stateOptions}
                    onChange={handleStateChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </fieldset>
      </DialogContent>
      <DialogActions className="dialogActions">
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <SaveButton
          disabled={state.approvementState === ""}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptForm);
