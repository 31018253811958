import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "redux/store";
import {
  setDisplayYear,
  fetchYearStatic,
  fetchReminders,
  fetchYear,
} from "redux/index";
import moment from "moment";
import "../Calendars.scss";
import { getGlobalSetting } from "Components/Utilities";

import makeStyles from "@mui/styles/makeStyles";
import SomuraTooltip from "Components/SomuraTooltip";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SomuraAutocomplete from "Components/SomuraAutocomplete";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import IconButton from "@mui/material/IconButton";
import RefreshButton from "Components/Buttons/RefreshButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import TimelineIcon from "@mui/icons-material/Timeline";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import UserStats from "../Components/UserStats";
import AbsenceStats from "../Components/AbsenceStats";

const useStyles = makeStyles((theme) => ({
  yearcalheader: {
    marginBottom: "6px",
    height: "54px",
    textTransform: "uppercase",
    fontSize: "1.2rem",
    paddingTop: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  headerIcon: {
    cursor: "pointer",
    marginTop: "6px",
  },
  headerYear: {
    fontSize: "1.2rem",
    marginTop: "0",
    padding: "4px 0",
    cursor: "pointer",
  },
  yearDays: {
    width: "7rem",
    marginTop: "-1rem",
    textAlign: "center",
    fontSize: "0.8rem",
  },
  monthname: {
    gridColumn: "1",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px",
  },
  currentMonth: {
    color: "orange",
  },
  select: {
    marginLeft: "6px",
  },
  iconButton: {
    marginTop: 0,
    padding: "4px",
  },
  speedDialWrapper: {
    width: "4rem",
    height: "2.5rem",
    display: "flex",
    justifyContent: "space-around",
  },
  speedDial: {
    position: "absolute",
  },
  action: {
    backgroundColor: theme.palette.somura,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.blue,
    },
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.9rem",
    borderRadius: "5px",
    boxShadow: "5px 5px 3px 1px rgba(0, 0, 0, 0.4)",
  },
}));

const mapStateToProps = (state) => {
  const settings = state.settings.PersonalSettings?.find(
    (GS) => GS.component === "calendars"
  )?.settings;
  const YearCalSettings = state.settings.PersonalSettings?.find(
    (GS) => GS.component === "yearcalendar"
  )?.settings;
  const usePersonNumbers = state.company.Company.usePersonNumbers;
  const loggedUser = state.loggeduser.LoggedUser;
  const displayyear = state.general.DisplayYear;
  const persons = state.persons.PersonsMin.filter(
    (P) =>
      P.lastName !== "" &&
      (P.active === true || parseInt(settings?.showOnlyActive) === 0)
  );
  const selectedPersonId = state.yearcalendar.displayUserId;
  const selectedPerson = persons.find((P) => P.id === selectedPersonId);
  const yearStatic = state.year.YearStatic;
  return {
    loggedUser,
    persons,
    displayyear,
    selectedPersonId,
    selectedPerson,
    yearStatic,
    YearCalSettings,
    usePersonNumbers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchYearStatic: (values) => dispatch(fetchYearStatic(values)),
    fetchYear: (values) => dispatch(fetchYear(values)),
    fetchReminders: (id) => dispatch(fetchReminders({ userId: id })),
    setDisplayYear: (year) => dispatch(setDisplayYear(year)),
  };
};

const YearCalHeader = ({
  loggedUser,
  persons,
  displayyear,
  selectedPersonId,
  selectedPerson,
  yearStatic,
  YearCalSettings,
  usePersonNumbers,
  fetchYear,
  fetchYearStatic,
  fetchReminders,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const emptyState = {
    year: moment().format("YYYY"),
    person: selectedPersonId ? selectedPersonId : loggedUser.id || "0",
    workDays: 0,
  };
  const [state, setState] = useState(emptyState);
  const [openStatsForm, setOpenStatsForm] = useState(
    parseInt(YearCalSettings?.autoOpenStats) === 1
  );
  const [openAbsenceStatsForm, setOpenAbsenceStatsForm] = useState(false);

  var personOptions = persons?.map((P) => ({
    label: P.listName,
    value: P.id,
  }));
  personOptions.push({ label: "", value: "0" });

  const calcWorkDays = () => {
    const currentDate = moment(displayyear + "-01-01");
    const workOnSaturday =
      parseInt(getGlobalSetting("system", "workOnSaturday")) === 1;
    let workDays = 0;
    let specialDay;
    while (currentDate.format("YYYY") === displayyear.toString()) {
      if (
        (currentDate.format("d") !== "6" || workOnSaturday) &&
        currentDate.format("d") !== "0"
      ) {
        workDays++;
        specialDay = yearStatic.find(
          (YS) =>
            YS.date === currentDate.format("YYYY-MM-DD") && YS.isOffDay === true
        );
        if (specialDay) {
          workDays -= 1 - parseFloat(specialDay.dayFactor);
        }
      }
      currentDate.add(1, "days");
    }
    return workDays;
  };

  const showStats = () => {
    setOpenStatsForm(true);
  };

  const showAbsenceStats = () => {
    setOpenAbsenceStatsForm(true);
  };

  const showStatsBar = () => {
    props.showStatsBar();
  };

  const refreshCalendar = () => {
    fetchYear({
      year: displayyear,
      userid: selectedPersonId,
    });
    fetchReminders(state.person);
  };

  const nextYear = () => {
    const newYear = parseInt(displayyear) + 1;
    setState({ ...state, year: newYear });
  };

  const prevYear = () => {
    const newYear = parseInt(displayyear) - 1;
    setState({ ...state, year: newYear });
  };

  const thisYear = () => {
    const newYear = parseInt(moment().format("YYYY"));
    setState({ ...state, year: newYear });
  };

  const handleAutoCompleteChange = (params) => {
    const value = params.value;
    setState({ ...state, person: value });
  };

  useEffect(() => {
    fetchYearStatic({
      year: state.year,
    });
    store.dispatch({
      type: "SET_DISPLAY_YEAR",
      payload: state.year,
    });

    fetchReminders(state.person);
    // eslint-disable-next-line
  }, [displayyear]);

  // useEffect(() => {
  //   if (yearStatic) {
  //     calcWorkDays();
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    fetchYear({
      year: state.year,
      userid: state.person,
    });
    store.dispatch({
      type: "SET_DISPLAY_USER",
      payload: state.person,
    });
    // eslint-disable-next-line
  }, [state.year, state.person]);

  useEffect(() => {
    if (loggedUser) {
      setState(emptyState);
    }
    // eslint-disable-next-line
  }, [loggedUser]);

  return (
    <>
      <div className={classes.yearcalheader}>
        <div className="column">
          <SomuraTooltip
            title={t("Previous year")}
            placement="top"
            arrow={true}
          >
            <IconButton
              className={classes.iconButton}
              onClick={prevYear}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
          </SomuraTooltip>
        </div>
        <div className="column">
          <SomuraTooltip title={t("This year")} placement="top" arrow={true}>
            <IconButton
              className={classes.headerYear}
              onClick={thisYear}
              size="large"
            >
              {displayyear}
            </IconButton>
          </SomuraTooltip>
        </div>
        <div className="column">
          <SomuraTooltip title={t("Next year")} placement="top" arrow={true}>
            <IconButton
              className={classes.iconButton}
              onClick={nextYear}
              size="large"
            >
              <ChevronRightIcon />
            </IconButton>
          </SomuraTooltip>
        </div>
        <div className="column">
          <SomuraTooltip title={t("This year")} placement="top" arrow={true}>
            <IconButton
              className={classes.iconButton}
              onClick={thisYear}
              size="large"
            >
              <HomeOutlinedIcon />
            </IconButton>
          </SomuraTooltip>
        </div>
        <SomuraAutocomplete
          name="person"
          label={t("Employee")}
          value={state.person}
          variant="outlined"
          size="small"
          fullWidth
          disableClearable={true}
          disabled={!loggedUser.isadmin && !loggedUser.isassistant}
          options={personOptions}
          onChange={(value) => handleAutoCompleteChange(value)}
        />
        <div className={classes.speedDialWrapper}>
          <SpeedDial
            className={classes.speedDial}
            ariaLabel="SpeedDial"
            icon={<InfoOutlinedIcon />}
            direction="down"
            FabProps={{ color: "primary", size: "small" }}
          >
            <SpeedDialAction
              className={classes.action}
              key={"1"}
              icon={<EqualizerIcon />}
              tooltipTitle={t("Statistics")}
              TooltipClasses={classes}
              onClick={showStats}
            />
            <SpeedDialAction
              className={classes.action}
              key={"2"}
              icon={<QueryStatsOutlinedIcon />}
              tooltipTitle={t("Absence statistics")}
              TooltipClasses={classes}
              onClick={showAbsenceStats}
            />
            <SpeedDialAction
              className={classes.action}
              key={"3"}
              icon={<TimelineIcon />}
              tooltipTitle={t("Statistics graph")}
              TooltipClasses={classes}
              onClick={showStatsBar}
            />
          </SpeedDial>
        </div>
        <RefreshButton onClick={refreshCalendar} />
      </div>
      <div className={classes.yearDays}>
        {calcWorkDays()} {t("Working days")}
      </div>
      {openStatsForm === true && (
        <UserStats
          closeStatsForm={() => {
            setOpenStatsForm(false);
          }}
        />
      )}
      {openAbsenceStatsForm === true && (
        <AbsenceStats
          closeStatsForm={() => {
            setOpenAbsenceStatsForm(false);
          }}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(YearCalHeader);
