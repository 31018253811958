import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setSelectedArticleTypeId } from "redux/index";
import makeStyles from "@mui/styles/makeStyles";

import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  item: {
    textTransform: "none",
    color: "white",
  },
  headline: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  const types = state.articles.ArticleTypes;
  const models = state.articles.ArticleModels;
  const selectedId = state.articles.selectedArticleTypeId;
  return { types, models, selectedId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setId: (id) => dispatch(setSelectedArticleTypeId(id)),
  };
};

function TypesTree({ types, models, selectedId, setId }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
    ({ theme }) => ({
      [`& .${treeItemClasses.content}`]: {
        "&.Mui-selected div.MuiTreeItem-label": {
          background: theme.palette.primary.main
        }
      },
      [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
          opacity: 0.3,
        },
      },
      [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      },
    })
  );

  const handleClick = (id) => {
    setId(id);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const Tree = (parentId = 0) => {
    return types
      .filter((T) => T.parentId === parentId)
      .map((T) => (
        <StyledTreeItem
          key={T.id}
          nodeId={T.id}
          label={
            T.articleTypeName +
            (models?.filter((M) => M.articleTypeId === T.id).length > 0
              ? " (" + models?.filter((M) => M.articleTypeId === T.id).length + ")"
              : "")
          }
          className={T.notSelectable ? classes.headline : classes.item}
          onClick={() => handleClick(T.id)}
        >
          {Tree(T.id)}
        </StyledTreeItem>
      ));
  };

  useEffect(() => {
    if(parseInt(selectedId) <= 0){
      setSelected([])
    } else {
      setSelected([selectedId]);
    }
  },[selectedId])

  useEffect(() => {
    const unique = [
      ...new Set(
        types.filter((T) => T.parentId !== "0").map((T) => T.parentId)
      ),
    ];
    setExpanded(unique);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TreeView
      aria-label="customized"
      expanded={expanded}
      selected={selected}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      sx={{ height: "60vh", flexGrow: 1, overflowY: "auto" }}
    >
      {Tree("0")}
    </TreeView>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TypesTree);
