import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  label: {
    width: "100%",
    marginTop: (props) => (props.marginTop ? props.marginTop : "8px"),
    color: theme.palette.text.main,
  },
  disabled: {
    width: "100%",
    marginTop: (props) => (props.marginTop ? props.marginTop : "8px"),
    fontStyle: "italic",
    color: theme.palette.text.disabled,
  },
  right: {
    textAlign: "end",
  },
  left: {
    textAlign: "start",
  },
}));

const SomuraLabel = (props) => {
  const classes = useStyles(props);
  return (
    <div
      className={
        (props.disabled ? classes.disabled : classes.label) +
        " " +
        (props.alignRight ? classes.right : classes.left)
      }
    >
      {props.text}
    </div>
  );
};

export default SomuraLabel;
