import {
  FETCH_REMINDERS_REQUEST,
  FETCH_REMINDERS_SUCCESS,
  SAVE_REMINDER,
  DELETE_REMINDER,
  REMINDERS_FAILURE,
  SET_SELECTED_REMINDER,
  SET_DISPLAY_REMINDER,
  SET_REMINDER_ALERT,
} from 'actions/types'
import { reminders, reminderSave, reminderDelete, reminderSetAlert } from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

const remindersFailure = (err) => {
  return {
    type: REMINDERS_FAILURE,
    payload: err,
  };
};

const fetchRemindersRequest = () => {
  return {
    type: FETCH_REMINDERS_REQUEST
  }
}

const fetchRemindersSuccess = (reminders) => {
  return {
    type: FETCH_REMINDERS_SUCCESS,
    payload: reminders
  }
}

export const fetchReminders = (values) => {
  return async (dispatch) => {
    await dispatch(fetchRemindersRequest())
    try {
      const returnJSONData = await dispatch(reminders(values))
      await dispatch(fetchRemindersSuccess(returnJSONData))
    } catch(err) {
      await dispatch(remindersFailure(err))
    }
  }
}

const saveReminderData = (values) => {
  return {
    type: SAVE_REMINDER,
    payload: values
  }
}

export const saveReminder = (values) => {
  return async (dispatch) => {
    
    try {
      await dispatch(saveReminderData(values));
      await dispatch(reminderSave(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err))
    }
  }
}

const deleteReminderData = (values) => {
  return {
    type: DELETE_REMINDER,
    payload: values
  }
}

export const deleteReminder = (values) => {
  return async (dispatch) => {
    
    try {
      await dispatch(deleteReminderData(values));
      await dispatch(reminderDelete(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err))
    }
  }
}

export const setSelectedReminder = (values) => {
  return {
    type: SET_SELECTED_REMINDER,
    payload: values
  }
}

export const setDisplayReminder = (values) => {
  return {
    type: SET_DISPLAY_REMINDER,
    payload: values
  }
}

const saveReminderAlert = (values) => {
  return {
    type: SET_REMINDER_ALERT,
    payload: values,
  };
};

export const setReminderAlert = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(saveReminderAlert(values));
      await dispatch(reminderSetAlert(values));
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};