import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { setRemarkId } from "redux/index";
import moment from "moment";
import parse from "html-react-parser";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "4px",
    cursor: "pointer",
  },
  listPaper: {
    padding: "0 !important",
    margin: "2px 0",
    backgroundColor: theme.palette.background.messageListItem,
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
    },
    borderRadius: "4px",
  },
  person: {
    fontSize: "0.8rem",
    opacity: "0.6"
  },
  text: {
    fontSize: "1rem",
    whiteSpace: "pre-wrap"
  },
}));

const mapStateToProps = (state) => {
  return {
    persons: state.persons.PersonsMin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setId: (values) => dispatch(setRemarkId(values)),
  };
};

const Remark = ({ persons, remark, remarkClick, setId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const createdOn = moment(remark.createdOn).format("L kk:mm");
  const createdBy =
    t("Created by") +
    " " +
    persons.find((P) => P.id === remark.createdById)?.fullName +
    " - " +
    createdOn;
  const modifiedOn = moment(remark.modifiedOn).format("L kk:mm");
  const modifiedBy =
    t("Modified by") +
    " " +
    persons.find((P) => P.id === remark.modifiedById)?.fullName +
    " - " +
    modifiedOn;

    const handleClick = () => {
      setId(remark.id)
      remarkClick()
    };


  return (
    <Paper square elevation={8} className={classes.listPaper}>
      <div className={classes.root} onClick={handleClick}>
        <div className={classes.person}>{createdBy}</div>
        {modifiedOn !== createdOn && (
          <div className={classes.person}>{modifiedBy}</div>
        )}
        <span className={classes.text}>{parse(remark.text)}</span>
      </div>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Remark);
