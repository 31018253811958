import React, { useState, useEffect, useCallback } from "react";
import { store } from "redux/store";
import {
  fetchDepartments,
  fetchRemarks,
  fetchPerson,
  fetchCompanies,
  updatePerson,
  addPerson,
  setPersonId,
  setEntitlementId,
  setCovStateId,
  setSalaryId,
  setSelectedArticleId,
  setRemarkId,
  setDisplayRemarksIds,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getGlobalSetting } from "Components/Utilities";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";

import * as Yup from "yup";
import makeStyles from "@mui/styles/makeStyles";
import Collapse from "@mui/material/Collapse";
import { getAge } from "Components/Utilities";

import SomuraAutocomplete from "Components/SomuraAutocomplete";
import SomuraTextField from "Components/SomuraTextField";
import SomuraSwitchField from "Components/SomuraSwitchField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import BackButton from "Components/Buttons/BackButton";
import SaveButton from "Components/Buttons/SaveButton";

import AddIcon from "@mui/icons-material/Add";

import EntitlementsTable from "./EntitlementsTable";
import EntitlementForm from "./EntitlementForm";
import SalaryTable from "./SalaryTable";
import SalaryForm from "./SalaryForm";
import LoanAssetsTable from "./LoanAssetsTable";
import LoanAssetForm from "./LoanAssetForm";
import CovStatesTable from "./CovStatesTable";
import CovStateForm from "./CovStateForm";
import RemarksList from "Components/Remarks/RemarksList";
import RemarkDialog from "Components/Remarks/RemarkDialog";

const useStyles = makeStyles((theme) => ({
  formHeader: {
    textAlign: "center",
    borderBottom: "2px solid grey",
    padding: "4px",
    fontSize: "22px",
    minHeight: "35px",
    lineHeight: "25px",
    color: theme.palette.text.screenTitle,
    display: "grid",
    gridTemplateColumns: "5rem 1fr 5rem",
  },
  formTitle: {
    gridColumn: "2 / 3",
  },
  remarks: {
    textAlign: "center",
    gridColumn: "-1",
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
  fieldset: {
    border: "none",
    padding: "0px",
    margin: "0",
  },
  emergency: {
    backgroundColor: "red",
  },
  fab: {
    position: "fixed",
    bottom: "16px",
    right: "16px",
  },
}));

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;

  const personId = state.persons.personId;
  const person = state.persons.Person;
  const persons = state.persons.PersonsMin;
  const departments = state.departments.Departments;
  const companies = state.companies.Companies;
  const selectedCovStateId = state.selections.selectedCovStateId;
  const selectedEntitlementId = state.selections.selectedEntitlementId;
  const selectedArticleId = state.articles.selectedArticleId;
  const selectedSalaryId = state.selections.selectedSalaryId;
  const selectedRemarkId = state.remarks.selectedRemarkId;
  return {
    loggedUser,
    personId,
    person,
    persons,
    departments,
    companies,
    selectedCovStateId,
    selectedEntitlementId,
    selectedArticleId,
    selectedSalaryId,
    selectedRemarkId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDepartments: () => dispatch(fetchDepartments()),
    fetchRemarks: () => dispatch(fetchRemarks()),
    fetchPerson: (values) => dispatch(fetchPerson(values)),
    fetchCompanies: () => dispatch(fetchCompanies()),
    updatePerson: (values) => dispatch(updatePerson(values)),
    addPerson: (values) => dispatch(addPerson(values)),
    setPersonId: (id) => dispatch(setPersonId(id)),
    setEntitlementId: (id) => dispatch(setEntitlementId(id)),
    setCovStateId: (id) => dispatch(setCovStateId(id)),
    setSalaryId: (id) => dispatch(setSalaryId(id)),
    setArticleId: (id) => dispatch(setSelectedArticleId(id)),
    setRemarkId: (id) => dispatch(setRemarkId(id)),
    setDisplayRemarksIds: (values) => dispatch(setDisplayRemarksIds(values)),
  };
};

const PersonsForm = ({
  selectedCovStateId,
  selectedEntitlementId,
  selectedArticleId,
  selectedSalaryId,
  selectedRemarkId,
  person,
  personId,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const loggedUserId = props.loggedUser.id;
  const isAdmin = props.loggedUser.isadmin;
  const isAssistant = props.loggedUser.isassistant;

  const useTimeTrackModule =
    parseInt(getGlobalSetting("system", "useTimeTrackModule")) === 1;
  const useLoanModule =
    parseInt(getGlobalSetting("system", "useLoanModule")) === 1;
  const useCovModule =
    parseInt(getGlobalSetting("system", "useCovModule")) === 1;
  const usePersonNumbers =
    parseInt(getGlobalSetting("persons", "usePersonNumbers")) === 1;

  const emptyState = {
    id: 0,
    username: "",
    salutationKey: "0",
    lastName: "",
    firstName: "",
    initials: "",
    personNumber: "",
    position: "",
    startDate: "",
    endDate: "",
    endReason: "",
    birthday: "",
    active: true,
    accountLocked: true,
    changePW: false,
    isadmin: false,
    isassistant: false,
    isapprentice: false,
    showBirthday: false,
    showAge: false,
    street: "",
    street_number: "",
    zip: "",
    city: "",
    country: "D",
    email: "",
    emailCompany: "",
    telephoneCompany: "",
    mobileCompany: "",
    telephone: "",
    mobile: "",
    IBAN: "",
    BIC: "",
    socialInsuranceNumber: "",
    taxIdNumber: "",
    emergencyName: "",
    emergencyNumber: "",
    healthInsuranceId: "0",
    internetGrant: "0",
    companyBicycle: false,
    companyCar: false,
    companyCarNumber: "",
    insuranceId1: "0",
    insuranceNumber1: "",
    insuranceIBAN1: "",
    insuranceBIC1: "",
    insuranceStart1: "",
    insuranceValue1: "0",
    insuranceId2: "0",
    insuranceNumber2: "",
    insuranceValue2: "0",
    insuranceFundingId2: "0",
    insuranceStart2: "",
    insuranceId3: "0",
    insuranceNumber3: "",
    insuranceValue3: "0",
    insuranceFundingId3: "0",
    insuranceStart3: "",
    insuranceId4: "0",
    insuranceNumber4: "",
    insuranceValue4: "0",
    insuranceFundingId4: "0",
    insuranceStart4: "",
    insuranceId5: "0",
    insuranceNumber5: "",
    insuranceValue5: "0",
    insuranceFundingId5: "0",
    insuranceStart5: "",
    isapprover: false,
    deputyId: "0",
    approverId: "0",
    departmentId: "0",
    showMonthCalendar: false,
    showUserStats: false,
    showOwnDepartment: true,
    messagesToAllUsers: false,
    deletePlannedAbsence: false,
    modifyTimes: false,
    dailyWorkingTime: 480,
    noTimeTracking: false,
    age: "?",
    usePersonNumbers: false,
    remarksIds: [],
  };
  const [state, setState] = useState(emptyState);
  const [value, setValue] = useState("1");
  const [valid, setValid] = useState(false);
  const [openRemarkDialog, setOpenRemarkDialog] = useState(false);

  const PersonSchema = Yup.object().shape({
    salutationKey: Yup.string().required(t("Required")),
    lastName: Yup.string().required(t("Required")),
    firstName: Yup.string().required(t("Required")),
    usePersonNumbers: Yup.boolean(),
    personNumber: Yup.string().when("usePersonNumbers", {
      is: true,
      then: Yup.string().required(t("Required")),
      otherwise: Yup.string(),
    }),
    initials: Yup.string()
      .max(2, t("maxChars", { numChars: "2" }))
      .required(t("Required"))
      .nullable(),
    emailCompany: Yup.string()
      .email(t("Invalid mail address"))
      .required(t("Required"))
      .nullable(),
    position: Yup.string()
      .max(100, t("maxChars", { numChars: "100" }))
      .nullable(),
    startDate: Yup.date().required(t("Required")),
    birthday: Yup.date().required(t("Required")),
    country: Yup.string().max(3, t("maxChars", { numChars: "3" })),
    companyCar: Yup.boolean(),
    companyCarNumber: Yup.string().when("companyCar", {
      is: true,
      then: Yup.string().required(t("Required")),
      otherwise: Yup.string(),
    }),
  });

  const selectedUser = person;
  const age = selectedUser ? getAge(selectedUser.birthday) : 0;
  const approvers = props.persons.filter(
    (d) => d.isapprover && d.id !== state.id
  );

  var companyOptions =
    props.companies?.map((c) => ({
      label: c.name,
      value: c.id,
    })) || [];
  companyOptions.push({ label: "", value: "0" });

  var deptOptions =
    props.departments?.map((D) => ({
      label: D.department,
      value: D.id,
    })) || [];
  deptOptions.push({ label: "", value: "0" });

  var approverOptions = approvers.map((d) => ({
    label: d.fullName,
    value: d.id,
  }));
  approverOptions.push({ label: "", value: "0" });

  var fundingOptions = [
    { label: "", value: "0" },
    { label: t("Employer"), value: "1" },
    { label: t("Employee"), value: "2" },
  ];

  let formHeader = "";
  if (parseInt(personId) > 0) {
    formHeader = selectedUser.listName;
    if (!selectedUser.active) {
      formHeader += " (" + t("Not active") + ")";
    }
  } else {
    formHeader = t("New employee");
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await PersonSchema.isValid(state));
  });

  const resetIds = () => {
    props.setEntitlementId(-1);
    props.setCovStateId(-1);
    props.setSalaryId(-1);
    props.setArticleId(-1);
    props.setRemarkId(-1);
  };

  const saveRemarksIds = (ids) => {
    setState({ ...state, remarksIds: ids });
  };

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "new":
        props.startPersonNew();
        break;
      case "newAsset":
        props.setArticleId(0);
        break;
      case "newEntitlement":
        props.setEntitlementId(0);
        break;
      case "newSalary":
        props.setSalaryId(0);
        break;
      case "newCovState":
        props.setCovStateId(0);
        break;
      case "newRemark":
        props.setRemarkId(0);
        setOpenRemarkDialog(true);
        break;
      case "cancel":
        resetIds();
        props.setPersonId(-1);
        break;
      default:
        props.setPersonId(-1);
    }
  };

  const handleSubmit = () => {
    if (state.id === 0) {
      props.addPerson(state);
    } else {
      props.updatePerson(state);
    }
    props.setPersonId(-1);
  };

  const handleChange = (event) => {
    const name = event.target ? event.target.name : event.name;
    const value = event.target ? event.target.value : event.value;

    if (name === "endDate") {
      setState({ ...state, endDate: value, active: value === "" });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value === "" ? "0" : params.value;
    setState({ ...state, [name]: value });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    if (name === "companyCar" && value === false) {
      setState({ ...state, [name]: value, companyCarNumber: "" });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    // if (Object.keys(selectedUser).length > 0) {
    if (parseInt(personId) > 0) {
      setState({
        ...selectedUser,
        age: age,
        usePersonNumbers: usePersonNumbers,
      });
      props.setDisplayRemarksIds(selectedUser.remarksIds);
    } else {
      resetIds();
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId, selectedUser, age, usePersonNumbers]);

  useEffect(() => {
    // load data
    props.fetchCompanies();
    props.fetchDepartments();
    if (parseInt(personId) > 0) {
      props.fetchRemarks();
      // Reset ids
      resetIds();
      // Fetch person data
      props.fetchPerson({ userId: personId });
      // check, if approver is set
      if (selectedUser && selectedUser.approverId === "0") {
        store.dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            text: t(
              "Please select a person that is allowed to approve vaction for this employee."
            ),
            type: "warning",
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  return (
    <>
      <Collapse
        in={
          selectedCovStateId === -1 &&
          selectedEntitlementId === -1 &&
          selectedArticleId !== 0 &&
          selectedSalaryId === -1
        }
        timeout={"auto"}
      >
        <div className={classes.formHeader}>
          <span className={classes.formTitle}>{formHeader}</span>
          {/* <span className={classes.remarks}>{state.remarksIds.length}</span> */}
        </div>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                className="tabPanel"
                onChange={handleTabsChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {(isAdmin || isAssistant) && (
                  <Tab label={t("Company")} value="1" />
                )}
                {(isAdmin || isAssistant) && (
                  <Tab label={t("Private")} value="2" />
                )}
                {isAdmin && <Tab label={t("Billing")} value="3" />}
                {isAdmin && (
                  <Tab label={t("Additional information")} value="4" />
                )}
                {isAdmin && parseInt(personId) > 0 && (
                  <Tab label={t("Salary")} value="5" />
                )}
                {isAdmin && parseInt(personId) > 0 && (
                  <Tab label={t("Vacation entitlement")} value="6" />
                )}
                {isAdmin && useTimeTrackModule && (
                  <Tab label={t("Time tracking")} value="7" />
                )}
                {(isAdmin || isAssistant) &&
                  useLoanModule &&
                  parseInt(personId) > 0 && (
                    <Tab label={t("Loan assets")} value="8" />
                  )}
                {(isAdmin || isAssistant) &&
                  useCovModule &&
                  parseInt(personId) > 0 && (
                    <Tab label={t("Covid state")} value="9" />
                  )}
                {(isAdmin || isAssistant) && parseInt(personId) > 0 && (
                  <Tab label={t("Remarks")} value="10" />
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <SomuraTextField
                    type="text"
                    name="personNumber"
                    label={t("Personnel number")}
                    required={usePersonNumbers}
                    value={state.personNumber}
                    maxlength="20"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraAutocomplete
                    name="salutationKey"
                    label={t("Salutation")}
                    options={[
                      { label: "", value: "0" },
                      { label: t("Mr"), value: "1" },
                      { label: t("Mrs"), value: "2" },
                    ]}
                    value={state.salutationKey}
                    onChange={handleChange}
                    // onChange={(values) => handleAutoCompleteChange(values)}

                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraTextField
                    type="text"
                    name="firstName"
                    label={t("First name")}
                    required
                    value={state.firstName}
                    maxlength="50"
                    onChange={handleChange}
                    className="required"
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraTextField
                    type="text"
                    name="lastName"
                    label={t("Last name")}
                    required
                    value={state.lastName}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    type="text"
                    name="initials"
                    label={t("Initials")}
                    required
                    value={state.initials}
                    maxlength="2"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraAutocomplete
                    name="departmentId"
                    label={t("Department")}
                    options={deptOptions}
                    // onChange={handleChange}
                    onChange={(values) => handleAutoCompleteChange(values)}
                    value={state.departmentId}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraTextField
                    label={t("Position")}
                    type="text"
                    name="position"
                    value={state.position}
                    maxlength="100"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    label={t("Entry date")}
                    type="date"
                    name="startDate"
                    required
                    value={state.startDate}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    label={t("Exit date")}
                    type="date"
                    name="endDate"
                    value={state.endDate}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    label={t("Reason")}
                    type="text"
                    name="endReason"
                    value={state.endReason}
                    maxlength="50"
                    onChange={handleChange}
                    disabled={
                      state.endDate === "0000-00-00" || state.endDate === ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <SomuraTextField
                    type="email"
                    name="emailCompany"
                    label={t("Mail address")}
                    required
                    value={state.emailCompany}
                    maxlength="255"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    disabled={!isAdmin}
                    name="accountLocked"
                    checked={state.accountLocked || false}
                    onChange={handleSwitchChange}
                    label={t("Account is locked")}
                  />
                </Grid>
                <Grid item xs={3} lg={3}>
                  <SomuraTextField
                    type="telephone"
                    name="telephoneCompany"
                    label={t("Phone number")}
                    value={state.telephoneCompany}
                    maxlength="30"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3} lg={3}>
                  <SomuraTextField
                    type="telephone"
                    name="mobileCompany"
                    label={t("Mobile number")}
                    value={state.mobileCompany}
                    maxlength="30"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    disabled={!isAdmin}
                    name="changePW"
                    checked={state.changePW || false}
                    onChange={handleSwitchChange}
                    label={t("User must assign a new password")}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid container spacing={1}>
                <Grid item xs={4} lg={4}>
                  <SomuraTextField
                    type="street"
                    name="street"
                    label={t("Street")}
                    value={state.street}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    type="street_number"
                    name="street_number"
                    label={t("Number")}
                    value={state.street_number}
                    maxlength="10"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    type="text"
                    name="country"
                    label={t("Country")}
                    value={state.country}
                    maxlength="3"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    type="text"
                    name="zip"
                    label={t("ZIP")}
                    value={state.zip}
                    maxlength="10"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="text"
                    name="city"
                    label={t("City")}
                    value={state.city}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="telephone"
                    name="telephone"
                    label={t("Phone number")}
                    value={state.telephone}
                    maxlength="30"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="telephone"
                    name="mobile"
                    label={t("Mobile number")}
                    value={state.mobile}
                    maxlength="30"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <SomuraTextField
                    type="email"
                    name="email"
                    label={t("Mail address")}
                    value={state.email}
                    maxlength="255"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={"subLine"}>{t("Birthday")}</div>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <SomuraTextField
                    onBlur={(e) => {
                      setState({ ...state, age: getAge(e.target.value) });
                    }}
                    label={t("Birthday")}
                    type="date"
                    name="birthday"
                    value={state.birthday}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={1} lg={1}>
                  <SomuraTextField
                    disabled={true}
                    type="text"
                    name="age"
                    label={t("Age")}
                    value={state.age}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <SomuraSwitchField
                    name="showBirthday"
                    checked={state.showBirthday}
                    onChange={handleSwitchChange}
                    label={t("Birthday visible")}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <SomuraSwitchField
                    name="showAge"
                    checked={state.showAge}
                    onChange={handleSwitchChange}
                    label={t("Age visible")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("Emergency contact")}</div>
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="text"
                    name="emergencyName"
                    label={t("Name")}
                    value={state.emergencyName}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="telephone"
                    name="emergencyNumber"
                    label={t("Phone number")}
                    value={state.emergencyNumber}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <SomuraTextField
                    type="text"
                    name="IBAN"
                    label="IBAN"
                    value={state.IBAN}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraTextField
                    type="text"
                    name="BIC"
                    label="BIC"
                    value={state.BIC}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraTextField
                    type="text"
                    name="taxIdNumber"
                    label={t("Tax id")}
                    value={state.taxIdNumber}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraTextField
                    type="text"
                    name="socialInsuranceNumber"
                    label={t("Social insurance number")}
                    value={state.socialInsuranceNumber}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <div className={"subLine"}>{t("Health insurance")}</div>
                </Grid>
                <Grid item xs={3}>
                  <div className={"subLine"}>{t("Internet grant")}</div>
                </Grid>
                <Grid item xs={6}>
                  <div className={"subLine"}>{t("Company vehicle")}</div>
                </Grid>
                <Grid item xs={3}>
                  <SomuraAutocomplete
                    name="healthInsuranceId"
                    label={t("Insurance company")}
                    value={state.healthInsuranceId}
                    options={companyOptions}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraTextField
                    type="number"
                    name="internetGrant"
                    label={t("Amount")}
                    adornmentText="€"
                    step="any"
                    value={state.internetGrant}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="companyBicycle"
                          margin="none"
                          checked={state.companyBicycle}
                          onChange={handleSwitchChange}
                          color="secondary"
                        />
                      }
                      label={t("Bicycle")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="companyCar"
                          margin="none"
                          checked={state.companyCar}
                          onChange={handleSwitchChange}
                          color="secondary"
                        />
                      }
                      label={t("Car")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    type="text"
                    name="companyCarNumber"
                    label={t("License number")}
                    value={state.companyCarNumber}
                    maxlength="20"
                    onChange={handleChange}
                    required={state.companyCar}
                    disabled={!state.companyCar}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={"subLine"}>
                    {t("Capital accumulation benefits")}
                  </div>
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraAutocomplete
                    name="insuranceId1"
                    label={t("Insurance carrier")}
                    value={state.insuranceId1}
                    options={companyOptions}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="text"
                    name="insuranceNumber1"
                    label={t("Insurance number")}
                    value={state.insuranceNumber1}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="date"
                    name="insuranceStart1"
                    label={t("Start")}
                    value={state.insuranceStart1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="number"
                    name="insuranceValue1"
                    label={t("Amount")}
                    adornmentText="€"
                    step="any"
                    value={state.insuranceValue1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="text"
                    name="insuranceIBAN1"
                    label="IBAN"
                    value={state.insuranceIBAN1}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} lg={3}>
                  <SomuraTextField
                    type="text"
                    name="insuranceBIC1"
                    label="BIC"
                    value={state.insuranceBIC1}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={"subLine"}>
                    {t("Employer-funded pension")}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <SomuraAutocomplete
                    name="insuranceId2"
                    label={t("Insurance carrier")}
                    value={state.insuranceId2}
                    options={companyOptions}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <SomuraTextField
                    type="text"
                    name="insuranceNumber2"
                    label={t("Insurance number")}
                    value={state.insuranceNumber2}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    type="number"
                    name="insuranceValue2"
                    label={t("Amount")}
                    adornmentText="€"
                    step="0.01"
                    value={state.insuranceValue2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraAutocomplete
                    name="insuranceFundingId2"
                    label={t("Funding by")}
                    options={fundingOptions}
                    value={state.insuranceFundingId2}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <SomuraTextField
                    label={t("Start")}
                    type="date"
                    name="insuranceStart2"
                    value={state.insuranceStart2}
                    onChange={handleChange}
                  />
                </Grid>
                {(state.insuranceId2 !== "0" ||
                  state.insuranceNumber2 !== "") && (
                  <>
                    <Grid item xs={3}>
                      <SomuraAutocomplete
                        name="insuranceId3"
                        label={t("Insurance carrier")}
                        value={state.insuranceId3}
                        options={companyOptions}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SomuraTextField
                        type="text"
                        name="insuranceNumber3"
                        label={t("Insurance number")}
                        value={state.insuranceNumber3}
                        maxlength="50"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraTextField
                        type="number"
                        name="insuranceValue3"
                        label={t("Amount")}
                        adornmentText="€"
                        step="0.01"
                        value={state.insuranceValue3}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraAutocomplete
                        name="insuranceFundingId3"
                        label={t("Funding by")}
                        options={fundingOptions}
                        value={state.insuranceFundingId3}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraTextField
                        label={t("Start")}
                        type="date"
                        name="insuranceStart3"
                        value={state.insuranceStart3}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}
                {(state.insuranceId3 !== "0" ||
                  state.insuranceNumber3 !== "") && (
                  <>
                    <Grid item xs={3}>
                      <SomuraAutocomplete
                        name="insuranceId4"
                        label={t("Insurance carrier")}
                        value={state.insuranceId4}
                        options={companyOptions}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SomuraTextField
                        type="text"
                        name="insuranceNumber4"
                        label={t("Insurance number")}
                        value={state.insuranceNumber4}
                        maxlength="50"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraTextField
                        type="number"
                        name="insuranceValue4"
                        label={t("Amount")}
                        adornmentText="€"
                        step="0.01"
                        value={state.insuranceValue4}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraAutocomplete
                        name="insuranceFundingId4"
                        label={t("Funding by")}
                        options={fundingOptions}
                        value={state.insuranceFundingId4}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraTextField
                        label={t("Start")}
                        type="date"
                        name="insuranceStart4"
                        value={state.insuranceStart4}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}
                {(state.insuranceId4 !== "0" ||
                  state.insuranceNumber4 !== "") && (
                  <>
                    <Grid item xs={3}>
                      <SomuraAutocomplete
                        name="insuranceId5"
                        label={t("Insurance carrier")}
                        value={state.insuranceId5}
                        options={companyOptions}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SomuraTextField
                        type="text"
                        name="insuranceNumber5"
                        label={t("Insurance number")}
                        value={state.insuranceNumber5}
                        maxlength="50"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraTextField
                        type="number"
                        name="insuranceValue5"
                        label={t("Amount")}
                        adornmentText="€"
                        step="0.01"
                        value={state.insuranceValue5}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraAutocomplete
                        name="insuranceFundingId5"
                        label={t("Funding by")}
                        options={fundingOptions}
                        value={state.insuranceFundingId5}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraTextField
                        label={t("Start")}
                        type="date"
                        name="insuranceStart5"
                        value={state.insuranceStart5}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value="4">
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <div className={"subLine"}>{t("Roll")}</div>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <SomuraSwitchField
                    name="isapprentice"
                    checked={state.isapprentice}
                    onChange={handleSwitchChange}
                    label={t("Trainee")}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <SomuraSwitchField
                    disabled={state.id === loggedUserId || isAssistant}
                    name="isadmin"
                    checked={state.isadmin}
                    onChange={handleSwitchChange}
                    label={t("Administrator")}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <SomuraSwitchField
                    disabled={state.id === loggedUserId || isAssistant}
                    name="isassistant"
                    checked={state.isassistant}
                    onChange={handleSwitchChange}
                    label={t("Assistant")}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={"subLine"}>{t("Permissions")}</div>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    name="showMonthCalendar"
                    checked={state.showMonthCalendar}
                    onChange={handleSwitchChange}
                    label={t("May open month calendar")}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    name="showUserStats"
                    checked={state.showUserStats}
                    onChange={handleSwitchChange}
                    label={t("May see vacation statistics")}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    name="showOwnDepartment"
                    checked={state.showOwnDepartment}
                    onChange={handleSwitchChange}
                    label={t("May only see own department")}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    name="messagesToAllUsers"
                    checked={state.messagesToAllUsers}
                    onChange={handleSwitchChange}
                    label={t("May send messages to all users")}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    name="deletePlannedAbsence"
                    checked={state.deletePlannedAbsence}
                    onChange={handleSwitchChange}
                    label={t("May delete own planned absence")}
                  />
                </Grid>
                {useTimeTrackModule && (
                  <Grid item xs={6} lg={4}>
                    <SomuraSwitchField
                      name="modifyTimes"
                      checked={state.modifyTimes}
                      onChange={handleSwitchChange}
                      label={t("May modify work times")}
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={12}>
                  <div className={"subLine"}>{t("Absence requests")}</div>
                </Grid>
                <Grid item xs={4}>
                  <SomuraAutocomplete
                    name="approverId"
                    label={t("Must be approved by")}
                    value={state.approverId}
                    options={approverOptions}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <SomuraSwitchField
                    name="isapprover"
                    checked={state.isapprover}
                    onChange={handleSwitchChange}
                    label={t("May approve")}
                  />
                </Grid>
                {state.isapprover && (
                  <Grid item xs={4}>
                    <SomuraAutocomplete
                      name="deputyId"
                      label={t("Deputy")}
                      value={state.deputyId}
                      options={approverOptions}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}

                <Grid item xs={3} lg={3}></Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="5">
              <SalaryTable />
            </TabPanel>
            <TabPanel value="6">
              <EntitlementsTable />
            </TabPanel>
            <TabPanel value="7">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SomuraTextField
                    label={t("Daily working time (minutes)")}
                    type="number"
                    name="dailyWorkingTime"
                    value={state.dailyWorkingTime}
                    min={0}
                    max={600}
                    step={5}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} lg={4}>
                  <SomuraSwitchField
                    disabled={!isAdmin}
                    name="noTimeTracking"
                    checked={state.noTimeTracking}
                    onChange={handleSwitchChange}
                    label={t("Time tracking is disabled")}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="8">
              <LoanAssetsTable />
            </TabPanel>
            <TabPanel value="9">
              <CovStatesTable />
            </TabPanel>
            <TabPanel value="10">
              <RemarksList
                primaryId={state.id}
                saveRemarkIds={saveRemarksIds}
              />
            </TabPanel>
          </TabContext>
        </Box>
        <div className="paperBottom">
          <BackButton
            onClick={() => {
              handleButtonClick("cancel");
            }}
          />
          <SaveButton onClick={handleSubmit} disabled={!valid} />
          {value === 4 && (
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              className={classes.fab}
              onClick={() => handleButtonClick("newSalary")}
            >
              <AddIcon />
              {t("New salary")}
            </Fab>
          )}
          {value === 5 && (
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              className={classes.fab}
              onClick={() => handleButtonClick("newEntitlement")}
            >
              <AddIcon />
              {t("New entitlement")}
            </Fab>
          )}
          {useLoanModule &&
            (value === (useTimeTrackModule ? 7 : 6) ||
              (isAssistant && value === (useTimeTrackModule ? 3 : 2))) && (
              <Fab
                variant="extended"
                color="primary"
                size="medium"
                className={classes.fab}
                onClick={() => handleButtonClick("newAsset")}
              >
                <AddIcon />
                {t("New loan asset")}
              </Fab>
            )}
          {useCovModule &&
            value ===
              (useLoanModule
                ? isAssistant
                  ? useTimeTrackModule
                    ? 4
                    : 3
                  : useTimeTrackModule
                  ? 8
                  : 7
                : isAssistant
                ? useTimeTrackModule
                  ? 3
                  : 2
                : useTimeTrackModule
                ? 7
                : 6) && (
              <Fab
                variant="extended"
                color="primary"
                size="medium"
                className={classes.fab}
                onClick={() => handleButtonClick("newCovState")}
              >
                <AddIcon />
                {t("New covid state")}
              </Fab>
            )}
          {value ===
            (useLoanModule
              ? isAssistant
                ? useTimeTrackModule
                  ? 5
                  : 4
                : useTimeTrackModule
                ? 9
                : 8
              : isAssistant
              ? useTimeTrackModule
                ? 4
                : 3
              : useTimeTrackModule
              ? 8
              : 7) && (
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              className={classes.fab}
              onClick={() => handleButtonClick("newRemark")}
            >
              <AddIcon />
              {t("New remark")}
            </Fab>
          )}
        </div>
      </Collapse>
      <Collapse in={selectedSalaryId >= 0} timeout={"auto"}>
        <SalaryForm />
      </Collapse>
      <Collapse in={selectedEntitlementId >= 0} timeout={"auto"}>
        <EntitlementForm />
      </Collapse>
      <Collapse in={selectedArticleId === 0} timeout={"auto"}>
        <LoanAssetForm />
      </Collapse>
      <Collapse in={selectedCovStateId >= 0} timeout={"auto"}>
        <CovStateForm />
      </Collapse>
      {openRemarkDialog && (
        <RemarkDialog
          // primaryId={state.id}
          close={() => setOpenRemarkDialog(false)}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonsForm);
