import {
  CHECK_MAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  USER_LOGOUT,
  SET_LOGGED_USER,
} from "actions/types";

import {
  checkMail,
  loginUser,
  setLoggedInUser,
  savePW,
  deletePW,
  createAdmin,
} from "../api";

const checkMailAddress = (loginState) => {
  return {
    type: CHECK_MAIL,
    payload: loginState,
  };
};

export const checkUsername = (mail) => {
  return async (dispatch) => {
    try {
      const loginStateJSON = await dispatch(checkMail(mail));
      await dispatch(checkMailAddress(loginStateJSON));
    } catch (err) {
      await dispatch(loginFailure(err));
      sessionStorage.removeItem("loggedUser");
    }
  };
};

const loginFailure = (err) => {
  return {
    type: LOGIN_FAILURE,
    payload: err,
  };
};

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const loginSuccess = (LoggedUser) => {
  return {
    type: LOGIN_SUCCESS,
    payload: LoggedUser,
  };
};

export const login = (credentials) => {
  return async (dispatch) => {
    await dispatch(loginRequest());
    try {
      const responseJSON = await dispatch(loginUser(credentials));
      if (responseJSON.jwt) {
        window.localStorage.setItem("jwt", responseJSON.jwt);
        document.cookie = "jwt=" + responseJSON.jwt;
        await dispatch(loginSuccess(responseJSON));
        localStorage.setItem("lastSomuraLogin", credentials.username);
        
      } else {
        await dispatch(loginFailure());
        sessionStorage.removeItem("loggedUser");
      }
    } catch (err) {
      await dispatch(loginFailure(err));
      sessionStorage.removeItem("loggedUser");
    }
  };
};

const userLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const clearStore = () => {
  return {
    type: USER_LOGOUT,
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    await dispatch(userLogout());
    await dispatch(clearStore());
  };
};

const LogUser = (id) => {
  return {
    type: SET_LOGGED_USER,
    payload: id,
  };
};

export const setLoggedUser = (id) => {
  return async (dispatch) => {
    await dispatch(loginRequest());
    try {
      const loggedUserJSONData = await dispatch(setLoggedInUser(id));
      await dispatch(LogUser(loggedUserJSONData));
      sessionStorage.setItem("loggedUser", JSON.stringify(loggedUserJSONData));
    } catch (err) {
      await dispatch(loginFailure(err));
      sessionStorage.removeItem("loggedUser");
    }
  };
};

export const createAdminAccount = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(createAdmin(values));
    } catch (err) {
      await dispatch(loginFailure(err));
      sessionStorage.removeItem("loggedUser");
    }
  };
};

export const savePassword = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(savePW(values));
    } catch (err) {
      await dispatch(loginFailure(err));
      sessionStorage.removeItem("loggedUser");
    }
  };
};

export const clearPassword = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(deletePW(values));
    } catch (err) {
      await dispatch(loginFailure(err));
      sessionStorage.removeItem("loggedUser");
    }
  };
};
