import React from 'react';
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  example: {
    gridRow: "3",
    display: "inline-flex",
    width: "10rem",
    height: "1.5rem",
    margin: "auto 0",
    borderRadius: "0.5rem",
    backgroundColor: (props) => `${props.componentProps.backgroundColor}`,
    color: (props) => `${props.componentProps.fontColor}`,
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem",
  },
  typeName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "1rem",
    color: (props) => `${props.componentProps.tooltipFontColor}`
  },
}));

function ToolTipExample({ component, ...props }) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <div className={classes.example}>
      <div className={classes.typeName}>{t(component)}</div>
    </div>
  );
}

export default ToolTipExample