import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPersonalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getPersonalSetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
  bold: {
    marginTop: "0.5rem",
    fontStyle: "normal",
    color: theme.palette.text.main,
  },
  normal: {
    marginTop: "0.5rem",
    fontStyle: "italic",
    color: theme.palette.text.disabled,
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalSettings: (values) => dispatch(setPersonalSettings(values)),
  };
};

const RemindersForm = ({ setPersonalSettings, loggedUserId, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "reminders";
  const emptyState = {
    showOnlyFuture: true,
    requestReminder: false,
    numDaysBeforeAbsence: 0,
    timesDurationOfAbsence: 0,
  };
  const [state, setState] = useState(emptyState);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "numDaysBeforeAbsence") {
      setState({ ...state, [name]: value, timesDurationOfAbsence: 0 });
      setPersonalSettings({
        userId: loggedUserId,
        component: component,
        key: name,
        value: value,
      });
      setPersonalSettings({
        userId: loggedUserId,
        component: component,
        key: "timesDurationOfAbsence",
        value: 0,
      });
    }
    if (name === "timesDurationOfAbsence") {
      setState({ ...state, [name]: value, numDaysBeforeAbsence: 0 });
      setPersonalSettings({
        userId: loggedUserId,
        component: component,
        key: name,
        value: value,
      });
      setPersonalSettings({
        userId: loggedUserId,
        component: component,
        key: "numDaysBeforeAbsence",
        value: 0,
      });
    }
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked ? 1 : 0;
    setState({ ...state, [name]: value });
    setPersonalSettings({
      userId: loggedUserId,
      component: component,
      key: name,
      value: value,
    });
  };

  useEffect(() => {
    setState({
      showOnlyFuture: parseInt(getPersonalSetting(component, "showOnlyFuture")),
      requestReminder: parseInt(
        getPersonalSetting(component, "requestReminder")
      ),
      numDaysBeforeAbsence: parseInt(
        getPersonalSetting(component, "numDaysBeforeAbsence")
      ),
      timesDurationOfAbsence: parseInt(
        getPersonalSetting(component, "timesDurationOfAbsence")
      ),
    });
  }, []);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Display")}</div>
            <Grid container spacing={2}>
              <Grid item>
                <SomuraSwitchField
                  name="showOnlyFuture"
                  checked={state.showOnlyFuture === 1}
                  onChange={handleSwitchChange}
                  label={t("Show only future reminders")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Planned absences")}</div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SomuraSwitchField
                  name="requestReminder"
                  checked={state.requestReminder === 1}
                  onChange={handleSwitchChange}
                  label={t("Remind me to request absence")}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  inputProps={{ min: "0", max: "365", step: "1" }}
                  value={state.numDaysBeforeAbsence}
                  onChange={handleChange}
                  disabled={!state.requestReminder}
                  name="numDaysBeforeAbsence"
                  // label={t("Number of days")}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <div
                  className={
                    state.requestReminder ? classes.bold : classes.normal
                  }
                >
                  {t("Days before absence starts")}
                </div>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  value={state.timesDurationOfAbsence}
                  onChange={handleChange}
                  disabled={!state.requestReminder}
                  name="timesDurationOfAbsence"
                  // label={t("Number of days")}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <div
                  className={
                    state.requestReminder ? classes.bold : classes.normal
                  }
                >
                  {t("times duration of absence")}
                </div>
              </Grid>
              <Grid item xs={1}>
                <InfoOutlinedIcon />
              </Grid>
              <Grid item xs={11}>
                <span>
                  <i>{t("Changes affect only new planned absences.")}</i>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindersForm);
