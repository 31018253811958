import React from "react";
import { useTranslation } from "react-i18next";
import { icons } from "Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function IconSelect(props) {
  const { t } = useTranslation();

  return (
    <Select
      name={props.name}
      label={t("Icon")}
      value={props.value}
      onChange={props.onChange}
      // options={iconOptions}
      disabled={props.disabled}
      variant="outlined"
      size="small"
      fullWidth
      style={{ height: "2.5rem" }}
    >
      {icons.map((i) => (
        <MenuItem key={i.id} value={parseInt(i.id)}>
          <svg
            className={i.name}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill={props.color}
            viewBox="0 0 22 22"
            aria-labelledby="title"
          >
            <title id="title">{i.name}</title>
            <path d={i.path} />
          </svg>
        </MenuItem>
      ))}
    </Select>
  );
}

export default IconSelect
