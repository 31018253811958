import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchCompany,
  fetchGlobalSettings,
} from "redux/index";
import { useAppContext } from "Components/context";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/de";
import Username from "./Username";
import WaitForPermission from "./WaitForPermission";
import Password from "./Password";
import NewPassword from "./NewPassword";
import WaitForPassword from "./WaitForPassword";
import FirstPassword from "./FirstPassword";
import AdminExists from "./AdminExists";
import NoAdmin from "./NoAdmin";

import LanguageSelection from "Components/LanguageSelection";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    backgroundColor: theme.palette.background.default,
    height: "100vh",
  },
  loginPage: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
  },
  person: {
    marginTop: "1rem",
    fontSize: "1rem",
  },
  content: {
    height: "100vh",
    width: "100vw",
    backgroundImage: "url(/images/bg.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  language: {
    textAlign: "right",
    cursor: "pointer",
  },
  img: {
    width: "50%",
    display: "flex",
    margin: "auto",
    marginBottom: "2rem",
  },
  loginFrame: {
    width: "30rem",
    margin: "auto 30%",
    padding: "2rem",
    borderRadius: "10px",
    backgroundColor: "rgba(150, 150, 150, 0.8)",
    [theme.breakpoints.down('xl')]: {
      margin: "auto 25%",
    },
  },
  firstLoginFrame: {
    width: "75%",
    margin: "auto 7.5%",
    padding: "2rem",
    borderRadius: "10px",
    backgroundColor: "rgba(150, 150, 150, 0.8)",
    [theme.breakpoints.down('xl')]: {
      margin: "auto 5%",
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    dbInit: state.general.DatabaseInitialized,
    loginState: state.loggeduser.LoginState,
    loggedUserId: state.loggeduser.LoggedUser.id,
    globalSettings: state.settings.GlobalSettings,
    settings: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompany: () => dispatch(fetchCompany()),
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
  };
};

const SignIn = ({
  dbInit,
  loginState,
  loggedUserId,
  globalSettings,
  settings,
  ...props
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { userHasAuthenticated } = useAppContext();

  const [openLS, setOpenLS] = useState(false);

  const multilingual = false;

  useEffect(() => {
    userHasAuthenticated(loginState === "999");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState]);

  useEffect(() => {
    props.fetchGlobalSettings();
    props.fetchCompany();
    i18n.changeLanguage("de-DE");
    moment.locale("de");
    localStorage.setItem("lang", "de-DE");
    localStorage.setItem("langShort", "DEU");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.loginPage}>
          <div className={classes.content}>
            <div
              className={
                loginState === "5"
                  ? classes.firstLoginFrame
                  : classes.loginFrame
              }
            >
              {multilingual && (
                <div
                  className={classes.language}
                  onClick={() => {
                    setOpenLS(true);
                  }}
                >
                  {/* {languageShort} */}
                </div>
              )}
              <img
                className={classes.img}
                alt="Logo"
                src={"/images/Somura-logo-default.svg"}
              />
              {loginState === "0" && <Username />}
              {loginState === "1" && <WaitForPermission />}
              {loginState === "2" && <Password />}
              {loginState === "3" && <NewPassword />}
              {loginState === "4" && <WaitForPassword />}
              {loginState === "5" && <FirstPassword />}
              {loginState === "6" && <AdminExists />}
              {loginState === "7" && <NoAdmin />}
            </div>
          </div>
        </div>
      </div>
      {openLS && (
        <LanguageSelection
          close={() => {
            setOpenLS(false);
          }}
        />
      )}
    </>
  );
  // );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
