import React, { useEffect, useState } from "react";
import {
  updateRemarkableDay,
  setRemarkableDayId,
  fetchRemarkableDays,
} from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SomuraTextField from "Components/SomuraTextField";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import SomuraSwitchField from "Components/SomuraSwitchField";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { easterDate, fourthAdventDate } from "Components/Utilities";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  dayDescription: {
    padding: "10px",
    fontSize: "18px",
  },
  days: {
    height: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nextOccurence: {
    textAlign: "center",
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
  },
}));

const mapStateToProps = (state) => {
  const remarkableDayId = state.specialdays.remarkableDayId;
  const remarkableDay = state.specialdays.RemarkableDays.find(
    (RD) => RD.id === remarkableDayId
  );

  const formDisabled = !state.general.EditMode;
  return {
    remarkableDay,
    formDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRemarkableDay: (values) => dispatch(updateRemarkableDay(values)),
    setRemarkableDayId: (id) => dispatch(setRemarkableDayId(id)),
    fetchRemarkableDays: () => dispatch(fetchRemarkableDays()),
  };
};

const RemarkableDaysForm = ({
  remarkableDay,
  formDisabled,
  setRemarkableDayId,
  fetchRemarkableDays,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    dayName: "",
    dayFactor: 0.0,
    isSpecialDay: false,
    isOffDay: false,
    isHalfDay: false,
    isMothersDay: false,
    useThisDay: false,
    alwaysUseThisDay: false,
    hasFixedDate: false,
    fixedDay: 0,
    fixedMonth: 0,
    referenceDay: "",
    referenceNumber: 0,
    numDays: 0,
    beforeAfter: "b",
    nextDate: "",
  };
  const [state, setState] = useState(emptyState);
  const selectedDay = remarkableDay;
  const formHeader = selectedDay
    ? t("Special day") + ": " + selectedDay.dayName
    : t("New special day");

  const handleSubmit = () => {
    props.updateRemarkableDay(state);
    fetchRemarkableDays();
    handleClose();
  };

  const handleClose = () => {
    setRemarkableDayId(-1);
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "edit":
        handleClose();
        break;
      case "cancel":
        handleClose();
        break;
      default:
        handleClose();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "numDays") {
      setState({
        ...state,
        [name]: value,
        referenceNumber: state.beforeAfter === "b" ? value * -1 : value,
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const handleAutoCompleteChange = (params) => {
    const name = params.name;
    const value = params.value;
    if (name === "beforeAfter") {
      setState({
        ...state,
        [name]: value,
        referenceNumber: state.beforeAfter === "b" ? value * -1 : value,
      });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
  };

  const nextOccurence = () => {
    var currentYear = moment().format("YYYY");
    var date
    if (state.hasFixedDate) {
      date = moment(
        currentYear + "-" + state.fixedMonth + "-" + state.fixedDay
      );
    } else if (state.isMothersDay) {
      date = moment(state.nextDate);
    } else {
      switch (state.referenceDay) {
        case "easter":
          date = easterDate(currentYear);
          date.add(state.referenceNumber, "d");
          if (date < moment()) {
            date = easterDate(parseInt(currentYear) + 1);
            date.add(state.referenceNumber, "d");
          }
          break;
        case "fourthadvent":
          date = fourthAdventDate(currentYear);
          date.add(state.referenceNumber, "d");
          if (date < moment()) {
            date = fourthAdventDate(parseInt(currentYear) + 1);
            date.add(state.referenceNumber, "d");
          }
          break;
        default:
          date = moment();
      }
    }
    if (date < moment()) {
      date.add(1, "y");
    }
    return (
      <div className={classes.nextOccurence}>
        {date.isValid() ? date.format("dddd, D. MMMM YYYY") : t("Invalid date")}
      </div>
    );
  };

  useEffect(() => {
    if (remarkableDay) {
      setState({
        ...remarkableDay,
        fixedDay: parseInt(remarkableDay.fixedDay),
        numDays: Math.abs(remarkableDay.referenceNumber),
        beforeAfter: remarkableDay.referenceNumber < 0 ? "b" : "a",
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remarkableDay]);

  return (
    <div>
      <Paper square elevation={8}>
        <div className="formHeader">{formHeader}</div>
        <div className={classes.thisForm}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <SomuraSwitchField
                    disabled={
                      selectedDay?.alwaysUseThisDay || state.isMothersDay
                    }
                    name="hasFixedDate"
                    checked={state.hasFixedDate}
                    onChange={handleSwitchChange}
                    label={t("Fixed date")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SomuraSwitchField
                    name="isMothersDay"
                    checked={state.isMothersDay}
                    onChange={handleSwitchChange}
                    label={t("Mother's Day")}
                  />
                </Grid>
                {state.hasFixedDate && !state.isMothersDay && (
                  <>
                    <Grid item xs={3}>
                      <SomuraTextField
                        type="number"
                        value={state.fixedDay}
                        onChange={handleChange}
                        min="1"
                        max="31"
                        name="fixedDay"
                        label={t("Day")}
                        variant="outlined"
                        size="small"
                        disabled={selectedDay?.alwaysUseThisDay}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SomuraTextField
                        type="number"
                        value={state.fixedMonth}
                        onChange={handleChange}
                        min="1"
                        max="12"
                        name="fixedMonth"
                        label={t("Month")}
                        variant="outlined"
                        size="small"
                        disabled={selectedDay?.alwaysUseThisDay}
                      />
                    </Grid>
                  </>
                )}
                {!state.hasFixedDate && !state.isMothersDay && (
                  <>
                    <Grid item xs={2}>
                      <SomuraTextField
                        type="number"
                        value={state.numDays}
                        onChange={handleChange}
                        min="1"
                        max="364"
                        name="numDays"
                        // label={t("Number")}
                        variant="outlined"
                        size="small"
                        disabled={selectedDay?.alwaysUseThisDay}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <div className={classes.days}>{t("Tage")}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <SomuraAutocomplete
                        name="beforeAfter"
                        // label={t("Day")}
                        options={[
                          { label: t("before"), value: "b" },
                          { label: t("after"), value: "a" },
                        ]}
                        onChange={(values) => handleAutoCompleteChange(values)}
                        value={state.beforeAfter}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={selectedDay?.alwaysUseThisDay}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SomuraAutocomplete
                        name="referenceDay"
                        // label={t("Day")}
                        options={[
                          { label: t("Easter Sunday"), value: "easter" },
                          { label: t("Fourth Advent"), value: "fourthadvent" },
                        ]}
                        onChange={(values) => handleAutoCompleteChange(values)}
                        value={state.referenceDay}
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        disabled={selectedDay?.alwaysUseThisDay}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <SomuraSwitchField
                disabled={selectedDay && selectedDay.alwaysUseThisDay}
                name="useThisDay"
                checked={state.useThisDay}
                onChange={handleSwitchChange}
                label={t("Show in calendar")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={"subLine"}>{t("Next occurence")}</div>
            </Grid>
            <Grid item xs={12}>
              {nextOccurence()}
            </Grid>
          </Grid>
          <div className="formBottom">
            <CancelButton
              onClick={() => {
                handleButtonClick("cancel");
              }}
            />
            <SaveButton onClick={handleSubmit} />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemarkableDaysForm);
