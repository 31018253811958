import React, { useState, useEffect, useCallback } from "react";
import { setCovStateId, addCovState, updateCovState } from "redux/index";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";
import SomuraAutocomplete from "Components/SomuraAutocomplete";
import SomuraTextField from "Components/SomuraTextField";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";

const useStyles = makeStyles(() => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  const personId = state.persons.personId;
  const person = state.persons.Person
  const selectedCovStateId = state.selections.selectedCovStateId;
  const covstate = state.persons.Person.covstates?.find(
    (CS) => CS.id === selectedCovStateId
  );
  const persons = state.persons.PersonsMin.filter(
    (P) => P.id !== personId
  );
  return { selectedCovStateId, personId, person, covstate, persons };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCovStateId: (id) => dispatch(setCovStateId(id)),
    addCovState: (values) => dispatch(addCovState(values)),
    updateCovState: (values) => dispatch(updateCovState(values)),
  };
};

function CovStateForm({
  selectedCovStateId,
  personId,
  person,
  covstate,
  setCovStateId,
  addCovState,
  updateCovState,
  persons,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const emptyState = {
    id: "",
    userId: personId,
    state: "",
    proofDate: "0000-00-00",
    proofSubmitted: moment().format("YYYY-MM-DD"),
    controlledById: "0",
    testDate: "",
    validUntil: "0000-00-00",
  };
  const [state, setState] = useState(emptyState);
  const [valid, setValid] = useState(false);

  const personsOptions = persons.map((P) => ({
    label: P.listName,
    value: P.id,
  }));
  personsOptions.push({label: "", value: "0"})

  const CovStateSchema = Yup.object().shape({
    state: Yup.number().required(t("Required")),
    proofDate: Yup.date().required(t("Required")),
    proofSubmitted: Yup.date().required(t("Required")),
    controlledById: Yup.number().min(1).required(t("Required")),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await CovStateSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formHeader =
    person?.listName +
    ": " +
    (covstate
      ? t("Covid state as of") +
        " " +
        moment(covstate.proofSubmitted).format("L")
      : t("New covid state"));

  const handleSubmit = () => {
    if (state.id === "") {
      addCovState(state);
    } else {
      updateCovState(state);
    }
    setState(emptyState);
    setCovStateId(-1);
  };

  const handleCancel = () => {
    setCovStateId(-1);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

    const handleAutoCompleteChange = (params) => {
      const name = params.name;
      const value = params.value;
      setState({ ...state, [name]: value });
    };

  useEffect(() => {
    if (selectedCovStateId > 0) {
      setState({
        ...covstate,
      });
    } else {
      setState({
        ...emptyState,
        userId: personId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCovStateId, covstate, personId]);

  return (
    <div>
      <div className="formHeader">{formHeader}</div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <SomuraAutocomplete
              name="state"
              label={t("State")}
              options={[
                { label: t("Unknown"), value: "0" },
                { label: t("Vaccinated"), value: "1" },
                { label: t("Recovered"), value: "2" },
                { label: t("Tested"), value: "3" },
              ]}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.state}
              variant="outlined"
              size="small"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={3}>
            <SomuraTextField
              type="date"
              name="proofDate"
              label={t("State as of")}
              value={state.proofDate}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={7}></Grid>
          <Grid item xs={3}>
            <SomuraTextField
              type="date"
              name="proofSubmitted"
              label={t("Proved on")}
              value={state.proofSubmitted}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <SomuraAutocomplete
              name="controlledById"
              label={t("Controlled by")}
              options={personsOptions}
              onChange={(values) => handleAutoCompleteChange(values)}
              value={state.controlledById}
              variant="outlined"
              size="small"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={3}>
            <SomuraTextField
              type="date"
              name="validUntil"
              label={t("Valid until")}
              value={state.validUntil}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <SomuraTextField
              type="date"
              name="testDate"
              label={t("Test date")}
              value={state.testDate}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <div className="formBottom">
          <CancelButton onClick={handleCancel} />
          <SaveButton disabled={!valid} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CovStateForm);
