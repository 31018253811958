import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import { fetchOffDayTypes } from "redux/index";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ScreenTitle from "Components/ScreenTitle";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import DashboardForm from "./DashboardForm";
import RemindersForm from "./RemindersForm";
import AllCalendarsForm from "./AllCalendarsForm";
import MonthCalendarForm from "./MonthCalendarForm";
import YearCalendarForm from "./YearCalendarForm";
import DisplayForm from "./DisplayForm";
import TimeTrackForm from "./TimeTrackForm";
import GeneralForm from "./GeneralForm";

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggeduser.LoggedUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffDayTypes: () => dispatch(fetchOffDayTypes()),
  };
};

const PersonalSettings = ({ loggedUser, fetchOffDayTypes }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  useEffect(() => {
    fetchOffDayTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content">
      <Paper elevation={8}>
        <ScreenTitle
          title={t("Settings")}
          class="settings"
          icon={<SettingsIcon style={{ width: "1.8em", height: "1.8em" }} />}
        />
        <Tabs
          className="tabPanel"
          value={value}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={handleTabsChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t("General")} value={0} />
          <Tab label={"Dashboard"} value={1} />
          <Tab label={t("Reminders")} value={2} />
          <Tab label={t("All calendars")} value={3} />
          <Tab label={t("Month calendar")} value={4} />
          <Tab label={t("Year calendar")} value={5} />
          <Tab label={t("Presentation")} value={6} />
          {!loggedUser.noTimeTracking && (
            <Tab label={t("Time tracking")} value={7} />
          )}
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <GeneralForm />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <DashboardForm />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <RemindersForm />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <AllCalendarsForm />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <MonthCalendarForm />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <YearCalendarForm />
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          <DisplayForm />
        </TabPanel>
        <TabPanel value={value} index={7} dir={theme.direction}>
          <TimeTrackForm />
        </TabPanel>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSettings);
