import React, { useState } from "react";
import { connect } from "react-redux";
import {
  setSelectedModelId,
} from "redux/index";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import ModelDialog from "./ModelDialog"

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
  },
  listPaper: {
    width: "100%",
    padding: "0 4px !important",
    // margin: "0 2px 6px 2px",
    backgroundColor: theme.palette.background.messageListItem,
    "&:hover": {
      backgroundColor: theme.palette.background.messageListHighlight,
      cursor: "pointer",
    },
    borderRadius: "4px",
  },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
}));

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedId: (id) => dispatch(setSelectedModelId(id)),
  };
};

const ModelItem = ({setSelectedId, ...props}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog ] = useState(false)

  const handleClick = () => {
    setSelectedId(props.id)
    setOpenDialog(true)
  };

  return (
    <>
      <Paper
        square
        elevation={8}
        className={classes.listPaper}
      >
        <div className={classes.root} onClick={handleClick}>
          <div className={classes.text}>{props.name}</div>
        </div>
      </Paper>
      {openDialog && (
        <ModelDialog
          close={() => setOpenDialog(false)}
        />
      )}
    </>
  );
};

export default connect(null, mapDispatchToProps)(ModelItem);
