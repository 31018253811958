import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as Yup from "yup";
import { saveReminder, deleteReminder, fetchReminderTimes } from "redux/index";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import makeStyles from "@mui/styles/makeStyles";
import SomuraTextField from "Components/SomuraTextField";
import SomuraTextFieldLargeMUI from "Components/SomuraTextFieldLargeMUI";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraSelectField from "Components/SomuraSelectField";

import PreviousButton from "Components/Buttons/PreviousButton";
import NextButton from "Components/Buttons/NextButton";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";

import DialogTitle from "Calendars/Components/DialogTitle";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  root: {
    minWidth: 700,
    maxWidth: 700,
    margin: "auto",
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
  cancelButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-selectionform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    reminders: state.reminders.displayReminder,
    reminderDate: state.reminders.showReminderDate,
    displayDate: state.reminders.displayDate,
    reminderTimes: state.remindertimes.ReminderTimes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReminder: (values) => dispatch(saveReminder(values)),
    deleteReminder: (values) => dispatch(deleteReminder(values)),
    fetchReminderTimes: () => dispatch(fetchReminderTimes()),
  };
};

const RemindersDialog = ({
  loggedUserId,
  reminders,
  reminderDate,
  displayDate,
  saveReminder,
  deleteReminder,
  fetchReminderTimes,
  reminderTimes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const reminderSchema = Yup.object().shape({
    title: Yup.string().required(t("Required")),
    startDate: Yup.date().required(t("Required")),
  });

  // const displayDate =
  //   reminderDate === "x"
  //     ? reminders.length > 0
  //       ? moment(reminders[0].startDateTime).format("YYYY-MM-DD")
  //       : moment().format("YYYY-MM-DD")
  //     : reminderDate;

  const emptyState = {
    id: 0,
    userId: loggedUserId,
    description: "",
    text: "",
    startDate: displayDate,
    startTime: "",
    endDate: "",
    endTime: "",
    enableAlert: false,
    alertTimeId: 4,
    alertDate: "",
    alertTime: "",
    isWholeDay: false,
  };
  const [state, setState] = useState(emptyState);
  const [selReminder, setSelReminder] = useState(0);
  const [valid, setValid] = useState(false);
  const reminder = reminders[selReminder];

  const reminderTimeOptions = reminderTimes.map((RT) => ({
    label: RT.reminderTimeText,
    value: RT.id,
  }));

  const handlePrev = () => {
    if (selReminder === 0) {
      setSelReminder(reminders.length - 1);
    } else {
      setSelReminder(selReminder - 1);
    }
  };

  const handleNext = () => {
    if (selReminder === reminders.length - 1) {
      setSelReminder(0);
    } else {
      setSelReminder(selReminder + 1);
    }
  };

  const handleClose = () => {
    store.dispatch({ type: "OPEN_REMINDER_DIALOG", payload: null });
  };

  const handleSubmit = () => {
    saveReminder({
      ...state,
      startDateTime: state.startDate + " " + state.startTime,
      endDateTime: state.isWholeDay
        ? state.startDate + " 23:59:59"
        : moment(state.endDate).isValid()
        ? state.endDate + " " + state.endTime
        : state.startDate + " " + state.startTime,
      alertDateTime: state.enableAlert
        ? moment(state.alertDate).isValid()
          ? state.alertDate + " " + state.alertTime
          : state.startDate + " " + state.startTime
        : state.startDate + " " + state.startTime,
    });
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target ? event.target.name : event.name;
    var value = event.target ? event.target.value : event.value;
    setState({ ...state, [name]: value });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked ? 1 : 0;
    setState({ ...state, [name]: value });
  };

  const handleDelete = () => {
    deleteReminder({ id: state.id });
    handleClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(async () => {
    setValid(await reminderSchema.isValid(state));
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (reminderDate === "x") {
      setState({
        ...reminder,
        startDate: moment(reminder.startDateTime).format("YYYY-MM-DD"),
        startTime: moment(reminder.startDateTime).format("HH:mm"),
        endDate: moment(reminder.endDateTime).format("YYYY-MM-DD"),
        endTime: moment(reminder.endDateTime).format("HH:mm"),
        alertDate: moment(reminder.alertDateTime).format("YYYY-MM-DD"),
        alertTime: moment(reminder.alertDateTime).format("HH:mm"),
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminder, reminderDate]);

  useEffect(() => {
    fetchReminderTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Dialog
      className={classes.root}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-selectionform-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-selectionform-title"
        dialogTitle={
          (reminderDate !== "x"
            ? t("New reminder")
            : reminders.length === 1
            ? t("Reminder")
            : reminders.length + " " + t("Reminders")) +
          " " +
          t("for") +
          " " +
          moment(displayDate).format("dddd, L")
        }
        closeForm={handleClose}
      />
      <DialogContent className="dialogContent" style={{ padding: "0px" }}>
        <div className={classes.thisForm}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("Target date")}</div>
                </Grid>
                <Grid item xs={8}>
                  <SomuraTextField
                    type="text"
                    name="title"
                    label={t("Title")}
                    value={state.title}
                    maxlength="50"
                    onChange={handleChange}
                  />
                </Grid>
                {reminders.length > 1 ? (
                  <Grid item xs={2}>
                    <PreviousButton onClick={handlePrev} />
                    <NextButton onClick={handleNext} />
                  </Grid>
                ) : (
                  <Grid item xs={2}></Grid>
                )}
                <Grid item xs={12}>
                  <SomuraSwitchField
                    name="isWholeDay"
                    label={t("All-day")}
                    checked={state.isWholeDay}
                    onChange={handleSwitchChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.label}>{t("Start")}:</div>
                </Grid>
                <Grid item xs={6}>
                  <SomuraTextField
                    type="date"
                    name="startDate"
                    label={"Date"}
                    value={state.startDate}
                    onChange={handleChange}
                    min={moment().format("YYYY-MM-DD")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SomuraTextField
                    type="time"
                    name="startTime"
                    label={"Time"}
                    value={state.startTime}
                    onChange={handleChange}
                    disabled={state.isWholeDay}
                  />
                </Grid>

                <Grid item xs={2}>
                  <div className={classes.label}>{t("End")}:</div>
                </Grid>
                <Grid item xs={6}>
                  <SomuraTextField
                    type="date"
                    name="endDate"
                    label={"Date"}
                    value={state.endDate}
                    onChange={handleChange}
                    min={state.startDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SomuraTextField
                    type="time"
                    name="endTime"
                    label={"Time"}
                    value={state.endTime}
                    onChange={handleChange}
                    disabled={state.isWholeDay}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SomuraTextFieldLargeMUI
                    type="text"
                    name="description"
                    label={"Description"}
                    rows="4"
                    value={state.description}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={"subLine"}>{t("Reminder")}</div>
                </Grid>
                <Grid item xs={12}>
                  <SomuraSwitchField
                    name="enableAlert"
                    label={t("Remind me")}
                    checked={state.enableAlert}
                    onChange={handleSwitchChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SomuraSelectField
                    name="alertTimeId"
                    label={t("Timeframe")}
                    options={reminderTimeOptions}
                    style={{ marginTop: "4px" }}
                    disabled={!state.enableAlert}
                    value={state.alertTimeId}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={7}>
                  <SomuraTextField
                    type="date"
                    name="alertDate"
                    label={"Date"}
                    value={state.alertDate}
                    onChange={handleChange}
                    min={moment().format("YYYY-MM-DD")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <SomuraTextField
                    type="time"
                    name="alertTime"
                    label={"Time"}
                    value={state.alertTime}
                    onChange={handleChange}
                    disabled={state.isWholeDay}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className="dialogActions">
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <DeleteButton
          disabled={reminder ? false : true}
          onClick={handleDelete}
        />
        <SaveButton onClick={handleSubmit} disabled={!valid} />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindersDialog);


            