import React, { useState } from "react";
import { connect } from "react-redux";
import { setGlobalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getGlobalSetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
  recipientsWrapper: {
    marginBottom: "4px",
    minHeight: "24rem",
  },
  settingsLine: {
    height: "1.5rem",
    marginLeft: "0.4rem",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  settingsLineItem: {
    display: "flex",
    alignItems: "center",
  },
  settingsCheckbox: {
    display: "flex",
    justifyContent: "center",
  },
  settingsHeadline: {
    fontWeight: "bold",
    height: "1.5rem",
    paddingTop: "0.5rem",
    marginLeft: "0.4rem",
  },
}));

const mapStateToProps = (state) => {
  return {
    globalSettings: state.settings.GlobalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalSettings: (values) => dispatch(setGlobalSettings(values)),
  };
};

const MessagesForm = ({ globalSettings, setGlobalSettings }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "messages";
  const [state, setState] = useState({
    disableMessages:
      parseInt(getGlobalSetting(component, "disableMessages")) === 1,
  });
  const [recipientsState, setRecipientsState] = useState(
    globalSettings?.find((GS) => GS.component === "messagerecipients")?.settings
  );

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
    setGlobalSettings({
      component: component,
      key: event.target.name,
      value: value ? "1" : "0",
    });
  };

  const RecipientsLine = (setting) => {
    const dataSet = setting.setting;
    const [line, setLine] = useState(dataSet[1]);

    const handleItemChange = (event, index) => {
      const value = event.target.checked ? 1 : 0;
      const newValue =
        line.substr(0, index) + value + line.substr(index + 1, line.length);
      setLine(newValue);
      var copiedState = { ...recipientsState };
      copiedState[dataSet[0]] = newValue;
      setRecipientsState(copiedState);
      setGlobalSettings({
        component: "messagerecipients",
        key: dataSet[0],
        value: newValue,
      });
    };

    return (
      <Grid container className={classes.settingsLine} spacing={3}>
        <Grid item className={classes.settingsLineItem} xs={2} lg={2}>
          {t(dataSet[0])}
        </Grid>
        <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
          <Checkbox
            name="sendToAdmin"
            margin="none"
            checked={parseInt(line.substr(0, 1)) === 1}
            onChange={(event) => handleItemChange(event, 0)}
            color="secondary"
          />
        </Grid>
        <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
          <Checkbox
            name="sendToAssistant"
            margin="none"
            checked={parseInt(line.substr(1, 1)) === 1}
            onChange={(event) => handleItemChange(event, 1)}
            color="secondary"
          />
        </Grid>
        <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
          <Checkbox
            name="sendToApprover"
            margin="none"
            checked={parseInt(line.substr(2, 1)) === 1}
            onChange={(event) => handleItemChange(event, 2)}
            color="secondary"
          />
        </Grid>
        <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
          <Checkbox
            name="sendToDeputy"
            margin="none"
            checked={parseInt(line.substr(3, 1)) === 1}
            onChange={(event) => handleItemChange(event, 3)}
            color="secondary"
          />
        </Grid>
        <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
          <Checkbox
            name="sendToUser"
            margin="none"
            checked={parseInt(line.substr(4, 1)) === 1}
            onChange={(event) => handleItemChange(event, 4)}
            color="secondary"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SomuraSwitchField
              name="disableMessages"
              checked={state.disableMessages}
              onChange={handleSwitchChange}
              label={t("Disable internal message-system")}
            />
          </Grid>
        </Grid>
        <div className={"subLine"}>{t("Message recipients")}</div>
        <div className={classes.recipientsWrapper}>
          <Grid container className={classes.settingsHeadline} spacing={3}>
            <Grid item className={classes.settingsLineItem} xs={2} lg={2}>
              {t("Request state")}
            </Grid>
            <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
              {t("Administrator")}
            </Grid>
            <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
              {t("Assistants")}
            </Grid>
            <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
              {t("Approver")}
            </Grid>
            <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
              {t("Deputy")}
            </Grid>
            <Grid item className={classes.settingsCheckbox} xs={2} lg={1}>
              {t("User")}
            </Grid>
          </Grid>
          {Object.entries(recipientsState)?.map(([key, value]) => {
            return <RecipientsLine key={key} setting={[key, value]} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesForm);
