import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const useStyles = makeStyles((theme) => ({
  messageContent: {
    position: "relative",
    height: "10rem",
    backgroundColor: theme.palette.dialog.content,
  },
  messageIcon: {
    position: "absolute",
    top: "-5rem",
    left: "-3rem",
    fontSize: "14rem",
    opacity: "0.4",
  },
  messageSVG: {
    height: "14rem",
    width: "14rem",
  },
  messageText: {
    position: "inherit",
    fontSize: "1rem",
    zIndex: "10",
    textAlign: "center",
  },
  messageActions: {
    height: "3rem",
    backgroundColor: theme.palette.dialog.bottom,
    display: "grid",
    gridTemplateColumns: "1fr 12rem 1fr",
    padding: 4,
  },
  OKButton: {
    height: "2rem",
    gridColumn: "2",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-planform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 400 }} />
    </Draggable>
  );
}

const SystemMessage = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          props.handleOK();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-planform-title"
    >
      <DialogTitle
        className="dialogTitle"
        dialogTitle="Systemmeldung"
        closeForm={props.handleOK}
        width="300px"
        color="black"
        backgroundColor="#AE690F"
      />
      <DialogContent className={classes.messageContent}>
        <div className={classes.messageIcon}>
          {props.messageType === "info" && (
            <InfoRoundedIcon
              className={classes.messageSVG}
              style={{ fill: "blue" }}
            />
          )}
          {props.messageType === "warning" && (
            <WarningRoundedIcon
              className={classes.messageSVG}
              style={{ fill: "red" }}
            />
          )}
        </div>
        <div className={classes.messageText}>{props.messageText}</div>
      </DialogContent>
      <DialogActions className={classes.messageActions}>
        <Button
          className={classes.OKButton}
          color="primary"
          size="large"
          variant="contained"
          onClick={props.handleOK}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SystemMessage;
