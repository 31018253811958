import React from "react";
import { stringToHslColor } from "Components/Utilities";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import Paper from "@mui/material/Paper";
import ScreenTitle from "Components/ScreenTitle";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "grid",
    gridTemplateColumns: "4rem 1fr",
    alignItems: "center",
    marginLeft: "1rem",
  },
  contentWrapper: {
    padding: "10px",
  },
  avatar: {
    color: "white",
    width: "4rem",
    height: "4rem",
    fontSize: "1.5rem !important",
    gridColumn: "1",
  },
  name: {
    marginLeft: "1rem",
    fontSize: "2rem",
    gridColumn: "2",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 33%)",
    margin: "0 1rem",
  },
  subtitle: {
    gridColumn: "1",
    fontSize: "1.2rem",
    marginTop: "6px",
    color: theme.palette.text.screenTitle,
  },
  Left: {
    gridColumn: "1",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginBottom: "4px",
  },
  Middle: {
    gridColumn: "2",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  Right: {
    gridColumn: "3",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  label: {
    fontSize: "1rem",
    fontWeight: "100",
  },
  value: {
    marginLeft: "1rem",
    fontSize: "1rem",
    fontWeight: "500",
  },
  bottom: {
    height: "1rem",
  },
}));

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;
  const LoginName = state.loggeduser.LoginName;
  const departments = state.departments.Departments;
  return {
    loggedUser,
    LoginName,
    department: departments?.find((D) => D.id === loggedUser.departmentId)
      ?.department,
  };
};

function Person({ loggedUser, LoginName, department, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    loggedUser && (
      <div className="content">
        <Paper elevation={8}>
          <ScreenTitle
            title={t("My saved data")}
            class="messages"
            icon={
              <PersonOutlineTwoToneIcon
                style={{ width: "1.8em", height: "1.8em" }}
              />
            }
          />
          <div className={classes.title}>
            <Avatar
              className={classes.avatar}
              style={{ backgroundColor: stringToHslColor(loggedUser.lastName) }}
            >
              {loggedUser.initials}
            </Avatar>
            <div className={classes.name}>{loggedUser.listName}</div>
          </div>
          <div className={classes.contentWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("General data")}</div>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Personnel number")}:</span>
                <span className={classes.value}>{loggedUser.personNumber}</span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Department")}:</span>
                <span className={classes.value}>{department}</span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Entry date")}:</span>
                <span className={classes.value}>
                  {moment(loggedUser.startDate).format("LL")}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Exit date")}:</span>
                <span className={classes.value}>
                  {loggedUser.endDate
                    ? moment(loggedUser.endDate).format("LL")
                    : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Birthday")}:</span>
                <span className={classes.value}>
                  {loggedUser.birthday
                    ? moment(loggedUser.birthday).format("LL")
                    : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>
                  {t("Mail address")} {t("company")}:
                </span>
                <span className={classes.value}>{LoginName}</span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>
                  {t("Phone number")} {t("company")}:
                </span>
                <span className={classes.value}>
                  {loggedUser.telephoneCompany}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>
                  {t("Mobile number")} {t("company")}:
                </span>
                <span className={classes.value}>
                  {loggedUser.mobileCompany}
                </span>
              </Grid>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Private data")}</div>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Street")}:</span>
                <span className={classes.value}>
                  {loggedUser.street ? loggedUser.street : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Number")}:</span>
                <span className={classes.value}>
                  {loggedUser.street_number ? loggedUser.street_number : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Country")}:</span>
                <span className={classes.value}>
                  {loggedUser.country ? loggedUser.country : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("ZIP")}:</span>
                <span className={classes.value}>
                  {loggedUser.zip ? loggedUser.zip : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("City")}:</span>
                <span className={classes.value}>
                  {loggedUser.city ? loggedUser.city : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Phone number")}:</span>
                <span className={classes.value}>
                  {loggedUser.telephone ? loggedUser.telephone : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Mobile number")}:</span>
                <span className={classes.value}>
                  {loggedUser.mobile ? loggedUser.mobile : "-"}
                </span>
              </Grid>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Emergency contact")}</div>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Name")}:</span>
                <span className={classes.value}>
                  {loggedUser.emergencyName ? loggedUser.emergencyName : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Phone number")}:</span>
                <span className={classes.value}>
                  {loggedUser.emergencyNumber
                    ? loggedUser.emergencyNumber
                    : "-"}
                </span>
              </Grid>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Billing")}</div>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>IBAN:</span>
                <span className={classes.value}>
                  {loggedUser.IBAN ? loggedUser.IBAN : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>BIC:</span>
                <span className={classes.value}>
                  {loggedUser.BIC ? loggedUser.BIC : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Tax id")}:</span>
                <span className={classes.value}>
                  {loggedUser.taxIdNumber ? loggedUser.taxIdNumber : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>
                  {t("Social insurance number")}:
                </span>
                <span className={classes.value}>
                  {loggedUser.socialInsuranceNumber
                    ? loggedUser.socialInsuranceNumber
                    : "-"}
                </span>
              </Grid>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Health insurance")}</div>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Insurance company")}:</span>
                <span className={classes.value}>
                  {loggedUser.healthInsuranceName
                    ? loggedUser.healthInsuranceName
                    : "-"}
                </span>
              </Grid>
              <Grid item xs={12}>
                <div className={"subLine"}>
                  {t("Capital accumulation benefits")}
                </div>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Insurance carrier")}:</span>
                <span className={classes.value}>
                  {loggedUser.insuranceName1 ? loggedUser.insuranceName1 : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Insurance number")}:</span>
                <span className={classes.value}>
                  {loggedUser.insuranceNumber1
                    ? loggedUser.insuranceNumber1
                    : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Amount")}:</span>
                <span className={classes.value}>
                  {loggedUser.insuranceValue1 > 0
                    ? loggedUser.insuranceValue1.replace(".", ",") + " €"
                    : "-"}
                </span>
              </Grid>
              <Grid item xs={12}>
                <div className={"subLine"}>{t("Employer-funded pension")}</div>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Insurance carrier")}:</span>
                <span className={classes.value}>
                  {loggedUser.insuranceName2 ? loggedUser.insuranceName2 : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Insurance number")}:</span>
                <span className={classes.value}>
                  {loggedUser.insuranceNumber2
                    ? loggedUser.insuranceNumber2
                    : "-"}
                </span>
              </Grid>
              <Grid item xs={4}>
                <span className={classes.label}>{t("Amount")}:</span>
                <span className={classes.value}>
                  {loggedUser.insuranceValue2 > 0
                    ? loggedUser.insuranceValue2.replace(".", ",") + " €"
                    : "-"}
                </span>
              </Grid>
              <div className={classes.bottom}></div>
            </Grid>
          </div>
        </Paper>
      </div>
    )
  );
}

export default connect(mapStateToProps, null)(Person);
