import React, { useState } from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getGlobalSetting } from "Components/Utilities";

import { minutesToTime, timeToMinutes, twoDigits } from "Components/Utilities";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import Paper from "@mui/material/Paper";
import CancelButton from "Components/Buttons/CancelButton";
import OKButton from "Components/Buttons/OKButton";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: 400,
  },
  dialogPosition: {
    position: "absolute",
    top: "3%",
    left: "6rem",
    transform: "translate(-50%, -50%)",
  },
  timeInput: {
    margin: "10px 0",
    "& Input": {
      fontSize: "2rem",
      textAlign: "center",
      padding: 0,
    },
  },
  dialogContent: {
    padding: "0 15px",
    backgroundColor: theme.palette.dialog.content,
  },
  dialogActions: {
    display: "block",
    padding: 0,
  },
  okButton: {
    gridColumn: 4,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-selectionform-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: 700 }} />
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    startTime: state.times.startTime,
  };
};

const EndTimeDialog = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    time: moment().format("HH:mm"),
    timeMinutes: timeToMinutes(moment().format("HH:mm")),
    endTimeMinutes: timeToMinutes(moment().format("HH:mm")),
    hours: parseInt(moment().format("HH")),
    minutes: parseInt(moment().format("mm")),
  });
  const { t } = useTranslation();
  const changeMinutes = parseInt(
    getGlobalSetting("timetracking", "changeMinutes")
  );

  const handleClose = () => {
    store.dispatch({ type: "SET_END_TIME", payload: null });
    store.dispatch({ type: "OPEN_END_TIME", payload: false });
  };

  const handleSave = () => {
        store.dispatch({ type: "SET_END_TIME", payload: state.time + ":59" });
        store.dispatch({ type: "OPEN_END_TIME", payload: false });
  }

  const handleTimeChange = (event) => {
    const time = event.target.value;
    setState({
      ...state,
      time: time,
      hours: parseInt(time.substring(0, 2)),
      minutes: parseInt(time.substring(3, 5)),
    });
  };

  const handleHoursSliderChange = (event, newValue) => {
    setState({
      ...state,
      hours: newValue,
      time: twoDigits(newValue) + ":" + twoDigits(state.minutes),
    });
  };
  const handleMinutesSliderChange = (event, newValue) => {
    setState({
      ...state,
      minutes: newValue,
      time: twoDigits(state.hours) + ":" + twoDigits(newValue),
    });
  };

  const handleTimeSliderChange = (event, newValue) => {
    const tTime = minutesToTime(newValue);
    setState({
      ...state,
      timeMinutes: newValue,
      hours: parseInt(tTime.substring(0, 2)),
      minutes: parseInt(tTime.substring(3, 5)),
      time: tTime,
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPosition }}
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-title"
    >
      <DialogTitle
        className="dialogTitle"
        id="draggable-title"
        dialogTitle={t("Set end time")}
        closeForm={handleClose}
        backgroundColor="#243573"
      />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.timeInput}
              autoFocus
              type="text"
              name="endTime"
              value={state.time}
              label={t("End")}
              onChange={handleTimeChange}
              disabled={changeMinutes > 0}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        {changeMinutes > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <span>{t("Time")}</span>
            </Grid>
            <Grid item xs={9}>
              <Slider
                aria-label="Time"
                value={state.timeMinutes}
                onChange={handleTimeSliderChange}
                valueLabelDisplay="off"
                // valueLabelFormat={(value) => <div>{minutesToTime(value)}</div>}
                step={1}
                marks
                min={state.endTimeMinutes - changeMinutes}
                max={state.endTimeMinutes + changeMinutes}
              />
            </Grid>
          </Grid>
        )}
        {changeMinutes < 1 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <span>{t("Hours")}</span>
              </Grid>
              <Grid item xs={9}>
                <Slider
                  aria-label="Hours"
                  value={state.hours}
                  onChange={handleHoursSliderChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => <div>{twoDigits(value)}</div>}
                  step={1}
                  marks
                  min={0}
                  max={23}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <span>{t("Minutes")}</span>
              </Grid>
              <Grid item xs={9}>
                <Slider
                  aria-label="Minutes"
                  value={state.minutes}
                  onChange={handleMinutesSliderChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => <div>{twoDigits(value)}</div>}
                  step={1}
                  marks
                  min={0}
                  max={59}
                />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions className="dialogActions">
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <OKButton onClick={handleSave} />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(EndTimeDialog);
