import {
  FETCH_TIMES_REQUEST,
  FETCH_TIMES_SUCCESS,
  FETCH_USERTIMES_REQUEST,
  FETCH_USERTIMES_SUCCESS,
  FETCH_USERTIMES_CUM_REQUEST,
  FETCH_USERTIMES_CUM_SUCCESS,
  FETCH_TIMES_FAULTY_REQUEST,
  FETCH_TIMES_FAULTY_SUCCESS,
  SET_TIME,
  END_TIME,
  SET_SELECTED_DATE,
  SET_SELECTED_TIME,
  SET_SELECTED_TIME_FORM_ID,
  UPDATE_TIME,
  DELETE_TIME,
  ADD_TIME,
  TERMINATE_DAY_REQUEST,
  TERMINATE_DAY_SUCCESS,
  TERMINATE_DAY_USER_REQUEST,
  TERMINATE_DAY_USER_SUCCESS,
  TERMINATE_MONTH_REQUEST,
  TERMINATE_MONTH_SUCCESS,
} from "actions/types";

import { getTimes, getTimesDay, getTimesCum, getTimesFaulty, setStartTime, 
  setEndTime, timeUpdate, timeDelete, timeAdd, dayTermination, dayTerminationUser, monthTermination,
  saveAliveTime } from '../api'
import {
  dataFailure,
  dataStartLoading,
  dataEndLoading,
  actionSuccess
} from "./general";

const fetchTimesRequest = () => {
  return {
    type: FETCH_TIMES_REQUEST
  }
}

const fetchTimesSuccess = (values) => {
  return {
    type: FETCH_TIMES_SUCCESS,
    payload: values
  }
}

export const fetchTimes = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchTimesRequest())
    try {
      const JSONData = await dispatch(getTimes(values))
      await dispatch(fetchTimesSuccess(JSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(dataFailure('times: fetch ' + err.message))
      dispatch(dataEndLoading())
    }
  }
}

const fetchUserTimesRequest = () => {
  return {
    type: FETCH_USERTIMES_REQUEST
  }
}

const fetchUserTimesSuccess = (values) => {
  return {
    type: FETCH_USERTIMES_SUCCESS,
    payload: values
  }
}

export const fetchUserTimes = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchUserTimesRequest())
    try {
      const JSONData = await dispatch(getTimesDay(values))
      await dispatch(fetchUserTimesSuccess(JSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(dataFailure('times: fetchUser ' + err.message))
      dispatch(dataEndLoading())
    }
  }
}

const fetchUserTimesCumRequest = () => {
  return {
    type: FETCH_USERTIMES_CUM_REQUEST
  }
}

const fetchUserTimesCumSuccess = (values) => {
  return {
    type: FETCH_USERTIMES_CUM_SUCCESS,
    payload: values
  }
}

export const fetchUserTimesCum = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchUserTimesCumRequest())
    try {
      const JSONData = await dispatch(getTimesCum(values))
      await dispatch(fetchUserTimesCumSuccess(JSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(dataFailure('times: fetchUserCum ' + err.message))
      dispatch(dataEndLoading())
    }
  }
}

const fetchTimesFaultyRequest = () => {
  return {
    type: FETCH_TIMES_FAULTY_REQUEST
  }
}

const fetchTimesFaultySuccess = (values) => {
  return {
    type: FETCH_TIMES_FAULTY_SUCCESS,
    payload: values
  }
}

export const fetchTimesFaulty = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchTimesFaultyRequest())
    try {
      const JSONData = await dispatch(getTimesFaulty(values))
      await dispatch(fetchTimesFaultySuccess(JSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(dataFailure('times: fetchTimesFaulty ' + err.message))
      dispatch(dataEndLoading())
    }
  }
}

export const saveAlive = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(saveAliveTime(values));
    } catch (err) {
      await dispatch(dataFailure("times: aliveTime " + err.message));
    }
  };
}

const setTimeData = (values) => {
  return {
    type: SET_TIME,
    payload: values
  }
}

export const setTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(setStartTime(values));
      await dispatch(setTimeData({ ...values, id: JSONData }));
    } catch(err) {
      await dispatch(dataFailure('times: set ' + err.message))
    }
  }
}

const endTimeData = (values) => {
  return {
    type: END_TIME,
    payload: values,
  };
};

export const endTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(setEndTime(values));
      await dispatch(endTimeData(JSONData));
    } catch(err) {
      await dispatch(dataFailure('times: end ' + err.message))
    }
  }
}

const updateTimeData = (values) => {
  return {
    type: UPDATE_TIME,
    payload: values
  }
}

export const updateTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(timeUpdate(values));
      await dispatch(updateTimeData(JSONData));
    } catch (err) {
      await dispatch(dataFailure('times: update ' + err.message));
    }
  };
};

const deleteTimeData = (values) => {
  return {
    type: DELETE_TIME,
    payload: values
  }
}

export const deleteTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(timeDelete(values));
      await dispatch(deleteTimeData(JSONData));
    } catch (err) {
      await dispatch(dataFailure('times: delete ' + err.message));
    }
  };
};

const addTimeData = (values) => {
  return {
    type: ADD_TIME,
    payload: values
  }
}

export const addTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(timeAdd(values));
      await dispatch(addTimeData(JSONData));
    } catch (err) {
      await dispatch(dataFailure('times: add ' + err.message));
    }
  };
};

export const setSelectedDate = (values) => {
  return {
    type: SET_SELECTED_DATE,
    payload: values,
  };
};

export const setSelectedTime = (values) => {
  return {
    type: SET_SELECTED_TIME,
    payload: values,
  };
};

export const setSelectedTimeFormId = (values) => {
  return {
    type: SET_SELECTED_TIME_FORM_ID,
    payload: values,
  };
};

const terminateDayRequest = () => {
  return {
    type: TERMINATE_DAY_REQUEST,
  };
};

const terminateDaySuccess = (values) => {
  return {
    type: TERMINATE_DAY_SUCCESS,
    payload: values,
  };
};

export const terminateDay = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(terminateDayRequest());
    try {
      const JSONData = await dispatch(dayTermination(values));
      await dispatch(terminateDaySuccess(JSONData));
      dispatch(actionSuccess());
      dispatch(dataEndLoading());
    } catch (err) {
      await dispatch(dataFailure("times: dayTermination " + err.message));
      dispatch(dataEndLoading());
    }
  };
};

const terminateDayUserRequest = () => {
  return {
    type: TERMINATE_DAY_USER_REQUEST,
  };
};

const terminateDayUserSuccess = (values) => {
  return {
    type: TERMINATE_DAY_USER_SUCCESS,
    payload: values,
  };
};

export const terminateDayUser = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(terminateDayUserRequest());
    try {
      const JSONData = await dispatch(dayTerminationUser(values));
      await dispatch(terminateDayUserSuccess(JSONData));
      await dispatch(fetchUserTimesCum(values))
      dispatch(actionSuccess())
      dispatch(dataEndLoading());
    } catch (err) {
      await dispatch(dataFailure("times: dayTermination " + err.message));
      dispatch(dataEndLoading());
    }
  };
};

const terminateMonthRequest = () => {
  return {
    type: TERMINATE_MONTH_REQUEST,
  };
};

const terminateMonthSuccess = (values) => {
  return {
    type: TERMINATE_MONTH_SUCCESS,
    payload: values,
  };
};

export const terminateMonth = (values) => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(terminateMonthRequest());
    try {
      const JSONData = await dispatch(monthTermination(values));
      await dispatch(terminateMonthSuccess(JSONData));
      dispatch(actionSuccess());
      dispatch(dataEndLoading());
    } catch (err) {
      await dispatch(dataFailure("times: monthTermination " + err.message));
      dispatch(dataEndLoading());
    }
  };
};
