import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchOffDayTypes } from "redux/index";
import "../Calendars.scss";
import { getPersonalSetting } from "Components/Utilities";

import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";

import ScreenTitle from "Components/ScreenTitle";
import RemindersBar from "Calendars/Components/RemindersBar";
import ContextMenu from "Calendars/Components/ContextMenu";
import DateRangeIcon from "@mui/icons-material/DateRangeTwoTone";

import YearCalHeader from "./YearCalHeader";
import HeadlineDayFrame from "./HeadlineDayFrame";
import YearCalDays from "./YearCalDays";
import DayInfo from "./DayInfo";
import StatsBar from "./StatsBar";
import CalToolTip from "./CalToolTip";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  yearcalWrapper: {
    padding: "4px",
    fontSize: "14px",
  },
  reminder: {
    fontSize: "1.2rem",
    marginTop: "0.5rem",
    height: "2rem",
    color: theme.palette.main,
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  reminderFixed: {
    height: "2rem",
    width: "18rem",
    lineHeight: "2.1rem",
    padding: "0 10px",
    textAlign: "center",
    borderRadius: "1rem 0 0 1rem",
    backgroundColor: theme.palette.red,
    color: "white",
    display: "inline-block",
    position: "absolute",
    zIndex: 10,
  },
  reminderFixedRight: {
    height: "0",
    width: "0",
    marginLeft: "18rem",
    display: "inline-block",
    position: "absolute",
    borderLeft: "1rem solid " + theme.palette.red,
    borderTop: "1rem solid transparent",
    borderBottom: "1rem solid transparent",
    zIndex: 10,
  },
  singleReminder: {
    display: "inline-block",
    borderRadius: "4px",
    padding: "0 4px 0 4px",
    marginTop: "2rem",
    marginLeft: "20rem",
    fontSize: "1.1rem",
    maxWidth: "calc(100% - 21rem)",
    backgroundColor: theme.palette.background.messageList,
    boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    animation: "$moving 5s linear infinite",
  },
  reminderTime: {
    height: "1.8rem",
    fontSize: "1rem",
    backgroundColor: theme.palette.blue,
    padding: "0 4px",
    margin: "0 4px",
    borderRadius: "4px",
  },
  reminderTitle: {
    display: "inline-block",
    fontWeight: "400",
  },
  "@keyframes moving": {
    "0%": { transform: "translateY(0rem)" },
    "20%": { transform: "translateY(-1.8rem)" },
    "80%": { transform: "translateY(-1.8rem)" },
    "100%": { transform: "translateY(-4rem)" },
  },
  infoWrapper: {
    height: "2rem",
    marginTop: "4px",
    borderRadius: "1rem",
    border: "2px solid " + theme.palette.background.paperBottom,
    backgroundColor: "white",
    padding: "0 4px 0 8px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
  },
}));

const mapStateToProps = (state) => {
  const loggedUserId = state.loggeduser.LoggedUser.id;
  const displayReminder = state.reminders.displayReminder;
  const showContextMenu = state.general.showContextMenu;
  return {
    loggedUserId,
    displayReminder,
    showContextMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffDayTypes: () => dispatch(fetchOffDayTypes()),
  };
};

function YearCalendar({
  loggedUserId,
  displayReminder,
  showReminder,
  showContextMenu,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openStatsBar, setOpenStatsBar] = useState(false);

  // // Personal settings
  const useCalendarTooltips =
    parseInt(getPersonalSetting("calendars", "useCalendarTooltips")) === 1;
  const useDayInfo =
    parseInt(getPersonalSetting("calendars", "useDayInfo")) === 1;

  const headline = () => {
    const days = [];
    days.push(<div className={classes.monthname} key={0}></div>);
    for (let day = 1; day <= 31; day++) {
      days.push(<HeadlineDayFrame id={day} key={day} />);
    }
    return <>{days}</>;
  };

  const showStatsBar = () => {
    setOpenStatsBar(!openStatsBar);
  };

  useEffect(() => {
    props.fetchOffDayTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <Paper square elevation={8}>
        <ScreenTitle
          title={t("Year calendar")}
          class="calendar"
          icon={
            <DateRangeIcon
              viewBox="0 0 24 24"
              style={{ width: "1.8em", height: "1.8em" }}
            />
          }
        />
        <div className={classes.formHeader}>
          <YearCalHeader showStatsBar={showStatsBar}/>
        </div>
        <div id="yearcal" className={classes.yearcalWrapper}>
          <div className="header-row">{headline()}</div>
          <div>
            <YearCalDays />
          </div>
          {useCalendarTooltips && <CalToolTip />}
        </div>
      </Paper>
      {showContextMenu && <ContextMenu />}
      {useDayInfo && (
        <div className={classes.infoWrapper}>
          <DayInfo />
        </div>
      )}
      {displayReminder.length > 0 && (
        <RemindersBar reminders={displayReminder} />
      )}
      {openStatsBar && <StatsBar />}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(YearCalendar);
