import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE
} from 'actions/types'
import produce from 'immer'  

const initialState = {
  Dashboard: {},
  loading: false,
  error: ''
}

const dashboardReducer = (state = initialState, action) => 
produce(state, draft => {
  switch (action.type) {
    case FETCH_DASHBOARD_REQUEST:
      draft.loading = true
      break
    case FETCH_DASHBOARD_SUCCESS:
      draft.Dashboard = action.payload
      draft.loading=false
      draft.error = ''
      break      
    case FETCH_DASHBOARD_FAILURE:
      draft.loading = false
      draft.error = action.payload
      break         
    default: return state
  }
})

export default dashboardReducer   