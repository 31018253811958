import React, { useState } from "react";
import { connect } from "react-redux";
import { setPersonalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getPersonalSetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
  hidden: {
    display: "none",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalSettings: (values) => dispatch(setPersonalSettings(values)),
  };
};

const MonthCalendarForm = ({ setPersonalSettings, loggedUserId, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "monthcalendar";
  const [state, setState] = useState({
    showCompanySumLine: parseInt(
      getPersonalSetting(component, "showCompanySumLine")
    ),
  });

  const handleChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setState({ ...state, [event.target.name]: value });
    setPersonalSettings({
      userId: loggedUserId,
      component: component,
      key: event.target.name,
      value: value,
    });
  };

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className={"subLine"}>{t("Sum rows")}</div>
            <Grid container spacing={3}>
              <Grid item>
                <SomuraSwitchField
                  name="showCompanySumLine"
                  checked={state.showCompanySumLine === 1}
                  onChange={handleChange}
                  label={t("Show company sum row")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthCalendarForm);
