import React, { useEffect, useState, Fragment } from "react";
import { stringToHslColor } from "Components/Utilities";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { logoutUser, runUtility } from "redux/index";
import { store } from "redux/store";

import SomuraTooltip from "Components/SomuraTooltip";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import ListItemIcon from "@mui/material/ListItemIcon";

import NewPassword from "./NewPassword";

import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import TimeTrackIcon from "@mui/icons-material/TimerOutlined";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import NightsStayTwoToneIcon from "@mui/icons-material/NightsStayTwoTone";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import MeetingRoomTwoToneIcon from "@mui/icons-material/MeetingRoomTwoTone";
// import SpeedIcon from "@mui/icons-material/Speed";

import AboutSomura from "./AboutSomura";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    width: "100%",
    marginLeft: "0",
  },
  menu: {
    marginTop: "-1rem",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  IconButton: {
    right: 0,
  },
  avatar: {
    color: "white",
  },
  grey: {
    backgroundColor: "grey",
  },
  img: {
    height: "30px",
    gridColumn: 1,
  },
  userName: {
    fontSize: "1.2rem",
    color: theme.palette.text.userMenuTitle,
  },
}));

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;
  return {
    loginState: state.loggeduser.LoginState,
    loggedUser,
    globalSettings: state.settings.GlobalSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    runUtility: () => dispatch(runUtility()),
  };
};

const UserMenu = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const loggedUser = props.loggedUser;

  const currentTheme = getSavedTheme();

  const [anchorE2, setAnchorE2] = useState(null);
  const [newPassword, setNewPassword] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [useTimeTracking, setUseTimeTracking] = useState(false)

  const openUser = Boolean(anchorE2);

  const handleUserMenu = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    setAnchorE2(null);
  };

  const handlePWChange = () => {
    setNewPassword(true);
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    setAnchorE2(null);
  };

  const handlePWClose = () => {
    setNewPassword(false);
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    setAnchorE2(null);
  };

  const showAbout = () => {
    setOpenAbout(true);
    handleClose();
  };

  function getSavedTheme() {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme || "dark";
  }

  const setMode = (mode) => {
    localStorage.setItem("theme", mode);
    window.location.reload();
  };

  const handleMode = () => {
    currentTheme === "light" ? setMode("dark") : setMode("light");
  };

  const handleLogout = () => {
    setAnchorE2(null);
    // sessionStorage.clear();
    localStorage.removeItem("jwt");
    props.logoutUser();
    // history.push("/login");
    window.location.reload(false);
  };

  useEffect(() => {
    if (props.globalSettings) {
    setUseTimeTracking(
      props.globalSettings?.find((GS) => GS.component === "system")?.settings[
        "useTimeTrackModule"
      ] === "1"
    );}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.globalSettings]);

  // const handleDevUtility = () => {
  //   props.runUtility();
  //   setAnchorE2(null);
  // };

  return (
    <Fragment>
      {loggedUser && (
        <>
          <SomuraTooltip
            title={
              t("Logged in as ") +
              loggedUser.firstName +
              " " +
              loggedUser.lastName
            }
            placement="left"
          >
            <IconButton
              style={{ margin: "0 0.5rem" }}
              edge="end"
              aria-label="account of current user"
              aria-controls="usermenu"
              aria-haspopup="true"
              onClick={handleUserMenu}
              color="inherit"
              size="large"
            >
              <Avatar
                className={classes.avatar}
                style={{
                  backgroundColor: stringToHslColor(
                    loggedUser.initials ? loggedUser.initials : "?"
                  ),
                }}
              >
                {loggedUser.initials ? loggedUser.initials : "?"}
              </Avatar>
            </IconButton>
          </SomuraTooltip>

          <Menu
            className={classes.menu}
            id="usermenu"
            anchorEl={anchorE2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openUser}
            onClose={handleClose}
          >
            <MenuItem disabled={true} className={classes.userName}>
              {loggedUser?.listName}
            </MenuItem>
            <MenuItem
              name="Person"
              component={NavLink}
              onClick={handleClose}
              to={"/Person"}
            >
              <ListItemIcon>
                <PersonOutlineTwoToneIcon />
              </ListItemIcon>
              {t("Personal data")}
            </MenuItem>
            {useTimeTracking && !loggedUser.noTimeTracking && (
              <MenuItem
                name="Worktime"
                component={NavLink}
                onClick={handleClose}
                to={"/Worktime"}
              >
                <ListItemIcon>
                  <TimeTrackIcon />
                </ListItemIcon>
                {t("Personal worktimes")}
              </MenuItem>
            )}
            <MenuItem
              // disabled={true}
              name="Reminders"
              component={NavLink}
              onClick={handleClose}
              to={"/Reminders"}
            >
              <ListItemIcon>
                <NotificationsNoneTwoToneIcon />
              </ListItemIcon>
              {t("Reminders")}
            </MenuItem>
            <MenuItem onClick={handlePWChange}>
              <ListItemIcon>
                <LockOpenTwoToneIcon />
              </ListItemIcon>
              {t("Change password")}
            </MenuItem>
            <MenuItem
              name="Settings"
              component={NavLink}
              onClick={handleClose}
              to={"/PersonalSettings"}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              {t("Settings")}
            </MenuItem>
            <MenuItem name="AboutSomura" onClick={showAbout}>
              <ListItemIcon>
                <InfoTwoToneIcon />
              </ListItemIcon>
              {t("About somura")}
            </MenuItem>
            {currentTheme === "light" && (
              <MenuItem onClick={handleMode}>
                <ListItemIcon>
                  <NightsStayTwoToneIcon />
                </ListItemIcon>
                {t("Dark mode")}
              </MenuItem>
            )}
            {currentTheme === "dark" && (
              <MenuItem onClick={handleMode}>
                <ListItemIcon>
                  <WbSunnyTwoToneIcon />
                </ListItemIcon>
                {t("Light mode")}
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <MeetingRoomTwoToneIcon />
              </ListItemIcon>
              {t("Logout")}
            </MenuItem>
            {loggedUser.emailCompany === "walter.rank@netitwork.net" && (
              <MenuItem
                name="Test"
                component={NavLink}
                onClick={handleClose}
                to={"/Test"}
              >
                {/* <MenuItem onClick={handleDevUtility}>
                <ListItemIcon>
                  <SpeedIcon />
                </ListItemIcon> */}
                {"Development utility"}
              </MenuItem>
            )}
          </Menu>
          {openAbout && <AboutSomura closeAbout={() => setOpenAbout(false)} />}
        </>
      )}
      {newPassword && <NewPassword closeNewPassword={handlePWClose} />}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
