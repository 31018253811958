import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { store } from "redux/store";
import { savePassword, createAdminAccount } from "redux/index";
import { getGlobalSetting } from "Components/Utilities";
import { withStyles, makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import SomuraTextField from "Components/SomuraTextField";
import SomuraProgress from "Components/SomuraProgress";
import Tooltip from "@mui/material/Tooltip";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

var sha256 = require("js-sha256");

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    textAlign: "center",
    paddingBottom: "0",
    width: "100%",
  },
  topText: {
    textAlign: "center",
    fontSize: "0.8rem",
  },
  link: {
    color: theme.palette.primary.main,
  },
  username: {
    fontSize: "1rem",
    textAlign: "center",
    paddingTop: "0",
    width: "100%",
  },
  change: {
    paddingLeft: "6px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  toolTipTitle: {
    color: "#243573",
    fontSize: "1rem",
    backgroundColor: "#ECECEC",
    padding: "2px 4px",
  },
  subLine: {
    fontSize: "0.8rem",
    margin: "1px 0px",
    padding: "0px 4px",
    borderRadius: "0.5rem",
  },
  signInButton: {
    width: "100%",
    margin: "20px 0 !important",
  },
  info: {
    paddingTop: "0 !important",
    color: theme.palette.somura,
  },
}));

const SomuraTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    border: "2px solid #DADDE9",
    padding: "8px",
  },
}))(Tooltip);

const mapStateToProps = (state) => {
  return {
    loginState: state.loggeduser.LoginState,
    loginName: state.loggeduser.LoginName,
    progressValue: state.general.progressValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePassword: (pw) => dispatch(savePassword(pw)),
    createAdminAccount: (values) => dispatch(createAdminAccount(values)),
  };
};

const FirstPassword = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState({
    username: props.loginName,
    firstName: "",
    lastName: "",
    companyName: "",
    companyEmail: "",
    startDate: "",
    password: "",
    password2: "",
  });

  const component = "passwords";
  const minLength = getGlobalSetting(component, "minLength");
  const useCapitalChars =
    parseInt(getGlobalSetting(component, "useCapitalChars")) === 1;
  const minCapitalChars = parseInt(
    getGlobalSetting(component, "minCapitalChars")
  );
  const useNormalChars =
    parseInt(getGlobalSetting(component, "useNormalChars")) === 1;
  const minNormalChars = parseInt(
    getGlobalSetting(component, "minNormalChars")
  );
  const useNumbers = parseInt(getGlobalSetting(component, "useNumbers")) === 1;
  const minNumbers = parseInt(getGlobalSetting(component, "minNumbers"));
  const useSpecialChars =
    parseInt(getGlobalSetting(component, "useSpecialChars")) === 1;
  const minSpecialChars = parseInt(
    getGlobalSetting(component, "minSpecialChars")
  );
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ";
  const lowercase = "abcdefghijklmnopqrstuvwxyzäöüß";
  const number = "0123456789";
  const special = "!$%&/()=*+-#.,;:@?{}[]";
  var progValue;
  var numCrit;

  const title = () => {
    numCrit = 1;
    var sublines = [];
    sublines.push(
      <div className={classes.subLine} key="0">
        {t("Minimum length")}: {minLength} {t("characters")}
      </div>
    );
    if (useCapitalChars) {
      numCrit++;
      sublines.push(
        <div className={classes.subLine} key="1">
          {t("Minimum")} {minCapitalChars} {t("capital letters")}
        </div>
      );
    }
    if (useNormalChars) {
      numCrit++;
      sublines.push(
        <div className={classes.subLine} key="2">
          {t("Minimum")} {minNormalChars} {t("lowercase letters")}
        </div>
      );
    }
    if (useNumbers) {
      numCrit++;
      sublines.push(
        <div className={classes.subLine} key="3">
          {t("Minimum")} {minNumbers} {t("digits")}
        </div>
      );
    }
    if (useSpecialChars) {
      numCrit++;
      sublines.push(
        <div className={classes.subLine} key="4">
          {t("Minimum")} {minSpecialChars} {t("special characters")}
        </div>
      );
    }
    return (
      <>
        <div className={classes.toolTipTitle}>
          {t("Password requirements")}:
        </div>
        <div>{sublines}</div>
      </>
    );
  };

  const handleSubmit = () => {
    props.createAdminAccount({
      ...state,
      initials: state.firstName.substr(0, 1) + state.lastName.substr(0, 1),
      password: sha256(state.password),
    });
    store.dispatch({ type: "SET_LOGIN_STATE", payload: "2" });
    store.dispatch({ type: "SET_LOGIN_NAME", payload: state.companyEmail });
  };

  const handleCancel = () => {
    store.dispatch({ type: "SET_LOGIN_STATE", payload: "0" });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    checkPassword(event.currentTarget.value);
  };

  useEffect(() => {
    setState({
      username: props.loginName,
      firstName: "",
      lastName: "",
      companyName: "",
      companyEmail: "",
      startDate: "",
      password: "",
      password2: "",
    });
  }, [props.loginName]);

  const checkPassword = (pw) => {
    let numUpper = 0;
    let numLower = 0;
    let numNumber = 0;
    let numSpecial = 0;
    let check = "";
    var arrFull = [false, false, false, false, false];

    if (pw.length >= minLength) {
      arrFull[0] = true;
    }
    for (var i = 0; i < pw.length; i++) {
      check = pw.substring(i, i + 1);
      if (useCapitalChars && uppercase.indexOf(check) > -1) {
        numUpper++;
        if (numUpper >= minCapitalChars) {
          arrFull[1] = true;
        }
      }
      if (useNormalChars && lowercase.indexOf(check) > -1) {
        numLower++;
        if (numLower >= minNormalChars) {
          arrFull[2] = true;
        }
      }
      if (useNumbers && number.indexOf(check) > -1) {
        numNumber++;
        if (numNumber >= minNumbers) {
          arrFull[3] = true;
        }
      }
      if (useSpecialChars && special.indexOf(check) > -1) {
        numSpecial++;
        if (numSpecial >= minSpecialChars) {
          arrFull[4] = true;
        }
      }
    }

    progValue = (arrFull.filter((AF) => AF === true).length * 100) / numCrit;
    store.dispatch({ type: "SET_PROGRESS_VALUE", payload: progValue });
  };

  useEffect(() => {
    store.dispatch({ type: "SET_PROGRESS_VALUE", payload: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.loginFrame}>
      <Grid container spacing={1}>
        <div className={classes.title}>{t("First login as")}</div>
        <div className={classes.username}>{state.loginName}</div>
        <div className={classes.topText}>
          <br />
          {t("Welcome to")} <b>somura</b>!
          <br />
          <br />
          {t(
            "Please fill out the fields completely so that we can create an administrator account for you."
          )}
          <br />
          <br />
          {t("Please be aware")}:
          <br />
          {t("Once your account has been created, further login with ")}
          <span className={classes.link}>{state.loginName}</span>{" "}
          {t(
            "is not possible. Use your company mail address and the password you provided."
          )}
          <br />
          <br />
          {t("Have fun with ")}
          <b>somura</b>!
        </div>
        <Grid item xs={12} lg={6}>
          <SomuraTextField
            label={t("First name")}
            type="text"
            name="firstName"
            value={state.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SomuraTextField
            label={t("Last name")}
            type="text"
            name="lastName"
            value={state.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SomuraTextField
            label={t("Company")}
            type="text"
            name="companyName"
            value={state.companyName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SomuraTextField
            label={t("Company mail address")}
            type="email"
            name="companyEmail"
            value={state.companyEmail}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SomuraTextField
            label={t("Entry date")}
            type="date"
            name="startDate"
            value={state.startDate}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <SomuraTextField
            label={t("Password")}
            type="password"
            name="password"
            value={state.password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={11} lg={11}>
          <SomuraProgress />
        </Grid>
        <Grid item xs={1} lg={1} className={classes.info}>
          <SomuraTooltip title={title()} placement="right" arrow={true}>
            <div>
              <InfoOutlinedIcon />
            </div>
          </SomuraTooltip>
        </Grid>
        <Grid item xs={12} lg={12}>
          <SomuraTextField
            label={t("Repeat password")}
            type="password"
            name="password2"
            value={state.password2}
            onChange={handleChange}
            disabled={props.progressValue < 100}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <CancelButton
            onClick={() => {
              handleCancel();
            }}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <SaveButton
            disabled={
              props.progressValue < 100 ||
              state.password !== state.password2 ||
              state.firstName.length === 0 ||
              state.lastName.length === 0 ||
              state.companyName.length === 0 ||
              state.companyEmail.length === 0 ||
              state.startDate.length === 0
            }
            onClick={() => {
              handleSubmit();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstPassword);
