import {
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  UPDATE_COUNTRY,
  UPDATE_REGION,
  DELETE_REGION,
  SET_COUNTRY_ID,
  SET_REGION_ID
} from 'actions/types'

import { countriesRead, countrySave, regionUpdate, regionDelete } from '../api'
import {
  dataFailure,
  dataSuccess,
  dataStartLoading,
  dataEndLoading,
} from "./general";

const fetchCountriesRequest = () => {
  return {
    type: FETCH_COUNTRIES_REQUEST
  }
}

const fetchCountriesSuccess = (Countries) => {
  return {
    type: FETCH_COUNTRIES_SUCCESS,
    payload: Countries
  }
}

export const fetchCountries = () => {
  return async (dispatch) => {
    dispatch(dataStartLoading());
    await dispatch(fetchCountriesRequest())
    try {
      const countriesJSONData = await dispatch(countriesRead())
      await dispatch(fetchCountriesSuccess(countriesJSONData))
      dispatch(dataEndLoading())
    } catch(err) {
      await dispatch(dataFailure(err))
      dispatch(dataEndLoading())
    }
  }
}

const updateCountryData = (values) => {
  return {
    type: UPDATE_COUNTRY,
    payload: values,
  };
};

export const updateCountry = (values) => {
  return async (dispatch) => {
    try {
      const countryId = await dispatch(countrySave(values));
      await dispatch(updateCountryData({ ...values, countryId }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};

const updateRegionData = (values) => {
  return {
    type: UPDATE_REGION,
    payload: values,
  };
};

export const updateRegion = (values) => {
  return async (dispatch) => {
    try {
      const regionId = await dispatch(regionUpdate(values));
      await dispatch(updateRegionData({...values, regionId}));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};

const deleteRegionData = (values) => {
  return {
    type: DELETE_REGION,
    payload: values,
  };
};

export const deleteRegion = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(regionDelete(values));
      await dispatch(deleteRegionData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err));
    }
  };
};

export const setSelectedCountryId = (id) => {
  return {
    type: SET_COUNTRY_ID,
    payload: id
  }
}

export const setSelectedRegionId = (id) => {
  return {
    type: SET_REGION_ID,
    payload: id
  }
}
