import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPersonalSettings } from "redux/index";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { getPersonalSetting } from "Components/Utilities";
import SomuraSwitchField from "Components/SomuraSwitchField";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  thisForm: {
    padding: "20px 10px 6px 10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    personalSettings: state.settings.PersonalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalSettings: (values) => dispatch(setPersonalSettings(values)),
  };
};

const TimeTrackForm = ({
  setPersonalSettings,
  loggedUserId,
  personalSettings,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const component = "timetracking";
  const emptyState = {
    autoStart: 0,
    autoEnd: 1,
    noSeconds: 0,
    blinkingColon: 0,
  };
  const [state, setState] = useState(emptyState);

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked ? 1 : 0;
    setState({ ...state, [name]: value });
    setPersonalSettings({
      userId: loggedUserId,
      component: component,
      key: name,
      value: value,
    });
  };

  useEffect(() => {
    if (personalSettings) {
      setState({
        autoStart: parseInt(getPersonalSetting(component, "autoStart")),
        autoEnd: parseInt(getPersonalSetting(component, "autoEnd")),
        noSeconds: parseInt(getPersonalSetting(component, "noSeconds")),
        blinkingColon: parseInt(getPersonalSetting(component, "blinkingColon")),
      });
    } else {
      setState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalSettings]);

  return (
    <div>
      <div className={classes.thisForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Automation")}</div>
            <Grid container spacing={2}>
              <Grid item>
                <SomuraSwitchField
                  name="autoStart"
                  checked={state.autoStart === 1}
                  onChange={handleSwitchChange}
                  label={t("Start work time when SOMURA starts")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <SomuraSwitchField
                  name="autoEnd"
                  checked={state.autoEnd === 1}
                  onChange={handleSwitchChange}
                  label={t("End work time when SOMURA closes")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div className={"subLine"}>{t("Display")}</div>
            <Grid container spacing={2}>
              <Grid item>
                <SomuraSwitchField
                  name="noSeconds"
                  checked={state.noSeconds === 1}
                  onChange={handleSwitchChange}
                  label={t("Short time format (no seconds)")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <SomuraSwitchField
                  name="blinkingColon"
                  checked={state.blinkingColon === 1}
                  onChange={handleSwitchChange}
                  label={t("Blinking Colon")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackForm);
