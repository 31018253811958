import React, { useEffect, useState, Fragment } from "react";
import { withRouter, useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  createPersonalSettings,
  createPersonalDisplaySettings,
  fetchCompany,
  fetchPersonalSettings,
  fetchPersonalDisplaySettings,
  fetchPersonsMinimal,
  setLoggedUser,
  fetchCountries,
  fetchCompanies,
  fetchIndustries,
  fetchOffDayTypes,
  fetchDepartmentsMinimal,
  fetchMessages,
  fetchMessageFolders,
  fetchUnpublishedDays,
  fetchReminders,
  fetchTimes,
  dataReset,
} from "redux/index";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "redux/store";
import makeStyles from "@mui/styles/makeStyles";
import { getGlobalSetting } from "Components/Utilities";
import SomuraTooltip from "Components/SomuraTooltip";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import MonthCalIcon from "@mui/icons-material/TodayTwoTone";
import DateRangeIcon from "@mui/icons-material/DateRangeTwoTone";
import PersonsIcon from "@mui/icons-material/PeopleTwoTone";
import TimeTrackIcon from "@mui/icons-material/TimerOutlined";

import NewsBadge from "./NewsBadge.js";
import UnpublishedDaysBadge from "./UnpublishedDaysBadge.js";
import UnpublishedDays from "./UnpublishedDays";
import RemindersBadge from "./RemindersBadge.js";
import Reminders from "Reminders/Reminders";

import SettingsMenu from "./SettingsMenu";
import UserMenu from "./UserMenu";
import LanguageMenu from "./LanguageMenu";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Snackbar from "@mui/material/Snackbar";
import SomuraAlert from "Components/SomuraAlert";
import SystemMessage from "./SystemMessage";
import ShowTime from "./ShowTime";

const drawerWidth = 240;
const appBarHeight = 64;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  img: {
    height: "1.8rem",
  },
  appBar: {
    backgroundColor: theme.palette.somura,
    color: "white",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "2rem 1fr 4rem 4rem 4rem 4rem",
    height: appBarHeight,
    marginLeft: "73px",
    width: `calc(100% - 73px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    gridRow: "1",
    gridColumn: "1",
    marginLeft: theme.spacing(1),
  },
  mainButton: {
    width: "4rem",
    gridRow: "1",
  },
  handleDrawerButton: {
    width: "1rem",
    marginLeft: "0.1rem",
    gridRow: "1",
  },
  menuItems: {
    width: "16rem",
    gridColumn: "-2 / -5",
    display: "inline-flex",
    justifyContent: "flex-end",
  },
  mainButton1: {
    gridColumn: "-2",
  },
  mainButton2: {
    gridColumn: "-3",
  },
  mainButton3: {
    gridColumn: "-4",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    border: "none",
    width: drawerWidth,
    backgroundColor: theme.palette.background.drawer,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    border: "none",
    backgroundColor: theme.palette.background.drawer,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "73px",
  },
  drawerHeader: {
    border: "none",
    minHeight: "50px !important",
    height: appBarHeight,
    backgroundColor: theme.palette.somura,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toolbar: {
    width: `calc(100% - ${drawerWidth}px)`,
    minHeight: "50px !important",
    height: appBarHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
  },
  drawerButton: {
    width: "4rem",
    height: "4rem",
    margin: "0.3rem",
    borderRadius: "6px",
  },
  drawerIcon: {
    width: "50%",
    height: "50%",
    margin: "-10%",
  },
  content: {
    marginLeft: "73px",
    marginTop: appBarHeight,
    flexGrow: 1,
    padding: "16px",
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;
  return {
    dataError: state.general.dataError,
    dataSuccess: state.general.dataSuccess,
    dataLoading: state.general.dataLoading,
    actionSuccess: state.general.actionSuccess,
    error: state.general.error,
    loggedUser,
    globalSettings: state.settings.GlobalSettings,
    unpublishedDays: state.unpublisheddays.UnpublishedDays,
    reminders: state.reminders.Reminders,
    showMessage: state.general.showMessage,
    selectedDepartmentId: state.month.displayDepartmentId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPersonalSettings: (id) =>
      dispatch(createPersonalSettings({ userId: id })),
    createPersonalDisplaySettings: (id) =>
      dispatch(createPersonalDisplaySettings({ userId: id })),
    setLoggedUser: (id) => dispatch(setLoggedUser({ id: id })),
    fetchCompany: () => dispatch(fetchCompany()),
    fetchPersonalSettings: (id) =>
      dispatch(fetchPersonalSettings({ userId: id })),
    fetchPersonalDisplaySettings: (id) =>
      dispatch(fetchPersonalDisplaySettings({ userId: id })),
    fetchPersonsMinimal: () => dispatch(fetchPersonsMinimal()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCompanies: () => dispatch(fetchCompanies()),
    fetchIndustries: () => dispatch(fetchIndustries()),
    fetchOffDayTypes: () => dispatch(fetchOffDayTypes()),
    fetchDepartmentsMinimal: () => dispatch(fetchDepartmentsMinimal()),
    fetchUnpublishedDays: (id) =>
      dispatch(fetchUnpublishedDays({ userId: id })),
    fetchMessages: (id) => dispatch(fetchMessages({ userId: id })),
    fetchMessageFolders: (id) => dispatch(fetchMessageFolders({ userId: id })),
    fetchReminders: (id) => dispatch(fetchReminders({ userId: id })),
    fetchTimes: (values) => dispatch(fetchTimes(values)),
    dataReset: () => dispatch(dataReset()),
  };
};

function MainMenu({ loggedUser, ...props }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openUnpublishedDays, setUnpublishedDays] = useState(false);
  const [openReminders, setReminders] = useState(false);
  const [disableMessages, setDisableMessages] = useState(false);
  const [useTimeTracking, setUseTimeTracking] = useState(false);
  const multilingual =
    parseInt(getGlobalSetting("language", "multilingual")) === 1;
  let numUnpublished = 0;
  if (props.unpublishedDays.length > 0) {
    numUnpublished = props.unpublishedDays.reduce(function (prev, act) {
      return prev + parseFloat(act.numDays);
    }, 0);
  }

  const numReminders = props.reminders?.filter(
    (R) => R.startDate === moment().format("YYYY-MM-DD")
  )?.length;

  const menuItemClick = (name) => {
    setOpen(false);
    store.dispatch({ type: "SET_DISPLAY_USER", payload: loggedUser.id });
    store.dispatch({ type: "SET_SELECTED_WORKDAYS", payload: 0 });
    store.dispatch({ type: "SET_SELECTED_CALENDARDAYS", payload: 0 });
    store.dispatch({ type: "SET_SELECTED_DAYS", payload: [] });
    store.dispatch({ type: "SET_PERSON_ID", payload: -1 });
    store.dispatch({ type: "SET_COMPANY_ID", payload: -1 });
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    history.push(`/` + name);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleUDClose = () => {
    setUnpublishedDays(false);
  };

  const handleUDOpen = () => {
    setUnpublishedDays(true);
  };

  const handleRemindersClose = () => {
    setReminders(false);
  };

  const handleRemindersOpen = () => {
    setReminders(true);
  };

  const handleSnackClose = () => {
    props.dataReset();
  };

  const handleMessageClose = () => {
    store.dispatch({ type: "SHOW_MESSAGE", payload: null });
  };

  useEffect(() => {
    if (props.globalSettings) {
      if (
        props.globalSettings?.find((GS) => GS.component === "messages")
          ?.settings["disableMessages"] !== "1"
      ) {
        props.fetchMessageFolders(loggedUser.id);
        props.fetchMessages(loggedUser.id);
      }
    }
    setDisableMessages(
      props.globalSettings?.find((GS) => GS.component === "messages")?.settings[
        "disableMessages"
      ] === "1"
    );
    setUseTimeTracking(
      props.globalSettings?.find((GS) => GS.component === "system")?.settings[
        "useTimeTrackModule"
      ] === "1"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.globalSettings]);

  useEffect(() => {
    // Set language
    const language = localStorage.getItem("lang");

    if (language && language !== "undefined" && language !== "") {
      i18n.changeLanguage(language);
      moment.locale(language.substr(0, 2));
    } else {
      const defaultLanguage = getGlobalSetting(
        "language",
        "systemLanguage"
      );
      i18n.changeLanguage(defaultLanguage);
      moment.locale(defaultLanguage.substr(0, 2));
      localStorage.setItem("lang", defaultLanguage);
    }
    const token = localStorage.getItem("jwt");
    if (token) {
      const userid = jwt_decode(token).uid;
      // Set logged user
      props.setLoggedUser(userid);
      // Create personal settings if needed
      props.createPersonalSettings(userid);
      props.createPersonalDisplaySettings(userid);
      // Get company data
      props.fetchCompany();
      // Get company departments
      props.fetchDepartmentsMinimal();
      // Get settings of user
      props.fetchPersonalSettings(userid);
      props.fetchPersonalDisplaySettings(userid);
      // Get basic persons information
      props.fetchPersonsMinimal();
      // Get countries and regions
      // props.fetchCountries();
      // Get companies
      // props.fetchCompanies();
      // Get industries
      // props.fetchIndustries();
      // Get absence type data
      // props.fetchOffDayTypes();
      // Get reminders of logged person
      props.fetchReminders(userid);
      // Get tracked time of logged person
      props.fetchTimes({
        userId: userid,
        month: moment().format("M"),
        year: moment().format("YYYY"),
      });
      // Get messages and folders if message system is active
      if (!disableMessages) {
        // props.fetchMessageFolders(userid);
        // props.fetchMessages(userid);
      }
      // Get unpublished days
      props.fetchUnpublishedDays(userid);
      // Reset state
      store.dispatch({ type: "SET_EDIT_MODE", payload: false });
      store.dispatch({ type: "SET_DISPLAY_USER", payload: userid });
      store.dispatch({ type: "SET_SELECTED_WORKDAYS", payload: 0 });
      store.dispatch({ type: "SET_SELECTED_CALENDARDAYS", payload: 0 });
      store.dispatch({ type: "SET_SELECTED_DAYS", payload: [] });
      store.dispatch({ type: "SET_PERSON_ID", payload: -1 });

      if (history.goBack.length === 0 && history.location.pathname === "/") {
        const startPage = localStorage.getItem("startPage");
        if (startPage) {
          history.push("/" + startPage);
        } else {
          if (loggedUser.isadmin || loggedUser.isassistant) {
            history.push("/Dashboard");
            localStorage.setItem("startPage", "Dashboard");
          } else {
            history.push("/YearCalendar");
            localStorage.setItem("startPage", "YearCalendar");
          }
        }
      }
    } else {
      history.push("/login");
    }
    setDisableMessages(
      parseInt(getGlobalSetting("messages", "disableMessages")) === 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.drawerHeader}>
          {open ? (
            <img
              className={classes.img}
              alt="Logo"
              src="/images/Somura-logo-default_wht.svg"
            />
          ) : (
            <img
              className={classes.img}
              alt="Logo"
              src="/images/Somura-icon_wht_512.svg"
            />
          )}
        </div>
        <List>
          <ListItem
            name="Dashboard"
            className={classes.drawerButton}
            component={NavLink}
            to={"/Dashboard"}
            button
            key="Dashboard"
          >
            <SomuraTooltip title="Dashboard" placement="right">
              <ListItemIcon>
                <DashboardTwoToneIcon className={classes.drawerIcon} />
              </ListItemIcon>
            </SomuraTooltip>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {loggedUser && loggedUser.showMonthCalendar && (
            <ListItem
              name="MonthCalendar"
              className={classes.drawerButton}
              component={NavLink}
              onClick={() => {
                menuItemClick("MonthCalendar");
              }}
              to={"/MonthCalendar"}
              button
              key="Monatskalender"
              activeClassName="selectedItem"
            >
              <SomuraTooltip title={t("Month calendar")} placement="right">
                <ListItemIcon>
                  <MonthCalIcon className={classes.drawerIcon} />
                </ListItemIcon>
              </SomuraTooltip>
              <ListItemText primary={t("Month calendar")} />
            </ListItem>
          )}
          <ListItem
            name="YearCalendar"
            className={classes.drawerButton}
            component={NavLink}
            onClick={() => {
              menuItemClick("YearCalendar");
            }}
            to={"/YearCalendar"}
            button
            key="Jahreskalender"
            activeClassName="selectedItem"
          >
            <SomuraTooltip title={t("Year calendar")} placement="right">
              <ListItemIcon>
                <DateRangeIcon className={classes.drawerIcon} />
              </ListItemIcon>
            </SomuraTooltip>
            <ListItemText
              className="NoDecoration"
              primary={t("Year calendar")}
            />
          </ListItem>
          {(loggedUser.isadmin || loggedUser.isassistant) && (
            <Fragment>
              <ListItem
                name="Persons"
                className={classes.drawerButton}
                component={NavLink}
                onClick={() => {
                  menuItemClick("Persons");
                }}
                to={"/Persons"}
                button
                key="Mitarbeiter"
                activeClassName="selectedItem"
              >
                <SomuraTooltip title={t("Employees")} placement="right">
                  <ListItemIcon>
                    <PersonsIcon className={classes.drawerIcon} />
                  </ListItemIcon>
                </SomuraTooltip>
                <ListItemText
                  className="NoDecoration"
                  primary={t("Employees")}
                />
              </ListItem>
            </Fragment>
          )}
          {useTimeTracking && loggedUser.modifyTimes && (
            <Fragment>
              <ListItem
                name="Times"
                className={classes.drawerButton}
                component={NavLink}
                onClick={() => {
                  menuItemClick("TimeTracking");
                }}
                to={"/TimeTracking"}
                button
                key="Zeiterfassung"
                activeClassName="selectedItem"
              >
                <SomuraTooltip title={t("Time tracking")} placement="right">
                  <ListItemIcon>
                    <TimeTrackIcon className={classes.drawerIcon} />
                  </ListItemIcon>
                </SomuraTooltip>
                <ListItemText
                  className="NoDecoration"
                  primary={t("Time tracking")}
                />
              </ListItem>
            </Fragment>
          )}
          <ListItem
            name="Messages"
            className={classes.drawerButton}
            component={NavLink}
            onClick={() => {
              menuItemClick("Messages");
            }}
            to={"/Messages"}
            button
            key="Nachrichten"
            activeClassName="selectedItem"
            disabled={disableMessages}
          >
            <SomuraTooltip
              title={
                disableMessages ? t("Messages are disabled") : t("Messages")
              }
              placement="right"
            >
              <ListItemIcon>
                <NewsBadge className={classes.drawerIcon} />
              </ListItemIcon>
            </SomuraTooltip>
            <ListItemText primary={t("Messages")} />
          </ListItem>
        </List>
      </Drawer>

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          className={classes.handleDrawerButton}
          size="large"
        >
          {open ? (
            <SomuraTooltip title={t("Shrink sidebar")} placement="right">
              <ChevronLeftIcon className={classes.mainButton} />
            </SomuraTooltip>
          ) : (
            <SomuraTooltip title={t("Expand sidebar")} placement="right">
              <ChevronRightIcon className={classes.mainButton} />
            </SomuraTooltip>
          )}
        </IconButton>
        {useTimeTracking && !loggedUser.noTimeTracking && <ShowTime />}
        <div className={classes.menuItems}>
          {numReminders > 0 && (
            <SomuraTooltip
              title={
                `${numReminders} ` +
                (numReminders === 1 ? t("reminder") : t("reminders"))
              }
              placement="left"
            >
              <IconButton
                className={classes.mainButton}
                edge="end"
                aria-label="reminders"
                aria-controls="usermenu"
                aria-haspopup="true"
                onClick={handleRemindersOpen}
                color="inherit"
                size="large"
              >
                <RemindersBadge closeReminders={handleRemindersClose} />
              </IconButton>
            </SomuraTooltip>
          )}
          {props.unpublishedDays.length > 0 && (
            <SomuraTooltip
              title={`${numUnpublished} ` + t("unpublished days")}
              placement="left"
            >
              <IconButton
                className={classes.mainButton}
                edge="end"
                aria-label="unpublished days"
                aria-controls="usermenu"
                aria-haspopup="true"
                onClick={handleUDOpen}
                color="inherit"
                size="large"
              >
                <UnpublishedDaysBadge closeUnpublishedDays={handleUDClose} />
              </IconButton>
            </SomuraTooltip>
          )}

          {(loggedUser.isadmin || loggedUser.isassistant) && (
            <SettingsMenu
              className={classes.mainButton}
              admin={loggedUser.isadmin}
              assistant={loggedUser.isassistant}
            />
          )}
          {multilingual && <LanguageMenu className={classes.mainButton} />}
          <UserMenu className={classes.mainButton} />
        </div>
      </AppBar>
      {props.children}
      <Snackbar
        open={props.dataError}
        autoHideDuration={4000}
        onClose={handleSnackClose}
      >
        <SomuraAlert
          severity="error"
          message={props.error ? props.error : t("Changes were NOT saved!")}
        />
      </Snackbar>
      <Snackbar
        open={props.dataSuccess}
        autoHideDuration={4000}
        onClose={handleSnackClose}
      >
        <SomuraAlert
          severity="success"
          message={t("Changes were successfully saved.")}
        />
      </Snackbar>
      <Snackbar
        open={props.actionSuccess}
        autoHideDuration={4000}
        onClose={handleSnackClose}
      >
        <SomuraAlert
          severity="success"
          message={t("The action was successfully completed.")}
        />
      </Snackbar>
      {openUnpublishedDays === true && (
        <UnpublishedDays
          closeUnpublishedDays={() => setUnpublishedDays(false)}
        />
      )}
      {openReminders === true && (
        <Reminders closeReminders={() => setReminders(false)} />
      )}
      {props.showMessage && (
        <SystemMessage
          messageText={props.showMessage.text}
          messageType={props.showMessage.type}
          handleOK={() => handleMessageClose()}
        />
      )}
      {props.dataLoading > 0 && (
        <Backdrop
          className={classes.backdrop}
          open={true}
          sx={{
            color: "#fff",
            opacity: "0",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </Fragment>
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainMenu)
);
