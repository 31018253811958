import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getGlobalSetting } from "Components/Utilities";
import jwt_decode from "jwt-decode";
import { fetchMessages, fetchReminders, saveAlive } from "../redux";
import makeStyles from "@mui/styles/makeStyles";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

const useStyles = makeStyles((theme) => ({
  drawerIcon: {
    width: "2rem",
    height: "2rem",
    marginLeft: "-0.4rem",
  },
}));

const mapStateToProps = (state) => {
  const logged = state.loggeduser.LoggedUser.id;
  const messages = state.messages.Messages;
  return {
    logged,
    messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMessages: (id) => dispatch(fetchMessages({ userId: id })),
    fetchReminders: (id) => dispatch(fetchReminders({ userId: id })),
    saveAlive: (values) => dispatch(saveAlive(values)),
  };
};

function NewsBadge({ logged, ...props }) {
  const classes = useStyles();
  const disableMessages =
    parseInt(getGlobalSetting("messages", "disableMessages")) === 1;

  const updateData = () => {
    const token = localStorage.getItem("jwt");
    const userid = jwt_decode(token).uid;
    if (!disableMessages) {
      props.fetchMessages(userid);
    }
    props.fetchReminders(userid);
    props.saveAlive({
      userId: userid,
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:ss"),
    });    
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateData();
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableMessages]);

  let unreadMessages = 0;
  if (props.messages.length > 0) {
    unreadMessages = props.messages.filter(
      (M) =>
        M.senderFolderId !== "-4" &&
        M.senderFolderId !== "-5" &&
        (M.recipients
          ? M.recipients.findIndex(
              (RF) =>
                parseInt(RF.recipientFolderId) === 0 &&
                RF.readTime === null &&
                RF.recipientId === logged
            )
          : -2) > -1
    ).length;
    // Change favicon
    var favLink = document.querySelector("link[rel~='icon']");
    var touchLink = document.querySelector("link[rel~='apple-touch-icon']");
    if (unreadMessages > 0) {
      favLink.href = "../images/somura-favicon_light_mail.svg";
      touchLink.href = "../images/somura-favicon_light_mail.svg";
      document.title = "Somura (" + unreadMessages + ")";
    } else {
      favLink.href = "../images/somura-favicon_light_512.svg";
      touchLink.href = "../images/somura-favicon_light_512.svg";
      document.title = "Somura";
    }
  }

  return (
    <Badge
      color="error"
      showZero={false}
      badgeContent={unreadMessages}
      max={99}
    >
      <MailIcon className={classes.drawerIcon} />
    </Badge>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsBadge);
